/**
 * @returns {Map}
 */

function roundToBase(number) {
  const pow = number.toString().length - 1;
  const base = Math.pow(10, pow);
  return Math.floor(number / base) * base;
}

function getNumberMap() {
  const numbers = [
    [1, "uno"],
    [2, "dos"],
    [3, "tres"],
    [4, "cuatro"],
    [5, "cinco"],
    [6, "seis"],
    [7, "siete"],
    [8, "ocho"],
    [9, "nueve"],
    [10, "diez"],
    [11, "once"],
    [12, "doce"],
    [13, "trece"],
    [14, "catorce"],
    [15, "quince"],
    [16, "dieciséis"],
    [20, "veinte"],
    [22, "veintidós"],
    [23, "veintitrés"],
    [26, "veintiséis"],
    [30, "treinta"],
    [40, "cuarenta"],
    [50, "cincuenta"],
    [60, "sesenta"],
    [70, "setenta"],
    [80, "ochenta"],
    [90, "noventa"],
    [100, "cien"],
    [200, "doscientos"],
    [300, "trescientos"],
    [400, "cuatrocientos"],
    [500, "quinientos"],
    [600, "seiscientos"],
    [700, "setecientos"],
    [800, "ochocientos"],
    [900, "novecientos"],
    [1000, "mil"],
  ];

  return new Map(numbers);
}

export default class Converter {
  static convert = (number) => {
    if (!Number.isInteger(number)) {
      throw new Error(`Invalid number "${number}"`);
    }

    const numberMap = getNumberMap();

    if (number === 0) {
      return "";
    }

    if (numberMap.has(number)) {
      return numberMap.get(number);
    }

    if (number >= 17 && number <= 19) {
      return "dieci" + this.convert(number - 10);
    }
    if (number >= 21 && number <= 29) {
      return "veinti" + this.convert(number - 20);
    }
    if (number > 30 && number <= 999) {
      const roundedDown = roundToBase(number);
      const modulo = number % roundedDown;
      if (number > 30 && number < 100) {
        return this.convert(roundedDown) + " y " + this.convert(modulo);
      }
      if (number > 100 && number < 200) {
        return "ciento " + this.convert(modulo);
      }
      return this.convert(roundedDown) + " " + this.convert(modulo);
    }

    if (number >= 1000 && number < 1000000) {
      const thousands = Math.floor(number / 1000);
      const rest = number % 1000;
      const thousandsPart = this.convert(thousands);
      const restPart = this.convert(rest);
      return thousandsPart + (thousands === 1 ? " mil" : " mil") + " " + restPart;
    }

    if (number >= 1000000) {
      const millions = Math.floor(number / 1000000);
      const rest = number % 1000000;
      const millionsPart = this.convert(millions);
      const restPart = this.convert(rest);
      if (rest === 0) {
        return (
          millionsPart.replace("uno", "un") +
          " millón" +
          (millions > 1 ? "es" : "")
        );
      } else if (rest < 1000) {
        return (
          millionsPart.replace("uno", "un") +
          " millón" +
          (millions > 1 ? "es" : "") +
          " " +
          this.convert(rest)
        );
      } else {
        return (
          millionsPart.replace("uno", "un") +
          " millón" +
          (millions > 1 ? "es" : "") +
          " " +
          this.convert(rest)
        );
      }
    }

    throw new Error(`Number "${number}" is too big!`);
  };
}
