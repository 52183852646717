import React, { useMemo, useState, useEffect } from "react";
import BtnVolver from "../../common/BtnVolver";
import { Container, Col, Row, Card, Form, Modal } from "react-bootstrap";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import "react-datetime/css/react-datetime.css";
import Button from "@mui/material-next/Button";
import { darken } from "@mui/material";
import { NuevaAgencia } from "./NuevaAgencia";
import { FaEraser } from "react-icons/fa";
import { Link } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import moment from "moment";
import Datetime from "react-datetime";
import Notificaciones from "../../../shared/helpers/notificacionesToast";

const AsignacionAgencia = (props) => {
  const rolUser = props.rolUsuario;
  const URL_LOCALIDAD = window.API_ROUTES.LOCALIDAD;
  const [tablaLocalidad, setTablaLocalidad] = useState([]);
  const [localidad, setLocalidad] = useState("");
  const [showModalReasignar, setShowModalReasignar] = useState(false);
  const [showSubirArchivo, setShowSubirArchivo] = useState(false);
  const [cambiar, setCambiar] = useState(false);
  const [resolucion, setResolucion] = useState("");
  const [expediente, setExpediente] = useState("");
  const [fechaNotificacion, setFechaNotificacion] = useState(null);
  const [fechaResolucion, setFechaResolucion] = useState(null);
  const [fechaHastaAgencias, setFechaHastaAgencias] = useState(null);

  const URL_ZONA = window.API_ROUTES.ZONA;
  const [tablaZona, setTablaZona] = useState([]);
  const [zona, setZona] = useState("");

  const URL_DOMICILIOCOMERCIAL = window.API_ROUTES.DOMICILIOCOMERCIAL;

  const URL_AGENCIA = window.API_ROUTES.AGENCIA;
  const URL_TITULAR = window.API_ROUTES.TITULAR;
  const URL_TITULARAGENCIA = window.API_ROUTES.TITULARAGENCIA;
  const URL_ARCHIVO = window.API_ROUTES.ARCHIVO;
  const URL_ARCHIVORESOLUCION = window.API_ROUTES.ARCHIVORESOLUCION;
  const URL_RESOLUCION = window.API_ROUTES.RESOLUCION;

  const [tablaEstado, setTablaEstado] = useState([]);
  const [estado, setEstado] = useState("Sin Asignar");

  const [estadoView, setEstadoView] = useState([]);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const [count, setCount] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [ableButtons, setAbleButtons] = useState(false);
  const [tituloModal, setTituloModal] = useState("");

  const [opcionesTitulares, setOpcionesTitulares] = useState([]);
  const [agenciaView, setAgenciaView] = useState([]);
  const [titularAgenciaView, setTitularAgenciaView] = useState([]);
  const [domicilioComercialView, setDomicilioComercialView] = useState([]);
  const [tipoResolucion, setTipoResolucion] = useState([]);
  const [n, setN] = useState();
  const [rowSelection, setRowSelection] = useState({});
  const [titularMostrar, setTitularMostrar] = useState([]);
  const [cuitMostrar, setCuitMostrar] = useState([]);
  const [domicilioMostrar, setDomicilioMostrar] = useState([]);
  const [localidadMostrar, setLocalidadMostrar] = useState([]);
  const [zonaExplotacionMostrar, setZonaExplotacionMostrar] = useState([]);
  const [jurisdiccionMostrar, setJurisdiccionMostrar] = useState([]);

  const [numeroAgencia, setNumeroAgencia] = useState([]);
  const [numeroAgenciaNuevo, setNumeroAgenciaNuevo] = useState([]);

  const [titularMostrar2, setTitularMostrar2] = useState([]);
  const [cuitMostrar2, setCuitMostrar2] = useState([]);
  const [domicilioMostrar2, setDomicilioMostrar2] = useState([]);
  const [localidadMostrar2, setLocalidadMostrar2] = useState([]);
  const [zonaExplotacionMostrar2, setZonaExplotacionMostrar2] = useState([]);
  const [jurisdiccionMostrar2, setJurisdiccionMostrar2] = useState([]);

  const [archivoPDF, setArchivoPDF] = useState(null);
  const [tipoSucursal, setTipoSucursal] = useState("");

  const [resolucionCreadaBool, setResolucionCreadaBool] = useState(false);
  const [resolucionCreada, setResolucionCreada] = useState(null);
  const [resolucionArchivoCreada, setResolucionArchivoCreada] = useState(null);
  const [archivoCreado, setArchivoCreado] = useState(null);
  const [tablaTipoSucursal, setTablaTipoSucursal] = useState([]);
  const URL_TIPOSUCURSAL = window.API_ROUTES.TIPOSUCURSAL;

  const requiredSymbol =
    props.titulo !== "Ver" ? <b style={{ color: "red" }}>*</b> : "";

  const handleArchivoPDFChange = (event) => {
    const archivo = {
      path: event.target.files[0],
    };
    setArchivoPDF(archivo);
  };

  useEffect(() => {
    cargarLocalidades();
    cargarZonas();
    cargarEstados();
    cargarArchivo();
    cargarTiposSucursales()
  }, []);

  useEffect(() => {
    cargarArchivo();
  }, [pagination, columnFilters, estado, zona, localidad, tipoSucursal]);

  const cargarLocalidades = () => {
    ConsultasAPI.ListarObjetos(URL_LOCALIDAD + "listSinPag/").then(
      (response) => {
        setTablaLocalidad(response.data);
      }
    );
  };

  const cargarZonas = () => {
    ConsultasAPI.ListarObjetos(URL_ZONA + "listSinPag/").then((response) => {
      setTablaZona(response.data);
    });
  };
  const cargarTiposSucursales = () => {
    ConsultasAPI.ListarObjetos(URL_TIPOSUCURSAL).then((response) => {
      setTablaTipoSucursal(response.data.results);
    });
  };
  const cargarEstados = () => {
    let est = [];
    est.push({
      id: 1,
      detalle: "Sin Asignar",
    });
    est.push({
      id: 2,
      detalle: "Baja - Reasignada",
    });
    setTablaEstado(est);
  };
  const cargarTitulares = async () => {
    const response = await ConsultasAPI.ListarObjetos(URL_TITULAR).then(
      (response) => {
        response.data.results.map((titular) => {
          let op = {
            id: titular.id,
            label:
              titular.persona_detalle.cuit +
              "-" +
              titular.persona_detalle.apellido +
              " " +
              titular.persona_detalle.nombre,
          };
          opcionesTitulares.push(op);
        });
      }
    );
  };

  const columns = useMemo(() => [
    {
      header: "id",
      accessorKey: "id",
      size: 50,
    },
    {
      header: "Agencia",
      accessorKey: "agencia",
      size: 50,
    },
    {
      header: "Subagencia",
      accessorKey: "subagencia",
      size: 50,
    },
    {
      header: "Titular",
      accessorKey: "titular",
      size: 50,
    },
    {
      header: "Localidad",
      accessorKey: "localidad",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Zona",
      accessorKey: "zona",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Estado",
      accessorKey: "estado",
      size: 50,
      enableColumnFilter: false,
    },
  ]);

  const columnsMostrar = useMemo(() => [
    {
      header: "id",
      accessorKey: "id",
      size: 50,
      enableColumnFilter: false,
      enableEditing: false,
    },
    {
      header: "Agencia",
      accessorKey: "agencia",
      size: 50,
      enableColumnFilter: false,
      enableEditing: false,
    },
    {
      header: "Subagencia",
      accessorKey: "subagencia",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Titular",
      accessorKey: "titular",
      size: 50,
      enableColumnFilter: false,
      enableEditing: false,
    },
    {
      header: "Cuit",
      accessorKey: "cuit",
      size: 50,
      enableColumnFilter: false,
      enableEditing: false,
    },
    {
      header: "Direccion Comercial",
      accessorKey: "direccionComercial",
      size: 50,
      enableColumnFilter: false,
      enableEditing: false,
    },
    {
      header: "telefono",
      accessorKey: "telefono",
      size: 50,
      enableColumnFilter: false,
      enableEditing: false,
    },
    {
      header: "email",
      accessorKey: "email",
      size: 50,
      enableColumnFilter: false,
      enableEditing: false,
    },
    {
      header: "estado",
      accessorKey: "estado",
      size: 50,
      enableColumnFilter: false,
      enableEditing: false,
    },
  ]);
  /* eslint-disable */
  const handleSaveRowEdits: MaterialReactTableProps<Person>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      setData2(
        data2.map((dato) => {
          if (dato.id === row.original.id) {
            return { ...dato, subagencia: parseInt(values.subagencia) };
          } else {
            return dato;
          }
        })
      );
      exitEditingMode();
    };
  /* eslint-enable */
  const cargarArchivo = async () => {
    let datos = [];
    const agencias = await ConsultasAPI.ListarObjetos(
      URL_AGENCIA,
      pagination.pageIndex,
      pagination.pageSize,
      columnFilters,
      null,
      null,
      estado,
      null,
      zona,
      localidad,
      null,
      null,
      tipoSucursal,
      null,
    );
    agencias.data.results.forEach((agencia) => {
      let d = {
        id: agencia.id,
        agencia: agencia.numero_agencia,
        subagencia: agencia.numero_subagencia,
        titular: agencia.titular
          ? agencia.titular.persona_detalle.cuit +
          "-" +
          agencia.titular.persona_detalle.apellido +
          " " +
          agencia.titular.persona_detalle.nombre
          : "-",
        localidad:
          agencia.domicilio
            ? agencia.domicilio.localidad_detalle.nombre
            : null,
        zona:
          agencia.domicilio && agencia.domicilio.zona
            ? agencia.domicilio.zona.descripcion
            : null,
        estado: agencia.estado,
      };
      datos.push(d);
    });
    setData(datos);
    setCount(agencias.data.count);
  };

  const handleAsignar = async (row) => {
    cargarTitulares();
    setEstadoView(row.estado);
    setTituloModal("Asignar a");
    const agencia = await ConsultasAPI.ObtenerObjeto(URL_AGENCIA, row.id);
    setAgenciaView(agencia.data);
    if (row.estado === "Sin Ubicacion") {
      const response2 = await ConsultasAPI.ObtenerObjeto(
        URL_TITULARAGENCIA + "busqueda/",
        agencia.data.id
      );
      const response3 = await ConsultasAPI.ObtenerObjeto(
        URL_TITULAR,
        response2.data.titular
      );
      opcionesTitulares.map((opcion) => {
        if (opcion.id === response3.data.id) {
          setTitularAgenciaView(opcion);
        }
      });
      setTituloModal("Asignar Ubicacion de");
      setTipoResolucion("Cambio de Domiciliio");
    }
    if (row.estado === "Sin Asignar") {
      const domicilio = await ConsultasAPI.ObtenerObjeto(
        URL_DOMICILIOCOMERCIAL + "busqueda/",
        agencia.data.id
      );
      setDomicilioComercialView(domicilio.data);
      setTitularAgenciaView("");
      setTituloModal("Asignar Titular de");
      setTipoResolucion("ALTA");
    }
    if (row.estado === "Sin Asignar y Sin ubicacion") {
      setDomicilioComercialView("");
      setTitularAgenciaView("");
      setTituloModal("Asignar Titular y Ubicacion de");
      setTipoResolucion("ALTA");
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setOpcionesTitulares([]);
    setShowModal(false);
  };

  const handleClickClear = () => {
    setLocalidad("");
    setZona("");
    setEstado("Sin Asignar");
    setTipoSucursal("");
  };

  const handleCloseModalReasignar = (event) => {
    event.preventDefault();
    setNumeroAgenciaNuevo("");
    setNumeroAgencia("");
    setData1([]);
    setData2([]);
    setTitularMostrar("");
    setCuitMostrar("");
    setDomicilioMostrar("");
    setLocalidadMostrar("");
    setZonaExplotacionMostrar("");
    setJurisdiccionMostrar("");
    setTitularMostrar2("");
    setCuitMostrar2("");
    setDomicilioMostrar2("");
    setLocalidadMostrar2("");
    setZonaExplotacionMostrar2("");
    setJurisdiccionMostrar2("");
    setRowSelection({});
    setCambiar(false);
    setAbleButtons(false);
    if (resolucionCreadaBool) {
      eliminarResolucion();
    }
    setShowModalReasignar(false);
  };
  const eliminarResolucion = () => {
    if (resolucionArchivoCreada) {
      ConsultasAPI.BorrarObjeto(
        URL_ARCHIVORESOLUCION,
        resolucionArchivoCreada
      ).then((response) => {
        if (resolucionCreada) {
          ConsultasAPI.BorrarObjeto(URL_RESOLUCION, resolucionCreada);
        }
        if (archivoCreado) {
          ConsultasAPI.BorrarObjeto(URL_ARCHIVO, archivoCreado);
        }
      });
    }
  };

  const handleMostrar = async () => {
    let datos = [];
    const subagencias = await ConsultasAPI.ObtenerObjeto(
      URL_AGENCIA + "listSubagenciasActivas/",
      numeroAgencia
    );
    if (subagencias.status !== 204) {
      setTitularMostrar(
        subagencias.data[0].titular.persona_detalle.apellido +
        " " +
        subagencias.data[0].titular.persona_detalle.nombre
      );
      setCuitMostrar(subagencias.data[0].titular.persona_detalle.cuit);
      setDomicilioMostrar(
        subagencias.data[0].domicilio.calle +
        " " +
        subagencias.data[0].domicilio.numero
      );
      setLocalidadMostrar(
        subagencias.data[0].domicilio ? subagencias.data[0].domicilio.localidad_detalle.nombre : '-'
      );
      setZonaExplotacionMostrar(
        subagencias.data[0].domicilio.tiposucursal_detalle.nombre
      );
      setJurisdiccionMostrar(
        subagencias.data[0].domicilio.localidad_detalle.nombre
      );
    }
    subagencias.data.map((subAgencia) => {
      if (subAgencia.numero_subagencia !== 0) {
        let d = {
          id: subAgencia.id,
          agencia: subAgencia.numero_agencia,
          subagencia: subAgencia.numero_subagencia,
          titular: subAgencia.titular
            ? subAgencia.titular.persona_detalle.apellido +
            " " +
            subAgencia.titular.persona_detalle.nombre +
            "-" +
            subAgencia.titular.persona_detalle.cuit
            : " - ",
          cuit: subAgencia.titular
            ? subAgencia.titular.persona_detalle.cuit
            : " - ",
          direccionComercial: subAgencia.domicilio
            ? subAgencia.domicilio.calle + " " + subAgencia.domicilio.numero
            : "-",
          telefono: subAgencia.telefono,
          email: subAgencia.email,
          estado: subAgencia.estado,
        };
        datos.push(d);
      }
    });
    setData1(datos);
  };

  const handleNuevaAgencia = async () => {
    let datos = [];
    const agencia = await ConsultasAPI.ObtenerObjeto(
      URL_AGENCIA + "busqueda/",
      numeroAgenciaNuevo
    );
    if (agencia.status === 200) {
      if (agencia.data.titular && agencia.data.titular.persona_detalle) {
        setTitularMostrar2(
          agencia.data.titular.persona_detalle.apellido +
          " " +
          agencia.data.titular.persona_detalle.nombre
        );
        setCuitMostrar2(agencia.data.titular.persona_detalle.cuit);
      }
      setDomicilioMostrar2(
        agencia.data.domicilio.calle + " " + agencia.data.domicilio.numero
      );
      setLocalidadMostrar2(
        agencia.data.domicilio.localidad_detalle.nombre
      );
      setZonaExplotacionMostrar2(
        agencia.data.domicilio.tiposucursal_detalle.nombre
      );
      setJurisdiccionMostrar2(
        agencia.data.domicilio.localidad_detalle.nombre
      );
      setAbleButtons(true);
    } else {
      setTituloModal("Nuevo");
      setShowModal(true);
    }
  };

  //   const handleEditarSubagencia = async (row) => {
  //     const agencia = await ConsultasAPI.ObtenerObjeto(URL_AGENCIA, row.id);
  //     setAgenciaView(agencia.data);
  //     const domicilio = await ConsultasAPI.ObtenerObjeto(
  //       URL_DOMICILIOCOMERCIAL + "busqueda/",
  //       row.id
  //     );
  //     setDomicilioComercialView(domicilio.data);
  //     const titular = await ConsultasAPI.ObtenerObjeto(
  //       URL_TITULAR + "busqueda/",
  //       row.titular.split("-")[1]
  //     );
  //     setTitularAgenciaView(titular.data);
  //     setTituloModal("Editar");
  //     setShowModal(true);
  //   };

  const handleAgregar = async () => {
    if (cambiar) {
      let dataNew = [];
      for (const key in rowSelection) {
        const d = {
          id: data1[key].id,
          agencia: numeroAgenciaNuevo,
          subagencia: data1[key].subagencia,
          titular: data1[key].titular,
          cuit: data1[key].cuit,
          direccionComercial: data1[key].direccionComercial,
          telefono: data1[key].telefono,
          email: data1[key].email,
          estado: data1[key].estado,
        };
        dataNew = [...dataNew, d];
        //     const nueva = {
        //       numero_agencia: numeroAgenciaNuevo,
        //       numero_subagencia: data1[key].subagencia,
        //       telefono: data1[key].telefono,
        //       email: data1[key].email,
        //       estado: data1[key].estado,
        //     };
        //   const modificar = await ConsultasAPI.ModificarObjeto(
        //     URL_AGENCIA,
        //     data1[key].id,
        //     nueva
        //   );
        //   if (modificar.status === 200) {
        //     cargarHistorial();
        //   }
      }
      setData2(dataNew);
    } else {
      setShowSubirArchivo(true);
    }
  };
  const handleReasignar = async (event) => {
    event.preventDefault();
    if (cambiar) {
      let nueva = {
        agencias: [],
        fecha_hasta: formatDateToISO(fechaHastaAgencias),
      };
      data2.forEach((dato) => {
        nueva.agencias = [
          ...nueva.agencias,
          {
            id: dato.id,
            numero_agencia: dato.agencia,
            numero_subagencia: dato.subagencia,
          },
        ];
      });
      ConsultasAPI.ModificarObjeto(
        URL_AGENCIA + "reasignar/",
        nueva.agencias[0].numero_agencia,
        nueva
      )
        .then((response) => {
          handleCloseModalReasignar(event);
        })
        .catch((error) => {
          console.error(error);
          Notificaciones.notificacion(error.response.data.error);
        });
    } else {
      Notificaciones.notificacion("Debe cargar la resolucion");
    }
  };

  function formatDateToISO(dateString) {
    // Parse the input date string in DD/MM/YYYY format
    const parts = dateString.split("/");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Months are 0-indexed
    const year = parseInt(parts[2]);

    // Create a Date object
    const date = new Date(year, month, day);

    // Format the date in ISO 8601 format
    const formattedDate = date.toISOString();

    return formattedDate;
  }

  //   const cargarHistorial = () => {
  //     setData2(dat2);
  //   };

  const handleSubmitArchivo = async (event) => {
    event.preventDefault();
    let resolucionT;
    var agenciasCambiadas = [
      data2.map((dato) => {
        return dato.id;
      }),
    ];

    resolucionT = {
      resolucion: 0,
      numero_agencia: numeroAgenciaNuevo,
      tipoderesolucion: 1,
      expediente: 0,
      comentario: "Resolucion para cambiar subagencias de una agencia a otra",
      fecha_resolucion: moment,
      fecha_notificacion: formatDateToISO(fechaNotificacion),
      datos_json: {
        agenciasCambiadas: agenciasCambiadas,
      },
    };
    let archID = null;
    try {
      setResolucionCreadaBool(true);
      const nombreCarpeta = await concatCodigo(numeroAgenciaNuevo, 0)

      archID = await crearArchivo(archivoPDF, nombreCarpeta);

      const resolId = await crearResolucion(resolucionT);

      const resArch = await crearArchivoResolucion(resolId, archID);
      setCambiar(true);
      setShowSubirArchivo(false);
    } catch (error) {
      console.error(error);
      Notificaciones.notificacion("Hubo un error");
      if (archID) {
        ConsultasAPI.BorrarObjeto(URL_ARCHIVO, archID);
      }
    }
  };
  const concatCodigo = (a, b) => {
    let codigoAgencia = "";
    if (a < 10) {
      codigoAgencia = "0" + a.toString();
      if (b < 10) {
        codigoAgencia = codigoAgencia + "00" + b.toString();
      } else {
        codigoAgencia = codigoAgencia + "0" + b.toString();
      }
    } else {
      codigoAgencia = a.toString();
      if (b < 10) {
        codigoAgencia = codigoAgencia + "00" + b.toString();
      } else {
        codigoAgencia = codigoAgencia + "0" + b.toString();
      }
    }
    return codigoAgencia;
  };
  const handleCloseModalSubirArchivo = (event) => {
    setShowSubirArchivo(false);
    event.preventDefault();
    setArchivoPDF("");
    setResolucion("");
    setExpediente("");
    setFechaResolucion("");
    setFechaNotificacion("");
    setCambiar(false);
  };

  const crearResolucion = async (resolucionT) => {
    try {
      const response = await ConsultasAPI.CrearObjeto(
        URL_RESOLUCION,
        resolucionT
      );
      const resolucionId = response.data.id; // Suponiendo que la respuesta contiene el ID de la resolución creada
      setResolucionCreada(response.data.id); // Suponiendo que la respuesta contiene el ID de la resolución creada
      return resolucionId;
    } catch (error) {
      console.error("Error al crear la resolución:", error);
      throw error;
    }
  };

  const crearArchivo = async (archivo, nombreCarpeta) => {
    try {
      const formData = new FormData();
      formData.append("path", archivo.path);
      formData.append("nombre_subcarpeta", nombreCarpeta + "/resoluciones");

      const response = await ConsultasAPI.CrearObjeto(URL_ARCHIVO, formData);
      const archivoId = response.data.id; // Suponiendo que la respuesta contiene el ID del archivo creado
      setArchivoCreado(response.data.id); // Suponiendo que la respuesta contiene el ID del archivo creado
      return archivoId;
    } catch (error) {
      console.error("Error al crear el archivo:", error);
      throw error;
    }
  };

  // Función para crear el registro ArchivoResolucion
  const crearArchivoResolucion = async (resolucionId, archivoId) => {
    const archivoResolucionT = {
      resolucion: resolucionId,
      archivo: archivoId,
    };

    try {
      const response = await ConsultasAPI.CrearObjeto(
        URL_ARCHIVORESOLUCION,
        archivoResolucionT
      );
      setResolucionArchivoCreada(response.data.id);
    } catch (error) {
      console.error("Error al crear el registro ArchivoResolucion:", error);
      throw error;
    }
  };

  return (
    <Container className="mainSection">
      <Row>
        <Col>
          <h1 className="py-4 fw-bold">Reasignacion Agencias</h1>
        </Col>
      </Row>
      <Row
        className="mb-3"
        style={{ display: "flex", justifyContent: "flex-end", marginTop: "2%" }}
      >
        <Form.Group as={Col} md="3">
          <Link
            className="btn botonCPA me-3"
            onClick={() => setShowModalReasignar(true)}
            style={{ alignContent: "center", margin: 15, float: "right" }}
          >
            <AssignmentIcon style={{ marginRight: 5, marginBottom: 3 }} />{" "}
            Reasignar
          </Link>
        </Form.Group>
      </Row>
      <Card className="m-3">
        <Card.Body>
          <Row
            className="mb-3"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <Form.Group as={Col} md="3">
              <Form.Label>Municipio/Comuna</Form.Label>
              <Form.Select
                name="localidad"
                value={localidad}
                onChange={(event) => setLocalidad(event.target.value)}
              >
                <option hidden>Elegir Municipio/Comuna</option>
                {tablaLocalidad.map((localidad) => (
                  <option key={localidad.id} value={localidad.id}>
                    {localidad.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Sucursal de Rendición</Form.Label>
              <Form.Select
                name="tipoSucursal"
                value={tipoSucursal.id}
                onChange={(event) =>
                  setTipoSucursal(event.target.value)
                }
                multiple={false}
              >
                <option hidden>Elegir Sucursal de Rendicion</option>
                {tablaTipoSucursal.map((jurisdiccion) => (
                  <option key={jurisdiccion.id} value={jurisdiccion.id}>
                    {jurisdiccion.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Estado</Form.Label>
              <Form.Select
                name="estado"
                value={estado}
                onChange={(event) => setEstado(event.target.value)}
              >
                <option hidden>Elegir Estado</option>
                {tablaEstado.map((estado) => (
                  <option key={estado.id} value={estado.detalle}>
                    {estado.detalle}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Link
                className="btn botonCPA me-3"
                onClick={handleClickClear}
                style={{ alignContent: "center", margin: 15, float: "right" }}
              >
                <FaEraser style={{ marginRight: 5, marginBottom: 3 }} /> limpiar
                filtro
              </Link>
            </Form.Group>
          </Row>
        </Card.Body>
        <MaterialReactTable
          className="w-100"
          columns={columns}
          data={data}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "0",
              border: "1px dashed #e0e0e0",
            },
          }}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: darken(theme.palette.background.default, 0.1),
              },
              fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
            }),
          }}
          initialState={{
            columnVisibility: { id: false },
            showColumnFilters: true,
          }} //hide firstName column by default
          editingMode="modal" //default
          enableRowSelection={false} //enable some features
          enableColumnOrdering={false}
          enableHiding={false}
          enableColumnActions={false}
          enableSorting={false}
          displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
          // enableRowActions
          positionActionsColumn="last"
          // renderRowActions={({ row }) => (
          //   <div>
          //     {/* <Button onClick={() => { handleAsignar(row.original); }} variant="outlined" color="success">Asignar</Button> */}
          //     <Button
          //       color="secondary"
          //       variant="outlined"
          //       onClick={() => {
          //         handleAsignar(row.original);
          //       }}
          //     >
          //       Asignar
          //     </Button>
          //     {/* <IconButton onClick={() => { handleAsignar(row.original); }}>
          //                       <Edit />
          //                   </IconButton> */}
          //   </div>
          // )}
          manualPagination
          muiTablePaginationProps={{
            rowsPerPageOptions: [10],
          }}
          enablePagination={true}
          localization={MRT_Localization_ES}
          rowCount={count}
          onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
          enableGlobalFilter={false} //turn off a feature
          manualFiltering //turn off client-side filtering
          onColumnFiltersChange={(value) => {
            setColumnFilters(value);
          }} //hoist internal columnFilters state to your state
          state={{
            columnFilters,
            pagination,
          }}
        />
      </Card>
      <Card>
        <NuevaAgencia
          onClose={handleCloseModal}
          show={showModal}
          titulo={tituloModal}
          estado={estadoView}
          opcionesTitulares={opcionesTitulares}
          datosAgencia={agenciaView}
          datosTitularAgencia={titularAgenciaView}
          tipoResolucion={tipoResolucion}
          datosDomicilio={domicilioComercialView}
          recargarTabla={cargarArchivo}
        />
      </Card>
      <Row className="text-center">
        <section className="d-flex justify-content-end my-3">
          <BtnVolver />
        </section>
      </Row>
      <Modal
        show={showModalReasignar}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={handleCloseModalReasignar}>
          <Modal.Title>Reasignar</Modal.Title>
        </Modal.Header>
        <Form /** Form de la reasignacion */>
          <Modal.Body>
            <Card className="mb-3">
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Agencia {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      placeholder="Ingrese la agencia"
                      value={numeroAgencia}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Link
                      className="btn botonCPA me-3"
                      onClick={handleMostrar}
                      style={{
                        alignContent: "center",
                        marginTop: "30px",
                        float: "left",
                      }}
                    >
                      <VisibilityIcon style={{ marginRight: 5 }} /> Mostrar
                    </Link>
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Titular</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={titularMostrar}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Cuit</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={cuitMostrar}
                      readOnly={true}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Domicilio</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={domicilioMostrar}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      readOnly={true}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Municipio/Comuna</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={localidadMostrar}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Sucursal de Rendicion</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={zonaExplotacionMostrar}
                      readOnly={true}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Jurisdiccion</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={jurisdiccionMostrar}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      readOnly={true}
                    />
                  </Form.Group>
                </Row>
                <MaterialReactTable
                  className="w-100"
                  columns={columnsMostrar}
                  data={data1}
                  muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                      borderRadius: "0",
                      border: "1px dashed #e0e0e0",
                    },
                  }}
                  muiTableBodyProps={{
                    sx: (theme) => ({
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: darken(
                          theme.palette.background.default,
                          0.1
                        ),
                      },
                      fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                    }),
                  }}
                  initialState={{
                    columnVisibility: {
                      id: false,
                      telefono: false,
                      email: false,
                      estado: false,
                    },
                    showColumnFilters: true,
                  }} //hide firstName column by default
                  //   editingMode="modal" //default
                  selectAllMode="all"
                  enableRowSelection
                  getRowId={(row) => row.userId} //give each row a more useful id
                  onRowSelectionChange={setRowSelection}
                  enableColumnOrdering={false}
                  enableHiding={false}
                  enableColumnActions={false}
                  enableSorting={false}
                  // displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                  // enableRowActions
                  positionActionsColumn="last"
                  // manualPagination
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [10],
                  }}
                  enablePagination={true}
                  localization={MRT_Localization_ES}
                  rowCount={data1.length}
                  enableGlobalFilter={false} //turn off a feature
                  state={{
                    rowSelection,
                  }}
                />
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Nueva Agencia {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      placeholder="Ingrese la agencia"
                      value={numeroAgenciaNuevo}
                      onChange={(event) =>
                        setNumeroAgenciaNuevo(event.target.value)
                      }
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Link
                      onClick={handleNuevaAgencia}
                      style={{
                        alignContent: "center",
                        marginTop: "35px",
                        float: "left",
                      }}
                    >
                      <AddCircleIcon
                        sx={{ color: "black" }}
                        style={{ marginRight: 5 }}
                      />
                    </Link>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Titular</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={titularMostrar2}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Cuit</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={cuitMostrar2}
                      readOnly={true}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Domicilio</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={domicilioMostrar2}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      readOnly={true}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Municipio/Comuna</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={localidadMostrar2}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Sucursal de Rendicion</Form.Label>
                    <Form.Select
                      name="tipoSucursal"
                      value={tipoSucursal.id}
                      onChange={(event) =>
                        setTipoSucursal(event.target.value)
                      }
                      required
                      multiple={false}
                    >
                      {tablaTipoSucursal.map((jurisdiccion) => (
                        <option key={jurisdiccion.id} value={jurisdiccion.id}>
                          {jurisdiccion.nombre}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Jurisdiccion</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombreColaborador"
                      value={jurisdiccionMostrar2}
                      onChange={(event) => setNumeroAgencia(event.target.value)}
                      readOnly={true}
                    />
                  </Form.Group>
                </Row>
                {Object.keys(rowSelection).length !== 0 ? (
                  // <Form.Group as={Col} md="3">
                  <Row
                    className="mb-3"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Col>
                      {/* </Col> */}
                      {ableButtons ? (
                        cambiar ? (
                          // <Col>
                          <Link
                            className="btn botonCPA"
                            onClick={handleAgregar}
                            disabled={!cambiar}
                            style={{
                              alignContent: "center",
                              marginTop: "30px",
                              float: "right",
                            }}
                          >
                            <AddIcon /> Agregar
                          </Link>
                        ) : (
                          <Link
                            className="btn botonCPA"
                            onClick={() => setShowSubirArchivo(true)}
                            style={{
                              alignContent: "center",
                              marginTop: "30px",
                              marginLeft: 10,
                              float: "right",
                            }}
                          >
                            <AddIcon /> Cargar Resolucion
                          </Link>
                        )
                      ) : null}
                    </Col>
                  </Row>
                ) : null}
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <MaterialReactTable
                className="w-100"
                columns={columnsMostrar}
                data={data2}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    borderRadius: "0",
                    border: "1px dashed #e0e0e0",
                  },
                }}
                muiTableBodyProps={{
                  sx: (theme) => ({
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: darken(
                        theme.palette.background.default,
                        0.1
                      ),
                    },
                    fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                  }),
                }}
                initialState={{
                  columnVisibility: {
                    id: false,
                    telefono: false,
                    email: false,
                    estado: false,
                  },
                  showColumnFilters: true,
                }} //hide firstName column by default
                editingMode="row" //default
                enableRowActions={false}
                enableEditing={true}
                onEditingRowSave={handleSaveRowEdits}
                enableColumnOrdering={false}
                enableHiding={false}
                enableColumnActions={false}
                enableSorting={false}
                displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                positionActionsColumn="last"
                // manualPagination
                muiTablePaginationProps={{
                  rowsPerPageOptions: [10],
                }}
                enablePagination={true}
                localization={MRT_Localization_ES}
                enableGlobalFilter={false} //turn off a feature
              />
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn botonCPA m-3"
              onClick={handleCloseModalReasignar}
            >
              Cancelar
            </button>
            <button className="btn botonCPA m-3" onClick={handleReasignar}>
              Guardar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showSubirArchivo}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={handleCloseModalSubirArchivo}>
          <Modal.Title>Subir Archivo de Resolucion</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitArchivo} /*Form de la resolucion*/>
          <Modal.Body>
            {/* <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>Expediente {requiredSymbol}</Form.Label>

                <Form.Control
                  type="text"
                  name="expediente"
                  placeholder="Ingrese el expediente"
                  value={expediente}
                  onChange={(event) => setExpediente(event.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Resolucion {requiredSymbol}</Form.Label>

                <Form.Control
                  type="text"
                  name="resolucion"
                  placeholder="Ingrese la resolucion"
                  value={resolucion}
                  onChange={(event) => setResolucion(event.target.value)}
                  required
                />
              </Form.Group>
            </Row> */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>Fecha de Reasignacion</Form.Label>

                <Datetime
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  inputProps={{
                    placeholder: fechaNotificacion,
                    readOnly: true,
                  }}
                  value={fechaNotificacion}
                  onChange={(momentDate) => {
                    setFechaNotificacion(momentDate.format("DD/MM/YYYY"));
                    setFechaHastaAgencias(momentDate.format("DD/MM/YYYY"));
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="formResolucionPDF">
                <Form.Label>Adjunte el archivo del pliego:</Form.Label>
                <Form.Control
                  type="file"
                  accept=".pdf"
                  size="md"
                  onChange={handleArchivoPDFChange}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn botonCPA m-3"
              onClick={handleCloseModalSubirArchivo}
            >
              Cancelar
            </button>
            <button className="btn botonCPA m-3" type="submit">
              Guardar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};
export default AsignacionAgencia;
