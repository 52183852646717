import MaterialReactTable from "material-react-table";
import { useEffect, useState, useMemo } from "react";
import { Container, Modal, Form, Col, Row, Button, Spinner, Card } from "react-bootstrap";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { darken } from "@mui/material";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import moment from "moment";
import { IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DetalleDeduccion from "./DetalleDocumentacion";
import Swal from "sweetalert2";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import BtnVolver from "../../common/BtnVolver";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
const Documentacion = () => {
    const URL_TIPOSUCURSAL = window.API_ROUTES.TIPOSUCURSAL;
    const URL_ARCHIVODOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
    const [datoAgencia, setDatoAgencia] = useState();
    const [periodo, setPeriodo] = useState({ id: "", descripcion: "" });
    const [periodoDetalle, setPeriodoDetalle] = useState();
    const [tipoSucursal, setTipoSucursal] = useState([]);
    const [count, SetCount] = useState(0);
    const [pagination, setPagination] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [data, setData] = useState([]);
    const [dataDocumentacionAgencia, setDataDocumentacionAgencia] = useState([]);
    const [tablaTipo, setTablaTipo] = useState([]);
    const [tablaPeriodo, setTablaPeriodo] = useState([]);
    const [tablaRevision, setTablaRevision] = useState([]);
    const [showModalDetalle, setModalDetalle] = useState(false);
    const [dataRev, setDataRev] = useState([]);
    const [revisado, setRevisado] = useState("Todos");
    const [isLoading, setIsLoading] = useState(false)

    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50,
        },
        {
            header: "Agencia",
            accessorKey: "agencia",
            size: 50,
        },
        {
            header: "Subagencia",
            accessorKey: "subagencia",
            size: 50,
        },
        {
            header: "Titular",
            accessorKey: "titular",
            size: 50,
        },
        {
            header: "CUIT",
            accessorKey: "cuit",
            size: 50,
            enableColumnFilter: true,
        },
        {
            header: "Fecha de Carga",
            accessorKey: "fechaCarga",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Periodo",
            accessorKey: "periodo",
            size: 80,
            enableColumnFilter: false,
        },
        {
            header: "Mes Periodo",
            accessorKey: "mesPeriodo",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "En Revision Por:",
            accessorKey: "revision",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "ind",
            accessorKey: "ind",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "",
            accessorKey: "actions",
            size: 50,
            enableColumnFilter: false,
            Cell: ({ row }) => {
                if (AuthenticationHelper.getRol() !== "AUDITORIA") {


                    // Verificar si la fila superior ya ha mostrado el botón de editar
                    if (dataRev.length !== 0) {
                        if (row.original.revision) {
                            if (row.original.revision === AuthenticationHelper.getUser()) {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        <IconButton
                                            title={row.id}
                                            onClick={(event) => {
                                                handleOpenEdit(row.original, null, null, event);
                                                setDatoAgencia(row.original)

                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        {
                                            AuthenticationHelper.getRol() === "ADMINISTRADOR" || AuthenticationHelper.getRol() === "GERENCIA-QUINIELA" ?
                                                <IconButton
                                                    title={"Limpiar Revision"}
                                                    onClick={(event) => {
                                                        handleOpenClean(row.original, event);
                                                    }}
                                                >
                                                    <CleaningServicesIcon />
                                                </IconButton>
                                                : null
                                        }
                                    </div>
                                );
                            } else {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        {
                                            AuthenticationHelper.getRol() === "ADMINISTRADOR" || AuthenticationHelper.getRol() === "GERENCIA-QUINIELA" ?
                                                <IconButton
                                                    title={"Limpiar Revision"}
                                                    onClick={(event) => {
                                                        handleOpenClean(row.original, event);
                                                    }}
                                                >
                                                    <CleaningServicesIcon />
                                                </IconButton>
                                                : null
                                        }
                                    </div>
                                )
                            };
                        } else { //solo debe controlar las superiores, no todas. esto esta controlando la inmediata superior nada mas
                            //hacer un for del data desde el 0 hasta el row.index y verificar si alguno no esta en revision. si hay alguno que no este en revision que retorne null si todos estan en revision que retorne el boton de editar
                            for (let i = 0; i < row.index; i++) {
                                if (!data[i].revision || data[i].revision === AuthenticationHelper.getUser()) {
                                    return null
                                }
                            }
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <IconButton
                                        title={row.id}
                                        onClick={() => {
                                            handleOpenEdit(row.original);
                                            setDatoAgencia(row.original)
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            );
                        }
                    } else {
                        if (row.index === 0) {
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <IconButton
                                        title={row.id}
                                        onClick={() => {
                                            handleOpenEdit(row.original);
                                            setDatoAgencia(row.original)
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            );
                        } else {
                            return (null);
                        };
                    };
                } else {
                    return (null);
                }

            }
        },

    ]);

    useEffect(() => {
        cargarTablaTipo();
        cargarTablaPeriodo();
        // cargarTabla();
        cargarTablaRevisado();
    }, []);

    useEffect(() => {
        cargarTabla();
    }, [periodo, tipoSucursal, revisado]);


    const cargarTablaTipo = async () => {
        const tipos = await ConsultasAPI.ListarObjetos(URL_TIPOSUCURSAL);
        setTablaTipo(tipos.data.results);
    };

    const cargarTablaRevisado = async () => {
        const revision = [];
        revision.push({
            id: 1,
            detalle: "Todos"
        });
        revision.push({
            id: 2,
            detalle: "Sin Revisar"
        });
        revision.push({
            id: 3,
            detalle: AuthenticationHelper.getUser()
        });
        setTablaRevision(revision)
    };

    const cargarTablaPeriodo = async () => {
        const data = [];
        let periodo1 = {
            id: 1,
            descripcion: "Vencido",
        };
        data.push(periodo1);
        let periodo2 = {
            id: 2,
            descripcion: "Actual - " + moment().add(-1, 'months').format("MM/YYYY"),
        };
        data.push(periodo2);
        setTablaPeriodo(data);
    };
    const handleTablaTipoChange = (tipo) => {
        setTipoSucursal(tablaTipo.filter((x) => x.id === parseInt(tipo))[0]);
    };
    const handleTablaPeriodoChange = (periodo) => {
        setPeriodo(tablaPeriodo.filter((x) => x.id === parseInt(periodo))[0]);
    };

    const handleOpenEdit = async (row, ag, periodoS) => {
        //var agencia = "";
        var obj;
        if (row) {
            const periodo = moment("01/" + row.mesPeriodo, "DD/MM/YYYY").format("YYYY-MM-DD")
            setPeriodoDetalle(periodo)
            obj = {
                agencia: row.agencia,
                subagencia: row.subagencia,
                periodo: periodo
            };
        } else {
            setPeriodoDetalle(moment().date(1).format("YYYY-MM-DD"))
            const a = ag.split(',')
            if (periodoS) {
                obj = {
                    agencia: a[0],
                    subagencia: a[1],
                    periodo: periodoS,
                };
            } else {
                obj = {
                    agencia: a[0],
                    subagencia: a[1],
                    periodo: moment().date(1).format("YYYY-MM-DD"),
                };

            }
        }
        // if (ag) {
        //     agencia = ag;
        // } else {
        //     agencia = row.agencia + "," + row.subagencia
        // };
        const documentacionAgencia = await ConsultasAPI.CrearObjeto(URL_ARCHIVODOCUMENTACION + 'obtenerDocumentacionAgenciaPeriodo/', obj);
        setDataDocumentacionAgencia(documentacionAgencia.data);
        documentacionAgencia.data.forEach(async documentacion => {
            if ((documentacion.enRevision && !(AuthenticationHelper.getUser() === documentacion.enRevision))) {
                Swal.fire(
                    "Documentacion en revision",
                    "La documentacion esta siendo revisada por el usuario : " + documentacion.enRevision,
                    "warning"
                ).then(async () => {
                    await cargarTabla();
                    setModalDetalle(false);
                });

            } else {
                const docRev = {
                    titular_agencia: documentacion.titular_agencia,
                    fechaCarga: documentacion.fecha_creacion,
                    periodo: moment(documentacion.periodo, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    archivo: documentacion.archivo,
                    enRevision: AuthenticationHelper.getUser(),
                }
                await ConsultasAPI.ModificarObjeto(URL_ARCHIVODOCUMENTACION, documentacion.id, docRev);
            }

        });
        setModalDetalle(true);
    };

    const handleOpenClean = async (row) => {

        var obj;
        if (row) {
            const periodo = moment("01/" + row.mesPeriodo, "DD/MM/YYYY").format("YYYY-MM-DD")
            obj = {
                agencia: row.agencia,
                subagencia: row.subagencia,
                periodo: periodo
            };
            const documentacionAgencia = await ConsultasAPI.CrearObjeto(URL_ARCHIVODOCUMENTACION + 'obtenerDocumentacionAgenciaPeriodo/', obj);
            documentacionAgencia.data.forEach(async documentacion => {
                const docRev = {
                    titular_agencia: documentacion.titular_agencia,
                    fechaCarga: documentacion.fecha_creacion,
                    periodo: moment(documentacion.periodo, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    archivo: documentacion.archivo,
                    enRevision: null,
                }
                await ConsultasAPI.ModificarObjeto(URL_ARCHIVODOCUMENTACION, documentacion.id, docRev);
            })
            limpiarFiltro()
        }
    };

    const limpiarFiltro = () => {
        setPeriodo({ id: "", descripcion: "" });
    };

    const cargarTabla = async () => {
        setIsLoading(true);
        await ConsultasAPI.ListarTodos(URL_ARCHIVODOCUMENTACION + 'obtenerDocumentacion/').then((response) => {

            const dat = response.data;
            const datRev = response.data.filter((doc) => doc.revision);
            dat.forEach(doc => {
                doc.fechaCarga = moment(doc.fechaCarga,"DD/MM/YYYY HH:mm:ss").subtract(3, 'hours').format("DD/MM/YYYY HH:mm:ss")
            });
            setDataRev(datRev)
            setData(periodo.id === "" ?
                revisado === "Todos" ?
                    dat
                    :
                    revisado === "Sin Revisar" ?
                        dat.filter((x) => x.revision === "")
                        :
                        dat.filter((x) => x.revision === revisado)
                : dat.filter((x) => x.periodo === periodo.descripcion)
            );
            SetCount(dat.length)
            setIsLoading(false);
        });
    };

    const handleCloseModalDetalle = async () => {
        const obj = {
            agencia: dataDocumentacionAgencia[0].titular_detalle.agencia_detalle.numero_agencia,
            subagencia: dataDocumentacionAgencia[0].titular_detalle.agencia_detalle.numero_subagencia,
            periodo: moment(dataDocumentacionAgencia[0].periodo, 'DD/MM/YYYY').format("YYYY-MM-DD"),
        };
        const documentacionAgencia = await ConsultasAPI.CrearObjeto(URL_ARCHIVODOCUMENTACION + 'obtenerDocumentacionAgenciaPeriodo/', obj);
        const promises = documentacionAgencia.data.map(async (documentacion) => {
            const docRev = {
                titular_agencia: documentacion.titular_agencia,
                periodo: moment(documentacion.periodo, "DD/MM/YYYY").format("YYYY-MM-DD"),
                archivo: documentacion.archivo,
                fechaCarga: documentacion.fecha_creacion,
                enRevision: null,
            }
            await ConsultasAPI.ModificarObjeto(URL_ARCHIVODOCUMENTACION, documentacion.id, docRev);
        });
        await Promise.all(promises);
        setModalDetalle(false);
        await cargarTabla();
    };

    return (
        <>
            <Container fluid className="mainSection">
                <Row>
                    <Col>
                        <h1 className="py-4 fw-bold">Documentacion de Agencias</h1>
                    </Col>
                </Row>
                <Row>
                    <Card className="m-3">
                        <Card.Body>
                            <Row>
                                <Form>
                                    {AuthenticationHelper.getRol() === "ADMINISTRADOR" || AuthenticationHelper.getRol() === "GERENCIA-QUINIELA" ?
                                        <Row className="d-flex justify-content-center mb-3">
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Sucursal de Rendicion:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    required
                                                    value={tipoSucursal.id}
                                                    onChange={(event) => {
                                                        handleTablaTipoChange(event.target.value);
                                                    }}
                                                >
                                                    <option value="" hidden>Elegir Tipo</option>
                                                    {
                                                        tablaTipo.length > 0 ?
                                                            tablaTipo.map((tipo) => (
                                                                <option key={tipo.id} value={tipo.id}>
                                                                    {tipo.nombre}
                                                                </option>
                                                            ))
                                                            : null
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Row>
                                        :
                                        null
                                    }
                                    <Row className="d-flex justify-content-center mb-3">
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Periodo:</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={periodo.id}
                                                onChange={(event) => {
                                                    handleTablaPeriodoChange(event.target.value);
                                                }}
                                            >
                                                <option value="" hidden>Elegir Periodo</option>
                                                {
                                                    tablaPeriodo.length > 0 ?
                                                        tablaPeriodo.map((periodo) => (
                                                            <option key={periodo.id} value={periodo.id}>
                                                                {periodo.descripcion}
                                                            </option>
                                                        ))
                                                        : null
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Revisado Por:</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={tipoSucursal.id}
                                                onChange={(event) => {
                                                    setRevisado(event.target.value);
                                                }}
                                            >
                                                <option value="" hidden>Elegir Tipo</option>
                                                {
                                                    tablaRevision.length > 0 ?
                                                        tablaRevision.map((rivisado) => (
                                                            <option key={rivisado.id} value={rivisado.detalle}>
                                                                {rivisado.detalle}
                                                            </option>
                                                        ))
                                                        : null
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" className="d-flex justify-content-end">
                                            <Button className="botonCPA" style={{ marginTop: "30px" }} onClick={limpiarFiltro}>Limpiar Filtro</Button>
                                        </Form.Group>
                                    </Row>
                                    <Row style={{ maxHeight: '100%' }}>
                                        {data.length > 0 ?
                                            <MaterialReactTable
                                                className="w-90"
                                                columns={columns}
                                                data={data}
                                                muiTablePaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        borderRadius: "0",
                                                        border: "1px dashed #e0e0e0",
                                                    },
                                                }}
                                                muiTableBodyProps={{
                                                    sx: (theme) => ({
                                                        "& tr:nth-of-type(odd)": {
                                                            backgroundColor: darken(theme.palette.background.default, 0.1),
                                                        },
                                                        fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                                                    }),
                                                }}
                                                initialState={{
                                                    columnVisibility: { id: false, ind: false },
                                                    showColumnFilters: true,
                                                }} //hide firstName column by default
                                                editingMode="modal" //default
                                                enableRowSelection={false} //enable some features
                                                enableColumnOrdering={false}
                                                enableHiding={false}
                                                enableColumnActions={false}
                                                enableSorting={false}
                                                enableRowVirtualization
                                                enableColumnVirtualization
                                                displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                                                manualPagination
                                                muiTablePaginationProps={{
                                                    rowsPerPageOptions: [10],
                                                }}
                                                enablePagination={true}
                                                localization={MRT_Localization_ES}
                                                rowCount={count}
                                                onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                                                enableGlobalFilter={false} //turn off a feature
                                                manualFiltering //turn off client-side filtering
                                                onColumnFiltersChange={(value) => {
                                                    setColumnFilters(value);
                                                }} //hoist internal columnFilters state to your state
                                                state={{
                                                    columnFilters,
                                                    pagination,
                                                }}
                                            />
                                            :
                                            null
                                        }
                                    </Row>
                                </Form>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <BtnVolver />
                        </Card.Footer>
                    </Card >
                </Row >
                 <DetalleDeduccion
                    show={showModalDetalle}
                    data={dataDocumentacionAgencia}
                    close={handleCloseModalDetalle}
                    cargar={handleOpenEdit}
                    periodo={periodoDetalle}
                    recarga={cargarTabla}
                    datoAgencia={datoAgencia}
                />
                 <Modal
                    centered
                    show={isLoading}
                >
                    <Modal.Body className="text-center">
                        <Spinner animation="border" />
                        <p>Cargando...</p>
                    </Modal.Body>
                </Modal>
            </Container >
        </>
    );
}
export default Documentacion;
