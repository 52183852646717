import React, { useMemo, useState, useEffect, useRef } from "react";
import BtnVolver from "../../common/BtnVolver";
import Datetime from 'react-datetime';
import { Modal, Form, Container, Col, Row, Card, Spinner, Button } from "react-bootstrap";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import { Link } from "react-router-dom";
import { FaEraser, FaFileDownload, FaPlusCircle, FaList } from "react-icons/fa";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { darken } from "@mui/material";
import { IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from "moment";
import VerDeducciones from "./VerDeducciones";
import logoUrl from "../../../img/logo.png";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import CargarDeduccion from "./CargarDeduccion";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import Parser from "../../common/Parser";
const Deducciones = () => {
    const [periodoLiquidar, setPeriodoLiquidar] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });
    const URL_DEDUCCION = window.API_ROUTES.DEDUCCION;
    const URL_TIPODEDUCCION = window.API_ROUTES.TIPODEDUCCION;
    const URL_ORDENPAGO = window.API_ROUTES.ORDENPAGO;

    const dateTimeRefPeriodo = useRef(null);

    const [concepto, setConcepto] = useState({ id: "", descripcion: "" });
    const [tablaConcepto, setTablaConcepto] = useState([]);

    const handleTablaConceptoChange = (concepto) => {
        setConcepto(tablaConcepto.filter((x) => x.id === parseInt(concepto))[0]);
    };

    const [numeroDocumento, setNumeroDocumento] = useState("");

    const [data, setData] = useState([]);
    const [count, setCount] = useState();
    const [n, setN] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50
        },
        {
            header: "Agencia",
            accessorKey: "agencia",
            size: 50
        },
        {
            header: "Subagencia",
            accessorKey: "subagencia",
            size: 50
        },
        {
            header: "Titular",
            accessorKey: "titular",
            size: 50
        },
        {
            header: "Concepto",
            accessorKey: "concepto",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Periodo a liquidar",
            accessorKey: "periodoLiquidar",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Importe",
            accessorKey: "importe",
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    const [isLoading, setIsLoading] = useState(false);
    const [showModalDetalle, setShowModalDetalle] = useState(false);
    const [showModalNuevaDeduccion, setShowModalNuevaDeduccion] = useState(false);
    const [deduccionData, setDeduccionData] = useState();
    const [deduccionesData, setDeduccionesData] = useState();

    useEffect(() => {
        cargarTablaConcepto();
    }, []);

    useEffect(() => {
        cargarDeducciones();
    }, [pagination, columnFilters, periodoLiquidar, concepto, numeroDocumento]);


    const cargarTablaConcepto = async () => {
        const conceptos = await ConsultasAPI.ListarObjetos(URL_TIPODEDUCCION);
        setTablaConcepto(conceptos.data);
    };


    const handleOpenModalDetalle = async (row) => {
        const deduccion = await ConsultasAPI.ObtenerObjeto(URL_DEDUCCION, row.id);
        const deducciones = await ConsultasAPI.ObtenerObjeto(URL_DEDUCCION + 'obtenerDeduccionPorTitular/', deduccion.data.titular_detalle.titular_detalle.persona_detalle.documento + ':' + moment(deduccion.data.periodo, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        const params = deduccion.data.titular_detalle.id.toString() + '/' +moment(deduccion.data.periodo, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const orden = await ConsultasAPI.ObtenerObjeto(
            URL_ORDENPAGO + 'BusquedaTitularPeriodo/',
            params
        );
        var pagada = false;
        if (orden.status===200 && orden.data.estado_comision === "Pagada"){
            pagada=true;
        }
        const dat = [];
        deducciones.data.forEach(deduccion => {
            let d = {
                id: deduccion.id,
                concepto: deduccion.tipo_deduccion_detalle.descripcion,
                importe: deduccion.importe,
                nro_cuota: deduccion.cantidad_cuotas,
                estado: deduccion.estado,
                pagada:pagada,
            };
            dat.push(d);
        });
        setDeduccionesData(dat);
        setDeduccionData(deduccion.data);
        setShowModalDetalle(true);
    };

    const handleCloseModalDetalle = (event) => {
        event.preventDefault();
        setShowModalDetalle(false);
    };

    const cargarDeducciones = async () => {
        const deducciones = await ConsultasAPI.ListarObjetos(URL_DEDUCCION,
            pagination.pageIndex,
            pagination.pageSize,
            columnFilters,
            periodoLiquidar.fechaComparar ? moment(periodoLiquidar.fechaComparar + '-01', 'YYYY-MM').format('YYYY-MM-DD') : null,
            null,
            concepto.id,
            numeroDocumento,
        );
        const dat = [];
        setCount(deducciones.data.count)
        setN(deducciones.data.count / 10);
        deducciones.data.results.forEach(deduccion => {
            let p = {
                id: deduccion.id,
                agencia: deduccion.titular_detalle.agencia_detalle.numero_agencia,
                subagencia: deduccion.titular_detalle.agencia_detalle.numero_subagencia,
                titular: deduccion.titular_detalle.titular_detalle.persona_detalle.nombre + " " + deduccion.titular_detalle.titular_detalle.persona_detalle.apellido + "-" + deduccion.titular_detalle.titular_detalle.persona_detalle.documento,
                concepto: deduccion.tipo_deduccion_detalle.descripcion,
                periodoLiquidar: moment(deduccion.periodo, "DD/MM/YYYY").format("MM/YYYY"),
                importe: Parser.currency(deduccion.importe),
            }
            dat.push(p);
        });
        setData(dat);
    }

    const handleClickClear = () => {
        setNumeroDocumento("");
        setConcepto({ id: "", concepto: "" })
        setPeriodoLiquidar({ fechaMuestra: null, fechaComparar: null });
        dateTimeRefPeriodo.current.setState({ inputValue: "" });
    };

    const guardarHistorial = async (isExcel) => {
        let i = 0;
        const historial = [];
        do {
            try {
                const deducciones = await ConsultasAPI.ListarObjetos(URL_DEDUCCION,
                    i,
                    10,
                    columnFilters,
                    periodoLiquidar.fechaComparar ? moment(periodoLiquidar.fechaComparar + '-01', 'YYYY-MM').format('YYYY-MM-DD') : null,
                    null,
                    concepto.id,
                    numeroDocumento,
                );
                deducciones.data.results.forEach(deduccion => {
                    let p = {
                        agencia: deduccion.titular_detalle.agencia_detalle.numero_agencia,
                        subagencia: deduccion.titular_detalle.agencia_detalle.numero_subagencia,
                        titular: deduccion.titular_detalle.titular_detalle.persona_detalle.nombre + " " + deduccion.titular_detalle.titular_detalle.persona_detalle.apellido + "-" + deduccion.titular_detalle.titular_detalle.persona_detalle.documento,
                        concepto: deduccion.tipo_deduccion_detalle.descripcion,
                        periodoLiquidar: moment(deduccion.periodo, "DD/MM/YYYY").format("MM/YYYY"),
                        Importe: Parser.currency(deduccion.importe)
                    }
                    historial.push(p);
                });
                setN(deducciones.data.count / 10);
                i++;
            } catch (error) {
                console.error(error);
            }
        } while (i < n);
        i = 0;
        if (isExcel) {
            exportToExcel(historial);
        } else {
            convertToPDF(historial);
        };
    };
    const handleClickExcel = () => {
        guardarHistorial(true);
        setIsLoading(true);
    };

    const handleClickPDF = () => {
        guardarHistorial(false);
        setIsLoading(true);
    };

    //Exporta los datos del historia en formato pdf
    const exportToExcel = (historial) => {
        // Crea una nueva hoja de cálculo de Excel
        const workbook = XLSX.utils.book_new();

        // Convierte los datos JSON en una hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(historial);

        // Agrega la hoja de cálculo al libro de Excel
        XLSX.utils.book_append_sheet(workbook, worksheet, "Historico");

        // Genera un archivo de Excel
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        // Crea un blob a partir del buffer de Excel
        const excelBlob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Guarda el archivo usando file-saver
        URL.createObjectURL(excelBlob);
        historial = [];
        saveAs(excelBlob, "deducciones.xlsx");
        setIsLoading(false);
    };

    //Exporta los datos del historial para transformarlo en pdf
    function convertToPDF(historial) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        const historialOrdenado = historial.sort((a, b) => {
            const fechaA = new Date(a.fecha_sorteo);
            const fechaB = new Date(b.fecha_sorteo);
            return fechaA - fechaB;
        });

        const headers = Object.keys(historialOrdenado[0]); // Obtener las propiedades del primer objeto del JSON como encabezados de la tabla

        const body = historial.map((data) => Object.values(data)); // Obtener los valores de cada objeto del JSON

        const table = {
            headerRows: 1,
            widths: headers.map(() => "auto"),
            body: [
                headers, // Encabezados de la tabla
                ...body, // Datos del JSON
            ],
        };
        const imageUrl = logoUrl; // Replace with the actual image URL or file path
        const imageSize = [200, 100];

        const documentDefinition = {
            pageOrientation: "landscape",
            content: [
                {
                    alignment: "center", // Center-align the container
                    margin: [0, 50], // Add top margin to push it down a bit
                    image: imageUrl,
                    width: imageSize[0],
                    height: imageSize[1],
                },
                { text: "Deducciones", style: "header" },
                {
                    table: table,
                },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 5],
                },
            },
        };
        historial = [];
        pdfMake
            .createPdf(documentDefinition)
            .download("deducciones.pdf");
        setIsLoading(false);
    };

    const handleOpenNuevaDeduccion = async (event) => {
        event.preventDefault();
        setShowModalNuevaDeduccion(true);
    }

    const handleCloseNuevaDeduccion = async (event) => {
        event.preventDefault();
        setShowModalNuevaDeduccion(false);
    }

    return (
        <Container fluid className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Deducciones</h1>
                </Col>
                <Col className="d-flex justify-content-end m-3">
                    {AuthenticationHelper.getRol() === "AUDITORIA" ?
                        null
                        :
                        <Link
                            className="btn botonCPA me-3"
                            to={window.location + '/archivos'}
                            style={{ alignContent: 'center', margin: 15, float: 'right' }}
                        >
                            Archivos
                        </Link>
                    }
                    {
                        AuthenticationHelper.getRol() !== "OFICINA-OPERADOR" && AuthenticationHelper.getRol() !== "DOCUMENTACION"  ?
                            <Link
                                className="btn botonCPA me-3"
                                to={window.location + '/OrdenesDePago'}
                                style={{ alignContent: 'center', margin: 15, float: 'right' }}
                            >
                                Ordenes de pago
                            </Link>
                            : null
                    }
                    {
                        AuthenticationHelper.getRol() === "AUDITORIA" ?
                            null
                            :
                            <Button
                                className="btn botonCPA me-3"
                                style={{ alignContent: 'center', margin: 15, float: 'right' }}
                                onClick={handleOpenNuevaDeduccion}
                            >
                                Cargar Deduccion
                            </Button>
                    }
                </Col>
            </Row>
            <Card className="m-3">
                <Card.Body>
                    <Row
                        className="mb-3"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "2%",
                        }}
                    >
                        <Form.Group as={Col} md="3">
                            <Form.Label>Documento:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingrese numero de Documento"
                                onKeyPress={(event) => {
                                    // Solo permitir números
                                    const regex = /[0-9]/;
                                    const key = String.fromCharCode(event.charCode);
                                    if (!regex.test(key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={numeroDocumento}
                                onChange={(event) => setNumeroDocumento(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <CalendarMonthIcon style={{ marginBottom: '6' }} />
                            <Form.Label>Periodo a liquidar:</Form.Label>
                            <Datetime
                                timeFormat={false}
                                dateFormat="MM/YYYY"
                                inputProps={{
                                    placeholder: periodoLiquidar.fechaMuestra,
                                    readOnly: true,
                                }}
                                value={null}
                                ref={dateTimeRefPeriodo}
                                onChange={(momentDate) => {
                                    const fechaMuestra = momentDate.format("MM/YYYY");
                                    const fechaComparar = momentDate.format("YYYY-MM");
                                    setPeriodoLiquidar({
                                        fechaMuestra: fechaMuestra,
                                        fechaComparar: fechaComparar,
                                    });
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" >
                            <Form.Label> Concepto:</Form.Label>
                            <Form.Select
                                value={concepto.id}
                                onChange={(event) => {
                                    handleTablaConceptoChange(event.target.value);
                                }}
                            >
                                <option hidden>Elegir Concepto</option>
                                {
                                    tablaConcepto.length > 0 ?
                                        tablaConcepto.map((concepto) => (
                                            <option key={concepto.id} value={concepto.id}>
                                                {concepto.descripcion}
                                            </option>
                                        ))
                                        : null
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="2" >
                            <Link
                                className="btn botonCPA me-3"
                                onClick={handleClickClear}
                                style={{ alignContent: "center", margin: 15, float: "right", marginTop: "15%" }}
                            >
                                <FaEraser className="iconAntD" /> Limpiar
                            </Link>
                        </Form.Group>
                    </Row>
                </Card.Body>
                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={data}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: "0",
                            border: "1px dashed #e0e0e0",
                        },
                    }}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            "& tr:nth-of-type(odd)": {
                                backgroundColor: darken(theme.palette.background.default, 0.1),
                            },
                            fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                        }),
                    }}
                    initialState={{
                        columnVisibility: { id: false },
                        showColumnFilters: true,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableSorting={false}
                    displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                        <div className="d-flex">
                            <IconButton
                                onClick={() => {
                                    handleOpenModalDetalle(row.original);
                                }}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </div>
                    )}
                    manualPagination
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    localization={MRT_Localization_ES}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                    manualFiltering //turn off client-side filtering
                    onColumnFiltersChange={(value) => {
                        setColumnFilters(value);
                    }} //hoist internal columnFilters state to your state
                    state={{
                        columnFilters,
                        pagination,
                    }}
                />
            </Card>
            <Row className="text-center">
                <section className="d-flex justify-content-end my-3">
                    <Link className="btn botonCPA me-3" onClick={handleClickExcel}>
                        <FaFileDownload className="iconAntD" />
                        Descargar Excel
                    </Link>
                    <Link className="btn botonCPA-PDF me-3" onClick={handleClickPDF}>
                        <FaFileDownload className="iconAntD" />
                        Descargar PDF
                    </Link>
                    <BtnVolver route="/" />
                </section>
            </Row>
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Descargando Archivo...</p>
                </Modal.Body>
            </Modal>
            <VerDeducciones
                show={showModalDetalle}
                close={handleCloseModalDetalle}
                dataDeduccion={deduccionData}
                dataDeducciones={deduccionesData}
            />
            <CargarDeduccion
                show={showModalNuevaDeduccion}
                close={handleCloseNuevaDeduccion}
                recargar={cargarDeducciones}
            />
        </Container >
    );
};

export default Deducciones;
