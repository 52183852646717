import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';


export const generarPDFsLineaCaja = (dataImpresion) => {
    return new Promise((resolve) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const img = new Image();
        img.src = require('../../../img/logo.png'); // Utiliza la ruta de importación directa

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const dataURL = canvas.toDataURL('image/png');
            const fechaFacturacion = moment().format('DD/MM/YYYY');

            const documentDefinition = {
                // pageOrientation: "landscape",
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 5, 0, 5],
                    },
                    text: {
                        fontSize: 12,
                        bold: false,
                        // margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                    },
                    tableExample2: {
                        margin: [92, 0, 0, 0],
                        alignment: 'center',
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black',
                    },
                },
                pageMargins: [40, 60, 40, 60],
                images: {
                    sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                },
                background: [
                    {
                        absolutePosition: { x: 40, y: 40 }, // Coordenadas x, y para la imagen
                        image: 'sampleImage', // Referencia a la imagen definida en 'images'
                        color: 'white',
                        width: 150.6, // Cambia el ancho de la imagen
                        height: 88.8, // Cambia la altura de la imagen
                    },
                ],
                defaultStyle: {
                    // alignment: 'justify'
                },
            };

            var body = dataImpresion.deducciones.map((dataImpresion) => Object.values(dataImpresion));
            var body2 = dataImpresion.dateRed.map((dataImpresion) => Object.values(dataImpresion));

            documentDefinition.content.push(
                {
                    text: 'Orden De Pago',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    text: 'Orden N°' + dataImpresion.numero_orden,
                    style: 'subheader',
                    alignment: 'right',
                },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        widths: [530],
                        body: [[{ text: '', style: 'tableHeader' }], ['']],
                    },
                    layout: 'headerLineOnly',
                },

                {
                    alignment: 'center',
                    columns: [
                        {
                            text: '\nFecha de Emision: ' + fechaFacturacion,
                        },
                        {
                            text: '\nProveedor: ' + dataImpresion.titular,
                        },
                    ],
                },
                {
                    alignment: 'center',
                    columns: [
                        {
                            text: '\nFecha de Pago: ' + fechaFacturacion,
                        },
                        {
                            text: '\nN° de Agencia: ' + dataImpresion.codigo,
                        },
                    ],
                },
                {
                    alignment: 'center',
                    columns: [
                        {
                            text: '\nConcepto: Pago de Comisiones',
                        },
                        {
                            text: '\nCUIT Proveedor: ' + dataImpresion.cuit,
                        },
                    ],
                },

                {
                    text: '\nComprobante de origen',
                    style: 'subheader',
                    alignment: 'left',
                },

                {
                    style: 'tableExample',
                    alignment: 'center',
                    table: {
                        widths: [100, 100, 100, 100, 100],
                        body: [
                            [
                                {
                                    text: 'Fecha de Factura',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Nro Factura',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Tipo  Factura',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Comision',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Importe',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                            ],
                            [
                                dataImpresion.factura.datos_json.fechaFactura,
                                dataImpresion.factura.datos_json.nroFactura,
                                dataImpresion.factura.datos_json.tipoFactura,
                                moment(dataImpresion.factura.periodo, 'DD/MM/YYYY')
                                    .format('MMMM YYYY')
                                    .charAt(0)
                                    .toUpperCase() +
                                moment(
                                    dataImpresion.factura.periodo,
                                    'DD/MM/YYYY'
                                )
                                    .format('MMMM YYYY')
                                    .slice(1),
                                dataImpresion.comisionBruta.toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                            ],
                        ],
                    },
                },

                {
                    headerRows: 1,
                    style: 'tableExample',
                    alignment: 'center',
                    table: {
                        widths: [171, 171, 171],
                        body: [
                            [
                                {
                                    text: 'Detalle Deducciones',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Nro Cuota',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Importe',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                            ],
                            ...body,
                        ],
                    },
                },
                {
                    headerRows: 1,
                    style: 'tableExample2',
                    alignment: 'center',
                    table: {
                        widths: [171, 171, 171],
                        body: [
                            [
                                {
                                    text: 'Detalle Retencion',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Importe',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                            ],
                            ...body2,
                        ],
                    },
                },

                {
                    text:
                        '\nSubtotal:' +
                        dataImpresion.subTotal.toLocaleString('es-AR', {
                            style: 'currency',
                            currency: 'ARS',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                    style: 'subheader',
                    alignment: 'right',
                },

                {
                    text:
                        '\nImporte Neto:' +
                        (dataImpresion.comisionBruta - dataImpresion.subTotal).toLocaleString(
                            'es-AR',
                            {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }
                        ),
                    style: 'subheader',
                    alignment: 'left',
                },

                {
                    style: 'tableExample',
                    alignment: 'center',

                    table: {
                        widths: [250, 250],
                        headerRows: 2,
                        body: [
                            [
                                {
                                    text: 'Medio de Pago',
                                    style: 'tableHeader',
                                    colSpan: 2,
                                    alignment: 'center',
                                },
                                {},
                            ],
                            [
                                {
                                    text: 'Nro de Cheque',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Nro de Cuenta',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                },
                            ],
                            [' ', ' '],
                        ],
                    },
                },
                {
                    text: '\nAutorizado Por:                                                           Firma:',
                    style: 'text',
                    alignment: 'left',
                },
                { text: '', pageBreak: 'after' } // Salto de página
            );

            const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
            pdfDocGenerator.getBlob((blob) => {
                resolve(blob);
            });
        };
    })
}
