import ConsultasAPI from '../../../shared/helpers/consultasAPI.jsx';
import { useEffect, useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { Card, Button, Modal, Spinner } from 'react-bootstrap';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';

const GenerarOrdenDePago = (props) => {
    const URL_ARCHIVO_DOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
    const URL_RETENCION = window.API_ROUTES.RETENCION;
    const URL_DEDUCCION = window.API_ROUTES.DEDUCCION;

    const [facturaElectronica, setFacturaElectronica] = useState('');
    const [certificadoTem, setCertificadoTem] = useState('');
    const [totalRetenciones, setTotalRetenciones] = useState(0);
    const [tablaRetenciones, setTablaRetenciones] = useState([]);
    const [tablaDeduccionesImpuestos, setTablaDeduccionesImpuestos] = useState(
        []
    );
    const [importeImpuestos, setImporteImpuestos] = useState(0);
    const [allowDownload, setAllowDownload] = useState(false);
    const [isLoadingView, setIsLoadingView] = useState(false);

    useEffect(() => {
        if (props.titularAgencia && props.ordenDePago && props.periodo) {
            obtenerDatos(
                props.titularAgencia,
                props.periodo,
                props.ordenDePago
            );
        } else {
            allowDownload(false);
        }
    }, [props.ordenDePago]);

    const obtenerDatos = async (agencia, date, ordenPago) => {
        //traigo las deducciones del periodo
        let paramDeducciones = agencia.id + ':' + date.format('YYYY-MM-DD');
        const deduccionesDatos = await ConsultasAPI.ObtenerObjeto(
            URL_DEDUCCION + 'obtenerDeduccionPorTitularOP/',
            paramDeducciones
        );
        postDataDeducciones(deduccionesDatos);
        //traigo la factura electronica del periodo
        let paramFactura =
            agencia.agencia_detalle.id + ':' + date.format('YYYY-MM-DD');
        const facturaDatos = await ConsultasAPI.ObtenerObjeto(
            URL_ARCHIVO_DOCUMENTACION + 'obtenerFacturaAgencia/',
            paramFactura
        );
        if (facturaDatos.status === 200) {
            setFacturaElectronica(facturaDatos.data);
        }

        //traigo la constancia de triuto municipal
        let paramTem =
            agencia.agencia_detalle.id + ':' + date.format('YYYY-MM-DD');
        const temDatos = await ConsultasAPI.ObtenerObjeto(
            URL_ARCHIVO_DOCUMENTACION + 'obtenerTemAgencia/',
            paramTem
        );
        if (temDatos.status === 200) {
            setCertificadoTem(temDatos.data);
        }
        //traigo las retenciones del periodo
        const retencionesDatos = await ConsultasAPI.ObtenerObjeto(
            URL_RETENCION + 'busquedaRetencionOP/',
            ordenPago.id
        );
        if (retencionesDatos.status === 200) {
            postDataReteneciones(retencionesDatos);
        } else {
            setTablaRetenciones([]);
        }
        setAllowDownload(true);
    };

    const postDataDeducciones = (deduccionesDatos) => {
        let dataDeduccionesImpuestos = [];
        let impuestos = 0;
        deduccionesDatos.data.forEach((deduccion) => {
            let deduccionImpuestoItem = {
                concepto: deduccion.tipo_deduccion_detalle.descripcion,
                nroCuotaPlan: '-',
                importe: deduccion.importe.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            };
            dataDeduccionesImpuestos.push(deduccionImpuestoItem);

            impuestos += deduccion.importe;
        });

        setTablaDeduccionesImpuestos(dataDeduccionesImpuestos);
        setImporteImpuestos(impuestos);
    };

    const postDataReteneciones = (retencionesDatos) => {
        let tablaretenciones = [];
        let datosRetenciones = {
            afip: retencionesDatos.data.retencion_ganancias,
            iva: retencionesDatos.data.retencion_iva,
            jurisdiccion: retencionesDatos.data.retencion_municipal,
            iibb:
                retencionesDatos.data.retencion_iibb_quiniela +
                retencionesDatos.data.retencion_iibb_tuqui,
        };
        setTotalRetenciones(
            retencionesDatos.data.retencion_ganancias +
                retencionesDatos.data.retencion_iva +
                retencionesDatos.data.retencion_municipal +
                retencionesDatos.data.retencion_iibb_quiniela +
                retencionesDatos.data.retencion_iibb_tuqui
        );

        tablaretenciones.push({
            concepto: 'Retencion GCIAS',
            nroCuotaPlan: '-',
            importe: datosRetenciones['afip'].toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        });

        tablaretenciones.push({
            concepto: 'Retencion IVA',
            nroCuotaPlan: '-',
            importe: datosRetenciones['iva'].toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        });

        tablaretenciones.push({
            concepto: 'Ret. Municipal',
            nroCuotaPlan: '-',
            importe: datosRetenciones['jurisdiccion'].toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        });

        tablaretenciones.push({
            concepto: 'Retencion IIBB',
            nroCuotaPlan: '-',
            importe: datosRetenciones['iibb'].toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        });
        setTablaRetenciones(tablaretenciones);
    };

    const generarPdf = (event) => {
        event.preventDefault();
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const img = new Image();
        img.src = require('../../../img/logo.png'); // Utiliza la ruta de importación directa

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const dataURL = canvas.toDataURL('image/png');
            const fechaFacturacion = moment().format('DD/MM/YYYY');
            var body = [];
            var body2 = [];
            var bodys = [];
            var sumaImporte = 0;
            var sumaImporteDed = 0;
            if (
                tablaDeduccionesImpuestos.length !== 0 &&
                tablaRetenciones.length !== 0
            ) {
                body = tablaDeduccionesImpuestos.map((fila) =>
                    Object.values(fila)
                );
                body2 = tablaRetenciones.map((fila) => Object.values(fila));
                bodys = [...body, ...body2];
                sumaImporte = bodys.reduce((total, row) => {
                    var importe = 0;
                    if (row[2].split('-').length > 1) {
                        importe = parseFloat(
                            row[2]
                                .replace(/[^\d.,]+/g, '')
                                .replace('.', '')
                                .replace(',', '.')
                        );
                        return total - importe;
                    } else {
                        importe = parseFloat(
                            row[2]
                                .replace(/[^\d.,]+/g, '')
                                .replace('.', '')
                                .replace(',', '.')
                        );
                        return total + importe;
                    }
                }, 0);

                sumaImporteDed = bodys.reduce((total, row) => {
                    var importe = 0;
                    if (row[2].split('-').length > 1) {
                        importe = parseFloat(
                            row[2]
                                .replace(/[^\d.,]+/g, '')
                                .replace('.', '')
                                .replace(',', '.')
                        );
                        return total - importe;
                    } else {
                        importe = parseFloat(
                            row[2]
                                .replace(/[^\d.,]+/g, '')
                                .replace('.', '')
                                .replace(',', '.')
                        );
                        return total + importe;
                    }
                }, 0);

                const documentDefinition = {
                    content: [
                        {
                            text: 'Caja Popular de Ahorro',
                            style: 'header',
                            alignment: 'center',
                        },
                        {
                            text: 'Orden De Pago',
                            style: 'subheader',
                            alignment: 'center',
                        },
                        {
                            text: 'Orden N°' + props.ordenDePago.numero_orden,
                            style: 'subheader',
                            alignment: 'right',
                        },
                        {
                            style: 'tableExample',
                            table: {
                                headerRows: 1,
                                widths: [530],
                                body: [
                                    [{ text: '', style: 'tableHeader' }],
                                    [''],
                                ],
                            },
                            layout: 'headerLineOnly',
                        },

                        {
                            alignment: 'center',
                            columns: [
                                {
                                    text:
                                        '\nFecha de Emision: ' +
                                        fechaFacturacion,
                                },
                                {
                                    text:
                                        '\nProveedor: ' +
                                        props.titularAgencia.titular_detalle
                                            .persona_detalle.apellido +
                                        ' ' +
                                        props.titularAgencia.titular_detalle
                                            .persona_detalle.nombre,
                                },
                            ],
                        },
                        {
                            alignment: 'center',
                            columns: [
                                {
                                    text:
                                        '\nFecha de Pago: ' + fechaFacturacion,
                                },
                                {
                                    text:
                                        '\nN° de Agencia: ' +
                                        props.titularAgencia.agencia_detalle.numero_agencia
                                            .toString()
                                            .padStart(2, '0') +
                                        '-' +
                                        props.titularAgencia.agencia_detalle.numero_subagencia
                                            .toString()
                                            .padStart(3, '0'),
                                },
                            ],
                        },
                        {
                            alignment: 'center',
                            columns: [
                                {
                                    text: '\nConcepto: Pago de Comisiones',
                                },
                                {
                                    text:
                                        '\nCUIT Proveedor: ' +
                                        props.titularAgencia.titular_detalle
                                            .persona_detalle.cuit,
                                },
                            ],
                        },

                        {
                            text: '\nComprobante de origen',
                            style: 'subheader',
                            alignment: 'left',
                        },

                        {
                            style: 'tableExample',
                            alignment: 'center',
                            table: {
                                widths: [100, 100, 100, 100, 100],
                                body: [
                                    [
                                        {
                                            text: 'Fecha de Factura',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Nro Factura',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Tipo  Factura',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Comision',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Importe',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                    ],
                                    [
                                        facturaElectronica.datos_json
                                            .fechaFactura,
                                        facturaElectronica.datos_json
                                            .nroFactura,
                                        facturaElectronica.datos_json
                                            .tipoFactura,
                                        moment(
                                            facturaElectronica.periodo,
                                            'DD/MM/YYYY'
                                        )
                                            .format('MMMM YYYY')
                                            .charAt(0)
                                            .toUpperCase() +
                                            moment(
                                                facturaElectronica.periodo,
                                                'DD/MM/YYYY'
                                            )
                                                .format('MMMM YYYY')
                                                .slice(1),
                                        props.comisionBruta.toLocaleString(
                                            'es-AR',
                                            {
                                                style: 'currency',
                                                currency: 'ARS',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        ),
                                    ],
                                ],
                            },
                        },

                        {
                            headerRows: 1,
                            style: 'tableExample',
                            alignment: 'center',
                            table: {
                                widths: [171, 171, 171],
                                body: [
                                    [
                                        {
                                            text: 'Detalle Deducciones',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Nro Certificado',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Importe',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                    ],
                                    ...bodys,
                                ],
                            },
                        },

                        {
                            text:
                                '\nSubtotal:' +
                                sumaImporte.toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                            style: 'subheader',
                            alignment: 'right',
                        },

                        {
                            text:
                                '\nImporte Neto:' +
                                (
                                    props.comisionBruta - sumaImporte
                                ).toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                            style: 'subheader',
                            alignment: 'left',
                        },

                        {
                            style: 'tableExample',
                            alignment: 'center',

                            table: {
                                widths: [250, 250],
                                headerRows: 2,
                                body: [
                                    [
                                        {
                                            text: 'Medio de Pago',
                                            style: 'tableHeader',
                                            colSpan: 2,
                                            alignment: 'center',
                                        },
                                        {},
                                    ],
                                    [
                                        {
                                            text: 'Nro de Cheque',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Nro de Cuenta',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                    ],
                                    // ...body,
                                ],
                            },
                        },
                        {
                            text: '\nAutorizado Por:',
                            style: 'text',
                            alignment: 'left',
                        },
                        {
                            text: '\nFirma:',
                            style: 'text',
                            alignment: 'center',
                        },
                    ],
                    styles: {
                        header: {
                            fontSize: 18,
                            bold: true,
                            margin: [0, 0, 0, 10],
                        },
                        subheader: {
                            fontSize: 16,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },
                        text: {
                            fontSize: 12,
                            bold: false,
                            // margin: [0, 10, 0, 5]
                        },
                        tableExample: {
                            margin: [0, 5, 0, 15],
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 13,
                            color: 'black',
                        },
                    },
                    images: {
                        sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                    },
                    pageMargins: [40, 60, 40, 60],
                    background: [
                        {
                            absolutePosition: { x: 40, y: 40 }, // Coordenadas x, y para la imagen
                            image: 'sampleImage', // Referencia a la imagen definida en 'images'
                            color: 'white',
                            width: 150.6, // Cambia el ancho de la imagen
                            height: 88.8, // Cambia la altura de la imagen
                        },
                    ],
                    defaultStyle: {
                        // alignment: 'justify'
                    },
                };
                pdfMake
                    .createPdf(documentDefinition)
                    .download(
                        'Orden De Pago-' +
                            props.titularAgencia.titular_detalle.persona_detalle.apellido.trim() +
                            ' ' +
                            props.titularAgencia.titular_detalle.persona_detalle.nombre.trim() +
                            '.pdf'
                    );
            }
        };
    };

    return (
        <>
            <Card
                className="m-2 p-3 d-flex justify-content-center"
                id="ComponenteFijo"
                style={{ width: '97%' }}
            >
                <Card.Title>Orden de Pago</Card.Title>
                <div
                    className="justify-content-between"
                    style={{
                        display: 'flex',
                        paddingBottom: '15px',
                        paddingTop: '10px',
                        flexDirection: 'row',
                    }}
                >
                    <Card.Text
                        style={{
                            fontSize: '0.75rem',
                            fontWeight: 'bold',
                            width: 'fit-content',
                        }}
                    >
                        PDF con los datos de la Orden de Pago del periodo
                    </Card.Text>
                    {allowDownload ? (
                        <Button
                            className="btn botonCPA"
                            style={{
                                width: 'fit-content',
                                justifyContent: 'center',
                            }}
                            onClick={(event) => {
                                generarPdf(event);
                            }}
                        >
                            <BsFillFileEarmarkPdfFill className="mb-1" />
                            <strong>Descargar</strong>
                        </Button>
                    ) : (
                        <Button
                            className="btn botonCPA"
                            style={{
                                width: 'fit-content',
                                justifyContent: 'center',
                                backgroundColor: 'grey',
                                borderColor: 'grey',
                                cursor: 'default',
                            }}
                            onClick={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <BsFillFileEarmarkPdfFill className="mb-1" />
                            <strong>Descargar</strong>
                        </Button>
                    )}
                </div>
            </Card>
        </>
    );
};

export default GenerarOrdenDePago;
