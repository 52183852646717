import React, { useMemo, useState, useEffect } from "react";
import BtnVolver from "../../common/BtnVolver";
import Datetime from 'react-datetime';
import { Modal, Form, Container, Col, Row, Card, Spinner } from "react-bootstrap";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { darken } from "@mui/material";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import moment from "moment";
import Swal from "sweetalert2";
import Papa from 'papaparse';
import { saveAs } from "file-saver";

const NuevoArchivo = () => {
    const URL_TIPODEDUCCION = window.API_ROUTES.TIPODEDUCCION;
    const URL_DEDUCCION = window.API_ROUTES.DEDUCCION
    const URL_TITULARAGENCIA = window.API_ROUTES.TITULARAGENCIA
    const URL_ARCHIVO = window.API_ROUTES.ARCHIVO;

    var currentDate = moment().utcOffset("-03:00").format("YYYY-MM-DD");
    const [periodoLiquidar, setPeriodoLiquidar] = useState({
        fechaMuestra: moment().add(-1, 'months').format("MM/YYYY"),
        fechaComparar: moment().add(-1, 'months').format("YYYY-MM"),
    });
    const [isLoadingView] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [concepto, setConcepto] = useState({ descripcion: '' });
    const [tablaConcepto, setTablaConcepto] = useState([]);


    const [archivoPDF, setArchivoPDF] = useState(null);
    const [csvData, setCsvData] = useState([]);

    const [data, setData] = useState([]);
    const [dataConcepto, setDataConcepto] = useState([]);
    const [archivosNombres, setArchivosNombres] = useState([]);
    const [countTabla, setCountTabla] = useState(0);
    const [montoTabla, setMontoTabla] = useState(0);
    const [count] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFilters, setColumnFilters] = useState([]);

    const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
    const requiredSymbol = <b style={{ color: "red" }}>*</b>;

    const columns = useMemo(() => [
        // {
        //     header: "id",
        //     accessorKey: "id",
        //     size: 50
        // },
        {
            header: "Agencia",
            accessorKey: "agencia",
            size: 50
        },
        {
            header: "Titular",
            accessorKey: "titular",
            size: 50
        },
        {
            header: "Documento",
            accessorKey: "dni",
            size: 50
        },
        {
            header: "Concepto",
            accessorKey: "concepto",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Periodo",
            accessorKey: "periodo",
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    const columnsConfirmar = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50
        },
        {
            header: "Agencia",
            accessorKey: "agencia",
            size: 50
        },
        {
            header: "Nombre y Apellido",
            accessorKey: "nombreYapellido",
            size: 100
        },
        {
            header: "DNI",
            accessorKey: "dni",
            size: 50
        },
        {
            header: "Concepto",
            accessorKey: "conceptoDesc",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Importe",
            accessorKey: "importe",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Nro Cuota Plan de pago",
            accessorKey: "numero_cuota",
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    useEffect(() => {
        cargarTablaConcepto();
    }, []);

    const cargarTablaConcepto = async () => {
        const conceptos = await ConsultasAPI.ListarObjetos(URL_TIPODEDUCCION);
        let results = conceptos.data
        results = results.filter(concepto => concepto.descripcion !== "Automatizacion" && concepto.descripcion !== "Ticket")
        setTablaConcepto(results)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // crearDeduccion();
        procesar();
    };
    const handleSubmitConfirmar = async (event) => {
        event.preventDefault();
        crearDeduccion();
        // procesar();
    };

    const handleOpenModalConfirmar = () => {
        setShowModalConfirmacion(true)
    };
    const handleCloseConfirmacion = (event) => {
        event.preventDefault();
        setShowModalConfirmacion(false);
    };

    const handleTablaConceptoChange = (concepto) => {
        setConcepto(tablaConcepto.filter((x) => x.id === parseInt(concepto))[0]);
    };

    const handleArchivoCSVChange = (event) => {
        const nombres = [...archivosNombres];
        if (event.target.files[0].type !== 'text/csv') {
            Swal.fire(
                'Formato de Archivo No Deseado',
                'El archivo tiene que tener formato .CSV',
                'error'
            );
            event.target.value = null;
        } else {
            const nombre = event.target.files[0].name;
            const archivo = {
                path: event.target.files[0],
            };
            nombres.push(nombre);
            Papa.parse(event.target.files[0], {
                complete: (result) => {
                    // El resultado contiene los datos analizados del CSV en result.data
                    const data = result.data;
                    if (data.length > 0 && data[0]) {
                        // Convertir los encabezados a minúsculas
                        const csvData = data.map(row => {
                            const newRow = {};
                            Object.keys(row).forEach(header => {
                                newRow[header.toLowerCase().replace(/\s/g, '')] = row[header];
                            });
                            return newRow;
                        });
                        setCsvData(csvData);
                    } else {
                        // No se encontraron datos
                        Swal.fire('Archivo Vacío', 'El archivo CSV está vacío.', 'error');
                        event.target.value = null;
                    };
                },
                header: true, // Si el CSV tiene encabezados de columna
            });
            setArchivoPDF(archivo);
            setArchivosNombres(nombres);
        };
    };
    const descargarTxt = (vector) => {
        // Convertir el array en una cadena de texto, separando los elementos por un salto de línea
        const contenido = vector.join('\n');

        // Crear un Blob con el contenido
        const blob = new Blob([contenido], { type: 'text/plain' });

        // Crear una URL para el Blob
        const url = URL.createObjectURL(blob);

        // Crear un enlace y simular un clic para descargar el archivo
        const link = document.createElement('a');
        link.href = url;
        link.download = 'deducciones.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Liberar la URL del Blob
        URL.revokeObjectURL(url);
    };
    const crearDeduccion = async () => {
        Swal.fire({
            title: "¿Estás seguro de procesar el archivo?",
            text: "Esta acción no se puede deshacer",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#008185",
            cancelButtonColor: "#EC1B23",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const archivo = await ConsultasAPI.CrearObjetoArchivo(URL_ARCHIVO, archivoPDF);
                    if (archivo.request.status === 201) {
                        const Deducciones = csvData;
                        const objeto = {
                            periodo: moment(periodoLiquidar.fechaComparar + '-01', 'YYYY-MM').format('YYYY-MM-DD'),
                            concepto: concepto.id,
                            deducciones: dataConcepto,
                            archivo: archivo.data.id,
                        }
                        const creacion = await ConsultasAPI.CrearObjeto(URL_DEDUCCION + 'crearDeduccionCompleta/', objeto);
                        if (creacion.status === 201) {
                            const Carga = await cargarDeduccionesAgencieros(csvData);
                            setShowModalConfirmacion(false);
                            if (creacion.data.length > 1) {
                                Swal.fire(
                                    {
                                        title: "Subida exitosa",
                                        text: "Se subieron algunas de las Deducciones",
                                        icon: "info",
                                        confirmButtonColor: "#008185",
                                        footer: 'Se descargara un archivo con las deducciones que no se subieron'
                                    }).then(() => {
                                        descargarTxt(creacion.data);
                                    })
                            } else {
                                Swal.fire(
                                    {
                                        title: "Creacion exitosa",
                                        text: "Se creo con Exito la Deduccion",
                                        icon: "success",
                                        confirmButtonColor: "#008185"
                                    }
                                ).then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.reload();
                                    };
                                });
                            }
                        } else {
                            Swal.fire(
                                {
                                    title: "Creacion erronea",
                                    text: "No se pudo crear la Deducción",
                                    icon: "error",
                                    confirmButtonColor: "#EC1B23"
                                }
                            );
                        };
                    };
                } catch (error) {
                    console.error(error);
                    Swal.fire(
                        {
                            title: "Creacion erronea",
                            text: "No se pudo crear la Deducción",
                            icon: "error",
                            confirmButtonColor: "#EC1B23"
                        }
                    );
                };
            };
        });
    };

    const procesar = async () => {
        Swal.fire({
            title: "¿Estás seguro de procesar el archivo?",
            text: "Esta acción no se puede deshacer",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#008185",
            cancelButtonColor: "#EC1B23",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const carga = await cargarDeduccionesAgencieros(csvData);
                    if (carga) {
                        Swal.fire(
                            'No existe el Titular Agencia',
                            "No existe el titular con dni: " + carga,
                            'error'
                        )
                    } else {
                        Swal.fire(
                            {
                                title: "Proceso exitoso",
                                text: "Se procesó con éxito el archivo",
                                icon: "success",
                                confirmButtonColor: "#008185"
                            }
                        );
                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire(
                        {
                            title: "Proceso erroneo",
                            text: "No se pudo procesar el archivo",
                            icon: "error",
                            confirmButtonColor: "#EC1B23"
                        }
                    );
                };
            };
        });
    };

    const cargarDeduccionesAgencieros = async (info) => {
        const pp = [...data];
        const dc = [...dataConcepto];
        var monto = montoTabla
        setIsLoading(true);
        for (const titular of info) {
            if (titular.dni) {
                try {
                    const dataTitular = await ConsultasAPI.ObtenerObjeto(URL_TITULARAGENCIA + 'busquedaPorDocumento/', titular.dni);
                    if (dataTitular.status === 204) {
                        setDataConcepto(dc);
                        setData(pp);
                        setIsLoading(false);
                        return (titular.dni);
                    } else {
                        const per = {
                            id: dataTitular.data.id,
                            agencia: dataTitular.data.agencia_detalle ? dataTitular.data.agencia_detalle.numero_agencia + '0' + dataTitular.data.agencia_detalle.numero_subagencia : '-',
                            titular: dataTitular.data.titular_detalle.persona_detalle.nombre + " " + dataTitular.data.titular_detalle.persona_detalle.apellido,
                            dni: dataTitular.data.titular_detalle.persona_detalle.documento,
                        };
                        const conf = {
                            id: dataTitular.data.id,
                            agencia: dataTitular.data.agencia_detalle ? dataTitular.data.agencia_detalle.numero_agencia + '0' + dataTitular.data.agencia_detalle.numero_subagencia : '-',
                            nombreYapellido: dataTitular.data.titular_detalle.persona_detalle.nombre + " " + dataTitular.data.titular_detalle.persona_detalle.apellido,
                            dni: dataTitular.data.titular_detalle.persona_detalle.documento,
                            conceptoDesc: concepto.descripcion,
                            conceptoid: concepto.id,
                            periodo: moment(periodoLiquidar.fechaComparar + '/01', 'YYYY/MM').format('YYYY-MM-DD'),
                            importe: titular.importecuota ? titular.importecuota : titular.importe.replace(',', '.'),
                            numero_cuota: titular.cuota ? titular.cuota : "",
                            cantidad_cuotas: titular.nrocuotasplandepago ? parseInt(titular.nrocuotasplandepago) : 0,
                        };
                        const importe = titular.importecuota ? parseFloat(titular.importecuota) : parseFloat(titular.importe);
                        monto = monto + importe;
                        dc.push(conf);
                        const existeDato = pp.some(element => element.dni === per.dni);
                        if (!existeDato) {
                            pp.push(per);
                        };
                    };
                } catch (error) {
                    console.error(error);
                };
            };
        };
        setCountTabla(pp.length)
        setIsLoading(false)
        setDataConcepto(dc);
        setData(pp);
        setMontoTabla(monto)
        // return pp;
    };

    const DescargarCSV = (event) => {
        event.preventDefault();

        let csvContent = [];
        let nombreCSV = ''

        if (concepto.descripcion === "Multas" || concepto.descripcion === "Producto de mantenimiento de equipos") {
            csvContent = ["Apellido", "Nombre", "DNI", "Importe Cuota", "Nro Cuotas Plan de Pago"];
            nombreCSV = 'multas_periodo.csv'
        } else {
            csvContent = ["Apellido", "Nombre", "DNI", "Importe"];
            nombreCSV = 'concepto_periodo.csv'
        }


        csvContent = csvContent.join(';') + '\n'

        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        saveAs(csvBlob, nombreCSV)
    }


    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Nuevo Archivo</h1>
                </Col>
            </Row>
            <Card className="m-3">
                <Form onSubmit={handleSubmit} >
                    <Card.Body>
                        <Row
                            className="mb-3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2%",
                            }}
                        >
                            <Form.Group as={Col} md="3">
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    value={moment(currentDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <CalendarMonthIcon style={{ marginBottom: '6' }} />
                                <Form.Label>Periodo a Liquidar {requiredSymbol}</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat="MM/YYYY"
                                    inputProps={{
                                        required: true,
                                        placeholder: periodoLiquidar.fechaMuestra,
                                        readOnly: true,
                                    }}
                                    value={periodoLiquidar.fechaMuestra}
                                    closeOnSelect={true}
                                    onChange={(momentDate) => {
                                        const fechaMuestra = momentDate.format("MM/YYYY");
                                        const fechaComparar = momentDate.format("YYYY-MM");
                                        setPeriodoLiquidar({
                                            fechaMuestra: fechaMuestra,
                                            fechaComparar: fechaComparar,
                                        });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Concepto {requiredSymbol}</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={concepto.id}
                                    onChange={(event) => {
                                        handleTablaConceptoChange(event.target.value);
                                    }}
                                >
                                    <option value="" hidden>Elegir Concepto</option>
                                    {
                                        tablaConcepto.length > 0 ?
                                            tablaConcepto.map((concepto) => (
                                                <option key={concepto.id} value={concepto.id}>
                                                    {concepto.descripcion}
                                                </option>
                                            ))
                                            : null
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="3" style={{ display: 'flex', justifyContent: 'center' }}>
                                <button onClick={DescargarCSV} className="btn botonCPA m-3">FORMATO CSV</button>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2%",
                            }}>
                            <Form.Group controlId="formResolucionPDF">
                                <Form.Label>Subir archivo:</Form.Label>
                                <Form.Control
                                    required
                                    type="file"
                                    accept=".csv"
                                    size="md"
                                    onChange={handleArchivoCSVChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2%",
                            }}>
                            <Form.Group as={Col} md="2">
                                <button className="btn botonCPA m-3" type="submit">
                                    Procesar
                                </button>
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Form>
                <Card className="m-0">
                    <Card.Title>Listado Deducciones Agencieros</Card.Title>
                    <MaterialReactTable
                        className="w-100"
                        columns={columns}
                        data={data}
                        muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: "0",
                                border: "1px dashed #e0e0e0",
                            },
                        }}
                        muiTableBodyProps={{
                            sx: (theme) => ({
                                "& tr:nth-of-type(odd)": {
                                    backgroundColor: darken(theme.palette.background.default, 0.1),
                                },
                                fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                            }),
                        }}
                        initialState={{
                            columnVisibility: { id: false, concepto: false, periodo: false },
                            showColumnFilters: true,
                        }} //hide firstName column by default
                        editingMode="modal" //default
                        enableRowSelection={false} //enable some features
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableColumnActions={false}
                        enableSorting={false}
                        displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                        positionActionsColumn="last"
                        renderRowActions={() => (
                            <div className="d-flex">
                                <IconButton
                                // onClick={() => {
                                //     handleOpenEditModal(row.original);
                                // }}
                                >
                                    <Edit />
                                </IconButton>
                            </div>
                        )}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10],
                        }}
                        enablePagination={true}

                        localization={MRT_Localization_ES}
                        rowCount={countTabla}
                        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                        enableGlobalFilter={false} //turn off a feature
                        onColumnFiltersChange={(value) => {
                            setColumnFilters(value);
                        }} //hoist internal columnFilters state to your state
                        state={{
                            columnFilters,
                            pagination,
                        }}
                    />
                    {
                        countTabla !== 0 ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <a style={{ marginRight: '1%' }}>Total de elementos en el archivo: {countTabla}</a>
                                <a>Monto Total del Archivo:{montoTabla}</a>
                            </div>
                            :
                            null
                    }
                </Card>
                <Row className="text-center">
                    <section className="d-flex justify-content-center my-3" >
                        <BtnVolver />
                        <button className="btn botonCPA" style={{ marginLeft: "20%" }} onClick={handleOpenModalConfirmar}>
                            Aceptar
                        </button>
                    </section>
                </Row>
            </Card>
            <Modal
                show={showModalConfirmacion}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton
                    onClick={handleCloseConfirmacion}
                >
                    <Modal.Title>Confirmar Deduccion</Modal.Title>
                </Modal.Header>
                <Form
                    onSubmit={handleSubmitConfirmar}
                >
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group >
                                <Form.Label>Listado deducciones Agencieros:</Form.Label>
                                <MaterialReactTable
                                    className="w-100"
                                    columns={columnsConfirmar}
                                    data={dataConcepto}
                                    muiTablePaperProps={{
                                        elevation: 0,
                                        sx: {
                                            borderRadius: "0",
                                            border: "1px dashed #e0e0e0",
                                        },
                                    }}
                                    muiTableBodyProps={{
                                        sx: (theme) => ({
                                            "& tr:nth-of-type(odd)": {
                                                backgroundColor: darken(theme.palette.background.default, 0.1),
                                            },
                                            fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                                        }),
                                    }}
                                    initialState={{
                                        columnVisibility: { id: false },
                                        showColumnFilters: true,
                                    }} //hide firstName column by default
                                    editingMode="modal" //default
                                    enableRowSelection={false} //enable some features
                                    enableColumnOrdering={false}
                                    enableHiding={false}
                                    enableColumnActions={false}
                                    enableSorting={false}
                                    displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                                    muiTablePaginationProps={{
                                        rowsPerPageOptions: [10],
                                    }}
                                    enablePagination={true}
                                    localization={MRT_Localization_ES}
                                    rowCount={count}
                                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                                    enableGlobalFilter={false} //turn off a feature
                                    manualFiltering //turn off client-side filtering
                                    onColumnFiltersChange={(value) => {
                                        setColumnFilters(value);
                                    }} //hoist internal columnFilters state to your state
                                    state={{
                                        columnFilters,
                                        pagination,
                                    }}
                                />
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                            className="btn botonCPA m-3"
                            onClick={handleCloseConfirmacion}
                        >
                            Cancelar
                        </button>
                        <button className="btn botonCPA m-3" type="submit">
                            Confirmar
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal
                centered
                show={isLoading || isLoadingView}
            >
                <Modal.Body className="text-center">
                    <Spinner animation="grow" />
                    <p>Procesando Archivo...</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default NuevoArchivo;
