import React, { useMemo, useState, useEffect, useRef } from "react";
import BtnVolver from "../../common/BtnVolver";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  InputGroup,
  Card,
  Form,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FaEraser, FaPlus, FaFileDownload, FaPlusCircle } from "react-icons/fa";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { IconButton } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { ModalTitular } from "./ModalTitular";
import { darken } from "@mui/material";
import logoUrl from "../../../img/logo.png";

var historial = [];
const FormItem = Form.Item;
const Titulares = (props) => {
  const rolUser = props.rolUsuario;
  const [data, setData] = useState([]);
  const URL_TITULAR = window.API_ROUTES.TITULAR;
  const URL_DOMICILIOPARTICULAR = window.API_ROUTES.DOMICILIOPARTICULAR;
  const URL_CONDICIONFISCALTITULAR = window.API_ROUTES.CONDICIONFISCALTITULAR;

  const [fechaDesde, setFechaDesde] = useState({
    fechaMuestra: null,
    fechaComparar: null,
  });
  const [fechaHasta, setFechaHasta] = useState({
    fechaMuestra: null,
    fechaComparar: null,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [tablaEstado, setTablaEstado] = useState([]);
  const [estado, setEstado] = useState("");
  const datetimeRefHasta = useRef(null);
  const datetimeRefDesde = useRef(null);
  const [count, setCount] = useState();
  const [n, setN] = useState();
  const [cantLocalidades] = useState();
  const [titularview, setTitularView] = useState({});
  const [domicilioview, setDomicilioView] = useState({});
  const [condicionview, setcondicionView] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [tituloModal, setTituloModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(false);

  // Definir el estado que indica si se debe mostrar o no el modal
  const columns = useMemo(() => [
    {
      header: "id",
      accessorKey: "id",
      size: 50,
    },
    {
      header: "Nombre",
      accessorKey: "nombre",
      size: 50,
    },
    {
      header: "Cuit",
      accessorKey: "cuit",
      size: 50,
    },
    {
      header: "Documento",
      accessorKey: "documento",
      size: 50,
    },
    {
      header: "Email",
      accessorKey: "email",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Agencia a Cargo",
      accessorKey: "titularAgencia",
      size: 50,
    },
    {
      header: "Estado",
      accessorKey: "estado",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "N° Cuenta",
      accessorKey: "cuenta",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Creado Por",
      accessorKey: "creado_por",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Creado En",
      accessorKey: "fecha_creacion",
      size: 50,
      enableColumnFilter: false,
    },
  ]);

  useEffect(() => {
    cargarArchivo();
  }, [pagination, columnFilters, fechaDesde, fechaHasta, estado]);

  useEffect(() => {
    cargarTablaEstado();
  }, []);

  const cargarTablaEstado = () => {
    let estados = [];
    estados.push({
      id: 1,
      detalle: "Activo",
      estado: true,
    });
    estados.push({
      id: 2,
      detalle: "De Baja",
      estado: false,
    });
    setTablaEstado(estados);
  };
  //Trae los datos desde el back para mostrar en la tabla del front
  const cargarArchivo = () => {
    try {
      ConsultasAPI.ListarObjetos(
        URL_TITULAR,
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        fechaDesde.fechaMuestra,
        fechaHasta.fechaMuestra,
        estado
      ).then((response) => {
        setCount(response.data.count);
        setN(response.data.count / 10);
        let titulares = response.data.results;
        let datos = [];
        if (titulares) {
          titulares.forEach((titular) => {
            if (titular.agencia) {
              titular.agencia.numero_agencia = String(
                titular.agencia.numero_agencia
              ).padStart(2, "0");
              if (titular.agencia.numero_subagencia) {
                titular.agencia.numero_subagencia = String(
                  titular.agencia.numero_subagencia
                ).padStart(3, "0");
              } else {
                titular.agencia.numero_subagencia = String("").padStart(3, "0");
              }
              titular.agencia =
                titular.agencia.numero_agencia +
                titular.agencia.numero_subagencia;
            }
            datos.push({
              id: titular.id,
              nombre:
                titular.persona_detalle.apellido +
                " " +
                titular.persona_detalle.nombre,
              creado_por: titular.usuario,
              cuit: titular.persona_detalle.cuit,
              email: titular.persona_detalle.email,
              documento: titular.persona_detalle.documento,
              titularAgencia: titular.TitularAgencia && !titular.TitularAgencia.fecha_hasta ?
                titular.agencia
                :
                "No posee Agencia a cargo"
              ,
              estado: titular.estado
                ? "Activo"
                : "Dado de Baja",
              fecha_creacion: titular.fecha_creacion.split(" ")[0],
              cuenta: titular.persona_detalle.nro_cuenta ? titular.persona_detalle.nro_cuenta
                : "-",
            });
          });
        } else {
          console.info("vacio");
        }
        datos.sort((a, b) => b.id - a.id);
        setData(datos);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickExcel = () => {
    guardarHistorial(true);
    setIsLoading(true);
  };
  const handleClickPDF = () => {
    guardarHistorial(false);
    setIsLoading(true);
  };

  //Espera que historial tenga datos, para poder exportarlo a excel y/o pdf
  async function esperarArrayDatos(array, isExcel) {
    while (array.length === 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Espera 1 segundo antes de volver a verificar
    }
    if (isExcel) {
      exportToExcel(array);
    } else {
      convertToPDF(array);
    }
  }

  //Trae todos los extractos del back y los guarda en historial
  const guardarHistorial = async (isExcel) => {
    let i = 0;
    historial = [];
    do {
      try {
        // eslint-disable-next-line no-loop-func
        await ConsultasAPI.ListarObjetos(
          URL_TITULAR,
          i,
          10,
          columnFilters,
          fechaDesde.fechaMuestra,
          fechaHasta.fechaMuestra,
          estado
        ).then((response) => {
          let titulares = response.data.results;
          titulares.forEach((titular) => {
            if (titular.agencia) {
              titular.agencia.numero_agencia = String(
                titular.agencia.numero_agencia
              ).padStart(2, "0");
              if (titular.agencia.numero_subagencia) {
                titular.agencia.numero_subagencia = String(
                  titular.agencia.numero_subagencia
                ).padStart(3, "0");
              } else {
                titular.agencia.numero_subagencia = String("").padStart(3, "0");
              }
              titular.agencia.numero_agencia =
                titular.agencia.numero_agencia +
                titular.agencia.numero_subagencia;
            }
            let tit = {
              nombre: titular.persona_detalle.nombre,
              apellido: titular.persona_detalle.apellido,
              cuit: titular.persona_detalle.cuit,
              documento: titular.persona_detalle.documento,
              email: titular.persona_detalle.email,
              titularAgencia: titular.agencia
                ? titular.agencia.numero_agencia
                : "",
              fecha_desde: titular.agencia
                ? titular.agencia.fecha_creacion.split(" ")[0]
                : "",
            };
            historial.push(tit);
          });
          setN(response.data.count / 10);
        });
        i++;
      } catch (error) {
        console.error(error);
      }
    } while (i < n);
    i = 0;
    esperarArrayDatos(historial, isExcel);
  };

  //Exporta los datos del historia en formato pdf
  const exportToExcel = (historial) => {

    // Crea una nueva hoja de cálculo de Excel
    const workbook = XLSX.utils.book_new();

    // Convierte los datos JSON en una hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(historial);

    // Agrega la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(workbook, worksheet, "Historico");

    // Genera un archivo de Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Crea un blob a partir del buffer de Excel
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Guarda el archivo usando file-saver
    URL.createObjectURL(excelBlob);
    historial = [];
    saveAs(excelBlob, "historico_de_titulares.xlsx");
    setIsLoading(false);
  };

  //Exporta los datos del historial para transformarlo en pdf
  function convertToPDF() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const historialOrdenado = historial.sort((a, b) => {
      const fechaA = new Date(a.fecha_sorteo);
      const fechaB = new Date(b.fecha_sorteo);
      return fechaA - fechaB;
    });

    const headers = Object.keys(historialOrdenado[0]); // Obtener las propiedades del primer objeto del JSON como encabezados de la tabla

    const body = historial.map((data) => Object.values(data)); // Obtener los valores de cada objeto del JSON

    const table = {
      headerRows: 1,
      widths: headers.map(() => "auto"),
      body: [
        headers, // Encabezados de la tabla
        ...body, // Datos del JSON
      ],
    };

    const imageUrl = logoUrl; // Replace with the actual image URL or file path
    const imageSize = [200, 100];

    const documentDefinition = {
      pageOrientation: "landscape",
      content: [
        {
          alignment: "center", // Center-align the container
          margin: [0, 50], // Add top margin to push it down a bit
          image: imageUrl,
          width: imageSize[0],
          height: imageSize[1],
        },
        { text: "Registro de Titulares", style: "header" },
        { text: "Titulares:", style: "subheader" },
        {
          table: table,
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5],
        },
      },
    };
    historial = [];
    pdfMake
      .createPdf(documentDefinition)
      .download("historico_de_titulares.pdf");
    setIsLoading(false);
  }

  const handleFechaDesdeChange = (momentDate) => {
    const fechaMuestra = momentDate.format("DD/MM/YYYY");
    const fechaComparar = momentDate.format("YYYY-MM-DD");
    setFechaDesde({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
  };

  const handleFechaHastaChange = (momentDate) => {
    const fechaMuestra = momentDate.format("DD/MM/YYYY");
    const fechaComparar = momentDate.format("YYYY-MM-DD");
    setFechaHasta({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
  };

  const handleTablaEstadolChange = (estado) => {
    setEstado(tablaEstado.filter((x) => x.id === parseInt(estado))[0].estado);
  };

  const handleClickClear = () => {
    setEstado({ estado: " " });
    setFechaDesde({ fechaMuestra: null, fechaComparar: null });
    setFechaHasta({ fechaMuestra: null, fechaComparar: null });
    datetimeRefHasta.current.setState({ inputValue: "" });
    datetimeRefDesde.current.setState({ inputValue: "" });
    setColumnFilters([]);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setTituloModal("Nuevo");
    setShowModal(true);
  };
  const handleOpenEditModal = async (row) => {
    setIsLoadingView(true);
    const response = await ConsultasAPI.ObtenerObjeto(URL_TITULAR, row.id);
    setTitularView(response.data);
    try {
      const response2 = await ConsultasAPI.ObtenerObjeto(
        URL_DOMICILIOPARTICULAR + "busqueda/",
        response.data.id
      );
      setDomicilioView(response2.data);
    } catch (error) {
      console.error(error)
    }

    const response3 = await ConsultasAPI.ObtenerObjeto(
      URL_CONDICIONFISCALTITULAR + "busqueda/",
      response.data.id
    );
    setcondicionView(response3.data);
    setTituloModal("Editar");
    setIsLoadingView(false);
    setShowModal(true);
  };

  const handleOpenViewModal = async (row) => {
    setIsLoadingView(true);
    const response = await ConsultasAPI.ObtenerObjeto(URL_TITULAR, row.id);
    setTitularView(response.data);
    const response2 = await ConsultasAPI.ObtenerObjeto(
      URL_DOMICILIOPARTICULAR + "busqueda/",
      response.data.id
    );
    setDomicilioView(response2.data);
    const response3 = await ConsultasAPI.ObtenerObjeto(
      URL_CONDICIONFISCALTITULAR + "busqueda/",
      response.data.id
    );
    setcondicionView(response3.data);
    setTituloModal("Ver");
    setIsLoadingView(false);
    setShowModal(true);
  };

  return (
    <Container fluid className="mainSection">
      <Row>
        <Col>
          <h1 className="py-4 fw-bold">Titulares</h1>
        </Col>
        <Col className="d-flex justify-content-end m-3 ">
          {(AuthenticationHelper.getRol() &&
            (rolUser === "DOCUMENTACION" || rolUser === "GERENCIA-QUINIELA" || rolUser === "CONTADURIA")) ||
            rolUser === "ADMINISTRADOR" ? (
            <Link
              className="btn botonCPA me-3"
              onClick={handleOpenModal}
              style={{ alignContent: "center", margin: 15, float: "right" }}
            >
              <FaPlusCircle className="iconAntD" />
              Nuevo
            </Link>
          ) : null}
        </Col>
      </Row>
      <Card className="m-3">
        <Card.Body>
          <Row
            className="mb-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Form.Group as={Col} md="2">
              <Form.Label>Fecha Desde</Form.Label>
              <Datetime
                timeFormat={false}
                style={{ width: "100%", height: "32px" }}
                dateFormat="DD/MM/YYYY"
                inputProps={{
                  readOnly: true,
                  placeholder: "Elegir fecha",
                }}
                ref={datetimeRefDesde}
                value={null}
                onChange={handleFechaDesdeChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label>Fecha Hasta</Form.Label>
              <Datetime
                timeFormat={false}
                style={{ width: "100%", height: "32px" }}
                dateFormat="DD/MM/YYYY"
                updateOnView=""
                inputProps={{
                  readOnly: true,
                  placeholder: "Elegir fecha",
                }}
                ref={datetimeRefHasta}
                value={null}
                onChange={handleFechaHastaChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label>Elegir Estado</Form.Label>
              <Form.Select
                value={estado.estado}
                onChange={(event) => {
                  handleTablaEstadolChange(event.target.value);
                }}
              >
                <option hidden>Elegir Estado</option>
                {tablaEstado.length > 0
                  ? tablaEstado.map((estado) => (
                    <option key={estado.id} value={estado.id}>
                      {estado.detalle}
                    </option>
                  ))
                  : null}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Link
                className="btn botonCPA me-3"
                onClick={handleClickClear}
                style={{ alignContent: "center", margin: 15, float: "right" }}
              >
                <FaEraser className="iconAntD" />
                Limpiar Filtro
              </Link>
            </Form.Group>
          </Row>
        </Card.Body>
        <MaterialReactTable
          className="w-100"
          columns={columns}
          data={data}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "0",
              border: "1px dashed #e0e0e0",
            },
          }}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: darken(theme.palette.background.default, 0.1),
              },
              fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
            }),
          }}
          initialState={{
            columnVisibility: { id: false },
            showColumnFilters: true,
          }} //hide firstName column by default
          editingMode="modal" //default
          enableRowSelection={false} //enable some features
          enableColumnOrdering={false}
          enableHiding={false}
          enableColumnActions={false}
          enableSorting={false}
          displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ row }) => {
            return (
              <div>
                <div>
                  <IconButton
                    onClick={() => {
                      handleOpenViewModal(row.original);
                    }}
                  >
                    <Visibility />
                  </IconButton>
                  {AuthenticationHelper.getRol() &&
                    (rolUser === "ADMINISTRADOR" ||
                      rolUser === "DOCUMENTACION" ||
                      rolUser === "GERENCIA-QUINIELA" ||
                      rolUser === 'CONTADURIA') && (
                      <IconButton
                        onClick={() => {
                          handleOpenEditModal(row.original);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                </div>
              </div>
            );
          }}
          manualPagination
          muiTablePaginationProps={{
            rowsPerPageOptions: [10],
          }}
          enablePagination={true}
          localization={MRT_Localization_ES}
          rowCount={count}
          onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
          enableGlobalFilter={false} //turn off a feature
          manualFiltering //turn off client-side filtering
          onColumnFiltersChange={(value) => {
            setColumnFilters(value);
          }} //hoist internal columnFilters state to your state
          state={{
            columnFilters,
            pagination,
          }}
        />
      </Card>
      <ModalTitular
        onClose={handleCloseModal}
        recargarTabla={cargarArchivo}
        show={showModal}
        titulo={tituloModal}
        cantLocalidades={cantLocalidades}
        datosTitular={tituloModal !== "Nuevo" ? titularview : null}
        datosDomicilio={tituloModal !== "Nuevo" ? domicilioview : null}
        datosCondicion={tituloModal !== "Nuevo" ? condicionview : null}
      />
      <Row className="text-center">
        <section className="d-flex justify-content-end my-3">
          <Link className="btn botonCPA me-3" onClick={handleClickExcel}>
            <FaFileDownload className="iconAntD" />
            Descargar Excel
          </Link>
          <Link className="btn botonCPA-PDF me-3" onClick={handleClickPDF}>
            <FaFileDownload className="iconAntD" />
            Descargar PDF
          </Link>
          <BtnVolver />
        </section>
      </Row>
      <Modal
        centered
        show={isLoading || isLoadingView}
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          {isLoading ? (
            <p>Descargando Archivo...</p>
          ) : isLoadingView ? (
            <p>Cargando Datos...</p>
          ) : null}
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default Titulares;
