import React, { useState } from "react";
import { Col, Row, Card, Form, Modal, Container } from "react-bootstrap";
import Swal from "sweetalert2";
import "react-datetime/css/react-datetime.css";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import Select from 'react-select'
import Notificaciones from '../../../shared/helpers/notificacionesToast';

const FormItem = Form.Item;
const { Option } = Select;

export function ModalDarBajaDocumentacion(props) {
 const URL_ARCHIVODOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
const [razon, setRazon] = useState("");
 const requiredSymbol = props.titulo !== "Ver" ? <b style={{ color: "red" }}>*</b> : "";
const DarBaja = async (event) => {
    event.preventDefault()
    if(razon.length===0){
      Notificaciones.notificacion("Debe indicar el motivo");
      return
    }
    Swal.fire({
      title: "Estás seguro?",
      title: "¿Estás seguro de dar de baja la documentación seleccionada?",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#008185",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancelar",
    }).then(async(result) => {

      if (result.isConfirmed) {
         var data={
            id:props.documentacion.id,
            estado:false,
            motivo:razon
        }

        try {
            const response = await ConsultasAPI.CrearObjeto(URL_ARCHIVODOCUMENTACION+'dar_baja_doc/', data);
            if (response.status === 200) {
                Swal.fire({
                    title: "Se realizo con exito la operación",
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                      handleClose(event);
                            }
                  });
            } else {
                Swal.fire({
                    title: "Ocurrio un error y No se realizo la operacion",
                    icon: "error"
                });
            }
        } catch (error) {
          console.info(error)
            Swal.fire({
                title: "Ocurrio un error y No se realizo la operacion",
                icon: "error"
            });
        }
 }
                        });
        }


  const handleClose = (event) => {
    event.preventDefault();
    setRazon("");
    props.onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (props.titulo === "Nuevo") {
      DarBaja(event);
    }
      };

const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
      const formControls = event.target.form.elements;
      const currentIndex = Array.from(formControls).indexOf(event.target);

      // Encuentra el siguiente campo y enfócalo
      const nextControl = formControls[currentIndex + 1];
      if (nextControl) {
        nextControl.focus();
      }
    }
  };


  return (
    <Container>
      <Modal show={props.show} size="lg" centered>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Motivo de baja de la documentación</Modal.Title>
        </Modal.Header>
        <Form onKeyDown={handleFormKeyDown} style={{ width: "100%" }}>
          <Modal.Body style={{ width: "100%" }}>
            <Card className="m-3">
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12">
                    <Form.Label>Motivo {requiredSymbol}</Form.Label>
                    <Form.Control
                    as="textarea" // Establecer el tipo de control como textarea
                    rows={6} // Establecer el número de filas
                      type="text"
                      inputMode="text"
                      readOnly={props.titulo === "Ver" ? true : false}
                      placeholder={"Ingrese el motivo de baja"}
                      value={razon}
                      onChange={(event) => setRazon(event.target.value)}
                      required
                  />
                  </Form.Group>
                  </Row>
                 </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn botonCPA m-3" onClick={handleClose}>
              {'Cancelar'}
            </button>
            {<button className="btn botonCPA m-3" onClick={handleSubmit}>
                Guardar
              </button>}
          </Modal.Footer>
        </Form>
        </Modal>
       </Container>
  );
}
