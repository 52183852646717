import React, { useRef, useState } from "react";
import moment from "moment";
import Table from "react-bootstrap/Table";
// import { toPng } from 'html-to-image';

const TablasExtractos = (props) => {
    const tableRef = useRef(null);
    const [imageSrc] = useState('');
    const components = props.datos;

    const formatNumberWithComma = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    // const handleGenerateImage = () => {
    //     if (tableRef.current === null) {
    //         return;
    //     }
    //     toPng(tableRef.current)
    //         .then((dataUrl) => {
    //             setImageSrc(dataUrl);  // Set the generated image source
    //         })
    //         .catch((err) => {
    //             console.error('Error al generar la imagen', err);
    //         });
    // }

    return (
        <div style={{ marginTop: '1%', marginLeft: '1%', marginRight: '1%' }}>
            {!imageSrc ? (
                <div ref={tableRef}>
                    <Table className="tabla-cpa" bordered responsive>
                        <tbody>
                            <tr>
                                <td
                                    colSpan={2}

                                    style={{
                                        fontSize: "170px",
                                        textAlign: "center",
                                        fontFamily: "Roboto",
                                        fontWeight: "bold",
                                        backgroundColor: "#008185",
                                        color: "#ffffff",
                                        border: "4px solid #008185",
                                        marginTop: "0px",
                                        paddingTop: "0px"
                                    }}
                                >
                                    Sorteo {components[0].tipo.tipo}

                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={2}
                                    style={{
                                        fontSize: "110px",
                                        textAlign: "center",
                                        fontFamily: "Roboto",
                                        fontWeight: "bold",
                                        backgroundColor: "#008185",
                                        color: "#ffffff",
                                        border: "4px solid #008185",
                                        marginTop: "0px",
                                        paddingTop: "0px"
                                    }}
                                >
                                    N° {formatNumberWithComma(components[0].sorteo)}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={2}
                                    style={{
                                        fontSize: "70px",
                                        textAlign: "center",
                                        fontFamily: "Roboto",
                                        backgroundColor: "#008185",
                                        color: "#ffffff",
                                        border: "4px solid #008185",
                                        marginBottom: "0px",
                                        paddingBottom: "0px"
                                    }}
                                >
                                    Realizado el{" "}
                                    {moment(components[0].fecha, "DD/MM/YYYY").format("dddd").charAt(0).toUpperCase() + moment(components[0].fecha, "DD/MM/YYYY").format("dddd").slice(1)}{" "}
                                    {moment(components[0].fecha, "DD/MM/YYYY").format("DD")} de{" "}
                                    {moment(components[0].fecha, "DD/MM/YYYY").format("MMMM").charAt(0).toUpperCase() + moment(components[0].fecha, "DD/MM/YYYY").format("MMMM").slice(1)} de{" "}
                                    {moment(components[0].fecha, "DD/MM/YYYY").format("YYYY")}

                                </td>
                            </tr>
                            {components.map((record, index) => (

                                index === 0 ?
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#d8d8d8', border: '4px solid #008185', height: '130px' }}>
                                        <td style={{ borderRightWidth: "5px" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center" }}>
                                                <span className="fw-bold" style={{ color: "#58595B", fontSize: "80px" }}>{record.numPremio1_10}</span>
                                                <span className="fw-bolder" style={{ color: "#EC1B23",fontSize: "200px" }}>{record.numero1_10}</span>
                                            </div>
                                        </td>
                                        <td style={{ borderLeftWidth: "5px" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                <span className="fw-bold" style={{ color: "#58595B", fontSize: "80px" }}>{record.numPremio11_20}</span>
                                                <span className="fw-bolder" style={{ fontSize: "200px" }}>{record.numero11_20}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#d8d8d8', border: '4px solid #008185', height: '130px' }}>
                                        <td style={{ borderRightWidth: "5px" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center" }}>
                                                <span className="fw-bold" style={{ color: "#58595B", fontSize: "80px" }}>{record.numPremio1_10}</span>
                                                <span className="fw-bolder" style={{ fontSize: "200px" }}>{record.numero1_10}</span>
                                            </div>
                                        </td>
                                        <td style={{ borderLeftWidth: "5px" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                <span className="fw-bold" style={{ color: "#58595B", fontSize: "80px" }}>{record.numPremio11_20}</span>
                                                <span className="fw-bolder" style={{ fontSize: "200px" }}>{record.numero11_20}</span>
                                            </div>
                                        </td>
                                    </tr>


                            ))}
                        </tbody>
                    </Table>
                </div>
            ) : (
                <img src={imageSrc} alt="Tabla generada" style={{
                    width: '120%',
                    height: '200%',
                    objectFit: 'cover',
                    transform: 'rotate(-90deg)', // Rotar 45 grados
                    marginLeft: '30%'
                }} />
            )}
            {/* {!imageSrc && <button onClick={handleGenerateImage}>Generar imagen</button>} */}
        </div>
    );
};

export default TablasExtractos;
