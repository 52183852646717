import MaterialReactTable from "material-react-table";
import { useState, useMemo, useEffect, useRef } from "react";
import { Container, Modal, Row, Form, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { darken } from "@mui/material";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { IconButton } from "@mui/material";
import { Visibility, AddBox, PictureAsPdf } from "@mui/icons-material";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import { set } from "react-hook-form";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Notificaciones from "../../../shared/helpers/notificacionesToast";

const Novedades = (props) => {
    const [count, setCount] = useState(0);
    const [dataResoluciones, setDataResoluciones] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [fecha, setFecha] = useState(props.fecha)
    const [AllowButton, setAllowButton] = useState(false)
    const datetimeRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const URL_RESOLUCION = window.API_ROUTES.RESOLUCION;
    const URL_ARCHIVORESOLUCION = window.API_ROUTES.ARCHIVORESOLUCION;


    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50,
        },
        {
            header: "Expediente",
            accessorKey: "expediente",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Resolucion",
            accessorKey: "resolucion",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Fecha de Resolucion",
            accessorKey: "fechaResolucion",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Fecha de Notificacion",
            accessorKey: "fechaNotificacion",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Motivo",
            accessorKey: "motivo",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Titular",
            accessorKey: "titular",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Archivo",
            accessorKey: "archivo",
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    useEffect(() => {
        cargarResoluciones(fecha);
    }, [fecha,props.show,pagination])

    const cargarResoluciones = async (fecha) => {
        let filters = { fecha: fecha, pagination: 'true' }
        const response = await ConsultasAPI.ListarObjetos(URL_RESOLUCION + 'listResolucionesMes/', pagination.pageIndex, pagination.pageSize, filters);
        if (response.status === 200) {
            let resoluciones = response.data.results;
            setCount(response.data.count);
            let dataTable = [];
            const promises = resoluciones.map(async (resolucion) => {
                const responseRes = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVORESOLUCION + "busqueda/",
                    resolucion.id
                );
                let datosResolucion = {
                    id: resolucion.id,
                    expediente: resolucion.expediente,
                    resolucion: resolucion.resolucion,
                    fechaResolucion: resolucion.fecha_resolucion.split(" ")[0],
                    fechaNotificacion: resolucion.fecha_notificacion.split(" ")[0],
                    motivo: resolucion.tipo_resolucion,
                    archivo: responseRes.status === 200 ? responseRes.data.archivo_detalle.path : '-',
                    titular: resolucion.datos_json ?
                        resolucion.datos_json.titular ?
                            resolucion.datos_json.titular.titular_detalle ?
                                resolucion.datos_json.titular.titular_detalle.persona_detalle.cuit + '-' + resolucion.datos_json.titular.titular_detalle.persona_detalle.apellido + ', ' + resolucion.datos_json.titular.titular_detalle.persona_detalle.nombre
                                :
                                resolucion.datos_json.titular.persona_detalle ?
                                    resolucion.datos_json.titular.persona_detalle.cuit + '-' + resolucion.datos_json.titular.persona_detalle.apellido + ', ' + resolucion.datos_json.titular.persona_detalle.nombre
                                    :
                                    resolucion.datos_json.titular
                            :
                            null
                        :
                        null,
                };
                dataTable.push(datosResolucion);
            });
            await Promise.all(promises);
            setDataResoluciones(dataTable);
            setAllowButton(true)
        } else {
            setDataResoluciones([]);
            setAllowButton(false)
        }
    }

    const exportToExcel = async () => {
        setIsLoading(true)
        let filters = { fecha: fecha, pagination: 'false'}
        const response = await ConsultasAPI.ListarObjetos(URL_RESOLUCION + 'listResolucionesMes/', null, null, filters);
        if (response.status === 200) {
            let resoluciones = response.data.results;
            let dataTable = [];
            const promises = resoluciones.map((resolucion) => {
                let datosResolucion = {
                    expediente: resolucion.expediente,
                    resolucion: resolucion.resolucion,
                    'fecha de Resolucion': resolucion.fecha_resolucion.split(" ")[0],
                    'fecha de Notificacion': resolucion.fecha_notificacion.split(" ")[0],
                    motivo: resolucion.tipo_resolucion,
                    titular: resolucion.datos_json ?
                        resolucion.datos_json.titular ?
                            resolucion.datos_json.titular.titular_detalle ?
                                resolucion.datos_json.titular.titular_detalle.persona_detalle.cuit + '-' + resolucion.datos_json.titular.titular_detalle.persona_detalle.apellido + ', ' + resolucion.datos_json.titular.titular_detalle.persona_detalle.nombre
                                :
                                resolucion.datos_json.titular.persona_detalle ?
                                    resolucion.datos_json.titular.persona_detalle.cuit + '-' + resolucion.datos_json.titular.persona_detalle.apellido + ', ' + resolucion.datos_json.titular.persona_detalle.nombre
                                    :
                                    resolucion.datos_json.titular
                            :
                            null
                        :
                        null,
                };
                dataTable.push(datosResolucion);
            });
            await Promise.all(promises);
            const worksheet = XLSX.utils.json_to_sheet(dataTable.flat());
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "Resoluciones de Mes " + fecha.format('MMMM YYYY') + '.xlsx');
            setIsLoading(false);
        } else {
            setIsLoading(false);
            Notificaciones.notificacion("Ocurrio un error");
        }
    }

    var valid = function (current) {
        const today = moment();
        return current.isBefore(today);
    };

    const handleCloseModal = () => {
        setFecha(props.fecha);
        props.close();
    }

    return (
        <>
            <Modal show={props.show}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>Novedades</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Elegir Mes:</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat="MMMM YYYY"
                                    value={fecha}
                                    ref={datetimeRef}
                                    onChange={(momentDate) => {
                                        setFecha(momentDate);
                                    }}
                                    isValidDate={valid}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <MaterialReactTable
                                className="w-100"
                                columns={columns}
                                data={dataResoluciones}
                                initialState={{
                                    columnVisibility: { id: false, archivo: false },
                                    showColumnFilters: true,
                                }} //hide firstName column by default
                                editingMode="modal" //default
                                enableRowSelection={false} //enable some features
                                enableColumnOrdering={false}
                                enableHiding={false}
                                enableColumnActions={false}
                                enableSorting={false}
                                displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                                enableRowActions
                                positionActionsColumn="last"
                                // renderRo
                                renderRowActions={({ row }) => (
                                    <a href={row.original.archivo} target="blank">
                                        <IconButton>
                                            <PictureAsPdf />
                                        </IconButton>
                                    </a>
                                )}
                                manualPagination
                                muiTablePaginationProps={{
                                    rowsPerPageOptions: [10],
                                }}
                                enablePagination={true}
                                localization={MRT_Localization_ES}
                                rowCount={count}
                                onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                                enableGlobalFilter={false} //turn off a feature
                                manualFiltering //turn off client-side filtering
                                onColumnFiltersChange={(value) => {
                                    setColumnFilters(value);
                                }} //hoist internal columnFilters state to your state
                                state={{
                                    columnFilters,
                                    pagination,
                                }}
                            />
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {AllowButton ?
                        <Link className="btn botonCPA-Excel me-3" onClick={exportToExcel}>
                            <FaFileDownload className="iconAntD" />
                            Descargar Excel
                        </Link>
                        :
                        null
                    }
                </Modal.Footer>
            </Modal >
            <Modal
                centered
                show={isLoading}
            >
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Descargando Archivo...</p>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Novedades;
