import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import Notificaciones from "../../../shared/helpers/notificacionesToast";
import AuthHelper from "../../../shared/helpers/authenticationHelper";
import CambiarClaveAPI from "../../../shared/helpers/cambiarClaveAPI";

const CambiarClave = (props) => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //  States para mostrar claves
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowconfirmPassword] = useState(false);
  const handleClickNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickConfirmPassword = () =>
    setShowconfirmPassword(!showConfirmPassword);

  const [message, setMessage] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = AuthHelper.getUser();
    CambiarClaveAPI(email, newPassword, confirmPassword, props.onLogout, setMessage,null);
  };

  return (
    <Container fluid className="mainSection my-md-5 p-3">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Header as="h5">Ingrese su nueva clave:</Card.Header>
            <Card.Body>
              <h5>Para mayor seguridad, deberá cambiar su clave actual</h5>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="newPassword">
                  <Form.Label>Nueva clave:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showNewPassword ? "text" : "password"}
                      autoComplete="off" // Deshabilitar autocompletado para este campo
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                      required
                    />
                    <Button
                      variant="link"
                      type="button"
                      onClick={handleClickNewPassword}
                    >
                      {showNewPassword ? (
                        <FaEyeSlash title="Ocultar clave" color="#777777" />
                      ) : (
                        <FaEye title="Mostrar clave" color="#777777" />
                      )}
                    </Button>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                  <Form.Label>Confirma nueva clave:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      autoComplete="off" // Deshabilitar autocompletado para este campo
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                      required
                    />
                    <Button
                      variant="link"
                      type="button"
                      onClick={handleClickConfirmPassword}
                    >
                      {showConfirmPassword ? (
                        <FaEyeSlash title="Ocultar clave" color="#777777" />
                      ) : (
                        <FaEye title="Mostrar clave" color="#777777" />
                      )}
                    </Button>
                  </InputGroup>
                </Form.Group>
                <p
                  style={{
                    marginTop: "1rem",
                    color: "#777777",
                    fontSize: "0.8rem",
                  }}
                >
                  La nueva contraseña debe tener al menos 8 caracteres, una
                  mayúscula, y contener al menos un número.
                </p>
                {message && (
                  <div
                    className={`alert alert-${message.variant} my-4`}
                    role="alert"
                  >
                    {message.text}
                  </div>
                )}
                <article className="my-4 mb-3 d-flex justify-content-end">
                  <Button className="botonCPA" type="submit">
                    Cambiar contraseña
                  </Button>
                </article>
              </Form>
            </Card.Body>
            <Toaster />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default CambiarClave;
