import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Modal, Container, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import CardHeader from "react-bootstrap/esm/CardHeader";
import Select from 'react-select'
import Notificaciones from '../../../../shared/helpers/notificacionesToast';
import { AsyncPaginate } from 'react-select-async-paginate';
import moment from 'moment';


const FormItem = Form.Item;
const { Option } = Select;

export function ModalNuevaComisionTuqui(props) {

  const URL_TITULARES = window.API_ROUTES.TITULARAGENCIA;
  const URL_CREAR = window.API_ROUTES.TUQUI_VENTAS;
  const URL_SORTEO = window.API_ROUTES.SORTEO;

 const [monto, setMonto] = useState("");
  const [opciones, setOpciones] = useState([]);
  const [filtroPeriodo, setFiltroPeriodo] = useState(
     moment().format('MM/YYYY')
);
const [sorteo, setSorteo] = useState("");
const [vectorSorteo, setVectorSorteo] = useState([]);
const requiredSymbol = props.titulo !== "Ver" ? <b style={{ color: "red" }}>*</b> : "";

  useEffect(() => {
  cargarTitulares();
      }, []);


  useEffect(() => {
        if(filtroPeriodo){
          cargarSorteo();
      }}, [filtroPeriodo]);



 const cargarSorteo = async () => {
   var data={
    FechaSorteo:filtroPeriodo
  }
  await ConsultasAPI.CrearObjeto(URL_SORTEO+'dame_sorteos/',data).then((response) => {
                setVectorSorteo(response.data);
                  if(response.status===204){
                  Notificaciones.notificacion("No hay sorteos en la fecha elegida. Seleccione otra fecha");
                }
          });
     };

  const cargarTitulares = async (search = '', { page = 1 } = {}) => {
    try {
        const response = await ConsultasAPI.ListarObjetos2(URL_TITULARES + 'listar_titular_agencia/', page - 1, 10, [], null, null, search);
        if (response.status === 200 && response.data) {
            const results = response.data.results || [];
           const uniqueCUITResults = results.filter((rango, index, self) => (
              // Comprobar si el CUIT no está duplicado en el resto del array
              self.findIndex((r) => r.titular_detalle.persona_detalle.cuit === rango.titular_detalle.persona_detalle.cuit) === index
          ));

            const newOptions = uniqueCUITResults.map((rango) => ({
                value: rango.id,
                label: `CUIT: ${rango.titular_detalle.persona_detalle.cuit} - ${rango.titular_detalle.persona_detalle.apellido} ${rango.titular_detalle.persona_detalle.nombre}`,
            }));
            return {
                options: newOptions,
                hasMore: response.data.next !== null,
                additional: {
                    page: page + 1,
                },
            };
        } else {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: page,
                },
            };
        }
    } catch (error) {
        console.error("Error al cargar los paises:", error);
        return {
            options: [],
            hasMore: false,
            additional: {
                page: page,
            },
        };
    }
};


const CrearVentaTuqui = async (event) => {
    event.preventDefault()
    if(opciones.length===0){
      Notificaciones.notificacion("Seleccione la agencia");
      return
    }
    if(monto<=0){
      Notificaciones.notificacion("El monto es inválido");
      return
    }

    if(sorteo.length===0){
      Notificaciones.notificacion("Debe seleccionar un sorteo");
      return
    }


    Swal.fire({
      title: "Estás seguro?",
      text: "Esta accion asignará un nuevo monto",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#008185",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancelar",
    }).then(async(result) => {

      if (result.isConfirmed) {
    var datos={
      Monto:monto,
        Sorteo:sorteo,
        Titular:opciones.value,
     }
  await ConsultasAPI.CrearObjeto(URL_CREAR+'crear_venta/', datos).then((response) => {
      if(response.status===200){
          Swal.fire(
            "Monto creado",
            "Se creó el monto con éxito",
            "success"
          );
            handleClose(event);
                             }

      if(response.status===202){
          Notificaciones.notificacion(response.data.Mensaje);
                                  }


        }).catch((error) => {
          console.info(error)
          Swal.fire({
            title: "Error",
            text: "Error del servidor",
            icon: "error"

          })
                         });
                        }
                        });
        }


  const handleClose = (event) => {
    event.preventDefault();
    setMonto("");
    setSorteo("");
    setOpciones([]);
    props.onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.titulo === "Nuevo") {
      CrearVentaTuqui(event);
    }
      };

const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
      const formControls = event.target.form.elements;
      const currentIndex = Array.from(formControls).indexOf(event.target);

      // Encuentra el siguiente campo y enfócalo
      const nextControl = formControls[currentIndex + 1];
      if (nextControl) {
        nextControl.focus();
      }
    }
  };


  return (
    <Container>
      <Modal show={props.show} size="lg" centered>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Agregar comisión</Modal.Title>
        </Modal.Header>
        <Form onKeyDown={handleFormKeyDown} style={{ width: "100%" }}>
          <Modal.Body style={{ width: "100%" }}>
            <Card className="m-3">
              <CardHeader>
                <Card.Title>Datos</Card.Title>
              </CardHeader>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>
                      Titular agencia {requiredSymbol}
                    </Form.Label>
                    <AsyncPaginate
                                 value={opciones}
                                 loadOptions={cargarTitulares}
                                 onChange={(option) => {

                                     setOpciones(option);
                                 }}
                                 additional={{
                                     page: 1,
                                 }}
                                 placeholder="Seleccionar titular"
                                 required
                             />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Monto {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="text"
                      inputMode="text"
                      readOnly={props.titulo === "Ver" ? true : false}
                      placeholder={"Ingrese el monto"}
                      value={monto}
                      onChange={(event) => setMonto(event.target.value)}
                      onKeyPress={(event) => {
                          // Solo permitir números y hasta dos decimales
                          const regex = /^\d+(\.\d{0,2})?$/;
                          const inputValue = event.target.value + event.key; // Concatenar el nuevo carácter al valor actual
                          if (!regex.test(inputValue)) {
                              event.preventDefault();
                          }
                      }}
                      required
                  />
                  </Form.Group>
                  </Row>
                 <Row className="mb-3">
                <Form.Group as={Col} md="6">
                   <Form.Label>Periodo: {requiredSymbol}</Form.Label>
                            <Datetime
                                timeFormat={false}
                                dateFormat="MM/YYYY"
                                inputProps={{
                                    required: true,
                                    placeholder: filtroPeriodo,
                                    readOnly: true,
                                }}
                                value={filtroPeriodo}
                                closeOnSelect={true}
                                onChange={(momentDate) => {
                                    const fechaMuestra =
                                        momentDate.format('MM/YYYY');
                                    setFiltroPeriodo(fechaMuestra);
                                }}
                            />
                    </Form.Group>
                {vectorSorteo.length>0 &&
                    <Form.Group as={Col} md="6">
                      <Form.Label>Sorteo {requiredSymbol}</Form.Label>
                      <Form.Select
                        readOnly={props.titulo === "Ver" ? true : false}
                        value={sorteo}
                        onChange={(event) =>
                          setSorteo(event.target.value)
                        }
                        required
                      >
                        <option value="" disabled hidden>
                          Seleccionar Sorteo
                        </option>

                        {vectorSorteo.map((condicion) => (
                          <option key={condicion.id} value={condicion.id}>
                            {condicion.fecha_sorteo} - Sorteo número: {condicion.numero}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                        }
               </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn botonCPA m-3" onClick={handleClose}>
              {'Cancelar'}
            </button>
            {<button className="btn botonCPA m-3" onClick={handleSubmit}>
                Guardar
              </button>}
          </Modal.Footer>
        </Form>
        </Modal>
       </Container>
  );
}
