/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Parser from "../../../common/Parser";
import { AiFillEdit, AiFillCheckCircle } from "react-icons/ai";
import { FaStepBackward } from "react-icons/fa";
import Notificaciones from "../../../../shared/helpers/notificacionesToast";

import ConsultasAPI from "../../../../shared/helpers/consultasAPI";

function ModalLeerRendicion(props) {
  const rolUser = props.rolUsuario;

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const URL_JUGADASMES = window.API_ROUTES.JUGADASMES;
  const URL_JUGADASMESTOTAL = window.API_ROUTES.JUGADASMESTOTAL;
  const URL_DEDUCCION = window.API_ROUTES.DEDUCCION;

  const [comision, setComision] = useState();
  const [comisiones, setComisiones] = useState([]);
  const [ticketsTotales, setTicketsTotales] = useState([
    comision ? comision.ticketsTotales : "",
  ]);
  const [comisionBruta, setComisionBruta] = useState([
    comision ? comision.comisionBruta : "",
  ]);
  // const [operacion, setOperacion] = useState();
  const [count, setCount] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      columnVisibility: false,
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 80,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {renderedCellValue}
        </span>
      ),
    },
    {
      header: "Subagencia",
      accessorKey: "numero_subagencia",
      size: 10,
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {renderedCellValue}
        </span>
      ),
    },
    {
      header: "CUIT",
      accessorKey: "cuit",
      size: 10,
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {renderedCellValue}
        </span>
      ),
    },
    {
      header: "Monto Vendido",
      accessorKey: "montoVendido",
      size: 10,
      // columnVisibility: false,
      // enableColumnOrdering: false,
      // enableSorting: false,
      enableEditing: comision && comision.aprobado ? false : true,
      Cell: ({ renderedCellValue, row }) => {
        return (
          <span
            style={
              row.original.cuit === "Sin Responsable"
                ? { color: "#f17e7e" }
                : {}
            }
          >
            {Parser.currency(renderedCellValue)}
          </span>
        );
      },
    },
    {
      header: "Monto Vendido en otras Agencias",
      accessorKey: "montoSinAporte",
      size: 20,
      // // columnVisibility: false,
      // enableColumnOrdering: false,
      // enableSorting: false,
      enableEditing: comision && comision.aprobado ? false : true,
      Cell: ({ renderedCellValue, row }) => {
        return (
          <span
            style={
              row.original.cuit === "Sin Responsable"
                ? { color: "#f17e7e" }
                : {}
            }
          >
            {Parser.currency(renderedCellValue)}
          </span>
        );
      },
    },
    {
      header: "Monto Fuera de Termino",
      accessorKey: "montoFueraDeTermino",
      size: 20,
      // columnVisibility: false,
      // enableColumnOrdering: false,
      // enableSorting: false,
      enableEditing: comision && comision.aprobado ? false : true,
      Cell: ({ renderedCellValue, row }) => {
        // const sum = row.original.montoVendido + row.original.montoSinAporte + row.original.montoFueraDeTermino;
        return (
          <span
            style={
              row.original.cuit === "Sin Responsable"
                ? { color: "#f17e7e" }
                : {}
            }
          >
            {Parser.currency(renderedCellValue)}
          </span>
        );
      },
    },
    {
      header: "Ventas Subagencia",
      accessorKey: "ventasTotales",
      size: 10,
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => {
        // const sum = row.original.montoVendido + row.original.montoSinAporte + row.original.montoFueraDeTermino;
        return (
          <span
            style={
              row.original.cuit === "Sin Responsable"
                ? { color: "#f17e7e" }
                : {}
            }
          >
            {/* {renderedCellValue} */}
            {Parser.currency(renderedCellValue)}
          </span>
        );
      },
    },
    {
      header: "Comision 12%",
      accessorKey: "comisionSubagencia",
      size: 10,
      enableEditing: comision && comision.aprobado ? false : true,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {Parser.currency(renderedCellValue)}
        </span>
      ),
    },
    {
      header: "Comision 4%",
      accessorKey: "comisionAgencia",
      size: 10,
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {Parser.currency(renderedCellValue)}
        </span>
      ),
    },
    {
      header: "Comision Bruta",
      accessorKey: "comisionBruta",
      size: 10,
      enableEditing: false,

      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {Parser.currency(renderedCellValue)}
        </span>
      ),
    },
    {
      header: "Tickets Emitidos",
      accessorKey: "ticketsEmitidos",
      size: 10,
      enableEditing: comision && comision.aprobado ? false : true,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {renderedCellValue}
        </span>
      ),
    },
    {
      header: "Tickets Anulados",
      accessorKey: "ticketsAnulados",
      size: 10,
      enableEditing: comision && comision.aprobado ? false : true,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {renderedCellValue}
        </span>
      ),
    },
    {
      header: "Total de Tickets",
      accessorKey: "ticketsTotales",
      size: 10,
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {renderedCellValue}
        </span>
      ),
    },
    {
      header: "Motivo de Cambio",
      accessorKey: "observaciones",
      size: 20,
      Cell: ({ renderedCellValue, row }) => (
        <span
          style={
            row.original.cuit === "Sin Responsable" ? { color: "#f17e7e" } : {}
          }
        >
          {renderedCellValue}
        </span>
      ),
    },
    {
      header: "Actualizado por",
      accessorKey: "actualizado_por",
      enableEditing: false,
      size: 20,
    },
  ];

  useEffect(() => {
    setIsOpen(props.isOpen);

    if (props.isOpen) {
      loadComisiones();
    } else {
      setIsLoading(true);
      setComision();
      setComisiones([]);
      setTicketsTotales(0);
      setPagination({
        pageIndex: 0,
        pageSize: 10,
      });
      setComisionBruta(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  useEffect(() => {
    if (isOpen) {
      loadComisiones();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);
  useEffect(() => {
    console.info(comisiones);
  }, [comisiones]);

  const loadComisiones = () => {
    ConsultasAPI.ListarObjetos(
      URL_JUGADASMES + "comisiones/" + props.comision.id + "/",
      pagination.pageIndex,
      pagination.pageSize
    ).then((response) => {
      if (response.status === 200) {
        setComision(response.data.results[0].jugadaMesTotal);
        setComisiones(
          response.data.results.map((value) => {
            if ((parseFloat(value.montoVendido) + parseFloat(value.montoSinAporte) + parseFloat(value.montoFueraDeTermino)) >= 0) {
              return {
                ...value,
                observaciones: value.observaciones ? value.observaciones : "",
                cuit:
                  value.titularAgencia.titular &&
                    value.titularAgencia.titular_detalle
                    ? value.titularAgencia.titular_detalle.persona_detalle.cuit
                    : "Sin Responsable",
                numero_subagencia:
                  value.titularAgencia.agencia_detalle ? value.titularAgencia.agencia_detalle.numero_subagencia : "-",
                actualizado_por: value.actualizado_por
                  ? value.actualizado_por.email
                  : "-",
                ventasTotales:
                  parseFloat(value.montoVendido) +
                  parseFloat(value.montoSinAporte) +
                  parseFloat(value.montoFueraDeTermino),
              };
            }
          })
        );
        setComisionBruta(response.data.results[0].jugadaMesTotal.comisionBruta);
        setTicketsTotales(response.data.results[0].jugadaMesTotal.ticketsTotales);
        setCount(response.data.count);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    if (comision) {
      const comisionAgencia = comision.comisionAgencia ?? 0;
      const comisionSubagencia = comision.comisionSubagencia ?? 0;
      const ticketsEmitidos = comision.ticketsEmitidos ?? 0;
      const ticketsAnulados = comision.ticketsAnulados ?? 0;

      setComisionBruta(comisionAgencia + comisionSubagencia);
      setComision({
        ...comision,
        comisionBruta: comisionAgencia + comisionSubagencia,
      });
      setTicketsTotales(ticketsEmitidos + ticketsAnulados);
      setComision({
        ...comision,
        ticketsTotales: ticketsEmitidos + ticketsAnulados,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comision]);

  const handleConfirmTotal = (value) => {
    ConsultasAPI.ModificarObjeto(URL_JUGADASMESTOTAL, comision.id, comision)
      .then((response) => {
        loadComisiones();
      })
      .catch((error) => {
        Notificaciones.notificacion(error.response.data.error);
      });
  };

  /* eslint-disable */
  const handleSaveRowEdits: MaterialReactTableProps<Person>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      //send/receive api updates here, then refetch or update local table data for re-render
      ConsultasAPI.ModificarObjeto(URL_JUGADASMES, row.original.id, values)
        .then((response) => {
          loadComisiones();
          exitEditingMode(); //required to exit editing mode and close modal
        })
        .catch((error) => {
          Notificaciones.notificacion(error.response.data.error);
        });
    };

  const guardarCambios = () => {
    //codigo para registrar rendicion
    ConsultasAPI.ModificarObjeto(
      URL_JUGADASMESTOTAL,
      comision.id + "/aprobarComision",
      null
    ).then((response) => {
      const creacion = ConsultasAPI.CrearObjeto(URL_DEDUCCION + 'crearAutomatizacionCompletas/', 1);
      props.onClose();
    });
  };

  return isLoading && isOpen ? (
    <Modal centered show={isLoading} onHide={() => setIsLoading(false)}>
      <Modal.Body className="text-center">
        <Spinner animation="border" />
        <p>Cargando Comisiones...</p>
      </Modal.Body>
    </Modal>
  ) : (
    <Modal fullscreen show={isOpen}>
      <Modal.Header closeButton onClick={props.onClose}>
        <Modal.Title>
          Leer Retención - Agencia{" "}
          {comision && comision.titularAgencia
            ? comision.titularAgencia.agencia_detalle.numero_agencia
            : ""}
          - Titular{" "}
          {comision &&
            comision.titularAgencia &&
            comision.titularAgencia.titular_detalle &&
            comision.titularAgencia.titular
            ? comision.titularAgencia.titular_detalle.persona_detalle.cuit
            : "-"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Label>Venta Agencia</Form.Label>
                <Form.Control
                  disabled={
                    rolUser !== "CONTADURIA" &&
                      rolUser !== "ADMINISTRADOR" &&
                      rolUser !== "AUDITORIA"
                      ? true
                      : comision && comision.aprobado
                        ? true
                        : false
                  }
                  onChange={(event) =>
                    setComision({
                      ...comision,
                      montoVendido: parseInt(event.target.value),
                    })
                  }
                  value={comision ? Parser.currency(comision.montoVendido) : ""}
                />
              </Col>
              <Col>
                <Form.Label>Comision 12%</Form.Label>
                <Form.Control
                  disabled={
                    rolUser !== "CONTADURIA" &&
                      rolUser !== "ADMINISTRADOR" &&
                      rolUser !== "AUDITORIA"
                      ? true
                      : comision && comision.aprobado
                        ? true
                        : false
                  }
                  onChange={(event) =>
                    setComision({
                      ...comision,
                      comisionSubagencia: parseInt(event.target.value),
                    })
                  }
                  value={
                    comision ? Parser.currency(comision.comisionSubagencia) : ""
                  }
                />
              </Col>
              <Col>
                <Form.Label>Comision 4%</Form.Label>
                <Form.Control
                  disabled={
                    rolUser !== "CONTADURIA" &&
                      rolUser !== "ADMINISTRADOR" &&
                      rolUser !== "AUDITORIA"
                      ? true
                      : comision && comision.aprobado
                        ? true
                        : false
                  }
                  onChange={(event) =>
                    setComision({
                      ...comision,
                      comisionAgencia: parseInt(event.target.value),
                    })
                  }
                  value={
                    comision ? Parser.currency(comision.comisionAgencia) : ""
                  }
                />
              </Col>
              <Col>
                <Form.Label>Comision Bruta</Form.Label>
                <Form.Control disabled value={Parser.currency(comisionBruta)} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Label>Tickets Emitidos</Form.Label>
                <Form.Control
                  disabled={
                    rolUser !== "CONTADURIA" &&
                      rolUser !== "ADMINISTRADOR" &&
                      rolUser !== "AUDITORIA"
                      ? true
                      : comision && comision.aprobado
                        ? true
                        : false
                  }
                  onChange={(event) =>
                    setComision({
                      ...comision,
                      ticketsEmitidos: parseInt(event.target.value),
                    })
                  }
                  value={comision ? comision.ticketsEmitidos : ""}
                />
              </Col>
              <Col>
                <Form.Label>Tickets Anulados</Form.Label>
                <Form.Control
                  disabled={
                    rolUser !== "CONTADURIA" &&
                      rolUser !== "ADMINISTRADOR" &&
                      rolUser !== "AUDITORIA"
                      ? true
                      : comision && comision.aprobado
                        ? true
                        : false
                  }
                  onChange={(event) =>
                    setComision({
                      ...comision,
                      ticketsAnulados: parseInt(event.target.value),
                    })
                  }
                  value={comision ? comision.ticketsAnulados : ""}
                />
              </Col>
              <Col>
                <Form.Label>Total de Tickets</Form.Label>
                <Form.Control
                  disabled
                  value={ticketsTotales}
                // onChange={(event) =>
                //   setComision({
                //     ...comision,
                //     ticketsTotales: parseInt(event.target.value),
                //   })
                // }
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Label>Motivo de Cambio</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  disabled={
                    rolUser !== "CONTADURIA" &&
                      rolUser !== "ADMINISTRADOR" &&
                      rolUser !== "AUDITORIA"
                      ? true
                      : false
                  }
                  onChange={(event) =>
                    setComision({
                      ...comision,
                      observaciones: event.target.value,
                    })
                  }
                  value={
                    comision && comision.observaciones
                      ? comision.observaciones
                      : ""
                  }
                />
              </Col>
              {comision && comision.actualizado_por ? (
                <p>Actualizado por {comision.actualizado_por.email}</p>
              ) : null}
            </Row>
            {
              comision && comision.aprobado ?
                null :
                <Button
                  className="botonCPA d-flex column align-items-center"
                  onClick={handleConfirmTotal}
                >
                  <AiFillEdit className="iconAntD" />
                  Modificar
                </Button>

            }
          </Form>
        </Row>
        <Row>
          <MaterialReactTable
            columns={columns}
            data={comisiones}
            initialState={{
              columnVisibility: {
                id: false,
                montoVendido: false,
                montoFueraDeTermino: false,
                montoSinAporte: false,
              },
            }} //hide firstName column by default
            // editingMode="modal" //default
            enableEditing={
              rolUser !== "CONTADURIA" &&
                rolUser !== "ADMINISTRADOR" &&
                rolUser !== "AUDITORIA"
                ? false
                : true
            }
            enableRowSelection={false} //enable some features
            enableColumnOrdering={false}
            enableGlobalFilter={true} //turn off a feature
            enableFilters={false}
            localization={MRT_Localization_ES}
            enableRowActions={
              rolUser !== "CONTADURIA" &&
                rolUser !== "ADMINISTRADOR" &&
                rolUser !== "AUDITORIA"
                ? false
                : true
            }
            positionActionsColumn="last"
            manualPagination
            muiTablePaginationProps={{
              rowsPerPageOptions: [10],
            }}
            enablePagination={true}
            rowCount={count}
            onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
            state={{
              pagination,
            }}
            editingMode="row" //default
            // onEditingRowSave={handleSaveRow}
            onEditingRowSave={handleSaveRowEdits}
          />
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Row>
          <Col>
            <Button
              className="botonCPA d-flex column align-items-center"
              onClick={props.onClose}
            >
              <FaStepBackward className="iconAntD" />
              Volver
            </Button>
          </Col>
          {comision && !comision.aprobado ? (

            <Col className="d-flex">
              <Button
                className="botonCPA d-flex column align-items-center"
                onClick={guardarCambios}
              >
                <AiFillCheckCircle className="iconAntD" />
                Aprobar
              </Button>
            </Col>
          ) : null}
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalLeerRendicion;
