import React, { useEffect, useState } from 'react';

import {
    Container,
    Card,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Spinner,
} from 'react-bootstrap';
import AuthenticationHelper from '../../../shared/helpers/authenticationHelper';
import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Swal from 'sweetalert2';
import moment from 'moment';
import GenerarCertificados from './GenerarCertificados';
import GenerarComisiones from './generarComisiones';
import GenerarOrdenDePago from './generarOrdenDePago';
import Parser from '../../common/Parser';

const SubirDocumentacion = () => {
    const URL_USER = window.API_ROUTES.USUARIOS;
    const URL_TITULAR_AGENCIA = window.API_ROUTES.TITULARAGENCIA;
    const URL_DOMICILIO_COMERCIAL = window.API_ROUTES.DOMICILIOCOMERCIAL;
    const URL_ARCHIVO_DOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
    const URL_ORDEN_PAGO = window.API_ROUTES.ORDENPAGO;
    const URL_FECHALIMITE = window.API_ROUTES.FECHALIMITE;
    const URL_COMISION = window.API_ROUTES.COMISION;
    const URL_JUGADASMES = window.API_ROUTES.JUGADASMES;
    const [TitularAgecia, setTitularAgecia] = useState([]);
    const [Domicilio, setDomicilio] = useState([]);
    const [MesCarga, setMesCarga] = useState(moment().subtract(1, 'month'));
    const [MesesPendientes, setMesesPendientes] = useState([]);
    const [facturaElectronica, setFacturaElectronica] = useState(null);
    const [cumplimientoFiscal, setCumplimientoFiscal] = useState(null);
    const [inscripcionAFIP, setInscripcionAFIP] = useState(null);
    const [inscripcionRentas, setInscripcionRentas] = useState(null);
    const [tributoEconocimo, setTributoEconocimo] = useState(null);
    const [deudorAlimenticio, setDeudorAlimenticio] = useState(null);
    const [liquidacionMensual, setLiquidacionMensual] = useState(null);
    const [allowSend, setAllowSend] = useState(false);
    const [allowComent, setAllowComent] = useState(false);
    const [allowPago, setAllowPago] = useState(false);
    const [AllowViews, setAllowViews] = useState({
        facturaElectronica: true,
        cumplimientoFiscal: true,
        inscripcionAFIP: true,
        inscripcionRentas: true,
        tributoEconocimo: true,
        deudorAlimenticio: true,
        liquidacionMensual: true,
    });

    const color = {
        Rechazado: '#EC1B23',
        Aprobado: '#008185',
        'Pendiente de Revision': '#008185',
    };
    const [ordenDePago, setOrdenDePago] = useState([]);
    const [dataPago, setDataPago] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingView, setIsLoadingView] = useState(false);
    const [isValidFile, setIsValidFile] = useState(false);
    const [isComisionAproved, setIsComisionAproved] = useState(false);
    const [comisionQ, setComisionQ] = useState(0);
    const [comisionT, setComisionT] = useState(0);
    const [subida, setSubida] = useState(false);

    useEffect(() => {
        BuscarDatosTitular(AuthenticationHelper.getUser());
        obtenerFecha();

    }, []);

    const obtenerFecha = async () => {
        var datos = {
            Fecha: FormatoFecha(moment()),
        }
        const fecha = await ConsultasAPI.CrearObjeto(URL_FECHALIMITE + 'dame_fecha_cierre_ultima/', datos);
        if (fecha.status === 200) {
            const limite = fecha.data;
            if (limite.dia_limite_nuevo !== null) {
                const diaLimite_nuevo = fecha.data.dia_limite_nuevo;
                // const fechaActual = moment().format('DD/MM/YYYY').split('/');
                // const fechaLimite = FormatoFecha(diaLimite_nuevo).split('/');
                const moment_limite = moment(diaLimite_nuevo, "DD/MM/YYYY")
                limite['dia_limite_nuevo'] = moment(fecha.data.dia_limite_nuevo, "DD/MM/YYYY hh:mm:ss").format('YYYY-MM-DD hh:mm:ss')
                limite['dia_activacion_nuevo'] = moment(fecha.data.dia_activacion_nuevo, "DD/MM/YYYY hh:mm:ss").format('YYYY-MM-DD hh:mm:ss')
                if (moment_limite.isBefore(moment())) {
                    delete limite['fecha_creacion'];
                    limite.activacion = false;
                    await ConsultasAPI.CrearObjeto(
                        URL_FECHALIMITE + 'modificar_fecha_general/',
                        limite
                    );
                }
            }
            if (limite.activacion) {
                setSubida(true);
            } else {
                setSubida(false);
            }
        }
    };


    const FechaParaBase = (fecha) => {
        var formato = new Date(fecha);
        var year = formato.getFullYear();
        var month = (formato.getMonth() + 1).toString().padStart(2, '0');
        var day = formato.getDate().toString().padStart(2, '0');
        var hours = formato.getHours().toString().padStart(2, '0');
        var minutes = formato.getMinutes().toString().padStart(2, '0');
        var seconds = formato.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}-03`;
    }



    const FormatoFecha = (fecha) => {
        var fechaMoment = moment(fecha, 'DD/MM/YYYY');
        var nuevaFecha = fechaMoment.format('DD/MM/YYYY');
        return nuevaFecha
    };



    const getDocuments = async (date, agencia) => {
        setIsLoadingView(true);
        setAllowSend(false);
        setAllowComent(false);

        // obtengo las comisiones de quiniela para saber si estan aprobadas y para mostrar el valor de las comisiones
        let paramComisionesQuiniela = agencia.id + ',' + date.format('MM/YYYY');
        const comisonesQuinielaDatos = await ConsultasAPI.ObtenerObjeto(
            URL_JUGADASMES + 'obtenerJugadaTitular/',
            paramComisionesQuiniela
        );

        //obtengo los archivos del periodo
        let paramComisionesTuqui =
            agencia.agencia_detalle.id + ':' + date.format('YYYY-MM-DD');
        const comisonesTuquiDatos = await ConsultasAPI.ObtenerObjeto(
            URL_COMISION + 'obtenerComisionAgenciaTotal/',
            paramComisionesTuqui
        );
        let param = date.format('YYYY-MM-DD') + '/' + agencia.id;
        const response = await ConsultasAPI.ObtenerObjeto(
            URL_ARCHIVO_DOCUMENTACION + 'archivosDeMes/',
            param
        );
        if (response.status === 200) {
            let arrayTipos = [
                'Factura Electronica',
                'Certificado de Cumplimiento Fiscal Vigente',
                'Constancia de Inscripción en AFIP',
                'Constancia de Inscripción en Rentas de la Provincia',
                'Constancia Del Tributo Económico Municipal Actualizada',
                'Certificación de no Estar Registrado Como Deudor Alimenticio',
                'Declaración Jurada de Liquidación Mensual',
            ];
            let arrayTiposSeteados = [];
            let arrayArchivos = response.data;
            let allAccepted = false;
            arrayArchivos = arrayArchivos.filter(
                (documento) => documento.estado_documentacion === 'Aprobado'
            );
            if (
                (agencia.agencia_detalle.numero_subagencia !== 0 &&
                    arrayArchivos.length === 6) ||
                (agencia.agencia_detalle.numero_subagencia === 0 &&
                    arrayArchivos.length === 7)
            ) {
                allAccepted = true;
            }
            if (
                (agencia.agencia_detalle.numero_subagencia !== 0 &&
                    response.data.length < 6) ||
                (agencia.agencia_detalle.numero_subagencia === 0 &&
                    response.data.length < 7)
            ) {
                setAllowSend(true);
            }

            if (
                comisonesQuinielaDatos.status === 200 &&
                comisonesQuinielaDatos.data.length > 0
            ) {
                setComisionQ(comisonesQuinielaDatos.data[0].comisionBruta);
                setIsComisionAproved(comisonesQuinielaDatos.data[0].aprobado);
            } else {
                setComisionQ(0);
                setIsComisionAproved(false);
            }

            //obtengo las comisiones de tuqui para mostrar el valor de las comisiones
            if (
                comisonesTuquiDatos.status === 200
            ) {
                setComisionT(comisonesTuquiDatos.data);
            } else {
                setComisionT(0);
            }

            response.data.forEach((documento) => {
                let fechaActual = moment();
                let fechaVencimiento = documento.fecha_vencimiento;
                if (moment(documento.fecha_vencimiento, 'DD/MM/AA').isValid()) {
                    fechaVencimiento = moment(
                        documento.fecha_vencimiento,
                        'DD/MM/AA'
                    );
                }
                if (documento.estado_documentacion === 'Rechazado') {
                    setAllowSend(true);
                    setAllowComent(true);
                }
                if (documento.tipo === 'Factura Electronica') {
                    arrayTiposSeteados.push(documento.tipo);
                    if (
                        fechaVencimiento &&
                        fechaActual.isAfter(fechaVencimiento) &&
                        !allAccepted
                    ) {
                        setFacturaElectronica(null);
                        AllowViews['facturaElectronica'] = true;
                        // isExpired['facturaElectronica'] = true;
                        setAllowSend(true);
                    } else {
                        setFacturaElectronica(documento);
                        AllowViews['facturaElectronica'] = false;
                        // isExpired['facturaElectronica'] = false;
                    }
                } else if (
                    documento.tipo ===
                    'Certificado de Cumplimiento Fiscal Vigente'
                ) {
                    arrayTiposSeteados.push(documento.tipo);
                    if (
                        fechaVencimiento &&
                        fechaActual.isAfter(fechaVencimiento) &&
                        !allAccepted
                    ) {
                        setCumplimientoFiscal(null);
                        AllowViews['cumplimientoFiscal'] = true;
                        // isExpired['cumplimientoFiscal'] = true;
                        setAllowSend(true);
                    } else {
                        setCumplimientoFiscal(documento);
                        AllowViews['cumplimientoFiscal'] = false;
                        // isExpired['cumplimientoFiscal'] = false;
                    }
                } else if (
                    documento.tipo === 'Constancia de Inscripción en AFIP'
                ) {
                    arrayTiposSeteados.push(documento.tipo);
                    if (
                        fechaVencimiento &&
                        fechaActual.isAfter(fechaVencimiento) &&
                        !allAccepted
                    ) {
                        setInscripcionAFIP(null);
                        AllowViews['inscripcionAFIP'] = true;
                        // isExpired['inscripcionAFIP'] = true;
                        setAllowSend(true);
                    } else {
                        setInscripcionAFIP(documento);
                        AllowViews['inscripcionAFIP'] = false;
                        // isExpired['inscripcionAFIP'] = false;
                    }
                } else if (
                    documento.tipo ===
                    'Constancia de Inscripción en Rentas de la Provincia'
                ) {
                    arrayTiposSeteados.push(documento.tipo);
                    if (
                        fechaVencimiento &&
                        fechaActual.isAfter(fechaVencimiento) &&
                        !allAccepted
                    ) {
                        setInscripcionRentas(null);
                        AllowViews['inscripcionRentas'] = true;
                        // isExpired['inscripcionRentas'] = true;
                        setAllowSend(true);
                    } else {
                        setInscripcionRentas(documento);
                        AllowViews['inscripcionRentas'] = false;
                        // isExpired['inscripcionRentas'] = false;
                    }
                } else if (
                    documento.tipo ===
                    'Constancia Del Tributo Económico Municipal Actualizada'
                ) {
                    arrayTiposSeteados.push(documento.tipo);
                    if (
                        fechaVencimiento &&
                        fechaActual.isAfter(fechaVencimiento) &&
                        !allAccepted
                    ) {
                        setTributoEconocimo(null);
                        AllowViews['tributoEconocimo'] = true;
                        // isExpired['tributoEconocimo'] = true;
                        setAllowSend(true);
                    } else {
                        setTributoEconocimo(documento);
                        AllowViews['tributoEconocimo'] = false;
                        // isExpired['tributoEconocimo'] = false;
                    }
                } else if (
                    documento.tipo ===
                    'Certificación de no Estar Registrado Como Deudor Alimenticio'
                ) {
                    arrayTiposSeteados.push(documento.tipo);
                    if (
                        fechaVencimiento &&
                        fechaActual.isAfter(fechaVencimiento) &&
                        !allAccepted
                    ) {
                        setDeudorAlimenticio(null);
                        AllowViews['deudorAlimenticio'] = true;
                        // isExpired['deudorAlimenticio'] = true;
                        setAllowSend(true);
                    } else {
                        setDeudorAlimenticio(documento);
                        AllowViews['deudorAlimenticio'] = false;
                        // isExpired['deudorAlimenticio'] = false;
                    }
                } else if (
                    documento.tipo ===
                    'Declaración Jurada de Liquidación Mensual'
                ) {
                    arrayTiposSeteados.push(documento.tipo);
                    if (
                        fechaVencimiento &&
                        fechaActual.isAfter(fechaVencimiento) &&
                        !allAccepted
                    ) {
                        setLiquidacionMensual(null);
                        AllowViews['liquidacionMensual'] = true;
                        // isExpired['liquidacionMensual'] = true;
                        setAllowSend(true);
                    } else {
                        setLiquidacionMensual(documento);
                        AllowViews['liquidacionMensual'] = false;
                        // isExpired['liquidacionMensual'] = false;
                    }
                }
            });
            arrayTipos = arrayTipos.filter(
                (item) => !arrayTiposSeteados.includes(item)
            );
            arrayTipos.forEach((tipo) => {
                if (tipo === 'Factura Electronica') {
                    setFacturaElectronica(null);
                    AllowViews['facturaElectronica'] = true;
                    // isExpired['facturaElectronica'] = false;
                } else if (
                    tipo === 'Certificado de Cumplimiento Fiscal Vigente'
                ) {
                    setCumplimientoFiscal(null);
                    AllowViews['cumplimientoFiscal'] = true;
                    // isExpired['cumplimientoFiscal'] = false;
                } else if (tipo === 'Constancia de Inscripción en AFIP') {
                    setInscripcionAFIP(null);
                    AllowViews['inscripcionAFIP'] = true;
                    // isExpired['inscripcionAFIP'] = false;
                } else if (
                    tipo ===
                    'Constancia de Inscripción en Rentas de la Provincia'
                ) {
                    setInscripcionRentas(null);
                    AllowViews['inscripcionRentas'] = true;
                    // isExpired['inscripcionRentas'] = false;
                } else if (
                    tipo ===
                    'Constancia Del Tributo Económico Municipal Actualizada'
                ) {
                    setTributoEconocimo(null);
                    AllowViews['tributoEconocimo'] = true;
                    // isExpired['tributoEconocimo'] = false;
                } else if (
                    tipo ===
                    'Certificación de no Estar Registrado Como Deudor Alimenticio'
                ) {
                    setDeudorAlimenticio(null);
                    AllowViews['deudorAlimenticio'] = true;
                    // isExpired['deudorAlimenticio'] = false;
                } else if (
                    tipo === 'Declaración Jurada de Liquidación Mensual'
                ) {
                    setLiquidacionMensual(null);
                    AllowViews['liquidacionMensual'] = true;
                    // isExpired['liquidacionMensual'] = false;
                }
            });
        } else {
            setFacturaElectronica(null);
            setCumplimientoFiscal(null);
            setInscripcionAFIP(null);
            setInscripcionRentas(null);
            setTributoEconocimo(null);
            setDeudorAlimenticio(null);
            setLiquidacionMensual(null);
            setAllowViews({
                facturaElectronica: true,
                cumplimientoFiscal: true,
                inscripcionAFIP: true,
                inscripcionRentas: true,
                tributoEconocimo: true,
                deudorAlimenticio: true,
                liquidacionMensual: true,
            });
            if (
                comisonesQuinielaDatos.status === 200 &&
                comisonesQuinielaDatos.data.length > 0
            ) {
                setComisionQ(comisonesQuinielaDatos.data[0].comisionBruta);
                setIsComisionAproved(comisonesQuinielaDatos.data[0].aprobado);
            } else {
                setComisionQ(0);
                setIsComisionAproved(false);
            }

            //obtengo las comisiones de tuqui para mostrar el valor de las comisiones
            if (
                comisonesTuquiDatos.status === 200
            ) {
                setComisionT(comisonesTuquiDatos.data);
            } else {
                setComisionT(0);
            }
            if (comisonesQuinielaDatos.data.length === 0) {
                setAllowSend(false);
            } else {
                setAllowSend(true);
            }
        }
        getOrdenDePago(date, agencia);
        setIsLoadingView(false);
    };

    const getOrdenDePago = async (date, agencia) => {
        const params =
            agencia.id.toString() + '/' + date.date(1).format('YYYY-MM-DD');
        const OrdenPago = await ConsultasAPI.ObtenerObjeto(
            URL_ORDEN_PAGO + 'BusquedaTitularPeriodo/',
            params
        );

        if (OrdenPago.status === 200) {
            setOrdenDePago(OrdenPago.data);
            if (OrdenPago.data.estado_comision !== 'Pendiente') {
                setAllowPago(true);
                setDataPago(OrdenPago.data.fecha_modificacion.split(' ')[0]);
            } else {
                setAllowPago(false);
            }
        } else {
            setOrdenDePago([]);
            setAllowPago(false);
        }
    };

    const changeMonth = async (date) => {
        if (document.getElementById('facturaInput')) {
            document.getElementById('facturaInput').value = null;
        }
        if (document.getElementById('cumplimientoInput')) {
            document.getElementById('cumplimientoInput').value = null;
        }
        if (document.getElementById('inscripcionInput')) {
            document.getElementById('inscripcionInput').value = null;
        }
        if (document.getElementById('inscripcionRentasInput')) {
            document.getElementById('inscripcionRentasInput').value = null;
        }
        if (document.getElementById('tributoInput')) {
            document.getElementById('tributoInput').value = null;
        }
        if (document.getElementById('deudorInput')) {
            document.getElementById('deudorInput').value = null;
        }
        if (document.getElementById('liquidacionInput')) {
            document.getElementById('liquidacionInput').value = null;
        }
        setMesCarga(date);
        getDocuments(date, TitularAgecia);
    };

    var valid = function (current) {
        const today = moment();
        const month = current.format('MM/YYYY') !== today.format('MM/YYYY');
        return current.isBefore(today) && month;
    };

    const isValidFileType = (file) => {
        const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg'];
        return validTypes.includes(file.type);
    };

    const handleArchivoPDFChange = (event, num) => {
        if (event.target.files[0]) {
            if (isValidFileType(event.target.files[0])) {
                const archivo = {
                    path: event.target.files[0],
                };
                if (num === 1) {
                    setFacturaElectronica(archivo);
                    AllowViews['facturaElectronica'] = true;
                } else if (num === 2) {
                    setCumplimientoFiscal(archivo);
                    AllowViews['cumplimientoFiscal'] = true;
                } else if (num === 3) {
                    setInscripcionAFIP(archivo);
                    AllowViews['inscripcionAFIP'] = true;
                } else if (num === 4) {
                    setTributoEconocimo(archivo);
                    AllowViews['tributoEconocimo'] = true;
                } else if (num === 5) {
                    setDeudorAlimenticio(archivo);
                    AllowViews['deudorAlimenticio'] = true;
                } else if (num === 6) {
                    setLiquidacionMensual(archivo);
                    AllowViews['liquidacionMensual'] = true;
                } else if (num === 7) {
                    setInscripcionRentas(archivo);
                    AllowViews['inscripcionRentas'] = true;
                }
            } else {
                setIsValidFile(true);
                event.target.value = null;
            }
        }
    };

    const BuscarDatosTitular = async (user) => {
        const Usuario = await ConsultasAPI.ObtenerObjeto(
            URL_USER + 'busquedaEmail/',
            user
        );
        const datosAgencia = await ConsultasAPI.ObtenerObjeto(
            URL_TITULAR_AGENCIA + 'busquedaPorDocumento/',
            Usuario.data.documento
        );
        setTitularAgecia(datosAgencia.data);
        const datosMeses = await ConsultasAPI.ObtenerObjeto(
            URL_ARCHIVO_DOCUMENTACION + 'mesesPendientes/',
            datosAgencia.data.id
        );
        setMesesPendientes(datosMeses.data);
        getDocuments(moment().subtract(1, 'months'), datosAgencia.data);
        const datosDomicilio = await ConsultasAPI.ObtenerObjeto(
            URL_DOMICILIO_COMERCIAL + 'busqueda/',
            datosAgencia.data.agencia
        );
        setDomicilio(datosDomicilio.data);
    };

    const crearTodos = async () => {
        const nombreCarpeta = concatCodigo(
            TitularAgecia.agencia_detalle.numero_agencia,
            TitularAgecia.agencia_detalle.numero_subagencia
        );
        let formData = new FormData();
        console.info(formData);
        if (facturaElectronica.path) {
            console.info(formData);
            console.info(facturaElectronica.path);
            formData.append('facturaElectronica', facturaElectronica.path);
        }
        if (cumplimientoFiscal.path) {
            console.info(formData);
            console.info(cumplimientoFiscal.path);
            formData.append('cumplimientoFiscal', cumplimientoFiscal.path);
        }
        if (inscripcionAFIP.path) {
            console.info(formData);
            console.info(inscripcionAFIP.path);

            formData.append('inscripcionAFIP', inscripcionAFIP.path);
        }
        if (tributoEconocimo.path) {
            console.info(formData);
            console.info(tributoEconocimo.path);

            formData.append('tributoEconocimo', tributoEconocimo.path);
        }
        if (deudorAlimenticio.path) {
            console.info(formData);
            console.info(deudorAlimenticio.path);

            formData.append('deudorAlimenticio', deudorAlimenticio.path);
        }
        if (inscripcionRentas.path) {
            console.info(formData);
            console.info(inscripcionRentas.path);

            formData.append('inscripcionRentas', inscripcionRentas.path);
        }
        if (liquidacionMensual && liquidacionMensual.path) {
            console.info(formData);
            console.info(liquidacionMensual.path);

            formData.append('liquidacionMensual', liquidacionMensual.path);
        }
        console.info(formData);
        formData.append(
            'nombre_subcarpeta',
            nombreCarpeta +
            '/documentacion/' +
            MesCarga.date(1).format('YYYY-MM')
        );
        formData.append('titular_agencia', TitularAgecia.id);
        formData.append('periodo', MesCarga.date(1).format('YYYY-MM-DD'));
        console.info(formData);
        const response = await ConsultasAPI.CrearObjetoArchivo(
            URL_ARCHIVO_DOCUMENTACION + 'crearTodos/',
            formData
        );
        console.info(response);
        return response;
    };


    const concatCodigo = (a, b) => {
        return a.toString().padStart(2, '0') + b.toString().padStart(3, '0');
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: '¿Estás seguro de subir los archivos?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoadingView(true);
                try {
                    const response = await crearTodos();
                    if (response.status === 201) {
                        setIsLoadingView(false);
                        setIsLoading(true);
                    }
                } catch (error) {
                    console.info(error)
                    Swal.fire({
                        title: 'Ocurrio un error y No se realizo la operacion',
                        icon: 'error',
                    });
                }
            }
        });
    };

    return (
        <Container className="mainSection my-5">

            <Card
                className="m-2 p-3 d-flex justify-content-center"
                id="ComponenteFijo"
                style={{ width: '97%' }}
            >
                {TitularAgecia.length !== 0 && Domicilio.length !== 0 ? (
                    <>
                        <Card.Text>
                            {
                                TitularAgecia.titular_detalle.persona_detalle
                                    .apellido
                            }{' '}
                            {
                                TitularAgecia.titular_detalle.persona_detalle
                                    .nombre
                            }{' '}
                            <br />
                            CUIT{' '}
                            {
                                TitularAgecia.titular_detalle.persona_detalle
                                    .cuit
                            }{' '}
                            <br />
                            Agencia Nro{' '}
                            {concatCodigo(
                                TitularAgecia.agencia_detalle.numero_agencia,
                                TitularAgecia.agencia_detalle.numero_subagencia
                            )}
                            <br />
                            {Domicilio.localidad_detalle.nombre}
                        </Card.Text>
                    </>
                ) : null}
            </Card>
            <div className="d-flex justify-content-end">
                <Card className="bg-transparent border-0 m-2 p-3 mb-0">
                    <Form.Group as={Col} md="12">
                        <Form.Label style={{ fontWeight: 'bold' }}>
                            ELEGIR MES A CARGAR
                        </Form.Label>
                        <Datetime
                            as={Col}
                            md="1"
                            timeFormat={false}
                            style={{ width: '100%', height: '32px' }}
                            dateFormat="MMMM YYYY"
                            inputProps={{
                                readOnly: true,
                                placeholder: MesCarga,
                            }}
                            value={MesCarga}
                            onChange={(event) => {
                                changeMonth(event);
                            }}
                            closeOnSelect
                            isValidDate={valid}
                        />
                    </Form.Group>
                </Card>
            </div>
            {allowPago ? (
                <>
                    {ordenDePago.estado_comision === 'Generada' ? (
                        <Card
                            className="m-2 p-3 d-flex justify-content-center"
                            style={{ width: '97%', backgroundColor: '#229010' }}
                        >
                            <Card.Title
                                style={{
                                    fontSize: '1rem',
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                ESTADO DE LA ORDEN DE PAGO
                            </Card.Title>
                            <Card.Text
                                style={{ fontSize: '1rem', color: 'white' }}
                            >
                                LA ORDEN DE PAGO FUE GENERADA EL DIA {dataPago}.{' '}
                            </Card.Text>
                            <Card.Text
                                style={{
                                    fontSize: '1rem',
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                ESTARÁ DISPONIBLE PARA SU COBRO EN 48HS HABILES.
                            </Card.Text>
                            <Card.Text
                                style={{ fontSize: '1rem', color: 'white' }}
                            >
                                Gracias
                            </Card.Text>
                        </Card>
                    ) : (
                        <>
                            <Card
                                className="m-2 p-3 d-flex justify-content-center"
                                style={{
                                    width: '97%',
                                    backgroundColor: '#229010',
                                }}
                            >
                                <Card.Title
                                    style={{
                                        fontSize: '1rem',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    ESTADO DE LA ORDEN DE PAGO: PAGADA
                                </Card.Title>
                                <Card.Text
                                    style={{ fontSize: '1rem', color: 'white' }}
                                >
                                    Puede realizar la descarga de los
                                    Certificados de las Retenciones
                                </Card.Text>
                            </Card>
                            <Card
                                className="m-2 p-3 d-flex justify-content-center"
                                id="ComponenteFijo"
                                style={{ width: '97%' }}
                            >
                                <GenerarCertificados
                                    ordenPagoId={ordenDePago.id}
                                />
                            </Card>
                            <GenerarOrdenDePago
                                ordenDePago={ordenDePago}
                                titularAgencia={TitularAgecia}
                                periodo={MesCarga}
                                comisionBruta={comisionT + comisionQ}
                            />
                        </>
                    )}
                </>
            ) : null}
            {isComisionAproved &&
                TitularAgecia.agencia_detalle.numero_subagencia === 0 ? (
                <GenerarComisiones
                    agencia={TitularAgecia.agencia_detalle}
                    periodo={MesCarga}
                />
            ) : null}
            {!allowPago ? (
                <Card
                    className="m-2 p-3 d-flex justify-content-center"
                    id="ComponenteFijo"
                    style={{ width: '97%' }}
                >
                    {subida ? (
                        <>
                            <Card.Title style={{ fontSize: '1rem' }}>
                                DOCUMENTACIÓN
                            </Card.Title>
                            <Card.Text style={{ fontSize: '0.75rem' }}>
                                Formatos aceptados: .PDF | Hasta 5Mb. por
                                archivo
                            </Card.Text>
                            <Form onSubmit={handleSubmit}>
                                <Card.Body>
                                    <Row
                                        className="mb-3"
                                        style={{
                                            borderBottom: 'inset',
                                            paddingBottom: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        <Form.Group as={Col} md="7">
                                            <Form.Label
                                                style={{
                                                    fontSize: '0.75rem',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                FACTURA ELECTRÓNICA
                                                CORRESPONDIENTE AL MES POR
                                                COMISIONAR - QUINIELA / TUQUI 10
                                            </Form.Label>
                                            {facturaElectronica &&
                                                facturaElectronica.fecha_vencimiento ? (
                                                <Form.Text
                                                    style={{
                                                        fontSize: '0.85rem',
                                                        color: 'black',
                                                    }}
                                                >
                                                    <br />
                                                    Fecha de Vencimiento:{' '}
                                                    {
                                                        facturaElectronica.fecha_vencimiento
                                                    }
                                                </Form.Text>
                                            ) : null}
                                            {isComisionAproved ? (
                                                <Form.Text
                                                    style={{
                                                        fontSize: '0.85rem',
                                                        color: 'black',
                                                    }}
                                                >
                                                    <br />
                                                    Comision de Quiniela:{' '}
                                                    {Parser.currency(
                                                        comisionQ
                                                    )}{' '}
                                                    <br /> Comision de Tuqui :{' '}
                                                    {Parser.currency(comisionT)}
                                                </Form.Text>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            {AllowViews[
                                                'facturaElectronica'
                                            ] ? null : (
                                                <Form.Label
                                                    className="botonCPA fw-bold"
                                                    style={{
                                                        backgroundColor:
                                                            'rgba(255, 0, 0, 0)',
                                                        color: facturaElectronica.estado_documentacion
                                                            ? color[
                                                            facturaElectronica
                                                                .estado_documentacion
                                                            ]
                                                            : '#008185',
                                                        borderColor:
                                                            facturaElectronica.estado_documentacion
                                                                ? color[
                                                                facturaElectronica
                                                                    .estado_documentacion
                                                                ]
                                                                : '#008185',
                                                        borderRadius: '20px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        width: 'fit-content',
                                                    }}
                                                >
                                                    {facturaElectronica.estado_documentacion ===
                                                        'Aprobado'
                                                        ? 'Pendiente'
                                                        : facturaElectronica.estado_documentacion.split(
                                                            ' '
                                                        )[0]}
                                                </Form.Label>
                                            )}
                                            {/* {isExpired['facturaElectronica'] ?
                        <Form.Label className="botonCPA fw-bold" style={{ backgroundColor: 'rgba(255, 0, 0, 0)', color: '#EC1B23', borderColor: '#EC1B23', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "fit-content" }}>
                          Vencida
                        </Form.Label>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            {AllowViews['facturaElectronica'] ||
                                                facturaElectronica.estado_documentacion ===
                                                'Rechazado' ? (
                                                <Form.Control
                                                    id="facturaInput"
                                                    size="sm"
                                                    required
                                                    type="file"
                                                    accept=".pdf,.jpg,.jpeg"
                                                    onChange={(event) => {
                                                        handleArchivoPDFChange(
                                                            event,
                                                            1
                                                        );
                                                    }}
                                                />
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row
                                        className="mb-3"
                                        style={{
                                            borderBottom: 'inset',
                                            paddingBottom: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        <Form.Group as={Col} md="7">
                                            <Form.Label
                                                style={{
                                                    fontSize: '0.75rem',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                CERTIFICADO DE CUMPLIMIENTO
                                                FISCAL VIGENTE
                                            </Form.Label>
                                            {/* {cumplimientoFiscal && cumplimientoFiscal.fecha_vencimiento ?
                        <Form.Text style={{ fontSize: "0.85rem", color: "black" }}><br />Fecha de Vencimiento: {cumplimientoFiscal.fecha_vencimiento}</Form.Text>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            {AllowViews[
                                                'cumplimientoFiscal'
                                            ] ? null : (
                                                <Form.Label
                                                    className="botonCPA fw-bold"
                                                    style={{
                                                        backgroundColor:
                                                            'rgba(255, 0, 0, 0)',
                                                        color: cumplimientoFiscal.estado_documentacion
                                                            ? color[
                                                            cumplimientoFiscal
                                                                .estado_documentacion
                                                            ]
                                                            : '#008185',
                                                        borderColor:
                                                            cumplimientoFiscal.estado_documentacion
                                                                ? color[
                                                                cumplimientoFiscal
                                                                    .estado_documentacion
                                                                ]
                                                                : '#008185',
                                                        borderRadius: '20px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        width: 'fit-content',
                                                    }}
                                                >
                                                    {cumplimientoFiscal.estado_documentacion ===
                                                        'Aprobado'
                                                        ? 'Pendiente'
                                                        : cumplimientoFiscal.estado_documentacion.split(
                                                            ' '
                                                        )[0]}
                                                </Form.Label>
                                            )}
                                            {/* {isExpired['cumplimientoFiscal'] ?
                        <Form.Label className="botonCPA fw-bold" style={{ backgroundColor: 'rgba(255, 0, 0, 0)', color: '#EC1B23', borderColor: '#EC1B23', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "fit-content" }}>
                          Vencida
                        </Form.Label>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            {AllowViews['cumplimientoFiscal'] ||
                                                cumplimientoFiscal.estado_documentacion ===
                                                'Rechazado' ? (
                                                <Form.Control
                                                    id="cumplimientoInput"
                                                    size="sm"
                                                    required
                                                    type="file"
                                                    accept=".pdf,.jpg,.jpeg"
                                                    onChange={(event) => {
                                                        handleArchivoPDFChange(
                                                            event,
                                                            2
                                                        );
                                                    }}
                                                />
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row
                                        className="mb-3"
                                        style={{
                                            borderBottom: 'inset',
                                            paddingBottom: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        <Form.Group as={Col} md="7">
                                            <Form.Label
                                                style={{
                                                    fontSize: '0.75rem',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                CONSTANCIA DE INSCRIPCION EN
                                                AFIP
                                            </Form.Label>
                                            {/* {inscripcionAFIP && inscripcionAFIP.fecha_vencimiento ?
                        <Form.Text style={{ fontSize: "0.85rem", color: "black" }}><br />Fecha de Vencimiento: {inscripcionAFIP.fecha_vencimiento}</Form.Text>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            {AllowViews[
                                                'inscripcionAFIP'
                                            ] ? null : (
                                                <Form.Label
                                                    className="botonCPA fw-bold"
                                                    style={{
                                                        backgroundColor:
                                                            'rgba(255, 0, 0, 0)',
                                                        color: inscripcionAFIP.estado_documentacion
                                                            ? color[
                                                            inscripcionAFIP
                                                                .estado_documentacion
                                                            ]
                                                            : '#008185',
                                                        borderColor:
                                                            inscripcionAFIP.estado_documentacion
                                                                ? color[
                                                                inscripcionAFIP
                                                                    .estado_documentacion
                                                                ]
                                                                : '#008185',
                                                        borderRadius: '20px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        width: 'fit-content',
                                                    }}
                                                >
                                                    {inscripcionAFIP.estado_documentacion ===
                                                        'Aprobado'
                                                        ? 'Pendiente'
                                                        : inscripcionAFIP.estado_documentacion.split(
                                                            ' '
                                                        )[0]}
                                                </Form.Label>
                                            )}
                                            {/* {isExpired['inscripcionAFIP'] ?
                        <Form.Label className="botonCPA fw-bold" style={{ backgroundColor: 'rgba(255, 0, 0, 0)', color: '#EC1B23', borderColor: '#EC1B23', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "fit-content" }}>
                          Vencida
                        </Form.Label>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            {AllowViews['inscripcionAFIP'] ||
                                                inscripcionAFIP.estado_documentacion ===
                                                'Rechazado' ? (
                                                <Form.Control
                                                    size="sm"
                                                    id="inscripcionInput"
                                                    required
                                                    type="file"
                                                    accept=".pdf,.jpg,.jpeg"
                                                    onChange={(event) => {
                                                        handleArchivoPDFChange(
                                                            event,
                                                            3
                                                        );
                                                    }}
                                                />
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row
                                        className="mb-3"
                                        style={{
                                            borderBottom: 'inset',
                                            paddingBottom: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        <Form.Group as={Col} md="7">
                                            <Form.Label
                                                style={{
                                                    fontSize: '0.75rem',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                CONSTANCIA DE INSCRIPCION EN
                                                RENTAS DE LA PROVINCIA
                                            </Form.Label>
                                            {/* {inscripcionRentas && inscripcionRentas.fecha_vencimiento ?
                        <Form.Text style={{ fontSize: "0.85rem", color: "black" }}><br />Fecha de Vencimiento: {inscripcionRentas.fecha_vencimiento}</Form.Text>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            {AllowViews[
                                                'inscripcionRentas'
                                            ] ? null : (
                                                <Form.Label
                                                    className="botonCPA fw-bold"
                                                    style={{
                                                        backgroundColor:
                                                            'rgba(255, 0, 0, 0)',
                                                        color: inscripcionRentas.estado_documentacion
                                                            ? color[
                                                            inscripcionRentas
                                                                .estado_documentacion
                                                            ]
                                                            : '#008185',
                                                        borderColor:
                                                            inscripcionRentas.estado_documentacion
                                                                ? color[
                                                                inscripcionRentas
                                                                    .estado_documentacion
                                                                ]
                                                                : '#008185',
                                                        borderRadius: '20px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        width: 'fit-content',
                                                    }}
                                                >
                                                    {inscripcionRentas.estado_documentacion ===
                                                        'Aprobado'
                                                        ? 'Pendiente'
                                                        : inscripcionRentas.estado_documentacion.split(
                                                            ' '
                                                        )[0]}
                                                </Form.Label>
                                            )}
                                            {/* {isExpired['inscripcionRentas'] ?
                        <Form.Label className="botonCPA fw-bold" style={{ backgroundColor: 'rgba(255, 0, 0, 0)', color: '#EC1B23', borderColor: '#EC1B23', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "fit-content" }}>
                          Vencida
                        </Form.Label>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            {AllowViews['inscripcionRentas'] ||
                                                inscripcionRentas.estado_documentacion ===
                                                'Rechazado' ? (
                                                <Form.Control
                                                    size="sm"
                                                    id="inscripcionRentasInput"
                                                    required
                                                    type="file"
                                                    accept=".pdf,.jpg,.jpeg"
                                                    onChange={(event) => {
                                                        handleArchivoPDFChange(
                                                            event,
                                                            7
                                                        );
                                                    }}
                                                />
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row
                                        className="mb-3"
                                        style={{
                                            borderBottom: 'inset',
                                            paddingBottom: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        <Form.Group as={Col} md="7">
                                            <Form.Label
                                                style={{
                                                    fontSize: '0.75rem',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                CONSTANCIA DEL TRIBUTO ECONÓMICO
                                                MUNICIPAL ACTUALIZADA
                                            </Form.Label>
                                            {/* {tributoEconocimo && tributoEconocimo.fecha_vencimiento ?
                        <Form.Text style={{ fontSize: "0.85rem", color: "black" }}><br />Fecha de Vencimiento: {tributoEconocimo.fecha_vencimiento}</Form.Text>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            {AllowViews[
                                                'tributoEconocimo'
                                            ] ? null : (
                                                <Form.Label
                                                    className="botonCPA fw-bold"
                                                    style={{
                                                        backgroundColor:
                                                            'rgba(255, 0, 0, 0)',
                                                        color: tributoEconocimo.estado_documentacion
                                                            ? color[
                                                            tributoEconocimo
                                                                .estado_documentacion
                                                            ]
                                                            : '#008185',
                                                        borderColor:
                                                            tributoEconocimo.estado_documentacion
                                                                ? color[
                                                                tributoEconocimo
                                                                    .estado_documentacion
                                                                ]
                                                                : '#008185',
                                                        borderRadius: '20px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        width: 'fit-content',
                                                    }}
                                                >
                                                    {tributoEconocimo.estado_documentacion ===
                                                        'Aprobado'
                                                        ? 'Pendiente'
                                                        : tributoEconocimo.estado_documentacion.split(
                                                            ' '
                                                        )[0]}
                                                </Form.Label>
                                            )}
                                            {/* {isExpired['tributoEconocimo'] ?
                        <Form.Label className="botonCPA fw-bold" style={{ backgroundColor: 'rgba(255, 0, 0, 0)', color: '#EC1B23', borderColor: '#EC1B23', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "fit-content" }}>
                          Vencida
                        </Form.Label>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            {AllowViews['tributoEconocimo'] ||
                                                tributoEconocimo.estado_documentacion ===
                                                'Rechazado' ? (
                                                <Form.Control
                                                    size="sm"
                                                    id="tributoInput"
                                                    required
                                                    type="file"
                                                    accept=".pdf,.jpg,.jpeg"
                                                    onChange={(event) => {
                                                        handleArchivoPDFChange(
                                                            event,
                                                            4
                                                        );
                                                    }}
                                                />
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row
                                        className="mb-3"
                                        style={{
                                            borderBottom: 'inset',
                                            paddingBottom: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        <Form.Group as={Col} md="7">
                                            <Form.Label
                                                style={{
                                                    fontSize: '0.75rem',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                CERTIFICIÓN DE NO ESTAR
                                                REGISTRADO COMO DEUDOR
                                                ALIMENTICIO
                                            </Form.Label>
                                            {/* {deudorAlimenticio && deudorAlimenticio.fecha_vencimiento ?
                        <Form.Text style={{ fontSize: "0.85rem", color: "black" }}><br />Fecha de Vencimiento: {deudorAlimenticio.fecha_vencimiento}</Form.Text>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            {AllowViews[
                                                'deudorAlimenticio'
                                            ] ? null : (
                                                <Form.Label
                                                    className="botonCPA fw-bold"
                                                    style={{
                                                        backgroundColor:
                                                            'rgba(255, 0, 0, 0)',
                                                        color: deudorAlimenticio.estado_documentacion
                                                            ? color[
                                                            deudorAlimenticio
                                                                .estado_documentacion
                                                            ]
                                                            : '#008185',
                                                        borderColor:
                                                            deudorAlimenticio.estado_documentacion
                                                                ? color[
                                                                deudorAlimenticio
                                                                    .estado_documentacion
                                                                ]
                                                                : '#008185',
                                                        borderRadius: '20px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        width: 'fit-content',
                                                    }}
                                                >
                                                    {deudorAlimenticio.estado_documentacion ===
                                                        'Aprobado'
                                                        ? 'Pendiente'
                                                        : deudorAlimenticio.estado_documentacion.split(
                                                            ' '
                                                        )[0]}
                                                </Form.Label>
                                            )}
                                            {/* {isExpired['deudorAlimenticio'] ?
                        <Form.Label className="botonCPA fw-bold" style={{ backgroundColor: 'rgba(255, 0, 0, 0)', color: '#EC1B23', borderColor: '#EC1B23', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "fit-content" }}>
                          Vencida
                        </Form.Label>
                        :
                        null
                      } */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            {AllowViews['deudorAlimenticio'] ||
                                                deudorAlimenticio.estado_documentacion ===
                                                'Rechazado' ? (
                                                <Form.Control
                                                    size="sm"
                                                    required
                                                    id="deudorInput"
                                                    type="file"
                                                    accept=".pdf,.jpg,.jpeg"
                                                    onChange={(event) => {
                                                        handleArchivoPDFChange(
                                                            event,
                                                            5
                                                        );
                                                    }}
                                                />
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    {TitularAgecia.length === 0 ||
                                        TitularAgecia.agencia_detalle
                                            .numero_subagencia !== 0 ? null : (
                                        <Row
                                            className="mb-3"
                                            style={{
                                                borderBottom: 'inset',
                                                paddingBottom: '15px',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <Form.Group as={Col} md="7">
                                                <Form.Label
                                                    style={{
                                                        fontSize: '0.75rem',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    DECLARACIÓN JURADA DE
                                                    LIQUIDACIÓN MENSUAL
                                                </Form.Label>
                                                {/* {liquidacionMensual && liquidacionMensual.fecha_vencimiento ?
                          <Form.Text style={{ fontSize: "0.85rem", color: "black" }}><br />Fecha de Vencimiento: {liquidacionMensual.fecha_vencimiento}</Form.Text>
                          :
                          null
                        } */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                {AllowViews[
                                                    'liquidacionMensual'
                                                ] ? null : (
                                                    <Form.Label
                                                        className="botonCPA fw-bold"
                                                        style={{
                                                            backgroundColor:
                                                                'rgba(255, 0, 0, 0)',
                                                            color: liquidacionMensual.estado_documentacion
                                                                ? color[
                                                                liquidacionMensual
                                                                    .estado_documentacion
                                                                ]
                                                                : '#008185',
                                                            borderColor:
                                                                liquidacionMensual.estado_documentacion
                                                                    ? color[
                                                                    liquidacionMensual
                                                                        .estado_documentacion
                                                                    ]
                                                                    : '#008185',
                                                            borderRadius:
                                                                '20px',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            width: 'fit-content',
                                                        }}
                                                    >
                                                        {liquidacionMensual.estado_documentacion ===
                                                            'Aprobado'
                                                            ? 'Pendiente'
                                                            : liquidacionMensual.estado_documentacion.split(
                                                                ' '
                                                            )[0]}
                                                    </Form.Label>
                                                )}
                                                {/* {isExpired['liquidacionMensual'] ?
                          <Form.Label className="botonCPA fw-bold" style={{ backgroundColor: 'rgba(255, 0, 0, 0)', color: '#EC1B23', borderColor: '#EC1B23', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "fit-content" }}>
                            Vencida
                          </Form.Label>
                          :
                          null
                        } */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="3">
                                                {AllowViews[
                                                    'liquidacionMensual'
                                                ] ||
                                                    liquidacionMensual.estado_documentacion ===
                                                    'Rechazado' ? (
                                                    <Form.Control
                                                        size="sm"
                                                        id="liquidacionInput"
                                                        required
                                                        type="file"
                                                        accept=".pdf,.jpg,.jpeg"
                                                        onChange={(event) => {
                                                            handleArchivoPDFChange(
                                                                event,
                                                                6
                                                            );
                                                        }}
                                                    />
                                                ) : null}
                                            </Form.Group>
                                        </Row>
                                    )}
                                    {allowComent ? (
                                        <>
                                            <Row
                                                className="mb-3"
                                                style={{ paddingTop: '10px' }}
                                            >
                                                <Form.Group as={Col} md="7">
                                                    <Form.Label
                                                        style={{
                                                            fontSize: '0.75rem',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        COMENTARIO
                                                    </Form.Label>
                                                </Form.Group>
                                            </Row>
                                            <Row
                                                className="mb-3"
                                                style={{
                                                    borderBottom: 'inset',
                                                    paddingBottom: '15px',
                                                }}
                                            >
                                                <Form.Group as={Col} md="7">
                                                    {facturaElectronica &&
                                                        facturaElectronica.comentario ? (
                                                        <Row>
                                                            <Form.Text
                                                                style={{
                                                                    fontSize:
                                                                        '0.85rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                FACTURA
                                                                ELECTRÓNICA:{' '}
                                                                {
                                                                    facturaElectronica.comentario
                                                                }
                                                            </Form.Text>
                                                        </Row>
                                                    ) : null}
                                                    {cumplimientoFiscal &&
                                                        cumplimientoFiscal.comentario ? (
                                                        <Row>
                                                            <Form.Text
                                                                style={{
                                                                    fontSize:
                                                                        '0.85rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                CERTIFICADO DE
                                                                CUMPLIMIENTO
                                                                FISCAL:{' '}
                                                                {
                                                                    cumplimientoFiscal.comentario
                                                                }
                                                            </Form.Text>
                                                        </Row>
                                                    ) : null}
                                                    {inscripcionAFIP &&
                                                        inscripcionAFIP.comentario ? (
                                                        <Row>
                                                            <Form.Text
                                                                style={{
                                                                    fontSize:
                                                                        '0.85rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                CONSTANCIA DE
                                                                INSCRIPCION EN
                                                                AFIP:{' '}
                                                                {
                                                                    inscripcionAFIP.comentario
                                                                }
                                                            </Form.Text>
                                                        </Row>
                                                    ) : null}
                                                    {inscripcionRentas &&
                                                        inscripcionRentas.comentario ? (
                                                        <Row>
                                                            <Form.Text
                                                                style={{
                                                                    fontSize:
                                                                        '0.85rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                CONSTANCIA DE
                                                                INSCRIPCION EN
                                                                RENTAS:{' '}
                                                                {
                                                                    inscripcionRentas.comentario
                                                                }
                                                            </Form.Text>
                                                        </Row>
                                                    ) : null}
                                                    {tributoEconocimo &&
                                                        tributoEconocimo.comentario ? (
                                                        <Row>
                                                            <Form.Text
                                                                style={{
                                                                    fontSize:
                                                                        '0.85rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                CONSTANCIA DEL
                                                                TRIBUTO
                                                                ECONÓMICO
                                                                MUNICIPAL:{' '}
                                                                {
                                                                    tributoEconocimo.comentario
                                                                }
                                                            </Form.Text>
                                                        </Row>
                                                    ) : null}
                                                    {deudorAlimenticio &&
                                                        deudorAlimenticio.comentario ? (
                                                        <Row>
                                                            <Form.Text
                                                                style={{
                                                                    fontSize:
                                                                        '0.85rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                CERTIFICIÓN DE
                                                                NO ESTAR
                                                                REGISTRADO COMO
                                                                DEUDOR
                                                                ALIMENTICIO:{' '}
                                                                {
                                                                    deudorAlimenticio.comentario
                                                                }
                                                            </Form.Text>
                                                        </Row>
                                                    ) : null}
                                                    {liquidacionMensual &&
                                                        liquidacionMensual.comentario ? (
                                                        <Row>
                                                            <Form.Text
                                                                style={{
                                                                    fontSize:
                                                                        '0.85rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                DECLARACIÓN
                                                                JURADA DE
                                                                LIQUIDACIÓN
                                                                MENSUAL:{' '}
                                                                {
                                                                    liquidacionMensual.comentario
                                                                }
                                                            </Form.Text>
                                                        </Row>
                                                    ) : null}
                                                </Form.Group>
                                            </Row>
                                        </>
                                    ) : null}
                                </Card.Body>
                                <Card.Footer
                                    className="d-flex justify-content-end"
                                    style={{
                                        borderTop: 'none',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    {allowSend ? (
                                        <Button
                                            type="submit"
                                            className="botonCPA"
                                            style={{
                                                backgroundColor: '#008185',
                                                borderRadius: '20px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            ENVIAR
                                        </Button>
                                    ) : null}
                                </Card.Footer>
                            </Form>
                        </>
                    ) : (
                        <Card.Text>
                            El período de pago de comisiones a finalizado.{' '}
                            <br />
                            El envío de documentación se habilitará en la
                            próxima comisión. Gracias.
                        </Card.Text>
                    )}
                </Card>
            ) : null}
            <Modal centered show={isLoading}>
                <Modal.Header
                    className="text-center"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        fontSize: '25px',
                    }}
                >
                    <p style={{ maxWidth: '100%' }}>Operación Exitosa</p>
                </Modal.Header>
                <Modal.Body
                    className="text-center"
                    style={{ fontSize: '20px' }}
                >
                    <p style={{ maxWidth: '100%' }}>
                        Los documentos se subieron exitosamente
                    </p>
                </Modal.Body>
                <Modal.Footer
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <button
                        className="botonCPA me-3"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        OK
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={isLoadingView}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando Datos...</p>
                </Modal.Body>
            </Modal>
            <Modal centered show={isValidFile}>
                <Modal.Header
                    className="text-center"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        fontSize: '25px',
                    }}
                >
                    <p style={{ maxWidth: '100%' }}>
                        Tipo de Documento erroneo
                    </p>
                </Modal.Header>
                <Modal.Body
                    className="text-center"
                    style={{ fontSize: '20px' }}
                >
                    <p style={{ maxWidth: '100%' }}>
                        El documento seleccionado no es valido. Por favor elegir
                        un archivo pdf, jpg o jpeg
                    </p>
                </Modal.Body>
                <Modal.Footer
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <button
                        className="botonCPA me-3"
                        onClick={() => {
                            setIsValidFile(false);
                        }}
                    >
                        OK
                    </button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default SubirDocumentacion;
