import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Col, Row, Card } from "react-bootstrap";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import BtnVolver from "../../../common/BtnVolver";
import ReciboRendiciones from "./PDF/ReciboRendiciones";
import { AiOutlineDownload } from "react-icons/ai";
import { FaStepBackward } from "react-icons/fa";
import Parser from "../../../common/Parser";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import ReciboRendicionesLinea from "./PDF/GenerarRendicionesLinea";

const GenerarRecibosRndiciones = (props) => {
  // const rolUser = props.rolUsuario;

  const URL_RENDICION_AGENCIA = window.API_ROUTES.RENDICIONAGENCIA;

  const location = useLocation();
  const navigate = useNavigate();

  // const { rendiciones } = location.state.rendiciones;
  const [rendicionesTabla] = useState(location.state.rendiciones);
  const [rendicionesExport, setRendicionesExport] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);
  const handleGoBack = () => {
    navigate("/liquidaciones/rendiciones/cargar-rendiciones", {
      state: location.state,
    });
  };
  const columns = [
    {
      header: "Agencia",
      accessorKey: "nueva_agencia",
      size: 30,
    },
    {
      header: "Rendicion N°",
      accessorKey: "numeroRendicion",
      size: 30,
    },
    {
      header: "Total Ingreso",
      accessorKey: "totalIngreso",
      size: 30,
      Cell: ({ renderedCellValue }) => (
        <span>
          {Number.isNaN(parseInt(renderedCellValue))
            ? renderedCellValue
            : Parser.currency(renderedCellValue)}
        </span>
      ),
    },
    {
      header: "Total Egreso",
      accessorKey: "totalEgreso",
      size: 30,
      Cell: ({ renderedCellValue }) => (
        <span>
          {Number.isNaN(parseInt(renderedCellValue))
            ? renderedCellValue
            : Parser.currency(renderedCellValue)}
        </span>
      ),
    },
    {
      header: "Total a Rendir",
      accessorKey: "totalARendir",
      size: 30,
      Cell: ({ renderedCellValue }) => (
        <span>
          {Number.isNaN(parseInt(renderedCellValue))
            ? renderedCellValue
            : Parser.currency(renderedCellValue)}
        </span>
      ),
    },
  ];

  useEffect(() => {
    var data = [];
    if (Object.keys(rowSelection).length > 0) {
      const numbers = Object.keys(rowSelection);
      numbers.forEach((number) => {
        var agencia = rendicionesTabla[number].nueva_agencia;
        var id = rendicionesTabla[number].id;
        // var response = await ConsultasAPI.ListarObjetos()
        var titular = rendicionesTabla[number].titular;
        var domicilio = rendicionesTabla[number].domicilio;
        var telefono = rendicionesTabla[number].telefono;
        var detalle =
          parseFloat(rendicionesTabla[number].totalARendir.split(' ')[1].replace('.', '').replace(',', '.')) > 0 ?
            "Recibo de Cobro N°: " +
            rendicionesTabla[number].numeroRendicion +
            " (Rendicion Liquidada el " +
            rendicionesTabla[number].fechaRendicion +
            ")"
            :
            "Orden de Pago N°: " +
            rendicionesTabla[number].numeroRendicion +
            " (Rendicion Liquidada el " +
            rendicionesTabla[number].fechaRendicion +
            ")";
        var importe = rendicionesTabla[number].totalARendir;
        var objeto = {
          id,
          detalle,
          importe,
          agencia,
          titular,
          domicilio,
          telefono,
        };
        data = [...data, objeto];
      });
    } else {
      rendicionesTabla.forEach((rendicionTabla) => {
        var id = rendicionTabla.id;
        var agencia = rendicionTabla.nueva_agencia;
        // var response = await ConsultasAPI.ListarObjetos()
        var titular = rendicionTabla.titular;
        var domicilio = rendicionTabla.domicilio;
        var telefono = rendicionTabla.telefono;
        var detalle =
          parseFloat(rendicionTabla.totalARendir.split(' ')[1].replace('.', '').replace(',', '.')) > 0 ?
            "Recibo de Cobro N°: " +
            rendicionTabla.numeroRendicion +
            " (Rendicion Liquidada el " +
            rendicionTabla.fechaRendicion +
            ")"
            :
            "Orden de Pago N°: " +
            rendicionTabla.numeroRendicion +
            " (Rendicion Liquidada el " +
            rendicionTabla.fechaRendicion +
            ")"
          ;
        var importe = rendicionTabla.totalARendir;
        var objeto = {
          id,
          detalle,
          importe,
          agencia,
          titular,
          domicilio,
          telefono,
        };

        data = [...data, objeto];
      });
    }
    setRendicionesExport(data);
  }, [rowSelection]);

  const handleExport = async () => {
    if (Object.keys(rowSelection).length > 0) {
      const numbers = Object.keys(rowSelection);
      numbers.map(async (number) => {
        let formData = new FormData();
        const blob =  await ReciboRendicionesLinea({ datos: rendicionesTabla[number] })
        formData.append('rendiciones', rendicionesTabla[number].id);
        formData.append('linea_caja',blob);
        ConsultasAPI.CrearObjetoArchivo(URL_RENDICION_AGENCIA + "enviarLineaCaja/",formData)
          .then((response) => console.info(response))
          .catch((error) => console.error(error));
      });
    } else {
      rendicionesTabla.map(async (rendicion) => {
        let formData = new FormData();
        const blob =  await ReciboRendicionesLinea({ datos: rendicion})
        formData.append('rendiciones', rendicion.id);
        formData.append('linea_caja',blob);
        ConsultasAPI.CrearObjetoArchivo(URL_RENDICION_AGENCIA + "enviarLineaCaja/",formData)
          .then((response) => console.info(response))
          .catch((error) => console.error(error));
        return rendicion.id;
      });
    }
  };

  return (
    <Container className="mainSection">
      <Row>
        <Col>
          <h1 className="py-4 fw-bold">Generar Recibos</h1>
        </Col>
      </Row>
      <Row></Row>
      <Row className="mt-4">
        <Card>
          <Row>
            <div className="my-3">
              <MaterialReactTable
                columns={columns}
                data={rendicionesTabla}
                initialState={{ columnVisibility: { id: false } }}
                enableColumnOrdering={false}
                enableGlobalFilter={true}
                localization={MRT_Localization_ES}
                enableRowSelection
                selectAllMode="all"
                enableSelectAll={true}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }} //pass our managed row selection state to the table to use
              />
            </div>
          </Row>
          <Row>
            {/* <Col>b
              <div className="d-flex justify-content-center">
                <Button
                  className="btn botonCPA my-4"
                  onClick={() => navigate(-1)}
                >
                  CANCELAR
                </Button>
              </div>
            </Col> */}
            <Col>
              <div className="d-flex justify-content-center">
                <ReciboRendiciones
                  datos={rendicionesExport}
                  onExport={() => handleExport()}
                >
                  <AiOutlineDownload className="iconAntD" />
                  DESCARGAR RECIBOS
                </ReciboRendiciones>
              </div>
            </Col>
          </Row>
        </Card>

        <section className="d-flex justify-content-end my-3">
          {location.state.upload ? (
            <button
              className="justify-content-center btn botonCPA me-3 d-flex align-items-center"
              onClick={handleGoBack}
              style={props.style}
            >
              <FaStepBackward /> Volver
            </button>
          ) : (
            <BtnVolver />
          )}
        </section>
      </Row>
    </Container>
  );
};

export default GenerarRecibosRndiciones;
