// import React from "react";
import { Container, Form, Button, Card, Row, Col } from "react-bootstrap";
import { FaSignInAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import LoginApi from "./api/LoginApi";
// import cliente from "../../../img/financiera_id2.png";
import React, { useState } from "react";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import Notificaciones from '../../../shared/helpers/notificacionesToast';
import PasswordResetForm from "./PasswordResetForm";
import  { Toaster } from "react-hot-toast";
import CambiarClave from "./CambiarClave";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const LoginForm = (props) => {
  const [allowLogin, setAllowLogin] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleRecaptcha = async (value) => {
    const response = await axios.post('login/recaptcha/', { recaptchaToken: value });
    if (response.status === 200) {
      setAllowLogin(response.data.isValid);
    } else {
      setAllowLogin(false);
    }
  }

  function loginAcceder(values) {
    LoginApi.Login(values.email, values.password)
      .then((response) => {
        if (!response.data.rol) {
          Notificaciones.notificacion("El usuario no posee un rol");
          AuthenticationHelper.logout();
        } else if (response.data.has_changed_password) {
          // Si el usuario ha cambiado su clave, permitir el acceso a las rutas
          // que corresponden a su rol
          props.onLogin(values.email, true, response.data.rol);
          // navigate("/");
        } else {
          // Si el usuario no ha cambiado su clave, redirigir a la ventana de cambio de clave
          props.onLogin(values.email, false, response.data.rol);
          // navigate("/cambiar-clave");
          // <CambiarClave rolUsuario={rolUser}/>
        }
      })
      .catch((error) => {
        console.error(error)
        Notificaciones.notificacion("No se pudo establecer la conexión");
      });
  }


  return (
    <Container className="mainSection my-5">
      <Toaster />
      <section>
        <Row className="d-flex justify-content-center g-0">
          <Col md="8" style={{ display: "flex", flexWrap: "nowrap", flexDirection: "column", alignItems: "center", marginTop: "-60px" }}>
            <div id="ComponenteDelante" className="circulo" />
            <Card className="d-flex justify-content-center" id="ComponenteFijo" style={{width:'80%'}}>
              <Row className="d-flex justify-content-center" style={{ padding: "25px" }}>
                <Col md="5" className="d-flex justify-content-center align-items-center m-3" >
                  <div>
                    <Form className="my-2" onSubmit={handleSubmit(loginAcceder)} style={{ width: "max-content" }}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fs-4">USUARIO</Form.Label>
                        <Form.Control
                          type="email"
                          autoComplete="off" // Deshabilitar autocompletado para este campo
                          placeholder="Ingrese su correo electrónico"
                          style={{ width: '300px' }}//Ajusta el valor de 'width' según tus preferencias
                          {...register("email", {
                            required: "El nombre de usuario es obligatorio",
                            minLength: {
                              value: 2,
                              message:
                                "La cantidad minima de caracteres debe ser 2",
                            },
                            maxLength: {
                              value: 100,
                              message:
                                "La cantidad maxima de caracteres debe ser 100",
                            },
                          })}
                        />
                        <Form.Text className="text-danger">
                          {errors.descripcion?.message}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="fs-4">CONTRASEÑA</Form.Label>
                        <Form.Control
                          type="password"
                          autoComplete="off" // Deshabilitar autocompletado para este campo
                          placeholder="Ingrese su contraseña"
                          {...register("password", {
                            required: "Debe ingresar una contraseña",
                            minLength: {
                              value: 2,
                              message:
                                "La cantidad minima de caracteres debe ser 2",
                            },
                            maxLength: {
                              value: 100,
                              message:
                                "La cantidad maxima de caracteres debe ser 100",
                            },
                          })}
                        />
                        <Form.Text className="text-danger">
                          {errors.descripcion?.message}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
                        <Form.Check type="checkbox" id="recordarme">
                          <Form.Check.Input />
                          <Form.Check.Label style={{ fontSize: '12px' }}>RECORDARME</Form.Check.Label>
                        </Form.Check>
                        <Link to="/recuperar-clave" className="red-link" style={{ fontSize: '12px' }}>OLVIDÉ MI CONTRASEÑA</Link>
                      </Form.Group>
                      {window.location.href === 'https://pruebajuegos.cajapopular.gov.ar/login' || window.location.href === 'https://agenciasdequiniela.cajapopular.gov.ar/login' ?
                        <>
                          <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
                            <ReCAPTCHA
                              sitekey="6LfATVYpAAAAAPjx5LiuxKxrRz4HK2dmXyEl-3GH"
                              onChange={(value) => { handleRecaptcha(value) }}
                              required
                            />
                          </Form.Group>
                          <section className="d-flex justify-content-center mb-3">
                            {allowLogin ?
                              <Button className="botonCPA fw-bold" type="submit" style={{ fontSize: '12px', width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                INGRESAR
                              </Button>
                              :
                              <Button className="botonCPA fw-bold" style={{ backgroundColor: 'grey', borderColor: 'grey', fontSize: '12px', width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: "none" }}>
                                INGRESAR
                              </Button>
                            }
                          </section>
                        </>
                        :
                        <section className="d-flex justify-content-center mb-3">
                          <Button className="botonCPA fw-bold" type="submit" style={{ fontSize: '12px', width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            INGRESAR
                          </Button>
                        </section>
                      }
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default LoginForm;
