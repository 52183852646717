import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Button, Form } from 'react-bootstrap';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import moment from 'moment';
import BtnVolver from '../../../common/BtnVolver';
import Parser from '../../../common/Parser';
import ConsultasAPI from '../../../../shared/helpers/consultasAPI';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaEraser } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { ModalNuevaComisionTuqui } from './ModalNuevaComisionTuqui';
import { FaPlusCircle } from 'react-icons/fa';


const ComisionesTuqui = (props) => {
    const rolUser = props.rolUsuario;


    const [periodo, setPeriodo] = useState(moment().format('MM/YYYY'));
    const [agencias, setAgencias] = useState([]);
    const [estados] = useState([
        { id: true, descripcion: 'Aprobado' },
        { id: false, descripcion: 'Pendiente' },
    ]);

    const [agencia, setAgencia] = useState();
    const [estado, setEstado] = useState();
    const [comisionesTabla, setComisionesTabla] = useState([]);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const URL_AGENCIAS = window.API_ROUTES.AGENCIAS;
    const URL_COMISION = window.API_ROUTES.COMISION;

    var valid = function (current) {
        const today = moment();
        const isSunday = current.day() === 0;
        return current.isBefore(today) && !isSunday;
    };

    const [showModalNuevaComisionTuqui, setShowModalNuevaComisionTuqui] =
        useState(false);
    const [tituloModal, setTituloModal] = useState('');

    const handleCloseModalNuevaComisionTuqui = () => {
        loadComisiones();
        setShowModalNuevaComisionTuqui(false);
    };

    const handleOpenModalNuevaComisionTuqui = () => {
        setTituloModal('Nuevo');
        setShowModalNuevaComisionTuqui(true);
    };

    useEffect(() => {
        loadComisiones();
    }, [pagination, agencia, estado, periodo]);

    useEffect(() => {}, []);

    const loadComisiones = () => {
        const filters = {
            agencia: agencia && agencia !== '-' ? agencia : '',
            aprobado:
                estado !== undefined && estado !== '-'
                    ? estado === 'true' || estado === true
                        ? 'aprobado'
                        : 'pendiente'
                    : '',
            periodo,
        };

        ConsultasAPI.ListarObjetos(
            URL_COMISION,
            pagination.pageIndex,
            pagination.pageSize,
            filters
        ).then((response) => {
            setCount(response.data.count);
            var datos = response.data.results;
            setComisionesTabla(datos);
        });
        ConsultasAPI.ListarObjetos(URL_AGENCIAS + 'ListaFiltrar/').then(
            (response) => {
                setAgencias(response.data);
            }
        );
    };

    const handleActualizarComisiones = async () => {
        try {
            const periodo1 = {
                periodo: moment('01/'+periodo,"DD/MM/YYYY").format("YYYY-MM-DD"),
            };
            const actualizar = await ConsultasAPI.CrearObjeto(
                URL_COMISION,
                periodo1
            );
            if (actualizar.status === 200) {
                loadComisiones();
            }
        } catch (error) {
            Swal.fire(
                'Error en actualizacion',
                error.response.data.error,
                'error'
            );
        }
    };

    const columns = [
        {
            header: 'id',
            accessorKey:'id',
            size: 30,
        },
        {
            header: 'Agencia',
            accessorKey:
                'ventas.titular_agencia.agencia_detalle.numero_agencia',
            size: 30,
        },
        {
            header: 'Subagencia',
            accessorKey:
                'ventas.titular_agencia.agencia_detalle.numero_subagencia',
            size: 30,
        },
        {
            header: 'Titular',
            accessorKey:
                'ventas.titular_agencia.titular_detalle.persona_detalle.cuit',
            size: 30,
        },

        {
            header: 'Comisión Venta',
            accessorKey: 'comision_venta',
            size: 30,
            Cell: ({ renderedCellValue }) => (
                <span>{Parser.currency(renderedCellValue)}</span>
            ),
        },
        {
            header: 'Comisión Venta Total ag + sub',
            accessorKey: 'comision_venta_total',
            size: 30,
            Cell: ({ renderedCellValue }) => (
                <span>{Parser.currency(renderedCellValue)}</span>
            ),
        },
        {
            header: 'Venta Total ag + sub',
            accessorKey: 'ventas_agencia_subagencia',
            size: 30,
            Cell: ({ renderedCellValue }) => (
                <span>{Parser.currency(renderedCellValue)}</span>
            ),
        },
        {
            header: 'Comisión Bruta',
            accessorKey: 'comision_bruta',
            size: 30,
            Cell: ({ renderedCellValue }) => (
                <span>{Parser.currency(renderedCellValue)}</span>
            ),
        },
    ];

    const handleClickClear = () => {
        setPeriodo(moment().format('MM/YYYY'));
        setAgencia('-');
        setEstado('-');
    };

    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Comisiones - Tuqui</h1>
                </Col>

                <Col className="d-flex justify-content-end my-3 ">
                    {rolUser === 'ADMINISTRADOR' ||
                    rolUser === 'DOCUMENTACION' ||
                    rolUser === 'CONTADURIA' ||
                    rolUser === 'GERENCIA-QUINIELA' ? (
                        <Button
                            className="btn botonCPA m-3"
                            onClick={handleOpenModalNuevaComisionTuqui}
                        >
                            <FaPlusCircle className="iconAntD" />
                            AGREGAR
                        </Button>
                    ) : null}
                </Col>
            </Row>
            <Card>
                <Card.Body>
                    <Row>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Periodo</Form.Label>
                            <Datetime
                                isValidDate={valid}
                                timeFormat={false}
                                dateFormat="MM/YYYY"
                                inputProps={{
                                    readOnly: true,
                                    placeholder: periodo,
                                }}
                                value={periodo}
                                onChange={
                                    //setPeriodo
                                    (momentDate) => {
                                        const fechaMuestra =
                                            momentDate.format('MM/YYYY');
                                        setPeriodo(fechaMuestra);
                                    }
                                }
                                allowClear
                                closeOnSelect
                                // onClear={() => setPeriodo("")}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="2">
                            <Form.Label>Agencia</Form.Label>
                            <Form.Select
                                // style={{ width: "20%", height: "37px" }}
                                value={agencia}
                                onChange={(event) =>
                                    setAgencia(event.target.value)
                                }
                                disabled={agencias.length < 1}
                            >
                                <option hidden>-</option>
                                {agencias
                                    .filter(
                                        (agencia) =>
                                            agencia.numero_subagencia === 0
                                    )
                                    .map((agencia) => (
                                        <option
                                            key={agencia.id}
                                            value={agencia.numero_agencia}
                                        >
                                            {agencia.numero_agencia}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="2">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select
                                // style={{ width: "20%", height: "37px" }}
                                value={estado}
                                onChange={(event) =>
                                    setEstado(event.target.value)
                                }
                                disabled={estados.length < 1}
                            >
                                <option hidden>-</option>
                                {estados.map((estado) => (
                                    <option key={estado.id} value={estado.id}>
                                        {estado.descripcion}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Button
                                className="btn botonCPA me-3"
                                onClick={handleClickClear}
                                style={{
                                    alignContent: 'center',
                                    margin: 15,
                                    float: 'right',
                                }}
                            >
                                <FaEraser className="iconAntD" />
                                Limpiar Filtro
                            </Button>
                        </Form.Group>
                    </Row>
                </Card.Body>
                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={comisionesTabla}
                    initialState={{ columnVisibility: { id: false } }}
                    // onEditingRowSave={handleSaveRowEdits}
                    // onEditingRowCancel={handleCancelRowEdits}
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableGlobalFilter={true} //turn off a feature
                    enableFilters={false}
                    localization={MRT_Localization_ES}
                    // enableRowActions
                    positionActionsColumn="last"
                    manualPagination
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    // state={{ pagination }} //pass the pagination state to the table
                    state={{
                        pagination,
                    }}
                />
                <section
                    className="d-flex justify-content-end my-3"
                    style={{ marginRight: 10 }}
                >
                    <BtnVolver route="/liquidaciones/comisiones" />

                    <Button
                        className="botonCPA"
                        style={{ marginLeft: 12 }}
                        variant="outline-info"
                        disabled={!periodo}
                        onClick={() => handleActualizarComisiones()}
                    >
                        <AiOutlineDownload className="iconAntD" />
                        Actualizar comisiones
                    </Button>
                </section>
            </Card>

            <ModalNuevaComisionTuqui
                onClose={handleCloseModalNuevaComisionTuqui}
                show={showModalNuevaComisionTuqui}
                titulo={tituloModal}
            />
        </Container>
    );
};

export default ComisionesTuqui;
