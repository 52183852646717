import React from "react";
import { Upload, Button } from "antd";
import Papa from "papaparse";
import { InboxOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

const { Dragger } = Upload;

class CSVReader extends React.Component {
  state = {
    uploadedFile: null, // Estado para almacenar el archivo cargado
  };

  handleFileUpload = async (file) => {
    if (this.props.isLoading) {
      this.props.isLoading(true);
    }
    if (file) {
      if (file.type == "text/csv") {
        const csvData = await this.readCSVFile(file);
        const parsedData = this.parseCSVData(csvData);
        this.props.onFileUploaded(parsedData);
        this.setState({ uploadedFile: file }); // Actualizar el archivo cargado
      } else {
        Swal.fire(
          "Ocurrio un error",
          "El archivo debe tener el formato CSV.",
          "error"
        );
      }
    } else {
      this.setState({ uploadedFile: null });
    }
    if (this.props.isLoading) this.props.isLoading(false);
  };

  readCSVFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsText(file);
    });
  };

  parseCSVData = (csvData) => {
    const parsedData = Papa.parse(csvData, { header: true });
    return parsedData.data;
  };

  handleRemoveFile = (file) => {
    console.log("archivo eliminado");
    this.props.onRemoveFile(file);
    this.setState({ uploadedFile: null }); // Eliminar el archivo cargado
  };

  render() {
    const { uploadedFile } = this.state;

    return (
      <Upload.Dragger
        accept=".csv"
        customRequest={({ file }) => {
          this.handleFileUpload(file);
        }}
        onRemove={this.handleRemoveFile} // Agregar el controlador de eliminación
        style={{ width: "100%", height: "60%" }}
        fileList={uploadedFile ? [uploadedFile] : []} // Mostrar el archivo cargado actualmente
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Haz clic o arrastra un archivo aquí para cargarlo
        </p>
        <p className="ant-upload-hint">
          {this.props.showMessage ? this.props.showMessage :"Solo se permiten archivos en formato CSV"}
        </p>
      </Upload.Dragger>
    );
  }
}

export default CSVReader;
