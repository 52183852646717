import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import 'moment/locale/es';
import moment from 'moment-timezone';
import 'pdfmake/build/vfs_fonts';
import { API_ROUTES, BASE_URL } from './apiRoutes';
import axios from "axios";
const root = ReactDOM.createRoot(document.getElementById('root'));
const MomentConfig = {
  tz: 'America/Argentina/Buenos_Aires',
};

moment.locale('es');
moment.tz.setDefault(MomentConfig.tz);

// Configurar variable global
window.API_ROUTES = API_ROUTES;
window.BASE_URL = BASE_URL;
axios.defaults.baseURL = window.BASE_URL;

root.render(
  <React.StrictMode>
    <ConfigProvider locale={esES}>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

