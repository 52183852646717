/* eslint-disable */

import React, { useCallback, useMemo, useState, useEffect } from "react";
import BtnVolver from "../../common/BtnVolver";
import { Container, Col, Row, Button, Modal, Spinner } from "react-bootstrap";
import { ModalUsuario } from "./ModalUsuario";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Swal from "sweetalert2";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { ImUserCheck, ImUserMinus } from "react-icons/im";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import getNombreEstado from "../../../shared/helpers/estados";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import * as XLSX from "xlsx";
import {FaFileDownload} from "react-icons/fa";

const BuscarUsuario = (props) => {
  const rolUser = props.rolUsuario;
  const URL_USUARIOS = window.API_ROUTES.USUARIOS;
  const [showModal, setShowModal] = useState(false); // Modal para editar o crear un nuevo elemento
  const [elemento, setElemento] = useState(null); // elemento a modificar, si lo hay
  const [tableData, setTableData] = useState([]); //actualiza los valores de la tabla
  const [nombreModal, setNombreModal] = useState(""); // indica el nombre de la ventana modal
  const [validationErrors, setValidationErrors] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [count, setCount] = useState();
  const [n, setN] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const columns = useMemo(() => [
    {
      header: "ID",
      accessorKey: "id", //simple accessorKey pointing to flat data
      enableEditing: false, //deshabilitar la edición en esta columna
      size: 50,
    },
    {
      header: "Email",
      accessorKey: "email",
      size: 140,
    },
    {
      header: "Apellido",
      accessorKey: "apellido",
      size: 100,
    },
    {
      header: "Nombre",
      accessorKey: "nombre",
      size: 100,
    },
    {
      header: "Legajo",
      accessorKey: "legajo",
      size: 50,
    },
    {
      header: "Documento",
      accessorKey: "documento",
      size: 50,
    },
    {
      header: "Rol",
      accessorKey: "rol_detalle",
      size: 100,
    },
    {
      header: "Estado",
      accessorKey: "is_active",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Ultimo Ingreso",
      accessorKey: "last_login",
      enableEditing: false, //deshabilitar la edición en esta columna
      size: 80,
      enableColumnFilter: false,
    },
  ]);

  useEffect(() => {
    cargartabla();
  }, [pagination, columnFilters]); // ,[] indica que solo se ejecuta en montaje

  const cargartabla = () => {
    ConsultasAPI.ListarObjetos(URL_USUARIOS, pagination.pageIndex, pagination.pageSize, columnFilters)
      .then((response) => {
        setCount(response.data.count);
        setN(response.data.count / 10);
        let usuarios = [];
        response.data.results.map((usuario) => {
          usuario.is_active = getNombreEstado(usuario.is_active);
          usuarios.push(usuario);
        });
        setTableData(usuarios);
      })
      .catch((err) => console.error(err));
  };

  const borrarUsuario = useCallback((id) => {
    Swal.fire({
      title: "Estás seguro?",
      text: "Esta accion eliminará el usuario",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#008185",
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        ConsultasAPI.BorrarObjeto(URL_USUARIOS, id)
          .then((respuesta) => {
            if (respuesta.status === 204) {
              Swal.fire(
                "Usuario eliminado",
                "El usuario fue eliminado exitosamente",
                "success"
              );

              ConsultasAPI.ListarObjetos(URL_USUARIOS).then((response) => {
                setTableData(response.data.results);
              });
            }
          })
          .catch((error) => {
            Swal.fire("Ocurrió un error", error.response.data.error, "error");
          });
      }
    });
  });

  const cambiarEstadoUsuario = async (id, email, estado) => {
    let usuario_modificar = {
      id: id,
      email: email,
      // estado nuevo, dependiendo del estado actual se setea
      is_active: estado === "Activo" ? false : true,
    };
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción cambiará el estado del usuario",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#008185",
      confirmButtonText: estado === "Activo" ? "Desactivar" : "Activar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        ConsultasAPI.ModificarObjeto(URL_USUARIOS, id, usuario_modificar)
          .then((respuesta) => {
            if (respuesta.status === 200) {
              ConsultasAPI.ListarObjetos(URL_USUARIOS)
                .then((response) => {
                  let usuarios = [];
                  response.data.results.map((usuario) => {
                    usuario.is_active = getNombreEstado(usuario.is_active);
                    usuarios.push(usuario);
                  });
                  setTableData(usuarios);
                })
                .catch((err) => console.error(err));
              // se dio de baja o de alta el usuario
              Swal.fire(
                "Usuario modificado",
                respuesta.data.is_active
                  ? "Usuario dado de alta con éxito"
                  : "Usuario dado de baja con éxito",
                "success"
              );
            }
          })
          .catch((error) => {
            console.error(error);
            Swal.fire("Ocurrio un error", error, "error");
          });
      }
    });
  };

  // ******************** FUNCIONES TABLE**********************
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleOpenEditModal = async (id) => {
    // Obtener los datos del departamento a editar
    try {
      const oficina = await ConsultasAPI.ObtenerObjeto(URL_USUARIOS, id);
      const oficinaReducida = {
        id: id,
        departamento_detalle: oficina.data.departamento_detalle,
        descripcion: oficina.data.descripcion,
        estado: oficina.data.estado,
      };
      setNombreModal("Modificar Usuario");
      setElemento(oficina.data);
      setShowModal(true);
    } catch (error) {
    }
  };

  // Definir la función que se encarga de cerrar el modal
  const handleOpenModal = () => {
    setElemento(null);
    setNombreModal("Nuevo Usuario");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setElemento(null);
    setShowModal(false);
  };

  const handleXlsx = async () => {
    setIsLoading(true);
    let datos = []
    await ConsultasAPI.ListarObjetos(URL_USUARIOS + 'ListSinPag/', null, null, columnFilters).then((response) => {
      let usuarios = [];
      response.data.map((usuario) => {
        let user = {
          Email: usuario.email,
          Nombre: usuario.nombre,
          Apellido: usuario.apellido,
          Legajo: usuario.legajo,
          Documento: usuario.documento,
          Rol: usuario.rol_detalle,
          Estado: usuario.is_active ? 'Activo' : 'De Baja',
          'Ultimo Ingreso': usuario.last_login,
          'Agencia a Cargo': usuario.agencia ? usuario.agencia : 'No Posee',
        }
        usuarios.push(user);
      });
      datos = usuarios;
    }).catch((err) => {
      console.error(err)
      Swal.fire("Ocurrio un error", err, "error");
    });
    const worksheet = XLSX.utils.json_to_sheet(datos.flat());
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      "Usuarios.xlsx"
    );
    setIsLoading(false);
  }

  return (
    <>
      <Container fluid className="mainSection">
        <Row className="m-3">
          <Col>
            <h1 className="py-4 fw-bold">Usuarios</h1>
          </Col>
          <Col className="d-flex justify-content-end m-3 ">
            {AuthenticationHelper.getRol() &&
              (rolUser === "ADMINISTRADOR" || rolUser === "GERENCIA-QUINIELA") ? (
              <>
                <Button
                  className="btn botonCPA m-3"
                  onClick={handleOpenModal}
                  variant="contained"
                >
                  <strong>+</strong> NUEVO
                </Button>
              </>
            ) : null}
          </Col>
        </Row>
        <Row className="m-3">
          <MaterialReactTable
            className="w-100"
            columns={columns}
            data={tableData}
            initialState={{ showColumnFilters: true, columnVisibility: { id: false }, }} //hide firstName column by default
            editingMode="modal" //default
            enableEditing
            enableDensityToggle={false}
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={handleCancelRowEdits}
            enableRowSelection={false} //enable some features
            enableColumnOrdering={false}
            // enableGlobalFilter={true} //turn off a feature
            localization={MRT_Localization_ES}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => {
              if (AuthenticationHelper.getRol() && (rolUser === "ADMINISTRADOR" || rolUser === "GERENCIA-QUINIELA")) {
                return (
                  <Box className="d-flex">
                    <Button
                      className="m-1"
                      title="Editar"
                      variant="outline-info"
                      onClick={() => handleOpenEditModal(row.getValue("id"))}
                    >
                      <BsFillPencilFill />
                    </Button>
                    {row.getValue("is_active") === "Activo" ? (
                      <Button
                        variant="outline-warning"
                        className="m-1"
                        title="Dar de Baja"
                        onClick={() =>
                          cambiarEstadoUsuario(
                            row.getValue("id"),
                            row.getValue("email"),
                            row.getValue("is_active")
                          )
                        }
                      >
                        <ImUserMinus />
                      </Button>
                    ) : (
                      <Button
                        variant="outline-success"
                        className="m-1"
                        title="Dar de Alta"
                        onClick={() =>
                          cambiarEstadoUsuario(
                            row.getValue("id"),
                            row.getValue("email"),
                            row.getValue("is_active")
                          )
                        }
                      >
                        <ImUserCheck />
                      </Button>
                    )}
                    {/* <Button
                      className="m-1"
                      title="Eliminar"
                      variant="outline-danger"
                      onClick={() => borrarUsuario(row.getValue("id"))}
                    >
                      <BsFillTrashFill />
                    </Button> */}
                  </Box>
                );
              } else {
                return null;
              }
            }}
            manualPagination
            muiTablePaginationProps={{
              rowsPerPageOptions: [10]
            }}
            enablePagination={true}
            rowCount={count}
            onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
            enableHiding={false}
            enableGlobalFilter={false} //turn off a feature
            manualFiltering //turn off client-side filtering
            onColumnFiltersChange={value => { setColumnFilters(value) }} //hoist internal columnFilters state to your state
            state={{
              columnFilters,
              pagination,
            }}
          />

          <section className="d-flex justify-content-end my-3">
            <button className="btn botonCPA me-3" onClick={() => { handleXlsx() }}>
              <FaFileDownload className="iconAntD" />
              Descargar Excel
            </button>
            <BtnVolver />
          </section>
          <ModalUsuario
            show={showModal}
            onClose={handleCloseModal}
            setTableData={setTableData}
            nombre={nombreModal}
            usuario={elemento}
          />

        </Row>
      </Container>
      <Modal
        centered
        show={isLoading}
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Descargando Archivo...</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuscarUsuario;
