import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Notificaciones from "../../../shared/helpers/notificacionesToast";
import Request from "../../../shared/helpers/request";

import { FaEye, FaEyeSlash } from "react-icons/fa";

const NewPassword = () => {
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //  States para mostrar claves
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowconfirmPassword] = useState(false);

  const handleClickNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickConfirmPassword = () =>
    setShowconfirmPassword(!showConfirmPassword);

  const [isValidLink, setIsValidLink] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function checkLinkValidity() {
      try {
        // Haga una solicitud al backend para verificar si el enlace de reinicio es válido
        const response = await Request.get(
          `login/password/reset/validate/${uidb64}/${token}/`
        );
        if (response.status === 200) {
          setIsValidLink(true);
        }
      } catch (error) {
        console.clear();
        setMessage({
          text: "El enlace de restablecimiento de contraseña no es válido o ha expirado.",
          variant: "danger",
        });
        setTimeout(() => {
          setMessage(null);
          navigate("/");
        }, 7000);
      }
    }
    checkLinkValidity();
  }, [uidb64, token]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validar que la contraseña tenga al menos 8 caracteres, una mayúscula, y no contenga solo letras o números
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*[\W_\x7B-\xFF]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setMessage({
        text: "La nueva contraseña debe tener al menos 8 caracteres, una mayúscula, y contener al menos un número",
        variant: "danger",
      });
      setTimeout(() => {
        setMessage(null);
      }, 7000);
      return;
    }

    if (newPassword != confirmPassword) {
      setMessage({
        text: "La nueva contraseña debe coincidir con la confirmacion",
        variant: "danger",
      });
      setTimeout(() => {
        setMessage(null);
      }, 7000);
      return;
    }

    try {
      const response = await Request.post(
        `login/password/reset/confirm/${uidb64}/${token}/`,
        {
          new_password: newPassword,
          confirm_new_password: confirmPassword,
        }
      );
      console.log(response.status);
      if (response.status === 200) {
        Notificaciones.notificacion("Clave cambiada correctamente");
        setTimeout(() => {
          navigate("/");
        }, 1000); // Redireccionar a la pantalla principal después de 3 segundos
      }

    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Manejar el error de contraseña no válida
        Notificaciones.notificacion(
          "La nueva contraseña no puede ser una de las últimas tres contraseñas."
        );
      }else {
        // Manejar otros errores
        Notificaciones.notificacion(
          "No se pudo cambiar la clave. Inténtalo de nuevo más tarde."
        );
      }
      console.clear();
    }
  };

  return (
    <>
      {isValidLink ? (
        <Container fluid className="mainSection my-md-5 p-3">
          <Row className="justify-content-center">
            <Col md={6}>
              <Card>
                <Card.Header as="h5">¿Olvido su contraseña?</Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="newPassword">
                      <Form.Label>Nueva clave:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showNewPassword ? "text" : "password"}
                          autoComplete="off" // Deshabilitar autocompletado para este campo
                          value={newPassword}
                          onChange={(event) =>
                            setNewPassword(event.target.value)
                          }
                          required
                        />
                        <Button
                          variant="link"
                          type="button"
                          onClick={handleClickNewPassword}
                        >
                          {showNewPassword ? (
                            <FaEyeSlash title="Ocultar clave" color="#777777" />
                          ) : (
                            <FaEye title="Mostrar clave" color="#777777" />
                          )}
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                      <Form.Label>Confirma nueva clave:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          autoComplete="off" // Deshabilitar autocompletado para este campo
                          value={confirmPassword}
                          onChange={(event) =>
                            setConfirmPassword(event.target.value)
                          }
                          required
                        />
                        <Button
                          variant="link"
                          type="button"
                          onClick={handleClickConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <FaEyeSlash title="Ocultar clave" color="#777777" />
                          ) : (
                            <FaEye title="Mostrar clave" color="#777777" />
                          )}
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <p
                      style={{
                        marginTop: "1rem",
                        color: "#777777",
                        fontSize: "0.8rem",
                      }}
                    >
                      La nueva contraseña debe tener al menos 8 caracteres, una
                      mayúscula, y contener al menos un número.
                    </p>
                    {message && (
                      <div
                        className={`alert alert-${message.variant} my-4`}
                        role="alert"
                      >
                        {message.text}
                      </div>
                    )}
                    <article className="my-4 mb-3 d-flex justify-content-end">
                      <Button className="botonCPA" type="submit">
                        Cambiar contraseña
                      </Button>
                    </article>
                  </Form>
                </Card.Body>
                <Toaster />
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="mainSection my-md-5 p-3">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="alert-container">
              {message && (
                <div
                  className={`alert alert-${message.variant} my-4`}
                  role="alert"
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  {message.text}
                </div>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default NewPassword;
