/* eslint-disable */

import React, { useState, useEffect } from 'react';
import BtnVolver from '../../common/BtnVolver';
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Form as F,
    Modal,
    Spinner,
} from 'react-bootstrap';
import { Table, Select, Form } from 'antd';
import moment from 'moment';
import ExcelReader from '../exportaciones/ExcelReader.jsx';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Swal from 'sweetalert2';
import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import AuthenticationHelper from '../../../shared/helpers/authenticationHelper';
import Notificaciones from '../../../shared/helpers/notificacionesToast';
import Extractos from './Extractos';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCluster } from 'react-icons/ai';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaPlus, FaFileDownload, FaEraser } from "react-icons/fa";

const FormItem = Form.Item;

const SubirExtracto = (props) => {
    const rolUser = props.rolUsuario;
    const URL_EXTRACTO = window.API_ROUTES.EXTRACTO;
    const URL_EXTRACTOREGISTRO = window.API_ROUTES.EXTRACTOREGISTRO;
    const URL_TIPOSORTEO = window.API_ROUTES.TIPOSORTEO;
    const URL_SORTEOEXCEPTUADO = window.API_ROUTES.SORTEOEXCEPTUADO;

    const [archivo, setArchivo] = useState(null);
    const [numeroSorteo, setNumeroSorteo] = useState('XXXX');
    const [fecha, setFecha] = useState({
        fechaMuestra: moment().format('DD/MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const [tipo, setTipo] = useState([]);
    const [datosTabla, setDatosTabla] = useState([]);
    const [numerosExtracto, setNumerosExtracto] = useState([]);
    const [mostrarError, setMostrarError] = useState(false);
    const [tipos, setTipos] = useState([]);
    const [tiposSubidos, setTiposSubidos] = useState([]);
    const [tiposSubidosEnFecha, setTiposSubidosEnFecha] = useState([]);
    const [fechaPendiente, setFechaPendiente] = useState(false);
    const [comentario, setComentario] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showXLSX, setShowXLSX] = useState(false);
    const [apiSmartoplay, SetApiSmartoplay] = useState(false);
    var currentTime = moment().utcOffset('-03:00').format('HH:mm');
    var currentDate = moment().utcOffset('-03:00').format('YYYY-MM-DD');
    const navigate = useNavigate();

    useEffect(() => {
        cargarTipo();
        sorteoPendiente();
    }, []);

    useEffect(() => {
        cargarTipo();
        controlarSubidos();
    }, [fecha]);

    useEffect(() => {
        if (archivo) {
            cargarArchivo();
        }
    }, [fecha, tipo, archivo]);

    //Carga los tipos de sorteo en la lista desplegable dependiendo de la hora final del sorteo y la actual, y tambien del estado de los sorteo
    const cargarTipo = () => {
        if (
            AuthenticationHelper.getRol() &&
            (rolUser === 'ADMINISTRADOR' ||
                rolUser === 'GERENCIA-QUINIELA' ||
                rolUser === 'OPERADOR-EXTRACTO')
        ) {
            ConsultasAPI.ListarObjetos(URL_TIPOSORTEO).then((response) => {
                const objetosOrdenados = ordenarEnRango(
                    response.data.results.filter(
                        (tipo) => tipo.estado === true
                    ),
                    currentTime,
                    '23:59'
                );
                let tiposExceptuar = [];
                ConsultasAPI.ObtenerObjeto(
                    URL_SORTEOEXCEPTUADO + 'busqueda/',
                    fecha.fechaComparar
                ).then((response) => {
                    if (response.data) {
                        response.data.forEach((tip) => {
                            for (const key in objetosOrdenados) {
                                if (
                                    objetosOrdenados.hasOwnProperty(key) &&
                                    objetosOrdenados[key].tipo ===
                                    tip.tipo_detalle.tipo
                                ) {
                                    tiposExceptuar = [
                                        ...tiposExceptuar,
                                        tip.tipo_detalle.tipo,
                                    ];
                                }
                            }
                        });
                    }
                    const tiposOrdenados = objetosOrdenados.filter(
                        (obj) => !tiposExceptuar.includes(obj.tipo)
                    );
                    setTipos(tiposOrdenados);
                    setTipo(
                        tiposOrdenados.length > 0
                            ? tiposOrdenados[0]
                            : 'Elegir Tipo'
                    );
                });
            });
        }
    };

    const ordenarEnRango = (t, horaMin, horaMax) => {
        const objetosOrdenados = t
            .filter((tipo) => tipo.estado === true)
            .sort((a, b) => {
                const horaA = moment(a.hora_sorteo, 'hh:mm:ss').format('HH:mm');
                const horaB = moment(b.hora_sorteo, 'hh:mm:ss').format('HH:mm');
                // Verificar si las horas están dentro del rango
                const horaDentroDelRango = (hora) =>
                    hora >= horaMin && hora <= horaMax;

                // Si las dos horas están dentro del rango o fuera del rango, no se realiza ningún cambio
                if (horaDentroDelRango(horaA) === horaDentroDelRango(horaB)) {
                    if (horaA < horaB) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                // Si a está dentro del rango pero b está fuera, se coloca a antes en la ordenación
                if (horaDentroDelRango(horaA)) {
                    return -1;
                }
                // Si b está dentro del rango pero a está fuera, se coloca b antes en la ordenación
                return 1;
            });
        return objetosOrdenados;
    };

    //Define las columnas de la tabla que muestra el extracto
    let columns = [
        {
            title: 'Sorteo N° ' + numeroSorteo,
            children: [
                {
                    title: 'Sorteo realizado el dia ' + fecha.fechaMuestra,
                    children: [
                        {
                            title: 'Sorteo ' + tipo ? tipo.tipo : '-',
                            children: [
                                {
                                    dataIndex: 'numPremio1_10',
                                    key: 'numPremio1_10',
                                },
                                {
                                    dataIndex: 'numero1_10',
                                    key: 'numero1_10',
                                },
                                {
                                    dataIndex: 'numPremio11_20',
                                    key: 'numPremio11_20',
                                },
                                {
                                    dataIndex: 'numero11_20',
                                    key: 'numero11_20',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];

    //Carga los datos de la tabla a guardar en la base de datos, desde el archivo .csv
    const cargarTabla = (numerosExtracto) => {
        setIsLoading(false);
        let datos = [];
        if (numerosExtracto) {
            setNumerosExtracto(numerosExtracto);
            for (let i = 0; i < 10; i++) {
                datos.push({
                    key: i,
                    numPremio1_10: `${i + 1}° Premio`,
                    numero1_10: numerosExtracto[i],
                    numPremio11_20: `${11 + i}° Premio`,
                    numero11_20: numerosExtracto[10 + i],
                });
            }
        } else {
            for (let i = 1; i <= 10; i++) {
                datos.push({
                    key: i,
                    numPremio1_10: `${i}° Premio`,
                    numero1_10: 'XXXX',
                    numPremio11_20: `${10 + i}° Premio`,
                    numero11_20: 'XXXX',
                });
            }
            setNumerosExtracto([]);
            setNumeroSorteo('XXXX');
        }
        setDatosTabla(datos);
    };

    //Maneja la subida del archivo .csv
    const handleFileUploaded = (fileData) => {
        if (fileData.length > 0) {
            SetApiSmartoplay(false);
            setArchivo(fileData);
        } else {
            setArchivo(null);
        }
    };

    //Maneja el borrado del archivo .csv
    const handleRemoveFile = (fileData) => {
        Notificaciones.notificacion('Se elimino el archivo');
        setArchivo(fileData);
    };

    const handleFechaChange = (momentDate) => {
        const fechaMuestra = momentDate.format('DD/MM/YYYY');
        const fechaComparar = momentDate.format('YYYY-MM-DD');
        setFecha({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
    };

    //Maneja el cambio de la variable tipos
    const hadleTipoChange = (tipo) => {
        setTipo(tipos.filter((x) => x.id === tipo)[0]);
    };

    //Carga el extracto seleccionado en la tabla, trae los datos desde el archivo .csv al front
    const cargarArchivo = () => {
        try {
            let sorteosFecha = archivo.filter(
                (sorteo) => sorteo['Fecha Sorteo'] === fecha.fechaComparar
            );
            if (sorteosFecha.length > 0) {
                setMostrarError(false);
                let sorteo = sorteosFecha.find(
                    (sorteo) =>
                        sorteo['Tipo Sorteo'].toUpperCase() ===
                        tipo.tipo.toUpperCase()
                );
                if (sorteo && tipo) {
                    const numerosExtracto = sorteo['Extracto']
                        .split(' - ')
                        .map((item) => {
                            const numero = item.split(':')[1].trim();
                            return numero;
                        });
                    setNumeroSorteo(parseInt(sorteo['N° Sorteo']));
                    cargarTabla(numerosExtracto);
                } else {
                    setMostrarError(true);
                    cargarTabla(null);
                }
            } else {
                cargarTabla(null);
                setMostrarError(true);
            }
        } catch (error) {
            numerosExtracto.splice(0, numerosExtracto.length);
            cargarTabla(null);
        }
    };

    const controlarSubidos = async () => {
        try {
            const sorteosSubidos = await ConsultasAPI.ObtenerObjeto(
                URL_EXTRACTO + 'busquedaFecha/',
                fecha.fechaComparar
            );
            let sub = [];
            if (sorteosSubidos.status === 200) {
                sorteosSubidos.data.forEach((subido) => {
                    sub.push(subido.tipo_detalle.tipo);
                });
                setTiposSubidosEnFecha(sub);
            }
        } catch (error) {
            console.error(error);
        }
    };

    //Guarda el archivo en la base de datos, pasa los datos al back
    const preguntaArchivo = () => {
        Swal.fire({
            title: '¿Estás seguro de subir el archivo?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                if (numerosExtracto.length > 0) {
                    let idExtracto;
                    let extr;
                    if (
                        comentario === '' &&
                        (currentTime > tipo.hora_fin ||
                            fecha.fechaComparar < currentDate)
                    ) {
                        Swal.fire(
                            'Sorteo sin comentario',
                            'No puede subir el sorteo, porque falta justificar el motivo de la demora',
                            'error'
                        );
                        return;
                    }
                    if (tipo) {
                        extr = {
                            sorteo: numeroSorteo,
                            tipo: tipo.id,
                            fecha_sorteo: fecha.fechaComparar,
                            comentario: comentario,
                            api_smartoplay: apiSmartoplay,
                        };
                        if (currentTime > tipo.hora_fin) {
                            extr = {
                                sorteo: numeroSorteo,
                                tipo: tipo.id,
                                fecha_sorteo: fecha.fechaComparar,
                                comentario: comentario,
                                api_smartoplay: apiSmartoplay,
                            };
                        }
                        if (tiposSubidosEnFecha.includes(tipo.tipo)) {
                            Swal.fire(
                                'Sorteo Repetido',
                                'No puede subir el sorteo, porque ya hay un sorteo del mismo tipo para la fecha elegida',
                                'error'
                            );
                            return;
                        }
                    } else {
                        extr = {
                            sorteo: numeroSorteo,
                            tipo: null,
                            fecha_sorteo: fecha.fechaComparar,
                            api_smartoplay: apiSmartoplay,
                        };
                    }
                    if (
                        fechaPendiente &&
                        fecha.fechaMuestra === moment().format('DD/MM/YYYY')
                    ) {
                        Swal.fire({
                            title: 'Sorteo Faltante',
                            text: 'No puede subir los sorteos del día de hoy, faltan subir sorteos del día anterior',
                            footer: `Sorteos que se subieron el dia anterior: ${tiposSubidos.map((tipo) => tipo).join(', ')}`,
                            icon: 'error',
                        });
                    } else {
                        ConsultasAPI.CrearObjeto(URL_EXTRACTO, extr)
                            .then((response) => {
                                if (response.status === 201) {
                                    idExtracto = response.data.id;
                                    numerosExtracto.forEach((valor, indice) => {
                                        let numero = {
                                            extracto: idExtracto,
                                            posicion: indice,
                                            numero: parseInt(valor),
                                        };
                                        ConsultasAPI.CrearObjeto(
                                            URL_EXTRACTOREGISTRO,
                                            numero
                                        )
                                            .then((response) => { })
                                            .catch((error) => { });
                                    });
                                    Swal.fire(
                                        'Subida exitosa',
                                        'El archivo se subio con exito',
                                        'success'
                                    ).then(() => {
                                        setComentario('');
                                        if (rolUser === 'OPERADOR-EXTRACTO') {
                                            navigate(
                                                '/extractos/subir-extracto',
                                                { replace: true }
                                            );
                                        } else {
                                            navigate('/extractos');
                                        }
                                    });
                                    sorteoPendiente();
                                } else {
                                    Swal.fire(response.data.error);
                                }
                            })
                            .catch((error) => {
                                Swal.fire(
                                    'Sorteo Repetido',
                                    'No puede subir dos sorteos con la misma fecha y tipo',
                                    'error'
                                );
                            });
                    }
                } else {
                    Swal.fire('Debe haber un sorteo cargado');
                }
            }
        });
    };

    //Trae los datos desde el back para mostrar en la tabla del front
    const sorteoPendiente = async () => {
        try {
            let xtr = [];
            let response;
            if (moment(currentDate).format('dddd') === 'lunes') {
                response = await ConsultasAPI.ObtenerObjeto(
                    URL_EXTRACTO + 'busquedaFecha/',
                    moment(currentDate).add(-2, 'day').format('YYYY-MM-DD')
                );
                xtr = response.data;
            } else {
                response = await ConsultasAPI.ObtenerObjeto(
                    URL_EXTRACTO + 'busquedaFecha/',
                    moment(currentDate).add(-1, 'day').format('YYYY-MM-DD')
                );
                xtr = response.data;
            }
            let datos = 0; //cantidad de extractos subidos el dia anterior
            let exceptuado = 0; //cantidad de sorteos exceptuados del dia anterior
            let tipitos = []; //tipos de extractos subidos del dia anterior
            for (const Extracto of xtr) {
                let fecha = moment(Extracto.fecha_sorteo, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD'
                );
                const exc = await ConsultasAPI.ObtenerObjeto(
                    URL_SORTEOEXCEPTUADO + 'busqueda/',
                    fecha
                );
                exceptuado = exc.data.length;
                const busqExc = await ConsultasAPI.ObtenerObjeto(
                    URL_SORTEOEXCEPTUADO + 'busquedaExc/',
                    Extracto.tipo + '/' + fecha
                );
                if (busqExc.status !== 204) {
                    Swal.fire({
                        title: 'Sorteo Subido',
                        text: 'Se subio un Sorteo el dia anterior que no se debia subir',
                        footer: `El mismo es: ${Extracto.tipo_detalle.tipo}`,
                        icon: 'error',
                    });
                }
                if (moment(currentDate).format('dddd') === 'lunes') {
                    if (
                        Extracto.fecha_sorteo ===
                        moment(currentDate).add(-2, 'day').format('DD/MM/YYYY')
                    ) {
                        datos++;
                        tipitos.push(Extracto.tipo_detalle.tipo);
                    }
                } else {
                    if (
                        Extracto.fecha_sorteo ===
                        moment(currentDate).add(-1, 'day').format('DD/MM/YYYY')
                    ) {
                        datos++;
                        tipitos.push(Extracto.tipo_detalle.tipo);
                    }
                }
                setTiposSubidos(tipitos);
            }
            if (response.status === 204) {
                let fecha = moment(currentDate)
                    .add(-1, 'day')
                    .format('YYYY-MM-DD');
                const exc = await ConsultasAPI.ObtenerObjeto(
                    URL_SORTEOEXCEPTUADO + 'busqueda/',
                    fecha
                );
                exceptuado = exc.data.length;
            }

            const tiposSorteos =
                await ConsultasAPI.ListarObjetos(URL_TIPOSORTEO);
            if (datos === tiposSorteos.data.count - exceptuado) {
                //Realiza el control de si se subio extracto el dia de ayer
                setFechaPendiente(false);
            } else {
                setFechaPendiente(true);
            }
        } catch (error) {
            Notificaciones.notificacion('Falta definir rango horario');
        }
    };

    // Controla las fechas validas para seleccionar

    var valid = function (current) {
        const today = moment();
        const isSunday = current.day() === 0;
        return current.isBefore(today) && !isSunday;
    };

    //Funcion para obtener los datos del servidor de Smartoplay
    const obtenerDatosSmartoplay = async () => {
        try {
            setIsLoading(true);
            setShowXLSX(false);
            const objeto =
                fecha.fechaComparar + '*' + tipo.tipo.replace(/\s/g, '');
            const conect = await ConsultasAPI.ObtenerObjeto(
                URL_EXTRACTO + 'conect/',
                objeto
            );
            setNumeroSorteo(conect.data[0]['N° Sorteo']);
            const extractoStr = conect.data[0].Extracto.split(' - ');
            const extractoJson = {};
            const extractoVec = [];
            SetApiSmartoplay(true);
            extractoStr.forEach((pair) => {
                const [key, value] = pair.split(': ');
                extractoJson[key] = parseInt(value);
                extractoVec.push(value);
            });
            if (extractoVec[0]) {
                cargarTabla(extractoVec);
            } else {
                setIsLoading(false);
                setShowXLSX(true);
                SetApiSmartoplay(false);
                Swal.fire({
                    title: 'No se realizo el Sorteo',
                    text: 'Todavia no se realizo el sorteo seleccionado',
                    icon: 'error',
                });
            }
        } catch (error) {
            setIsLoading(false);
            setShowXLSX(true);
            SetApiSmartoplay(false);
            Swal.fire({
                title: 'Error de Conexion',
                text: 'No se pudo conectar al servidor',
                footer: `Asegurese de tener la VPN encendida`,
                icon: 'error',
            });
        }
    };

    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Nuevo Extracto</h1>
                </Col>
                <Col className='mt-3'>
                    {rolUser === "ADMINISTRADOR" || rolUser === "OPERADOR-EXTRACTO" ?
                        <Link
                            className="btn botonCPA m-3"
                            to="/extractos/resultados"
                            variant="contained"
                            style={{ float: 'right' }}
                        >
                            <FaPlus className="iconAntD" /> Cargar Resultado
                        </Link>
                        :
                        null
                    }
                </Col>
            </Row>
            {fechaPendiente ? (
                <>
                    <Col className="text-center">
                        <h4
                            className="py-1 fw-bold"
                            style={{ color: '#EC1B23' }}
                        >
                            No se subio extracto del dia Anterior
                        </h4>
                    </Col>
                </>
            ) : null}
            <Card>
                <Form>
                    <Col>
                        <Row>
                            <div className="d-flex justify-content-center m-3">
                                <h4 className="me-2">Fecha:</h4>
                                <FormItem
                                    name="Fecha"
                                    style={{ width: '23%', height: '32px' }}
                                >
                                    {AuthenticationHelper.getRol() &&
                                        (AuthenticationHelper.getRol() ===
                                            'ADMINISTRADOR' ||
                                            AuthenticationHelper.getRol() ===
                                            'GERENCIA-QUINIELA' ||
                                            AuthenticationHelper.getRol() ===
                                            'OPERADOR-EXTRACTO') ? (
                                        <Datetime
                                            timeFormat={false}
                                            style={{
                                                width: '100%',
                                                height: '32px',
                                            }}
                                            dateFormat="DD/MM/YYYY"
                                            inputProps={{
                                                readOnly: true,
                                                placeholder: fecha.fechaMuestra,
                                            }}
                                            value={fecha.fechaMuestra}
                                            onChange={handleFechaChange}
                                            isValidDate={valid}
                                        />
                                    ) : (
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/YYYY"
                                            renderInput={(
                                                props,
                                                openCalendar,
                                                closeCalendar
                                            ) => (
                                                <input
                                                    {...props}
                                                    readOnly
                                                    placeholder={
                                                        fecha.fechaMuestra
                                                    }
                                                    onFocus={openCalendar}
                                                    onBlur={closeCalendar}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            )}
                                            open={false}
                                            value={fecha.fechaMuestra}
                                            onChange={handleFechaChange}
                                        />
                                    )}
                                </FormItem>
                                <h4 className="me-2 ms-5">Tipo:</h4>
                                <FormItem
                                    name="Tipo"
                                    style={{ width: '23%', height: '37px' }}
                                >
                                    <Select
                                        style={{
                                            width: '100%',
                                            height: '37px',
                                        }}
                                        placeholder={
                                            'Sorteo ' +
                                            (tipos.length > 0
                                                ? tipos[0].tipo
                                                : '-')
                                        }
                                        value={tipo}
                                        onSelect={hadleTipoChange}
                                    >
                                        {tipos.map((tipo) => (
                                            <Select.Option
                                                key={tipo.id}
                                                value={tipo.id}
                                            >
                                                Sorteo {tipo.tipo}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </FormItem>
                            </div>
                            <div className="d-flex justify-content-end m-1">
                                {showXLSX ? (
                                    <Link
                                        className="btn botonCPA me-3"
                                        onClick={obtenerDatosSmartoplay}
                                        style={{
                                            alignContent: 'center',
                                            margin: 15,
                                            float: 'right',
                                            backgroundColor: '#EC1B23',
                                            borderColor: '#EC1B23',
                                            width: 'max-content',
                                        }}
                                    >
                                        <AiOutlineCluster className="iconAntD" />{' '}
                                        Reintentar conectarse al servidor
                                    </Link>
                                ) : (
                                    <Link
                                        className="btn botonCPA me-3"
                                        onClick={obtenerDatosSmartoplay}
                                        style={{
                                            alignContent: 'center',
                                            margin: 15,
                                        }}
                                    >
                                        <AiOutlineCluster className="iconAntD" />{' '}
                                        Conectarse al Servidor
                                    </Link>
                                )}
                            </div>
                        </Row>
                        {showXLSX ? (
                            <>
                                <Row>
                                    <div className="d-flex justify-content-center mt-3">
                                        <h4 className="me-2">Subir Archivo:</h4>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="d-flex justify-content-center mt-3">
                                        <FormItem
                                            name="Archivo"
                                            style={{
                                                width: '63%',
                                                height: '80%',
                                            }}
                                        >
                                            <ExcelReader
                                                onFileUploaded={
                                                    handleFileUploaded
                                                }
                                                onRemoveFile={handleRemoveFile}
                                            />
                                        </FormItem>
                                    </div>
                                </Row>
                            </>
                        ) : null}
                    </Col>
                </Form>
            </Card>
            {currentTime > tipo.hora_fin ||
                fecha.fechaComparar < currentDate ? (
                <F.Group as={Col} md="12">
                    <F.Label
                        style={{
                            fontFamily: 'bold',
                            fontSize: '25px',
                            fontWeight: 'bold',
                        }}
                    >
                        {' '}
                        Justifique el motivo de demora
                    </F.Label>
                    <F.Control
                        type="text"
                        placeholder="Ingrese el Motivo"
                        required
                        value={comentario}
                        readOnly={false}
                        onChange={(event) => setComentario(event.target.value)}
                        style={{ height: '50px' }}
                    />
                </F.Group>
            ) : null}
            <Row className="mt-4">
                {numerosExtracto.length > 0 ? (
                    <>
                        <Table
                            style={{
                                width: '50%',
                                margin: 'auto',
                                fontSize: '18px',
                            }}
                            size="small"
                            dataSource={datosTabla}
                            columns={columns}
                            pagination={false}
                            bordered
                        />
                        <Row>
                            <Col className="d-flex justify-content-center mt-3">
                                <button
                                    className="btn botonCPA ms-4"
                                    variant="contained"
                                    onClick={preguntaArchivo}
                                >
                                    Subir extracto
                                </button>
                            </Col>
                        </Row>
                    </>
                ) : null}
                {mostrarError === true ? (
                    <>
                        <div className="d-flex justify-content-center mt-3">
                            <h4 className="me-2">
                                No hay sorteos con la fecha seleccionada
                            </h4>
                        </div>
                    </>
                ) : null}
                {rolUser === 'ADMINISTRADOR' ||
                    rolUser === 'GERENCIA-QUINIELA' ? (
                    <>
                        <section className="d-flex justify-content-end my-3">
                            <BtnVolver  />
                        </section>
                    </>
                ) : null}
                {rolUser === 'OPERADOR-EXTRACTO' ? (
                    <>
                        <section className="d-flex justify-content-end my-3">
                            <BtnVolver  route='/'/>
                        </section>
                    </>
                ) : null}
            </Row>
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Conectando al Servidor...</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};
export default SubirExtracto;
