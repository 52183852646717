import { Button } from "react-bootstrap";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../../../../img/logo.png";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import AuthenticationHelper from "../../../../../shared/helpers/authenticationHelper.js";
import dayjs from "dayjs";
import Parser from "../../../../common/Parser";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    padding: 20,
    maxHeight:"100vh",
    size: "FOLIO",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  logo: {
    width: 80,
    height: 47,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: "bold",
    // marginLeft: "auto",
  },
  subHeaderTitle: {
    fontSize: 10,
    // marginLeft: "auto",
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  titleMax: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 8,
    marginTop: 25,
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "auto",
    // height: "100%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    // height: 8,
  },
  tableColHead: {
    flex: 1,
    width: "50px",
    borderStyle: "solid",
    backgroundColor: "#bfbfbf",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    // padding: 3,
    fontWeight: "bold",
    fontSize: 5,
    textAlign: "center",
  },
  tableColHeader: {
    flex: 1,
    // width: "50%",
    borderStyle: "solid",
    backgroundColor: "#bfbfbf",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    // padding: 3,
    fontWeight: "bold",
    fontSize: 5,
    textAlign: "center",
  },
  tableCol: {
    flex: 1,
    // width: "50%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    padding: 0.5,
    fontSize: 5,
    borderRightWidth: 1, // borde derecho de las columnas
    textAlign: "center",
  },
  lastRow: {
    fontSize: 6,
    fontStyle: "bold",
    backgroundColor: "gray",
  },
  sectionSeparator: {
    height: 20, // Altura de la separación entre secciones
  },
  sectionSeparator2: {
    height: 38, // Altura de la separación entre secciones
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    marginTop: 5,
    marginBottom: 20,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const MovimientosDeOperaciones = (props) => {
  // const rolUser = props.rolUsuario;

  function splitText(text, maxWidth) {
    const lines = [];
    let currentLine = "";
    for (let i = 0; i < text.length; i++) {
      currentLine += text[i];

      // Check if the current line exceeds the maxWidth or if we have reached the end of the text
      if (currentLine.length >= maxWidth || i === text.length - 1) {
        lines.push(currentLine);
        currentLine = "";
      }
    }

    return lines;
  }

  const components = props.datos;
  var total = {};
  const attributesList = components
    ? components.map((component) => Object.keys(component))
    : [];
  // Generar una tabla por cada conjunto de atributos iguales
  const uniqueAttributesList = [
    ...new Set(attributesList.map((attributes) => attributes.join())),
  ];
  uniqueAttributesList.map((uniqueAttributes) => {
    const filteredComponents = components.filter((component) => {
      const componentAttributes = Object.keys(component);
      return componentAttributes.join() === uniqueAttributes;
    });

    const tableHeaders = uniqueAttributes.split(",");
    total = {};
    total["Agencia"] = "Total";
    filteredComponents.forEach((object, index) => {
      tableHeaders.forEach((column, index) => {
        if (column !== "Agencia")
          total[column] = (total[column] ? total[column] : 0) + object[column];
      });
    });
  });

  if (!components.find((item) => item.Agencia === "Total")) {
    components.push(total);
  }

  const tables2 = [];
  // const dividirTabla = (components) => {
  //   const result = []
  //   for (let i = 0; i < components.length; i += 21) {
  //     result.push(components.slice(i, i + 21));
  //   };
  //   return result;
  // };
  const dividirTabla = (components) => {
    const result = [];
    result.push(components);
    // for (let i = 0; i < components.length; i += 21) {
    // };
    return result;
  };
  const tablas = dividirTabla(components);
  for (let index = 0; index < tablas.length; index++) {
    const components = tablas[index];
    const attributesList = components
      ? components.map((component) => Object.keys(component))
      : [];
    // Generar una tabla por cada conjunto de atributos iguales
    const uniqueAttributesList = [
      ...new Set(attributesList.map((attributes) => attributes.join())),
    ];
    tables2.push(
      uniqueAttributesList.map((uniqueAttributes, tableIndex) => {
        const filteredComponents = components.filter((component) => {
          const componentAttributes = Object.keys(component);
          return componentAttributes.join() === uniqueAttributes;
        });

        if (filteredComponents.length === 0) {
          return null;
        }
        const tableHeaders = uniqueAttributes.split(",");
        // var total = {};
        // total["Agencia"] = "Total"
        // filteredComponents.forEach((object, index) => {
        //   tableHeaders.forEach((column, index) => {
        //     if (column !== "Agencia")
        //       total[column] = (total[column] ? total[column] : 0) + object[column];
        //   });
        // });

        return (
          <div key={"operaciones"}>
            {/* <View style={styles.sectionSeparator} /> */}
            {/* <Text style={styles.subtitle}>{tableName}</Text> */}
            <View style={styles.table}>
              {/* Encabezado de tabla */}
              <View style={styles.tableRow}>
                {tableHeaders.map((header, index) => (
                  <View style={styles.tableColHeader} key={index.toString()}>
                    <Text>
                      {
                        header.charAt(0).toUpperCase() +
                          header.slice(1).replace("_", " ")
                      }
                    </Text>
                  </View>
                ))}
              </View>

              {/* Filas de tabla */}
              {filteredComponents.map((object, index) => (
                <View key={index.toString()} style={styles.tableRow}>
                  {tableHeaders.map((column, index) => (
                    <View style={styles.tableCol} key={index.toString()}>
                      {object["Agencia"] !== "Total" ? (
                        <Text>
                          {column !== "Agencia"
                            ? splitText(Parser.currency(object[column]), 7)
                            : object[column]}
                        </Text>
                      ) : (
                        <Text style={styles.lastRow}>
                          {column !== "Agencia"
                            ? splitText(Parser.currency(object[column]), 7)
                            : object[column]}
                        </Text>
                      )}
                    </View>
                  ))}
                </View>
              ))}
              {/* <View style={styles.tableRow}>
                {tableHeaders.map((column, index) => (
                  <View style={styles.tableCol} key={index.toString()}>
                  <Text>
                  {column !== "Agencia" && index === tablas.length
                  ? splitText(Parser.currency(total[column]), 7)
                  : total[column]}
                  </Text>
                  </View>
                  ))}
                </View> */}
              {/* <View style={styles.sectionSeparator2} /> */}
            </View>
          </div>
        );
      })
    );
  }
  // Generar una lista de todos los atributos de todos los componentes

  // Generacion de las tablas de las Rendiciones Vencidas

  let tablasPorFecha = props.datosVen
    ? props.datosVen.reduce((result, fila) => {
        const fechaInicio = fila.fecha_inicio;
        if (!result[fechaInicio]) {
          result[fechaInicio] = [];
        }
        result[fechaInicio].push(fila);
        return result;
      }, {})
    : [];

  const tablesVen = [];
  const claves = Object.keys(tablasPorFecha);

  for (let i = 0; i < claves.length; i++) {
    const components = tablasPorFecha[claves[i]].map(
      ({ fecha_inicio, ...resto }) => resto
    );
    // Generar una lista de todos los atributos de todos los componentes
    const attributesListVen = components
      ? components.map((component) => Object.keys(component))
      : [];
    //   const uniqueAttributesList = components ? Object.keys(components[0]) : [];
    // Generar una tabla por cada conjunto de atributos iguales
    const uniqueAttributesListVen = [
      ...new Set(attributesListVen.map((attributes) => attributes.join())),
    ];
    tablesVen.push(
      uniqueAttributesListVen.map((uniqueAttributes) => {
        const filteredComponentsVen = components.filter((component) => {
          const componentAttributes = Object.keys(component);
          return componentAttributes.join() === uniqueAttributes;
        });

        if (filteredComponentsVen.length === 0) {
          return null;
        }
        const tableHeadersVen = uniqueAttributes.split(",");
        var total = {};
        total["Agencia"] = "Total";
        filteredComponentsVen.forEach((object, index) => {
          tableHeadersVen.forEach((column, index) => {
            if (column !== "Agencia")
              total[column] =
                (total[column] ? total[column] : 0) + object[column];
          });
        });

        return (
          <div key={"operaciones"}>
            <View style={styles.sectionSeparator} />
            {/* <Text style={styles.subtitle}>{tableName}</Text> */}
            <Text style={styles.subtitle}>
              Movimiento de Operaciones {claves[i]}
            </Text>
            <Text style={styles.subtitle}>
              Rendicion Sorteos Fecha{" "}
              {moment(claves[i], "DD/MM/YYYY")
                .add(-2, "days")
                .format("DD/MM/YYYY")}
            </Text>
            <View style={styles.table}>
              {/* Encabezado de tabla */}
              <View style={styles.tableRow}>
                {tableHeadersVen.map((header, index) => (
                  <View style={styles.tableColHeader} key={index.toString()}>
                    <Text>
                      {
                        // header === "Agencia" ? "Ag" :
                        header.charAt(0).toUpperCase() +
                          header.slice(1).replace("_", " ")
                      }
                    </Text>
                  </View>
                ))}
              </View>

              {/* Filas de tabla */}
              {filteredComponentsVen.map((object, index) => (
                <View key={index.toString()} style={styles.tableRow}>
                  {tableHeadersVen.map((column, index) => (
                    <View style={styles.tableCol} key={index.toString()}>
                      <Text>
                        {column !== "Agencia"
                          ? splitText(Parser.currency(object[column]), 7)
                          : object[column]}
                      </Text>
                    </View>
                  ))}
                </View>
              ))}
              <View style={styles.tableRow}>
                {tableHeadersVen.map((column, index) => (
                  <View style={styles.tableCol} key={index.toString()}>
                    <Text>
                      {column !== "Agencia"
                        ? splitText(Parser.currency(total[column]), 7)
                        : total[column]}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </div>
        );
      })
    );
  }

  return (
    <PDFDownloadLink
      className="PDF-Download"
      document={
        <Document>
          <Page
            wrap
            style={{ ...styles.page, height: "100vh" }}
            orientation="landscape"
            size="FOLIO"
          >
            {/* Encabezado */}
            <View fixed>
              <View fixed style={styles.header}>
                {/* <Image src={logo} style={styles.logo} /> */}
                  <Text style={styles.title}>
                    Movimiento de Operaciones para Contaduría del día: {props.fechaR}
                  </Text>
                 <Text style={styles.title}>
                    Fecha de Sorteo: {props.fechaS}
                  </Text> 
                {/* <Text style={styles.subHeaderTitle}>
                  Fecha:{dayjs().format("DD/MM/YYYY")}
                  {"\n"}
                  Hora:{dayjs().format("HH:mm")}
                  {"\n"}
                  Usuario: {AuthenticationHelper.getUser()}
                  {"\n"}
                  Sucursal Rendición: {props.sucursal ? props.sucursal : "TODOS"}
                  {"\n"}
                </Text> */}
              </View>
              {/* <View style={styles.separator} /> */}
            </View>
            {/* Cuerpo */}
            <View style={{'marginLeft':'-10px','marginRight':'-10px'}}>{tables2}</View>
            <View break>
              {props.datosVen ? (
                <>
                  <Text style={styles.titleMax}>Vencidas</Text>
                  {/* <View style={styles.sectionSeparator} /> */}
                  {tablesVen.length > 0 ? (
                    tablesVen.map((tabla, index) => (
                      <div key={index.toString()}>{tabla}</div>
                    ))
                  ) : (
                    <Text style={styles.title}>
                      No hay tablas vencidas para mostrar.
                    </Text>
                  )}
                </>
              ) : null}
            </View>
            {/* <View style={styles.sectionSeparator} /> */}
            {/* Pie de página */}
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </Page>
        </Document>
      }
      fileName={
        "Movimiento de Operaciones " + dayjs().format("DD/MM/YYYY") + ".pdf"
      }
    >
      {({ blob, url, loading, error }) => (
        <Button
          style={props.style ? props.style : {}}
          disabled={props.datos.length === 0}
          className="btn botonCPA my-4"
        >
          <BsFillFileEarmarkPdfFill className="mb-1" />
          <strong>{loading ? "Generando PDF..." : "Exportar a PDF"}</strong>
        </Button>
      )}
    </PDFDownloadLink>
  );
};

export default MovimientosDeOperaciones;
