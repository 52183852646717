import React from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { read, utils } from 'xlsx';

const { Dragger } = Upload;

class ExcelReader extends React.Component {
    state = {
        uploadedFile: null, // Estado para almacenar el archivo cargado
    };

    handleFileUpload = async (file) => {
        if (file) {
            if (
                file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                const excelData = await this.readExcelFile(file);
                const parsedData = this.parseExcelData(excelData);
                this.props.onFileUploaded(parsedData);
                this.setState({ uploadedFile: file }); // Actualizar el archivo cargado
            } else {
                Swal.fire(
                    'Ocurrió un error',
                    'El archivo debe tener el formato Excel (XLSX).',
                    'error'
                );
            }
        } else {
            this.setState({ uploadedFile: null });
        }
    };

    readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const data = reader.result;
                resolve(new Uint8Array(data));
            };

            reader.onerror = () => {
                reject(reader.error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    parseExcelData = (excelData) => {
        const workbook = read(excelData, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const parsedData = utils.sheet_to_json(worksheet, { header: 1 });
    
        // Omitir las primeras 6 filas del archivo Excel
        const skippedRows = 6;
        const filteredData = parsedData.slice(skippedRows);
          
        const headers = filteredData[0];
        const formattedData = filteredData.slice(1).map((row) => {
            const rowData = {};
            headers.forEach((header, index) => {
                rowData[header] = row[index];
            });
            return rowData;
        });
    
        return formattedData;
    };
    

    handleRemoveFile = (file) => {
        this.props.onRemoveFile(file);
        this.setState({ uploadedFile: null }); // Eliminar el archivo cargado
    };

    render() {
        const { uploadedFile } = this.state;

        return (
            <Upload.Dragger
                accept=".xlsx"
                customRequest={({ file }) => {
                    this.handleFileUpload(file);
                }}
                onRemove={this.handleRemoveFile} // Agregar el controlador de eliminación
                style={{ width: '100%', height: '60%' }}
                fileList={uploadedFile ? [uploadedFile] : []} // Mostrar el archivo cargado actualmente
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Haz clic o arrastra un archivo aquí para cargarlo</p>
                <p className="ant-upload-hint">          {this.props.showMessage ? this.props.showMessage :"Solo se permiten archivos en formato Excel (XLSX)"}
</p>
            </Upload.Dragger>
        );
    }
}

export default ExcelReader;
