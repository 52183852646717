import React, { useMemo, useState, useEffect, useRef } from 'react';
import Datetime from 'react-datetime';
import {
    Modal,
    Form,
    Container,
    Col,
    Row,
    Card,
    Spinner,
    Button,
    Dropdown,
} from 'react-bootstrap';
import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import MaterialReactTable from 'material-react-table';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { darken } from '@mui/material';
import { IconButton } from '@mui/material';
import { Preview } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import Swal from 'sweetalert2';
import Retenciones from './Retenciones';
import { FaEraser } from 'react-icons/fa';
import BtnVolver from '../../common/BtnVolver';
import Parser from '../../common/Parser';
import { AiOutlineDownload } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import AuthenticationHelper from '../../../shared/helpers/authenticationHelper';
import Notificaciones from '../../../shared/helpers/notificacionesToast';
import GenerarCertificados from '../subirDocumentacion/GenerarCertificados';
import ModalRetencion from './ModalRetencion';
import Notifiaciones from '../../../shared/helpers/notificacionesToast';
import { ModalRazonCambioEstadoComision } from './ModalRazonCambioEstadoComision';
import RestoreIcon from '@mui/icons-material/Restore';
import { generarPDFsLineaCaja } from './GenerarOP';

/* eslint-disable */
const OrdenesDePago = (props) => {
    const rolUser = props.rolUsuario;
    const [periodoComision, setPeriodoComision] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });
    const dateTimeRefPeriodo = useRef(null);

    const [fechaSubida, setFechaSubida] = useState({
        fechaMuestra: moment().format('MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const fechaSubidaRef = useRef(null);

    const [estado, setEstado] = useState('');
    //const [tem, setTem] = useState('');
    const [loadingExcel, setLoadingExcel] = useState('');

    const tablaEstado = [
        { id: 0, detalle: 'Completo' },
        { id: 1, detalle: 'Incompleto' },
    ];
    // const [tablaEstado, setTablaEstado] = useState([
    //     { id: 0, detalle: 'Completo' },
    //     { id: 1, detalle: 'Incompleto' },
    // ]);
    const tablaDocumentacion = [
        { id: 0, detalle: 'Aprobado' },
        { id: 1, detalle: 'Desaprobado' },
    ];
    // const [tablaDocumentacion, setTablaDocumentacion] = useState([
    //     { id: 0, detalle: 'Aprobado' },
    //     { id: 1, detalle: 'Desaprobado' },
    // ]);
    const tablaEstadoComision = [
        { id: 0, detalle: 'Pendiente' },
        { id: 1, detalle: 'Generada' },
        { id: 2, detalle: 'Pagada' },
    ];
    // const [tablaEstadoComision, setTablaEstadoComision] = useState([
    //     { id: 0, detalle: 'Pendiente' },
    //     { id: 1, detalle: 'Generada' },
    //     { id: 2, detalle: 'Pagada' },
    // ]);
    const [estadoComisionF, setEstadoComisionF] = useState('');
    const [estadoDocumentacionF, setEstadoDocumentacionF] = useState('');
    const handleTablaEstadoChange = (estado) => {
        setEstado(
            tablaEstado.filter(
                (elemento) => elemento.id === parseInt(estado)
            )[0]
        );
    };
    const handleTablaSucursalChange = (estado) => {
        setSucursalRendicion(
            tablaTipoSucursal.filter(
                (elemento) => elemento.id === parseInt(estado)
            )[0]
        );
    };
    const handleTablaEstadoComisionChange = (estado) => {
        setEstadoComisionF(estado);
    };
    const handleTablaEstadoDocumentacionChange = (estado) => {
        setEstadoDocumentacionF(estado);
    };
    // var suma = 0;
    const [data, setData] = useState([]);
    const [dataDed, setDataDed] = useState([]);
    const [dataRed, setDataRed] = useState([]);
    const [dataDedImp, setDataDedImp] = useState([]);
    const [importeImp, setImporteImp] = useState(0);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFilters, setColumnFilters] = useState([]);

    const URL_ORDENPAGO = window.API_ROUTES.ORDENPAGO;
    const URL_CONDICIONFISCALTITULAR = window.API_ROUTES.CONDICIONFISCALTITULAR;
    const URL_AGENCIA = window.API_ROUTES.AGENCIA;
    const URL_DEDUCCION = window.API_ROUTES.DEDUCCION;
    const URL_CONDICIONFISCAL = window.API_ROUTES.CONDICIONFISCAL;
    const URL_RETENCION = window.API_ROUTES.RETENCION;
    const URL_TIPOSUCURSAL = window.API_ROUTES.TIPOSUCURSAL;
    const URL_REGISTROMAIL = window.API_ROUTES.REGISTROMAIL;
    const [showModalDetalle, setShowModalDetalle] = useState(false);
    const [tituloModal, setTituloModal] = useState('');
    const [agencia, setAgencia] = useState('');
    const [subagencia, setSubagencia] = useState('');
    const [titular, setTitular] = useState('');
    const [dni, setDni] = useState('');
    const [cuit, setCuit] = useState('');
    const [periodoComisionV, setPeriodoComisionV] = useState('');
    const [legajo, setLegajo] = useState('');
    const [estadoDocumentacion, setEstadoDocumentacion] = useState('');
    const [jurisdiccion, setJurisdiccion] = useState('');
    const [zonaExplotacion, setZonaExplotacion] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [condicionFiscal, setCondicionFiscal] = useState({
        id: '',
        condicion: '',
    });
    const fechaDesde = {
        fechaMuestra: null,
        fechaComparar: null,
    };
    // const [fechaDesde, setFechaDesde] = useState({
    //     fechaMuestra: null,
    //     fechaComparar: null,
    // });
    const [tablaCondiciones, setTablaCondiciones] = useState([]);
    const [condicionAnterior, setCondicionAnterior] = useState([]);
    const [idTitular, setIdTitular] = useState('');
    const [idCondicionTitular, setIdCondicionTitular] = useState('');
    const [isLoadingView, setIsLoadingView] = useState(false);
    const [dataOrden, setDataOrden] = useState([]);
    //const [ultimaOrden, setUltimaOrden] = useState(0);
    const [totalRetenciones, setTotalRetenciones] = useState(0);
    //const [totalDeducciones, setTotalDeducciones] = useState(0);
    const [rowSelection, setRowSelection] = useState({});
    const [tablaTipoSucursal, setTablaTipoSucursal] = useState([]);
    const [tipoSucursal, setTipoSucursal] = useState('CASA CENTRAL');
    const fechaDepositoRef = useRef(null);
    const [fechaDeposito, setFechaDeposito] = useState(moment());
    const [SucursalRendicion, setSucursalRendicion] = useState({ id: '' });
    const [ShowModalTxt, setShowModalTxt] = useState(false);
    const [showModalRetencion, setShowModalRetencion] = useState(false);

    const fechaRentasRef = useRef(null);
    const [fechaRentas, setFechaRentas] = useState({
        fechaMuestra: moment().format('MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const [ShowModalTxtQuinielaTuqui, setShowModalTxtQuinielaTuqui] =
        useState(false);
    const [sistemaQuinielaTuqui, setSistemaQuinielaTuqui] = useState();

    const [showModalRazonCambioEstadoComision, setShowModalRazonCambioEstadoComision] =
        useState(false);
    const [cambioEstadoComision, setCambioEstadoComision] =
        useState('');

    const handleCloseModalRazonCambioEstadoComision = () => {
        setShowModalRazonCambioEstadoComision(false);
        cargarOrdenesPago();
    };
    const [pdfOP, setPdfOP] = useState();
    const dataPush = [];
    const columns = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            size: 50,
        },
        {
            header: 'Agencia',
            accessorKey: 'agencia',
            size: 50,
        },
        {
            header: 'Subagencia',
            accessorKey: 'subagencia',
            size: 50,
        },
        {
            header: 'Titular',
            accessorKey: 'titular',
            size: 50,
        },
        {
            header: 'CUIT',
            accessorKey: 'cuit',
            size: 50,
        },
        {
            header: 'Periodo de Comision',
            accessorKey: 'periodoComision',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Legajo',
            accessorKey: 'legajo',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Estado Documentacion',
            accessorKey: 'estadoDocumentacion',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Documentacion Rechazada',
            accessorKey: 'DocumentacionRechazada',
            size: 300,
            enableColumnFilter: false,
        },
        {
            header: 'Estado Comision',
            accessorKey: 'estadoComision',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Documentacion Revisada Por:',
            accessorKey: 'revisada_por',
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    const columns2 = useMemo(() => [
        // {
        //     header: "id",
        //     accessorKey: "id",
        //     size: 50
        // },
        {
            header: 'Concepto',
            accessorKey: 'concepto',
            size: 50,
        },
        {
            header: 'Importe',
            accessorKey: 'importe',
            size: 50,
        },
    ]);
    const columns1 = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            size: 50,
        },
        {
            header: 'Concepto',
            accessorKey: 'concepto',
            size: 50,
        },
        {
            header: 'Importe',
            accessorKey: 'importe',
            size: 50,
        },
        {
            header: 'Nro Cuota Plan de Pago',
            accessorKey: 'nroCuotaPlan',
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    useEffect(() => {
        cargarOrdenesPago();
    }, [
        pagination,
        columnFilters,
        estado,
        periodoComision,
        estadoComisionF,
        estadoDocumentacionF,
        fechaSubida,
        SucursalRendicion,
    ]);

    useEffect(() => {
        cargarTablaCondicionFiscal();
        cargarTablaTipoSucursal();
        //obtenerUltima();
    }, []);

    const [rowSelected, setRowSelected] = useState([]);

    useEffect(() => {
        var dat = [];
        for (const key in rowSelection) {
            dat.push(data[key]);
        }
        setRowSelected(dat);
    }, [rowSelection]);

    const cargarTablaTipoSucursal = async () => {
        const data = await ConsultasAPI.ListarObjetos(URL_TIPOSUCURSAL);
        if (data.status === 200) {
            setTablaTipoSucursal(data.data.results);
        } else {
            setTablaTipoSucursal([]);
        }
    };

    // const obtenerUltima = async () => {
    //     const ultimaOrden = await ConsultasAPI.ListarTodos(
    //         URL_ORDENPAGO + 'obtenerUltima/'
    //     );
    //     if (ultimaOrden.status === 200) {
    //         setUltimaOrden(parseInt(ultimaOrden.data));
    //     } else {
    //         setUltimaOrden(0);
    //     }
    // };

    const cargarOrdenesPago = async () => {
        let estadoDetalle = estado.detalle ? estado.detalle : null;
        const ordenes = await ConsultasAPI.ListarObjetos(
            URL_ORDENPAGO,
            pagination.pageIndex,
            pagination.pageSize,
            columnFilters,
            periodoComision.fechaComparar
                ? moment(
                    periodoComision.fechaComparar + '-01',
                    'YYYY/MM'
                ).format('YYYY-MM-DD')
                : periodoComision.fechaComparar,
            fechaSubida.fechaComparar,
            estadoDetalle,
            null,
            SucursalRendicion.id, //aqui
            null,
            null,
            null,
            estadoComisionF,
            estadoDocumentacionF
        );
        const dat = [];
        setCount(ordenes.data.count);
        for (let i = 0; i < ordenes.data.results.length; i++) {
            const obj = {
                agencia:
                    ordenes.data.results[i].titular_detalle.agencia_detalle
                        .numero_agencia,
                subagencia:
                    ordenes.data.results[i].titular_detalle.agencia_detalle
                        .numero_subagencia,
                periodo: moment(
                    ordenes.data.results[i].periodo_comision,
                    'DD/MM/YYYY'
                ).format('YYYY-MM-DD'),
            };
            const documentacion = await ConsultasAPI.CrearObjeto(
                URL_ARCHIVODOCUMENTACION +
                'obtenerDocumentacionAgenciaPeriodo/',
                obj
            );
            let rechazadas = '';
            if (documentacion.status !== 204) {
                documentacion.data.forEach((documento) => {
                    if (documento.estado_documentacion === 'Rechazado') {
                        rechazadas =
                            rechazadas +
                            documento.tipo +
                            ': ' +
                            documento.comentario +
                            ', ';
                    }
                });
            }
            const o = {
                id: ordenes.data.results[i].id,
                agencia:
                    ordenes.data.results[i].titular_detalle.agencia_detalle
                        .numero_agencia,
                subagencia:
                    ordenes.data.results[i].titular_detalle.agencia_detalle
                        .numero_subagencia,
                titular:
                    ordenes.data.results[i].titular_detalle.titular_detalle
                        .persona_detalle.apellido +
                    ' ' +
                    ordenes.data.results[i].titular_detalle.titular_detalle
                        .persona_detalle.nombre,
                cuit: ordenes.data.results[i].titular_detalle.titular_detalle
                    .persona_detalle.cuit,
                periodoComision: moment(
                    ordenes.data.results[i].periodo_comision,
                    'DD/MM/YYYY'
                ).format('MM/YYYY'),
                legajo: ordenes.data.results[i].legajo,
                estadoDocumentacion:
                    ordenes.data.results[i].estado_documentacion,
                DocumentacionRechazada:
                    rechazadas.length > 0 ? rechazadas : '-',
                estadoComision: ordenes.data.results[i].estado_comision,
                revisada_por:
                    documentacion.data && ordenes.data.results[i].legajo !== 'Incompleto'
                        ? documentacion.data.filter(
                            (x) => x.revisado_por !== null
                        )[0]
                            ? documentacion.data.filter(
                                (x) => x.revisado_por !== null
                            )[0].revisado_por
                            : '-'
                        : '-',
            };
            dat.push(o);
        }
        setData(dat);
    };

    const cargarTablaCondicionFiscal = async () => {
        const condiciones =
            await ConsultasAPI.ListarObjetos(URL_CONDICIONFISCAL);
        setTablaCondiciones(condiciones.data.results);
    };

    const handleCloseModal = () => {
        setCondicionFiscal({ id: '', condicion: '' });
        setShowModalDetalle(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleCloseModal();
    };


    const handleEditOrdenPago = async (row) => {
        setTituloModal('Editar');
        setIsLoadingView(true);

        setTitular(row.titular);
        setPeriodoComisionV(row.periodoComision);
        setEstadoDocumentacion(row.estadoDocumentacion);
        setLegajo(row.legajo);

        const datosOrden = await ConsultasAPI.ObtenerObjeto(
            URL_ORDENPAGO,
            row.id
        );
        setDataOrden(datosOrden.data);
        setDni(
            datosOrden.data.titular_detalle.titular_detalle.persona_detalle
                .documento
        );
        setCuit(
            datosOrden.data.titular_detalle.titular_detalle.persona_detalle.cuit
        );
        setIdTitular(datosOrden.data.titular_detalle.titular_detalle.id);

        const condFiscal = await ConsultasAPI.ObtenerObjeto(
            URL_CONDICIONFISCALTITULAR + 'busqueda/',
            datosOrden.data.titular_detalle.titular_detalle.id
        );
        setCondicionFiscal(
            tablaCondiciones.filter(
                (x) => x.id === parseInt(condFiscal.data.condicionfiscal)
            )[0]
        );
        setCondicionAnterior(
            tablaCondiciones.filter(
                (x) => x.id === parseInt(condFiscal.data.condicionfiscal)
            )[0]
        );
        setIdCondicionTitular(condFiscal.data.id);

        const agencia = await ConsultasAPI.ObtenerObjeto(
            URL_AGENCIA + 'busquedaId/',
            datosOrden.data.titular_detalle.agencia_detalle.id
        );
        setAgencia(agencia.data.numero_agencia);
        setSubagencia(agencia.data.numero_subagencia);
        setJurisdiccion(
            agencia.data.domicilio
                ? agencia.data.domicilio.localidad_detalle.nombre
                : ''
        );
        setZonaExplotacion(
            agencia.data.domicilio
                ? agencia.data.domicilio.zona.descripcion
                : ''
        );
        setLocalidad(
            agencia.data.domicilio
                ? agencia.data.domicilio.localidad_detalle.nombre
                : ''
        );
        const deducciones = await ConsultasAPI.ObtenerObjeto(
            URL_DEDUCCION + 'obtenerDeduccionPorTitularOP/',
            datosOrden.data.titular_agencia +
            ':' +
            moment(datosOrden.data.periodo_comision, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
            )
        );
        if (deducciones.status !== 204) {
            cargarTablaDeducciones(deducciones.data);
        }
        const t =
            datosOrden.data.titular_detalle.id + ',' + row.periodoComision;
        const a = datosOrden.data.titular_detalle.agencia_detalle.id;
        const l = await obtenerDatos(
            t,
            a,
            datosOrden.data,
            tablaCondiciones.filter(
                (x) => x.id === parseInt(condFiscal.data.condicionfiscal)
            )[0],
            row.periodoComision,
            agencia.data.domicilio
                ? agencia.data.domicilio.localidad_detalle.nombre
                : ''
        );
        if (l) {
            setShowModalDetalle(true);
            setIsLoadingView(false);
        }
    };

    const cargarTablaDeducciones = (info) => {
        const datDeducciones = [];
        const datDeduccionesImp = [];
        var imp = 0;
        for (const deduccion of info) {
            const d = {
                id: deduccion.id,
                concepto: deduccion.tipo_deduccion_detalle.descripcion,
                importe: deduccion.importe.toLocaleString('AR-es'),
                nroCuotaPlan: deduccion.cantidad_cuotas,
            };
            const l = {
                concepto: deduccion.tipo_deduccion_detalle.descripcion,
                nroCuotaPlan: '-',
                importe: deduccion.importe.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            };
            imp += deduccion.importe;
            datDeducciones.push(d);
            datDeduccionesImp.push(l);
        }
        setDataDed(datDeducciones);
        setDataDedImp(datDeduccionesImp);
        setImporteImp(imp);
    };

    const handleTablaCondicionChange = (condicion) => {
        setCondicionFiscal(
            tablaCondiciones.filter((x) => x.id === parseInt(condicion))[0]
        );
    };

    const handleGenerarImprimir = async () => {
        Swal.fire({
            title: '¿Estás seguro de generar e imprimir la Orden de Pago?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    if (condicionAnterior.id !== condicionFiscal.id) {
                        const condicionVieja = {
                            condicionfiscal: condicionAnterior.id,
                            titular: idTitular,
                            fecha_hasta: fechaDesde.fechaComparar,
                        };
                        await ConsultasAPI.ModificarObjeto(
                            URL_CONDICIONFISCALTITULAR,
                            idCondicionTitular,
                            condicionVieja
                        );
                        const condicionNueva = {
                            condicionfiscal: condicionFiscal.id,
                            titular: idTitular,
                            fecha_creacion: fechaDesde.fechaComparar,
                        };
                        const nueva = await ConsultasAPI.CrearObjeto(
                            URL_CONDICIONFISCALTITULAR,
                            condicionNueva
                        );

                        const edicionOrdenPago = {
                            fecha_facturacion: moment().format('YYYY-MM-DD'),
                            estado_comision: 'Generada',
                            periodo_comision: dataOrden.periodo_comision,
                            titular_agencia: dataOrden.titular_agencia,
                        };
                        await ConsultasAPI.ModificarObjeto(
                            URL_ORDENPAGO,
                            dataOrden.id,
                            edicionOrdenPago
                        );

                        if (nueva.status === 201) {
                            const pdfOP_blob = await generarPDF();
                            cargarOrdenesPago();
                            await ConsultasAPI.CrearObjeto(
                                URL_RETENCION +
                                'crearRetencionesOrdenesDePago/',
                                1
                            );
                            const linea_caja = [];
                            linea_caja.push({
                                id: dataOrden.id,
                                importe_neto: importeNeto,
                            });
                            let formData = new FormData();
                            formData.append('pdf', pdfOP_blob);
                            formData.append('linea_caja', linea_caja);
                            const Response = await ConsultasAPI.CrearObjetoArchivo(
                                URL_ORDENPAGO + 'enviarLineaCajaOP/',
                                formData
                            );

                            Swal.fire({
                                title: 'Se genero con Exito',
                                text: 'Se genero con Exito la Orden de Pago',
                                icon: 'success',
                                confirmButtonColor: '#008185',
                            });
                        } else {
                            Swal.fire({
                                title: 'Generación erronea',
                                text: 'No se pudo generar la Orden de Pago',
                                icon: 'error',
                                confirmButtonColor: '#EC1B23',
                            });
                        }
                    } else {
                        const edicionOrdenPago = {
                            fecha_facturacion: moment().format('YYYY-MM-DD'),
                            estado_comision: 'Generada',
                            periodo_comision: moment(
                                dataOrden.periodo_comision,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            titular_agencia: dataOrden.titular_agencia,
                        };
                        const edicion = await ConsultasAPI.ModificarObjeto(
                            URL_ORDENPAGO,
                            dataOrden.id,
                            edicionOrdenPago
                        );
                        if (edicion.status === 200) {
                            const pdfOP_blob = await generarPDF();
                            cargarOrdenesPago();
                            await ConsultasAPI.CrearObjeto(
                                URL_RETENCION +
                                'crearRetencionesOrdenesDePago/',
                                1
                            );
                            const linea_caja = [];
                            linea_caja.push({
                                id: dataOrden.id,
                                importe_neto: importeNeto,
                            });
                            let formData = new FormData();
                            console.info(pdfOP_blob)
                            if (pdfOP_blob) {
                                formData.append('pdf', pdfOP_blob);
                                formData.append('id', dataOrden.id);
                                formData.append('importe_neto', importeNeto);
                                const Response = await ConsultasAPI.CrearObjetoArchivo(
                                    URL_ORDENPAGO + 'enviarLineaCajaOP/',
                                    formData
                                );
                            }
                            Swal.fire({
                                title: 'Se genero con Exito',
                                text: 'Se genero con Exito la Orden de Pago',
                                icon: 'success',
                                confirmButtonColor: '#008185',
                            });
                        } else {
                            setPdfOP(null);
                            Swal.fire({
                                title: 'Generación erronea',
                                text: 'No se pudo generar la Orden de Pago',
                                icon: 'error',
                                confirmButtonColor: '#EC1B23',
                            });
                        }
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Generación erronea',
                        text: 'No se pudo generar la Orden de Pago',
                        icon: 'error',
                        confirmButtonColor: '#EC1B23',
                    });
                }
            }
        });
    };

    function generarPDF() {
        return new Promise((resolve, reject) => {
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            const img = new Image();
            img.src = require('../../../img/logo.png'); // Utiliza la ruta de importación directa

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                const dataURL = canvas.toDataURL('image/png');
                const fechaFacturacion = moment().format('DD/MM/YYYY');
                var body = [];
                var body2 = [];
                var bodys = [];
                var sumaImporte = 0;
                var sumaImporteDed = 0;

                if (dataDedImp.length !== 0 && dataRed.length !== 0) {
                    body = dataDedImp.map((data) => Object.values(data)); // Obtener los valores de cada objeto del JSON
                    body2 = dataRed.map((data) => Object.values(data)); // Obtener los valores de cada objeto del JSON
                    bodys = [...body, ...body2];

                    sumaImporte = bodys.reduce((total, row) => {
                        var importe = 0;
                        if (row[2].split('-').length > 1) {
                            importe = parseFloat(
                                row[2]
                                    .replace(/[^\d.,]+/g, '')
                                    .replace('.', '')
                                    .replace('.', '')
                                    .replace(',', '.')
                            );
                            return total - importe;
                        } else {
                            importe = parseFloat(
                                row[2]
                                    .replace(/[^\d.,]+/g, '')
                                    .replace('.', '')
                                    .replace('.', '')
                                    .replace(',', '.')
                            );
                            return total + importe;
                        }
                    }, 0);

                    sumaImporteDed = bodys.reduce((total, row) => {
                        var importe = 0;
                        if (row[2].split('-').length > 1) {
                            importe = parseFloat(
                                row[2]
                                    .replace(/[^\d.,]+/g, '')
                                    .replace('.', '')
                                    .replace(',', '.')
                            );
                            return total - importe;
                        } else {
                            importe = parseFloat(
                                row[2]
                                    .replace(/[^\d.,]+/g, '')
                                    .replace('.', '')
                                    .replace(',', '.')
                            );
                            return total + importe;
                        }
                    }, 0);

                    if (comisionBruta - sumaImporte <= 0) {
                        Swal.fire({
                            title: 'No se pudo realizar la generacion',
                            text: 'No se pueden realizar las deducciones',
                            icon: 'error',
                            confirmButtonColor: '#EC1B23',
                        });
                        return 0;
                    }
                }
                const documentDefinition = {
                    // pageOrientation: "landscape",
                    content: [
                        {
                            text: 'Caja Popular de Ahorro',
                            style: 'header',
                            alignment: 'center',
                        },
                        {
                            text: 'Orden De Pago',
                            style: 'subheader',
                            alignment: 'center',
                        },
                        {
                            text: 'Orden N°' + dataOrden.numero_orden,
                            style: 'subheader',
                            alignment: 'right',
                        },
                        {
                            style: 'tableExample',
                            table: {
                                headerRows: 1,
                                widths: [530],
                                body: [[{ text: '', style: 'tableHeader' }], ['']],
                            },
                            layout: 'headerLineOnly',
                        },

                        {
                            alignment: 'center',
                            columns: [
                                {
                                    text: '\nFecha de Emision: ' + fechaFacturacion,
                                },
                                {
                                    text: '\nProveedor: ' + titular,
                                },
                            ],
                        },
                        {
                            alignment: 'center',
                            columns: [
                                {
                                    text: '\nFecha de Pago: ' + fechaFacturacion,
                                },
                                {
                                    text:
                                        '\nN° de Agencia: ' +
                                        agencia.toString().padStart(2, '0') +
                                        '-' +
                                        subagencia.toString().padStart(3, '0'),
                                },
                            ],
                        },
                        {
                            alignment: 'center',
                            columns: [
                                {
                                    text: '\nConcepto: Pago de Comisiones',
                                },
                                {
                                    text: '\nCUIT Proveedor: ' + cuit,
                                },
                            ],
                        },

                        {
                            text: '\nComprobante de origen',
                            style: 'subheader',
                            alignment: 'left',
                        },

                        {
                            style: 'tableExample',
                            alignment: 'center',
                            table: {
                                widths: [100, 100, 100, 100, 100],
                                body: [
                                    [
                                        {
                                            text: 'Fecha de Factura',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Nro Factura',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Tipo  Factura',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Comision',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Importe',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                    ],
                                    [
                                        datosFactura.datos_json.fechaFactura,
                                        datosFactura.datos_json.nroFactura,
                                        datosFactura.datos_json.tipoFactura,
                                        moment(datosFactura.periodo, 'DD/MM/YYYY')
                                            .format('MMMM YYYY')
                                            .charAt(0)
                                            .toUpperCase() +
                                        moment(
                                            datosFactura.periodo,
                                            'DD/MM/YYYY'
                                        )
                                            .format('MMMM YYYY')
                                            .slice(1),
                                        comisionBruta.toLocaleString('es-AR', {
                                            style: 'currency',
                                            currency: 'ARS',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }),
                                    ],
                                ],
                            },
                        },

                        {
                            headerRows: 1,
                            style: 'tableExample',
                            alignment: 'center',
                            table: {
                                widths: [171, 171, 171],
                                body: [
                                    [
                                        {
                                            text: 'Detalle Deducciones',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Nro Certificado',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Importe',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                    ],
                                    ...bodys,
                                ],
                            },
                        },

                        {
                            text:
                                '\nSubtotal:' +
                                sumaImporte.toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                            style: 'subheader',
                            alignment: 'right',
                        },

                        {
                            text:
                                '\nImporte Neto:' +
                                (comisionBruta - sumaImporte).toLocaleString(
                                    'es-AR',
                                    {
                                        style: 'currency',
                                        currency: 'ARS',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }
                                ),
                            style: 'subheader',
                            alignment: 'left',
                        },

                        {
                            style: 'tableExample',
                            alignment: 'center',

                            table: {
                                widths: [250, 250],
                                headerRows: 2,
                                body: [
                                    [
                                        {
                                            text: 'Medio de Pago',
                                            style: 'tableHeader',
                                            colSpan: 2,
                                            alignment: 'center',
                                        },
                                        {},
                                    ],
                                    [
                                        {
                                            text: 'Nro de Cheque',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                        {
                                            text: 'Nro de Cuenta',
                                            style: 'tableHeader',
                                            alignment: 'center',
                                        },
                                    ],
                                    // ...body,
                                ],
                            },
                        },
                        {
                            text: '\nAutorizado Por:',
                            style: 'text',
                            alignment: 'left',
                        },
                        {
                            text: '\nFirma:',
                            style: 'text',
                            alignment: 'center',
                        },
                        {
                            text: '\nIdLineadeCaja: ' + dataOrden.numero_orden,
                            style: 'text',
                            color: '#A9A9A9',
                            absolutePosition: { x: 40, y: 750 }, // Ajusta y según sea necesario
                        },
                    ],
                    styles: {
                        header: {
                            fontSize: 18,
                            bold: true,
                            margin: [0, 0, 0, 10],
                        },
                        subheader: {
                            fontSize: 16,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },
                        text: {
                            fontSize: 12,
                            bold: false,
                            // margin: [0, 10, 0, 5]
                        },
                        tableExample: {
                            margin: [0, 5, 0, 15],
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 13,
                            color: 'black',
                        },
                    },
                    images: {
                        sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                    },
                    pageMargins: [40, 60, 40, 60],
                    background: [
                        {
                            absolutePosition: { x: 40, y: 40 }, // Coordenadas x, y para la imagen
                            image: 'sampleImage', // Referencia a la imagen definida en 'images'
                            color: 'white',
                            width: 150.6, // Cambia el ancho de la imagen
                            height: 88.8, // Cambia la altura de la imagen
                        },
                    ],
                    defaultStyle: {
                        // alignment: 'justify'
                    },
                };
                const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

                // Descarga el PDF
                pdfDocGenerator.download('OrdenDePago-' + titular.trim() + '.pdf');

                // Obtén el PDF en formato base64 y realiza el POST
                pdfDocGenerator.getBlob((blob) => {
                    resolve(blob);
                });
            };
        });
    };

    const URL_JUGADASMES = window.API_ROUTES.JUGADASMES;
    const URL_COMISION = window.API_ROUTES.COMISION;
    const URL_ARCHIVODOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
    const [retencion, setRetencion] = useState([]);
    const [factura, setFactura] = useState('');
    const [comisionTuqui, setComisionTuqui] = useState('');
    const [comisionQuiniela, setComisionQuiniela] = useState('');
    const [importeNeto, setImporteNeto] = useState(0);
    const [datosFactura, setDatosFactura] = useState([]);
    const [comisionBruta, setComisionBruta] = useState('');
    //const [ordenesImp, setOrdenesImp] = useState([]);

    useEffect(() => {
        if (importeImp !== 0 && comisionBruta !== 0 && totalRetenciones !== 0) {
            setImporteNeto(comisionBruta - importeImp - totalRetenciones);
        }
    }, [importeImp, comisionBruta, totalRetenciones]);

    const obtenerDatos = async (
        titu,
        ag,
        orden,
        condicion,
        periodoComisionV,
        localidad
    ) => {
        let cond = condicion;
        cond = null;
        let loc = localidad;
        loc = null;
        // setIsLoadingView(true)
        const datos = await ConsultasAPI.ObtenerObjeto(
            URL_JUGADASMES + 'obtenerJugadaTitular/',
            titu
        );

        const datosTuqui = await ConsultasAPI.ObtenerObjeto(
            URL_COMISION + 'obtenerComisionTitularTotal/',
            titu.split(',')[0] +
            ':' +
            moment('01/' + periodoComisionV, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
            )
        );
        if (datos.data.length === 0 || !datos.data[0].aprobado) {
            Swal.fire({
                title: 'No se cargaron las comisiones de Quiniela',
                icon: 'error',
                confirmButtonColor: '#EC1B23',
            });
            setIsLoadingView(false);
            handleCloseModal();
        } else {
            const aut = {
                tipo_deduccion: 'Automatizacion',
                importe: datos.data[0].comisionBruta,
                agencia: ag,
                titular_agencia: titu.split(',')[0],
                periodo: moment('01/' + periodoComisionV, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD'
                ),
                orden_pago: orden.id,
            };
            const deduccion = await ConsultasAPI.CrearObjeto(
                URL_DEDUCCION + 'crearAutomatizacion/',
                aut
            );
            if (deduccion.status === 201) {
                const deducciones = await ConsultasAPI.ObtenerObjeto(
                    URL_DEDUCCION + 'obtenerDeduccionPorTitularOP/',
                    datos.data[0].titularAgencia +
                    ':' +
                    moment('01/' + periodoComisionV, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    )
                );
                if (deducciones.status !== 204) {
                    cargarTablaDeducciones(deducciones.data);
                }
            }
            if (datosTuqui.status !== 200) {
                Swal.fire({
                    title: 'No se cargaron las comisiones de Tuqui',
                    icon: 'error',
                    confirmButtonColor: '#EC1B23',
                });
                setIsLoadingView(false);
                setRetencion(datos.data[0]);
                setComisionTuqui(0);
                setComisionBruta(datos.data[0].comisionBruta);
                setComisionQuiniela(datos.data[0].comisionBruta);
                const factura = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVODOCUMENTACION + 'obtenerFacturaAgencia/',
                    ag +
                    ':' +
                    moment('01/' + periodoComisionV, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    )
                );
                const tem = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVODOCUMENTACION + 'obtenerTemAgencia/',
                    ag +
                    ':' +
                    moment('01/' + periodoComisionV, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    )
                );
                // setTem(
                //     tem.data.datos_json.actividad
                //         ? tem.data.datos_json.actividad
                //         : '-'
                // );
                setFactura(factura.data.datos_json.tipoFactura);
                setDatosFactura(factura.data);

                // const afip = await obtenerRetencionAfip(condicion, factura.data.datos_json.tipoFactura, datos.data[0].comisionBruta);
                // const iva = await obtenerRetencionIva(condicion, factura.data.datos_json.tipoFactura, datos.data[0].comisionBruta);
                // const jurisdiccion = await retencionJurisdiccion(localidad, datos.data[0].comisionBruta
                // , factura.data.titular_detalle.agencia_detalle.numero_subagencia, tem.data.datos_json.actividad)

                // const iibb = await retencionIIBB(datos.data[0].comisionBruta);
                const retenciones = await ConsultasAPI.ObtenerObjeto(
                    URL_RETENCION + 'busquedaRetencionOP/',
                    orden.id
                );
                const afip = retenciones.data.retencion_ganancias;
                const iva = retenciones.data.retencion_iva;
                const jurisdiccion = retenciones.data.retencion_municipal;
                const iibb =
                    retenciones.data.retencion_iibb_quiniela +
                    retenciones.data.retencion_iibb_tuqui;
                setTotalRetenciones(afip + iva + jurisdiccion + iibb);
                handleRetencionAfip(afip, iva, jurisdiccion, iibb);
                // handleRetencionIva(iva)
                // handleRetencionJurisdiccion(jurisdiccion)
                // handleRetencionIIBB(iibb)

                // if(importeImp !==0){
                //     setImporteNeto((datos.data[0].comisionBruta + datosTuqui.data[0].comision_bruta) - importeImp - afip - iva - jurisdiccion - iibb)
                // }

                setIsLoadingView(false);
                return datos.status;
                // handleCloseModal();
            } else {
                setRetencion(datos.data[0]);
                setComisionTuqui(datosTuqui.data);
                setComisionBruta(
                    datos.data[0].comisionBruta +
                    datosTuqui.data
                );
                setComisionQuiniela(datos.data[0].comisionBruta);
                const factura = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVODOCUMENTACION + 'obtenerFacturaAgencia/',
                    ag +
                    ':' +
                    moment('01/' + periodoComisionV, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    )
                );
                const tem = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVODOCUMENTACION + 'obtenerTemAgencia/',
                    ag +
                    ':' +
                    moment('01/' + periodoComisionV, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    )
                );
                // setTem(
                //     tem.data.datos_json.actividad
                //         ? tem.data.datos_json.actividad
                //         : '-'
                // );
                setFactura(factura.data.datos_json.tipoFactura);
                setDatosFactura(factura.data);
                const retenciones = await ConsultasAPI.ObtenerObjeto(
                    URL_RETENCION + 'busquedaRetencionOP/',
                    orden.id
                );
                const afip = retenciones.data.retencion_ganancias;
                const iva = retenciones.data.retencion_iva;
                const jurisdiccion = retenciones.data.retencion_municipal;
                const iibb =
                    retenciones.data.retencion_iibb_quiniela +
                    retenciones.data.retencion_iibb_tuqui;
                // const afip = await obtenerRetencionAfip(condicion, factura.data.datos_json.tipoFactura, datos.data[0].comisionBruta + datosTuqui.data[0].comision_bruta);
                // const iva = await obtenerRetencionIva(condicion, factura.data.datos_json.tipoFactura, datos.data[0].comisionBruta + datosTuqui.data[0].comision_bruta);
                // const jurisdiccion = await retencionJurisdiccion(localidad, datos.data[0].comisionBruta
                //     + datosTuqui.data[0].comision_bruta, factura.data.titular_detalle.agencia_detalle.numero_subagencia, tem.data.datos_json.actividad)

                // const iibb = await retencionIIBB(datos.data[0].comisionBruta + datosTuqui.data[0].comision_bruta);
                setTotalRetenciones(afip + iva + jurisdiccion + iibb);
                handleRetencionAfip(afip, iva, jurisdiccion, iibb);
                // handleRetencionIva(iva)
                // handleRetencionJurisdiccion(jurisdiccion)
                // handleRetencionIIBB(iibb)

                // if(importeImp !==0){
                //     setImporteNeto((datos.data[0].comisionBruta + datosTuqui.data[0].comision_bruta) - importeImp - afip - iva - jurisdiccion - iibb)
                // }

                setIsLoadingView(false);
                return datos.status;
            }
        }
    };

    const handleRetencionAfip = (retencionAfip, iva, jurisdiccion, iibb) => {
        if (!isNaN(retencionAfip)) {
            //const dat = [...dataRed];
            const l = {
                concepto: 'Retencion GCIAS',
                nroCuotaPlan: '-',
                importe: retencionAfip.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            };
            dataPush.push(l);
            // setDataRed(dataPush)
            handleRetencionIva(iva, jurisdiccion, iibb);
        }
    };
    const handleRetencionJurisdiccion = (retencionJurisdiccion, iibb) => {
        if (!isNaN(retencionJurisdiccion)) {
            //const dat = [...dataRed];
            const l = {
                concepto: 'Ret. Municipal',
                nroCuotaPlan: '-',
                importe: retencionJurisdiccion.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            };
            dataPush.push(l);
            // setDataRed(dataPush)
            handleRetencionIIBB(iibb);
        }
    };

    const handleRetencionIva = (retencionIva, jurisdiccion, iibb) => {
        if (!isNaN(retencionIva)) {
            //const dat = [...dataRed];
            const l = {
                concepto: 'Retencion IVA',
                nroCuotaPlan: '-',
                importe: retencionIva.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            };
            dataPush.push(l);
            // setDataRed(dataPush)
            handleRetencionJurisdiccion(jurisdiccion, iibb);
        }
    };
    const handleRetencionIIBB = (retencionIIBB) => {
        if (!isNaN(retencionIIBB)) {
            //const dat = [...dataRed];
            const l = {
                concepto: 'Retencion IIBB',
                nroCuotaPlan: '-',
                importe: retencionIIBB.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            };
            dataPush.push(l);
            setDataRed(dataPush);
        }
    };

    const pagarOrdenes = async () => {
        if (rowSelected.length === 0) {
            Swal.fire({
                title: 'No Se Seleccionaron Ordenes Para Pagar',
                icon: 'error',
                confirmButtonColor: '#008185',
            });
        } else {
            try {
                const pagar = await ConsultasAPI.CrearObjeto(
                    URL_ORDENPAGO + 'PagarOrdenes/',
                    rowSelected
                );
                if (pagar.status === 200) {
                    cargarOrdenesPago();
                    Swal.fire({
                        title: 'Se Pagaron con Exito las Ordenes Seleccionadas',
                        icon: 'success',
                        confirmButtonColor: '#EC1B23',
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'No Se Pagaron las Ordenes Seleccionadas',
                    text: error.response.data,
                    icon: 'error',
                    confirmButtonColor: '#EC1B23',
                });
            }
        }
    };

    const imprimirOrdenes = async () => {
        const dataImpresion = [];
        setIsLoadingView(true);
        const sinPlata = [];
        const linea_caja = [];
        rowSelected.forEach(async (orden) => {
            const ord = await ConsultasAPI.ObtenerObjeto(
                URL_ORDENPAGO,
                orden.id
            );
            const datos = await ConsultasAPI.ObtenerObjeto(
                URL_JUGADASMES + 'obtenerJugadaTitular/',
                ord.data.titular_agencia +
                ',' +
                moment(ord.data.periodo_comision, 'DD/MM/YYYY').format(
                    'MM/YYYY'
                )
            );
            const datosTuqui = await ConsultasAPI.ObtenerObjeto(
                URL_COMISION + 'obtenerComisionAgencia/',
                ord.data.titular_detalle.agencia +
                ':' +
                moment(ord.data.periodo_comision, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD'
                )
            );
            if (datos.status === 200 && datosTuqui.status === 200) {
                const aut = {
                    tipo_deduccion: 'Automatizacion',
                    importe: datos.data[0].comisionBruta.toFixed(2),
                    agencia: ord.data.titular_detalle.agencia,
                    titular_agencia: ord.data.titular_agencia,
                    periodo: moment(
                        ord.data.periodo_comision,
                        'DD/MM/YYYY'
                    ).format('YYYY-MM-DD'),
                    orden_pago: orden.id,
                };
                try {
                    await ConsultasAPI.CrearObjeto(
                        URL_DEDUCCION + 'crearAutomatizacion/',
                        aut
                    );
                } catch (error) {
                    console.info(error);
                }

                const factura = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVODOCUMENTACION + 'obtenerFacturaAgencia/',
                    ord.data.titular_detalle.agencia +
                    ':' +
                    moment(ord.data.periodo_comision, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    )
                );
                const deducciones = await ConsultasAPI.ObtenerObjeto(
                    URL_DEDUCCION + 'obtenerDeduccionPorTitularOP/',
                    ord.data.titular_agencia +
                    ':' +
                    moment(ord.data.periodo_comision, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    )
                );
                var datDeduccionesImp = [];
                var imp = 0;
                for (const deduccion of deducciones.data) {
                    const l = {
                        concepto: deduccion.tipo_deduccion_detalle.descripcion,
                        nroCuotaPlan:
                            deduccion.nro_cuota === 0
                                ? '-'
                                : deduccion.nro_cuota,
                        importe: parseFloat(deduccion.importe).toLocaleString(
                            'es-AR',
                            {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }
                        ),
                    };
                    imp += deduccion.importe;
                    datDeduccionesImp.push(l);
                }

                const condFiscal = await ConsultasAPI.ObtenerObjeto(
                    URL_CONDICIONFISCALTITULAR + 'busqueda/',
                    ord.data.titular_detalle.titular_detalle.id
                );

                const agencia = await ConsultasAPI.ObtenerObjeto(
                    URL_AGENCIA + 'busquedaId/',
                    ord.data.titular_detalle.agencia
                );
                await ConsultasAPI.ObtenerObjeto(
                    URL_RETENCION + 'busquedaRetencionOP/',
                    ord.data.id
                );
                const retencionJuri = await retencionJurisdiccion(
                    agencia.data.domicilio.localidad_detalle.nombre,
                    datos.data[0].comisionBruta +
                    datosTuqui.data[0].comision_bruta,
                    ord.data.titular_detalle.agencia_detalle.numero_subagencia,
                    tablaCondiciones.filter(
                        (x) =>
                            x.id === parseInt(condFiscal.data.condicionfiscal)
                    )[0]
                );
                const retencionAfip = await obtenerRetencionAfip(
                    tablaCondiciones.filter(
                        (x) =>
                            x.id === parseInt(condFiscal.data.condicionfiscal)
                    )[0],
                    factura.data.datos_json.tipoFactura,
                    datos.data[0].comisionBruta +
                    datosTuqui.data[0].comision_bruta
                );
                const retencionIva = await obtenerRetencionIva(
                    tablaCondiciones.filter(
                        (x) =>
                            x.id === parseInt(condFiscal.data.condicionfiscal)
                    )[0],
                    factura.data.datos_json.tipoFactura,
                    datos.data[0].comisionBruta +
                    datosTuqui.data[0].comision_bruta
                );
                const retencionIB = await retencionIIBB(
                    datos.data[0].comisionBruta +
                    datosTuqui.data[0].comision_bruta
                );
                const codigo = await concatCodigo(
                    ord.data.titular_detalle.agencia_detalle.numero_agencia,
                    ord.data.titular_detalle.agencia_detalle.numero_subagencia
                );
                const imprimir = {
                    deducciones: datDeduccionesImp,
                    dateRed: [
                        {
                            tipo: 'RETENCION GCIAS',
                            importe: retencionAfip.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                        },
                        {
                            tipo: 'RET. MUNICIPAL',
                            importe: retencionJuri.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                        },
                        {
                            tipo: 'RETENCION IVA',
                            importe: retencionIva.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                        },
                        {
                            tipo: 'RETENCION IIBB',
                            importe: retencionIB.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                        },
                    ],
                    codigo: codigo,
                    numero_orden: ord.data.numero_orden,
                    titular:
                        ord.data.titular_detalle.titular_detalle.persona_detalle
                            .apellido +
                        ' ' +
                        ord.data.titular_detalle.titular_detalle.persona_detalle
                            .nombre,
                    cuit: ord.data.titular_detalle.titular_detalle
                        .persona_detalle.cuit,
                    factura: factura.data,
                    comisionBruta:
                        parseFloat(datos.data[0].comisionBruta) +
                        parseFloat(datosTuqui.data[0].comision_bruta),
                    subTotal:
                        retencionAfip +
                        retencionJuri +
                        retencionIva +
                        retencionIB +
                        imp,
                };

                if (
                    parseFloat(datos.data[0].comisionBruta) -
                    parseFloat(
                        retencionAfip +
                        retencionJuri +
                        retencionIva +
                        retencionIB +
                        imp
                    ) <=
                    0
                ) {
                    var l = {
                        titular:
                            ord.data.titular_detalle.titular_detalle
                                .persona_detalle.apellido +
                            ' ' +
                            ord.data.titular_detalle.titular_detalle
                                .persona_detalle.nombre,
                        periodo: ord.data.periodo_comision,
                    };
                    sinPlata.push(l);
                } else {
                    dataImpresion.push(imprimir);
                    const edicionOrdenPago = {
                        fecha_facturacion: moment().format('YYYY-MM-DD'),
                        estado_comision: 'Generada',
                        periodo_comision: moment(
                            ord.data.periodo_comision,
                            'DD/MM/YYYY'
                        ).format('YYYY-MM-DD'),
                        titular_agencia: ord.data.titular_agencia,
                    };
                    const edicion = await ConsultasAPI.ModificarObjeto(
                        URL_ORDENPAGO,
                        ord.data.id,
                        edicionOrdenPago
                    );
                    if (edicion.status === 200) {
                        linea_caja.push({
                            lineacaja: {
                                id: ord.data.id,
                                importe_neto:
                                    parseFloat(datos.data[0].comisionBruta) +
                                    parseFloat(datosTuqui.data[0].comision_bruta) -
                                    (retencionAfip +
                                        retencionJuri +
                                        retencionIva +
                                        retencionIB +
                                        imp)
                            },
                            dataImpresion: imprimir,
                        });
                    }
                }
            } else {
                setIsLoadingView(false);
                Swal.fire({
                    title: 'Hubo un error al Generar',
                    text: 'No se encontraro comisiones de tuqui o de quiniela para generar la orden de pago',
                    icon: 'error',
                    confirmButtonColor: '#EC1B23',
                });
            }
        });
        cargarOrdenesPago();
        await ConsultasAPI.CrearObjeto(
            URL_RETENCION + 'crearRetencionesOrdenesDePago/',
            1
        );
        esperarArrayDatos(dataImpresion, sinPlata, linea_caja);
    };

    const concatCodigo = (a, b) => {
        let codigoAgencia = '';
        if (a < 10) {
            codigoAgencia = '0' + a.toString();
            if (b < 10) {
                codigoAgencia = codigoAgencia + '00' + b.toString();
            } else {
                if (b < 99) {
                    codigoAgencia = codigoAgencia + '0' + b.toString();
                } else {
                    codigoAgencia = codigoAgencia + b.toString();
                }
            }
        } else {
            codigoAgencia = a.toString();
            if (b < 10) {
                codigoAgencia = codigoAgencia + '00' + b.toString();
            } else {
                if (b < 99) {
                    codigoAgencia = codigoAgencia + '0' + b.toString();
                } else {
                    codigoAgencia = codigoAgencia + b.toString();
                }
            }
        }
        return codigoAgencia;
    };

    async function esperarArrayDatos(array, sin, linea_caja) {
        while (array.length === 0 && sin.length === 0) {
            await new Promise((resolve) => setTimeout(resolve, 2000)); // Espera 1 segundo antes de volver a verificar
        }
        if (sin.length !== 0) {
            setIsLoadingView(false);
            Swal.fire({
                title: 'No se Pudieron generar Las Ordenes de Pago',
                text: `No se pudieron generar las siguientes ordenes de pago: ${sin
                    .map(
                        (tipo) =>
                            tipo.titular +
                            '- ' +
                            moment(tipo.periodo, 'DD/MM/YYYY').format('MM/YYYY')
                    )
                    .join(' , ')}`,
                icon: 'error',
                confirmButtonColor: '#EC1B23',
            });
        }
        linea_caja.forEach(async data => {
            let formData = new FormData();
            const pdf = await generarPDFsLineaCaja(data['dataImpresion'])
            formData.append('pdf', pdf);
            formData.append('id', data['lineacaja']['id']);
            formData.append('importe_neto', data['lineacaja']['importe_neto']);

            const Response = ConsultasAPI.CrearObjetoArchivo(
                URL_ORDENPAGO + 'enviarLineaCajaOP/',
                formData
            );
        });

        generarPDFs(array);
    }

    async function retencionJurisdiccion(
        jurisdiccion,
        comisionBruta,
        numero_subagencia,
        actividad
    ) {
        if (
            jurisdiccion === 'AGUILARES' ||
            jurisdiccion === 'JUAN BAUTISTA ALBERDI' ||
            jurisdiccion === 'LAS TALITAS'
        ) {
            return (4 * comisionBruta) / 100;
        } else {
            if (jurisdiccion === 'BANDA DEL RIO SALI') {
                return (4.4 * comisionBruta) / 100;
            } else {
                if (jurisdiccion === 'FAMAILLA' && numero_subagencia === 0) {
                    return (3 * comisionBruta) / 100;
                } else {
                    if (
                        jurisdiccion === 'SAN MIGUEL DE TUCUMAN' &&
                        comisionBruta > 20000
                    ) {
                        if (actividad !== 'No Inscripto') {
                            return (3.7 * comisionBruta) / 100;
                        } else {
                            return (7.4 * comisionBruta) / 100;
                        }
                    } else {
                        if (jurisdiccion === 'SIMOCA') {
                            return (3 * comisionBruta) / 100;
                        } else {
                            if (jurisdiccion === 'TAFI VIEJO') {
                                return (2 * comisionBruta) / 100;
                            } else {
                                if (jurisdiccion === 'MONTEROS') {
                                    return 0 * comisionBruta;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    async function obtenerRetencionAfip(
        condicionFiscal,
        tipoFactura,
        comisionBruta
    ) {
        if (condicionFiscal.condicion === 'INSCRIPTO EN GANANCIAS') {
            if (tipoFactura === 'C' || tipoFactura === 'A') {
                if (comisionBruta - 67170 >= 240) {
                    return (2 * (comisionBruta - 67170)) / 100;
                } else {
                    return 0;
                }
            } else {
                if (tipoFactura === 'A sujeta a retención') {
                    return (3 * comisionBruta) / 100;
                } else {
                    if (tipoFactura === 'M') {
                        return (6 * (comisionBruta - 67170)) / 100;
                    }
                }
            }
        } else {
            if (condicionFiscal.condicion === 'RESPONSABLE NO INSCRIPTO') {
                return (28 * comisionBruta) / 100;
            } else {
                if (condicionFiscal.condicion === 'MONOTRIBUTO') {
                    return (0 * comisionBruta) / 100;
                } else {
                    return (35 * comisionBruta) / 100;
                }
            }
        }
    }

    async function obtenerRetencionIva(
        condicionFiscal,
        tipoFactura,
        comisionBruta
    ) {
        if (condicionFiscal.condicion === 'INSCRIPTO EN GANANCIAS') {
            if (comisionBruta >= 67170) {
                if (tipoFactura === 'C' || tipoFactura === 'A') {
                    return (0 * comisionBruta) / 100;
                } else {
                    if (tipoFactura === 'A sujeta a retención') {
                        return (0 * comisionBruta) / 100;
                    } else {
                        return (0 * comisionBruta) / 100;
                    }
                }
            }
        } else {
            if (condicionFiscal.condicion === 'RESPONSABLE NO INSCRIPTO') {
                return (0 * comisionBruta) / 100;
            } else {
                if (condicionFiscal.condicion === 'MONOTRIBUTO') {
                    return (0 * comisionBruta) / 100;
                } else {
                    return (21 * comisionBruta) / 100;
                }
            }
        }
    }

    async function retencionIIBB(comisionBruta) {
        return (comisionBruta * 6.5) / 100;
    }

    const handleCloseModalTxt = async (event) => {
        event.preventDefault();
        setTipoSucursal('');
        fechaDepositoRef.current.setState({ inputValue: '' });
        setFechaDeposito(moment());
        setShowModalTxt(false);
    };

    const handleSubmitTxt = async (event) => {
        event.preventDefault();
        const filasNota = [];
        let sumaTotal = 0;
        const ordenes = [];
        let vectorOrdenes = [];
        setIsLoadingView(true);
        if (rowSelected.length > 0) {
            vectorOrdenes = rowSelected;
        } else {
            let estadoDetalle = estado.detalle ? estado.detalle : null;
            const ordenes = await ConsultasAPI.ListarObjetos(
                URL_ORDENPAGO + 'ListGeneradas/',
                pagination.pageIndex,
                pagination.pageSize,
                columnFilters,
                periodoComision.fechaComparar
                    ? moment(
                        periodoComision.fechaComparar + '-01',
                        'YYYY/MM'
                    ).format('YYYY-MM-DD')
                    : periodoComision.fechaComparar,
                fechaSubida.fechaComparar,
                estadoDetalle,
                null,
                SucursalRendicion.id, //aqui
                null,
                null,
                null,
                estadoComisionF,
                estadoDocumentacionF
            );

            vectorOrdenes = ordenes.data;
        }
        let acumuladoTxt = 0;
        const promises = vectorOrdenes.map(async (row, index) => {
            let ordenesPago = null;
            if (rowSelected.length > 0) {
                const Filter = [{ id: 'id', value: row.id.toString() }];
                const response = await ConsultasAPI.ListarObjetos(
                    URL_ORDENPAGO + 'ListGeneradas/',
                    0,
                    10,
                    Filter
                );
                if (response.data.length > 0) {
                    ordenesPago = response.data[0];
                }
            } else {
                ordenesPago = row;
            }
            if (ordenesPago) {
                let orden = ordenesPago;
                //total de retenciones
                var suma_retenciones = orden.retencion
                    ? orden.retencion.retencion_ganancias +
                    orden.retencion.retencion_iva +
                    orden.retencion.retencion_iibb_quiniela +
                    orden.retencion.retencion_iibb_tuqui +
                    orden.retencion.retencion_municipal
                    : 0;
                //deducciones individuales
                var seguro = orden.deducciones_Seguros
                    ? orden.deducciones_Seguros.importe
                    : 0;
                var Tarjeta_Visa = orden.deducciones_TarjetaVISA
                    ? orden.deducciones_TarjetaVISA.importe
                    : 0;
                var Tarjeta_CPA = orden.deducciones_TarjetaCABAL
                    ? orden.deducciones_TarjetaCABAL.importe
                    : 0;
                var EmbargosFijos = orden.deducciones_EmbargosFijos
                    ? orden.deducciones_EmbargosFijos.importe
                    : 0;
                var Multas = orden.deducciones_Multas
                    ? orden.deducciones_Multas.importe
                    : 0;
                var Automatizacion = orden.deducciones_Automatizacion
                    ? orden.deducciones_Automatizacion.importe
                    : 0;
                var Ticket = orden.deducciones_Ticket
                    ? orden.deducciones_Ticket.importe
                    : 0;
                var Reposicion_de_Equipo =
                    orden.deducciones_Productodemantenimientodeequipos
                        ? orden.deducciones_Productodemantenimientodeequipos
                            .importe
                        : 0;
                var PlandePagodeudaAFIP = orden.deducciones_PlandePagodeudaAFIP
                    ? orden.deducciones_PlandePagodeudaAFIP.importe
                    : 0;
                var EmbargosPorcentuales =
                    orden.deducciones_EmbargosPorcentuales
                        ? orden.deducciones_EmbargosPorcentuales.importe
                        : 0;
                var Reajuste_Comision = orden.deducciones_ReajusteComision
                    ? orden.deducciones_ReajusteComision.importe
                    : 0;
                // Suma de Deducciones
                var suma_deducciones =
                    seguro +
                    Tarjeta_Visa +
                    Tarjeta_CPA +
                    EmbargosFijos +
                    Multas +
                    Automatizacion +
                    Ticket +
                    Reposicion_de_Equipo +
                    PlandePagodeudaAFIP +
                    EmbargosPorcentuales +
                    Reajuste_Comision;

                var importe_neto;
                if (orden.comisionT && orden.comisionQ) {
                    importe_neto = (
                        orden.comisionQ +
                        orden.comisionT -
                        suma_retenciones -
                        suma_deducciones
                    )
                        .toFixed(2)
                        .toString()
                        .replace('.', '')
                        .padStart(11, '0');
                } else if (orden.comisionQ) {
                    importe_neto = (
                        orden.comisionQ -
                        suma_retenciones -
                        suma_deducciones
                    )
                        .toFixed(2)
                        .toString()
                        .replace('.', '')
                        .padStart(11, '0');
                } else {
                    importe_neto = '-';
                }
                if (
                    orden.titular_detalle.titular_detalle.persona_detalle
                        .nro_cuenta
                ) {
                    let filter = {
                        orden_pago: orden.id,
                    };
                    const registradoDatos = await ConsultasAPI.ListarObjetos(
                        URL_REGISTROMAIL,
                        null,
                        null,
                        filter
                    );
                    if (
                        registradoDatos.data.length === 0 ||
                        vectorOrdenes === rowSelected
                    ) {
                        ordenes.push(
                            '0101' +
                            orden.titular_detalle.titular_detalle.persona_detalle.nro_cuenta.padStart(
                                8,
                                '0'
                            ) +
                            fechaDeposito.format('YYYYMMDD') +
                            '0080' +
                            importe_neto
                        );
                        // let periodo = moment(
                        //     orden.periodo_comision,
                        //     'DD/MM/AAAA'
                        // )
                        //     .format('MMMM YYYY')
                        //     .toUpperCase();
                        sumaTotal += parseFloat(importe_neto) / 100;
                        filasNota.push({
                            CUENTA: orden.titular_detalle.titular_detalle
                                .persona_detalle.nro_cuenta,
                            AG: concatCodigo(
                                orden.titular_detalle.agencia_detalle
                                    .numero_agencia,
                                orden.titular_detalle.agencia_detalle
                                    .numero_subagencia
                            ),
                            'APELLIDO Y NOMBRE':
                                orden.titular_detalle.titular_detalle
                                    .persona_detalle.apellido +
                                ' ' +
                                orden.titular_detalle.titular_detalle
                                    .persona_detalle.nombre,
                            DNI: orden.titular_detalle.titular_detalle
                                .persona_detalle.documento,
                            IMPORTE: Parser.currency(
                                parseFloat(importe_neto) / 100
                            ),
                            'MES Y AÑO': moment(
                                orden.periodo_comision,
                                'DD/MM/AAAA'
                            )
                                .format('MMMM YYYY')
                                .toUpperCase(),
                        });
                        if (
                            orden.titular_detalle.agencia_detalle
                                .numero_subagencia !== 0
                        ) {
                            acumuladoTxt += parseFloat(importe_neto) / 100;
                        }
                        if (registradoDatos.data.length === 0) {
                            let dataMail = {
                                orden_pago: orden.id,
                                concepto: 'Generacion TXT Ahorro',
                                fecha_envio: moment().format('YYYY-MM-DD'),
                                periodo: moment(
                                    orden.periodo_comision,
                                    'DD/MM/YYYY'
                                ).format('YYYY-MM-DD'),
                            };
                            await ConsultasAPI.CrearObjeto(
                                URL_REGISTROMAIL,
                                dataMail
                            ).catch((response) => {
                                console.info(response)
                                ordenes.pop();
                                filasNota.pop();
                            });
                        }
                    }
                }
            }
        });



        await Promise.all(promises).then(async () => {
            if (filasNota.length > 0 && ordenes.length > 0) {
                let dataLineaCaja = {
                    first_name: '-',
                    last_name: '-',
                    dni: '-',
                    external_id: moment().format('DDMMYYYY'),
                    importe: acumuladoTxt.toFixed(2),
                };
                const lineaCajaDato = await ConsultasAPI.CrearObjeto(
                    URL_ORDENPAGO + 'EnvioLineaCajaTxt/',
                    dataLineaCaja
                );
                const datatxt = ordenes.join('\n') + '\n';
                const blob = new Blob([datatxt], { type: 'text/plain' });
                saveAs(blob, 'OrdenesPago-Ahorro.txt');
                notaPDF(filasNota, sumaTotal);
            } else {
                Swal.fire({
                    title: 'Todas las ordenes de pago fueron registradas previamente',
                    icon: 'error',
                });
            }
            setIsLoadingView(false);
        });
    };

    const notaPDF = (dataPdf, importeTotal) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const img = new Image();
        img.src = require('../../../img/logo-quiniela.png');

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const dataURL = canvas.toDataURL('image/png');
            const fechaFactura = moment().format('dddd DD [de] MMMM YYYY');

            const documentDefinition = {
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 5, 0, 5],
                    },
                    text: {
                        fontSize: 12,
                        bold: false,
                        // margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                    },
                    tableExample2: {
                        margin: [92, 0, 0, 0],
                        alignment: 'center',
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black',
                        fillColor: 'grey',
                    },
                },
                pageMargins: [40, 60, 40, 60],
                images: {
                    sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                },
                defaultStyle: {
                    // alignment: 'justify'
                },
            };
            var body = dataPdf.map((data, index) => {
                return [
                    {
                        text: (index + 1).toString(), // Número de fila
                        style: 'tableHeader',
                        fontSize: 10,
                        alignment: 'center',
                    },
                    ...Object.values(data),
                ];
            });
            documentDefinition.content.push(
                {
                    image: dataURL, // Reemplaza con el dataURL de tu imagen
                    absolutePosition: { x: 40, y: 40 },
                    width: 150.6, // Cambia el ancho de la imagen
                    height: 88.8, // Cambia la altura de la imagen
                },
                {
                    text: 'Gerencia de Quiniela, ' + fechaFactura,
                    style: 'subheader',
                    fontSize: 12,
                    alignment: 'right',
                },
                {
                    alignment: 'left',
                    marginTop: 80,
                    columns: [
                        {
                            text: 'Señora Jefa',
                        },
                    ],
                },
                {
                    alignment: 'left',
                    columns: [
                        {
                            text: 'Oficina de Ahorro Común:',
                        },
                    ],
                },
                {
                    alignment: 'right',
                    marginTop: 10,
                    columns: [
                        {
                            text: 'Por la presente, se solicita a Ud. efectuar los siguientes depósitos correspondientes al pago de Comisiones de Agencias y Sub agencias, detallados a continuacíon:',
                        },
                    ],
                },
                {
                    style: 'tableExample',
                    alignment: 'center',
                    marginTop: 40,
                    fontSize: 10,
                    table: {
                        widths: [
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                        ],
                        body: [
                            [
                                {
                                    text: 'N°',
                                    style: 'tableHeader',
                                    fontSize: 10,
                                    alignment: 'center',
                                },
                                {
                                    text: 'CUENTA',
                                    style: 'tableHeader',
                                    fontSize: 10,
                                    alignment: 'center',
                                },
                                {
                                    text: 'AGENCIA',
                                    style: 'tableHeader',
                                    fontSize: 10,
                                    alignment: 'center',
                                },
                                {
                                    text: 'APELLIDO Y NOMBRE',
                                    style: 'tableHeader',
                                    fontSize: 10,
                                    alignment: 'center',
                                },
                                {
                                    text: 'DNI',
                                    style: 'tableHeader',
                                    fontSize: 10,
                                    alignment: 'center',
                                },
                                {
                                    text: 'IMPORTE',
                                    style: 'tableHeader',
                                    fontSize: 10,
                                    alignment: 'center',
                                },
                                {
                                    text: 'MES Y AÑO',
                                    style: 'tableHeader',
                                    fontSize: 10,
                                    alignment: 'center',
                                },
                            ],
                            ...body,
                            [
                                {
                                    text: 'TOTAL',
                                    style: 'tableHeader',
                                    colSpan: 4,
                                    alignment: 'center',
                                },
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: Parser.currency(importeTotal),
                                    style: 'tableHeader',
                                    colSpan: 2,
                                    alignment: 'center',
                                },
                                {},
                            ],
                        ],
                    },
                },
                {
                    alignment: 'center',
                    columns: [
                        {
                            text: 'Atentamente.',
                        },
                    ],
                }
            );
            pdfMake
                .createPdf(documentDefinition)
                .download('NotaOrdenesDePago.pdf');
        };
    };

    function generarPDFs(dataImpresion) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const img = new Image();
        img.src = require('../../../img/logo.png'); // Utiliza la ruta de importación directa

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const dataURL = canvas.toDataURL('image/png');
            const fechaFacturacion = moment().format('DD/MM/YYYY');

            const documentDefinition = {
                // pageOrientation: "landscape",
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 5, 0, 5],
                    },
                    text: {
                        fontSize: 12,
                        bold: false,
                        // margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                    },
                    tableExample2: {
                        margin: [92, 0, 0, 0],
                        alignment: 'center',
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black',
                    },
                },
                pageMargins: [40, 60, 40, 60],
                images: {
                    sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                },
                background: [
                    {
                        absolutePosition: { x: 40, y: 40 }, // Coordenadas x, y para la imagen
                        image: 'sampleImage', // Referencia a la imagen definida en 'images'
                        color: 'white',
                        width: 150.6, // Cambia el ancho de la imagen
                        height: 88.8, // Cambia la altura de la imagen
                    },
                ],
                defaultStyle: {
                    // alignment: 'justify'
                },
            };
            dataImpresion.forEach((data) => {
                var body = data.deducciones.map((data) => Object.values(data));
                var body2 = data.dateRed.map((data) => Object.values(data));
                var bodys = [];
                var sumaImporte = 0;

                documentDefinition.content.push(
                    {
                        text: 'Orden De Pago',
                        style: 'subheader',
                        alignment: 'center',
                    },
                    {
                        text: 'Orden N°' + data.numero_orden,
                        style: 'subheader',
                        alignment: 'right',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            headerRows: 1,
                            widths: [530],
                            body: [[{ text: '', style: 'tableHeader' }], ['']],
                        },
                        layout: 'headerLineOnly',
                    },

                    {
                        alignment: 'center',
                        columns: [
                            {
                                text: '\nFecha de Emision: ' + fechaFacturacion,
                            },
                            {
                                text: '\nProveedor: ' + data.titular,
                            },
                        ],
                    },
                    {
                        alignment: 'center',
                        columns: [
                            {
                                text: '\nFecha de Pago: ' + fechaFacturacion,
                            },
                            {
                                text: '\nN° de Agencia: ' + data.codigo,
                            },
                        ],
                    },
                    {
                        alignment: 'center',
                        columns: [
                            {
                                text: '\nConcepto: Pago de Comisiones',
                            },
                            {
                                text: '\nCUIT Proveedor: ' + data.cuit,
                            },
                        ],
                    },

                    {
                        text: '\nComprobante de origen',
                        style: 'subheader',
                        alignment: 'left',
                    },

                    {
                        style: 'tableExample',
                        alignment: 'center',
                        table: {
                            widths: [100, 100, 100, 100, 100],
                            body: [
                                [
                                    {
                                        text: 'Fecha de Factura',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                    {
                                        text: 'Nro Factura',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                    {
                                        text: 'Tipo  Factura',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                    {
                                        text: 'Comision',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                    {
                                        text: 'Importe',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                ],
                                [
                                    data.factura.datos_json.fechaFactura,
                                    data.factura.datos_json.nroFactura,
                                    data.factura.datos_json.tipoFactura,
                                    moment(data.factura.periodo, 'DD/MM/YYYY')
                                        .format('MMMM YYYY')
                                        .charAt(0)
                                        .toUpperCase() +
                                    moment(
                                        data.factura.periodo,
                                        'DD/MM/YYYY'
                                    )
                                        .format('MMMM YYYY')
                                        .slice(1),
                                    data.comisionBruta.toLocaleString('es-AR', {
                                        style: 'currency',
                                        currency: 'ARS',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }),
                                ],
                            ],
                        },
                    },

                    {
                        headerRows: 1,
                        style: 'tableExample',
                        alignment: 'center',
                        table: {
                            widths: [171, 171, 171],
                            body: [
                                [
                                    {
                                        text: 'Detalle Deducciones',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                    {
                                        text: 'Nro Cuota',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                    {
                                        text: 'Importe',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                ],
                                ...body,
                            ],
                        },
                    },
                    {
                        headerRows: 1,
                        style: 'tableExample2',
                        alignment: 'center',
                        table: {
                            widths: [171, 171, 171],
                            body: [
                                [
                                    {
                                        text: 'Detalle Retencion',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                    {
                                        text: 'Importe',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                ],
                                ...body2,
                            ],
                        },
                    },

                    {
                        text:
                            '\nSubtotal:' +
                            data.subTotal.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                        style: 'subheader',
                        alignment: 'right',
                    },

                    {
                        text:
                            '\nImporte Neto:' +
                            (data.comisionBruta - data.subTotal).toLocaleString(
                                'es-AR',
                                {
                                    style: 'currency',
                                    currency: 'ARS',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }
                            ),
                        style: 'subheader',
                        alignment: 'left',
                    },

                    {
                        style: 'tableExample',
                        alignment: 'center',

                        table: {
                            widths: [250, 250],
                            headerRows: 2,
                            body: [
                                [
                                    {
                                        text: 'Medio de Pago',
                                        style: 'tableHeader',
                                        colSpan: 2,
                                        alignment: 'center',
                                    },
                                    {},
                                ],
                                [
                                    {
                                        text: 'Nro de Cheque',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                    {
                                        text: 'Nro de Cuenta',
                                        style: 'tableHeader',
                                        alignment: 'center',
                                    },
                                ],
                                [' ', ' '],
                            ],
                        },
                    },
                    {
                        text: '\nAutorizado Por:                                                           Firma:',
                        style: 'text',
                        alignment: 'left',
                    },
                    { text: '', pageBreak: 'after' } // Salto de página
                );
            });
            setIsLoadingView(false);
            pdfMake.createPdf(documentDefinition).download('OrdenesDePago.pdf');
        };
    }

    const handleSubmitTxtQuinielaTuqui = async (event, sistema) => {
        event.preventDefault();
        var nombre_contador;
        const montoPDF = [];
        const ordenes = [];
        let vectorOrdenes = [];
        setIsLoadingView(true);
        var ordenes_pago;

        if (sistema === 'SICORE') {
            let estadoDetalle = estado.detalle ? estado.detalle : null;
            ordenes_pago = await ConsultasAPI.ListarObjetos(
                URL_ORDENPAGO + 'ListSICORETXT/',
                null,
                null,
                null,
                null,
                moment(fechaRentas.fechaComparar + '-01', 'YYYY/MM').format(
                    'YYYY-MM-DD'
                ),
                estadoDetalle,
                null,
                null, //aqui
                null,
                null,
                null,
                estadoComisionF,
                estadoDocumentacionF
            );
        } else {
            let estadoDetalle = estado.detalle ? estado.detalle : null;
            ordenes_pago = await ConsultasAPI.ListarObjetos(
                URL_ORDENPAGO + 'ListPagadasTXT/',
                null,
                null,
                null,
                null,
                moment(fechaRentas.fechaComparar + '-01', 'YYYY/MM').format(
                    'YYYY-MM-DD'
                ),
                estadoDetalle,
                null,
                null, //aqui
                null,
                null,
                null,
                estadoComisionF,
                estadoDocumentacionF
            );
        }

        if (ordenes_pago.status === 204) {
            Notifiaciones.notificacion('No hay resultados para descargar');
            setIsLoadingView(false);
            return;
        }

        vectorOrdenes = ordenes_pago.data;
        const promises = vectorOrdenes.map(async (row) => {
            let orden = row;
            nombre_contador = orden.nombre_contables;
            if (sistema !== 'SICORE') {
                var cuit_agente = '30517999551';
                var cuit_sujeto =
                    orden.titular_detalle.titular_detalle.persona_detalle.cuit
                        .toString()
                        .padStart(11, '0');
                var razon_social = (
                    orden.titular_detalle.titular_detalle.persona_detalle.apellido.toUpperCase() +
                    ' ' +
                    orden.titular_detalle.titular_detalle.persona_detalle.nombre.toUpperCase()
                )
                    .toString()
                    .padEnd(40, ' ');
                var nro_agencia =
                    orden.titular_detalle.agencia_detalle.numero_agencia
                        .toString()
                        .padStart(3, '0') +
                    orden.titular_detalle.agencia_detalle.numero_subagencia
                        .toString()
                        .padStart(3, '0');
                var periodo = moment(
                    fechaRentas.fechaMuestra,
                    'MM/YYYY'
                ).format('YYYYMM');
                var repter = 'R';
                var venta_propia;
                var codigo_juego;
                if (sistema === 'QUINIELA') {
                    codigo_juego = '08';
                    venta_propia = orden.montoVendido
                        .toFixed(2)
                        .toString()
                        .replace('.', '')
                        .padStart(15, '0');
                    montoPDF.push(orden.montoVendido.toFixed(2));
                }
                if (sistema === 'TUQUI') {
                    codigo_juego = '12';
                    venta_propia = orden.monto_vendido_tuqui
                        .toFixed(2)
                        .toString()
                        .replace('.', '')
                        .padStart(15, '0');
                    montoPDF.push(orden.monto_vendido_tuqui.toFixed(2));
                }

                var comision;
                var valor;
                if (
                    orden.titular_detalle.agencia_detalle.numero_subagencia ===
                    0
                ) {
                    comision = '0400';
                    valor = 0.04
                } else {
                    comision = '1200';
                    valor = 0.12
                }
                var base_calculo = (parseFloat(venta_propia) * valor)
                    .toFixed(2)
                    .toString()
                    .replace('.', '')
                    .padStart(15, '0');

                var venta_sucursal = '000000000000000';
                var alicuota = '0650';
                var monto_iib;
                if (sistema === 'QUINIELA') {
                    monto_iib = orden.retencion.retencion_iibb_quiniela
                        .toFixed(2)
                        .toString()
                        .replace('.', '')
                        .padStart(15, '0');
                }
                if (sistema === 'TUQUI') {
                    monto_iib = orden.retencion.retencion_iibb_tuqui
                        .toFixed(2)
                        .toString()
                        .replace('.', '')
                        .padStart(15, '0');
                }

                ordenes.push(
                    cuit_agente +
                    cuit_sujeto +
                    razon_social +
                    nro_agencia +
                    codigo_juego +
                    periodo +
                    repter +
                    venta_propia +
                    comision +
                    base_calculo +
                    venta_sucursal +
                    alicuota +
                    monto_iib
                );
            } else {
                var codigo_comprobante = '01';
                var fecha_factura =
                    orden.archivo_documentacion.datos_json.fechaFactura;
                var nro_factura =
                    orden.archivo_documentacion.datos_json.nroFactura;
                nro_factura = nro_factura.replace(/-/g, '');
                nro_factura = nro_factura.toString().padStart(16, '0');
                var comision =
                    parseFloat(
                        orden.archivo_documentacion.datos_json
                            .importeFacturacionQuiniela
                    ) +
                    parseFloat(
                        orden.archivo_documentacion.datos_json
                            .importeFacturacionTuqui
                    );
                comision = comision
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')
                    .padStart(16, '0');

                var codigo_regimen;
                var codigo_impuesto;
                if (
                    orden.archivo_documentacion.condicion ===
                    'MONOTRIBUTISTA EXCLUIDO'
                ) {
                    codigo_impuesto = '0767';
                    codigo_regimen = '777';
                } else {
                    codigo_impuesto = '0217';
                    codigo_regimen =
                        orden.archivo_documentacion.codigo_ganancias;
                }

                var codigo_condicion;
                if (
                    orden.archivo_documentacion.condicion ===
                    'MONOTRIBUTISTA EXCLUIDO' ||
                    (orden.archivo_documentacion.condicion ===
                        'INSCRIPTO EN GANANCIAS' &&
                        orden.archivo_documentacion.tipoFactura ===
                        'A sujeta a retención')
                ) {
                    codigo_condicion = '00';
                }

                if (
                    orden.archivo_documentacion.condicion === 'NO ACTIVO' ||
                    (orden.archivo_documentacion.condicion ===
                        'INSCRIPTO EN GANANCIAS' &&
                        (orden.archivo_documentacion.tipoFactura === 'A' ||
                            orden.archivo_documentacion.tipoFactura === 'C' ||
                            orden.archivo_documentacion.tipoFactura === 'M'))
                ) {
                    codigo_condicion = '01';
                }

                var base_calculo = parseFloat(comision) - parseFloat(67170);
                base_calculo = base_calculo
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')
                    .padStart(14, '0');
                var fecha_liq = orden.retencion.fecha_creacion;
                fecha_liq = fecha_liq.split(' ');
                fecha_liq = fecha_liq[0];
                var importe_retencion =
                    parseFloat(orden.retencion.retencion_ganancias) +
                    parseFloat(orden.retencion.retencion_iibb_quiniela) +
                    parseFloat(
                        orden.retencion.retencion_iibb_tuqui +
                        orden.retencion.retencion_iva
                    ) +
                    parseFloat(orden.retencion.retencion_municipal);
                importe_retencion = importe_retencion
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')
                    .padStart(14, '0');
                var cuit_sujeto =
                    orden.titular_detalle.titular_detalle.persona_detalle.cuit
                        .toString()
                        .padStart(20, '0');

                montoPDF.push(
                    orden.montoVendido.toFixed(2) +
                    orden.monto_vendido_tuqui.toFixed(2)
                );
                ordenes.push(
                    codigo_comprobante +
                    fecha_factura +
                    nro_factura +
                    comision +
                    codigo_impuesto +
                    codigo_regimen +
                    '1' +
                    base_calculo +
                    fecha_liq +
                    codigo_condicion +
                    '0' +
                    importe_retencion +
                    '0' +
                    '          ' +
                    '80' +
                    cuit_sujeto +
                    '0'
                );
            }
        });

        await Promise.all(promises).then(async () => {
            if (ordenes.length > 0) {
                const datatxt = ordenes.join('\n') + '\n';
                const blob = new Blob([datatxt], { type: 'text/plain' });
                var nombre;
                if (sistema === 'QUINIELA') {
                    nombre = 'Rentas-Quiniela.txt';
                }
                if (sistema === 'TUQUI') {
                    nombre = 'Rentas-Tuqui.txt';
                }
                if (sistema === 'SICORE') {
                    nombre = 'SICORE.txt';
                }
                saveAs(blob, nombre);
                const sumaTotal2 = montoPDF.reduce(
                    (total, current) => total + current,
                    0
                );
                const sumaFormateada = parseFloat(sumaTotal2).toFixed(2);
                if (sistema === 'QUINIELA') {
                    RentasQuinielanotaPDF(
                        formatNumber(sumaFormateada),
                        nombre_contador
                    );
                }
                if (sistema === 'TUQUI') {
                    RentasTuquinotaPDF(
                        formatNumber(sumaFormateada),
                        nombre_contador
                    );
                }
                if (sistema === 'SICORE') {
                    SICOREnotaPDF(
                        formatNumber(sumaFormateada),
                        nombre_contador
                    );
                }

                Notifiaciones.notificacion('Se han descargado los archivos');
                handleCloseModalTxtQuinielaTuqui();
            } else {
                Swal.fire({
                    title: 'Todas las ordenes de pago fueron registradas previamente',
                    icon: 'error',
                });
            }
            setIsLoadingView(false);
        });
    };

    function formatNumber(number) {
        const parts = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        const lastDotIndex = parts.lastIndexOf('.');
        if (lastDotIndex !== -1) {
            const formatted =
                parts.substring(0, lastDotIndex) +
                ',' +
                parts.substring(lastDotIndex + 1);
            return formatted;
        }
        return parts + ',';
    }

    const RentasQuinielanotaPDF = (monto, nombre) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const img = new Image();
        img.src = require('../../../img/logo-quiniela.png');
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            const fechaFactura = moment().format('dddd DD [de] MMMM YYYY');
            const fecha =
                moment().format('MMMM YYYY').charAt(0).toUpperCase() +
                moment().format('MMMM YYYY').slice(1);

            const documentDefinition = {
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 5, 0, 5],
                    },
                    text: {
                        fontSize: 12,
                        bold: false,
                        // margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                    },
                    tableExample2: {
                        margin: [92, 0, 0, 0],
                        alignment: 'center',
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black',
                        fillColor: 'grey',
                    },
                },
                pageMargins: [40, 60, 40, 60],
                images: {
                    sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                },
                defaultStyle: {
                    // alignment: 'justify'
                },
            };

            documentDefinition.content.push(
                {
                    image: dataURL, // Reemplaza con el dataURL de tu imagen
                    absolutePosition: { x: 40, y: 40 },
                    width: 150.6, // Cambia el ancho de la imagen
                    height: 88.8, // Cambia la altura de la imagen
                },
                {
                    text: 'Contaduría de Quiniela, ' + fechaFactura,
                    style: 'subheader',
                    fontSize: 12,
                    alignment: 'right',
                },

                {
                    alignment: 'left',
                    marginTop: 60,
                    columns: [
                        {
                            text: 'Oficina de Impuestos',
                        },
                    ],
                }
            );

            if (nombre.length !== 0) {
                documentDefinition.content.push({
                    alignment: 'left',
                    marginTop: 20,
                    columns: [
                        {
                            text: `${nombre}`,
                        },
                    ],
                });
            }

            documentDefinition.content.push(
                {
                    alignment: 'justify',
                    marginTop: 30,
                    stack: [
                        {
                            marginLeft: 125,
                            alignment: 'justify',
                            text: `Por medio de la presente, se remite a Ud. archivo en formato TXT de `,
                        },

                        {
                            alignment: 'justify',
                            text: `Ingresos Brutos correspondiente a las liquidaciones de comisiones de Quiniela efectuadas en
                            el mes de ${fecha}, cuyo monto asciende a $${monto}`,
                        },
                    ],
                },

                {
                    alignment: 'center',
                    marginTop: 10,
                    columns: [
                        {
                            text: 'Atentamente.',
                        },
                    ],
                }
            );
            pdfMake
                .createPdf(documentDefinition)
                .download('NotaRentasQuiniela.pdf');
        };
    };

    const RentasTuquinotaPDF = (monto, nombre) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const img = new Image();
        img.src = require('../../../img/logo-quiniela.png');
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const dataURL = canvas.toDataURL('image/png');
            const fechaFactura = moment().format('dddd DD [de] MMMM YYYY');
            const fecha =
                moment().format('MMMM YYYY').charAt(0).toUpperCase() +
                moment().format('MMMM YYYY').slice(1);
            const documentDefinition = {
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 5, 0, 5],
                    },
                    text: {
                        fontSize: 12,
                        bold: false,
                        // margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                    },
                    tableExample2: {
                        margin: [92, 0, 0, 0],
                        alignment: 'center',
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black',
                        fillColor: 'grey',
                    },
                },
                pageMargins: [40, 60, 40, 60],
                images: {
                    sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                },
                defaultStyle: {
                    // alignment: 'justify'
                },
            };

            documentDefinition.content.push(
                {
                    image: dataURL, // Reemplaza con el dataURL de tu imagen
                    absolutePosition: { x: 40, y: 40 },
                    width: 150.6, // Cambia el ancho de la imagen
                    height: 88.8, // Cambia la altura de la imagen
                },
                {
                    text: 'Contaduría de Quiniela, ' + fechaFactura,
                    style: 'subheader',
                    fontSize: 12,
                    alignment: 'right',
                },

                {
                    alignment: 'left',
                    marginTop: 60,
                    columns: [
                        {
                            text: 'Oficina de Impuestos',
                        },
                    ],
                }
            );

            if (nombre.length !== 0) {
                documentDefinition.content.push({
                    alignment: 'left',
                    marginTop: 20,
                    columns: [
                        {
                            text: `${nombre}`,
                        },
                    ],
                });
            }
            documentDefinition.content.push(
                {
                    alignment: 'justify',
                    marginTop: 30,
                    stack: [
                        {
                            marginLeft: 125,
                            alignment: 'justify',
                            text: `Por medio de la presente, se remite a Ud. archivo en formato TXT de `,
                        },

                        {
                            alignment: 'justify',
                            text: `Ingresos Brutos retenidos en el mes de ${fecha}, correspondiente al juego TUQUI 10 cuyo monto asciende a $${monto}`,
                        },
                    ],
                },

                {
                    alignment: 'center',
                    marginTop: 10,
                    columns: [
                        {
                            text: 'Atentamente.',
                        },
                    ],
                }
            );
            pdfMake
                .createPdf(documentDefinition)
                .download('NotaRentasTuqui.pdf');
        };
    };

    const SICOREnotaPDF = (monto, nombre) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const img = new Image();
        img.src = require('../../../img/logo-quiniela.png');
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const dataURL = canvas.toDataURL('image/png');
            const fechaFactura = moment().format('dddd DD [de] MMMM YYYY');
            const fecha =
                moment().format('MMMM YYYY').charAt(0).toUpperCase() +
                moment().format('MMMM YYYY').slice(1);
            const documentDefinition = {
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 5, 0, 5],
                    },
                    text: {
                        fontSize: 12,
                        bold: false,
                        // margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                    },
                    tableExample2: {
                        margin: [92, 0, 0, 0],
                        alignment: 'center',
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black',
                        fillColor: 'grey',
                    },
                },
                pageMargins: [40, 60, 40, 60],
                images: {
                    sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                },
                defaultStyle: {
                    // alignment: 'justify'
                },
            };

            documentDefinition.content.push(
                {
                    image: dataURL, // Reemplaza con el dataURL de tu imagen
                    absolutePosition: { x: 40, y: 40 },
                    width: 150.6, // Cambia el ancho de la imagen
                    height: 88.8, // Cambia la altura de la imagen
                },
                {
                    text: 'Contaduría de Quiniela, ' + fechaFactura,
                    style: 'subheader',
                    fontSize: 12,
                    alignment: 'right',
                },

                {
                    alignment: 'left',
                    marginTop: 60,
                    columns: [
                        {
                            text: 'Oficina de Impuestos',
                        },
                    ],
                }
            );

            if (nombre.length !== 0) {
                documentDefinition.content.push({
                    alignment: 'left',
                    marginTop: 20,
                    columns: [
                        {
                            text: `${nombre}`,
                        },
                    ],
                });
            }

            documentDefinition.content.push(
                {
                    alignment: 'justify',
                    marginTop: 30,
                    stack: [
                        {
                            marginLeft: 125,
                            alignment: 'justify',
                            text: `Por medio de la presente, se remite a Ud. archivo en formato TXT de `,
                        },

                        {
                            alignment: 'justify',
                            text: `Ganancia e IVA para SICORE, correspondientea las liquidaciones efectuadas en el mes de ${fecha}, ascendiendo el monto total a $${monto}`,
                        },
                    ],
                },

                {
                    alignment: 'center',
                    marginTop: 10,
                    columns: [
                        {
                            text: 'Atentamente.',
                        },
                    ],
                }
            );
            pdfMake.createPdf(documentDefinition).download('NotaSICORE.pdf');
        };
    };

    const handleCloseModalTxtQuinielaTuqui = async () => {
        setTipoSucursal('');
        fechaRentasRef.current.setState({ inputValue: '' });
        setFechaRentas(moment());
        setShowModalTxtQuinielaTuqui(false);
    };

    const clearFiltros = () => {
        setEstado({ id: '' });
        setPeriodoComision({ fechaMuestra: null, fechaComparar: null });
        dateTimeRefPeriodo.current.setState({ inputValue: '' });
        fechaSubidaRef.current.setState({ inputValue: '' });
        setFechaSubida({
            fechaMuestra: null,
            fechaComparar: null,
        });
        setEstadoComisionF('');
        setEstadoDocumentacionF('');
        setSucursalRendicion({ id: '' });
    };

    const descargaExcel = async () => {
        //let i = 0;
        const ordenes = [];
        var cantidad = 100;
        var partesADescargar = Math.ceil(count / cantidad);
        var partesDescargadas = 0;
        //var offset = 0;
        const pag = {
            limit: cantidad,
            offset: 0,
        };
        Notificaciones.notificacion('Comenzando Descarga...');
        const fetchNextPage = async () => {
            try {
                setLoadingExcel(true);
                let estadoDetalle = estado.detalle ? estado.detalle : null;
                await ConsultasAPI.CrearObjeto(
                    URL_DEDUCCION + 'crearAutomatizacionCompletas/',
                    1
                );
                const ordenesPago = await ConsultasAPI.ListarObjetos(
                    URL_ORDENPAGO + 'ListExcel/',
                    0,
                    10,
                    columnFilters,
                    periodoComision.fechaComparar
                        ? moment(
                            periodoComision.fechaComparar + '-01',
                            'YYYY/MM'
                        ).format('YYYY-MM-DD')
                        : periodoComision.fechaComparar,
                    fechaSubida.fechaComparar,
                    estadoDetalle,
                    null,
                    SucursalRendicion.id, //aqui
                    null,
                    null,
                    null,
                    estadoComisionF,
                    estadoDocumentacionF,
                    pag.offset,
                    pag.limit
                );
                const promises = ordenesPago.data.map(async (orden) => {
                    var suma_retenciones = orden.retencion
                        ? orden.retencion.retencion_ganancias +
                        orden.retencion.retencion_iva +
                        orden.retencion.retencion_iibb_quiniela +
                        orden.retencion.retencion_iibb_tuqui +
                        orden.retencion.retencion_municipal
                        : 0;
                    var seguro = orden.deducciones_Seguros
                        ? orden.deducciones_Seguros.importe
                        : 0;
                    var Tarjeta_Visa = orden.deducciones_TarjetaVISA
                        ? orden.deducciones_TarjetaVISA.importe
                        : 0;
                    var Tarjeta_CPA = orden.deducciones_TarjetaCABAL
                        ? orden.deducciones_TarjetaCABAL.importe
                        : 0;
                    var EmbargosFijos = orden.deducciones_EmbargosFijos
                        ? orden.deducciones_EmbargosFijos.importe
                        : 0;
                    var EmbargosPorcentuales =
                        orden.deducciones_EmbargosPorcentuales
                            ? orden.deducciones_EmbargosPorcentuales.importe
                            : 0;
                    var Multas = orden.deducciones_Multas
                        ? orden.deducciones_Multas.importe
                        : 0;
                    var PlandePagodeudaAFIP =
                        orden.deducciones_PlandePagodeudaAFIP
                            ? orden.deducciones_PlandePagodeudaAFIP.importe
                            : 0;
                    var Automatizacion = orden.deducciones_Automatizacion
                        ? orden.deducciones_Automatizacion.importe
                        : 0;
                    var Ticket = orden.deducciones_Ticket
                        ? orden.deducciones_Ticket.importe
                        : 0;
                    var Ticket_Subagencias = orden.deducciones_TicketporSubagencias
                        ? orden.deducciones_TicketporSubagencias.importe
                        : 0;
                    var Reposicion_de_Equipo =
                        orden.deducciones_Productodemantenimientodeequipos
                            ? orden.deducciones_Productodemantenimientodeequipos
                                .importe
                            : 0;
                    var Reajuste_Comision = orden.deducciones_ReajusteComision
                        ? orden.deducciones_ReajusteComision.importe
                        : 0;
                    var suma_deducciones =
                        seguro +
                        Tarjeta_Visa +
                        Tarjeta_CPA +
                        EmbargosFijos +
                        Multas +
                        Automatizacion +
                        Ticket +
                        Reposicion_de_Equipo +
                        PlandePagodeudaAFIP +
                        EmbargosPorcentuales +
                        Reajuste_Comision +
                        Ticket_Subagencias;
                    let archivo_afip = orden.archivos.filter(
                        (archivo) =>
                            archivo.tipo === 'Constancia de Inscripción en AFIP'
                    )[0];
                    let dgr = orden.archivos.filter(
                        (archivo) =>
                            archivo.tipo ===
                            'Constancia de Inscripción en Rentas de la Provincia'
                    )[0];
                    let cump_fiscal = orden.archivos.filter(
                        (archivo) =>
                            archivo.tipo ===
                            'Certificado de Cumplimiento Fiscal Vigente'
                    )[0];
                    let tem = orden.archivos.filter(
                        (archivo) =>
                            archivo.tipo ===
                            'Constancia Del Tributo Económico Municipal Actualizada'
                    )[0];
                    let poder_judicial = orden.archivos.filter(
                        (archivo) =>
                            archivo.tipo ===
                            'Certificación de no Estar Registrado Como Deudor Alimenticio'
                    )[0];
                    let ddjj = orden.archivos.filter(
                        (archivo) =>
                            archivo.tipo ===
                            'Declaración Jurada de Liquidación Mensual'
                    )[0];
                    let factura = orden.archivos.filter(
                        (archivo) => archivo.tipo === 'Factura Electronica'
                    )[0];
                    let ultimo_archivo_recibido = orden.archivos.sort((a, b) => {
                        // Parsear las fechas usando moment
                        const fechaA = moment(a.fecha_creacion, "DD/MM/YYYY", true);
                        const fechaB = moment(b.fecha_creacion, "DD/MM/YYYY", true);

                        // Verificar si las fechas son válidas
                        if (!fechaA.isValid()) {
                            return 1; // Colocar fechas inválidas al final
                        }
                        if (!fechaB.isValid()) {
                            return -1; // Colocar fechas inválidas al final
                        }

                        return fechaB.isAfter(fechaA) ? 1 : -1;
                    })[0];
                    let ultimo_archivo_revisado = orden.archivos.sort((a, b) => {
                        // Parsear las fechas usando moment
                        const fechaA = moment(a.fecha_revision, "DD/MM/YYYY", true);
                        const fechaB = moment(b.fecha_revision, "DD/MM/YYYY", true);

                        // Verificar si las fechas son válidas
                        if (!fechaA.isValid()) {
                            return 1; // Colocar fechas inválidas al final
                        }
                        if (!fechaB.isValid()) {
                            return -1; // Colocar fechas inválidas al final
                        }

                        return fechaB.isAfter(fechaA) ? -1 : 1;
                    })[0];
                    let ultimo_archivo_aprobado = orden.archivos.filter(archivo => archivo.estado_documentacion === "Aprobado").sort((a, b) => {
                        // Parsear las fechas usando moment
                        const fechaA = moment(a.fecha_revision, "DD/MM/YYYY", true);
                        const fechaB = moment(b.fecha_revision, "DD/MM/YYYY", true);

                        // Verificar si las fechas son válidas
                        if (!fechaA.isValid()) {
                            return 1; // Colocar fechas inválidas al final
                        }
                        if (!fechaB.isValid()) {
                            return -1; // Colocar fechas inválidas al final
                        }

                        return fechaB.isAfter(fechaA) ? 1 : -1;
                    })[0];
                    let o = {
                        'FECHA DE RECEPCION': ultimo_archivo_recibido
                            ? ultimo_archivo_recibido.fecha_creacion
                            : '-',
                        'N° AGENCIA': concatCodigo(
                            orden.titular_detalle.agencia_detalle
                                .numero_agencia,
                            orden.titular_detalle.agencia_detalle
                                .numero_subagencia
                        ),
                        TITULAR:
                            orden.titular_detalle.titular_detalle
                                .persona_detalle.apellido +
                            ', ' +
                            orden.titular_detalle.titular_detalle
                                .persona_detalle.nombre,
                        CUIT: orden.titular_detalle.titular_detalle
                            .persona_detalle.cuit,
                        'MES DE COMISION': orden.periodo_comision
                            ? moment(
                                orden.periodo_comision,
                                'DD/MM/YYYY'
                            ).format('MMMM YYYY')
                            : '-',
                        FECHA_CONTROL: ultimo_archivo_revisado
                            ? ultimo_archivo_revisado.fecha_revision
                            : '-', // fecha de revision ultima osea la mas nueva
                        FECHA_APROBACION: ultimo_archivo_aprobado
                            ? ultimo_archivo_aprobado.fecha_revision
                            : '-', // fecha de revision ultima osea la mas nueva
                        COM_QUINIELA: orden.comisionQ,
                        COM_TUQUI10: orden.comisionT,
                        COM_TOTAL:
                            orden.comisionT && orden.comisionQ
                                ? orden.comisionQ + orden.comisionT
                                : parseFloat(orden.comisionQ),
                        'CONDIC. FISCAL AFIP': orden.condicion_fiscal
                            ? orden.condicion_fiscal.condicion_fiscal
                            : '-',
                        'Nº DE ACTIV AFIP':
                            archivo_afip && archivo_afip.datos_json
                                ? archivo_afip.datos_json.actividad
                                : '-',
                        CONSTANCIA_INSCRIPCION: dgr
                            ? dgr.estado_documentacion === 'Aprobado'
                                ? 'INSCRIPTO'
                                : 'NO INSCRIPTO'
                            : '-',
                        'Nº DE ACTIVID. DGR':
                            dgr && dgr.datos_json
                                ? dgr.datos_json.actividad
                                : '-',
                        CUMPLIMIENTO_FISCAL: cump_fiscal
                            ? cump_fiscal.estado_documentacion === 'Aprobado'
                                ? 'SI'
                                : 'NO'
                            : '-',
                        FECHA_VTO: cump_fiscal
                            ? cump_fiscal.fecha_vencimiento
                            : '-',
                        'CONST. INSC. TEM': tem
                            ? tem.estado_documentacion === 'Aprobado'
                                ? 'INSCRIPTO'
                                : 'NO INSCRIPTO'
                            : '-',
                        'COMUNA/MUNICIPIO': orden.domicilio
                            ? orden.domicilio.localidad_detalle.nombre
                            : '-',
                        FECHA_CONST:
                            tem && tem.datos_json
                                ? tem.datos_json.fechaConstancia
                                : '-',
                        'CONTROL CERTIFICADO DA': poder_judicial
                            ? poder_judicial.estado_documentacion === 'Aprobado'
                                ? 'SI'
                                : 'NO'
                            : '-',
                        'DDJJ DEL AGENCIERO': ddjj
                            ? ddjj.estado_documentacion === 'Aprobado'
                                ? 'COINCIDE CON FACTURA Y COMISION'
                                : '-'
                            : '-',
                        RECHAZADA: ddjj
                            ? ddjj.estado_documentacion === 'Aprobado'
                                ? 'NO'
                                : 'SI'
                            : '-',
                        MOTIVO: ddjj ? ddjj.comentario : '-',
                        FECHA_RECHAZO: ddjj ? ddjj.fecha_revision : '-',
                        AUDITOR: orden.archivos.filter(
                            (x) => x.revisado_por !== null
                        )[0]
                            ? orden.archivos.filter(
                                (x) => x.revisado_por !== null
                            )[0].revisado_por
                            : '-',
                        FECHA_LIQ: orden.fecha_facturacion
                            ? orden.fecha_facturacion
                            : '-',
                        BASE_IMPONIBLE:
                            orden.comisionT && orden.comisionQ ?
                                orden.comisionQ + orden.comisionT
                                :
                                orden.comisionQ ?
                                    orden.comisionQ
                                    :
                                    orden.comisionT ?
                                        orden.comisionT
                                        :
                                        '-',
                        Ret_Ganancias_def: orden.retencion
                            ? orden.retencion.retencion_ganancias
                            : '-',
                        Ret_IVA: orden.retencion
                            ? orden.retencion.retencion_iva
                            : '-',
                        Ret_Ing_Bruto_Q: orden.retencion
                            ? orden.retencion.retencion_iibb_quiniela
                            : '-',
                        Ret_Ing_Bruto_T: orden.retencion
                            ? orden.retencion.retencion_iibb_tuqui
                            : '-',
                        Ret_Munic: orden.retencion
                            ? orden.retencion.retencion_municipal
                            : '-',
                        Neto_de_Retencion:
                            orden.comisionT && orden.comisionQ
                                ? orden.comisionQ +
                                orden.comisionT -
                                suma_retenciones
                                : '-',
                        Cond_Fiscal: orden.condicion_fiscal
                            ? orden.condicion_fiscal.condicion_fiscal
                            : '-',
                        EXCLUIDO: '0',
                        Creditos: '-',
                        Seguro_Vida: orden.deducciones_Seguros
                            ? orden.deducciones_Seguros.importe
                            : '-',
                        Tarjeta_Visa: orden.deducciones_TarjetaVISA
                            ? orden.deducciones_TarjetaVISA.importe
                            : '-',
                        Tarjeta_CPA: orden.deducciones_TarjetaCABAL
                            ? orden.deducciones_TarjetaCABAL.importe
                            : '-',
                        EmbargosFijos: orden.deducciones_EmbargosFijos
                            ? orden.deducciones_EmbargosFijos.importe
                            : '-',
                        EmbargosPorcentuales:
                            orden.deducciones_EmbargosPorcentuales
                                ? orden.deducciones_EmbargosPorcentuales.importe
                                : '-',
                        Multas: orden.deducciones_Multas
                            ? orden.deducciones_Multas.importe
                            : '-',
                        Automatizacion: orden.deducciones_Automatizacion
                            ? orden.deducciones_Automatizacion.importe
                            : '-',
                        Ticket: orden.deducciones_Ticket
                            ? orden.deducciones_Ticket.importe
                            : '-',
                        'Ticket por Subagencias': orden.deducciones_TicketporSubagencias
                            ? orden.deducciones_TicketporSubagencias.importe
                            : '-',
                        Reposicion_de_Equipo:
                            orden.deducciones_Productodemantenimientodeequipos
                                ? orden
                                    .deducciones_Productodemantenimientodeequipos
                                    .importe
                                : '-',
                        Ajuste: orden.deducciones_ReajusteComision
                            ? orden.deducciones_ReajusteComision.importe
                            : 0,
                        Cuota_Plan_de_Pago:
                            orden.deducciones_PlandePagodeudaAFIP
                                ? orden.deducciones_PlandePagodeudaAFIP.importe
                                : '-',
                        NETO_A_PAGAR:
                            orden.comisionT && orden.comisionQ
                                ? orden.comisionQ +
                                orden.comisionT -
                                suma_retenciones -
                                suma_deducciones
                                : orden.comisionQ
                                    ? orden.comisionQ -
                                    suma_retenciones -
                                    suma_deducciones
                                    : '-',
                        // CONTROL_NETO: "",
                        // CONTROL_GASTOS: "",
                        // CONTROL_OP: "",
                        // N_DE_CUENTA: "",
                        // FECHA_PAGO: "",
                        // AHORRO: "",
                        // CONTROL_AHORRO: "",
                        // ENVIO: "",
                        // SUCURSAL: "",
                        N_OP: orden.numero_orden,
                        N_FACTURA:
                            factura && factura.datos_json
                                ? factura.datos_json.nroFactura
                                : '-',
                        Domicilio: orden.domicilio
                            ? orden.domicilio.calle
                            : '-',
                        Tipo_De_Factura:
                            factura && factura.datos_json
                                ? factura.datos_json.tipoFactura
                                : '-',
                        Fecha_Factura:
                            factura && factura.datos_json
                                ? factura.datos_json.fechaFactura
                                : '-',
                        Tipo_AGENCIA:
                            orden.titular_detalle.agencia_detalle
                                .numero_subagencia === 0
                                ? 'Agencia'
                                : 'Subagencia',
                        Sucursal_Rendicion:
                            orden.domicilio
                                ? orden.domicilio.tiposucursal_detalle.nombre
                                : '-',
                        // PAGADA: "",
                        // REPETIDA: "",
                        // BASE_Q: orden.comisionQ ? orden.comisionQ : "-",
                        // BASE_T: orden.comisionT ? orden.comisionT : "-",
                        // CONTROL_NETO: "",
                        // OBSERVACION: "",
                        // Fecha_Agencias: "",
                    };
                    ordenes.push(o);
                });
                await Promise.all(promises);

                pag.limit = pag.limit + cantidad;
                pag.offset = pag.offset + cantidad;
                if (pag.offset >= count) {
                    Notificaciones.notificacion('Descarga finalizada');
                    exportToExcel(ordenes);
                } else {
                    partesDescargadas = partesDescargadas + 1;
                    Notificaciones.notificacion(
                        'Descargada parte ' +
                        partesDescargadas +
                        ' de ' +
                        partesADescargar
                    );
                    fetchNextPage();
                }
            } catch (error) {
                setLoadingExcel(false);
                console.info(error);
            }
        };
        fetchNextPage();
    };

    const exportToExcel = (historial) => {
        // Crea una nueva hoja de cálculo de Excel
        const workbook = XLSX.utils.book_new();

        // Convierte los datos JSON en una hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(historial);

        // Agrega la hoja de cálculo al libro de Excel
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ordenes');

        // Genera un archivo de Excel
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Crea un blob a partir del buffer de Excel
        const excelBlob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Guarda el archivo usando file-saver
        const excelFile = URL.createObjectURL(excelBlob);
        saveAs(excelBlob, 'ordenes_pago.xlsx');
        setLoadingExcel(false);
    };

    return (
        <Container fluid className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Ordenes de Pago</h1>
                </Col>
            </Row>
            <Card className="m-3">
                <Card.Body>
                    <Row
                        className="mb-3"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '2%',
                        }}
                    >
                        <Col md={10}>
                            <Row
                                className="mb-3"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '2%',
                                }}
                            >
                                <Form.Group as={Col} md="4">
                                    <CalendarMonthIcon
                                        style={{ marginBottom: '6' }}
                                    />
                                    <Form.Label>
                                        Periodo de recepcion:
                                    </Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat="MM/YYYY"
                                        inputProps={{
                                            placeholder: 'elegir fecha',
                                            readOnly: true,
                                        }}
                                        value={fechaSubida?.fechaMuestra}
                                        ref={fechaSubidaRef}
                                        onChange={(momentDate) => {
                                            const fechaMuestra =
                                                momentDate.format('MM/YYYY');
                                            const fechaComparar =
                                                momentDate.format('YYYY-MM-DD');
                                            setFechaSubida({
                                                fechaMuestra: fechaMuestra,
                                                fechaComparar: fechaComparar,
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <CalendarMonthIcon
                                        style={{ marginBottom: '6' }}
                                    />
                                    <Form.Label>
                                        Periodo de Comision:
                                    </Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat="MM/YYYY"
                                        inputProps={{
                                            placeholder: 'elegir periodo',
                                            readOnly: true,
                                        }}
                                        value={null}
                                        ref={dateTimeRefPeriodo}
                                        onChange={(momentDate) => {
                                            const fechaMuestra =
                                                momentDate.format('MM/YYYY');
                                            const fechaComparar =
                                                momentDate.format('YYYY-MM');
                                            setPeriodoComision({
                                                fechaMuestra: fechaMuestra,
                                                fechaComparar: fechaComparar,
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>
                                        Sucursal de Rendicion:
                                    </Form.Label>
                                    <Form.Select
                                        value={SucursalRendicion.id}
                                        onChange={(event) => {
                                            handleTablaSucursalChange(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <option hidden id="">
                                            Elegir Sucursal
                                        </option>
                                        {tablaTipoSucursal.length > 0
                                            ? tablaTipoSucursal.map((tipo) => (
                                                <option
                                                    key={tipo.id}
                                                    value={tipo.id}
                                                >
                                                    {tipo.nombre}
                                                </option>
                                            ))
                                            : null}
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row
                                className="mb-3"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '2%',
                                }}
                            >
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Estado Legajo:</Form.Label>
                                    <Form.Select
                                        value={estado.id}
                                        onChange={(event) => {
                                            handleTablaEstadoChange(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <option hidden id="">
                                            Elegir Estado
                                        </option>
                                        {tablaEstado.length > 0
                                            ? tablaEstado.map((estado) => (
                                                <option
                                                    key={estado.id}
                                                    value={estado.id}
                                                >
                                                    {estado.detalle}
                                                </option>
                                            ))
                                            : null}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Estado de Comision:</Form.Label>
                                    <Form.Select
                                        value={estadoComisionF}
                                        onChange={(event) => {
                                            handleTablaEstadoComisionChange(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <option hidden id="">
                                            Elegir Estado Comision
                                        </option>
                                        {tablaEstadoComision.length > 0
                                            ? tablaEstadoComision.map(
                                                (estado) => (
                                                    <option
                                                        key={estado.id}
                                                        value={estado.detalle}
                                                    >
                                                        {estado.detalle}
                                                    </option>
                                                )
                                            )
                                            : null}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>
                                        Estado de Documentacion:
                                    </Form.Label>
                                    <Form.Select
                                        value={estadoDocumentacionF}
                                        onChange={(event) => {
                                            handleTablaEstadoDocumentacionChange(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <option hidden id="">
                                            Elegir Estado Documentacion
                                        </option>
                                        {tablaDocumentacion.length > 0
                                            ? tablaDocumentacion.map(
                                                (estado) => (
                                                    <option
                                                        key={estado.id}
                                                        value={estado.detalle}
                                                    >
                                                        {estado.detalle}
                                                    </option>
                                                )
                                            )
                                            : null}
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col
                            md={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                className="btn botonCPA me-3"
                                onClick={() => {
                                    clearFiltros();
                                }}
                                style={{
                                    margin: '15px',
                                    width: 'fit-content',
                                    height: 'fit-content',
                                }}
                            >
                                <FaEraser className="iconAntD" />
                                Limpiar Filtro
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={data}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: '0',
                            border: '1px dashed #e0e0e0',
                        },
                    }}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                    theme.palette.background.default,
                                    0.1
                                ),
                            },
                            fontFamily: 'Roboto, sans-serif', // Configuración de la tipografía para las filas pares
                        }),
                    }}
                    initialState={{
                        columnVisibility: { id: false },
                        showColumnFilters: true,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    // enableRowSelection={true} //enable some features
                    enableRowSelection={(row) =>
                        (row.original.estadoDocumentacion === 'Aprobado' &&
                            row.original.legajo === 'Completo') ||
                        row.original.estadoComision === 'Generada'
                    }
                    enableMultiRowSelection={true}
                    onRowSelectionChange={setRowSelection}
                    enableColumnOrdering={false}
                    getRowId={(row) => row.userId} //give each row a more useful id
                    enableHiding={false}
                    enableColumnActions={false}
                    enableSorting={false}
                    displayColumnDefOptions={{
                        'mrt-row-actions': { size: 10 },
                    }} //change width of actions column to 300px
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                        <div className="d-flex">
                            {row.original.estadoDocumentacion !== 'Aprobado' ||
                                row.original.legajo !== 'Completo' ? null : (
                                <IconButton
                                    onClick={() => {
                                        handleEditOrdenPago(row.original);
                                    }}
                                    title="Editar"
                                >
                                    <Preview />
                                </IconButton>
                            )}

                            {row.original.estadoComision === 'Generada' && (AuthenticationHelper.getRol() === "ADMINISTRADOR" || AuthenticationHelper.getRol() === "GERENCIA-QUINIELA") ? (
                                <IconButton
                                    onClick={() => {
                                        setShowModalRazonCambioEstadoComision(true)
                                        setCambioEstadoComision(row.original)
                                    }}
                                    title="Cambiar estado"
                                >
                                    <RestoreIcon />
                                </IconButton>
                            ) : null}
                            {/* <IconButton
                                onClick={() => {
                                    handleViewOrdenPago(row.original)
                                }}
                            >
                                <Visibility />
                            </IconButton> */}
                        </div>
                    )}
                    manualPagination
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    localization={MRT_Localization_ES}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                    manualFiltering //turn off client-side filtering
                    onColumnFiltersChange={(value) => {
                        setColumnFilters(value);
                    }} //hoist internal columnFilters state to your state
                    state={{
                        columnFilters,
                        pagination,
                        rowSelection,
                    }}
                />
                <div className="d-flex justify-content-end">
                    <BtnVolver
                        route={'/deducciones'}
                        style={{
                            width: 'fit-content',
                            height: 'fit-content',
                            marginTop: '17px',
                        }}
                    />

                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn botonCPA m-3"
                            id="dropdown-basic"
                            variant="success"
                        >
                            Acciones
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {AuthenticationHelper.getRol() !== 'AUDITORIA' ? (
                                <>
                                    <Dropdown.Item onClick={imprimirOrdenes}>
                                        Generar e imprimir
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={pagarOrdenes}>
                                        Pagar
                                    </Dropdown.Item>
                                </>
                            ) : null}
                            <Dropdown.Item onClick={descargaExcel}>
                                Descargar Excel
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={(event) => {
                                    setShowModalTxt(true);
                                }}
                            >
                                Ahorro txt
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={(event) => {
                                    setShowModalTxtQuinielaTuqui(true);
                                    setSistemaQuinielaTuqui('QUINIELA');
                                }}
                            >
                                Rentas Quiniela txt
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={(event) => {
                                    setShowModalTxtQuinielaTuqui(true);
                                    setSistemaQuinielaTuqui('TUQUI');
                                }}
                            >
                                Rentas Tuqui txt
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={(event) => {
                                    setShowModalTxtQuinielaTuqui(true);
                                    setSistemaQuinielaTuqui('SICORE');
                                }}
                            >
                                SICORE txt
                            </Dropdown.Item>

                            {rolUser === 'ADMINISTRADOR' ||
                                rolUser === 'GERENCIA-QUINIELA' ||
                                rolUser === 'CONTADURIA' ? (
                                <Dropdown.Item
                                    onClick={(event) => {
                                        setShowModalRetencion(true);
                                    }}
                                >
                                    Recalcular Retenciones
                                </Dropdown.Item>
                            ) : null}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Card>
            <Modal
                show={showModalDetalle}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>
                        {tituloModal} Detalle de Orden de Pago
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Agencia</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={agencia}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Subagencia</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={subagencia}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Titular</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={titular}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>DNI</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={dni}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Cuit</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={cuit}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Periodo de Comision</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={periodoComisionV}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Legajo</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={legajo}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Estado de Documentacion</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={estadoDocumentacion}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Jurisdiccion</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={jurisdiccion}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Zona de Explotacion</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={zonaExplotacion}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Condicion Fiscal</Form.Label>
                                <Form.Control
                                    required
                                    value={condicionFiscal.condicion}
                                    readOnly={true}
                                    onChange={(event) => {
                                        handleTablaCondicionChange(
                                            event.target.value
                                        );
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Comision Bruta Total</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={Parser.currency(comisionBruta)}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Comision Quiniela</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={Parser.currency(comisionQuiniela)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Comision Tuqui</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={Parser.currency(comisionTuqui)}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Importe Neto</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    type="text"
                                    value={Parser.currency(importeNeto)}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <h5>Deducciones</h5>
                            </Form.Group>
                        </Row>
                        <MaterialReactTable
                            className="w-100"
                            columns={columns1}
                            data={dataDed}
                            muiTablePaperProps={{
                                elevation: 0,
                                sx: {
                                    borderRadius: '0',
                                    border: '1px dashed #e0e0e0',
                                },
                            }}
                            muiTableBodyProps={{
                                sx: (theme) => ({
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: darken(
                                            theme.palette.background.default,
                                            0.1
                                        ),
                                    },
                                    fontFamily: 'Roboto, sans-serif', // Configuración de la tipografía para las filas pares
                                }),
                            }}
                            initialState={{
                                columnVisibility: { id: false },
                                showColumnFilters: true,
                            }} //hide firstName column by default
                            editingMode="modal" //default
                            enableRowSelection={false} //enable some features
                            enableColumnOrdering={false}
                            enableHiding={false}
                            enableColumnActions={false}
                            enableSorting={false}
                            displayColumnDefOptions={{
                                'mrt-row-actions': { size: 10 },
                            }} //change width of actions column to 300px
                            manualPagination
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [10],
                            }}
                            enablePagination={true}
                            localization={MRT_Localization_ES}
                            rowCount={count}
                            onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                            enableGlobalFilter={false} //turn off a feature
                            manualFiltering //turn off client-side filtering
                            onColumnFiltersChange={(value) => {
                                setColumnFilters(value);
                            }} //hoist internal columnFilters state to your state
                            state={{
                                columnFilters,
                                pagination,
                            }}
                        />
                        <Row
                            className="mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <Form.Group as={Col} md="6">
                                <h5>Retenciones</h5>
                            </Form.Group>
                        </Row>
                        <MaterialReactTable
                            className="w-100"
                            columns={columns2}
                            data={dataRed}
                            muiTablePaperProps={{
                                elevation: 0,
                                sx: {
                                    borderRadius: '0',
                                    border: '1px dashed #e0e0e0',
                                },
                            }}
                            muiTableBodyProps={{
                                sx: (theme) => ({
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: darken(
                                            theme.palette.background.default,
                                            0.1
                                        ),
                                    },
                                    fontFamily: 'Roboto, sans-serif', // Configuración de la tipografía para las filas pares
                                }),
                            }}
                            initialState={{
                                columnVisibility: { id: false },
                                showColumnFilters: true,
                            }} //hide firstName column by default
                            editingMode="modal" //default
                            enableRowSelection={false} //enable some features
                            enableColumnOrdering={false}
                            enableHiding={false}
                            enableColumnActions={false}
                            enableSorting={false}
                            displayColumnDefOptions={{
                                'mrt-row-actions': { size: 10 },
                            }} //change width of actions column to 300px
                            manualPagination
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [10],
                            }}
                            enablePagination={true}
                            localization={MRT_Localization_ES}
                            rowCount={count}
                            onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                            enableGlobalFilter={false} //turn off a feature
                            manualFiltering //turn off client-side filtering
                            onColumnFiltersChange={(value) => {
                                setColumnFilters(value);
                            }} //hoist internal columnFilters state to your state
                            state={{
                                columnFilters,
                                pagination,
                            }}
                        />
                        <GenerarCertificados ordenPagoId={dataOrden.id} />
                    </Modal.Body>
                    <Modal.Footer
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        {tituloModal === 'Editar' ? (
                            <>
                                <button
                                    className="btn botonCPA m-3"
                                    onClick={handleCloseModal}
                                >
                                    Volver
                                </button>
                                {retencion &&
                                    retencion.aprobado &&
                                    AuthenticationHelper.getRol() !==
                                    'AUDITORIA' ? (
                                    <button
                                        className="btn botonCPA m-3"
                                        onClick={handleGenerarImprimir}
                                    >
                                        Generar e imprimir
                                    </button>
                                ) : null}
                            </>
                        ) : (
                            <button
                                className="btn botonCPA m-3"
                                onClick={handleCloseModal}
                            >
                                Volver
                            </button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal centered show={isLoadingView}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    {isLoadingView ? <p>Cargando Datos...</p> : null}
                </Modal.Body>
            </Modal>
            <Modal centered show={loadingExcel}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    {loadingExcel ? <p>Descargando Excel...</p> : null}
                </Modal.Body>
            </Modal>
            <Retenciones
                retenciones={retencion}
                condicionFiscal={condicionFiscal}
                jurisdiccion={localidad}
                dataRetencionAfip={(retencionAfip) =>
                    handleRetencionAfip(retencionAfip)
                }
                dataRetencionJurisdiccion={(retencionJurisdiccion) =>
                    handleRetencionJurisdiccion(retencionJurisdiccion)
                }
                dataRetencionIva={(retencionIva) =>
                    handleRetencionIva(retencionIva)
                }
                tipoFactura={factura}
                comisionBruta={comisionBruta}
            />

            <Modal show={ShowModalTxt} centered>
                <Modal.Header closeButton onClick={handleCloseModalTxt}>
                    <Modal.Title>
                        Generar txt para sistema de Ahorro
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitTxt}>
                    <Modal.Body>
                        <Form.Group as={Col} md="8">
                            <Form.Label>Fecha de deposito:</Form.Label>
                            <Datetime
                                ref={fechaDepositoRef}
                                timeFormat={false}
                                required
                                dateFormat="DD/MM/YYYY"
                                inputProps={{
                                    readOnly: true,
                                    placeholder: moment().format('DD/MM/YYYY'),
                                }}
                                value={fechaDeposito.fechaMuestra}
                                onChange={(momentDate) => {
                                    setFechaDeposito(momentDate);
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="8">
                            <Form.Label>Sucursal de Rendicion:</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                value={tipoSucursal}
                                onChange={(event) => {
                                    setTipoSucursal(event.target.value);
                                }}
                            >
                                {tablaTipoSucursal.length > 0
                                    ? tablaTipoSucursal.map((tipoSucursal) => (
                                        <option
                                            key={tipoSucursal.id}
                                            value={tipoSucursal.nombre}
                                        >
                                            {tipoSucursal.nombre}
                                        </option>
                                    ))
                                    : null}
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn botonCPA m-3"
                            type="submit"
                            style={{ width: 'fit-content', float: 'right' }}
                        >
                            <AiOutlineDownload className="iconAntD" />
                            Generar txt
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={ShowModalTxtQuinielaTuqui} centered>
                <Modal.Header
                    closeButton
                    onClick={handleCloseModalTxtQuinielaTuqui}
                >
                    {sistemaQuinielaTuqui !== 'SICORE' ? (
                        <Modal.Title>
                            Generar txt para rentas - {sistemaQuinielaTuqui}
                        </Modal.Title>
                    ) : (
                        <Modal.Title>Generar txt para SICORE</Modal.Title>
                    )}
                </Modal.Header>
                <Form
                    onSubmit={(event) =>
                        handleSubmitTxtQuinielaTuqui(
                            event,
                            sistemaQuinielaTuqui
                        )
                    }
                >
                    <Modal.Body>
                        <Form.Group as={Col} md="8">
                            <Form.Label>Fecha:</Form.Label>
                            <Datetime
                                ref={fechaRentasRef}
                                timeFormat={false}
                                required
                                dateFormat="MM/YYYY"
                                inputProps={{
                                    readOnly: true,
                                    placeholder: moment().format('MM/YYYY'),
                                }}
                                value={fechaRentas.fechaMuestra}
                                onChange={(momentDate) => {
                                    const fechaMuestra =
                                        momentDate.format('MM/YYYY');
                                    const fechaComparar =
                                        momentDate.format('YYYY-MM');
                                    setFechaRentas({
                                        fechaMuestra: fechaMuestra,
                                        fechaComparar: fechaComparar,
                                    });
                                }}
                            />
                        </Form.Group>
                        {/* <Form.Group as={Col} md="8">
                            <Form.Label>Sucursal de Rendicion:</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                value={tipoSucursal}
                                onChange={(event) => {
                                    setTipoSucursal(event.target.value);
                                }}
                            >
                                {tablaTipoSucursal.length > 0
                                    ? tablaTipoSucursal.map((tipoSucursal) => (
                                          <option
                                              key={tipoSucursal.id}
                                              value={tipoSucursal.nombre}
                                          >
                                              {tipoSucursal.nombre}
                                          </option>
                                      ))
                                    : null}
                            </Form.Control>
                        </Form.Group> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn botonCPA m-3"
                            type="submit"
                            style={{ width: 'fit-content', float: 'right' }}
                        >
                            <AiOutlineDownload className="iconAntD" />
                            Generar txt
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <ModalRetencion
                show={showModalRetencion}
                close={() => {
                    setShowModalRetencion(false);
                }}
            />

            <ModalRazonCambioEstadoComision
                onClose={handleCloseModalRazonCambioEstadoComision}
                show={showModalRazonCambioEstadoComision}
                //titulo={tituloModalRazonCambioEstadoComision}
                orden={cambioEstadoComision}
                close={handleCloseModalRazonCambioEstadoComision}
            />

        </Container>
    );
};
export default OrdenesDePago;
