import { Document } from "@react-pdf/renderer";
import ConsultasAPI from "../../../shared/helpers/consultasAPI.jsx";
import { useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Card, Button } from "react-bootstrap";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import EmailComisiones from "../liquidacion/comisiones/PDF/EmailComisiones.jsx";

const GenerarComisiones = (props) => {
    const URL_JUGADASMES = window.API_ROUTES.JUGADASMES;
    const URL_COMISION = window.API_ROUTES.COMISION;
    const [tablaComisiones, setTablaComisiones] = useState([]);
    const [allowButton, setAllowButton] = useState(false);

    useEffect(() => {
        if (props.agencia && props.periodo) {
            loadComisiones(props.agencia, props.periodo);
        }
    }, [props.agencia, props.periodo]);

    const loadComisiones = async (agencia, periodo) => {
        setAllowButton(false);
        const filters = {
            agencia: agencia.numero_agencia,
            periodo: periodo.format("YYYY-MM-DD HH:mm:ssZ"),
        };
        ConsultasAPI.ListarObjetos(URL_JUGADASMES + "noPag/", null, null, filters).then(async (response) => {
            var datos = response.data;
            const promises = datos.filter((dato) => dato.titularAgencia.titular).map((dato) => {
                return ConsultasAPI.ObtenerObjeto(URL_COMISION + 'obtenerComisionTitular/', dato.titularAgencia.id + ':' + periodo.format('YYYY-MM-DD')).then((responseTuqui) => {
                    var tuqui;
                    if (responseTuqui.data.length === 0) {
                        tuqui = 0;
                    } else {
                        tuqui = responseTuqui.data[0];
                    }
                    return {
                        AGENCIA: 'Ag: ' + dato.titularAgencia.agencia_detalle.numero_agencia + ' - Sub: ' + dato.titularAgencia.agencia_detalle.numero_subagencia,
                        // SUB: dato.titularAgencia.agencia_detalle.numero_subagencia,
                        Titular:
                            dato.titularAgencia.titular_detalle.persona_detalle.apellido + ', ' +
                            dato.titularAgencia.titular_detalle.persona_detalle.nombre,
                        CUIT: dato.titularAgencia.titular_detalle.persona_detalle.cuit.toString(),
                        Venta: dato.montoVendido,
                        "Venta Oficial+Sub":
                            dato.titularAgencia.agencia_detalle.numero_subagencia === 0
                                ? dato.jugadaMesTotal.montoVendido
                                : 0,
                        "Comision 12%": dato.comisionSubagencia,
                        "Comision 4%":
                            dato.titularAgencia.agencia_detalle.numero_subagencia === 0
                                ? dato.comisionAgencia
                                : 0,
                        "Subtotal Quiniela":
                            dato.titularAgencia.agencia_detalle.numero_subagencia === 0
                                ? dato.jugadaMesTotal.comisionBruta
                                : dato.comisionBruta,
                        "Comision 12%  Tuqui": tuqui === 0 ? tuqui : tuqui.comision_venta,
                        "Comision 4%  Tuqui": tuqui === 0 ? tuqui : tuqui.comision_venta_total,
                        "Subtotal Tuqui": tuqui === 0 ? tuqui : tuqui.comision_bruta,
                        "Comision Bruta Total":
                            dato.titularAgencia.agencia_detalle.numero_agencia === 0
                                ? dato.jugadaMesTotal.comisionBruta + tuqui.comision_bruta
                                : dato.comisionBruta + (tuqui === 0 ? tuqui : tuqui.comision_bruta),
                    };
                });
            });
            Promise.all(promises).then((resultados) => {
                setTablaComisiones(resultados);
                setAllowButton(true);
            });
        });
    }



    return (
        <Card className="m-2 p-3 d-flex justify-content-center" id="ComponenteFijo" style={{ width: "97%" }} >
            <Card.Title>Comisiones de Quiniela y Tuqui</Card.Title>
            <div className="justify-content-between" style={{ display: 'flex', paddingBottom: "15px", paddingTop: "10px", flexDirection: 'row' }}>
                <Card.Text style={{ fontSize: "0.75rem", fontWeight: 'bold', width: 'fit-content' }}>PDF con los datos de comisiones de agencia y subagencias</Card.Text>
                {allowButton ?

                    <PDFDownloadLink
                        className="PDF-Download"
                        document={
                            <Document orientation="landscape">
                                <EmailComisiones
                                    datos={tablaComisiones ? tablaComisiones : []}
                                />
                            </Document>
                        }
                        fileName={"comisiones " + props.periodo.format("MM-YYYY") + ".pdf"}
                        style={{ display: 'flex', width: 'fit-content' }}
                    >
                        {({ blob, loading, error }) => (
                            <>
                                <Button className="btn botonCPA" style={{ width: 'fit-content', justifyContent: 'center' }}>
                                    <BsFillFileEarmarkPdfFill className="mb-1" />
                                    <strong>
                                        {loading ? "Generando PDF..." : "Descargar"}
                                    </strong>
                                </Button>
                            </>
                        )}
                    </PDFDownloadLink>
                    :
                    <Button className="btn botonCPA" style={{ width: 'fit-content', justifyContent: 'center', backgroundColor: 'grey', borderColor: 'grey', cursor: 'default' }} onClick={(event) => { event.preventDefault() }}>
                        <BsFillFileEarmarkPdfFill className="mb-1" />
                        <strong>
                            Descargar
                        </strong>
                    </Button>
                }
            </div>

        </Card>
    );
}

export default GenerarComisiones; 