import React, { useState } from "react";
import { Col, Row, Card, Form, Modal, Container, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import Select from 'react-select'
import Notificaciones from '../../../shared/helpers/notificacionesToast';
import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select;

export function ModalDocumentacionMasiva(props) {
const requiredSymbol = props.titulo !== "Ver" ? <b style={{ color: "red" }}>*</b> : "";
const URL_GENERAR = window.API_ROUTES.AGENCIAS;
const [fechaCierre, setFechaCierre] = useState({
    fechaMuestra: moment().format('MM/YYYY'),
    fechaComparar: moment().format('YYYY-MM'),
  });

  const GenerarDocumentacion = async (event) => {
    event.preventDefault()
    Swal.fire({
      title: "Estás seguro?",
      text: "Esta accion va a generar la documentación en la fecha seleccionada",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#008185",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancelar",
    }).then(async(result) => {
      if (result.isConfirmed) {
        var datos={
          Fecha:fechaCierre.fechaComparar,
           }
      ConsultasAPI.CrearObjeto(URL_GENERAR+'generar_masivo/', datos);
      Notificaciones.notificacion('Comenzando proceso de descarga de documentación. Una vez finalizado, se le comunicará a traves de un correo electrónico.');
    handleClose(event);
        }
                        });
 }

const handleClose = (event) => {
    event.preventDefault();
    setFechaCierre(prevState => ({
              ...prevState,
              fechaMuestra: moment().format('MM/YYYY'),
              fechaComparar:moment().format('YYYY-MM'),
          }));
 props.onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
GenerarDocumentacion(event);
  };

const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
      const formControls = event.target.form.elements;
      const currentIndex = Array.from(formControls).indexOf(event.target);
      const nextControl = formControls[currentIndex + 1];
      if (nextControl) {
        nextControl.focus();
      }
    }
  };


  return (
    <Container>
      <Modal show={props.show} size="lg" centered>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Generar documentación</Modal.Title>
        </Modal.Header>
        <Form onKeyDown={handleFormKeyDown} style={{ width: "100%" }}>
          <Modal.Body style={{ width: "100%" }}>
            <Card className="m-3">
          <Card.Body>
             <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="6">
                      <Form.Label>Periodo {requiredSymbol}</Form.Label>
                      <Datetime
                        timeFormat={false}
                        dateFormat="MM/YYYY"
                        inputProps={{
                          readOnly: true,
                          placeholder: fechaCierre.fechaMuestra?fechaCierre.fechaMuestra:'Seleccione una fecha',
                        }}
                        required
                        value={fechaCierre.fechaMuestra}
                        onChange={(momentDate) => {
                        const fechaMuestra = momentDate.format("MM/YYYY");
                          const fechaComparar = momentDate.format("YYYY-MM");
                            setFechaCierre({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                          }}
                       />
                    </Form.Group>
                   </Row>
                </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn botonCPA m-3" onClick={handleClose}>
              {'Cancelar'}
            </button>
            {<button className="btn botonCPA m-3" onClick={handleSubmit}>
                Generar
              </button>}
          </Modal.Footer>
        </Form>
        </Modal>
       </Container>
  );
}
