import toast, { Toaster } from "react-hot-toast";
import Notificaciones from "./notificacionesToast";
import AuthHelper from "./authenticationHelper";
import Request from "./request";

const CambiarClaveAPI = (
  email,
  newPassword,
  confirmPassword,
  onLogout,
  setMessage,
  currentPassword
) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*[\W_\x7B-\xFF]).{8,}$/;
  const logOut = () => {
    AuthHelper.logout(() => {
      onLogout();
    });
  };

  return new Promise((resolve, reject) => {
    if (currentPassword !== null && currentPassword === newPassword) {
      setMessage({
        text: "La nueva contraseña no puede ser igual a la actual",
        variant: "danger",
      });
      setTimeout(() => {
        setMessage(null);
      }, 7000);
      resolve(0);
    } else if (!passwordRegex.test(newPassword)) {
      setMessage({
        text: "La nueva contraseña debe tener al menos 8 caracteres, una mayúscula, y contener al menos un número",
        variant: "danger",
      });
      setTimeout(() => {
        setMessage(null);
      }, 7000);
      resolve(0);
    } else if (newPassword !== confirmPassword) {
      setMessage({
        text: "La nueva contraseña debe coincidir con la confirmación",
        variant: "danger",
      });
      setTimeout(() => {
        setMessage(null);
      }, 7000);
      resolve(0);
    } else {
      Request
        .post("/login/cambiar-clave/", {
          email: email,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
          currentPassword: currentPassword,
        })
        .then((response) => {
          Notificaciones.notificacion(response.data.message);
          logOut();
          resolve(1);
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            Notificaciones.notificacion(
              "La nueva contraseña no puede ser una de las últimas tres contraseñas."
            );
            resolve(0);
          } else if (error.response && error.response.status === 400) {
            setMessage({
              text: error.response.data.error,
              variant: "danger",
            });
            setTimeout(() => {
              setMessage(null);
            }, 7000);
            resolve(0);
          } else {
            Notificaciones.notificacion(
              "No se pudo cambiar la clave. Inténtalo de nuevo más tarde."
            );
            resolve(0);
          }
        });
    }
  });
};

export default CambiarClaveAPI;
