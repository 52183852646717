import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Modal, Container, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import Select from 'react-select'
import Notificaciones from '../../../../shared/helpers/notificacionesToast';
import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select;

export function ModalFechaCierre(props) {
    const requiredSymbol = props.titulo !== "Ver" ? <b style={{ color: "red" }}>*</b> : "";
const URL_CREAR = window.API_ROUTES.FECHALIMITE;
const [fechaCierre, setFechaCierre] = useState({
    fechaMuestra: props.periodo,
    fechaComparar: moment(props.periodo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
  });

  const [IdFechaCierre, setIdFechaCierre] = useState("");
  const [Operacion, setOperacion] = useState("");

  var valid = function (current) {
    const fechaSeleccionada = moment(current);
    const fechaProps = moment(props.periodo, 'DD/MM/YYYY');
    return fechaSeleccionada.isSame(fechaProps, 'month');
    };


useEffect(() => {
    setFechaCierre(prevState => ({
        ...prevState,
        fechaMuestra: props.periodo,
        fechaComparar:moment(props.periodo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }));
    setIdFechaCierre(props.idFecha)
    setOperacion(props.titulo)

}, [props.periodo, props.idFecha]);


  const CrearFechaLimite = async (event) => {
    event.preventDefault()

        if(Operacion==='Nuevo')
                {
    Swal.fire({
      title: "Estás seguro?",
      text: "Esta accion creará la fecha de cierre",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#008185",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancelar",
    }).then(async(result) => {

      if (result.isConfirmed) {
        var datos={
          Fecha:fechaCierre.fechaComparar,
          id:IdFechaCierre
                    }
      await ConsultasAPI.CrearObjeto(URL_CREAR+'modificar_fecha_cierre/', datos).then((response) => {
          if(response.status===200){
              Swal.fire(
                "Fecha de cierre creada",
                "Se creó la fecha con éxito",
                "success"
              );
                handleClose(event);
                                 }

               }).catch((error) => {
              console.info(error)
              Swal.fire({
                title: "Error",
                text: "Error del servidor",
                icon: "error"
    })
                             });
                        }
                        });
                    }

              if(Operacion==='Modificar')
                        {
            Swal.fire({
              title: "Estás seguro?",
              text: "Esta accion modificará la fecha de cierre",
              icon: "warning",
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonColor: "#008185",
              cancelButtonColor: "#d33",
              confirmButtonText: "Guardar",
              cancelButtonText: "Cancelar",
            }).then(async(result) => {

              if (result.isConfirmed) {
            var datos={
              Fecha:fechaCierre.fechaComparar,
              id:IdFechaCierre
                        }
          await ConsultasAPI.CrearObjeto(URL_CREAR+'modificar_fecha_cierre/', datos).then((response) => {
              if(response.status===200){
                  Swal.fire(
                    "Fecha de cierre modificada",
                    "Se modificó la fecha con éxito",
                    "success"
                  );
                    handleClose(event);
                                     }

                   }).catch((error) => {
                  console.info(error)
                  Swal.fire({
                    title: "Error",
                    text: "Error del servidor",
                    icon: "error"
        })
                                 });
                                }
                                });
                            }

 }


 const handleClose = (event) => {
    event.preventDefault();
      props.onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

        CrearFechaLimite(event);

      };


  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter

      // Encuentra el índice del campo actual dentro del formulario
      const formControls = event.target.form.elements;
      const currentIndex = Array.from(formControls).indexOf(event.target);

      // Encuentra el siguiente campo y enfócalo
      const nextControl = formControls[currentIndex + 1];
      if (nextControl) {
        nextControl.focus();
      }
    }
  };


  return (
    <Container>
      <Modal show={props.show} size="lg" centered>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>{Operacion==='Nuevo'?'Agregar':'Modificar'} fecha de cierre</Modal.Title>
        </Modal.Header>
        <Form onKeyDown={handleFormKeyDown} style={{ width: "100%" }}>
          <Modal.Body style={{ width: "100%" }}>
            <Card className="m-3">

              <Card.Body>

                <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="6">
                      <Form.Label>Fecha de cierre {requiredSymbol}</Form.Label>
                      <Datetime
                        timeFormat={false}
                        //dateFormat="DD/MM/YYYY"
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          readOnly: true,
                          placeholder: fechaCierre.fechaMuestra?fechaCierre.fechaMuestra:'Seleccione una fecha',
                        }}
                        required
                        value={fechaCierre.fechaMuestra}
                        onChange={(momentDate) => {
                        const fechaMuestra = momentDate.format("DD/MM/YYYY");
                          const fechaComparar = momentDate.format("YYYY-MM-DD");
                            setFechaCierre({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });

                        }}
                        isValidDate={valid}
                      />
                    </Form.Group>
                   </Row>

              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn botonCPA m-3" onClick={handleClose}>
              {'Cancelar'}
            </button>
            {<button className="btn botonCPA m-3" onClick={handleSubmit}>
                Guardar
              </button>}
          </Modal.Footer>
        </Form>
        </Modal>
       </Container>
  );
}
