import { useState } from "react";
import moment from 'moment';
import {
    Modal,
    Form,
    Spinner,
    Button,
    Container,
} from 'react-bootstrap';
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import Swal from 'sweetalert2';

const ModalRetencion = (props) => {
    const [motivo, setMotivo] = useState("");
    const URL_RETENCION = window.API_ROUTES.RETENCION;
    const URL_MOTIVOCAMBIORETENCION = window.API_ROUTES.MOTIVOCAMBIORETENCION;
    const [isLoading, setIsLoading] = useState(false);

    const agregarMotivo = async (event) => {
        event.preventDefault();
        Swal.fire({
            title: '¿Estás seguro de recalcular las retenciones?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                const objeto = {
                    motivo: motivo,
                    fecha_cambio: moment(),
                };
                const motivo_response = await ConsultasAPI.CrearObjeto(URL_MOTIVOCAMBIORETENCION, objeto);
                if (motivo_response.status === 201) {
                    const response = await ConsultasAPI.CrearObjeto(
                        URL_RETENCION +
                        'recalcularRetencionesOrdenesDePago/',
                        1
                    );
                    if (response.status === 200) {
                        setIsLoading(false);
                        Swal.fire({
                            title: 'Se recalcularon con Exito',
                            text: 'Se recalcularon con Exito las Retenciones',
                            icon: 'success',
                            confirmButtonColor: '#008185',
                        });
                        handleCloseModal();
                    } else {
                        setIsLoading(false);
                        Swal.fire({
                            title: 'No se pudieron recalcular las Retenciones',
                            icon: 'error',
                            confirmButtonColor: '#EC1B23',
                        });
                        handleCloseModal();
                    }
                } else {
                    setIsLoading(false);
                    Swal.fire({
                        title: 'No se pudieron recalcular las Retenciones',
                        icon: 'error',
                        confirmButtonColor: '#EC1B23',
                    });
                    handleCloseModal();
                }
            };
        });
    };

    const handleCloseModal = () => {
        setMotivo("");
        props.close();
    };

    return (
        <Container>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>
                        Retenciones
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={agregarMotivo}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Motivo: </Form.Label>
                            <Form.Control
                                as="textarea"
                                value={motivo}
                                required
                                placeholder="Ingrese el Motivo por el cual quiere recalcular las retenciones"
                                onChange={(event) => { setMotivo(event.target.value); }}
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button type="submit" className="btn botonCPA m-3">Recalcular</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Form>
            </Modal>
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Recalculando...</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};
export default ModalRetencion;
