/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    Navbar,
    Nav,
    NavDropdown,
    Modal,
    Ratio
} from "react-bootstrap";
import { Form } from "antd";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import TablasExtractos from "./TablasExtractos";
import { Link } from "react-router-dom";
import { FcCalendar } from "react-icons/fc";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from 'react-bootstrap/Carousel';
import VideoComponent from "./VideoComponent"
const FormItem = Form.Item;
function agregarCeros(numero) {
    const numeroString = numero.toString(); // Convertir el número a string
    const cerosFaltantes = 4 - numeroString.length; // Calcular la cantidad de ceros faltantes
    if (cerosFaltantes > 0) {
        const ceros = "0".repeat(cerosFaltantes); // Generar una cadena de ceros
        return ceros + numeroString; // Concatenar los ceros y el número
    }
    return numeroString; // Devolver el número sin cambios si ya tiene 4 dígitos o más
}

const BuscarExtracto = () => {
    const URL_EXTRACTOREGISTRO = window.API_ROUTES.EXTRACTOREGISTRO;
    const URL_TIPOSORTEO = window.API_ROUTES.TIPOSORTEO;
    const URL_EXTRACTO = window.API_ROUTES.EXTRACTO;
    const URL_PUBLICIDAD = window.API_ROUTES.PUBLICIDAD;
    const [fecha, setFecha] = useState({
        fechaMuestra: moment().format("DD/MM/YYYY"),
        fechaComparar: moment().format("YYYY-MM-DD"),
    });
    const [cards, setCards] = useState([]);
    const [duracionExtracto, setDuracionExtracto] = useState(0);
    const [cardPath, setCardPath] = useState(0);

    useEffect(() => {
        cargarTabla();
        // obtenerPublicidad();
    }, [fecha]);

    useEffect(() => {
        // Configura el intervalo para ejecutar cargarTabla cada 2minutos
        const intervalId = setInterval(() => {
            cargarTabla();
            obtenerPublicidad()
            console.info(".")
        }, 240000); // 2 minutos
        // Limpia el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // Configura el intervalo para ejecutar la actualizacion cada 6hs

        const intervalId = setInterval(() => {
            window.location.reload();
        }, 21600000); //3.600.000 milisegundos = 1 hora

        // Limpia el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId);
    }, []);


    const obtenerPublicidad = async () => {
        const publicidad = await ConsultasAPI.ObtenerObjeto(URL_PUBLICIDAD + 'obtener/', 1)
        const videos = []
        const imagenes = []
        const publicidades = [];
        publicidad.data.forEach(publi => {
            const [hours, minutes, seconds] = publi.duracion.split(":").map(Number);

            // Calcular los segundos totales
            const totalSeconds = hours * 3600 + minutes * 60 + seconds;
            if (publi.video) {
                videos.push({
                    path: publi.media,
                    duracion: totalSeconds * 1000,
                })
                publicidades.push({
                    video: true,
                    path: publi.media,
                    duracion: totalSeconds * 1000,
                })
            } else {
                if (!publi.extracto) {
                    imagenes.push({ path: publi.media })
                    publicidades.push({
                        video: false,
                        path: publi.media,
                        duracion: totalSeconds * 1000,
                    })
                } else {
                    setDuracionExtracto(totalSeconds * 1000)
                }

            }
        });
        return publicidades;
    }

    const cargarTabla = async () => {
        // Traemos el extracto del back, seleccionando una fecha
        ConsultasAPI.ObtenerObjeto(
            URL_EXTRACTO + 'busquedaFecha/', fecha.fechaComparar
        ).then(async (response) => {
            let datosTablas = [];
            let contador = 0;
            let cantidad = response.data.length;
            if (response.data.length > 0 && response.status !== 202) {
                response.data.forEach((ext) => {
                    let datosExtracto = [];
                    ConsultasAPI.ObtenerObjeto(URL_TIPOSORTEO, ext.tipo).then(
                        (response) => {
                            let hora = response.data.hora_sorteo;
                            ConsultasAPI.ObtenerObjeto(
                                URL_EXTRACTOREGISTRO + 'busqueda_registros/', ext.id
                            ).then(async (response) => {
                                // Con el extracto obtenido traemos los registros, con el id extracto
                                if (response.data.length > 0) {
                                    for (let i = 10; i > 0; i--) {
                                        datosExtracto.push({
                                            key: i,
                                            numPremio1_10: `${11 - i}° Premio`,
                                            numero1_10: agregarCeros(response.data[i + 9].numero),
                                            numPremio11_20: `${21 - i}° Premio`,
                                            numero11_20: agregarCeros(response.data[i - 1].numero),
                                            tipo: ext.tipo_detalle,
                                            tipoid: ext.tipo,
                                            sorteo: ext.sorteo,
                                            fecha: fecha.fechaMuestra,
                                            hora: hora,
                                        });
                                    }
                                    datosTablas.push(datosExtracto);
                                    // Ordena los datos de la tabla segun el tipo de sorteo
                                    datosTablas.sort((a, b) => {
                                        if (a[0].tipoid < b[0].tipoid) {
                                            return -1;
                                        } else if (a[0].tipoid > b[0].tipoid) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                    contador++;
                                    if (contador == cantidad) {
                                        const publ = await obtenerPublicidad();

                                        var cards = [...publ, ...datosTablas]
                                        if (publ.length > contador) {
                                            const diferencia = publ.length - parseInt(contador);
                                            for (let i = 1; i <= diferencia; i++) {
                                                // cards.push(datosTablas[i])
                                                if (contador <= i) {
                                                    cards.push(datosTablas[i - (contador * i)])
                                                } else {
                                                    cards.push(datosTablas[i])
                                                }
                                            }
                                        };
                                        if (publ.length < contador) {
                                            const diferencia = parseInt(contador) - publ.length;
                                            for (let i = 1; i <= diferencia; i++) {
                                                if (publ.length <= i) {
                                                    cards.push(publ[i - (publ.length * i)])
                                                } else {
                                                    cards.push(publ[i])
                                                }
                                            }
                                        };
                                        const arrays = cards.filter(item => Array.isArray(item));
                                        const nonArrays = cards.filter(item => !Array.isArray(item));

                                        // Interclasión de los arrays y no arrays de manera intercalada
                                        let result = [];
                                        for (let i = 0; i < Math.max(arrays.length, nonArrays.length); i++) {
                                            if (arrays[i]) result.push(arrays[i]);
                                            if (nonArrays[i]) result.push(nonArrays[i]);
                                        }

                                        cards = result
                                        setCards(cards)
                                    }
                                } else {
                                    const publ = await obtenerPublicidad();
                                    setCards(publ)
                                }
                            });
                        }
                    );
                });
            } else {
                if (response.status === 202) {
                    setFecha({
                        fechaMuestra: moment(response.data, "YYYY-MM-DD").format("DD/MM/YYYY"),
                        fechaComparar: moment(response.data, "YYYY-MM-DD").format("YYYY-MM-DD"),
                    })
                }
                const publ = await obtenerPublicidad();
                setCards(publ)
            }
        });
    };

    const handleSelect = (selectedIndex) => {
        if (cards[selectedIndex]) {
            setCardPath(cards[selectedIndex].path)
        }
    };
    return (
        <Modal fullscreen
            show={true}>
            <Carousel onSelect={handleSelect} controls={false} pause={false}>
                {
                    cards && cards.length > 0 ? (
                        cards.map((card, index) => (
                            card.length > 1 ?
                                <Carousel.Item key={index} style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }} interval={duracionExtracto}>
                                    <TablasExtractos datos={card} />
                                </Carousel.Item>
                                :
                                !card.video ?
                                    <Carousel.Item key={index} style={{ height: '100vh' }} interval={card.duracion}>
                                        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={card.path} style={{ width: '100%' }} alt="Descripción de la imagen" />

                                        </div>
                                    </Carousel.Item>
                                    :
                                    <Carousel.Item key={index} interval={card.duracion}>
                                        <VideoComponent videoPath={card.path} mostrar={cardPath} />
                                    </Carousel.Item>
                        ))
                    ) : null
                }
            </Carousel>
        </Modal>
    );

};
export default BuscarExtracto;
