import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Row, Card, Form, Spinner, Modal, Button, Dropdown } from "react-bootstrap";
import Datetime from 'react-datetime';
import ConsultasAPI from "../../../shared/helpers/consultasAPI.jsx";
import Select from 'react-select';
import { FaEraser, FaClipboardList, FaClipboardCheck } from "react-icons/fa";
import ModalAgencias from "./ModalAgencias.jsx";
import ModalResumen from "./ModalResumen.jsx";
import ModalAjuste from "./ModalAjuste.jsx";
import ModalSinDocumentacion from "./ModalSinDocumentacion.jsx";
import ModalCambioEstadoOP from "./ModalCambiosEstadoOP.jsx";
import BtnVolver from "../../common/BtnVolver.jsx";

const Reportes = () => {

    const URL_AGENCIA = window.API_ROUTES.AGENCIA
    const [fechaDesde, setFechaDesde] = useState({
        fechaMuestra: null,
        fechaComparar: null
    });
    const fechaDesdeRef = useRef(null);
    const [fechaHasta, setFechaHasta] = useState({
        fechaMuestra: null,
        fechaComparar: null
    });
    const fechaHastaRef = useRef(null);
    const [tablaAgencias, setTablaAgencias] = useState([]);
    const [agenciaDesde, setAgenciaDesde] = useState('');
    const [agenciaHasta, setAgenciaHasta] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showResumen, setShowResumen] = useState(false);
    const [showAgencias, setShowAgencias] = useState(false);
    const [showAjuste, setShowAjuste] = useState(false);
    const [showSinDocumentacion, setShowSinDocumentacion] = useState(false);
    const [showCambioEstadoOP, setShowCambioEstadoOP] = useState(false);

    useEffect(() => {
        CargarTablaAgencias();
    }, []);

    const CargarTablaAgencias = async () => {
        setIsLoading(true);
        const agenciaData = await ConsultasAPI.ListarObjetos(URL_AGENCIA + 'ListSelect/')
        if (agenciaData.status === 200) {
            const results = agenciaData.data.map((agencia) => ({ value: agencia.id, label: agencia.numero_agencia.toString().padStart(2, '0') + agencia.numero_subagencia.toString().padStart(3, '0') }))
            setTablaAgencias(results);
        } else {
            setTablaAgencias([])
        }
        setIsLoading(false);
    };

    const handleClear = () => {
        setAgenciaDesde('');
        setAgenciaHasta('');
        fechaDesdeRef.current.setState({ inputValue: "" });
        fechaHastaRef.current.setState({ inputValue: "" });
        setFechaDesde({
            fechaMuestra: null,
            fechaHasta: null
        });
        setFechaHasta({
            fechaMuestra: null,
            fechaHasta: null
        });
    }

    const closeResumen = () => {
        setShowResumen(false);
    };
    const closeAgencias = () => {
        setShowAgencias(false);
    };
    const closeAjuste = () => {
        setShowAjuste(false);
    };
    const closeSinDocumentacion = () => {
        setShowSinDocumentacion(false);
    };
    const closeCambioEstadoOP = () => {
        setShowCambioEstadoOP(false);
    };

    return (
        <Container fluid className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Reportes</h1>
                </Col>
            </Row>
            <Card className="m-3">

                <Card.Body>
                    <Row
                        className="mb-3"
                        style={{
                            marginTop: "2%",
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Col md={10}>
                            <Row
                                className="mb-3"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <Col md={4}>
                                    <Form.Label>Periodo Comision Desde</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        style={{ width: "100%", height: "32px" }}
                                        dateFormat="MM/YYYY"
                                        inputProps={{
                                            readOnly: true,
                                            placeholder: "Elegir periodo",
                                        }}
                                        ref={fechaDesdeRef}
                                        value={fechaDesde.fechaMuestra}
                                        onChange={(momentDate) => setFechaDesde({
                                            fechaMuestra: momentDate.format('MM/YYYY'),
                                            fechaComparar: momentDate.format('YYYY-MM')
                                        })}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Periodo Comision Hasta</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        style={{ width: "100%", height: "32px" }}
                                        dateFormat="MM/YYYY"
                                        inputProps={{
                                            readOnly: true,
                                            placeholder: "Elegir periodo",
                                        }}
                                        ref={fechaHastaRef}
                                        value={fechaHasta.fechaMuestra}
                                        onChange={(momentDate) => setFechaHasta({
                                            fechaMuestra: momentDate.format('MM/YYYY'),
                                            fechaComparar: momentDate.format('YYYY-MM')
                                        })}
                                    />
                                </Col>
                            </Row>
                            <Row
                                className="mb-3"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <Col md={4} style={{ zIndex: '999' }}>
                                    <Form.Label>Agencia Desde</Form.Label>
                                    <Select
                                        value={agenciaDesde}
                                        onChange={(option) => { setAgenciaDesde(option) }}
                                        options={tablaAgencias}
                                        placeholder="Seleccionar Agencia"
                                        required
                                    />
                                </Col>
                                <Col md={4} style={{ zIndex: '999' }}>
                                    <Form.Label>Agencia Hasta</Form.Label>
                                    <Select
                                        value={agenciaHasta}
                                        onChange={(option) => { setAgenciaHasta(option) }}
                                        options={tablaAgencias}
                                        placeholder="Seleccionar Agencia"
                                        required
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                            <Button
                                className="btn botonCPA me-3"
                                onClick={handleClear}
                                style={{ margin: '15px', width: 'fit-content', height: 'fit-content' }}
                            >
                                <FaEraser className="iconAntD" />
                                Limpiar Filtro
                            </Button>
                        </Col>
                    </Row>
                    <Row
                        className="mb-3"
                        style={{
                            marginTop: "2%",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Col style={{ width: 'fit-content' }} md={4}>
                            <Dropdown >
                                <Dropdown.Toggle className="btn botonCPA m-3" id="dropdown-basic" variant='success'>
                                    Envio Comisiones
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { setShowAgencias(true) }}>Reporte Por Agencia</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setShowResumen(true) }}>Reporte Resumen</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col style={{ width: 'fit-content' }} md={4}>
                            <Button className="btn botonCPA m-3" onClick={() => { setShowAjuste(true) }} >
                                <FaClipboardList />
                                Reporte Ajustes
                            </Button>
                        </Col>
                        <Col style={{ width: 'fit-content' }} md={4}>
                            <Button className="btn botonCPA m-3" onClick={() => { setShowSinDocumentacion(true) }} >
                                <FaClipboardList />
                                Reporte Pendientes
                            </Button>
                        </Col>
                        <Col style={{ width: 'fit-content' }} md={4}>
                            <Button className="btn botonCPA m-3" onClick={() => { setShowCambioEstadoOP(true) }} >
                                <FaClipboardList />
                                Reporte Cambios de Estado
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>

            </Card>
            <Row className="text-center">
                <section className="d-flex justify-content-end my-3">
                    <BtnVolver />
                </section>
            </Row>
            <ModalResumen
                show={showResumen}
                close={closeResumen}
                fechaDesde={fechaDesde.fechaComparar}
                fechaHasta={fechaHasta.fechaComparar}
                agenciaDesde={agenciaDesde}
                agenciaHasta={agenciaHasta}
            />
            <ModalAgencias
                show={showAgencias}
                close={closeAgencias}
                fechaDesde={fechaDesde.fechaComparar}
                fechaHasta={fechaHasta.fechaComparar}
                agenciaDesde={agenciaDesde}
                agenciaHasta={agenciaHasta}
            />
            <ModalAjuste
                show={showAjuste}
                close={closeAjuste}
                fechaDesde={fechaDesde.fechaComparar}
                fechaHasta={fechaHasta.fechaComparar}
                agenciaDesde={agenciaDesde}
                agenciaHasta={agenciaHasta}
            />
            <ModalSinDocumentacion
                show={showSinDocumentacion}
                close={closeSinDocumentacion}
                fechaDesde={fechaDesde.fechaComparar}
                fechaHasta={fechaHasta.fechaComparar}
                agenciaDesde={agenciaDesde}
                agenciaHasta={agenciaHasta}
            />
            <ModalCambioEstadoOP
                show={showCambioEstadoOP}
                close={closeCambioEstadoOP}
                fechaDesde={fechaDesde.fechaComparar}
                fechaHasta={fechaHasta.fechaComparar}
                agenciaDesde={agenciaDesde}
                agenciaHasta={agenciaHasta}
            />
            <Modal
                centered
                show={isLoading}
            >
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando Datos...</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};
export default Reportes;
