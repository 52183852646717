import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, Button, Form } from "react-bootstrap";
//import Datetime from "react-datetime";
// import { useNavigate } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import { FaEraser } from "react-icons/fa";

import MaterialReactTable from "material-react-table";
// import {
//     BsCheckCircleFill,
//     BsFillXSquareFill,
//     BsDashCircleFill,
// } from "react-icons/bs";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import moment from "moment";
//import ExportarRendiciones from "./ExportarRendiciones";
import BtnVolver from "../../../common/BtnVolver";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import { AiOutlineUpload, AiOutlineDownload } from "react-icons/ai";
//import { Box } from "@mui/material";
import Parser from "../../../common/Parser";
//import Notificaciones from "../../../../shared/helpers/notificacionesToast";
import { ModalNuevoSorteo } from "./ModalNuevoSorteo";
import {FaPlusCircle } from "react-icons/fa";

const RendicionesTuqui = (props) => {
    const rolUser = props.rolUsuario;

    // const navigate = useNavigate();

    const [agencias, setAgencias] = useState([]);
    const [sorteos, setSorteos] = useState([]);
    //const [jurisdicciones, setJurisdicciones] = useState([]);

    const [fechaDesde, setFechaDesde] = useState(
        moment().subtract(2, "days").format("YYYY-MM-DD")
    );
    const [fechaHasta, setFechaHasta] = useState(moment().format("YYYY-MM-DD"));
    const [agencia, setAgencia] = useState();
    const [sorteo, setSorteo] = useState();
    const [localidad, setLocalidad] = useState();
    const [rendicionesTabla, setRendicionesTabla] = useState([]);
    const [localidades,setLocalidades]=useState([]);
    //const [recalcComision, setRecalcComision] = useState(false);
    // const [rendicionesFecha, setRendicionesFecha] = useState([]);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    //const [openExport, setOpenExport] = useState(false);

    // const [showModalNuevoSorteo, setShowModalNuevoSorteo] = useState(false);
    // const [tituloModal, setTituloModal] = useState("");

    // const handleCloseModalNuevoSorteo = () => {
    //     setShowModalNuevoSorteo(false);
    //   };

    //   const handleOpenModalNuevoSorteo = () => {
    //     setTituloModal("Nuevo");
    //     setShowModalNuevoSorteo(true);
    //   };





    //const URL_RENDICION_AGENCIA = window.API_ROUTES.RENDICIONAGENCIA;
    const URL_AGENCIAS = window.API_ROUTES.AGENCIAS;
    const URL_SORTEO = window.API_ROUTES.SORTEO;
    const URL_VENTAS = window.API_ROUTES.VENTAS;
    const URL_LOCALIDAD = window.API_ROUTES.LOCALIDAD;


    const columns = [
        {
            header: "Agencia",
            accessorKey: "titular_agencia.agencia_detalle.numero_agencia",
            size: 30,
        },
        {
            header: "Subagencia",
            accessorKey: "titular_agencia.agencia_detalle.numero_subagencia",
            size: 30,
        },
        {
            header: "Sorteo",
            accessorKey: "sorteo.numero",
            size: 30,
        },
        {
            header: "Cantidad Entregada",
            accessorKey: "cantidad_entregada",
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
        {
            header: "Cantidad Devuelta",
            accessorKey: "cantidad_devuelta",
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
        {
            header: "Cantidad Vendida",
            accessorKey: "cantidad_vendida",
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
        {
            header: "Monto Total",
            accessorKey: "monto_vendida",
            size: 30,
            Cell: ({ renderedCellValue }) => (
                <span>{Parser.currency(renderedCellValue)}</span>
            ),
        },
        {
            header: "Estado",
            accessorKey: "estado",
            size: 30,
        },
    ];

    // var valid = function (current) {
    //     const today = moment();
    //     const isSunday = current.day() === 0;
    //     return current.isBefore(today) && !isSunday;
    // };

    useEffect(() => {
        ConsultasAPI.ListarObjetos(URL_AGENCIAS + "ListaFiltrar/").then(
            (response) => {
                setAgencias(response.data);
            }
        );
        ConsultasAPI.ListarObjetos(URL_SORTEO + "listSorteos/").then(
            (response) => {
                setSorteos(response.data);
            }
        );
        ConsultasAPI.ListarObjetos(URL_LOCALIDAD + "listSinPag/").then(
            (response) => {

                setLocalidades(response.data);
            }
        );

    }, []);

    useEffect(() => {
        const filters = {
            agencia: agencia && agencia !== "-" ? agencia : "",
            sorteo: sorteo && sorteo !== "-" ? sorteo : "",
            fechaDesde,
            fechaHasta,
            localidad: localidad && localidad !== "-" ? localidad : "",
        };
        ConsultasAPI.ListarObjetos(
            URL_VENTAS,
            pagination.pageIndex,
            pagination.pageSize,
            filters
        ).then((response) => {

            setCount(response.data.count);
            setRendicionesTabla(response.data.results);
        });

    }, [
        sorteo,
        pagination.pageIndex,
        pagination.pageSize,
        agencia,
        localidad,
    ]);

    const handleClickClear = () => {
        setFechaDesde(moment().subtract(2, "days").format("YYYY-MM-DD"));
        setFechaHasta(moment().format("YYYY-MM-DD"));
        setAgencia("-");
        setLocalidad("-");
        setSorteo("-");
    };
    const cargarRendiciones = () => {
        window.location.href += "/cargar-ventas";
    };

    // const registrarRendiciones = () => {
    //   window.location.href += "/registrar-rendiciones";
    // };

    // const calcularVencimiento = (fecha) => {
    //     const fechaRendicion = moment(fecha, "YYYY-MM-DD");
    //     const fechaActual = moment();

    //     if (fechaActual.diff(fechaRendicion, "days") >= 2) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // };

    // const cambiarEstado = (id_rendicion) => {
    //     // let estado = "";
    //     // const rendicion = rendicionesTabla.find(
    //     //     (rendicion) => rendicion.id === id_rendicion
    //     // );
    //     // if (rendicion.estado === "Pendiente") {
    //     //     estado = "Pagada";
    //     // } else if (rendicion.estado === "Pagada") {
    //     //     if (calcularVencimiento(rendicion.fecha_inicio) === true) {
    //     //         estado = "Vencida";
    //     //     } else {
    //     //         estado = "Pendiente";
    //     //     }
    //     // } else {
    //     //     estado = "Pagada";
    //     // }

    //     // setRecalcComision(true);
    //     // ConsultasAPI.ModificarObjeto(URL_RENDICION_AGENCIA, id_rendicion, {
    //     //     estado_rendicion: estado_rendicion,
    //     // })
    //     //     .then((response) => {
    //     //         rendicion.estado_rendicion = estado_rendicion;
    //     //         rendicion.actualizado_por = response.data.actualizado_por;
    //     //         setRendicionesTabla([...rendicionesTabla]);
    //     //         setRecalcComision(false);
    //     //     })
    //     //     .catch((error) => {
    //     //         Notificaciones.notificacion(error.response.data.error);
    //     //         setRecalcComision(false);
    //     //     });
    // };

    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Rendiciones Tuqui</h1>
                </Col>
                <Col className="d-flex justify-content-end my-3 ">
                {/* {rolUser === "ADMINISTRADOR" ||
                        rolUser === "DOCUMENTACION" ||
                        rolUser === "CONTADURIA" ||
                        rolUser === "GERENCIA-QUINIELA" ? (


                        <Button className="btn botonCPA m-3" onClick={handleOpenModalNuevoSorteo}>
                            <FaPlusCircle className="iconAntD" />
                            NUEVO SORTEO
                        </Button>
                    ) : null} */}

                    {rolUser === "ADMINISTRADOR" ||
                        rolUser === "CONTADURIA" ||
                        rolUser === "GERENCIA-QUINIELA" ? (


                        <Button className="btn botonCPA m-3" onClick={cargarRendiciones}>
                            <AiOutlineUpload className="iconAntD" />
                            SUBIR ARCHIVO
                        </Button>
                    ) : null}
                    {/* <Button className="btn botonCPA m-3" onClick={registrarRendiciones}>
            <AiFillEdit  /> REGISTRAR
            RENDICIONES
          </Button> */}
                </Col>
            </Row>
            {/* <Row> */}
            <Card>
                <Card.Body>
                    <Row>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Agencia</Form.Label>
                            <Form.Select
                                // style={{ width: "18%", height: "32px" }}

                                value={agencia}
                                onChange={(event) => setAgencia(event.target.value)}
                                disabled={agencias.length === 0}
                            >
                                <option hidden>-</option>
                                {agencias
                                    .filter((agencia) => agencia.numero_subagencia === 0)
                                    .map((agencia) => (
                                        <option key={agencia.id} value={agencia.numero_agencia}>
                                            {agencia.numero_agencia}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Sorteo</Form.Label>
                            <Form.Select
                                // style={{ width: "18%", height: "32px" }}

                                value={sorteo}
                                onChange={(event) => setSorteo(event.target.value)}
                                disabled={sorteos.length === 0}
                            >
                                <option hidden>-</option>
                                {sorteos
                                    .map((sorteo) => (
                                        <option key={sorteo.id} value={sorteo.id}>
                                            {sorteo.numero}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Municipio/Comuna</Form.Label>
                            <Form.Select
                                // style={{ width: "14%", height: "37px" }}

                                value={localidad}
                                onChange={(event) => setLocalidad(event.target.value)}
                                disabled={localidades.length === 0}
                            >
                                <option hidden>-</option>
                                {localidades.map((localidad) => (
                                    <option key={localidad.id} value={localidad.id}>
                                        {localidad.nombre}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Button
                                className="btn botonCPA me-3"
                                onClick={handleClickClear}
                                style={{ alignContent: "center", margin: 15, float: "right" }}
                            >
                                <FaEraser className="iconAntD" />
                                Limpiar Filtro
                            </Button>
                        </Form.Group>
                    </Row>
                    {/* <Row>
            <section className=" d-flex justify-content-center my-3">
              <Button
                className="btn botonCPA m-3"
                onClick={() => {
                  // setRendicionesTabla(rendicionesFecha);
                }}
              >
                Limpiar Filtro
              </Button>
            </section>
          </Row> */}
                </Card.Body>
                {/* </Row> */}
                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={rendicionesTabla}
                    initialState={{ columnVisibility: { id: false , estado : false} }}
                    enableRowSelection={false}
                    enableColumnOrdering={false}
                    enableSorting={false}
                    enableGlobalFilter={false}
                    localization={MRT_Localization_ES}
                    manualPagination
                    enablePagination={true}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    // state={{ pagination }} //pass the pagination state to the table
                    state={{
                        pagination,
                    }}
                    // enableRowActions={
                    //     rolUser === "ADMINISTRADOR" ||
                    //     rolUser === "DOCUMENTACION" ||
                    //     rolUser === "CONTADURIA" ||
                    //     rolUser === "GERENCIA-QUINIELA"
                    // }
                    // positionActionsColumn="last"
                    // renderRowActions={({ row }) => (
                    //     <Box className="d-flex">
                    //         <Button
                    //             className="me-3"
                    //             disabled={recalcComision}
                    //             title={
                    //                 row.estado === false
                    //                     ? "Confirmar"
                    //                     : ""
                    //             }
                    //             variant={
                    //                 row.estado === true
                    //                     ?
                    //                     "secondary"
                    //                     :
                    //                     "outline-danger"

                    //             }
                    //             onClick={() => cambiarEstado(row.getValue("id"))}
                    //         >
                    //             {row.estado === false ?
                    //                 <BsFillXSquareFill />
                    //                 :
                    //                 <BsCheckCircleFill />
                    //             }
                    //         </Button>
                    //     </Box>
                    // )}
                />
                <section
                    className="d-flex justify-content-end my-3"
                    style={{ marginRight: 10 }}
                >
                    <BtnVolver route="/liquidaciones/rendiciones" />
                </section>
            </Card>

            {/* <ModalNuevoSorteo
        onClose={handleCloseModalNuevoSorteo}
        show={showModalNuevoSorteo}
        titulo={tituloModal}
       /> */}


        </Container>
    );
};

export default RendicionesTuqui;
