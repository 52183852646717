import React, { useState, useEffect } from 'react';
import { IconButton, Badge, Popover, List, ListItem, ListItemText } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from "react-router-dom";
import ConsultasAPI from "../../shared/helpers/consultasAPI";

function App() {

    const [notifications, setNotifications] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const [load, setLoad] = useState(false)
    const URL_ARCHIVODOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    useEffect(() => {
        cargarData();
    }, [open])



    const cargarData = async () => {
        const archivos = await ConsultasAPI.ListarTodos(URL_ARCHIVODOCUMENTACION + 'obtenerDocumentacionBool/');
        if (archivos.status === 200) {
            const newNotification = `Hay agencias que subieron documentacion`;
            if (!load) {
                cargarNotificacionesLiquidacionPendiente(newNotification);
            };
            setLoad(true);

        } else {
            setLoad(false);
        };
    };


    const cargarNotificacionesLiquidacionPendiente = async (newNotification) => {
        setNotifications([...notifications, newNotification]);
        setLoad(true);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenModalDocumentacion = () => {
        navigate('/Documentacion')
    };


    const handleNotificationClick = (noti, index) => {
        handleOpenModalDocumentacion();
        if (!load) {
            // Elimina la notificación específica al hacer clic
            const updatedNotifications = [...notifications];
            updatedNotifications.splice(index, 1);
            setNotifications(updatedNotifications);
        };
        handleClose(); // Cierra el Popover después de hacer clic en una notificación
    };


    return (
        <div className="App">
            <IconButton
                size="large"
                color="inherit"
                aria-describedby={id}
                onClick={handleClick}
            >
                <Badge badgeContent={notifications.length} color="info">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {notifications.length === 0 ?
                    <List>
                        <ListItem>
                            <ListItemText primary="No hay notificaciones" />
                        </ListItem>
                    </List>
                    :
                    <List>
                        {notifications.map((notification, index) => (
                            <ListItem
                                key={index}
                                button
                                onClick={() => handleNotificationClick(notification, index)}
                            >
                                <ListItemText primary={notification} />
                            </ListItem>
                        ))}
                    </List>
                }
            </Popover>

        </div>
    );
}

export default App;
