import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const OpcionInicio = ({ imagen, texto, ruta }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(ruta);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="card-seleccion" style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'nowrap', }}>
      <div  >
        <div className={`d-flex mb-4 card-options ${isHovered ? 'hovered' : ''}`}
          style={{ backgroundColor: '#fff', color: 'black' }}
          onClick={handleCardClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <div className="img-options img-2" >
              <img src={imagen} style={{ width: '112px', height: '122px', paddingBottom: '8px' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
              <p className="text-options text-center" >{texto}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="card-side"></div>
    </div>
  );
};

export default OpcionInicio;
