import { Button } from "react-bootstrap";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../../../../img/logo.png";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import AuthenticationHelper from "../../../../../shared/helpers/authenticationHelper.js";
import dayjs from "dayjs";
const styles = StyleSheet.create({
  page: {
    padding: 49,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    width: 80,
    height: 47,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: "auto",
  },
  subHeaderTitle: {
    fontSize: 11,
    marginLeft: "auto",
    marginBot: 20,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    textAlign: "left",
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  table: {
    marginTop: 10,
    marginBot: 50,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 11,
    marginBot: 10,
  },
  tableColHeader: {
    flex: 1,
    width: "50%",
    borderStyle: "solid",
    backgroundColor: "#bfbfbf",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    fontWeight: "bold",
    fontSize: 12,
  },
  tableCol: {
    flex: 1,
    width: "50%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    padding: 5,
    borderRightWidth: 1, // borde derecho de las columnas
  },
  sectionSeparator: {
    height: 20, // Altura de la separación entre secciones
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    marginTop: 5,
    marginBottom: 5,
  },
  separatorDotted: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    marginTop: 115,
    marginBottom: 20,
    borderStyle: 'dashed',
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    float: "right",
    fontSize: 8,
    textAlign: "center",
    color: "black",
  },
  signatureContainer: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  dottedLine: {
    flex: 1,
    width: '50%',
    borderBottomWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'black',
  },
  signatureText: {
    marginLeft: 10,
    fontWeight: 'bold',
    fontSize: 11,
  },
});

const ReciboRendiciones = (props) => {
  const components = props.datos;
  const componentsToTable = props.datos.map(dato => { return { detalle: dato.detalle, importe: dato.importe } })
  const tables = (dato) => {
    const component = [{ detalle: dato.detalle, importe: dato.importe }];
    // Generar una lista de todos los atributos de todos los componentes
    const attributesList = componentsToTable
      ? componentsToTable.map((component) => Object.keys(component))
      : [];
    const uniqueAttributesList = [
      ...new Set(attributesList.map((attributes) => attributes.join())),
    ];
    //   const uniqueAttributesList = components ? Object.keys(components[0]) : [];
    // Generar una tabla por cada conjunto de atributos iguales
    return uniqueAttributesList.map((attributes) => {
      const filteredComponents = component;

      if (filteredComponents.length === 0) {
        return null;
      }
      const tableHeaders = attributes.split(",");
      return (
        <div key={"recibos"}>
          {/* <View style={styles.sectionSeparator} /> */}
          {/* <Text style={styles.subtitle}>{tableName}</Text> */}

          <View style={styles.table}>
            {/* Encabezado de tabla */}
            <View style={styles.tableRow}>
              {tableHeaders.map((header, index) => (
                <View style={styles.tableColHeader} key={index.toString()}>
                  <Text>
                    {header.charAt(0).toUpperCase() +
                      header.slice(1).replace("_", " ")}
                  </Text>
                </View>
              ))}
            </View>

            {/* Filas de tabla */}
            {filteredComponents.map((object, index) => (
              <View key={index.toString()} style={styles.tableRow}>
                {tableHeaders.map((column, index) => (
                  <View style={styles.tableCol} key={index.toString()}>
                    <Text>{object[column]}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </div>
      );
    });
  };
  return (
    <PDFDownloadLink
      className="PDF-Download"
      document={
        <Document>
          {components.map((dato) => (
            <Page style={styles.page} key={dato.agencia}>
              {/* Encabezado */}
              <View>
                <View style={styles.header}>
                  <Image src={logo} style={styles.logo} />
                  <Text style={styles.subHeaderTitle}>
                    Fecha:{dayjs().format("DD/MM/YYYY")} {dayjs().format("HH:mm")}
                    {"\n"}
                    Id Viñeta:{dato.id}
                    {"\n"}
                    Usuario: {AuthenticationHelper.getUser()}
                    {"\n"}
                  </Text>
                </View>
                <View style={styles.separator} />
              </View>
              {/* Copia/Agencia */}
              <View>
                <Text style={styles.title}>
                  Agencia Oficial N°: {dato.agencia}
                  {"\n"}
                  Titular: {dato.titular}
                  {"\n"}
                  Domicilio: {dato.domicilio}
                  {"\n"}
                  Telefono: {dato.telefono}
                </Text>
                {/* <View style={styles.sectionSeparator} /> */}
                {tables(dato)}
              </View>
              <Text style={styles.subHeaderTitle}>Copia/Agencia</Text>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={styles.signatureContainer}>
                  <View style={styles.dottedLine} />
                  <Text style={styles.signatureText}>Firma</Text>
                </View>
                <View style={styles.signatureContainer}>
                  <View style={styles.dottedLine} />
                  <Text style={styles.signatureText}>Aclaracion</Text>
                </View>
              </div>
              {/* Original/CPA */}
              <View style={styles.separatorDotted} />

              <View style={styles.header}>
                <Image src={logo} style={styles.logo} />
                <Text style={styles.subHeaderTitle}>
                  Fecha:{dayjs().format("DD/MM/YYYY")} {dayjs().format("HH:mm")}
                  {"\n"}
                  Id Viñeta:{dato.id}
                  {"\n"}
                  Usuario: {AuthenticationHelper.getUser()}
                  {"\n"}
                </Text>
              </View>
              <View style={styles.separator} />
              <View>
                <Text style={styles.title}>
                  Agencia Oficial N°: {dato.agencia}
                  {"\n"}
                  Titular: {dato.titular}
                  {"\n"}
                  Domicilio: {dato.domicilio}
                  {"\n"}
                  Telefono: {dato.telefono}
                </Text>
                {/* <View style={styles.sectionSeparator} /> */}
                {tables({ detalle: dato.detalle, importe: dato.importe })}
              </View>
              <Text style={styles.subHeaderTitle}>Original/CPA</Text>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={styles.signatureContainer}>
                  <View style={styles.dottedLine} />
                  <Text style={styles.signatureText}>Firma</Text>
                </View>
                <View style={styles.signatureContainer}>
                  <View style={styles.dottedLine} />
                  <Text style={styles.signatureText}>Aclaracion</Text>
                </View>
              </div>
            </Page>
          ))}
        </Document>
      }
      fileName={"Recibos.pdf"}
    >
      {({ blob, url, loading, error }) => (
        <Button onClick={props.onExport} className="btn botonCPA my-4">
          <BsFillFileEarmarkPdfFill className="mb-1" />
          <strong>{loading ? "Generando PDF..." : "Exportar a PDF"}</strong>
        </Button>
      )}
    </PDFDownloadLink>
  );
};

export default ReciboRendiciones;
