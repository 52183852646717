import React, { useMemo, useState, useEffect, useRef } from 'react';
import BtnVolver from '../../common/BtnVolver';
import { Link } from 'react-router-dom';
import {
    Container,
    Col,
    Row,
    Card,
    Form,
    Spinner,
    Modal,
    Button,
} from 'react-bootstrap';
import { FaEraser, FaFileDownload, FaPlusCircle, FaList } from 'react-icons/fa';
import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import MaterialReactTable from 'material-react-table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import AuthenticationHelper from '../../../shared/helpers/authenticationHelper';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { IconButton } from '@mui/material';
import { Edit, Visibility } from '@mui/icons-material';
import { NuevaAgencia } from './NuevaAgencia';
import { darken } from '@mui/material';
import logoUrl from '../../../img/logo.png';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DetalleDeduccion from '../deducciones/DetalleDocumentacion';
import Novedades from './Novedades';
import moment from 'moment';
import Estadistica from './Estadistica';
import { ModalDocumentacionMasiva } from './ModalDocumentacionMasiva';

//var historial = [];
// const FormItem = Form.Item;
const Agencias = (props) => {
    const [datoAgencia, setDatoAgencia] = useState();
    const rolUser = props.rolUsuario;
    const URL_TITULAR = window.API_ROUTES.TITULAR;
    const URL_AGENCIA = window.API_ROUTES.AGENCIA;
    const URL_TITULARAGENCIA = window.API_ROUTES.TITULARAGENCIA;
    const URL_RESOLUCION = window.API_ROUTES.RESOLUCION;
    const URL_COLABORADOR = window.API_ROUTES.COLABORADOR;
    const URL_DOMICILIOCOMERCIAL = window.API_ROUTES.DOMICILIOCOMERCIAL;
    const URL_ARCHIVORESOLUCION = window.API_ROUTES.ARCHIVORESOLUCION;
    const URL_ARCHIVODOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
    const URL_ARCHIVO = window.API_ROUTES.ARCHIVO;
    const [data, setData] = useState([]);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [fechaDesde, setFechaDesde] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });
    const [fechaHasta, setFechaHasta] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });




    const [showModalDocumentacionMasiva, setShowModalDocumentacionMasiva] = useState(false);
    const [tituloModalDocumentacionMasiva, setTituloModalDocumentacionMasiva] = useState('');

       const handleCloseModalDocumentacionMasiva = () => {
           setShowModalDocumentacionMasiva(false);
        //    loadComisiones();
        //    consultarProceso();
        //    cargaFechaCierre();
       };

       const handleOpenModalDocumentacionMasiva = () => {
        //    if (fechaCierre.length !== 0) {
        //        setfechaCierreModal(fechaCierre);
        //    } else {
        //        setfechaCierreModal(FormatoFecha(periodo));
        //    }
           setTituloModalDocumentacionMasiva('Nuevo');
           setShowModalDocumentacionMasiva(true);
       };




    const [tablaEstado, setTablaEstado] = useState([]);
    const [tablaTipo, setTablaTipo] = useState([]);
    const [estado, setEstado] = useState('');
    const [tipo, setTipo] = useState('');
    const datetimeRefHasta = useRef(null);
    const datetimeRefDesde = useRef(null);
    const [n, setN] = useState();
    const [domicilioComercialview, setDomicilioComercialView] = useState({});
    const [agenciaview, setAgenciaView] = useState({});
    const [titularAgenciaview, setTitularAgenciaView] = useState({});
    const resolucionview={}
    const [colaboradorview, setColaboradorView] = useState({});
    const [localidadview, setLocalidadView] = useState({});
   const [isLoading, setIsLoading] = useState(false);
    const [isLoadingView, setIsLoadingView] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [tituloModal, setTituloModal] = useState('');
    const [opcionesTitulares, setOpcionesTitulares] = useState([]);
    const [dataColaborador, setDataColaboradores] = useState([]);
    const [tipoResolucion, setTipoResolucion] = useState('');
    const [dataResolucion, setDataResolucion] = useState([]);
    const [showModalDetalle, setModalDetalle] = useState(false);
    const [dataDocumentacionAgencia, setDataDocumentacionAgencia] = useState(
        []
    );
    const [dataAgencia, setDataAgencia] = useState([]);
    // Definir el estado que indica si se debe mostrar o no el modal
    const columns = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            size: 50,
        },
        {
            header: 'Agencia',
            accessorKey: 'agencia',
            size: 50,
        },
        {
            header: 'Subagencia',
            accessorKey: 'subagencia',
            size: 50,
        },
        {
            header: 'Codigo',
            accessorKey: 'codigo',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Tipo',
            accessorKey: 'tipo',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha de Registro',
            accessorKey: 'fechaRegistro',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Titular',
            accessorKey: 'titular',
            size: 50,
            enableColumnFilter: true,
        },
        {
            header: 'Email',
            accessorKey: 'email',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha Desde',
            accessorKey: 'fechaDesde',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha Hasta',
            accessorKey: 'fechaHasta',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Estado',
            accessorKey: 'estado',
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    useEffect(() => {
        cargarArchivo();
    }, [pagination, columnFilters, fechaDesde, fechaHasta, estado, tipo]);

    useEffect(() => {
        cargarTablaEstado();
        cargarTablaTipo();
    }, []);

    const cargarTablaEstado = () => {
        let estados = [];
        estados.push({
            id: 1,
            detalle: 'Activo',
        });
        estados.push({
            id: 2,
            detalle: 'Baja',
        });
        estados.push({
            id: 3,
            detalle: 'Suspendido',
        });
        estados.push({
            id: 4,
            detalle: 'Sin Asignar',
        });
        estados.push({
            id: 5,
            detalle: 'Baja - Reasignada',
        });
        estados.push({
            id: 6,
            detalle: 'Caducada',
        });
        setTablaEstado(estados);
    };

    const cargarTablaTipo = () => {
        let tipos = [];
        tipos.push({
            id: 1,
            detalle: 'Agencia',
        });
        tipos.push({
            id: 2,
            detalle: 'SubAgencia',
        });
        setTablaTipo(tipos);
    };

    function formatDate(isoDateString) {
        const date = new Date(isoDateString);

        const day = date.getDate();
        const month = date.getMonth() + 1; // Month is 0-indexed, so add 1
        const year = date.getFullYear();

        // Zero-pad day and month if needed
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
        return formattedDate;
    }
    //Trae los datos desde el back para mostrar en la tabla del front
    const cargarArchivo = () => {
        try {
            ConsultasAPI.ListarObjetos(
                URL_AGENCIA,
                pagination.pageIndex,
                pagination.pageSize,
                columnFilters,
                fechaDesde.fechaMuestra,
                fechaHasta.fechaMuestra,
                estado,
                tipo,
                null,
                null
            ).then((response) => {
                setCount(response.data.count);
                setN(response.data.count / 10);
                let agencias = response.data.results;
                if (agencias) {
                    let datos = [];
                    agencias.forEach((agencia) => {
                        datos.push({
                            id: agencia.id,
                            agencia:
                                agencia.numero_agencia &&
                                agencia.numero_agencia < 10
                                    ? '0' + agencia.numero_agencia.toString()
                                    : agencia.numero_agencia,
                            subagencia:
                                agencia.numero_subagencia &&
                                agencia.numero_subagencia < 10
                                    ? '0' + agencia.numero_subagencia.toString()
                                    : agencia.numero_subagencia,
                            codigo: agencia.numero_subagencia
                                ? concatCodigo(
                                      agencia.numero_agencia,
                                      agencia.numero_subagencia
                                  )
                                : concatCodigo(agencia.numero_agencia, 0),
                            fechaRegistro: agencia.fecha_creacion.split(' ')[0],
                            titular: agencia.titular
                                ? agencia.titular.persona_detalle.cuit +
                                  '-' +
                                  agencia.titular.persona_detalle.apellido +
                                  ' ' +
                                  agencia.titular.persona_detalle.nombre
                                : '-',
                            email: agencia.titular
                                ? agencia.titular.persona_detalle.email
                                : '-',
                            tipo: agencia.numero_subagencia
                                ? 'SubAgencia'
                                : 'Agencia',
                            estado: agencia.estado ? agencia.estado : '-',
                            fechaDesde:
                                agencia.titular && agencia.fecha_desde
                                    ? formatDate(agencia.fecha_desde)
                                    : '-',
                            fechaHasta:
                                agencia.titular && agencia.fecha_hasta
                                    ? formatDate(agencia.fecha_hasta)
                                    : '-',
                        });
                    });
                    setData(datos);
                }
            });
        } catch (error) {
            console.error(error)
        }
    };

    const concatCodigo = (a, b) => {
        let codigoAgencia = '';
        if (a < 10) {
            codigoAgencia = '0' + a.toString();
            if (b < 10) {
                codigoAgencia = codigoAgencia + '00' + b.toString();
            } else {
                codigoAgencia = codigoAgencia + '0' + b.toString();
            }
        } else {
            codigoAgencia = a.toString();
            if (b < 10) {
                codigoAgencia = codigoAgencia + '00' + b.toString();
            } else {
                codigoAgencia = codigoAgencia + '0' + b.toString();
            }
        }
        return codigoAgencia;
    };

    const handleClickExcel = () => {
        guardarHistorial(true);
        setIsLoading(true);
    };
    const handleClickPDF = () => {
        guardarHistorial(false);
        setIsLoading(true);
    };

    //Espera que historial tenga datos, para poder exportarlo a excel y/o pdf
    async function esperarArrayDatos(array, isExcel) {
        while (array.length === 0) {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Espera 1 segundo antes de volver a verificar
        }
        if (isExcel) {
            exportToExcel(array);
        } else {
            convertToPDF(array);
        }
    }

    //Trae todos los extractos del back y los guarda en historial
    const guardarHistorial = async (isExcel) => {
        let i = 0;
        let agenciasTotales = [];
        do {
            try {
                await ConsultasAPI.ListarObjetos(
                    URL_AGENCIA,
                    i,
                    10,
                    columnFilters,
                    fechaDesde.fechaMuestra,
                    fechaHasta.fechaMuestra,
                    estado,
                    tipo,
                    null,
                    null
                ).then((response) => {
                    let agencia = response.data.results;

                    agencia.forEach((agencia) => {
                        let ag = {
                            agencia:
                                agencia.numero_agencia &&
                                agencia.numero_agencia < 10
                                    ? '0' + agencia.numero_agencia.toString()
                                    : agencia.numero_agencia,
                            subagencia:
                                agencia.numero_subagencia &&
                                agencia.numero_subagencia < 10
                                    ? '0' + agencia.numero_subagencia.toString()
                                    : agencia.numero_subagencia,
                            codigo: concatCodigo(
                                agencia.numero_agencia,
                                agencia.numero_subagencia
                            ),
                            fechaRegistro: agencia.fecha_creacion.split(' ')[0],
                            titular: agencia.titular
                                ? agencia.titular.persona_detalle.cuit +
                                  '-' +
                                  agencia.titular.persona_detalle.apellido +
                                  ' ' +
                                  agencia.titular.persona_detalle.nombre
                                : '-',
                            email: agencia.titular
                                ? agencia.titular.persona_detalle.email
                                : '-',
                            tipo: agencia.numero_subagencia
                                ? 'Subagencia'
                                : 'agencia',
                            estado: agencia.estado,
                            fechaDesde: agencia.titular
                                ? agencia.titular.persona_detalle.fecha_creacion.split(
                                      ' '
                                  )[0]
                                : '-',
                            fechaHasta: agencia.fecha_hasta
                                ? agencia.fecha_hasta.split('T')[0]
                                : '-',
                        };
                        agenciasTotales.push(ag);
                    });
                });
                i++;
            } catch (error) {
                console.info(error);
            }
        } while (i < n);
        i = 0;
        esperarArrayDatos(agenciasTotales, isExcel);
    };

    //Exporta los datos del historia en formato pdf
    const exportToExcel = (agencias) => {
        // Crea una nueva hoja de cálculo de Excel
        const workbook = XLSX.utils.book_new();

        // Convierte los datos JSON en una hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(agencias);

        // Agrega la hoja de cálculo al libro de Excel
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Historico');

        // Genera un archivo de Excel
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Crea un blob a partir del buffer de Excel
        const excelBlob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });


        saveAs(excelBlob, 'historico_de_agencias.xlsx');
        setIsLoading(false);
    };

    //Exporta los datos del historial para transformarlo en pdf
    function convertToPDF(agencias) {
        // setLoading(true);
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        const historialOrdenado = agencias.sort((a, b) => {
            const fechaA = new Date(a.fecha_sorteo);
            const fechaB = new Date(b.fecha_sorteo);
            return fechaA - fechaB;
        });

        const headers = Object.keys(historialOrdenado[0]); // Obtener las propiedades del primer objeto del JSON como encabezados de la tabla

        const body = agencias.map((data) => Object.values(data)); // Obtener los valores de cada objeto del JSON

        const table = {
            headerRows: 1,
            widths: headers.map(() => 'auto'),
            body: [
                headers, // Encabezados de la tabla
                ...body, // Datos del JSON
            ],
        };

        const imageUrl = logoUrl; // Replace with the actual image URL or file path
        const imageSize = [200, 100];

        const documentDefinition = {
            pageOrientation: 'landscape',
            content: [
                {
                    alignment: 'center', // Center-align the container
                    margin: [0, 50], // Add top margin to push it down a bit
                    image: imageUrl,
                    width: imageSize[0],
                    height: imageSize[1],
                },
                { text: 'Registro de Titulares', style: 'header' },
                { text: 'Titulares:', style: 'subheader' },
                {
                    table: table,
                },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 5],
                },
            },
        };
        agencias = [];
        pdfMake
            .createPdf(documentDefinition)
            .download('historico_de_agencias.pdf');
        setIsLoading(false);
    }



    const handleTablaEstadoChange = (estado) => {
        setEstado(
            tablaEstado.filter((x) => x.id === parseInt(estado))[0].detalle
        );
    };

    const handleTablaTipoChange = (tipo) => {
        setTipo(tablaTipo.filter((x) => x.id === parseInt(tipo))[0].detalle);
    };

    const handleClickClear = () => {
        setEstado({ estado: ' ' });
        setTipo({ detalle: '' });
        setFechaDesde({ fechaMuestra: null, fechaComparar: null });
        setFechaHasta({ fechaMuestra: null, fechaComparar: null });
        datetimeRefHasta.current.setState({ inputValue: '' });
        datetimeRefDesde.current.setState({ inputValue: '' });
        setColumnFilters([]);
    };

    const handleView = async (row) => {
        cargarTitulares();
        setIsLoadingView(true);
        setTitularAgenciaView('');
        setDomicilioComercialView('');
        setLocalidadView({});
        setAgenciaView({});
        // try {
        const agencia = await ConsultasAPI.ObtenerObjeto(URL_AGENCIA, row.id);
        setAgenciaView(agencia.data);
        const titularAgencia = await ConsultasAPI.ObtenerObjeto(
            URL_TITULARAGENCIA + 'busqueda/',
            agencia.data.id
        );
        if (titularAgencia.request.status === 204) {
        } else {
            const response3 = await ConsultasAPI.ObtenerObjeto(
                URL_TITULAR,
                titularAgencia.data.titular
            );
            setTitularAgenciaView(response3.data);
            setTitularAgenciaView(response3.data);
        }
        const domicilio = await ConsultasAPI.ObtenerObjeto(
            URL_DOMICILIOCOMERCIAL + 'busqueda/',
            agencia.data.id
        );
        if (domicilio.request.status === 204) {
        } else {
            setDomicilioComercialView(domicilio.data);
        }
        const response7 = await ConsultasAPI.ObtenerObjeto(
            URL_RESOLUCION + 'listResoluciones/',
            agencia.data.id
        );
        if (response7.request.status !== 204) {
            const archivoRes = await ConsultasAPI.ObtenerObjeto(
                URL_ARCHIVORESOLUCION + 'busqueda/',
                response7.data[0].id
            );
            const archivo = await ConsultasAPI.ObtenerObjeto(
                URL_ARCHIVO,
                archivoRes.data.archivo
            );
            setDataResolucion([]);
            if (response7.request.status === 200) {
                let resoluciones = response7.data;
                let datosTablaResol = [];
                resoluciones.forEach((resolucion) => {
                    let datosResolucion = {
                        id: resolucion.id,
                        expediente: resolucion.expediente,
                        resolucion: resolucion.resolucion,
                        fechaResolucion: resolucion.fecha_creacion,
                        fechaNotificacion: resolucion.fecha_notificacion,
                        motivo: resolucion.tipo_resolucion,
                        archivo: archivo.data.path,
                    };
                    datosTablaResol.push(datosResolucion);
                });
                setDataResolucion(datosTablaResol);
            }
        }
        const colaborador = await ConsultasAPI.ObtenerObjeto(
            URL_COLABORADOR + 'listColaboradores/',
            agencia.data.id
        );
        if (colaborador.request.status === 204) {
            setColaboradorView('');
            setDataColaboradores([]);
        } else {
            let datosColaborador = [];
            colaborador.data.forEach((colaborador) => {
                datosColaborador.push({
                    id: colaborador.id,
                    nombreYapellido:
                        colaborador.persona_detalle.apellido +
                        ', ' +
                        colaborador.persona_detalle.nombre,
                    documento: colaborador.persona_detalle.documento,
                    fechaAlta: colaborador.persona_detalle.fecha_creacion,
                    fechaActualizacion: '-',
                    estado: '-',
                });
            });
            setDataColaboradores(datosColaborador);
        }
        setTituloModal('Ver');
        setIsLoadingView(false);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        cargarArchivo();
        setOpcionesTitulares([]);
        setShowModal(false);
    };
    const handleOpenModal = () => {
        setTituloModal('Nuevo');
        setTipoResolucion('ALTA');
        setShowModal(true);
        cargarTitulares();
    };

    const handleOpenEditModal = async (row) => {
        setIsLoadingView(true);
        setTitularAgenciaView('');
        setDomicilioComercialView('');
        setLocalidadView({});
        cargarTitulares();
        setAgenciaView({});
        const agencia = await ConsultasAPI.ObtenerObjeto(URL_AGENCIA, row.id);
        setAgenciaView(agencia.data);
        const titularAgencia = await ConsultasAPI.ObtenerObjeto(
            URL_TITULARAGENCIA + 'busqueda/',
            agencia.data.id
        );
        if (titularAgencia.request.status === 204) {
        } else {
            const response3 = await ConsultasAPI.ObtenerObjeto(
                URL_TITULAR,
                titularAgencia.data.titular
            );
            setTitularAgenciaView(response3.data);
        }
        const domicilio = await ConsultasAPI.ObtenerObjeto(
            URL_DOMICILIOCOMERCIAL + 'busqueda/',
            agencia.data.id
        );
        if (domicilio.request.status === 204) {
        } else {
            setDomicilioComercialView(domicilio.data);
        }
        const response7 = await ConsultasAPI.ObtenerObjeto(
            URL_RESOLUCION + 'listResoluciones/',
            agencia.data.id
        );
        if (response7.request.status === 204) {
            setDataResolucion([]);
        } else {
            const archivoRes = await ConsultasAPI.ObtenerObjeto(
                URL_ARCHIVORESOLUCION + 'busqueda/',
                response7.data[0].id
            );
            if (archivoRes.status === 200) {
                const archivo = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVO,
                    archivoRes.data.archivo
                );
                setDataResolucion([]);
                if (response7.request.status === 200) {
                    let resoluciones = response7.data;
                    let datosTablaResol = [];
                    resoluciones.forEach((resolucion) => {
                        let datosResolucion = {
                            id: resolucion.id,
                            expediente: resolucion.expediente,
                            resolucion: resolucion.resolucion,
                            fechaResolucion: resolucion.fecha_creacion,
                            fechaNotificacion: resolucion.fecha_notificacion,
                            motivo: resolucion.tipo_resolucion,
                            archivo: archivo.data.path,
                        };
                        datosTablaResol.push(datosResolucion);
                    });
                    setDataResolucion(datosTablaResol);
                }
            }
        }
        const colaborador = await ConsultasAPI.ObtenerObjeto(
            URL_COLABORADOR + 'listColaboradores/',
            agencia.data.id
        );
        if (colaborador.request.status === 204) {
            setColaboradorView('');
            setDataColaboradores([]);
        } else {
            let datosColaborador = [];
            colaborador.data.forEach((colaborador) => {
                datosColaborador.push({
                    id: colaborador.id,
                    nombreYapellido:
                        colaborador.persona_detalle.apellido +
                        ', ' +
                        colaborador.persona_detalle.nombre,
                    documento: colaborador.persona_detalle.documento,
                    fechaAlta: colaborador.persona_detalle.fecha_creacion,
                    fechaActualizacion: '-',
                    estado: '-',
                });
            });
            setDataColaboradores(datosColaborador);
        }
        setTituloModal('Editar');
        setIsLoadingView(false);
        setShowModal(true);
    };

    // Solo carga los titulares que no tienen agencias asignadas
    const cargarTitulares = async () => {
        const response = await ConsultasAPI.ListarObjetos(
            URL_TITULARAGENCIA + 'titularesSinAg/'
        );
        response.data.forEach((titular) => {
            let op = {
                id: titular.id,
                label:
                    titular.persona_detalle.cuit +
                    '-' +
                    titular.persona_detalle.apellido +
                    ' ' +
                    titular.persona_detalle.nombre,
            };
            opcionesTitulares.push(op);
        });
    };

    const handleOpenDocumentacionAgencia = async (row, ag) => {
        var agencia;
        if (row) {
            agencia = row.agencia + ',' + row.subagencia;
        } else {
            agencia = ag;
        }
        const documentacionAgencia = await ConsultasAPI.ObtenerObjeto(
            URL_ARCHIVODOCUMENTACION + 'obtenerDocumentacionAgencia/',
            agencia
        );
        if (documentacionAgencia.status !== 204) {
            setDataDocumentacionAgencia(documentacionAgencia.data);
        } else {
            const agencia = await ConsultasAPI.ObtenerObjeto(
                URL_AGENCIA + 'busquedaId/',
                row.id
            );
            setDataAgencia(agencia.data);
        }
        setModalDetalle(true);
    };

    const handleCloseModalDetalle = async (event) => {
        event.preventDefault();
        setModalDetalle(false);
    };
    const [showModalNovedades, setShowModalNovedades] = useState(false);
    const [showModalEstadistica, setShowModalEstadistica] = useState(false);

    const handleOpenModalNovedades = async () => {
        setShowModalNovedades(true);
    };
    const handleCloseModalNovedades = async () => {
        setShowModalNovedades(false);
    };
    const handleOpenEstadistica = async () => {
        setShowModalEstadistica(true);
    };
    const handleCloseEstadistica = async () => {
        setShowModalEstadistica(false);
    };

    return (
        <Container fluid className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Agencias</h1>
                </Col>
                <Col className="d-flex justify-content-end m-3 ">
                    {AuthenticationHelper.getRol() &&
                    (rolUser === 'DOCUMENTACION' ||
                        rolUser === 'GERENCIA-QUINIELA' ||
                        rolUser === 'CONTADURIA' ||
                        rolUser === 'ADMINISTRADOR') ? (
                        <>

                            {rolUser === 'GERENCIA-QUINIELA' ||
                            rolUser === 'ADMINISTRADOR' ? (
                                <Button
                                    className="btn botonCPA me-3"
                                    style={{
                                        alignContent: 'center',
                                        margin: 15,
                                        float: 'right',
                                    }}
                                    onClick={handleOpenModalDocumentacionMasiva}
                                >
                                    DOCUMENTACION
                                </Button>
                            ) : null}

                            {rolUser === 'GERENCIA-QUINIELA' ||
                            rolUser === 'ADMINISTRADOR' ? (
                                <Button
                                    className="btn botonCPA me-3"
                                    style={{
                                        alignContent: 'center',
                                        margin: 15,
                                        float: 'right',
                                    }}
                                    onClick={handleOpenEstadistica}
                                >
                                    Estadistica
                                </Button>
                            ) : null}
                            <Button
                                className="btn botonCPA me-3"
                                style={{
                                    alignContent: 'center',
                                    margin: 15,
                                    float: 'right',
                                }}
                                onClick={handleOpenModalNovedades}
                            >
                                Novedades
                            </Button>
                            {rolUser === 'GERENCIA-QUINIELA' ||
                                rolUser === 'ADMINISTRADOR' ? (
                                <Link
                                    className="btn botonCPA me-3"
                                    to={window.location + '/asignacion-agencia'}
                                    style={{
                                        alignContent: 'center',
                                        margin: 15,
                                        float: 'right',
                                    }}
                                >
                                    <FaList className="iconAntD" />
                                    Reasignar Agencias
                                </Link>)
                                :
                                null
                            }
                            <Link
                                className="btn botonCPA me-3"
                                onClick={handleOpenModal}
                                style={{
                                    alignContent: 'center',
                                    margin: 15,
                                    float: 'right',
                                }}
                            >
                                <FaPlusCircle className="iconAntD" />
                                Nuevo
                            </Link>
                        </>
                    ) : null}
                </Col>
            </Row>
            <Card className="m-3">
                <Card.Body>
                    <Row
                        className="mb-3"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '2%',
                        }}
                    >
                        <Form.Group as={Col} md="2">
                            <Form.Label>Fecha Desde</Form.Label>
                            <Datetime
                                timeFormat={false}
                                dateFormat="DD/MM/YYYY"
                                inputProps={{
                                    placeholder: fechaDesde.fechaMuestra,
                                    readOnly: true,
                                }}
                                value={null}
                                ref={datetimeRefDesde}
                                onChange={(momentDate) => {
                                    const fechaMuestra =
                                        momentDate.format('DD/MM/YYYY');
                                    const fechaComparar =
                                        momentDate.format('YYYY-MM-DD');
                                    setFechaDesde({
                                        fechaMuestra: fechaMuestra,
                                        fechaComparar: fechaComparar,
                                    });
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Fecha Hasta</Form.Label>
                            <Datetime
                                timeFormat={false}
                                dateFormat="DD/MM/YYYY"
                                inputProps={{
                                    placeholder: fechaHasta.fechaMuestra,
                                    readOnly: true,
                                }}
                                ref={datetimeRefHasta}
                                value={null}
                                onChange={(momentDate) => {
                                    const fechaMuestra =
                                        momentDate.format('DD/MM/YYYY');
                                    const fechaComparar =
                                        momentDate.format('YYYY-MM-DD');
                                    setFechaHasta({
                                        fechaMuestra: fechaMuestra,
                                        fechaComparar: fechaComparar,
                                    });
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Estado:</Form.Label>
                            <Form.Select
                                multiple={false}
                                value={estado.estado}
                                onChange={(event) => {
                                    handleTablaEstadoChange(event.target.value);
                                }}
                            >
                                <option hidden>Elegir Estado</option>
                                {tablaEstado.length > 0
                                    ? tablaEstado.map((estado) => (
                                          <option
                                              key={estado.id}
                                              value={estado.id}
                                          >
                                              {estado.detalle}
                                          </option>
                                      ))
                                    : null}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Tipo:</Form.Label>
                            <Form.Select
                                value={tipo.detalle}
                                multiple={false}
                                onChange={(event) => {
                                    handleTablaTipoChange(event.target.value);
                                }}
                            >
                                <option hidden>Elegir Tipo</option>
                                {tablaTipo.length > 0
                                    ? tablaTipo.map((tipo) => (
                                          <option key={tipo.id} value={tipo.id}>
                                              {tipo.detalle}
                                          </option>
                                      ))
                                    : null}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Link
                                className="btn botonCPA me-3"
                                onClick={handleClickClear}
                                style={{
                                    alignContent: 'center',
                                    margin: 15,
                                    float: 'right',
                                }}
                            >
                                <FaEraser className="iconAntD" /> Limpiar
                            </Link>
                        </Form.Group>
                    </Row>
                </Card.Body>
                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={data}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: '0',
                            border: '1px dashed #e0e0e0',
                        },
                    }}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                    theme.palette.background.default,
                                    0.1
                                ),
                            },
                            fontFamily: 'Roboto, sans-serif', // Configuración de la tipografía para las filas pares
                        }),
                    }}
                    initialState={{
                        columnVisibility: { id: false },
                        showColumnFilters: true,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableSorting={false}
                    displayColumnDefOptions={{
                        'mrt-row-actions': { size: 10 },
                    }} //change width of actions column to 300px
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => {
                        return (
                            <div className="d-flex">
                                <IconButton
                                    onClick={() => {
                                        handleView(row.original);
                                    }}
                                >
                                    <Visibility />
                                </IconButton>
                                {AuthenticationHelper.getRol() &&
                                    (rolUser === 'ADMINISTRADOR' ||
                                        rolUser === 'GERENCIA-QUINIELA' ||
                                        rolUser === 'DOCUMENTACION') && (
                                        <>
                                            <IconButton
                                                onClick={() => {
                                                    handleOpenEditModal(
                                                        row.original
                                                    );
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                title="Ver Documentación"
                                                onClick={() => {
                                                    handleOpenDocumentacionAgencia(
                                                        row.original
                                                    );
                                                    setDatoAgencia(row.original)

                                                }}
                                            >
                                                <LibraryBooksIcon />
                                            </IconButton>
                                        </>
                                    )}
                                {AuthenticationHelper.getRol() ===
                                    'AUDITORIA' ||
                                AuthenticationHelper.getRol() ===
                                    'CONTADURIA' ? (
                                    <IconButton
                                        title="Ver Documentación"
                                        onClick={() => {
                                            handleOpenDocumentacionAgencia(
                                                row.original
                                            );
                                            setDatoAgencia(row.original)
                                        }}
                                    >
                                        <LibraryBooksIcon />
                                    </IconButton>
                                ) : null}
                            </div>
                        );
                    }}
                    manualPagination
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    localization={MRT_Localization_ES}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                    manualFiltering //turn off client-side filtering
                    onColumnFiltersChange={(value) => {
                        setColumnFilters(value);
                    }} //hoist internal columnFilters state to your state
                    state={{
                        columnFilters,
                        pagination,
                    }}
                />
            </Card>
            <NuevaAgencia
                onClose={handleCloseModal}
                show={showModal}
                titulo={tituloModal}
                tipoResolucion={tipoResolucion}
                opcionesTitulares={opcionesTitulares}
                datosTitularAgencia={
                    tituloModal !== 'Nuevo' ? titularAgenciaview : null
                }
                datosDomicilio={
                    tituloModal !== 'Nuevo' ? domicilioComercialview : null
                }
                datosLocalidad={tituloModal !== 'Nuevo' ? localidadview : null}
                datosResolucion={
                    tituloModal !== 'Nuevo' ? resolucionview : null
                }
                datosAgencia={tituloModal !== 'Nuevo' ? agenciaview : null}
                datosColaborador={
                    tituloModal !== 'Nuevo' ? colaboradorview : null
                }
                datosColaboradorTabla={
                    tituloModal !== 'Nuevo' ? dataColaborador : null
                }
                recargarTabla={cargarArchivo}
                datosResolucionTabla={
                    tituloModal !== 'Nuevo' ? dataResolucion : null
                }
            />
            <Row className="text-center">
                <section className="d-flex justify-content-end my-3">
                    <Link
                        className="btn botonCPA me-3"
                        onClick={handleClickExcel}
                    >
                        <FaFileDownload className="iconAntD" />
                        Descargar Excel
                    </Link>
                    <Link
                        className="btn botonCPA-PDF me-3"
                        onClick={handleClickPDF}
                    >
                        <FaFileDownload className="iconAntD" />
                        Descargar PDF
                    </Link>

                    <BtnVolver />
                </section>
            </Row>

            <Modal centered show={isLoading || isLoadingView}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    {isLoading ? (
                        <p>Descargando Archivo...</p>
                    ) : isLoadingView ? (
                        <p>Cargando Datos...</p>
                    ) : null}
                </Modal.Body>
            </Modal>
             <DetalleDeduccion
                show={showModalDetalle}
                data={dataDocumentacionAgencia}
                dataAgencia={dataAgencia}
                close={handleCloseModalDetalle}
                titulo={'Agencia'}
                cargar={handleOpenDocumentacionAgencia}

                datoAgencia={datoAgencia}
            />
            <Novedades
                show={showModalNovedades}
                close={handleCloseModalNovedades}
                fecha={moment()}
            />
            <Estadistica
                show={showModalEstadistica}
                close={handleCloseEstadistica}
                fecha={moment()}
            />

            <ModalDocumentacionMasiva
                onClose={handleCloseModalDocumentacionMasiva}
                show={showModalDocumentacionMasiva}
                titulo={tituloModalDocumentacionMasiva}
                //periodo={fechaCierreModal}
                //idFecha={idFecha}
            />



        </Container>
    );
};
export default Agencias;
