import React, { useEffect, useState } from 'react';
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Spinner,
    Modal,
    Form,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
// import { Select } from "antd";
// import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
// import { Form } from "antd";
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
// import moment from "moment";
import BtnVolver from '../../../common/BtnVolver';
import CSVReader from '../../exportaciones/CSVReader';
// import CSVReaderMultiple from "../../exportaciones/CSVReaderMultiple";
import {
    AiFillCheckCircle,
    AiOutlineUpload,
    AiFillCloseCircle,
    AiOutlineCluster,
} from 'react-icons/ai';
import moment from 'moment';
import ConsultasAPI from '../../../../shared/helpers/consultasAPI';
import Notificaciones from '../../../../shared/helpers/notificacionesToast';


const CargarSorteo = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [fechaSorteo, setFecha] = useState({
        fechaMuestra: moment().format('DD/MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const [tipoSorteo, setTipoSorteo] = useState('');
    const [sorteosArchivo, setSorteosArchivo] = useState([]);
    const [sorteosTabla, setSorteosTabla] = useState([]);
    const [message, setMessage] = useState(null);
    const [showCSV, setShowCSV] = useState(false);
    const [tiposFaltantes, setTiposFaltantes] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [api_smartoplay, SetApiSmartoplay] = useState(false);
    /* eslint-disable */
    const [tiposFaltantesAyer, setTiposFaltantesAyer] = useState([]);
    const URL_JUGADAS = window.API_ROUTES.JUGADAS;

    const columns = [
        {
            header: 'Agencia de Venta',
            accessorKey: 'agenciaDeVenta',
            size: 30,
        },
        {
            header: 'Subagencia de Venta',
            accessorKey: 'subagenciaDeVenta',
            size: 30,
        },
        {
            header: 'Monto',
            accessorKey: 'montoTotalSubagencia',
            size: 30,
        },
        {
            header: 'Tickets Emitidos',
            accessorKey: 'ticketsEmitidos',
            size: 30,
        },
        {
            header: 'Tickets Anulados',
            accessorKey: 'ticketsAnulados',
            size: 30,
        },
        {
            header: 'Tipo de sorteo',
            accessorKey: 'tipoSorteo',
            size: 30,
        },
        {
            header: 'Fecha de Sorteo',
            accessorKey: 'fechaSorteo',
            size: 30,
        },
    ];

    useEffect(() => {
        obtenerJugadas();
    }, []);

    useEffect(() => {
        obtenerTipoSorteo();
    }, [fechaSorteo]);

    const obtenerTipoSorteo = async () => {
        const jugadas = await ConsultasAPI.ObtenerObjeto(
            URL_JUGADAS + 'obtenerJugadasFaltantesDelDiaActual/',
            fechaSorteo.fechaComparar
        );
        setTiposFaltantes(jugadas.data);
    };

    const obtenerJugadas = async () => {
        const jugadas = await ConsultasAPI.ObtenerObjeto(
            URL_JUGADAS + 'obtenerJugadasFaltantesDelMes/',
            1
        );
        var tipos = 'Faltan los sorteos: ';
        const jugadasAyer = await ConsultasAPI.ObtenerObjeto(
            URL_JUGADAS + 'obtenerJugadasFaltantesDelMesAnterior/',
            1
        );
        if (jugadasAyer.data.length > 0) {
            for (const jugada of jugadasAyer.data) {
                const jugada_cadena = jugada.split('-');
                tipos =
                    tipos + jugada_cadena[0] + ' de ' + jugada_cadena[1] + ', ';
            }
        }
        if (jugadas.data.length > 0) {
            for (const jugada of jugadas.data) {
                const jugada_cadena = jugada.split('-');
                tipos =
                    tipos + jugada_cadena[0] + ' de ' + jugada_cadena[1] + ', ';
            }
        }
        tipos = tipos.slice(0, -2);
        if (jugadasAyer.data.length > 0 || jugadas.data.length > 0) {
            setMensaje(tipos);
        } else {
            setMensaje('');
        }
    };

    const onConfirm = async () => {
        setMessage('Cargando sorteos...');
        setIsLoading(true);
        var data = sorteosTabla;
        await ConsultasAPI.ObtenerObjeto(URL_JUGADAS + 'esperarResultado/', 1);
        ConsultasAPI.CrearObjeto(URL_JUGADAS, data)
            .then(() => {
                setIsLoading(false);
                // navigate("/liquidaciones/sorteos");
                window.location.href = '/liquidaciones/sorteos';
            })
            .catch((error) => {
                Notificaciones.notificacion(error.response.data.error);
                setIsLoading(false);
            });
        await ConsultasAPI.ObtenerObjeto(
            URL_JUGADAS + 'esperarResultado/',
            1
        );
        data = sorteosTabla;
    };

    const handleFileUploaded = (fileData) => {
        //Ordenar sorteos por agencia y subagencia
        setMessage('Subiendo archivo...');
        setIsLoading(true);
        fileData.sort((a, b) => {
            var agenciaA = null;
            var subagenciaA = null;
            var agenciaB = null;
            var subagenciaB = null;

            if (
                Number.isInteger(parseInt(a['Agencia de Venta (A)'])) &&
                Number.isInteger(parseInt(a['Subagencia de Venta (B)']))
            ) {
                agenciaA = parseInt(a['Agencia de Venta (A)']);
                subagenciaA = parseInt(a['Subagencia de Venta (B)']);
                agenciaB = parseInt(b['Agencia de Venta (A)']);
                subagenciaB = parseInt(b['Subagencia de Venta (B)']);
            } else {
                agenciaA = parseInt(a['Agencia de Venta']);
                subagenciaA = parseInt(a['Subagencia de Venta']);
                agenciaB = parseInt(b['Agencia de Venta']);
                subagenciaB = parseInt(b['Subagencia de Venta']);
            }

            if (agenciaA < agenciaB) {
                return -1;
            } else if (agenciaA > agenciaB) {
                return 1;
            }

            if (subagenciaA < subagenciaB) {
                return -1;
            } else if (subagenciaA > subagenciaB) {
                return 1;
            }
            return 0;
        });
        SetApiSmartoplay(false);
        setSorteosArchivo([...sorteosArchivo, fileData]);
        setIsLoading(false);
    };

    const cargarTabla = () => {
        setMessage('Procesando datos...');
        setIsLoading(true);

        if (sorteosArchivo.length > 0) {
            const sorteos = [];
            const ticketsSet = new Set();
            sorteosArchivo.forEach((sorteo) => {
                // let i=0;
                let agenciaDeVenta = null;
                let subagenciaDeVenta = null;
                let agenciaDeVentaNueva = null;
                let subagenciaDeVentaNueva = null;
                let jugadasSubagencia = [];
                let montoTotalSubagencia = [];
                let ticketsEmitidos = 0;
                let ticketsAnulados = 0;
                const fechaSorteo = sorteo[0]['Fecha de sorteo'];
                const tipoSorteo = sorteo[0]['Tipo de sorteo'];
                let añadioUltima = false;
                sorteo.forEach((jugada) => {
                    const ticket = jugada['N° de ticket'];
                    const monto = parseInt(jugada['Monto']);
                    const horaDeAnulacion =
                        jugada['Hora Anulación'] !== ''
                            ? jugada['Hora Anulación']
                            : null;
                    if (
                        Number.isInteger(
                            parseInt(jugada['Agencia de Venta (A)'])
                        ) &&
                        Number.isInteger(
                            parseInt(jugada['Subagencia de Venta (B)'])
                        )
                    ) {
                        agenciaDeVentaNueva = parseInt(
                            jugada['Agencia de Venta (A)']
                        );
                        subagenciaDeVentaNueva = parseInt(
                            jugada['Subagencia de Venta (B)']
                        );
                    } else {
                        agenciaDeVentaNueva = parseInt(
                            jugada['Agencia de Venta']
                        );
                        subagenciaDeVentaNueva = parseInt(
                            jugada['Subagencia de Venta']
                        );
                    }
                    if (
                        //Si continuo con la misma agencia y sub
                        subagenciaDeVenta === subagenciaDeVentaNueva &&
                        agenciaDeVenta === agenciaDeVentaNueva
                    ) {
                        añadioUltima = false;
                        if (!horaDeAnulacion) {
                            //Sumo monto y ticket
                            montoTotalSubagencia += monto;
                            if (!ticketsSet.has(ticket)) {
                                ticketsEmitidos += 1;
                                ticketsSet.add(ticket);
                            }
                        } else if (!ticketsSet.has(ticket)) {
                            ticketsAnulados += 1;
                            ticketsSet.add(ticket);
                        }
                        jugadasSubagencia.push({ monto, horaDeAnulacion });
                    } else {
                        añadioUltima = true;

                        //Si no añado la anterior y empiezo a contar la nueva
                        if (agenciaDeVenta) {
                            sorteos.push({
                                fechaSorteo,
                                tipoSorteo,
                                api_smartoplay,
                                agenciaDeVenta,
                                subagenciaDeVenta,
                                jugadasSubagencia,
                                montoTotalSubagencia,
                                ticketsEmitidos,
                                ticketsAnulados,
                            });
                            ticketsEmitidos = 0;
                            ticketsAnulados = 0;
                        }

                        if (
                            Number.isInteger(
                                parseInt(jugada['Agencia de Venta (A)'])
                            ) &&
                            Number.isInteger(
                                parseInt(jugada['Subagencia de Venta (B)'])
                            )
                        ) {
                            agenciaDeVenta = parseInt(
                                jugada['Agencia de Venta (A)']
                            );
                            subagenciaDeVenta = parseInt(
                                jugada['Subagencia de Venta (B)']
                            );
                        } else {
                            agenciaDeVenta = parseInt(
                                jugada['Agencia de Venta']
                            );
                            subagenciaDeVenta = parseInt(
                                jugada['Subagencia de Venta']
                            );
                        }
                        jugadasSubagencia = [{ monto, horaDeAnulacion }];
                        if (!horaDeAnulacion) {
                            if (!ticketsSet.has(ticket)) {
                                ticketsEmitidos += 1;
                                ticketsSet.add(ticket);
                            }
                            montoTotalSubagencia = monto;
                        } else {
                            if (!ticketsSet.has(ticket)) {
                                ticketsAnulados += 1;
                                ticketsSet.add(ticket);
                            }
                            montoTotalSubagencia = 0;
                        }
                    }
                });
                if (!añadioUltima && agenciaDeVenta) {
                    sorteos.push({
                        fechaSorteo,
                        tipoSorteo,
                        api_smartoplay,
                        agenciaDeVenta,
                        subagenciaDeVenta,
                        jugadasSubagencia,
                        montoTotalSubagencia,
                        ticketsEmitidos,
                        ticketsAnulados,
                    });
                }
            });
            setSorteosTabla(sorteos.flat());
            setIsLoading(false);
        }
    };

    const handleRemoveFile = () => {
        setSorteosArchivo([]);
        setSorteosTabla([]);
    };

    const handleConnectSmartoplay = async (event) => {
        event.preventDefault();
        const fecha = fechaSorteo.fechaComparar;
        const tipo = tipoSorteo.split(' ')[tipoSorteo.split(' ').length - 1];
        if (fecha !== '' && tipo !== '') {
            const data = fecha + '*' + tipo;
            setMessage('Trayendo datos del servidor y Procesando...');
            setIsLoading(true);
            try {
                await ConsultasAPI.ObtenerObjeto(
                    URL_JUGADAS + 'jugadasSmartoplay/',
                    data
                ).then((response) => {
                    setIsLoading(false);
                    setShowCSV(false);
                    SetApiSmartoplay(true);
                    if (response.data !== 0) {
                        setSorteosTabla(response.data.flat());
                    } else {
                        SetApiSmartoplay(false);
                        Notificaciones.notificacion(
                            'No existen registros de jugadas para el sorteo seleccionado'
                        );
                    }
                });
            } catch (error) {
                console.error(error);
                SetApiSmartoplay(false);
                setIsLoading(false);
                setShowCSV(true);
                Notificaciones.notificacion(
                    'Hubo un error al intentar conectarse al servidor'
                );
            }
        } else {
            SetApiSmartoplay(false);
            Notificaciones.notificacion(
                'Se deben elegir fecha y tipo del sorteo para hacer una busqueda'
            );
        }
    };


    const handleFechaChange = (momentDate) => {
        const fechaMuestra = momentDate.format('DD/MM/YYYY');
        const fechaComparar = momentDate.format('YYYY-MM-DD');
        setFecha({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
    };

    var valid = function (current) {
        if (tiposFaltantesAyer.length === 0) {
            const today = moment();
            const isSunday = current.day() === 0;
            return current.isBefore(today) && !isSunday;
        } else {
            const today = moment().subtract(1, 'days');
            const isSunday = current.day() === 0;
            return current.isBefore(today) && !isSunday;
        }
    };

    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Cargar Sorteos</h1>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Form onSubmit={handleConnectSmartoplay}>
                        <Row>
                            <div className="d-flex justify-content-center mt-3">
                                <h4 className="me-2">
                                    Conectarse al Servidor:
                                </h4>
                            </div>
                        </Row>
                        <Row className="m-2">
                            <Form.Group as={Col} md="3">
                                <Form.Label>Seleccionar Fecha:</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    style={{ width: '100%', height: '32px' }}
                                    dateFormat="DD/MM/YYYY"
                                    inputProps={{
                                        readOnly: true,
                                        placeholder: fechaSorteo.fechaMuestra,
                                    }}
                                    value={fechaSorteo.fechaMuestra}
                                    onChange={handleFechaChange}
                                    isValidDate={valid}
                                    closeOnSelect={true}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Seleccionar Tipo:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={tipoSorteo}
                                    onChange={(event) => {
                                        setTipoSorteo(event.target.value);
                                    }}
                                    required
                                >
                                    <option value="" hidden>
                                        Elegir Tipo de Sorteo
                                    </option>
                                    {tiposFaltantesAyer.length > 0
                                        ? tiposFaltantesAyer.map((tipo) => (
                                            <option
                                                key={tipo}
                                                value={tipo.toLowerCase()}
                                            >
                                                {tipo}
                                            </option>
                                        ))
                                        : tiposFaltantes.length > 0
                                            ? tiposFaltantes.map((tipo) => (
                                                <option
                                                    key={tipo}
                                                    value={tipo.toLowerCase()}
                                                >
                                                    {tipo}
                                                </option>
                                            ))
                                            : null}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="5" className="m-3">
                                {showCSV ? (
                                    <Button
                                        className="btn botonCPA mb-3"
                                        type="submit"
                                        style={{
                                            backgroundColor: '#EC1B23',
                                            borderColor: '#EC1B23',
                                            width: 'max-content',
                                            float: 'inline-end',
                                            marginTop: '3%',
                                        }}
                                    >
                                        <AiOutlineCluster className="iconAntD" />{' '}
                                        Reintentar conectar al Servidor
                                    </Button>
                                ) : (
                                    <Button
                                        className="btn botonCPA mb-3"
                                        type="submit"
                                        style={{
                                            float: 'inline-end',
                                            marginTop: '3%',
                                        }}
                                    >
                                        <AiOutlineCluster className="iconAntD" />{' '}
                                        Obtener datos del Servidor
                                    </Button>
                                )}
                            </Form.Group>
                            {mensaje.length > 0 ? (
                                <Form.Label
                                    style={{ color: 'red', width: '100%' }}
                                >
                                    {mensaje}
                                </Form.Label>
                            ) : null}
                        </Row>
                        <Row className="d-flex justify-content-end"></Row>
                    </Form>
                    {showCSV ? (
                        <>
                            <Row>
                                <div className="d-flex justify-content-center mt-3">
                                    <h4 className="me-2">Subir Archivo:</h4>
                                </div>
                            </Row>

                            <Row>
                                <div className="d-flex justify-content-center mt-3">
                                    <div
                                        className="mb-3"
                                        style={{ width: '63%', height: '80%' }}
                                    >
                                        <CSVReader
                                            onFileUploaded={handleFileUploaded}
                                            onRemoveFile={handleRemoveFile}
                                            isLoading={(loading) =>
                                                setIsLoading(loading)
                                            }
                                            showMessage={
                                                "Esperando archivo 'Detalle de Jugadas por Sorteo' en formato CSV"
                                            }
                                        />
                                    </div>
                                    {/* <div className="mb-3" style={{ width: "63%", height: "80%" }}>
                <CSVReaderMultiple
                onFileUploaded={handleFileUploaded}
                onRemoveFile={handleRemoveFile}
                />
              </div> */}
                                </div>
                            </Row>
                            <Row>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn botonCPA m-3"
                                        onClick={cargarTabla}
                                    >
                                        <AiOutlineUpload className="iconAntD" />
                                        PROCESAR
                                    </Button>
                                </div>
                            </Row>
                        </>
                    ) : null}
                </Card>
            </Row>
            <Row className="mt-4">
                <Card>
                    <Row>
                        <div className="my-3">
                            <MaterialReactTable
                                columns={columns}
                                data={sorteosTabla}
                                initialState={{
                                    columnVisibility: {
                                        id: false,
                                        acciones: false,
                                    },
                                }}
                                enableRowSelection={false}
                                enableColumnOrdering={false}
                                enableGlobalFilter={true}
                                localization={MRT_Localization_ES}
                                enableColumnActions={false}
                            />
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-center">
                                <Button
                                    className="btn botonCPA mb-2"
                                    onClick={handleRemoveFile}
                                >
                                    <AiFillCloseCircle className="iconAntD" />{' '}
                                    CANCELAR
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-center">
                                <Button
                                    className="btn botonCPA mb-2"
                                    onClick={onConfirm}
                                >
                                    <AiFillCheckCircle className="iconAntD" />{' '}
                                    CONFIRMAR
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>

                <section className="d-flex justify-content-end my-3">
                    <BtnVolver route="/liquidaciones/sorteos" />
                </section>
            </Row>
            <Modal centered show={isLoading} onHide={() => setIsLoading(false)}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>{message}</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default CargarSorteo;
