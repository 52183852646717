/* eslint-disable */
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Modal,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Notificaciones from "../../../shared/helpers/notificacionesToast";

// import { Select } from "antd";
import moment from "moment";
import { ModalTitular } from "../titulares/ModalTitular";
import { useForm } from "react-hook-form";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import Swal from "sweetalert2";

//cambiar from si no funciona sin cambiarlo
import "react-datetime/css/react-datetime.css";
import { useNavigate, Link } from "react-router-dom";
import Datetime from "react-datetime";
import Select from 'react-select'

// const FormItem = Form.Item;
// const { Option } = Select;

export function ModalEditar(props) {
  const formRef = useRef(null);

  const [archivo, setArchivo] = useState(null);
  const [titulares, setTitulares] = useState(props.opcionesTitulares);

  const [añadirTitularModal, setAñadirTitularModal] = useState(false);
  const [fecha, setFecha] = useState({
    fechaMuestra: moment().format("DD/MM/YYYY"),
    fechaComparar: moment().format("YYYY-MM-DD"),
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [selectedOption, setSelectedOption] = useState("");

  const [expediente, setExpediente] = useState([]);
  const [resolucion, setResolucion] = useState([]);
  const [fechaResolucion, setFechaResolucion] = useState({
    fechaMuestra: moment().format("DD/MM/YYYY"),
    fechaComparar: moment().format("YYYY-MM-DD"),
  });
  const [fechaNotificacion, setFechaNotificacion] = useState({
    fechaMuestra: moment().format("DD/MM/YYYY"),
    fechaComparar: moment().format("YYYY-MM-DD"),
  });

  const [domicilioComercial, setDomicilioComercial] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [tipoSucursal, setTipoSucursal] = useState("");
  const [coordenadas, setCoordenadas] = useState("");
  const [tablaTipoSucursal, setTablaTipoSucursal] = useState([]);
  const [tablaZona, setTablaZona] = useState([]);
  const [zona, setZona] = useState([]);
  const [numero, setNumero] = useState("0");

  const [tipoResolucion, setTipoResolucion] = useState("");
  const [comentario, setComentario] = useState("");
  const [detallesEquipamiento, setDetallesEquipamiento] = useState([]);
  const [montoDevolver, setMontoDevolver] = useState([]);
  const [importeMulta, setImporteMulta] = useState([]);
  const [cuota, setCuota] = useState([]);
  const [reposicionEquipo, setReposicionEquipo] = useState([]);
  const [cuotaPagada, setCuotaPagada] = useState([]);
  const [descontarComision, setDescontarComision] = useState([]);
  const [tablaTipoResolucion, setTablaTipoResolucion] = useState([]);
  const [cantDias, setCantDias] = useState(0);
  const [titular, setTitular] = useState(null);

  const navigate = useNavigate();
  var currentTime = moment().utcOffset("-03:00").format("hh:mm");
  var currentDate = moment().utcOffset("-03:00").format("YYYY-MM-DD");
  const URL_ZONA = window.API_ROUTES.ZONA;
  const URL_DOMICILIOCOMERCIAL = window.API_ROUTES.DOMICILIOCOMERCIAL;
  const URL_RESOLUCION = window.API_ROUTES.RESOLUCION;
  const URL_AGENCIA = window.API_ROUTES.AGENCIA;
  const URL_TITULAR = window.API_ROUTES.TITULAR;
  const URL_PERSONA = window.API_ROUTES.PERSONA;
  const URL_TITULARAGENCIA = window.API_ROUTES.TITULARAGENCIA;
  const URL_COLABORADOR = window.API_ROUTES.COLABORADOR;
  const URL_TIPORESOLUCION = window.API_ROUTES.TIPORESOLUCION;
  const URL_ARCHIVORESOLUCION = window.API_ROUTES.ARCHIVORESOLUCION;
  const URL_ARCHIVO = window.API_ROUTES.ARCHIVO;
  const URL_TIPOSUCURSAL = window.API_ROUTES.TIPOSUCURSAL;
  const URL_LOCALIDAD = window.API_ROUTES.LOCALIDAD;
  const requiredSymbol = props.titulo !== "Ver" ? <b style={{ color: "red" }}>*</b> : "";
  const [tablaLocalidad, setTablaLocalidad] = useState([])

  const isValidDate = (current) => {
    // If fechaResolucion has a date set, compare it with the current date
    if (fechaResolucion.fechaComparar) {
      const fechaResolucionDate = new Date(fechaResolucion.fechaComparar);
      const currentDate = current.toDate();
      return currentDate > fechaResolucionDate;
    }
    return false; // No fechaResolucion set yet, allow any date
  };

  const handleNuevoTitular = (selectedId) => {
    const cargarTitulares = async () => {
      var titus = [];
      ConsultasAPI.ListarObjetos(URL_TITULARAGENCIA + "titularesSinAg/").then(
        (response) => {
          response.data.forEach((titular) => {
            let obj = {
              id: titular.id,
              label:
                titular.persona_detalle.cuit +
                "-" +
                titular.persona_detalle.apellido +
                " " +
                titular.persona_detalle.nombre,
            };
            titus.push(obj);
          });
          setTitulares(titus);
          setTitular(titus.filter((titular) => titular.id === selectedId));
        }
      );
    };
    cargarTitulares();
  };

  useEffect(() => {
    cargarLocalidades();
    if (props.show) {
      cargarZonas();
      cargarTipoResolucion();
      handleNuevoTitular(null);
      cargarTiposSucursales();
    }
  }, [props.show]);

  const crearTitularAgencia = async (titularAgencia) => {
    ConsultasAPI.CrearObjeto(URL_TITULARAGENCIA, titularAgencia)
      .then((response) => {
        console.info("Registro TitularAgencia creado:", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Error al crear el titular agencia:", error);
      });
  };

  const handleTablaTipoChange = (tipo) => {
    setTipoResolucion(
      tablaTipoResolucion.filter((x) => x.id === parseInt(tipo))[0]
    );
    if (tablaTipoResolucion.filter((x) => x.id === parseInt(tipo))[0].tipo === 'ALTA' && props.datosDomicilio) {
      setDomicilioComercial(props.datosDomicilio.calle);
      setNumero("0");
      setZona(props.datosDomicilio.zona.id);
      setCoordenadas(props.datosDomicilio.coordenadas);
      setLocalidad(props.datosDomicilio.zona.localidad_detalle.nombre);
      if (tablaTipoSucursal.length > 0) {
        setTipoSucursal(props.datosDomicilio.tiposucursal_detalle.id);
      };
    };
  };

  const {
    register,
    formState: { errors },
    reset,
  } = useForm();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleCloseResolucion = (event) => {
    props.recargarTablaR();
    props.recargarTablaD();
    props.onClose(event);
    setTipoResolucion({ id: "" });
  };

  const handleSubmitResolucion = (event) => {
    if (tipoResolucion !== "") {
      if (formRef.current) {
        const formFields = [...formRef.current.elements];
        // Loop through form fields
        let invalidFields = [];
        formFields.forEach((field) => {
          // Check if the field is invalid
          if (!field.validity.valid) {
            invalidFields.push(field.name + ", ");
          }
        });

        if (formRef.current.checkValidity()) {
          event.preventDefault();
          subirResolucion(event);
          props.recargarTablaR();
          props.recargarTablaD();
        } else {
          Notificaciones.notificacion(`Debe llenar los campos del formulario`);
        }
      }
    } else {
      Notificaciones.notificacion("Debe seleccionar un tipo de resolucion");
    }
  };

  const columns = useMemo(() => [
    {
      header: "id",
      accessorKey: "id",
      size: 50,
    },
    {
      header: "Nombre",
      accessorKey: "nombre",
      size: 50,
    },
    {
      header: "Apellido",
      accessorKey: "apellido",
      size: 50,
    },
    {
      header: "Cuit",
      accessorKey: "cuit",
      size: 50,
    },
  ]);

  const cargarZonas = () => {
    ConsultasAPI.ListarObjetos(URL_ZONA + 'listSinPag/').then((response) => {
      setTablaZona(response.data);
      setZona(response.data[0].id);
    });
  };

  const cargarTipoResolucion = () => {
    ConsultasAPI.ListarObjetos(URL_TIPORESOLUCION).then((response) => {
      let tabla = response.data.results
      if (props.datosAgencia.estado === "Baja") {
        tabla = tabla.filter(estado => estado.tipo === "ALTA")
      }
      if (props.datosAgencia.estado === "Activo") {
        tabla = tabla.filter(estado => estado.tipo !== "ALTA")
      }
      setTablaTipoResolucion(tabla);
    });
  };


  const cambioLocalidad = (zona) => {
    ConsultasAPI.ObtenerObjeto(URL_ZONA, zona).then((response) => {
      setLocalidad(response.data.localidad_detalle.nombre);
    });
  };
  const [archivoPDF, setArchivoPDF] = useState(null);

  const handleArchivoPDFChange = (event) => {
    const archivo = {
      path: event.target.files[0],
    };
    setArchivoPDF(archivo);
  };
  // Función para crear el archivo
  const crearArchivo = async (nombreCarpeta) => {
    try {
      let formData = new FormData();
      formData.append("path", archivoPDF.path);
      formData.append("nombre_subcarpeta", nombreCarpeta + "/resoluciones");

      const response = await ConsultasAPI.CrearObjetoArchivo(
        URL_ARCHIVO,
        formData
      );
      const archivoId = response.data.id; // Suponiendo que la respuesta contiene el ID del archivo creado
      return archivoId;
    } catch (error) {
      console.error("Error al crear el archivo:", error);
      throw error;
    }
  };


  const subirResolucion = async (event) => {
    try {
      const confirmResolucion = await Swal.fire({
        title: "¿Estás seguro de subir la Resolucion?",
        text: "Esta acción no se puede revertir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008185",
        cancelButtonColor: "#EC1B23",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      });
      if (confirmResolucion.isConfirmed) {
        let archivoId = null;
        try {
          if (tipoResolucion.tipo === "ALTA" && titular) {
            let titularAgencia = {
              agencia: props.datosAgencia.id,
              titular: titular,
            };
            props.setTitular(crearTitularAgencia(titularAgencia));
          };

          if (
            tipoResolucion.tipo === "ALTA" ||
            tipoResolucion.tipo === "BAJA" ||
            tipoResolucion.tipo === "SANCION - CADUCIDAD"
          ) {
            const resolucionABS = await crearResolucionAltaBajaCaducidad();
            const nombreCarpeta = await concatCodigo(props.datosAgencia.numero_agencia, props.datosAgencia.numero_subagencia)
            // Crear el archivo y obtener su ID
            archivoId = await crearArchivo(nombreCarpeta);

            // Crear el registro ArchivoResolucion que asocia el archivo con la resolución
            const archivoResolucionId = await crearArchivoResolucion(
              resolucionABS,
              archivoId
            );
            if (tipoResolucion.tipo === "ALTA") {
              const modificarAgencia = await cambiarEstadoAgencia("Activo");
            }
            if (tipoResolucion.tipo === "BAJA") {
              const modificarAgencia = await cambiarEstadoAgencia("Baja");
            }
            if (tipoResolucion.tipo === "SANCION - CADUCIDAD") {
              const modificarAgencia = await cambiarEstadoAgencia("Caducada");
            }
          }
          if (tipoResolucion.tipo === "CAMBIO DE DOMICILIO") {
            const resolucionCambioDomicilio =
              await crearResolucionCambioDomicilio();
            // Crear el archivo y obtener su ID
            const nombreCarpeta = await concatCodigo(props.datosAgencia.numero_agencia, props.datosAgencia.numero_subagencia)
            // Crear el archivo y obtener su ID
            archivoId = await crearArchivo(nombreCarpeta);

            // Crear el registro ArchivoResolucion que asocia el archivo con la resolución
            const archivoResolucionId = await crearArchivoResolucion(
              resolucionCambioDomicilio,
              archivoId
            );
          }
          if (tipoResolucion.tipo === "SANCION - MULTA") {
            const resolucionMulta = await crearResolucionMulta();
            // Crear el archivo y obtener su ID
            const nombreCarpeta = await concatCodigo(props.datosAgencia.numero_agencia, props.datosAgencia.numero_subagencia)
            // Crear el archivo y obtener su ID
            archivoId = await crearArchivo(nombreCarpeta);
            // Crear el registro ArchivoResolucion que asocia el archivo con la resolución
            const archivoResolucionId = await crearArchivoResolucion(
              resolucionMulta,
              archivoId
            );
          }
          if (tipoResolucion.tipo === "SANCION - SUSPENSION") {
            const resolucionSuspension = await crearResolucionSuspension();
            // Crear el archivo y obtener su ID
            const nombreCarpeta = await concatCodigo(props.datosAgencia.numero_agencia, props.datosAgencia.numero_subagencia)
            // Crear el archivo y obtener su ID
            archivoId = await crearArchivo(nombreCarpeta);

            // Crear el registro ArchivoResolucion que asocia el archivo con la resolución
            const archivoResolucionId = await crearArchivoResolucion(
              resolucionSuspension,
              archivoId
            );

            if (tipoResolucion.tipo === "SANCION - SUSPENSION") {
              const modificarAgencia = await cambiarEstadoAgencia("Suspendido");
            }
          }
          if (tipoResolucion.tipo === "ALTA") {
            let domicilio = {
              agencia: props.datosAgencia.id,
              calle: domicilioComercial,
              numero: numero,
              zonaExplotacion: zona,
              tiposucursal: tipoSucursal,
              localidad: localidad.value,
              zona: zona,
              coordenadas: coordenadas,
            };
            try {
              const response1 = await ConsultasAPI.CrearObjeto(
                URL_DOMICILIOCOMERCIAL + 'crearDomicilio/',
                domicilio
              );
              console.info(response1)
            } catch (error) {
              console.error(error)
            }
          };
          Notificaciones.notificacion("Se creo con Exito la resolución");
          handleCloseResolucion(event);
        } catch (error) {
          console.error(error);
          if (archivoId) {
            ConsultasAPI.BorrarObjeto(URL_ARCHIVO, archivoId);
          }
        }
      }
    } catch (error) {
      console.error("Error al mostrar el diálogo:", error);
      // Aquí podrías mostrar un mensaje de error al usuario, si lo deseas.
    }
  };

  const cambiarEstadoAgencia = async (estado) => {
    const agencia = {
      id: props.datosAgencia.id,
      numero_agencia: props.datosAgencia.numero_agencia,
      numero_subagencia: props.datosAgencia.numero_subagencia,
      estado: estado,
      telefono: props.datosAgencia.telefono,
      email: props.datosAgencia.email,
    };

    try {
      const response = await ConsultasAPI.ModificarObjeto(
        URL_AGENCIA,
        props.datosAgencia.id,
        agencia
      );
    } catch (error) {
      console.error("Error al modificar Agencia:", error);
      throw error;
    }
  };

  const crearArchivoResolucion = async (resolucionId, archivoId) => {
    const archivoResolucionT = {
      resolucion: resolucionId,
      archivo: archivoId,
    };

    try {
      const response = await ConsultasAPI.CrearObjeto(
        URL_ARCHIVORESOLUCION,
        archivoResolucionT
      );
    } catch (error) {
      console.error("Error al crear el registro ArchivoResolucion:", error);
      throw error;
    }
  };

  function formatDateToISO(dateString) {
    // Parse the input date string in DD/MM/YYYY format
    const parts = dateString.split("/");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Months are 0-indexed
    const year = parseInt(parts[2]);

    // Create a Date object
    const date = new Date(year, month, day);

    // Format the date in ISO 8601 format
    const formattedDate = date.toISOString();

    return formattedDate;
  }

  const crearResolucionAltaBajaCaducidad = async () => {
    try {
      var titularAgencia;
      if (tipoResolucion.tipo === "ALTA") {
        titularAgencia = await ConsultasAPI.ObtenerObjeto(URL_TITULAR, titular)
      } else {
        titularAgencia = await ConsultasAPI.ObtenerObjeto(URL_TITULARAGENCIA + 'busqueda/', props.datosAgencia.id)
      }
      let resolucionA = {
        resolucion: resolucion,
        agencia: props.datosAgencia.id,
        tipoderesolucion: tipoResolucion.id,
        expediente: expediente,
        comentario: comentario ?? null,
        fecha_resolucion: formatDateToISO(fechaResolucion.fechaMuestra),
        fecha_notificacion: formatDateToISO(fechaNotificacion.fechaMuestra),
        datos_json: {
          titular: titularAgencia.data,
        },
      };
      const response = await ConsultasAPI.CrearObjeto(
        URL_RESOLUCION,
        resolucionA
      );
      const resolucionId = response.data.id; // Suponiendo que la respuesta contiene el ID de la resolución creada
      return resolucionId;
    } catch (error) {
      console.error("Error al crear la resolución:", error);
      throw error;
    }
  };

  const crearResolucionCambioDomicilio = async () => {
    try {
      const titularAgencia = await ConsultasAPI.ObtenerObjeto(URL_TITULARAGENCIA + 'busqueda/', props.datosAgencia.id);

      let resolucionA = {
        resolucion: resolucion,
        agencia: props.datosAgencia.id,
        tipoderesolucion: tipoResolucion.id,
        expediente: expediente,
        comentario: comentario,
        fecha_resolucion: formatDateToISO(fechaResolucion.fechaMuestra),
        fecha_notificacion: formatDateToISO(fechaNotificacion.fechaMuestra),
        datos_json: {
          domicilioComercial: domicilioComercial,
          zonaExplotacion: zona,
          localidad: localidad.value,
          zona: zona,
          coordenadas: coordenadas,
          numero: numero,
          tiposucursal: tipoSucursal,
          titular: titularAgencia.status === 200 ? titularAgencia.data : '-',
        },
      };
      let domicilio = {
        agencia: props.datosAgencia.id,
        calle: domicilioComercial,
        numero: numero,
        zonaExplotacion: zona,
        tiposucursal: tipoSucursal,
        localidad: localidad.value,
        zona: zona,
        coordenadas: coordenadas,
      };
      const response = await ConsultasAPI.CrearObjeto(
        URL_RESOLUCION,
        resolucionA
      );
      const response1 = await ConsultasAPI.CrearObjeto(
        URL_DOMICILIOCOMERCIAL,
        domicilio
      );
      const resolucionId = response.data.id; // Suponiendo que la respuesta contiene el ID de la resolución creada
      return resolucionId;
    } catch (error) {
      console.error("Error al crear la resolución:", error);
      throw error;
    }
  };

  const crearResolucionMulta = async () => {
    try {
      const titularAgencia = await ConsultasAPI.ObtenerObjeto(URL_TITULARAGENCIA + 'busqueda/', props.datosAgencia.id);

      let resolucionA = {
        resolucion: resolucion,
        agencia: props.datosAgencia.id,
        tipoderesolucion: tipoResolucion.id,
        expediente: expediente,
        comentario: comentario,
        fecha_resolucion: formatDateToISO(fechaResolucion.fechaMuestra),
        fecha_notificacion: formatDateToISO(fechaNotificacion.fechaMuestra),
        datos_json: {
          montoTotalDevolverPorEquipo: montoDevolver,
          detallesEquipamiento: detallesEquipamiento,
          importeMulta: importeMulta,
          cuota: cuota,
          reposicionEquipo: reposicionEquipo,
          cuotaPagada: cuotaPagada,
          descontarComision: descontarComision,
          titular: titularAgencia.status === 200 ? titularAgencia.data : '-',

        },
      };
      const response1 = await ConsultasAPI.CrearObjeto(
        URL_RESOLUCION,
        resolucionA
      );
      const resolucionId = response1.data.id; // Suponiendo que la respuesta contiene el ID de la resolución creada
      return resolucionId;
    } catch (error) {
      console.error("Error al crear la resolución:", error);
      throw error;
    }
  };

  const crearResolucionSuspension = async () => {
    try {
      const titularAgencia = await ConsultasAPI.ObtenerObjeto(URL_TITULARAGENCIA + 'busqueda/', props.datosAgencia.id);

      let resolucionA = {
        resolucion: resolucion,
        agencia: props.datosAgencia.id,
        tipoderesolucion: tipoResolucion.id,
        expediente: expediente,
        comentario: comentario,
        fecha_resolucion: formatDateToISO(fechaResolucion.fechaMuestra),
        fecha_notificacion: formatDateToISO(fechaNotificacion.fechaMuestra),
        datos_json: {
          cantidadDias: cantDias,
          titular: titularAgencia.status === 200 ? titularAgencia.data : '-',

        },
      };
      const response1 = await ConsultasAPI.CrearObjeto(
        URL_RESOLUCION,
        resolucionA
      );
      const resolucionId = response1.data.id; // Suponiendo que la respuesta contiene el ID de la resolución creada
      return resolucionId;
    } catch (error) {
      console.error("Error al crear la resolución:", error);
      throw error;
    }
  };
  const handleSelectTitular = (selected) => {
    if (selected.length !== 0) {
      setSelectedOption(selected)
      setTitular(selected[0].id)
    }
  };


  const cargarTiposSucursales = () => {
    ConsultasAPI.ListarObjetos(URL_TIPOSUCURSAL).then((response) => {
      setTablaTipoSucursal(response.data.results);
      setTipoSucursal(
        response.data.results.length > 0 ? response.data.results[0].id : ""
      );
    });
  };

  const concatCodigo = (a, b) => {
    let codigoAgencia = "";
    if (a < 10) {
      codigoAgencia = "0" + a.toString();
      if (b < 10) {
        codigoAgencia = codigoAgencia + "00" + b.toString();
      } else {
        codigoAgencia = codigoAgencia + "0" + b.toString();
      }
    } else {
      codigoAgencia = a.toString();
      if (b < 10) {
        codigoAgencia = codigoAgencia + "00" + b.toString();
      } else {
        codigoAgencia = codigoAgencia + "0" + b.toString();
      }
    }
    return codigoAgencia;
  };

  const cargarLocalidades = () => {
    let i = 0;
    ConsultasAPI.ListarObjetos(URL_LOCALIDAD + "listSinPag/").then(
      (response) => {
        let results = response.data.map((localidad) => ({ value: localidad.id, label: localidad.nombre }));
        setTablaLocalidad(results);
      }
    );
  };

  return (
    <Modal
      show={props.show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={handleCloseResolucion}>
        <Modal.Title>{props.titulo} : {props.datosAgencia ? props.datosAgencia.numero_subagencia
          ? concatCodigo(
            props.datosAgencia.numero_agencia,
            props.datosAgencia.numero_subagencia
          )
          : concatCodigo(props.datosAgencia.numero_agencia, 0) : null}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmitResolucion} id="formResolucion" ref={formRef}>
        <Modal.Body>
          <Card className="mb-3">
            <Card.Body>
              <Row className="mb-3">
                <Form.Group as={Col} md="6">
                  <Form.Label>Tipo {requiredSymbol}</Form.Label>
                  {props.datosResolucion ? (
                    <Form.Control
                      required
                      type="text"
                      value={props.datosResolucion.tipo_resolucion}
                      readOnly={true}
                    />
                  ) : (
                    <Form.Select
                      name="Tipo"
                      value={tipoResolucion.id}
                      onChange={(event) =>
                        handleTablaTipoChange(event.target.value)
                      }
                      multiple={false}
                    >
                      <option hidden>Elegir Tipo</option>
                      {tablaTipoResolucion.map((tipo) => (
                        <option key={tipo.id} value={tipo.id}>
                          {tipo.tipo}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12">
                  <Form.Label>Comentario</Form.Label>
                  {props.datosResolucion ? (
                    <Form.Control
                      type="text"
                      value={props.datosResolucion.comentario}
                      readOnly={true}
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      value={comentario}
                      readOnly={false}
                      placeholder="Ingrese el comentario"
                      onChange={(event) => {
                        if (event.target.value.trim() !== '') {
                          setComentario(event.target.value);
                        } else {
                          setComentario('');
                        }
                      }}
                    />
                  )}
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
          {tipoResolucion.tipo === "CAMBIO DE DOMICILIO" ||
            props.datosResolucion.tipo_resolucion === "CAMBIO DE DOMICILIO" ? (
            <Card className="mb-3">
              <Card.Header>
                <Card.Title>Informacion</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Expediente  {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="expediente"
                        value={props.datosResolucion.expediente}
                        readOnly={true}
                        required
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="expediente"
                        placeholder="Ingrese el expediente"
                        value={expediente}
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setExpediente(event.target.value);
                          } else {
                            setExpediente('');
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Resolucion  {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={props.datosResolucion.resolucion}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        placeholder="Ingrese la resolucion"
                        value={resolucion}
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setResolucion(event.target.value);
                          } else {
                            setResolucion('');
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Fecha de Resolucion</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={
                          props.datosResolucion.fecha_resolucion.split(" ")[0]
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: fechaResolucion.fechaMuestra,
                          readOnly: true,
                        }}
                        value={fechaResolucion.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format(
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const fechaComparar = momentDate.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          );

                          setFechaResolucion({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Fecha de Notificacion (Posterior a la Fecha de Resolucion)</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={
                          props.datosResolucion.fecha_notificacion.split(" ")[0]
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: fechaNotificacion.fechaMuestra,
                          readOnly: true,
                        }}
                        isValidDate={isValidDate} // Use the custom isValidDate function
                        value={fechaNotificacion.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format(
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const fechaComparar = momentDate.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          );

                          setFechaNotificacion({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="5">
                    <Form.Label>Dirección {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        placeholder="Domicilio comercial de la agencia"
                        value={
                          props.datosResolucion.datos_json.domicilioComercial
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Domicilio comercial de la agencia"
                        value={domicilioComercial}
                        readOnly={false}
                        required
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setDomicilioComercial(event.target.value);
                          } else {
                            setDomicilioComercial('');
                          }
                        }}
                      />
                    )}
                  </Form.Group>
                  {/* <Form.Group as={Col} md="1">
                    <Form.Label>N° {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        placeholder="Nro  "
                        value={props.datosResolucion.datos_json.numero}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Nro  "
                        value={numero}
                        readOnly={false}
                        onChange={(event) => setNumero(event.target.value)}
                        required
                      />
                    )}
                  </Form.Group> */}
                  <Form.Group as={Col} md="4">
                    <Form.Label>Zona  {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        value={props.nombreZona}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Select
                        name="zona"
                        value={zona.id}
                        onChange={(event) => {
                          setZona(event.target.value);
                        }}
                        readOnly={false}
                        required
                      >
                        <option hidden>Elegir Zona</option>
                        {tablaZona.map((zona) => (
                          <option key={zona.id} value={zona.id}>
                            {zona.descripcion}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Coordenadas</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        value={props.datosResolucion.datos_json.coordenadas}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        value={coordenadas}
                        readOnly={false}
                        placeholder="Ingrese las coordenadas de su ubicacion"
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setCoordenadas(event.target.value);
                          } else {
                            setCoordenadas('');
                          }
                        }}
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="8">
                    <Form.Label>Municipio/Comuna</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        value={props.datosResolucion.datos_json.localidad}
                        readOnly={true}
                      />
                    ) : (
                      <Select
                        value={localidad}
                        onChange={(option) => { setLocalidad(option) }}
                        options={tablaLocalidad}
                        placeholder="Seleccionar Localidad"
                        required
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Sucursal de Rendicion {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        value={props.datosResolucion.datos_json.tiposucursal}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Select
                        name="tipoSucursal"
                        value={tipoSucursal.id}
                        onChange={(event) =>
                          setTipoSucursal(event.target.value)
                        }
                        required
                        multiple={false}
                      >
                        {tablaTipoSucursal.map((jurisdiccion) => (
                          <option key={jurisdiccion.id} value={jurisdiccion.id}>
                            {jurisdiccion.nombre}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  {props.datosResolucion ? (
                    <>
                      <Form.Label>Descargar Archivo:</Form.Label>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        <a href={props.datosArchivo} target="blank">
                          <img src={require("../../../img/iconoPDF.png")}></img>
                        </a>
                      </div>
                      <p style={{ textAlign: "center" }}>
                        {
                          props.datosArchivo.split("/")[
                          props.datosArchivo.split("/").length - 1
                          ]
                        }
                      </p>
                    </>
                  ) : (
                    <>
                      <Form.Label>Subir Documentacion:</Form.Label>
                      <Row className="mb-3">
                        <Form.Group controlId="formResolucionPDF">
                          <Form.Label>
                            Adjunte el archivo de resolución:  {requiredSymbol}
                          </Form.Label>
                          <Form.Control
                            // required
                            type="file"
                            accept=".pdf"
                            size="md"
                            onChange={handleArchivoPDFChange}
                          />
                        </Form.Group>
                      </Row>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          ) : tipoResolucion.tipo === "SANCION - MULTA" ||
            props.datosResolucion.tipo_resolucion === "SANCION - MULTA" ? (
            <>
              <Card>
                <Card.Header>
                  <Card.Title>Informacion</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Expediente {requiredSymbol}</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          name="expediente"
                          value={props.datosResolucion.expediente}
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          name="expediente"
                          placeholder="Ingrese el expediente"
                          value={expediente}
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setExpediente(event.target.value);
                            } else {
                              setExpediente('');
                            }
                          }}
                          required
                        />
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Resolucion {requiredSymbol}</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          name="resolucion"
                          value={props.datosResolucion.resolucion}
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          name="resolucion"
                          placeholder="Ingrese la resolucion"
                          value={resolucion}
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setResolucion(event.target.value);
                            } else {
                              setResolucion('');
                            }
                          }}
                          required
                        />
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Fecha de Resolucion</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          name="resolucion"
                          value={
                            props.datosResolucion.fecha_resolucion.split(" ")[0]
                          }
                          readOnly={true}
                        />
                      ) : (
                        <Datetime
                          timeFormat={false}
                          dateFormat="DD/MM/YYYY"
                          inputProps={{
                            placeholder: fechaResolucion.fechaMuestra,
                            readOnly: true,
                          }}
                          value={fechaResolucion.fechaMuestra}
                          onChange={(momentDate) => {
                            const fechaMuestra =
                              momentDate.format("DD/MM/YYYY");
                            const fechaComparar =
                              momentDate.format("YYYY-MM-DD");
                            setFechaResolucion({
                              fechaMuestra: fechaMuestra,
                              fechaComparar: fechaComparar,
                            });
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Fecha de Notificacion (Posterior a la Fecha de Resolucion)</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          name="resolucion"
                          value={
                            props.datosResolucion.fecha_notificacion.split(
                              " "
                            )[0]
                          }
                          readOnly={true}
                        />
                      ) : (
                        <Datetime
                          timeFormat={false}
                          dateFormat="DD/MM/YYYY"
                          isValidDate={isValidDate} // Use the custom isValidDate function
                          inputProps={{
                            placeholder: fechaNotificacion.fechaMuestra,
                            readOnly: true,
                          }}
                          value={fechaNotificacion.fechaMuestra}
                          onChange={(momentDate) => {
                            const fechaMuestra =
                              momentDate.format("DD/MM/YYYY");
                            const fechaComparar =
                              momentDate.format("YYYY-MM-DD");
                            setFechaNotificacion({
                              fechaMuestra: fechaMuestra,
                              fechaComparar: fechaComparar,
                            });
                          }}
                        />
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Monto Total a devolver por equipo</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          value={
                            props.datosResolucion.datos_json
                              .montoTotalDevolverPorEquipo
                          }
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          placeholder="Monto Total"
                          value={montoDevolver}
                          readOnly={false}
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setMontoDevolver(event.target.value);
                            } else {
                              setMontoDevolver('');
                            }
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Detalles de Equipamiento</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          value={
                            props.datosResolucion.datos_json
                              .detallesEquipamiento
                          }
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          placeholder="Detalle de Equipamiento  "
                          value={detallesEquipamiento}
                          readOnly={false}
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setDetallesEquipamiento(event.target.value);
                            } else {
                              setDetallesEquipamiento('');
                            }
                          }}
                        />
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="4">
                      <Form.Label>Importe Multa</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          value={props.datosResolucion.datos_json.importeMulta}
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value={importeMulta}
                          readOnly={false}
                          placeholder="Ingrese el importe de la multa"
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setImporteMulta(event.target.value);
                            } else {
                              setImporteMulta('');
                            }
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                      <Form.Label>Cuota </Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          value={props.datosResolucion.datos_json.cuota}
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value={cuota}
                          readOnly={false}
                          placeholder="Ingrese las cuotas"
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setCuota(event.target.value);
                            } else {
                              setCuota('');
                            }
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>Reposicion Equipo</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          value={
                            props.datosResolucion.datos_json.reposicionEquipo
                          }
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value={reposicionEquipo}
                          readOnly={false}
                          placeholder="Ingrese la reposicion de Equipo"
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setReposicionEquipo(event.target.value);
                            } else {
                              setReposicionEquipo('');
                            }
                          }}
                        />
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Cuota Pagada</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          value={props.datosResolucion.datos_json.cuotaPagada}
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          placeholder="Monto Total"
                          value={cuotaPagada}
                          readOnly={false}
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setCuotaPagada(event.target.value);
                            } else {
                              setCuotaPagada('');
                            }
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Descontar de la Comision</Form.Label>
                      {props.datosResolucion ? (
                        <Form.Control
                          type="text"
                          value={
                            props.datosResolucion.datos_json.descontarComision
                          }
                          readOnly={true}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          placeholder="Descontar de la Comision"
                          value={descontarComision}
                          readOnly={false}
                          onChange={(event) => {
                            if (event.target.value.trim() !== '') {
                              setDescontarComision(event.target.value);
                            } else {
                              setDescontarComision('');
                            }
                          }}
                        />
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Row className="mb-3">
                      {props.datosResolucion ? (
                        <>
                          <Form.Label>Descargar Archivo:</Form.Label>
                          <div
                            className="d-flex"
                            style={{ justifyContent: "center" }}
                          >
                            <a href={props.datosArchivo} target="blank">
                              <img
                                src={require("../../../img/iconoPDF.png")}
                              ></img>
                            </a>
                          </div>
                          <p style={{ textAlign: "center" }}>
                            {
                              props.datosArchivo.split("/")[
                              props.datosArchivo.split("/").length - 1
                              ]
                            }
                          </p>
                        </>
                      ) : (
                        <>
                          <Form.Label>Subir Documentacion:</Form.Label>
                          <Row className="mb-3">
                            <Form.Group controlId="formResolucionPDF">
                              <Form.Label>
                                Adjunte el archivo de resolución: {requiredSymbol}
                              </Form.Label>
                              <Form.Control
                                // required
                                type="file"
                                accept=".pdf"
                                size="md"
                                onChange={handleArchivoPDFChange}
                              />
                            </Form.Group>
                          </Row>
                        </>
                      )}
                    </Row>
                  </Row>
                </Card.Body>
              </Card>
            </>
          ) : tipoResolucion.tipo === "ALTA" ||
            props.datosResolucion.tipo_resolucion === "ALTA" || tipoResolucion.tipo === "CAMBIO DE TITULAR" ||
            props.datosResolucion.tipo_resolucion === "CAMBIO DE TITULAR" ? (
            <Card>
              <Card.Header>
                <Card.Title>Informacion</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Expediente {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="expediente"
                        value={props.datosResolucion.expediente}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="expediente"
                        placeholder="Ingrese el expediente"
                        value={expediente}
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setExpediente(event.target.value);
                          } else {
                            setExpediente('');
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Resolucion {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={props.datosResolucion.resolucion}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        placeholder="Ingrese la resolucion"
                        value={resolucion}
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setResolucion(event.target.value);
                          } else {
                            setResolucion('');
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Fecha de Resolucion</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={
                          props.datosResolucion.fecha_resolucion.split(" ")[0]
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: fechaResolucion.fechaMuestra,
                          readOnly: true,
                        }}
                        value={fechaResolucion.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format(
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const fechaComparar = momentDate.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          );

                          setFechaResolucion({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Fecha de Notificacion (Posterior a la Fecha de Resolucion)</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={
                          props.datosResolucion.fecha_notificacion.split(" ")[0]
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: fechaNotificacion.fechaMuestra,
                          readOnly: true,
                        }}
                        isValidDate={isValidDate} // Use the custom isValidDate function
                        value={fechaNotificacion.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format(
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const fechaComparar = momentDate.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          );

                          setFechaNotificacion({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                  {props.datosResolucion ? null : (
                    <Row className="d-flex mt-3 mb-3">
                      <Form.Group as={Col} md="6">
                        <Form.Label>Titular {requiredSymbol}</Form.Label>
                        <Typeahead
                          id="autocomplete"
                          options={titulares}
                          labelKey="label"
                          onChange={handleSelectTitular}
                          Selected={selectedOption}
                          placeholder="Escribe aquí para autocompletar"
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"

                      // style={{
                      //   height: "100%",
                      // }}
                      >
                        {/* <Row  > */}
                        <div className="d-flex justify-content-center align-items-end">
                          <button
                            className="botonCPA"
                            style={{
                              // height: "100%",
                              marginTop: 22,
                              //   marginLeft: 110,
                            }}
                            onClick={() => setAñadirTitularModal(true)}
                          >
                            Añadir Titular
                          </button>
                        </div>
                        {/* </Row> */}
                      </Form.Group>
                    </Row>
                  )}
                  <Card className="mt-3">
                    <Card.Header>
                      <Card.Title>Domicilio Comercial</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="5">
                          <Form.Label>Dirección</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Domicilio comercial de la agencia"
                            value={domicilioComercial}
                            readOnly={false}
                            onChange={(event) => {
                              if (event.target.value.trim() !== '') {
                                setDomicilioComercial(event.target.value);
                              } else {
                                setDomicilioComercial('');
                              }
                            }}
                          />
                        </Form.Group>
                        {/* <Form.Group as={Col} md="1">
                          <Form.Label>Numero</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Nro  "
                            value={numero}
                            readOnly={false}
                            onChange={(event) => setNumero(event.target.value)}
                          />
                        </Form.Group> */}
                        <Form.Group as={Col} md="4">
                          <Form.Label>Zona</Form.Label>
                          <Form.Select
                            name="zona"
                            value={zona}
                            onChange={(event) => {
                              setZona(event.target.value);
                            }}
                            readOnly={false}
                            required
                          >
                            {tablaZona.map((zona) => (
                              <option key={zona.id} value={zona.id}>
                                {zona.descripcion}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                          <Form.Label>Coordenadas</Form.Label>
                          <Form.Control
                            // required
                            type="text"
                            value={coordenadas}
                            readOnly={false}
                            placeholder="Ingrese las coordenadas de su ubicacion"
                            onChange={(event) => {

                              if (event.target.value.trim() !== '') {
                                setCoordenadas(event.target.value);
                              } else {
                                setCoordenadas('');
                              }
                            }}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="8">
                          <Form.Label>Municipio/Comuna</Form.Label>
                          <Select
                            value={localidad}
                            onChange={(option) => { setLocalidad(option) }}
                            options={tablaLocalidad}
                            placeholder="Seleccionar Localidad"
                            required
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                          <Form.Label>Sucursal de Rendición</Form.Label>
                          <Form.Select
                            name="tipoSucursal"
                            onChange={(event) => setTipoSucursal(event.target.value)}
                            required
                            multiple={false}
                            value={tipoSucursal}
                          >
                            {tablaTipoSucursal.map((jurisdiccion) => (
                              <option key={jurisdiccion.id} value={jurisdiccion.id}>
                                {jurisdiccion.nombre}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
                <Row className="mb-3">
                  <Row className="mb-3">
                    {props.datosResolucion ? (
                      <>
                        <Form.Label>Descargar Archivo:</Form.Label>
                        <div
                          className="d-flex"
                          style={{ justifyContent: "center" }}
                        >
                          <a href={props.datosArchivo} target="blank">
                            <img
                              src={require("../../../img/iconoPDF.png")}
                            ></img>
                          </a>
                        </div>
                        <p style={{ textAlign: "center" }}>
                          {
                            props.datosArchivo.split("/")[
                            props.datosArchivo.split("/").length - 1
                            ]
                          }
                        </p>
                      </>
                    ) : (
                      <>
                        <Form.Label>Subir Documentacion:</Form.Label>
                        <Row className="mb-3">
                          <Form.Group controlId="formResolucionPDF">
                            <Form.Label>
                              Adjunte el archivo de resolución: {requiredSymbol}
                            </Form.Label>
                            <Form.Control
                              // required
                              type="file"
                              accept=".pdf"
                              size="md"
                              onChange={handleArchivoPDFChange}
                            />
                          </Form.Group>
                        </Row>
                      </>
                    )}
                  </Row>
                </Row>
              </Card.Body>
            </Card>
          ) : tipoResolucion.tipo === "BAJA" ||
            props.datosResolucion.tipo_resolucion === "BAJA" ||
            tipoResolucion.tipo === "SANCION - CADUCIDAD" ||
            props.datosResolucion.tipo_resolucion === "SANCION - CADUCIDAD" ? (
            <Card>
              <Card.Header>
                <Card.Title>Informacion</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Expediente {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="expediente"
                        value={props.datosResolucion.expediente}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="expediente"
                        placeholder="Ingrese el expediente"
                        value={expediente}
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setExpediente(event.target.value);
                          } else {
                            setExpediente('');
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Resolucion {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={props.datosResolucion.resolucion}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        placeholder="Ingrese la resolucion"
                        value={resolucion}
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setResolucion(event.target.value);
                          } else {
                            setResolucion('');
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Fecha de Resolucion</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={
                          props.datosResolucion.fecha_resolucion.split(" ")[0]
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: fechaResolucion.fechaMuestra,
                          readOnly: true,
                        }}
                        value={fechaResolucion.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format(
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const fechaComparar = momentDate.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          );

                          setFechaResolucion({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Fecha de Notificacion (Posterior a la Fecha de Resolucion)</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={
                          props.datosResolucion.fecha_notificacion.split(" ")[0]
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Datetime
                        timeFormat={false}
                        isValidDate={isValidDate} // Use the custom isValidDate function
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: fechaNotificacion.fechaMuestra,
                          readOnly: true,
                        }}
                        value={fechaNotificacion.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format(
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const fechaComparar = momentDate.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          );

                          setFechaNotificacion({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Row className="mb-3">
                    {props.datosResolucion ? (
                      <>
                        <Form.Label>Descargar Archivo:</Form.Label>
                        <div
                          className="d-flex"
                          style={{ justifyContent: "center" }}
                        >
                          <a href={props.datosArchivo} target="blank">
                            <img
                              src={require("../../../img/iconoPDF.png")}
                            ></img>
                          </a>
                        </div>
                        <p style={{ textAlign: "center" }}>
                          {
                            props.datosArchivo.split("/")[
                            props.datosArchivo.split("/").length - 1
                            ]
                          }
                        </p>
                      </>
                    ) : (
                      <>
                        <Form.Label>Subir Documentacion:</Form.Label>
                        <Row className="mb-3">
                          <Form.Group controlId="formResolucionPDF">
                            <Form.Label>
                              Adjunte el archivo de resolución: {requiredSymbol}
                            </Form.Label>
                            <Form.Control
                              // required
                              type="file"
                              accept=".pdf"
                              size="md"
                              onChange={handleArchivoPDFChange}
                            />
                          </Form.Group>
                        </Row>
                      </>
                    )}
                  </Row>
                </Row>
              </Card.Body>
            </Card>
          ) : tipoResolucion.tipo === "SANCION - SUSPENSION" ||
            props.datosResolucion.tipo_resolucion === "SANCION - SUSPENSION" ? (
            <Card>
              <Card.Header>
                <Card.Title>Informacion</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Expediente {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="expediente"
                        value={props.datosResolucion.expediente}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="expediente"
                        placeholder="Ingrese el expediente"
                        value={expediente}
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setExpediente(event.target.value);
                          } else {
                            setExpediente('');
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Resolucion {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={props.datosResolucion.resolucion}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        placeholder="Ingrese la resolucion"
                        value={resolucion}
                        onChange={(event) => {
                          if (event.target.value.trim() !== '') {
                            setResolucion(event.target.value);
                          } else {
                            setResolucion('');
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Cantidad de Dias {requiredSymbol}</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="cantDias"
                        value={props.datosResolucion.datos_json.cantidadDias}
                        readOnly={true}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="cantDias"
                        placeholder="Ingrese la cantidad de dias"
                        value={cantDias}
                        onChange={(event) => setCantDias(event.target.value)}
                        onKeyPress={(event) => {
                          const regex = /[0-9]/;
                          const key = String.fromCharCode(event.charCode);
                          if (!regex.test(key)) {
                            event.preventDefault();
                          }
                        }}
                        required
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Fecha de Resolucion</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={
                          props.datosResolucion.fecha_resolucion.split(" ")[0]
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: fechaResolucion.fechaMuestra,
                          readOnly: true,
                        }}
                        value={fechaResolucion.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format(
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const fechaComparar = momentDate.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          );
                          setFechaResolucion({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Fecha de Notificacion (Posterior a la Fecha de Resolucion)</Form.Label>
                    {props.datosResolucion ? (
                      <Form.Control
                        type="text"
                        name="resolucion"
                        value={
                          props.datosResolucion.fecha_notificacion.split(" ")[0]
                        }
                        readOnly={true}
                      />
                    ) : (
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: fechaNotificacion.fechaMuestra,
                          readOnly: true,
                        }}
                        isValidDate={isValidDate} // Use the custom isValidDate function
                        value={fechaNotificacion.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format(
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const fechaComparar = momentDate.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          );

                          setFechaNotificacion({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Row className="mb-3">
                    {props.datosResolucion ? (
                      <>
                        <Form.Label>Descargar Archivo:</Form.Label>
                        <div
                          className="d-flex"
                          style={{ justifyContent: "center" }}
                        >
                          <a href={props.datosArchivo} target="blank">
                            <img
                              src={require("../../../img/iconoPDF.png")}
                            ></img>
                          </a>
                        </div>
                        <p style={{ textAlign: "center" }}>
                          {
                            props.datosArchivo.split("/")[
                            props.datosArchivo.split("/").length - 1
                            ]
                          }
                        </p>
                      </>
                    ) : (
                      <>
                        <Form.Label>Subir Documentacion:</Form.Label>
                        <Row className="mb-3">
                          <Form.Group controlId="formResolucionPDF">
                            <Form.Label>
                              Adjunte el archivo de resolución: {requiredSymbol}
                            </Form.Label>
                            <Form.Control
                              // required
                              type="file"
                              accept=".pdf"
                              size="md"
                              onChange={handleArchivoPDFChange}
                            />
                          </Form.Group>
                        </Row>
                      </>
                    )}
                  </Row>
                </Row>
              </Card.Body>
            </Card>
          ) : null}
        </Modal.Body>
      </Form>
      {props.titulo === "Ver Resolucion" ? null : (
        <Modal.Footer>
          <button className="btn botonCPA m-3" onClick={handleSubmitResolucion}>
            Guardar
          </button>
        </Modal.Footer>
      )}
      <ModalTitular
        onClose={() => {
          setAñadirTitularModal(false);
        }}
        show={añadirTitularModal}
        titulo={"Nuevo"}
        nuevoTitular={(data) => handleNuevoTitular(data)}
      />
    </Modal>
  );
}
