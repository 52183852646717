import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import React, { useMemo, useState, useEffect, useRef } from 'react';

const Retenciones = (props) => {
    const [porcentajeRetencionGanancias, setPorcentajeRetencionGanancias] =
        useState(0);
    const [porcentajeRetencionIva, setPorcentajeRetencionIva] = useState(0);
    const [ingresosBrutos, setIngresosBrutos] = useState(6.5);
    const [porcentajeJurisdiccion, setPorcentajeJurisdiccion] = useState(0);

    useEffect(() => {
        if (props.retenciones) {
            obtenerRetencionAfip();
            retencionJurisdiccion();
        }
    }, [props.retenciones]);

    useEffect(() => {
        props.dataRetencionAfip(
            (porcentajeRetencionGanancias * props.retenciones.comisionBruta) /
                100
        );
    }, [porcentajeRetencionGanancias]);

    useEffect(() => {
        props.dataRetencionJurisdiccion(
            (porcentajeJurisdiccion * props.retenciones.comisionBruta) / 100
        );
    }, [porcentajeJurisdiccion]);

    useEffect(() => {
        props.dataRetencionIva(
            (porcentajeRetencionIva * props.retenciones.comisionBruta) / 100
        );
    }, [porcentajeRetencionIva]);

    const obtenerRetencionAfip = () => {
        if (props.condicionFiscal.condicion === 'INSCRIPTO EN GANANCIAS') {
            if (props.retenciones.comisionBruta >= 67170) {
                if (props.tipoFactura === 'C' || props.tipoFactura === 'A') {
                    setPorcentajeRetencionGanancias(2);
                    setPorcentajeRetencionIva(0);
                } else {
                    if (props.tipoFactura === 'A sujeta a retención') {
                        setPorcentajeRetencionGanancias(3);
                        setPorcentajeRetencionIva(0);
                    } else {
                        setPorcentajeRetencionGanancias(6);
                        setPorcentajeRetencionIva(0);
                    }
                }
            }
        } else {
            if (
                props.condicionFiscal.condicion === 'RESPONSABLE NO INSCRIPTO'
            ) {
                setPorcentajeRetencionGanancias(28);
            } else {
                if (props.condicionFiscal.condicion === 'MONOTRIBUTO') {
                    setPorcentajeRetencionGanancias(0);
                    setPorcentajeRetencionIva(0);
                } else {
                    setPorcentajeRetencionGanancias(35);
                    setPorcentajeRetencionIva(21);
                }
            }
        }
    };

    const retencionJurisdiccion = () => {
        if (props.jurisdiccion === 'AGUILARES') {
            setPorcentajeJurisdiccion(4);
        } else {
            if (props.jurisdiccion === 'JUAN BAUTISTA ALBERDI') {
                setPorcentajeJurisdiccion(4);
            } else {
                if (props.jurisdiccion === 'BANDA DEL RIO SALI') {
                    setPorcentajeJurisdiccion(4.4);
                } else {
                    if (
                        props.jurisdiccion === 'FAMAILLA' &&
                        props.retenciones.titular_agencia &&
                        props.retenciones.titular_agencia.agencia_detalle
                            .numero_subagencia === 0
                    ) {
                        setPorcentajeJurisdiccion(3);
                    } else {
                        if (props.jurisdiccion === 'LAS TALITAS') {
                            setPorcentajeJurisdiccion(4);
                        } else {
                            if (
                                props.jurisdiccion ===
                                    'SAN MIGUEL DE TUCUMAN' &&
                                props.retenciones.comisionBruta > 20000
                            ) {
                                if (
                                    props.condicionFiscal.condicion ===
                                    'INSCRIPTO EN GANANCIAS'
                                ) {
                                    setPorcentajeJurisdiccion(3.7);
                                } else {
                                    setPorcentajeJurisdiccion(7.4);
                                }
                            } else {
                                if (props.jurisdiccion === 'SIMOCA') {
                                    setPorcentajeJurisdiccion(3);
                                } else {
                                    if (props.jurisdiccion === 'TAFI VIEJO') {
                                        setPorcentajeJurisdiccion(2);
                                    } else {
                                        if (props.jurisdiccion === 'MONTEROS') {
                                            setPorcentajeJurisdiccion(0);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };
};
export default Retenciones;
