import { REACT_APP_API_BASE_URL } from './config';


export const BASE_URL = REACT_APP_API_BASE_URL;

export const API_ROUTES = {
    BASE: BASE_URL,
    USUARIOS: BASE_URL + 'users/',
    ROLES: BASE_URL + 'roles/',
    EXTRACTO: BASE_URL + 'extracto/',
    EXTRACTOREGISTRO: BASE_URL + 'extracto-registro/',
    TIPOSORTEO: BASE_URL + 'tipo-sorteo/',
    SORTEOEXCEPTUADO: BASE_URL + 'sorteo-exceptuado/',
    TIPOSUCURSAL: BASE_URL + 'tipo-sucursal/',
    ZONA: BASE_URL + 'zona/',
    TITULAR: BASE_URL + 'titular/',
    LOCALIDAD: BASE_URL + 'localidad/',
    CONDICIONFISCAL: BASE_URL + 'condicion-fiscal/',
    DOMICILIOPARTICULAR: BASE_URL + 'domicilio-particular/',
    CONDICIONFISCALTITULAR: BASE_URL + 'condicion-fiscal-titular/',
    AGENCIA: BASE_URL + 'agencia/',
    PERSONA: BASE_URL + 'persona/',
    DOMICILIOCOMERCIAL: BASE_URL + 'domicilio-comercial/',
    RESOLUCION: BASE_URL + 'resolucion/',
    JUGADAS: BASE_URL + 'jugada/',
    JUGADASMES: BASE_URL + 'jugadas-mes/',
    JUGADASMESTOTAL: BASE_URL + 'jugadas-mes-total/',
    AGENCIAS: BASE_URL + 'agencia/',
    TITULARAGENCIA: BASE_URL + 'titular-agencia/',
    RENDICIONAGENCIA: BASE_URL + 'rendicion-agencia/',
    RENDICION: BASE_URL + 'rendicion/',
    COLABORADOR: BASE_URL + 'colaborador/',
    ARCHIVO: BASE_URL + 'archivo/',
    ARCHIVORESOLUCION: BASE_URL + 'archivo-resolucion/',
    TIPORESOLUCION: BASE_URL + 'tipo-resolucion/',
    TIPODEDUCCION: BASE_URL + 'tipo_deduccion/',
    ARCHIVODEDUCCION: BASE_URL + 'archivo_deduccion/',
    ARCHIVODOCUMENTACION: BASE_URL + 'archivo_documentacion/',
    CUOTA: BASE_URL + 'cuota/',
    DEDUCCION: BASE_URL + 'deduccion/',
    ORDENPAGO: BASE_URL + 'orden_pago/',
    VENTAS: BASE_URL + 'ventas/',
    SORTEO: BASE_URL + 'sorteo-tuqui/',
    COMISION: BASE_URL + 'comision/',
    CONCEPTORECHAZADO: BASE_URL + 'concepto-rechazado/',
    RETENCION: BASE_URL + 'retencion/',//De Deducciones
    FECHALIMITE: BASE_URL + 'fecha_limite/',
    REGISTROMAIL: BASE_URL + 'registro-mail/',
    DOCUMENTACIONFACTURA: BASE_URL + 'archivo_documentacion/', //// FACTURA,
    MOTIVOCAMBIORETENCION : BASE_URL + 'motivo_cambio_retencion/',
    TUQUI_RANGO: BASE_URL + 'rango-tuqui/',
    TUQUI_COLOR: BASE_URL + 'color-tuqui/',
    TUQUI_VENTAS: BASE_URL + 'ventas/',
    PUBLICIDAD: BASE_URL + 'publicidad/',
    PUBLICACIONEXTRACTO: BASE_URL + 'publicacion-extracto-tuqui/',
};
