import MaterialReactTable from 'material-react-table';
import { useEffect, useState, useMemo, useRef } from 'react';
import { Modal, Form, Col, Row, Button, Image, Spinner } from 'react-bootstrap';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { darken } from '@mui/material';
import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import { IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Swal from 'sweetalert2';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import AuthenticationHelper from '../../../shared/helpers/authenticationHelper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ModalDarBajaDocumentacion } from './ModalDarBajaDocumentacion';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DetalleDeduccion = (props) => {
    const URL_CONDICIONFISCAL = window.API_ROUTES.CONDICIONFISCAL;
    const URL_RETENCION = window.API_ROUTES.RETENCION;
    const [showModalDarBajaDocumentacion, setShowModalDarBajaDocumentacion] =
        useState(false);
    const [documentacionBajaModal, setdocumentacionBajaModal] =
        useState('');
    const [tituloModal, setTituloModal] = useState('');
    const handleCloseModalDarBajaDocumentacion = () => {
        setShowModalDarBajaDocumentacion(false);
        props.cargar(props.datoAgencia);
    };
    const handleOpenModalDarBajaDocumentacion = (documentacion) => {
        setTituloModal('Nuevo');
        setShowModalDarBajaDocumentacion(true);
        setdocumentacionBajaModal(documentacion)
    };
    const cargarTablaCondicionFiscal = async () => {
        const condiciones =
            await ConsultasAPI.ListarObjetos(URL_CONDICIONFISCAL);
        setTablaCondiciones(condiciones.data.results);
    };
    const [tablaCondiciones, setTablaCondiciones] = useState([]);

    const [count, SetCount] = useState(0);
    const [pagination, setPagination] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [data, setData] = useState([]);
    const [dataDetalleDocumentacion, setDataDetalleDocumentacion] = useState(
        []
    );
    const [showModalDetalleDocumentacion, setShowModalDetalleDocumentacion] =
        useState(false);
    const [showModalAgregarDocumentacion, setShowModalAgregarDocumentacion] =
        useState(false);
    const [importeFacturacionQuiniela, setImporteFacturacionQuiniela] =
        useState('');
    const [importeFacturacionTuqui, setImporteFacturacionTuqui] = useState('');
    const [estado, setEstado] = useState({ id: '', descripcion: '' });
    const [estadoViejo, setEstadoViejo] = useState({ id: '', descripcion: '' });
    const [tablaEstado, setTablaEstado] = useState([]);
    const [comentario, setComentario] = useState('');
    const [tipoFactura, setTipoFactura] = useState('');
    const [nroFactura, setNroFactura] = useState('');
    const [nroFacturaVenta, setNroFacturaVenta] = useState('');
    const [nroFacturaNro, setNroFacturaNro] = useState('');
    const [ingresosBrutos, setIngresosBrutos] = useState('');
    const [montoConcesion, setMontoConcesion] = useState('');
    const [actividad, setActividad] = useState('');
    const [tipoDocumentacion, setTipoDocumentacion] = useState({
        descripcion: dataDetalleDocumentacion
            ? dataDetalleDocumentacion.tipo
            : '',
    });
    const [condicionFiscal, setCondicionFiscal] = useState('');
    const [condicionFiscalTit, setCondicionFiscalTit] = useState('');
    const [archivoPDF, setArchivoPDF] = useState([]);
    const [fechaFactura, setFechaFactura] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });
    const fechaFacturaRef = useRef(null);
    const [fechaCertificado, setFechaCertificado] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });
    const fechaCertificadoRef = useRef(null);
    const [fechaVencimiento, setFechaVencimiento] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });
    const fechaVencimientoRef = useRef(null);
    const [fechaConstancia, setFechaConstancia] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });
    const fechaConstanciaRef = useRef(null);
    const [path, setPath] = useState('');
    const [periodo, setPeriodo] = useState({
        fechaMuestra: moment(dataDetalleDocumentacion.periodo, 'DD/MM/YYYY'),
        fechaComparar: moment(dataDetalleDocumentacion.periodo, 'YYYY-MM-DD'),
    });
    const periodoRef = useRef(null);
    const [zoom, setZoom] = useState(1.0);

    const URL_ARCHIVODOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
    const URL_ORDENPAGO = window.API_ROUTES.ORDENPAGO;
    const URL_ARCHIVO = window.API_ROUTES.ARCHIVO;
    const URL_JUGADASMES = window.API_ROUTES.JUGADASMES;
    const URL_COMISION = window.API_ROUTES.COMISION;
    const URL_CONDICIONFISCALTITULAR = window.API_ROUTES.CONDICIONFISCALTITULAR;
    const URL_DOMICILIO_COMERCIAL = window.API_ROUTES.DOMICILIOCOMERCIAL;
    const URL_CONCEPTO_RECHAZADO = window.API_ROUTES.CONCEPTORECHAZADO;
    const URL_DETALLE_FACTURA = window.API_ROUTES.DOCUMENTACIONFACTURA;

    const [filtroPeriodo, setFiltroPeriodo] = useState(
        props.periodo
            ? moment(props.periodo, 'YYYY-MM-DD').format('MM/YYYY')
            : moment().add(-1, 'months').format('MM/YYYY')
    );
    const requiredSymbol = !dataDetalleDocumentacion.datos_json ? (
        <b style={{ color: 'red' }}>*</b>
    ) : (
        ''
    );
    const [tablaTipoDocumentacion, setTablaTipoDocumentacion] = useState([]);
    const [imagen, setImagen] = useState(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [nuevoMotivo, setNuevoMotivo] = useState('');
    const [fechaDesde, setFechaDesde] = useState({
        fechaMuestra: moment().format('DD/MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const fechaDesdeRef = useRef(null);
    const [tablaTipoFactura, setTablaTipoFactura] = useState([]);
    const [tablaMotivosRechazo, setTablaMotivosRechazo] = useState([]);
    const [zonaExplotacion, setZonaExplotacion] = useState(null);
    const [editar, setEditar] = useState(false);
    const [comisionQ, setComisionQ] = useState(0);
    const [comisionT, setComisionT] = useState(0);
    const [restaComisionQ, setRestaComisionQ] = useState(0);
    const [restaComisionT, setRestaComisionT] = useState(0);
    const [restaConcesion, setRestaConcesion] = useState(0);

    const [PuntoVenta, setPuntoVenta] = useState();
    const vectorActividades = ['924911', '924912', '924913', '924914', 'OTRO'];

    const columns = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            size: 50,
        },
        {
            header: 'primera',
            accessorKey: 'primera',
            size: 50,
        },
        {
            header: 'Periodo de Comision',
            accessorKey: 'periodo',
            size: 50,
        },
        {
            header: 'Documentacion',
            accessorKey: 'documentacion',
            size: 50,
        },
        {
            header: 'Estado',
            accessorKey: 'estado',
            size: 50,
        },
        {
            header: 'Fecha de Carga',
            accessorKey: 'fechaCarga',
            size: 50,
            enableColumnFilter: true,
        },
        {
            header: 'Fecha de Revision',
            accessorKey: 'fechaRevision',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Revisado por',
            accessorKey: 'revisadoPor',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'archivo',
            accessorKey: 'archivo',
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    useEffect(() => {
        cargarTablaMotivoRechazo();
    }, []);

    const cargarTablaMotivoRechazo = async () => {
        const response = await ConsultasAPI.ListarObjetos(
            URL_CONCEPTO_RECHAZADO + 'listAll/'
        );
        const vectorMotivos = [];
        response.data.forEach((motivo) => {
            vectorMotivos.push({ value: motivo.concepto });
        });
        setTablaMotivosRechazo(vectorMotivos);
    };

    useEffect(() => {

        cargarTabla();
        cargarTablaEstado();
        cargarTablaCondicionFiscal();
        cargarTablaTipoFactura();
    }, [props.show, filtroPeriodo, props.data, showModalDarBajaDocumentacion]);

    useEffect(() => {
        if (restaComisionQ === 0 && restaComisionT === 0) {
            setEstado(
                tablaEstado.filter((x) => x.descripcion === 'Aprobado')[0]
            );
        } else {
            setEstado(
                tablaEstado.filter((x) => x.descripcion === 'Rechazado')[0]
            );
        }
    }, [restaComisionQ, restaComisionT]);

    useEffect(() => {



        cargarTabla();
        cargarTablaEstado();
        cargarComisiones();
    }, [props.show, filtroPeriodo, props.data, showModalDarBajaDocumentacion]);

    useEffect(() => {
        cargarTablaTipoDocumentacion();
        setFiltroPeriodo(
            props.periodo
                ? moment(props.periodo, 'YYYY-MM-DD').format('MM/YYYY')
                : moment().add(-1, 'months').format('MM/YYYY')
        );
    }, [props.show]);

    useEffect(() => {
        if (path.length !== 0) {
            const extension = path.split('.').pop();
            if (extension === 'jpg' || extension === 'jpeg') {
                setImagen(true);
            } else {
                setImagen(false);
            }
        }
    }, [path]);

    const handleCloseModal = (event) => {
        props.close(event);
        setComisionQ(0)
        setComisionT(0)
    };

    const handleCloseModalDetalleDocumentacion = (event) => {
        event.preventDefault();
        setEditar(false);
        cargarTabla();

        setPeriodo({ fechaMuestra: null, fechaComparar: null });
        if (periodoRef && periodoRef.current) {
            periodoRef.current.setState({ inputValue: '' });
        }
        setFechaFactura({ fechaMuestra: null, fechaComparar: null });
        if (fechaFacturaRef && fechaFacturaRef.current) {
            fechaFacturaRef.current.setState({ inputValue: '' });
        }
        setFechaCertificado({ fechaMuestra: null, fechaComparar: null });
        if (fechaCertificadoRef && fechaCertificadoRef.current) {
            fechaCertificadoRef.current.setState({ inputValue: '' });
        }
        setFechaDesde({ fechaMuestra: null, fechaComparar: null });
        if (fechaDesdeRef && fechaDesdeRef.current) {
            fechaDesdeRef.current.setState({ inputValue: '' });
        }
        setFechaConstancia({ fechaMuestra: null, fechaComparar: null });
        if (fechaConstanciaRef && fechaConstanciaRef.current) {
            fechaConstanciaRef.current.setState({ inputValue: '' });
        }
        setFechaVencimiento({ fechaComparar: null, fechaMuestra: null });
        if (fechaVencimientoRef && fechaVencimientoRef.current) {
            fechaVencimientoRef.current.setState({ inputValue: '' });
        }

        setCondicionFiscal('');
        setMontoConcesion('');
        setTipoDocumentacion({ descripcion: '' });
        setActividad('');
        setComentario('');
        setImporteFacturacionQuiniela('');
        setImporteFacturacionTuqui('');
        setIngresosBrutos('');
        setEstado({ id: '', descripcion: '' });
        setTipoFactura('');
        setNroFacturaVenta('');
        setNroFacturaNro('');
        setShowModalDetalleDocumentacion(false);
        setRestaComisionQ(0);
        setRestaComisionT(0);
        // setComisionQ(0);
        // setComisionT(0);
        setRestaConcesion(0);
        setPuntoVenta('');
    };

    const cargarComisiones = async () => {
        if (props.data[0] && props.show) {
            const datos = await ConsultasAPI.ObtenerObjeto(
                URL_JUGADASMES + 'obtenerJugadaTitular/',
                props.data[0].titular_agencia +
                ',' +
                moment('01/' + filtroPeriodo, 'DD/MM/YYYY').format(
                    'MM/YYYY'
                )
            );
            if (datos.status === 200 && datos.data.length > 0) {
                setComisionQ(datos.data[0].comisionBruta);
            } else {
                setComisionQ(0);
            }
            const datosTuqui = await ConsultasAPI.ObtenerObjeto(
                URL_COMISION + 'obtenerComisionTitularTotal/',
                props.data[0].titular_detalle.id +
                ':' +
                moment('01/' + filtroPeriodo, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD'
                )
            );
            if (datosTuqui.status === 200) {
                setComisionT(datosTuqui.data);
            } else {
                setComisionT(0);
            }
        }
    };

    const cargarTablaTipoDocumentacion = () => {
        const tabla = [];
        const v = {
            descripcion: 'Factura Electronica',
        };
        tabla.push(v);
        const w = {
            descripcion: 'Certificado de Cumplimiento Fiscal Vigente',
        };
        tabla.push(w);
        const x = {
            descripcion: 'Constancia de Inscripción en AFIP',
        };
        tabla.push(x);
        const y = {
            descripcion:
                'Constancia Del Tributo Económico Municipal Actualizada',
        };
        tabla.push(y);
        const z = {
            descripcion:
                'Certificación de no Estar Registrado Como Deudor Alimenticio',
        };
        tabla.push(z);
        const a = {
            descripcion: 'Declaración Jurada de Liquidación Mensual',
        };
        tabla.push(a);
        const b = {
            descripcion: 'Constancia de Inscripción en Rentas de la Provincia',
        };
        tabla.push(b);
        setTablaTipoDocumentacion(tabla);
    };






    const cargarTabla = () => {
        const d = [];
        const tiposEncontrados = new Set();

        props.data.forEach((documento) => {
            const doc = {
                id: documento.id,
                periodo: moment(documento.periodo, 'DD/MM/YYYY').format(
                    'MM/YYYY'
                ),
                documentacion: documento.tipo,
                estado: documento.estado_documentacion,
                fechaCarga: documento.fecha_creacion,
                fechaRevision: documento.fecha_revision
                    ? documento.fecha_revision
                    : '-',
                revisadoPor: documento.revisado_por
                    ? documento.revisado_por
                    : '-',
                archivo: documento.archivo_detalle.path,
            };
            if (!filtroPeriodo || doc.periodo === filtroPeriodo) {
                if (!tiposEncontrados.has(doc.documentacion)) {
                    doc.primera = true; // Agregar campo extra a la primera ocurrencia
                    tiposEncontrados.add(doc.documentacion); // Marca este tipo como encontrado
                }
                d.push(doc);
            }
        });
        setData(d);
    };

    const cargarTablaTipoFactura = () => {
        const dat = [];
        const d = {
            descripcion: 'A',
        };
        dat.push(d);
        const d1 = {
            descripcion: 'C',
        };
        dat.push(d1);
        const d2 = {
            descripcion: 'A sujeta a retención',
        };
        dat.push(d2);
        const d3 = {
            descripcion: 'M',
        };
        dat.push(d3);
        setTablaTipoFactura(dat);
    };

    const cargarTablaEstado = () => {
        const dat = [];
        const d = {
            id: 1,
            descripcion: 'Rechazado',
        };
        dat.push(d);
        const d1 = {
            id: 2,
            descripcion: 'Aprobado',
        };
        dat.push(d1);
        setTablaEstado(dat);
    };

    const handleOpenDetalleDocumentacion = async (row, ruta) => {
        var ruta = {
            ruta: ruta,
            id: row.id

        };

        const dataDoc = await ConsultasAPI.ObtenerObjeto(
            URL_ARCHIVODOCUMENTACION,
            row.id
        );
        const condFiscal = await ConsultasAPI.ObtenerObjeto(
            URL_CONDICIONFISCALTITULAR + 'busqueda/',
            dataDoc.data.titular_detalle.titular_detalle.id
        );
        const domicilioComercial = await ConsultasAPI.ObtenerObjeto(
            URL_DOMICILIO_COMERCIAL + 'busqueda/',
            dataDoc.data.titular_detalle.agencia
        );

        const detalleFactura = await ConsultasAPI.CrearObjeto(
            URL_DETALLE_FACTURA + 'datos_pdf_factura/',
            ruta
        );

        if (domicilioComercial.status === 200) {
            setZonaExplotacion(domicilioComercial.data.zona.descripcion);
        } else {
            setZonaExplotacion(null);
        }
        setCondicionFiscalTit(condFiscal.data);
        const a = {
            id: condFiscal.data.condicionfiscal,
            condicion: condFiscal.data.condicion_fiscal,
        };
        setCondicionFiscal(a);
        setDataDetalleDocumentacion(dataDoc.data);
        setTipoDocumentacion({ descripcion: dataDoc.data.tipo });
        setPeriodo({
            fechaMuestra: moment(dataDoc.data.periodo, 'DD/MM/YYYY').format(
                'MM/YYYY'
            ),
            fechaComparar: moment(dataDoc.data.periodo, 'DD/MM/YYYY').format(
                'YYYY-MM'
            ),
        });

        if (detalleFactura.status === 200) {
            if (Array.isArray(detalleFactura.data)) {
                const coincidencias = [];
                for (const numero of vectorActividades) {
                    const coincidencia = detalleFactura.data.find(
                        (objeto) => objeto.Codigo === numero
                    );
                    if (coincidencia) {
                        coincidencias.push(coincidencia);
                    }
                }
                if (coincidencias.length > 0) {
                    setActividad(coincidencias[0].Codigo);
                }
            }
            if (
                typeof detalleFactura.data === 'object' &&
                detalleFactura.data !== null
            ) {
                if (detalleFactura.data.Operacion === 'FACTURA') {
                    setImporteFacturacionQuiniela(
                        parseFloat(
                            detalleFactura.data.ComisionQuiniela.replace(
                                ',',
                                '.'
                            )
                        ).toFixed(2)
                    );
                    setImporteFacturacionTuqui(
                        parseFloat(
                            detalleFactura.data.ComisionTuqui.replace(',', '.')
                        ).toFixed(2)
                    );
                    setPuntoVenta(detalleFactura.data.PuntoVenta);
                    setNroFacturaNro(detalleFactura.data.CompraNro);
                    setNroFacturaVenta(detalleFactura.data.PuntoVenta);

                    setFechaFactura((fechaFactura) => ({
                        ...fechaFactura,
                        fechaMuestra: detalleFactura.data.FechaEmision,
                    }));
                    setTipoFactura(detalleFactura.data.TipoFactura);
                }

                if (detalleFactura.data.Operacion === 'PJT') {
                    const partes = detalleFactura.data.Fecha.split('-');

                    const fechaFormateada =
                        partes[2].substring(0, 2) +
                        '/' +
                        partes[1] +
                        '/' +
                        partes[0];

                    setFechaCertificado((fechaCertificado) => ({
                        ...fechaCertificado,
                        fechaMuestra: fechaFormateada,
                    }));
                }
            }
            setShowModalDetalleDocumentacion(true);
        } else {
            setShowModalDetalleDocumentacion(true);
        }
    };

    const handleEditarDetalleDocumentacion = async (row) => {
        setEditar(true);
        setShowModalDetalleDocumentacion(true);
        const dataDoc = await ConsultasAPI.ObtenerObjeto(
            URL_ARCHIVODOCUMENTACION,
            row.id
        );
        const condFiscal = await ConsultasAPI.ObtenerObjeto(
            URL_CONDICIONFISCALTITULAR + 'busqueda/',
            dataDoc.data.titular_detalle.titular_detalle.id
        );
        const domicilioComercial = await ConsultasAPI.ObtenerObjeto(
            URL_DOMICILIO_COMERCIAL + 'busqueda/',
            dataDoc.data.titular_detalle.agencia
        );
        setEstado(
            tablaEstado.filter(
                (x) => x.descripcion === dataDoc.data.estado_documentacion
            )[0]
        );
        setEstadoViejo(
            tablaEstado.filter(
                (x) => x.descripcion === dataDoc.data.estado_documentacion
            )[0]
        );

        if (dataDoc.data.tipo === 'Factura Electronica') {
            setImporteFacturacionQuiniela(
                dataDoc.data.datos_json.importeFacturacionQuiniela
            );
            setImporteFacturacionTuqui(
                dataDoc.data.datos_json.importeFacturacionTuqui
            );
            setTipoFactura(dataDoc.data.datos_json.tipoFactura);
            setNroFactura(dataDoc.data.datos_json.nroFactura);
            setFechaFactura({
                fechaMuestra: moment(
                    dataDoc.data.datos_json.fechaFactura,
                    'DD/MM/YYYY'
                ).format('DD/MM/YYYY'),
                fechaComparar: moment(
                    dataDoc.data.datos_json.fechaFactura,
                    'DD/MM/YYYY'
                ).format('YYYY-MM-DD'),
            });
        }
        if (
            dataDoc.data.tipo === 'Certificado de Cumplimiento Fiscal Vigente'
        ) {
            setFechaCertificado({
                fechaMuestra: moment(
                    dataDoc.data.datos_json.fechaCertificado,
                    'DD/MM/YYYY'
                ).format('DD/MM/YYYY'),
                fechaComparar: moment(
                    dataDoc.data.datos_json.fechaCertificado,
                    'DD/MM/YYYY'
                ).format('YYYY-MM-DD'),
            });
        }
        if (dataDoc.data.tipo === 'Constancia de Inscripción en AFIP') {
            setFechaConstancia({
                fechaMuestra: moment(
                    dataDoc.data.datos_json.fechaConstancia,
                    'DD/MM/YYYY'
                ).format('DD/MM/YYYY'),
                fechaComparar: moment(
                    dataDoc.data.datos_json.fechaConstancia,
                    'DD/MM/YYYY'
                ).format('YYYY-MM-DD'),
            });
            setActividad(dataDoc.data.datos_json.actividad);
            setCondicionFiscal(
                tablaCondiciones.filter(
                    (x) =>
                        x.id ===
                        parseInt(dataDoc.data.datos_json.condicionFiscal.id)
                )[0]
            );
        }
        if (
            dataDoc.data.tipo ===
            'Constancia de Inscripción en Rentas de la Provincia'
        ) {
            setActividad(dataDoc.data.datos_json.actividad);
        }
        if (
            dataDoc.data.tipo ===
            'Constancia Del Tributo Económico Municipal Actualizada'
        ) {
            setActividad(dataDoc.data.datos_json.actividad);
            setFechaConstancia({
                fechaMuestra: moment(
                    dataDoc.data.datos_json.fechaConstancia,
                    'DD/MM/YYYY'
                ).format('DD/MM/YYYY'),
                fechaComparar: moment(
                    dataDoc.data.datos_json.fechaConstancia,
                    'DD/MM/YYYY'
                ).format('YYYY-MM-DD'),
            });
        }
        if (
            dataDoc.data.tipo ===
            'Certificación de no Estar Registrado Como Deudor Alimenticio'
        ) {
            setFechaCertificado({
                fechaMuestra: moment(
                    dataDoc.data.datos_json.fechaCersetFechaCertificado,
                    'DD/MM/YYYY'
                ).format('DD/MM/YYYY'),
                fechaComparar: moment(
                    dataDoc.data.datos_json.fechaCersetFechaCertificado,
                    'DD/MM/YYYY'
                ).format('YYYY-MM-DD'),
            });
        }
        if (dataDoc.data.tipo === 'Declaración Jurada de Liquidación Mensual') {
            setMontoConcesion(dataDoc.data.datos_json.montoConcesion);
        }
        if (domicilioComercial.status === 200) {
            setZonaExplotacion(domicilioComercial.data.zona.descripcion);
        } else {
            setZonaExplotacion(null);
        }
        setCondicionFiscalTit(condFiscal.data);
        const a = {
            id: condFiscal.data.condicionfiscal,
            condicion: condFiscal.data.condicion_fiscal,
        };
        setCondicionFiscal(a);
        setDataDetalleDocumentacion(dataDoc.data);
        setTipoDocumentacion({ descripcion: dataDoc.data.tipo });
        setPeriodo({
            fechaMuestra: moment(dataDoc.data.periodo, 'DD/MM/YYYY').format(
                'MM/YYYY'
            ),
            fechaComparar: moment(dataDoc.data.periodo, 'DD/MM/YYYY').format(
                'YYYY-MM'
            ),
        });
    };

    const handleTablaEstadoChange = (estado) => {
        setEstado(tablaEstado.filter((x) => x.id === parseInt(estado))[0]);
        if (fechaVencimiento.fechaComparar) {
            fechaVencimientoRef.current.setState({ inputValue: '' });
            setFechaVencimiento({ fechaComparar: null, fechaMuestra: null });
        }
    };

    const handleTablaTipoDocumentacionChange = (tipo) => {
        setTipoDocumentacion(
            tablaTipoDocumentacion.filter((x) => x.descripcion === tipo)[0]
        );
    };

    const handleConfirmarDetalleDocumentacion = async (event) => {
        event.preventDefault();
        Swal.fire({
            title: '¿Estás seguro de confirmar la documentación?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                const obtenerDocumento = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVODOCUMENTACION,
                    dataDetalleDocumentacion.id
                );
                if (obtenerDocumento.status === 200) {
                    var objeto = [];
                    if (
                        tipoDocumentacion.descripcion === 'Factura Electronica'
                    ) {
                        objeto = {
                            archivo: obtenerDocumento.data.archivo,
                            titular_agencia:
                                obtenerDocumento.data.titular_agencia,
                            estado_documentacion: estado.descripcion,
                            comentario: comentario,
                            periodo: moment(
                                '01/' + periodo.fechaMuestra,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            fecha_revision: moment().format('YYYY-MM-DD'),
                            fecha_vencimiento: fechaVencimiento.fechaComparar,
                            revisado_por: AuthenticationHelper.getUser(),
                            datos_json: {
                                importeFacturacionQuiniela:
                                    importeFacturacionQuiniela,
                                importeFacturacionTuqui:
                                    importeFacturacionTuqui,
                                tipoFactura: tipoFactura,
                                nroFactura: nroFactura,
                                fechaFactura: fechaFactura.fechaMuestra,
                            },
                            tipo: tipoDocumentacion.descripcion,
                        };
                    }
                    if (
                        tipoDocumentacion.descripcion ===
                        'Certificado de Cumplimiento Fiscal Vigente'
                    ) {
                        objeto = {
                            archivo: obtenerDocumento.data.archivo,
                            titular_agencia:
                                obtenerDocumento.data.titular_agencia,
                            estado_documentacion: estado.descripcion,
                            comentario: comentario,
                            periodo: moment(
                                '01/' + periodo.fechaMuestra,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            fecha_revision: moment().format('YYYY-MM-DD'),
                            fecha_vencimiento: fechaVencimiento.fechaComparar,
                            revisado_por: AuthenticationHelper.getUser(),
                            datos_json: {
                                ingresosBrutos: ingresosBrutos,
                                actividad: actividad,
                                fechaCertificado: fechaCertificado.fechaMuestra,
                            },
                            tipo: tipoDocumentacion.descripcion,
                        };
                    }
                    if (
                        tipoDocumentacion.descripcion ===
                        'Constancia de Inscripción en AFIP'
                    ) {
                        if (
                            condicionFiscal.condicion !==
                            condicionFiscalTit.condicion_fiscal
                        ) {
                            const condicionVieja = {
                                condicionfiscal: condicionFiscalTit.id,
                                titular:
                                    obtenerDocumento.data.titular_detalle
                                        .titular_detalle.id,
                                fecha_hasta: fechaDesde.fechaComparar,
                            };
                            await ConsultasAPI.ModificarObjeto(
                                URL_CONDICIONFISCALTITULAR,
                                condicionFiscalTit.id,
                                condicionVieja
                            );
                            const condicionNueva = {
                                condicionfiscal: condicionFiscal.id,
                                titular:
                                    obtenerDocumento.data.titular_detalle
                                        .titular_detalle.id,
                                fecha_creacion: fechaDesde.fechaComparar,
                            };
                            await ConsultasAPI.CrearObjeto(
                                URL_CONDICIONFISCALTITULAR,
                                condicionNueva
                            );
                        }
                        objeto = {
                            archivo: obtenerDocumento.data.archivo,
                            titular_agencia:
                                obtenerDocumento.data.titular_agencia,
                            estado_documentacion: estado.descripcion,
                            comentario: comentario,
                            periodo: moment(
                                '01/' + periodo.fechaMuestra,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            fecha_revision: moment().format('YYYY-MM-DD'),
                            fecha_vencimiento: fechaVencimiento.fechaComparar,
                            tipo: tipoDocumentacion.descripcion,
                            revisado_por: AuthenticationHelper.getUser(),
                            datos_json: {
                                condicionFiscal: condicionFiscal,
                                actividad: actividad,
                                fechaConstancia: fechaConstancia.fechaMuestra,
                            },
                        };
                    }
                    if (
                        tipoDocumentacion.descripcion ===
                        'Constancia de Inscripción en Rentas de la Provincia'
                    ) {
                        objeto = {
                            archivo: obtenerDocumento.data.archivo,
                            titular_agencia:
                                obtenerDocumento.data.titular_agencia,
                            estado_documentacion: estado.descripcion,
                            comentario: comentario,
                            periodo: moment(
                                '01/' + periodo.fechaMuestra,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            fecha_revision: moment().format('YYYY-MM-DD'),
                            tipo: tipoDocumentacion.descripcion,
                            fecha_vencimiento: fechaVencimiento.fechaComparar,
                            revisado_por: AuthenticationHelper.getUser(),
                            datos_json: {
                                actividad: actividad,
                            },
                        };
                    }
                    if (
                        tipoDocumentacion.descripcion ===
                        'Constancia Del Tributo Económico Municipal Actualizada'
                    ) {
                        objeto = {
                            archivo: obtenerDocumento.data.archivo,
                            titular_agencia:
                                obtenerDocumento.data.titular_agencia,
                            periodo: moment(
                                '01/' + periodo.fechaMuestra,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            estado_documentacion: estado.descripcion,
                            comentario: comentario,
                            fecha_revision: moment().format('YYYY-MM-DD'),
                            tipo: tipoDocumentacion.descripcion,
                            fecha_vencimiento: fechaVencimiento.fechaComparar,
                            revisado_por: AuthenticationHelper.getUser(),
                            datos_json: {
                                condicionFiscal: condicionFiscal,
                                fechaConstancia: fechaConstancia.fechaMuestra,
                                actividad: actividad,
                            },
                        };
                    }
                    if (
                        tipoDocumentacion.descripcion ===
                        'Certificación de no Estar Registrado Como Deudor Alimenticio'
                    ) {
                        objeto = {
                            archivo: obtenerDocumento.data.archivo,
                            titular_agencia:
                                obtenerDocumento.data.titular_agencia,
                            estado_documentacion: estado.descripcion,
                            comentario: comentario,
                            periodo: moment(
                                '01/' + periodo.fechaMuestra,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            fecha_revision: moment().format('YYYY-MM-DD'),
                            tipo: tipoDocumentacion.descripcion,
                            fecha_vencimiento: fechaVencimiento.fechaComparar,
                            revisado_por: AuthenticationHelper.getUser(),
                            datos_json: {
                                fechaCertificado: fechaCertificado.fechaMuestra,
                                montoConcesion: montoConcesion,
                            },
                        };
                    }
                    if (
                        tipoDocumentacion.descripcion ===
                        'Declaración Jurada de Liquidación Mensual'
                    ) {
                        objeto = {
                            archivo: obtenerDocumento.data.archivo,
                            titular_agencia:
                                obtenerDocumento.data.titular_agencia,
                            estado_documentacion: estado.descripcion,
                            comentario: comentario,
                            periodo: moment(
                                '01/' + periodo.fechaMuestra,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            fecha_revision: moment().format('YYYY-MM-DD'),
                            tipo: tipoDocumentacion.descripcion,
                            fecha_vencimiento: fechaVencimiento.fechaComparar,
                            revisado_por: AuthenticationHelper.getUser(),
                            datos_json: {
                                fechaCertificado: fechaCertificado.fechaMuestra,
                                montoConcesion: montoConcesion,
                            },
                        };
                    }
                    //hasta aqui esta creado el objeto para hacer la modificacion de la fecha de vencimiento.
                    try {
                        await ConsultasAPI.ModificarObjeto(
                            URL_ARCHIVODOCUMENTACION,
                            dataDetalleDocumentacion.id,
                            objeto
                        );
                        if (objeto['estado_documentacion'] === 'Rechazado') {
                            let subject =
                                'Documentacion de agencia N°: ' +
                                obtenerDocumento.data.titular_detalle.agencia_detalle.numero_agencia.toString() +
                                '-' +
                                String(
                                    obtenerDocumento.data.titular_detalle
                                        .agencia_detalle.numero_subagencia
                                ).padStart(3, '0') +
                                ' Rechazada';
                            let message =
                                'Buen dia ' +
                                obtenerDocumento.data.titular_detalle
                                    .titular_detalle.persona_detalle.apellido +
                                ' ' +
                                obtenerDocumento.data.titular_detalle
                                    .titular_detalle.persona_detalle.nombre +
                                ', La documentacion "' +
                                objeto['tipo'] +
                                '" correspondiente al periodo de ' +
                                periodo.fechaMuestra +
                                ' fue rechazada. El motivo del rechazo es: ' +
                                objeto['comentario'];
                            let mail =
                                obtenerDocumento.data.titular_detalle
                                    .titular_detalle.persona_detalle.email;
                            let params = {
                                subject: subject,
                                message: message,
                                mail: mail,
                            };
                            await ConsultasAPI.CrearObjeto(
                                URL_ARCHIVODOCUMENTACION + 'send_text_email/',
                                params
                            );
                        }
                        // else if (fechaVencimiento.fechaComparar) {
                        //     let params = fechaVencimiento.fechaComparar + '/' + moment("01/" + periodo.fechaMuestra, "DD/MM/YYYY").format("YYYY-MM");
                        //     const periodos = await ConsultasAPI.ObtenerObjeto(URL_ARCHIVODOCUMENTACION + 'obtenerPeriodos/', params);
                        //     let vector_periodos = periodos.data.results;
                        //     objeto['estado_documentacion'] = "Pendiente de Revision"
                        //     let objetoModificado = objeto;
                        //     vector_periodos.forEach(async (period) => {
                        //         if (period !== objeto['periodo']) {
                        //             var documento = {
                        //                 periodo: period,
                        //                 tipo: objeto['tipo']
                        //             }
                        //             const crear = await ConsultasAPI.CrearObjeto(URL_ARCHIVODOCUMENTACION + 'obtenerDocumentoTipoPeriodo/', documento)
                        //             if (crear.data) {
                        //                 objetoModificado['periodo'] = period
                        //                 objetoModificado['datos_json'] = null
                        //                 await ConsultasAPI.CrearObjeto(URL_ARCHIVODOCUMENTACION, objeto);
                        //             }
                        //         };
                        //     });
                        // };
                        props.cargar(
                            null,
                            props.data[0].titular_detalle.agencia_detalle
                                .numero_agencia +
                            ',' +
                            props.data[0].titular_detalle.agencia_detalle
                                .numero_subagencia,
                            moment(
                                '01/' + periodo.fechaMuestra,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD')
                        );
                        // cargarTabla();
                        handleCloseModalDetalleDocumentacion(event);
                        creacionOrdenPago(
                            obtenerDocumento.data.titular_detalle
                                .agencia_detalle.numero_agencia +
                            ',' +
                            obtenerDocumento.data.titular_detalle
                                .agencia_detalle.numero_subagencia,
                            moment(
                                obtenerDocumento.data.periodo,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            obtenerDocumento.data.titular_agencia
                        );
                        setIsLoading(false);
                        Swal.fire({
                            title: 'Se realizo con exito la operación',
                            icon: 'success',
                        });
                        await ConsultasAPI.CrearObjeto(
                            URL_RETENCION + 'crearRetencionesOrdenesDePago/',
                            1
                        );
                    } catch (error) {
                        console.info(error);
                        Swal.fire({
                            title: 'No se pudo realizar la operación',
                            icon: 'error',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'No se pudo realizar la operación',
                        text: 'No se encuentra el archivo que desea editar',
                        icon: 'error',
                    });
                }
            } else {
                handleCloseModalDetalleDocumentacion(event);
            }
        });
    };

    const creacionOrdenPago = async (agencia, periodo, titular) => {
        var ag = agencia + ':' + periodo;
        const estadoDoc = await ConsultasAPI.ObtenerObjeto(
            URL_ARCHIVODOCUMENTACION + 'obtenerDocumentacionCompletaAgencia/',
            ag
        );
        if (estadoDoc.status === 204) {
            const ordenPago = {
                periodo_comision: periodo,
                estado_documentacion: 'Desaprobado',
                estado_comision: 'Pendiente',
                titular_agencia: titular,
                legajo: 'Incompleto',
                // numero_orden:"",
            };
            const ordenCompleta = await ConsultasAPI.CrearObjeto(
                URL_ORDENPAGO + 'crearOrdenPago/',
                ordenPago
            );
            return ordenCompleta.status;
        } else {
            var rechazo = 0;
            var aprobadoAg = 0;
            var aprobadoSag = 0;
            var pendiente = 0;
            estadoDoc.data.forEach((doc) => {
                if (doc.estado_documentacion === 'Rechazado') {
                    rechazo += 1;
                }
                if (doc.estado_documentacion === 'Pendiente de Revision') {
                    pendiente += 1;
                }
                if (
                    doc.estado_documentacion === 'Aprobado' &&
                    doc.titular_detalle.agencia_detalle.numero_subagencia === 0
                ) {
                    aprobadoAg += 1;
                } else {
                    aprobadoSag += 1;
                }
            });
            if (rechazo === 0 || aprobadoAg === 7 || aprobadoSag === 6) {
                if (pendiente !== 0) {
                    const ordenPago = {
                        periodo_comision: periodo,
                        estado_documentacion: 'Aprobado',
                        estado_comision: 'Pendiente',
                        titular_agencia: titular,
                        legajo: 'Incompleto',
                        // numero_orden:"",
                    };
                    const ordenCompleta = await ConsultasAPI.CrearObjeto(
                        URL_ORDENPAGO + 'crearOrdenPago/',
                        ordenPago
                    );
                    return ordenCompleta.status;
                } else {
                    const ordenPago = {
                        periodo_comision: periodo,
                        estado_documentacion: 'Aprobado',
                        estado_comision: 'Pendiente',
                        titular_agencia: titular,
                        legajo: 'Completo',
                        // numero_orden:"",
                    };
                    const ordenCompleta = await ConsultasAPI.CrearObjeto(
                        URL_ORDENPAGO + 'crearOrdenPago/',
                        ordenPago
                    );
                    return ordenCompleta.status;
                }
            } else {
                const ordenPago = {
                    periodo_comision: periodo,
                    estado_documentacion: 'Desaprobado',
                    estado_comision: 'Pendiente',
                    titular_agencia: titular,
                    legajo: 'Completo',
                    // numero_orden:"",
                };
                const ordenCompleta = await ConsultasAPI.CrearObjeto(
                    URL_ORDENPAGO + 'crearOrdenPago/',
                    ordenPago
                );
                return ordenCompleta.status;
            }
        }
    };

    const handleOpenAgregarDocumentacion = () => {
        setShowModalAgregarDocumentacion(true);
    };
    const handleCloseAgregarDocumentacion = (event) => {
        event.preventDefault();
        setTipoDocumentacion({ id: '', descripcion: '' });
        setArchivoPDF([]);
        setFechaVencimiento({ fechaComparar: null, fechaMuestra: null });
        setShowModalAgregarDocumentacion(false);
    };

    const handleGuardarEdicion = (event) => {
        Swal.fire({
            title: '¿Estás seguro de editar el estado de la documentación?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                var objeto = [];
                if (dataDetalleDocumentacion.tipo === 'Factura Electronica') {
                    objeto = {
                        comentario: comentario,
                        estado_documentacion: estado.descripcion,
                        revisado_por: AuthenticationHelper.getUser(),
                        datos_json: {
                            importeFacturacionQuiniela:
                                importeFacturacionQuiniela,
                            importeFacturacionTuqui: importeFacturacionTuqui,
                            tipoFactura: tipoFactura,
                            nroFactura: nroFactura,
                            fechaFactura: fechaFactura.fechaMuestra,
                        },
                    };
                }
                if (
                    dataDetalleDocumentacion.tipo ===
                    'Certificado de Cumplimiento Fiscal Vigente'
                ) {
                    objeto = {
                        comentario: comentario,
                        estado_documentacion: estado.descripcion,
                        revisado_por: AuthenticationHelper.getUser(),
                        datos_json: {
                            fechaCertificado: fechaCertificado.fechaMuestra,
                        },
                    };
                }
                if (
                    dataDetalleDocumentacion.tipo ===
                    'Constancia de Inscripción en AFIP'
                ) {
                    objeto = {
                        comentario: comentario,
                        estado_documentacion: estado.descripcion,
                        revisado_por: AuthenticationHelper.getUser(),
                        datos_json: {
                            fechaConstancia: fechaConstancia.fechaMuestra,
                            actividad: actividad,
                            condicionFiscal: condicionFiscal,
                        },
                    };
                }
                if (
                    dataDetalleDocumentacion.tipo ===
                    'Constancia de Inscripción en Rentas de la Provincia'
                ) {
                    objeto = {
                        comentario: comentario,
                        estado_documentacion: estado.descripcion,
                        revisado_por: AuthenticationHelper.getUser(),
                        datos_json: {
                            actividad: actividad,
                        },
                    };
                }
                if (
                    dataDetalleDocumentacion.tipo ===
                    'Constancia Del Tributo Económico Municipal Actualizada'
                ) {
                    objeto = {
                        comentario: comentario,
                        estado_documentacion: estado.descripcion,
                        revisado_por: AuthenticationHelper.getUser(),
                        datos_json: {
                            actividad: actividad,
                            fechaConstancia: fechaConstancia.fechaMuestra,
                        },
                    };
                }
                if (
                    dataDetalleDocumentacion.tipo ===
                    'Certificación de no Estar Registrado Como Deudor Alimenticio'
                ) {
                    objeto = {
                        comentario: comentario,
                        estado_documentacion: estado.descripcion,
                        revisado_por: AuthenticationHelper.getUser(),
                        datos_json: {
                            fechaCertificado: fechaCertificado.fechaMuestra,
                        },
                    };
                }
                if (
                    dataDetalleDocumentacion.tipo ===
                    'Declaración Jurada de Liquidación Mensual'
                ) {
                    objeto = {
                        comentario: comentario,
                        estado_documentacion: estado.descripcion,
                        revisado_por: AuthenticationHelper.getUser(),
                        datos_json: {
                            montoConcesion: montoConcesion,
                        },
                    };
                }
                try {
                    const cambiarEstado = await ConsultasAPI.ModificarObjeto(
                        URL_ARCHIVODOCUMENTACION + 'editarDocumentacion/',
                        dataDetalleDocumentacion.id,
                        objeto
                    );
                    if (cambiarEstado.status === 200) {
                        if (
                            estadoViejo.descripcion === 'Aprobado' &&
                            estado.descripcion === 'Rechazado'
                        ) {
                            let subject =
                                'Documentacion de agencia N°: ' +
                                dataDetalleDocumentacion.titular_detalle.agencia_detalle.numero_agencia.toString() +
                                '-' +
                                String(
                                    dataDetalleDocumentacion.titular_detalle
                                        .agencia_detalle.numero_subagencia
                                ).padStart(3, '0') +
                                ' Rechazada';
                            let message =
                                'Buen dia ' +
                                dataDetalleDocumentacion.titular_detalle
                                    .titular_detalle.persona_detalle.apellido +
                                ' ' +
                                dataDetalleDocumentacion.titular_detalle
                                    .titular_detalle.persona_detalle.nombre +
                                ', La documentacion "' +
                                dataDetalleDocumentacion.tipo +
                                '" correspondiente al periodo de ' +
                                moment(
                                    dataDetalleDocumentacion.periodo,
                                    'DD/MM/YYYY'
                                ).format('MM/YYYY') +
                                ' fue rechazada. El motivo del rechazo es: ' +
                                objeto['comentario'];
                            let mail =
                                dataDetalleDocumentacion.titular_detalle
                                    .titular_detalle.persona_detalle.email;
                            let params = {
                                subject: subject,
                                message: message,
                                mail: mail,
                            };
                            await ConsultasAPI.CrearObjeto(
                                URL_ARCHIVODOCUMENTACION + 'send_text_email/',
                                params
                            );
                        }
                        const ordenPago = {
                            periodo_comision: moment(
                                dataDetalleDocumentacion.periodo,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM-DD'),
                            estado_documentacion: 'Desaprobado',
                            estado_comision: 'Pendiente',
                            titular_agencia:
                                dataDetalleDocumentacion.titular_agencia,
                            legajo: 'Incompleto',
                            // numero_orden:"",
                        };
                        await ConsultasAPI.CrearObjeto(
                            URL_ORDENPAGO + 'crearOrdenPago/',
                            ordenPago
                        );
                        Swal.fire({
                            title: 'Se realizo con exito la operación',
                            icon: 'success',
                        });
                        handleCloseModalDetalleDocumentacion(event);
                        const ag = (dataDetalleDocumentacion.titular_detalle
                            .agencia_detalle.numero_agencia).toString() + ',' + (dataDetalleDocumentacion.titular_detalle
                                .agencia_detalle.numero_subagencia).toString()

                        props.cargar(null, ag)
                    } else {
                        Swal.fire({
                            title: 'No se realizo con exito la operación',
                            icon: 'error',
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'No se realizo con exito la operación',
                        icon: 'error',
                    });
                    console.info(error);
                }
            }
        });
    };

    const handleArchivoCSVChange = (event) => {
        const archivo = {
            path: event.target.files[0],
        };
        setArchivoPDF(archivo);
    };

    const handleSubmitSubirArchivo = (event) => {
        event.preventDefault();
        subirArchivo();
        handleCloseAgregarDocumentacion(event);
    };
    const concatCodigo = (a, b) => {
        let codigoAgencia = '';
        if (a < 10) {
            codigoAgencia = '0' + a.toString();
            if (b < 10) {
                codigoAgencia = codigoAgencia + '00' + b.toString();
            } else {
                codigoAgencia = codigoAgencia + '0' + b.toString();
            }
        } else {
            codigoAgencia = a.toString();
            if (b < 10) {
                codigoAgencia = codigoAgencia + '00' + b.toString();
            } else {
                codigoAgencia = codigoAgencia + '0' + b.toString();
            }
        }
        return codigoAgencia;
    };
    const subirArchivo = () => {
        Swal.fire({
            title: '¿Estás seguro de subir la documentación?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                const nombreCarpeta = props.data[0]
                    ? await concatCodigo(
                        props.data[0].titular_detalle.agencia_detalle
                            .numero_agencia,
                        props.data[0].titular_detalle.agencia_detalle
                            .numero_subagencia
                    )
                    : await concatCodigo(
                        props.dataAgencia.numero_agencia,
                        props.dataAgencia.numero_subagencia
                    );
                formData.append('path', archivoPDF.path);
                formData.append(
                    'nombre_subcarpeta',
                    nombreCarpeta +
                    '/documentacion/' +
                    moment('01/' + filtroPeriodo, 'DD/MM/YYYY').format(
                        'YYYY-MM'
                    )
                );
                const response = await ConsultasAPI.CrearObjetoArchivo(
                    URL_ARCHIVO,
                    formData
                );
                if (response.status === 201) {
                    const archivo = {
                        archivo: response.data.id,
                        titular_agencia: props.data[0]
                            ? props.data[0].titular_detalle.id
                            : props.dataAgencia.titular_agencia,
                        tipo: tipoDocumentacion.descripcion,
                        periodo: moment(
                            '01/' + filtroPeriodo,
                            'DD/MM/YYYY'
                        ).format('YYYY-MM-DD'),
                    };
                    const creacion = await ConsultasAPI.CrearObjeto(
                        URL_ARCHIVODOCUMENTACION,
                        archivo
                    );
                    if (creacion.status === 201) {
                        props.cargar(
                            null,
                            props.data[0].titular_detalle.agencia_detalle
                                .numero_agencia +
                            ',' +
                            props.data[0].titular_detalle.agencia_detalle
                                .numero_subagencia,
                            moment('01/' + filtroPeriodo, 'DD/MM/YYYY').format(
                                'YYYY-MM-DD'
                            )
                        );
                        Swal.fire({
                            title: 'Se realizo con exito la operación',
                            icon: 'success',
                        });
                    } else {
                        Swal.fire({
                            title: 'No se realizo la operacion',
                            icon: 'error',
                        });
                    }
                }
            } else {
            }
        });
    };
    const [totalPages, setTotalPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setTotalPages(numPages);
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };
    const handleTablaCondicionChange = (condicion) => {
        setCondicionFiscal(
            tablaCondiciones.filter((x) => x.id === parseInt(condicion))[0]
        );
    };
    const handleNextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    useEffect(() => {
        if (importeFacturacionQuiniela !== '') {
            if (parseFloat(importeFacturacionQuiniela) === comisionQ) {
                setRestaComisionQ(0);
            } else {
                setRestaComisionQ(
                    parseFloat(
                        comisionQ - parseFloat(importeFacturacionQuiniela)
                    ).toFixed(2)
                );
            }
        } else {
            setRestaComisionQ(0);
        }
    }, [importeFacturacionQuiniela, props.show]);

    useEffect(() => {
        if (importeFacturacionTuqui !== '') {
            if (parseFloat(importeFacturacionTuqui) === comisionT) {
                setRestaComisionT(0);
            } else {
                setRestaComisionT(
                    parseFloat(
                        comisionT -
                        parseFloat(importeFacturacionTuqui).toFixed(2)
                    )
                );
            }
        } else {
            setRestaComisionT(0);
        }
    }, [importeFacturacionTuqui, props.show]);

    useEffect(() => {
        if (montoConcesion !== '') {
            if (parseFloat(montoConcesion) === comisionQ) {
                setEstado(
                    tablaEstado.filter((x) => x.descripcion === 'Aprobado')[0]
                );
                setRestaConcesion(0);
            } else {
                setEstado(
                    tablaEstado.filter((x) => x.descripcion === 'Rechazado')[0]
                );
                setRestaConcesion(
                    parseFloat(comisionQ - parseFloat(montoConcesion)).toFixed(
                        2
                    )
                );
            }
        } else {
            setEstado({ id: '', descripcion: '' });
            setRestaConcesion(0);
        }
    }, [montoConcesion]);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom + 0.1);
    };

    useEffect(() => {
        if (nroFacturaVenta !== '' && nroFacturaNro !== '') {
            setNroFactura(
                nroFacturaVenta.padStart(5, '0') +
                '-' +
                nroFacturaNro.padStart(8, '0')
            );
        } else {
            setNroFactura('');
        }
    }, [nroFacturaVenta, nroFacturaNro]);

    const descargarDocumentos = async () => {
        const zip = new JSZip();
        let periodo = moment('01/' + data[0].periodo, 'DD/MM/YYYY');
        let codigoAgencia = generaCodigo(
            props.data[0]
                ? props.data[0].titular_detalle.agencia_detalle.numero_agencia
                : props.dataAgencia
                    ? props.dataAgencia.numero_agencia
                    : 0,
            props.data[0]
                ? props.data[0].titular_detalle.agencia_detalle
                    .numero_subagencia
                : props.dataAgencia
                    ? props.dataAgencia.numero_subagencia
                    : 0
        );
        await Promise.all(
            data.map(async (item) => {
                const response = await fetch(item.archivo);
                const fileData = await response.blob();
                // let periodo = item.periodo.replace('/', '-');
                let nombre = item.archivo.split('/');
                zip.file(nombre.pop(), fileData);
            })
        );
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(
                content,
                codigoAgencia + '-' + periodo.format('MMMM YYYY') + '.zip'
            );
        });
    };

    const generaCodigo = (agencia, subagencia) => {
        let agZerofielded = String(agencia).padStart(2, '0');
        let subagZerofielded = String(subagencia).padStart(3, '0');
        return agZerofielded + subagZerofielded;
    };

    var valid = function (current) {
        if (estado.descripcion === 'Rechazado') {
            return true;
        } else {
            const primerdia = moment().startOf('month');
            return current.isAfter(primerdia) || current.isSame(primerdia);
        }
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
    };
    const handleCrearMotivo = async (event) => {
        event.preventDefault();
        try {
            let objeto = {
                concepto: nuevoMotivo,
            };
            await ConsultasAPI.CrearObjeto(URL_CONCEPTO_RECHAZADO, objeto);
            Swal.fire({
                title: 'Se realizo con exito la operación',
                icon: 'success',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCloseModalAdd();
                }
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: 'Ocurrio un error al crear el motivo',
                icon: 'error',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCloseModalAdd();
                }
            });
        }
    };

    const handleCloseModalAdd = async () => {
        setShowModalAdd(false);
        setNuevoMotivo('');
        cargarTablaMotivoRechazo();
    };
    return (
        <>
            <Modal
                show={props.show}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>Documentacion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="d-flex mb-3">
                        <Form.Group as={Col} md="4">
                            <Form.Label>Agencia:</Form.Label>
                            <Form.Control
                                placeholder="Ingrese Agencia"
                                value={
                                    props.data[0]
                                        ? props.data[0].titular_detalle
                                            .agencia_detalle.numero_agencia
                                        : props.dataAgencia
                                            ? props.dataAgencia.numero_agencia
                                            : ''
                                }
                                readOnly={true}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Subagencia:</Form.Label>
                            <Form.Control
                                placeholder="Ingrese Agencia"
                                value={
                                    props.data[0]
                                        ? props.data[0].titular_detalle
                                            .agencia_detalle.numero_subagencia
                                        : props.dataAgencia
                                            ? props.dataAgencia.numero_subagencia
                                            : ''
                                }
                                readOnly={true}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Codigo:</Form.Label>
                            <Form.Control
                                placeholder="Ingrese Agencia"
                                readOnly={true}
                                value={
                                    props.data[0]
                                        ? props.data[0].titular_detalle
                                            .agencia_detalle
                                            .numero_subagencia < 10
                                            ? props.data[0].titular_detalle
                                                .agencia_detalle
                                                .numero_agencia +
                                            '00' +
                                            props.data[0].titular_detalle
                                                .agencia_detalle
                                                .numero_subagencia
                                            : props.data[0].titular_detalle
                                                .agencia_detalle
                                                .numero_agencia +
                                            '0' +
                                            props.data[0].titular_detalle
                                                .agencia_detalle
                                                .numero_subagencia
                                        : props.dataAgencia
                                            ? props.dataAgencia
                                                .numero_subagencia < 10
                                                ? props.dataAgencia
                                                    .numero_agencia +
                                                '00' +
                                                props.dataAgencia
                                                    .numero_subagencia
                                                : props.dataAgencia
                                                    .numero_agencia +
                                                '0' +
                                                props.dataAgencia
                                                    .numero_subagencia
                                            : ''
                                }
                            />
                        </Form.Group>
                    </Row>
                    <Row className="d-flex mb-3">
                        <Form.Group as={Col} md="4">
                            <Form.Label>Titular:</Form.Label>
                            <Form.Control
                                placeholder="Ingrese Agencia"
                                value={
                                    props.data[0]
                                        ? props.data[0].titular_detalle
                                            .titular_detalle.persona_detalle
                                            .nombre +
                                        ' ' +
                                        props.data[0].titular_detalle
                                            .titular_detalle.persona_detalle
                                            .apellido
                                        : props.dataAgencia
                                            ? props.dataAgencia.titular
                                                ? props.dataAgencia.titular
                                                    .persona_detalle.nombre +
                                                ' ' +
                                                props.dataAgencia.titular
                                                    .persona_detalle.apellido
                                                : ''
                                            : ''
                                }
                                readOnly={true}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>CUIT Titular:</Form.Label>
                            <Form.Control
                                placeholder="Ingrese Agencia"
                                value={
                                    props.data[0]
                                        ? props.data[0].titular_detalle
                                            .titular_detalle.persona_detalle
                                            .cuit
                                        : props.dataAgencia
                                            ? props.dataAgencia.titular
                                                ? props.dataAgencia.titular
                                                    .persona_detalle.cuit
                                                : ''
                                            : ''
                                }
                                readOnly={true}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="d-flex mb-3">
                        <Form.Group as={Col} md="4" />
                        <Form.Group as={Col} md="4">
                            <Form.Label>Periodo:</Form.Label>
                            <Datetime
                                timeFormat={false}
                                dateFormat="MM/YYYY"
                                inputProps={{
                                    required: true,
                                    placeholder: filtroPeriodo,
                                    readOnly: true,
                                }}
                                value={filtroPeriodo}
                                closeOnSelect={true}
                                onChange={(momentDate) => {
                                    const fechaMuestra =
                                        momentDate.format('MM/YYYY');
                                    setFiltroPeriodo(fechaMuestra);
                                }}
                            />
                        </Form.Group>
                        {props.titulo === 'Agencia' &&
                            AuthenticationHelper.getRol() !== 'AUDITORIA' &&
                            AuthenticationHelper.getRol() !== 'CONTADURIA' ? (
                            <Form.Group as={Col} md="3">
                                <Button
                                    className="botonCPA"
                                    style={{
                                        marginTop: '31px',
                                        marginLeft: '10px',
                                    }}
                                    onClick={handleOpenAgregarDocumentacion}
                                >
                                    Agregar Documentación
                                </Button>
                            </Form.Group>
                        ) : null}
                    </Row>
                    <MaterialReactTable
                        className="w-100"
                        columns={columns}
                        data={data}
                        muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: '0',
                                border: '1px dashed #e0e0e0',
                            },
                        }}
                        muiTableBodyProps={{
                            sx: (theme) => ({
                                '& tr:nth-of-type(odd)': {
                                    backgroundColor: darken(
                                        theme.palette.background.default,
                                        0.1
                                    ),
                                },
                                fontFamily: 'Roboto, sans-serif', // Configuración de la tipografía para las filas pares
                            }),
                        }}
                        initialState={{
                            columnVisibility: { id: false, archivo: false, primera: false },
                            showColumnFilters: true,
                        }} //hide firstName column by default
                        editingMode="modal" //default
                        enableRowSelection={false} //enable some features
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableColumnActions={false}
                        enableSorting={false}
                        displayColumnDefOptions={{
                            'mrt-row-actions': { size: 10 },
                        }} //change width of actions column to 300px
                        enableRowActions
                        positionActionsColumn="last"
                        renderRowActions={({ row }) => (
                            <div className="d-flex">
                                <a href={row.original.archivo} target="blank">
                                    <IconButton
                                        title="Ver PDF"
                                        onClick={() => {
                                            setPath(row.original.archivo);
                                        }}
                                    >
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                </a>
                                {row.original.estado ===
                                    'Pendiente de Revision' ? null : (
                                    <>
                                        <IconButton
                                            title="Ver Detalle Documentación"
                                            onClick={() => {
                                                handleOpenDetalleDocumentacion(
                                                    row.original
                                                );
                                                setPath(row.original.archivo);
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                        {row.original.estado !== 'Pendiente de Revision'
                                            && row.original.primera &&
                                            (AuthenticationHelper.getRol() === 'GERENCIA-QUINIELA'
                                                || AuthenticationHelper.getRol() === 'CONTADURIA'
                                                || AuthenticationHelper.getRol() === 'ADMINISTRADOR') ? (
                                            <IconButton
                                                title="Editar Documentación"
                                                onClick={() => {
                                                    handleEditarDetalleDocumentacion(
                                                        row.original
                                                    );
                                                    setPath(
                                                        row.original.archivo
                                                    );
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        ) : null}
                                    </>
                                )}
                                {props.titulo !== 'Agencia' &&
                                    row.original.estado ===
                                    'Pendiente de Revision' ? (
                                    <IconButton
                                        title="Editar Documentación AA"
                                        onClick={() => {
                                            handleOpenDetalleDocumentacion(
                                                row.original,
                                                row.original.archivo
                                            );
                                            setPath(row.original.archivo);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                ) : null}


                                {
                                    AuthenticationHelper.getRol() === "ADMINISTRADOR" || AuthenticationHelper.getRol() === "GERENCIA-QUINIELA" ?
                                        (
                                            <IconButton
                                                title="Baja Documentación"
                                                onClick={() => {
                                                    handleOpenModalDarBajaDocumentacion(
                                                        row.original
                                                    );
                                                    //setPath(row.original.archivo);
                                                }}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        )
                                        :
                                        null
                                }





                            </div>
                        )}
                        manualPagination
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10],
                        }}
                        enablePagination={true}
                        localization={MRT_Localization_ES}
                        rowCount={count}
                        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                        enableGlobalFilter={false} //turn off a feature
                        manualFiltering //turn off client-side filtering
                        onColumnFiltersChange={(value) => {
                            setColumnFilters(value);
                        }} //hoist internal columnFilters state to your state
                        state={{
                            columnFilters,
                            pagination,
                        }}
                    />
                    {data.length > 0 ? (
                        <Row className="d-flex justify-content-end mb-3">
                            <Form.Group as={Col} md="4">
                                <button
                                    className="botonCPA"
                                    style={{
                                        marginTop: '31px',
                                        marginLeft: '10px',
                                    }}
                                    onClick={() => {
                                        descargarDocumentos();
                                    }}
                                >
                                    Descargar Archivos
                                </button>
                            </Form.Group>
                        </Row>
                    ) : null}
                </Modal.Body>
            </Modal>
            <Modal
                fullscreen
                show={showModalDetalleDocumentacion}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton
                    onClick={handleCloseModalDetalleDocumentacion}
                >
                    <Modal.Title>Detalle Documentacion</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex">
                    {imagen ? (
                        <div
                            style={{
                                width: '100%',
                                border: '1px solid #ccc',
                                padding: '10px',
                                height: '105vh',
                                marginRight: '10px',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Image src={path} style={{ width: '100%' }} />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <SkipPreviousIcon
                                onClick={handlePreviousPage}
                                disabled={pageNumber <= 1}
                                color={pageNumber <= 1 ? 'disabled' : 'primary'}
                            >
                                Previous Page
                            </SkipPreviousIcon>
                            <span>
                                Pagina {pageNumber} de {totalPages}
                            </span>
                            <SkipNextIcon
                                onClick={handleNextPage}
                                disabled={pageNumber >= totalPages}
                                color={
                                    pageNumber >= totalPages
                                        ? 'disabled'
                                        : 'primary'
                                }
                            >
                                Next Page
                            </SkipNextIcon>
                            <div>
                                <ZoomInIcon
                                    onClick={handleZoomIn}
                                    color="primary"
                                />
                                <ZoomOutIcon
                                    onClick={handleZoomOut}
                                    color="primary"
                                />
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Document
                                    file={path}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page
                                        pageNumber={pageNumber}
                                        scale={zoom}
                                    />
                                </Document>
                            </div>
                        </div>
                    )}
                    <Form
                        onSubmit={handleConfirmarDetalleDocumentacion}
                        style={{ width: '150%' }}
                    >
                        <Row className="d-flex mb-3">
                            <Form.Group as={Col} md="12">
                                <Form.Label>Titular:</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    value={
                                        props.data[0]
                                            ? props.data[0].titular_detalle
                                                .titular_detalle
                                                .persona_detalle.nombre +
                                            ' ' +
                                            props.data[0].titular_detalle
                                                .titular_detalle
                                                .persona_detalle.apellido +
                                            ' - ' +
                                            props.data[0].titular_detalle
                                                .titular_detalle
                                                .persona_detalle.cuit
                                            : props.dataAgencia
                                                ? props.dataAgencia.titular
                                                    ? props.dataAgencia.titular
                                                        .persona_detalle
                                                        .nombre +
                                                    ' ' +
                                                    props.dataAgencia.titular
                                                        .persona_detalle
                                                        .apellido +
                                                    ' - ' +
                                                    props.dataAgencia.titular
                                                        .persona_detalle.cuit
                                                    : ''
                                                : ''
                                    }
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>Periodo de Comision:</Form.Label>
                                <Form.Control
                                    value={periodo.fechaMuestra}
                                    readOnly={true}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Fecha de Revision:</Form.Label>
                                <Form.Control
                                    value={moment().format('DD/MM/YYYY')}
                                    readOnly={true}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="d-flex mb-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>Tipo Documentación:</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={tipoDocumentacion.descripcion}
                                    onChange={(event) => {
                                        handleTablaTipoDocumentacionChange(
                                            event.target.value
                                        );
                                    }}
                                >
                                    <option value="" hidden>
                                        Elegir Tipo de Documentacion
                                    </option>
                                    {tablaTipoDocumentacion.length > 0
                                        ? tablaTipoDocumentacion.map((tipo) => (
                                            <option
                                                key={tipo.descripcion}
                                                value={tipo.descripcion}
                                            >
                                                {tipo.descripcion}
                                            </option>
                                        ))
                                        : null}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Fecha de Recepción:</Form.Label>
                                <Form.Control
                                    value={
                                        dataDetalleDocumentacion.fecha_creacion
                                    }
                                    readOnly={true}
                                />
                            </Form.Group>
                        </Row>
                        {tipoDocumentacion.descripcion ===
                            'Factura Electronica' ? (
                            <>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Importe Facturación Quiniela:{' '}
                                            {requiredSymbol}
                                        </Form.Label>
                                        {restaComisionQ !== 0 ? (
                                            <Form.Label
                                                style={{
                                                    color: 'grey',
                                                    width: '100%',
                                                }}
                                            >
                                                Diferencia: $ {restaComisionQ}
                                            </Form.Label>
                                        ) : null}
                                        <Form.Control
                                            placeholder="Ingrese Importe"
                                            type="number"
                                            value={
                                                dataDetalleDocumentacion.datos_json &&
                                                    !editar
                                                    ? dataDetalleDocumentacion
                                                        .datos_json
                                                        .importeFacturacionQuiniela
                                                    : importeFacturacionQuiniela
                                            }
                                            readOnly={
                                                dataDetalleDocumentacion.datos_json &&
                                                    !editar
                                                    ? true
                                                    : false
                                            }
                                            required={
                                                estado.descripcion ===
                                                    'Rechazado'
                                                    ? false
                                                    : true
                                            }
                                            onChange={(event) => {
                                                setImporteFacturacionQuiniela(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Importe Facturación Tuqui:
                                            {requiredSymbol}
                                        </Form.Label>
                                        {restaComisionT !== 0 ? (
                                            <Form.Label
                                                style={{
                                                    color: 'grey',
                                                    width: '100%',
                                                }}
                                            >
                                                Diferencia: $ {restaComisionT}
                                            </Form.Label>
                                        ) : null}
                                        <Form.Control
                                            placeholder="Ingrese Importe"
                                            type="number"
                                            required={
                                                estado.descripcion ===
                                                    'Rechazado'
                                                    ? false
                                                    : true
                                            }
                                            value={
                                                dataDetalleDocumentacion.datos_json &&
                                                    !editar
                                                    ? dataDetalleDocumentacion
                                                        .datos_json
                                                        .importeFacturacionTuqui
                                                    : importeFacturacionTuqui
                                            }
                                            readOnly={
                                                dataDetalleDocumentacion.datos_json &&
                                                    !editar
                                                    ? true
                                                    : false
                                            }
                                            onChange={(event) => {
                                                setImporteFacturacionTuqui(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Tipo Factura:{requiredSymbol}
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={
                                                dataDetalleDocumentacion.datos_json &&
                                                    !editar
                                                    ? dataDetalleDocumentacion
                                                        .datos_json
                                                        .tipoFactura
                                                    : tipoFactura
                                            }
                                            required={
                                                estado.descripcion ===
                                                    'Rechazado'
                                                    ? false
                                                    : true
                                            }
                                            readOnly={
                                                dataDetalleDocumentacion.datos_json &&
                                                    !editar
                                                    ? true
                                                    : false
                                            }
                                            onChange={(event) => {
                                                setTipoFactura(
                                                    event.target.value
                                                );
                                            }}
                                        >
                                            <option value="" hidden>
                                                Elegir Tipo de Factura
                                            </option>
                                            {tablaTipoFactura.length > 0
                                                ? tablaTipoFactura.map(
                                                    (tipo) => (
                                                        <option
                                                            key={
                                                                tipo.descripcion
                                                            }
                                                            value={
                                                                tipo.descripcion
                                                            }
                                                        >
                                                            {tipo.descripcion}
                                                        </option>
                                                    )
                                                )
                                                : null}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Condicion Fiscal:{requiredSymbol}
                                        </Form.Label>
                                        <Form.Control
                                            readOnly={true}
                                            value={condicionFiscal.condicion}
                                            onChange={(event) => {
                                                handleTablaCondicionChange(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="d-flex mb-3">
                                    {dataDetalleDocumentacion.datos_json &&
                                        !editar ? (
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>
                                                Punto de Venta:{requiredSymbol}
                                            </Form.Label>
                                            <Form.Control
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json.nroFactura
                                                }
                                                required
                                                readOnly={true}
                                            />
                                        </Form.Group>
                                    ) : (
                                        <>
                                            <Form.Group
                                                as={Col}
                                                md="6"
                                                style={{ width: '100%' }}
                                            >
                                                <Form.Label>
                                                    Numero de Factura:
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>
                                                    Punto de Venta:
                                                    {requiredSymbol}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    maxLength={5}
                                                    value={nroFacturaVenta}
                                                    required={
                                                        estado.descripcion ===
                                                            'Rechazado'
                                                            ? false
                                                            : true
                                                    }
                                                    onChange={(event) => {
                                                        setNroFacturaVenta(
                                                            event.target.value
                                                        );
                                                    }}
                                                    readOnly={false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>
                                                    Comp. N°:{requiredSymbol}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    maxLength={8}
                                                    value={nroFacturaNro}
                                                    required={
                                                        estado.descripcion ===
                                                            'Rechazado'
                                                            ? false
                                                            : true
                                                    }
                                                    onChange={(event) => {
                                                        setNroFacturaNro(
                                                            event.target.value
                                                        );
                                                    }}
                                                    readOnly={false}
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                </Row>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Fecha Factura:{requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json &&
                                            !editar ? (
                                            <Form.Control
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json.fechaFactura
                                                        ? dataDetalleDocumentacion
                                                            .datos_json
                                                            .fechaFactura
                                                        : '-'
                                                }
                                                required
                                                readOnly={true}
                                                onChange={(event) => {
                                                    setNroFactura(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Datetime
                                                ref={fechaFacturaRef}
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                value={
                                                    fechaFactura.fechaMuestra
                                                }
                                                onChange={(momentDate) => {
                                                    const fechaMuestra =
                                                        momentDate.format(
                                                            'DD/MM/YYYY'
                                                        );
                                                    const fechaComparar =
                                                        momentDate.format(
                                                            'YYYY-MM-DD'
                                                        );
                                                    setFechaFactura({
                                                        fechaMuestra:
                                                            fechaMuestra,
                                                        fechaComparar:
                                                            fechaComparar,
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row
                                    className="d-flex mb-3"
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        style={{
                                            display: 'flex',
                                            alignContent: 'flex-end',
                                            flexWrap: 'wrap',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <a
                                            href="https://serviciosweb.afip.gob.ar/genericos/comprobantes/cae.aspx"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'transparent' }}
                                        >
                                            <Button className="botonCPA">
                                                Control CAE
                                            </Button>
                                        </a>
                                        <a
                                            href="https://seti.afip.gob.ar/padron-puc-constancia-internet/ConsultaConstanciaAction.do"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                color: 'transparent',
                                                paddingLeft: '10px',
                                            }}
                                        >
                                            <Button className="botonCPA">
                                                Constancia de CUIT
                                            </Button>
                                        </a>
                                    </Form.Group>
                                    <Row />
                                </Row>
                            </>
                        ) : null}
                        {tipoDocumentacion.descripcion ===
                            'Certificado de Cumplimiento Fiscal Vigente' ? (
                            <>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Fecha Certificado:{requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json &&
                                            !editar ? (
                                            <Form.Control
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json
                                                        .fechaCertificado
                                                }
                                                required
                                                readOnly={true}
                                                onChange={(event) => {
                                                    setNroFactura(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Datetime
                                                ref={fechaCertificadoRef}
                                                timeFormat={false}
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                dateFormat="DD/MM/YYYY"
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                value={
                                                    fechaCertificado.fechaMuestra
                                                }
                                                onChange={(momentDate) => {
                                                    const fechaMuestra =
                                                        momentDate.format(
                                                            'DD/MM/YYYY'
                                                        );
                                                    const fechaComparar =
                                                        momentDate.format(
                                                            'YYYY-MM-DD'
                                                        );
                                                    setFechaCertificado({
                                                        fechaMuestra:
                                                            fechaMuestra,
                                                        fechaComparar:
                                                            fechaComparar,
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row
                                    className="d-flex mb-3"
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        style={{
                                            display: 'flex',
                                            alignContent: 'flex-end',
                                            flexWrap: 'wrap',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <a
                                            href="http://www.rentastucuman.gob.ar/nomina/rentastuc2/nwconstancias/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'transparent' }}
                                        >
                                            <Button className="botonCPA">
                                                Constancia de Inscripcion
                                            </Button>
                                        </a>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        style={{
                                            display: 'flex',
                                            alignContent: 'flex-end',
                                            flexWrap: 'wrap',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <a
                                            href="http://www.rentastucuman.gob.ar/nomina/rentastuc2/nwcertificados/validez.php?tipo=ccf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'transparent' }}
                                        >
                                            <Button className="botonCPA">
                                                Validez Certificado
                                            </Button>
                                        </a>
                                    </Form.Group>
                                    <Row />
                                </Row>
                            </>
                        ) : null}
                        {tipoDocumentacion.descripcion ===
                            'Constancia de Inscripción en AFIP' ? (
                            <>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Condicion Fiscal:{requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json ? (
                                            <Form.Control
                                                value={
                                                    condicionFiscal.condicion
                                                }
                                                readOnly={true}
                                                onChange={(event) => {
                                                    handleTablaCondicionChange(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                as="select"
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                value={condicionFiscal.id}
                                                onChange={(event) => {
                                                    handleTablaCondicionChange(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                {tablaCondiciones.length > 0
                                                    ? tablaCondiciones.map(
                                                        (condicion) => (
                                                            <option
                                                                key={
                                                                    condicion.id
                                                                }
                                                                value={
                                                                    condicion.id
                                                                }
                                                            >
                                                                {
                                                                    condicion.condicion
                                                                }
                                                            </option>
                                                        )
                                                    )
                                                    : null}
                                            </Form.Control>
                                        )}
                                    </Form.Group>
                                    {condicionFiscalTit.condicion_fiscal !==
                                        condicionFiscal.condicion ? (
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>
                                                Fecha Desde: {requiredSymbol}
                                            </Form.Label>
                                            <Datetime
                                                ref={fechaDesdeRef}
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                inputProps={{
                                                    placeholder:
                                                        fechaDesde.fechaMuestra,
                                                    readOnly: true,
                                                }}
                                                value={fechaDesde.fechaMuestra}
                                                onChange={(momentDate) => {
                                                    const fechaMuestra =
                                                        momentDate.format(
                                                            'DD/MM/YYYY'
                                                        );
                                                    const fechaComparar =
                                                        momentDate.format(
                                                            'YYYY-MM-DD'
                                                        );
                                                    setFechaDesde({
                                                        fechaMuestra:
                                                            fechaMuestra,
                                                        fechaComparar:
                                                            fechaComparar,
                                                    });
                                                }}
                                            />
                                        </Form.Group>
                                    ) : null}
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Actividad:{requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json &&
                                            !editar ? (
                                            <Form.Control
                                                type="text"
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json.actividad
                                                }
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                readOnly={true}
                                            />
                                        ) : (
                                            <Form.Control
                                                as="select"
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                value={actividad}
                                                onChange={(event) => {
                                                    setActividad(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                <option value="" hidden>
                                                    Elegir Actvidad
                                                </option>
                                                <option value="92001">
                                                    92001
                                                </option>
                                                <option value="92009">
                                                    92009
                                                </option>
                                                <option value="OTRO">
                                                    OTRO
                                                </option>
                                            </Form.Control>
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Fecha Constancia:{requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json &&
                                            !editar ? (
                                            <Form.Control
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json
                                                        .fechaConstancia
                                                }
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                readOnly={true}
                                                onChange={(event) => {
                                                    setNroFactura(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Datetime
                                                ref={fechaConstanciaRef}
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                value={
                                                    fechaConstancia.fechaMuestra
                                                }
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                onChange={(momentDate) => {
                                                    const fechaMuestra =
                                                        momentDate.format(
                                                            'DD/MM/YYYY'
                                                        );
                                                    const fechaComparar =
                                                        momentDate.format(
                                                            'YYYY-MM-DD'
                                                        );
                                                    setFechaConstancia({
                                                        fechaMuestra:
                                                            fechaMuestra,
                                                        fechaComparar:
                                                            fechaComparar,
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row
                                    className="d-flex mb-3"
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        style={{
                                            display: 'flex',
                                            alignContent: 'flex-end',
                                            flexWrap: 'wrap',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <a
                                            href="https://seti.afip.gob.ar/padron-puc-constancia-internet/ConsultaConstanciaAction.do"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'transparent' }}
                                        >
                                            <Button className="botonCPA">
                                                Constancia de CUIT
                                            </Button>
                                        </a>
                                    </Form.Group>
                                </Row>
                            </>
                        ) : null}
                        {tipoDocumentacion.descripcion ===
                            'Constancia de Inscripción en Rentas de la Provincia' ? (
                            <>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Actividad:{requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json &&
                                            !editar ? (
                                            <Form.Control
                                                placeholder="Ingrese Actividad"
                                                type="text"
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json.actividad
                                                }
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                readOnly={true}
                                            />
                                        ) : (
                                            <Form.Control
                                                as="select"
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                value={actividad}
                                                onChange={(event) => {
                                                    setActividad(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                <option value="" hidden>
                                                    Elegir Actvidad
                                                </option>
                                                <option value="924911">
                                                    924911
                                                </option>
                                                <option value="924912">
                                                    924912
                                                </option>
                                                <option value="924913">
                                                    924913
                                                </option>
                                                <option value="924914">
                                                    924914
                                                </option>
                                                <option value="OTRO">
                                                    OTRO
                                                </option>
                                            </Form.Control>
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row
                                    className="d-flex mb-3"
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        style={{
                                            display: 'flex',
                                            alignContent: 'flex-end',
                                            flexWrap: 'wrap',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <a
                                            href="http://www.rentastucuman.gob.ar/nomina/rentastuc2/nwconstancias/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'transparent' }}
                                        >
                                            <Button className="botonCPA">
                                                Constancia Inscripción
                                            </Button>
                                        </a>
                                    </Form.Group>
                                </Row>
                            </>
                        ) : null}
                        {tipoDocumentacion.descripcion ===
                            'Constancia Del Tributo Económico Municipal Actualizada' ? (
                            <>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Actividad:{requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json &&
                                            !editar ? (
                                            <Form.Control
                                                type="text"
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json.actividad
                                                }
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                readOnly={true}
                                            />
                                        ) : (
                                            <Form.Control
                                                as="select"
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                value={actividad}
                                                onChange={(event) => {
                                                    setActividad(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                <option value="" hidden>
                                                    Elegir Concepto
                                                </option>
                                                <option value="920001">
                                                    920001
                                                </option>
                                                <option value="920002">
                                                    920002
                                                </option>
                                                <option value="920009">
                                                    920009
                                                </option>
                                                <option value="No Inscripto">
                                                    No Inscripto
                                                </option>
                                                <option value="OTRO">
                                                    OTRO
                                                </option>
                                            </Form.Control>
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Fecha Constancia Tributo:
                                            {requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json &&
                                            !editar ? (
                                            <Form.Control
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json
                                                        .fechaConstancia
                                                }
                                                readOnly={true}
                                                onChange={(event) => {
                                                    setNroFactura(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Datetime
                                                ref={fechaConstanciaRef}
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                value={
                                                    fechaConstancia.fechaMuestra
                                                }
                                                onChange={(momentDate) => {
                                                    const fechaMuestra =
                                                        momentDate.format(
                                                            'DD/MM/YYYY'
                                                        );
                                                    const fechaComparar =
                                                        momentDate.format(
                                                            'YYYY-MM-DD'
                                                        );
                                                    setFechaConstancia({
                                                        fechaMuestra:
                                                            fechaMuestra,
                                                        fechaComparar:
                                                            fechaComparar,
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Group>
                                </Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        Zona de Explotacion:
                                    </Form.Label>
                                    <Form.Control
                                        value={zonaExplotacion}
                                        readOnly={true}
                                    />
                                </Form.Group>
                                <Row
                                    className="d-flex mb-3"
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        style={{
                                            display: 'flex',
                                            alignContent: 'flex-end',
                                            flexWrap: 'wrap',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <a
                                            href="https://dimsmt.gob.ar/online/constancia/index.jsp"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'transparent' }}
                                        >
                                            <Button className="botonCPA">
                                                Constancia Inscripción (Solo
                                                SMT)
                                            </Button>
                                        </a>
                                    </Form.Group>
                                </Row>
                            </>
                        ) : null}
                        {tipoDocumentacion.descripcion ===
                            'Certificación de no Estar Registrado Como Deudor Alimenticio' ? (
                            <>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Fecha Certificado:{requiredSymbol}
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json &&
                                            !editar ? (
                                            <Form.Control
                                                value={
                                                    dataDetalleDocumentacion
                                                        .datos_json
                                                        .fechaCertificado
                                                }
                                                readOnly={true}
                                                onChange={(event) => {
                                                    setNroFactura(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Datetime
                                                ref={fechaCertificadoRef}
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                required={
                                                    estado.descripcion ===
                                                        'Rechazado'
                                                        ? false
                                                        : true
                                                }
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                value={
                                                    fechaCertificado.fechaMuestra
                                                }
                                                onChange={(momentDate) => {
                                                    const fechaMuestra =
                                                        momentDate.format(
                                                            'DD/MM/YYYY'
                                                        );
                                                    const fechaComparar =
                                                        momentDate.format(
                                                            'YYYY-MM-DD'
                                                        );
                                                    setFechaCertificado({
                                                        fechaMuestra:
                                                            fechaMuestra,
                                                        fechaComparar:
                                                            fechaComparar,
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row
                                    className="d-flex mb-3"
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        style={{
                                            display: 'flex',
                                            alignContent: 'flex-end',
                                            flexWrap: 'wrap',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <a
                                            href="https://www3.justucuman.gov.ar/deudores-alimentarios/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'transparent' }}
                                        >
                                            <Button className="botonCPA">
                                                Control Certificado
                                            </Button>
                                        </a>
                                    </Form.Group>
                                </Row>
                            </>
                        ) : null}
                        {tipoDocumentacion.descripcion ===
                            'Declaración Jurada de Liquidación Mensual' ? (
                            <>
                                <Row className="d-flex mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Monto Concesión:{requiredSymbol}
                                        </Form.Label>
                                        {restaConcesion !== 0 ? (
                                            <Form.Label
                                                style={{
                                                    color: 'grey',
                                                    width: '100%',
                                                }}
                                            >
                                                Diferencia: $ {restaConcesion}{' '}
                                            </Form.Label>
                                        ) : null}
                                        <Form.Control
                                            placeholder="Ingrese Importe"
                                            type="number"
                                            value={
                                                dataDetalleDocumentacion.datos_json &&
                                                    !editar
                                                    ? dataDetalleDocumentacion
                                                        .datos_json
                                                        .montoConcesion
                                                    : montoConcesion
                                            }
                                            required={
                                                estado.descripcion ===
                                                    'Rechazado'
                                                    ? false
                                                    : true
                                            }
                                            readOnly={
                                                dataDetalleDocumentacion.datos_json &&
                                                    !editar
                                                    ? true
                                                    : false
                                            }
                                            onChange={(event) => {
                                                setMontoConcesion(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </>
                        ) : null}
                        <Row className="d-flex mb-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    Estado del Documento:{requiredSymbol}
                                </Form.Label>
                                {dataDetalleDocumentacion.datos_json &&
                                    !editar ? (
                                    <Form.Control
                                        value={
                                            dataDetalleDocumentacion.estado_documentacion
                                        }
                                        readOnly={true}
                                    />
                                ) : (
                                    <Form.Control
                                        as="select"
                                        required
                                        value={estado.id}
                                        onChange={(event) => {
                                            handleTablaEstadoChange(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <option value="" hidden>
                                            Elegir Estado
                                        </option>
                                        {tablaEstado.length > 0
                                            ? tablaEstado.map((estado) => (
                                                <option
                                                    key={estado.id}
                                                    value={estado.id}
                                                >
                                                    {estado.descripcion}
                                                </option>
                                            ))
                                            : null}
                                    </Form.Control>
                                )}
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                {tipoDocumentacion.descripcion ===
                                    'Factura Electronica' ||
                                    tipoDocumentacion.descripcion ===
                                    'Declaración Jurada de Liquidación Mensual' ? null : (
                                    <>
                                        <Form.Label>
                                            Fecha de Vencimiento
                                        </Form.Label>
                                        {dataDetalleDocumentacion.datos_json ? (
                                            <Form.Control
                                                value={
                                                    dataDetalleDocumentacion.fecha_vencimiento
                                                }
                                                readOnly={true}
                                                onChange={(event) => {
                                                    setNroFactura(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Datetime
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                value={
                                                    fechaVencimiento.fechaMuestra
                                                }
                                                ref={fechaVencimientoRef}
                                                onChange={(momentDate) => {
                                                    const fechaMuestra =
                                                        momentDate.format(
                                                            'DD/MM/YYYY'
                                                        );
                                                    const fechaComparar =
                                                        momentDate.format(
                                                            'YYYY-MM-DD'
                                                        );
                                                    setFechaVencimiento({
                                                        fechaMuestra:
                                                            fechaMuestra,
                                                        fechaComparar:
                                                            fechaComparar,
                                                    });
                                                }}
                                                isValidDate={valid}
                                            />
                                        )}
                                    </>
                                )}
                            </Form.Group>
                        </Row>
                        {estado.descripcion === 'Rechazado' ||
                            dataDetalleDocumentacion.estado_documentacion ===
                            'Rechazado' ? (
                            <Row className="d-flex mb-3">
                                <Form.Group as={Col} md="11">
                                    <Form.Label>
                                        Respuesta:{requiredSymbol}
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        placeholder="Seleccione Comentario"
                                        value={
                                            dataDetalleDocumentacion.datos_json &&
                                                !editar
                                                ? dataDetalleDocumentacion.comentario
                                                : comentario
                                        }
                                        onChange={(event) => {
                                            setComentario(event.target.value);
                                        }}
                                        required
                                    >
                                        <option value="" hidden>
                                            Selecciona una opción
                                        </option>
                                        {tablaMotivosRechazo.map((motivo) => (
                                            <option value={motivo.value}>
                                                {motivo.value}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="1"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'end',
                                    }}
                                >
                                    <AddCircleIcon
                                        onClick={() => {
                                            setShowModalAdd(true);
                                        }}
                                        style={{
                                            color: '#1b756b',
                                            cursor: 'pointer',
                                            marginBottom: '8px',
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                        ) : null}
                        <Row className="d-flex mb-3">
                            {dataDetalleDocumentacion.datos_json ? null : (
                                <Form.Group
                                    as={Col}
                                    md="6"
                                    style={{ marginTop: '2%' }}
                                >
                                    {tipoDocumentacion.descripcion ===
                                        'Constancia de Inscripción en AFIP' &&
                                        condicionFiscal.condicion ==
                                        'Sin Asignar' ? (
                                        <Button className="botonCPA-PDF">
                                            Verifique la Condicion Fiscal
                                        </Button>
                                    ) : tipoDocumentacion.descripcion ===
                                        'Certificado de Cumplimiento Fiscal Vigente' &&
                                        !fechaVencimiento.fechaMuestra ? (
                                        <Button className="botonCPA-PDF">
                                            Ingrese la Fecha de Vencimiento
                                        </Button>
                                    ) : (
                                        <Button
                                            className="botonCPA"
                                            type="submit"
                                        >
                                            Confirmar
                                        </Button>
                                    )}
                                </Form.Group>
                            )}
                            {editar ? (
                                <Form.Group
                                    as={Col}
                                    md="6"
                                    style={{ marginTop: '2%' }}
                                >
                                    <Button
                                        className="botonCPA"
                                        onClick={handleGuardarEdicion}
                                    >
                                        Guardar
                                    </Button>
                                </Form.Group>
                            ) : null}
                            <Form.Group
                                as={Col}
                                md="6"
                                style={{ marginTop: '2%' }}
                            >
                                <Button
                                    className="botonCPA"
                                    onClick={
                                        handleCloseModalDetalleDocumentacion
                                    }
                                >
                                    Cancelar
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal
                show={showModalAgregarDocumentacion}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton
                    onClick={handleCloseAgregarDocumentacion}
                >
                    <Modal.Title>Agregar Documentacion</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitSubirArchivo}>
                    <Modal.Body>
                        <Row className="d-flex mb-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>Periodo:</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat="MM/YYYY"
                                    inputProps={{
                                        required: true,
                                        placeholder: filtroPeriodo,
                                        readOnly: true,
                                    }}
                                    value={filtroPeriodo}
                                    closeOnSelect={true}
                                    onChange={(momentDate) => {
                                        const fechaMuestra =
                                            momentDate.format('MM/YYYY');
                                        setFiltroPeriodo(fechaMuestra);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Tipo Documentación:</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={tipoDocumentacion.descripcion}
                                    onChange={(event) => {
                                        handleTablaTipoDocumentacionChange(
                                            event.target.value
                                        );
                                    }}
                                >
                                    <option value="" hidden>
                                        Elegir Tipo de Documentacion
                                    </option>
                                    {tablaTipoDocumentacion.length > 0
                                        ? tablaTipoDocumentacion.map((tipo) => (
                                            <option
                                                key={tipo.descripcion}
                                                value={tipo.descripcion}
                                            >
                                                {tipo.descripcion}
                                            </option>
                                        ))
                                        : null}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className="d-flex mb-3">
                            <Form.Group
                                as={Col}
                                md="6"
                                controlId="formResolucionPDF"
                            >
                                <Form.Label>Subir archivo:</Form.Label>
                                <Form.Control
                                    required
                                    type="file"
                                    accept=".pdf"
                                    size="md"
                                    onChange={handleArchivoCSVChange}
                                />
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="botonCPA" type="submit">
                            Subir Archivo
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Procesando...</p>
                </Modal.Body>
            </Modal>
            <Modal centered show={showModalAdd}>
                <Form onSubmit={handleCrearMotivo}>
                    <Modal.Header
                        closeButton
                        onClick={handleCloseModalAdd}
                        className="text-center"
                        style={{ fontSize: '20px' }}
                    >
                        Agregar Motivo de Rechazo
                    </Modal.Header>
                    <Modal.Body
                        className="text-center"
                        style={{ fontSize: '20px' }}
                    >
                        <Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        fontSize: '17px',
                                    }}
                                >
                                    Ingresar Nuevo Motivo:
                                </Form.Label>
                                <Form.Control
                                    placeholder="Ingresar un motivo"
                                    type="text"
                                    value={nuevoMotivo}
                                    required
                                    readOnly={false}
                                    onChange={(event) => {
                                        setNuevoMotivo(event.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer
                        style={{ display: 'flex', justifyContent: 'end' }}
                    >
                        <button className="botonCPA me-3" type="submit">
                            Crear
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <ModalDarBajaDocumentacion
                onClose={handleCloseModalDarBajaDocumentacion}
                show={showModalDarBajaDocumentacion}
                titulo={tituloModal}
                documentacion={documentacionBajaModal}
            //close={handleCloseModal}
            //cargarTabla={props.tabal}
            />
        </>
    );
};

export default DetalleDeduccion;
