import React, { useState } from "react";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Modal,
    Spinner,
    Form
} from "react-bootstrap";
import Datetime from "react-datetime";
import { useNavigate, useLocation } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import BtnVolver from "../../../common/BtnVolver";
import MovimientosDeOperaciones from "./PDF/MovimientosDeOperaciones";
import { AiOutlineUpload, AiOutlineDownload, AiFillCopy, AiOutlineDelete, AiOutlineCluster, AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import Swal from "sweetalert2";
import ExcelReader from "../../exportaciones/ExcelReader";
import Parser from "../../../common/Parser";
import Notificaciones from "../../../../shared/helpers/notificacionesToast";
import CSVReader from "../../exportaciones/CSVReader";

const CargarVentasTuqui = () => {
    // const rolUser = props.rolUsuario;
    const location = useLocation();
    const navigate = useNavigate();
    // const [fecha] = useState(moment().format("DD/MM/YYYY"));
    const [rendicionesArchivo, setRendicionesArchivo] = useState([]);
    const [ventasTabla, setVentasTabla] = useState(
        location.state ? location.state.rendiciones : []
    );
    const [rendicionesExport] = useState(
        location.state ? location.state.rendicionesExport : []
    );
    const URL_VENTAS = window.API_ROUTES.VENTAS;

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const columns = [
        {
            header: "Agencia",
            accessorKey: "agencia",
            size: 30,
        },
        {
            header: "Subagencia",
            accessorKey: "subagencia",
            size: 30,
        },
        {
            header: "Cantidad Entregada",
            accessorKey: "cantidad_entregada",
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
        {
            header: "Cantidad Devuelta",
            accessorKey: "cantidad_devuelta",
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
        {
            header: "Cantidad Vendida",
            accessorKey: "cantidad_vendida",
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
        // {
        //     header: "Monto Total",
        //     accessorKey: "monto_vendida",
        //     size: 30,
        //     Cell: ({ renderedCellValue, row }) => (
        //       <span>{Parser.currency(renderedCellValue)}</span>
        //     ),
        // },
    ];

    const handleFileUploaded = (fileData) => {
        setRendicionesArchivo(fileData);
    };

    const cargarTabla = async () => {
        if (rendicionesArchivo) {
            setMessage('Subiendo ventas...');
            setIsLoading(true);
            if (rendicionesArchivo.length > 0) {
                const rendiciones = [];

                rendicionesArchivo.forEach((venta) => {
                    // const tiposDeVentasUnicos = [...new Set(rendicionesArchivo.map(venta => venta["Tipo de ventas"]))];
                    if (venta["Tipo de ventas"] === "Ventas por ag./sub.") {
                        if (venta['N� de Agencia'] !== '800') {
                            rendiciones.push({
                                numero_sorteo: venta['N� de sorteo'],
                                titular_agencia: venta['Cuit'],
                                agencia: venta['N� de Agencia'],
                                subagencia: venta['N� de Subagencia'],
                                cantidad_entregada: venta['Cantidad Entregada'],
                                cantidad_devuelta: venta['Cantidad Devuelta'],
                                cantidad_vendida: venta['Cantidad Vendida'],
                            });
                        };
                    };
                });
                try {
                    const creacion = await ConsultasAPI.CrearObjeto(URL_VENTAS, rendiciones);
                    if (creacion.status === 200) {
                        setIsLoading(false);
                        setMessage('Subiendo ventas...');
                    };
                } catch (error) {
                    console.error(error.response.data);
                    setIsLoading(false);
                    Swal.fire(
                        "Error en la carga de las ventas",
                        error.response.data.error,
                        "error"
                    );
                };
                setVentasTabla(rendiciones);
            };
        } else {
            Notificaciones.notificacion("Debe cargar datos de Rendiciones para poder procesarlas");
        };
    };

    const handleRemoveFile = () => {
        setRendicionesArchivo([]);
        setVentasTabla([]);
    };

    const generarRecibos = () => {
        if (ventasTabla.length > 0) {
            navigate("/liquidaciones/rendiciones/generar-recibos-rendiciones", {
                state: {
                    rendiciones: ventasTabla,
                    upload: true,
                    rendicionesExport: rendicionesExport,
                },
            });
        }
    };

    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Cargar Ventas</h1>
                </Col>
            </Row>
            <Row>
                <Card>
                    <>
                        <Row>
                            <div className="d-flex justify-content-center mt-3">
                                <h4 className="me-2">Subir Archivo:</h4>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-center mt-1">
                                <div className="mb-3" style={{ width: "63%", height: "80%" }}>
                                    <CSVReader
                                        onFileUploaded={handleFileUploaded}
                                        onRemoveFile={handleRemoveFile}
                                        showMessage={"Esperando el archivo 'Ventas-Subagencias' en formato (csv)"}
                                    />
                                </div>
                            </div>
                        </Row>
                    </>
                    <Row>
                        <div className="d-flex justify-content-center">
                            <Button className="btn botonCPA mb-3" onClick={cargarTabla}>
                                <AiOutlineUpload className="iconAntD" />
                                PROCESAR
                            </Button>
                        </div>
                    </Row>
                </Card>
            </Row>
            <Row className="mt-4">
                <Card>
                    <Row>
                        <div className="my-3">
                            <MaterialReactTable
                                columns={columns}
                                data={ventasTabla}
                                initialState={{
                                    columnVisibility: { id: false, acciones: false },
                                }}
                                enableRowSelection={false}
                                enableColumnOrdering={false}
                                enableGlobalFilter={true}
                                localization={MRT_Localization_ES}
                                enableColumnActions={false}
                            />
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-center">
                                <Button className="btn botonCPA my-4" onClick={generarRecibos}>
                                    <AiFillCopy className="iconAntD" />
                                    GENERAR RECIBOS
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-center">
                                {/* <Button
                className="btn botonCPA my-4"
                onClick={}
              > */}
                                <MovimientosDeOperaciones datos={rendicionesExport}>
                                    <AiOutlineDownload className="iconAntD" />
                                    EXPORTAR MOVIMIENTO
                                </MovimientosDeOperaciones>
                                {/* </Button> */}
                            </div>
                        </Col>
                    </Row>
                </Card>
                <section className="d-flex justify-content-end my-3">
                    <BtnVolver route="/liquidaciones/rendiciones/rendiciones-tuqui" />
                </section>
            </Row>
            <Modal centered show={isLoading} >
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>{message}</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default CargarVentasTuqui;
