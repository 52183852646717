import {
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link
} from "@react-pdf/renderer";
import logo from "../../../../../img/logo.png";
import AuthenticationHelper from "../../../../../shared/helpers/authenticationHelper.js";
const styles = StyleSheet.create({
  page: {
    padding: 49,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  logo: {
    width: 80,
    height: 47,
    marginBottom: 10,
  },
  paragraph: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
    padding: 4,
  },
  paragraphRed: {
    color: 'red',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
    padding: 4,
  },
});

const EmailComisiones = (props) => {
  function formatDate(date) {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "UTC", // Adjust this to the desired time zone
    };
    return date.toLocaleDateString("es-AR", options);
  }
  function formatMonth(date) {
    const options = { month: "long", timeZone: "UTC" };
    const newDate = new Date(date);
    return newDate.toLocaleDateString("es-AR", options).toUpperCase();
  }
  function formatYear(date) {
    const options = { year: "numeric", timeZone: "UTC" };
    const newDate = new Date(date);
    return newDate.toLocaleDateString("es-AR", options).toUpperCase();
  }
  const mes = formatMonth(props.datos);
  const ano = formatYear(props.datos);
  return (
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image src={logo} style={styles.logo} />
      </View>

      {/* Cuerpo */}
      <View style={styles.paragraph}>
        <Text style={styles.paragraph}>
          San Miguel de Tucumán, {formatDate(new Date())}
          {"\n"}
        </Text>
        <Text style={styles.paragraph}>Dpto. de Quinielas</Text>
        <Text style={styles.paragraph}>
          Sr.\a agente de quinielas, la presentación de documentación para el
          cobro de comisiones del mes de {mes} {ano} será
          a través de de la siguiente Enlace:
        </Text>
        <Text style={styles.paragraph}>
          <Link style={styles.link} src='https://agenciasdequiniela.cajapopular.gov.ar/login'>https://agenciasdequiniela.cajapopular.gov.ar/login</Link>
        </Text>
        <Text style={styles.paragraph}>
          La modalidad que se implementara será la siguiente:
        </Text>
        <Text style={styles.paragraph}>
          1) El Concesionario Oficial deberá remitir a cada sub agente esta
          dirección web, para que cada integrante de la red de Agentes
          y Sub agentes de Quiniela suba o cuelgue la documentación
          correspondiente para el cobro de su comisión en la página.
        </Text>
        <Text style={styles.paragraph}>
          La documentación que se requerirá para subir a la página,
          en formato PDF de manera excluyente, será:
        </Text>
        <Text style={styles.paragraph}>
          a) Factura electrónica correspondiente al mes por comisionar.
        </Text>
        <Text style={styles.paragraph}>
          b) Certificado de Cumplimiento Fiscal VIGENTE.
        </Text>
        <Text style={styles.paragraph}>
          c) Constancia de Inscripción en AFIP ACTUALIZADO.
        </Text>
        <Text style={styles.paragraph}>
          d) Constancia de Inscripción en Rentas de la Provincia ACTUALIZADO.
        </Text>
        <Text style={styles.paragraph}>
          e) Constancia del Tributo Económico Municipal ACTUALIZADA.
        </Text>
        <Text style={styles.paragraph}>
          f) Certificación de NO estar registrado como Deudor Alimenticio.
        </Text>
        <Text style={styles.paragraph}>
          g) Declaración Jurada de liquidación mensual (solo Agencieros)
        </Text>
        <Text style={styles.paragraphRed}>
          CABE ACLARAR QUE DE NO SUBIR ALGUNOS DE LOS REQUISITOS EXIGIDOS,
          DARÁ COMO CONSECUENCIA EL NO ENVIO DE LA DOCUMENTACION POR PARTE DEL SISTEMA.
        </Text>
        <Text style={styles.paragraph}>
          2) En el detalle de su facturacion deberan discriminar “COMISION 
          {mes} {ano} VENTA JUEGO QUINIELA” y
          “COMISION {mes} {ano} VENTA JUEGO EL TUQUI
          10”.-
        </Text>
        <Text style={styles.paragraph}>Atte.-</Text>
      </View>
    </Page>
  );
};
export default EmailComisiones;


