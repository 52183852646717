import React, { useRef, useEffect } from "react";

const VideoComponent = ({ videoPath,mostrar }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
    }
  }, [mostrar]);

  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
      {/* <Ratio aspectRatio="16x9"> */}
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        controls
        autoPlay
        muted
        playsInline
        loop
        // onEnded={handleVideoEnd}
        style={{ width: '100%', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
      // onCanPlay={() => requestFullscreen()}
      >
        <source src={videoPath} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* </Ratio> */}
    </div>
  );
};
export default VideoComponent;
