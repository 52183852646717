import {
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import Parser from "../../../../common/Parser";
import Column from "antd/es/table/Column";

const styles = StyleSheet.create({
  page: {
    padding: 49,
    size:'A3',
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    width: 80,
    height: 47,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: "auto",
  },
  subHeaderTitle: {
    fontSize: 7,
    marginLeft: "auto",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 8,
    width: "auto",
    justifyContent: 'right',
  },
  tableColHeader: {
    flex: 1,
    width: "auto",
    borderStyle: "solid",
    backgroundColor: "#bfbfbf",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    fontWeight: "bold",
    fontSize: 9,
    textAlign: "center",

  },
  tableCol: {
    flex: 1,
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    padding: 2,
    overflowWrap: "breakWord",
    borderRightWidth: 1, // borde derecho de las columnas
    textAlign: "right",

  },
  tableColTitular: {
    flex: 1,
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    padding: 2,
    overflowWrap: "breakWord",
    borderRightWidth: 1, // borde derecho de las columnas
    textAlign: "center",
  },
  sectionSeparator: {
    height: 20, // Altura de la separación entre secciones
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    marginTop: 5,
    marginBottom: 20,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  tableColHeaderAG: {
    flex: 1,
    width: "-10%",
    borderStyle: "solid",
    backgroundColor: "#bfbfbf",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    fontWeight: "bold",
    fontSize: 9,
  },

  tableColHeaderSUB: {
    flex: 1,
    width: "10%",
    borderStyle: "solid",
    backgroundColor: "#bfbfbf",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    fontWeight: "bold",
    fontSize: 9,
  },
});

const EmailComisiones = (props) => {
  const components = props.datos;
  // Function to split long text into multiple lines based on available width
  function splitText(text, maxWidth) {
    const lines = [];
    let currentLine = '';

    for (let i = 0; i < text.length; i++) {
      currentLine += text[i];

      // Check if the current line exceeds the maxWidth or if we have reached the end of the text
      if (currentLine.length >= maxWidth || i === text.length - 1) {
        lines.push(currentLine);
        currentLine = '';
      }
    }

    return lines;
  }

  // Generar una lista de todos los atributos de todos los componentes
  const attributesList = components
    ? components.map((component) => Object.keys(component))
    : [];
  //   const uniqueAttributesList = components ? Object.keys(components[0]) : [];
  // Generar una tabla por cada conjunto de atributos iguales
  const uniqueAttributesList = [
    ...new Set(attributesList.map((attributes) => attributes.join())),
  ];
  const tables = uniqueAttributesList.map((uniqueAttributes) => {
    const filteredComponents = components.filter((component) => {
      const componentAttributes = Object.keys(component);
      return componentAttributes.join() === uniqueAttributes;
    });

    if (filteredComponents.length === 0) {
      return null;
    }
    const tableHeaders = uniqueAttributes.split(",");

    return (
      <div key={"comisiones"}>
        <View style={styles.sectionSeparator} />
        {/* <Text style={styles.subtitle}>{tableName}</Text> */}

        <View style={styles.table}>
          {/* Encabezado de tabla */}
          <View style={styles.tableRow}>
            {tableHeaders.map((header, index) => (
              <View 
              style={styles.tableColHeader}
              key={index.toString()}>
                <Text>
                  {header.charAt(0).toUpperCase() +
                    header.slice(1).replace("_", " ")}
                </Text>
              </View>
            ))}
          </View>

          {/* Filas de tabla */}
          {filteredComponents.map((object, index) => (
            <View key={index.toString()} style={styles.tableRow}>
              {tableHeaders.map((column, index) => (
                <View style={column === "Titular" || column === "CUIT" || column === "AGENCIA" ? styles.tableColTitular : styles.tableCol}
                  key={index.toString()}>
                  <Text>{column === "CUIT" ? splitText(object[column], 7) : column !== "AGENCIA" && column !== "SUB" && column !== "Titular" ? splitText(Parser.currency(object[column]), 7) : object[column]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </div>
    );
  });

  return (
    // <PDFDownloadLink
    //   document={
    <Page style={styles.page} orientation="landscape" size='A3'>
      <View>{tables}</View>

      <View style={styles.sectionSeparator} />

      {/* Pie de página */}
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `${pageNumber} / ${totalPages}`
        }
      />
    </Page>
    //   }
    //   fileName={
    //     "Movimiento de Operaciones " + dayjs().format("DD/MM/YYYY") + ".pdf"
    //   }
    // >
    //   {({ blob, url, loading, error }) => (
    //     <Button className="btn botonCPA my-4">
    //       <BsFillFileEarmarkPdfFill className="mb-1" />
    //       <strong>{loading ? "Generando PDF..." : "Exportar a PDF"}</strong>
    //     </Button>
    //   )}
    // </PDFDownloadLink>
  );
};

export default EmailComisiones;
