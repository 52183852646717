/* eslint-disable */
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import BtnVolver from "../../common/BtnVolver";
import Datetime from "react-datetime";
import { Link } from "react-router-dom";
import {
  Modal,
  Form,
  Container,
  Col,
  Row,
  Card,
  Spinner,
  Button
} from "react-bootstrap";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { FaPlus, FaFileDownload, FaEraser } from "react-icons/fa";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { Box, MenuItem, TextField } from "@mui/material";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import getNombreEstado from "../../../shared/helpers/estados";
import MaterialReactTable from "material-react-table";
import Swal from "sweetalert2";
import { ImUserCheck, ImUserMinus } from "react-icons/im";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import Notificaciones from "../../../shared/helpers/notificacionesToast";
import { darken } from "@mui/material";
import logoUrl from "../../../img/logo.png";
import SubirPublicidad from "../pantallaResultados/SubirPublicidad";
var historial = [];

const Extractos = (props) => {
  const rolUser = props.rolUsuario;
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [Extracto, setExtracto] = useState([]);
  const [extractos, setExtractos] = useState([]);
  const [data, setData] = useState([]);
  const URL_EXTRACTO = window.API_ROUTES.EXTRACTO;
  const URL_EXTRACTOREGISTRO = window.API_ROUTES.EXTRACTOREGISTRO;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const datetimeRef = useRef(null);
  const [fecha, setFecha] = useState({
    fechaMuestra: null,
    fechaComparar: null,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [count, setCount] = useState();
  const [obj, setObj] = useState([]);
  const [n, setN] = useState();
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("");
  const [tipo, setTipo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subirPublicidad, setSubirPublicidad] = useState(false);
  // Definir el estado que indica si se debe mostrar o no el modal
  const columns = useMemo(() => [
    {
      header: "Usuario",
      accessorKey: "email",
      size: 140,
    },
    {
      header: "N° Sorteo",
      accessorKey: "numSorteo",
      size: 50,
    },
    {
      header: "Tipo Sorteo",
      accessorKey: "tipoSorteo",
      size: 140,
    },
    {
      header: "Fecha del Sorteo",
      accessorKey: "fechaSorteo",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Hora del Sorteo",
      accessorKey: "horaSorteo",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Fecha de Subida",
      accessorKey: "fecha",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Hora de Subida",
      accessorKey: "hora",
      size: 50,
      enableColumnFilter: false,
    },
    {
      header: "Observaciones",
      accessorKey: "observaciones",
      size: 200,
      enableColumnFilter: false,
    },
    {
      header: "Comentario",
      accessorKey: "comentario",
      size: 300,
      enableColumnFilter: false,
    },
  ]);

  useEffect(() => {
    cargarArchivo();
  }, [pagination, columnFilters, sorting, fecha, tipo]);

  //Trae los datos desde el back para mostrar en la tabla del front
  const cargarArchivo = () => {
    let Tipo = null;
    if (tipo !== "") {
      Tipo = tipo;
    }
    ConsultasAPI.ListarObjetos(
      URL_EXTRACTO,
      pagination.pageIndex,
      pagination.pageSize,
      columnFilters,
      fecha.fechaComparar,
      null,
      null,
      Tipo,
      null,
      null,
      sorting
    )
      .then((response) => {
        setCount(response.data.count);
        setN(response.data.count / 10);
        let datos = [];
        response.data.results.forEach((Extracto) => {
          datos.push({
            email: Extracto.usuario,
            fecha: Extracto.fecha_creacion
              ? Extracto.fecha_creacion.split(" ")[0]
              : "-", //fecha del sorteo y tiene que ser la de carga
            hora: Extracto.fecha_creacion
              ? Extracto.fecha_creacion.split(" ")[1].split(".")[0]
              : "-",
            numSorteo: Extracto.sorteo,
            tipoSorteo: Extracto.tipo_detalle.tipo,
            is_active: Extracto.estado ? "Activo" : "Dado de Baja",
            horaSorteo: Extracto.tipo_detalle.hora_sorteo,
            observaciones: Extracto.observaciones,
            fechaSorteo: Extracto.fecha_sorteo,
            comentario: Extracto.comentario
              ? Extracto.comentario
              : "Sin Comentario",
          });
        });
        setData(datos);
      })
      .catch((error) => {
        Notificaciones.notificacion("Falta definir rango horario");
      });
  };

  const handleClickExcel = () => {
    guardarHistorial(true);
    setIsLoading(true);
  };
  const handleClickPDF = () => {
    guardarHistorial(false);
    setIsLoading(true);
  };

  //Espera que historial tenga datos, para poder exportarlo a excel y/o pdf
  async function esperarArrayDatos(array, isExcel) {
    while (array.length === 0) {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Espera 1 segundo antes de volver a verificar
    }
    if (isExcel) {
      exportToExcel(array);
    } else {
      convertToPDF(array);
    }
  }

  //Trae todos los extractos del back y los guarda en historial
  const guardarHistorial = async (isExcel) => {
    let i = 0;
    historial = [];
    do {
      try {
        let Tipo = null;
        if (tipo !== "") {
          Tipo = tipo;
        }
        await ConsultasAPI.ListarObjetos(
          URL_EXTRACTO,
          i,
          10,
          columnFilters,
          fecha.fechaComparar,
          null,
          null,
          Tipo,
          null,
          null,
          sorting
          // eslint-disable-next-line no-loop-func
        ).then((response) => {
          let xtr = response.data.results;
          xtr.forEach((Extracto) => {
            let extractoArch = {
              Email_Usuario: Extracto.usuario ? Extracto.usuario : "-",
              Fecha_Subida: Extracto.fecha_creacion
                ? Extracto.fecha_creacion.split(" ")[0]
                : "-", //fecha del sorteo y tiene que ser la de carga
              Hora_Subida: Extracto.fecha_creacion
                ? Extracto.fecha_creacion.split(" ")[1].split(".")[0]
                : "-",
              Numero_Sorteo: Extracto.sorteo ? Extracto.sorteo : "-",
              Tipo_Sorteo: Extracto.tipo_detalle.tipo
                ? Extracto.tipo_detalle.tipo
                : "-",
              Fecha_Sorteo: Extracto.fecha_sorteo ? Extracto.fecha_sorteo : "-",
              Hora_Sorteo: Extracto.tipo_detalle.hora_sorteo
                ? Extracto.tipo_detalle.hora_sorteo
                : "-",
              Observaciones: Extracto.observaciones
                ? Extracto.observaciones
                : "-",
            };
            historial.push(extractoArch);
          });
          setN(response.data.count / 10);
        });
        i++;
      } catch (error) { }
    } while (i < n);
    i = 0;
    esperarArrayDatos(historial, isExcel);
  };

  //Exporta los datos del historia en formato pdf
  const exportToExcel = (historial) => {
    const historialOrdenado = historial.sort((a, b) => {
      // Asumiendo que el campo de fecha se llama "fecha"
      const fechaA = new Date(a.fecha_sorteo);
      const fechaB = new Date(b.fecha_sorteo);
      return fechaA - fechaB;
    });
    // Crea una nueva hoja de cálculo de Excel
    const workbook = XLSX.utils.book_new();

    // Convierte los datos JSON en una hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(historial);

    // Agrega la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(workbook, worksheet, "Historico");

    // Genera un archivo de Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Crea un blob a partir del buffer de Excel
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Guarda el archivo usando file-saver
    const excelFile = URL.createObjectURL(excelBlob);
    historial = [];
    saveAs(excelBlob, "historico_de_insercciones.xlsx");
    setIsLoading(false);
  };

  //Exporta los datos del historial para transformarlo en pdf
  function convertToPDF(historial) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const historialOrdenado = historial.sort((a, b) => {
      const fechaA = new Date(a.fecha_sorteo);
      const fechaB = new Date(b.fecha_sorteo);
      return fechaA - fechaB;
    });

    const headers = Object.keys(historialOrdenado[0]); // Obtener las propiedades del primer objeto del JSON como encabezados de la tabla

    const body = historial.map((data) => Object.values(data)); // Obtener los valores de cada objeto del JSON

    const table = {
      headerRows: 1,
      widths: headers.map(() => "auto"),
      body: [
        headers, // Encabezados de la tabla
        ...body, // Datos del JSON
      ],
    };
    const imageUrl = logoUrl; // Replace with the actual image URL or file path
    const imageSize = [200, 100];

    const documentDefinition = {
      pageOrientation: "landscape",
      content: [
        {
          alignment: "center", // Center-align the container
          margin: [0, 50], // Add top margin to push it down a bit
          image: imageUrl,
          width: imageSize[0],
          height: imageSize[1],
        },
        { text: "Historico de Insercciones", style: "header" },
        { text: "Sorteos:", style: "subheader" },
        {
          table: table,
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5],
        },
      },
    };
    historial = [];
    pdfMake
      .createPdf(documentDefinition)
      .download("historico_de_insercciones.pdf");
    setIsLoading(false);
  }

  const handleChange = (event) => {
    setOpcionSeleccionada(event.target.value);
  };

  const handleFechaChange = (momentDate) => {
    const fechaMuestra = momentDate.format("DD/MM/YYYY");
    const fechaComparar = momentDate.format("YYYY-MM-DD");
    setFecha({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
  };

  const handleClickClear = () => {
    setTipo("");
    setFecha({ fechaMuestra: null, fechaComparar: null });
    datetimeRef.current.setState({ inputValue: "" });
  };

  const handleTipoChange = (tipo) => {
    setTipo(tipo);
  };

  return (
    <Container fluid className="mainSection">
      <div style={{ margin: "40px" }}>
        <Row>
          <Col>
            <h1 className="py-4 fw-bold">Extractos</h1>
          </Col>
          <Col className="d-flex justify-content-end m-3 ">
            {AuthenticationHelper.getRol() &&
              (rolUser === "ADMINISTRADOR" || rolUser === "GERENCIA-QUINIELA") ? (
              <>
                <Link
                  className="btn botonCPA m-3"
                  to={window.location + "/sorteos-exceptuados"}
                  variant="contained"
                >
                  Sorteos Exceptuados
                </Link>
                <Link
                  className="btn botonCPA m-3"
                  to={window.location + "/subir-extracto"}
                  variant="contained"
                >
                  <FaPlus className="iconAntD" /> Nuevo
                </Link>
              </>
            ) : null}
            {rolUser === "ADMINISTRADOR" || rolUser === "GERENCIA-QUINIELA" || rolUser === "RELACIONES" ?
              <Link
                className="btn botonCPA m-3"
                to={window.location + "/publicidad"}
                variant="contained"
              >
                <FaPlus className="iconAntD" /> Subir Publicidad
              </Link>
              :
              null
            }
            {rolUser === "ADMINISTRADOR" || rolUser === "GERENCIA-QUINIELA" || rolUser === "OPERADOR-EXTRACTO" ?
              <Link
                className="btn botonCPA m-3"
                to={window.location + "/resultados"}
                variant="contained"
              >
                <FaPlus className="iconAntD" /> Cargar Resultado
              </Link>
              :
              null
            }

          </Col>
        </Row>
        <Card>
          <Card.Body>
            <Row
              className="mb-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Form.Group as={Col} md="2">
                <Form.Label>Tipo de Fecha</Form.Label>
                <Form.Select
                  value={tipo}
                  onChange={(event) => {
                    handleTipoChange(event.target.value);
                  }}
                >
                  <option hidden>Elegir </option>
                  <option> Fecha de Sorteo </option>
                  <option> Fecha de Subida </option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>Fecha</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  inputProps={{
                    readOnly: true,
                    placeholder: "Elegir fecha",
                  }}
                  ref={datetimeRef}
                  value={null}
                  onChange={handleFechaChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="3">
                <Link
                  className="btn botonCPA me-3"
                  onClick={handleClickClear}
                  style={{ alignContent: "center", margin: 15, float: "right" }}
                >
                  <FaEraser className="iconAntD" />
                  Limpiar Filtro
                </Link>
              </Form.Group>
            </Row>
          </Card.Body>
          <MaterialReactTable
            className="w-100"
            columns={columns}
            data={data}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: "0",
                border: "1px dashed #e0e0e0",
              },
            }}
            muiTableBodyProps={{
              sx: (theme) => ({
                "& tr:nth-of-type(odd)": {
                  backgroundColor: darken(
                    theme.palette.background.default,
                    0.1
                  ),
                },
                fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
              }),
            }}
            initialState={{
              columnVisibility: { id: false },
              showColumnFilters: true,
            }} //hide firstName column by default
            editingMode="modal" //default
            // enableEditing
            enableRowSelection={false} //enable some features
            enableColumnOrdering={false}
            enableHiding={false}
            enableSorting={true}
            manualPagination
            muiTablePaginationProps={{
              rowsPerPageOptions: [10],
            }}
            enablePagination={true}
            localization={MRT_Localization_ES}
            rowCount={count}
            onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
            // state={{ pagination }} //pass the pagination state to the table
            enableGlobalFilter={false} //turn off a feature
            //enableColumnFilters={columnFilters.email}
            manualFiltering //turn off client-side filtering
            onColumnFiltersChange={(value) => {
              setColumnFilters(value);
            }} //hoist internal columnFilters state to your state
            manualSorting
            onSortingChange={setSorting}
            state={{
              columnFilters,
              pagination,
              sorting,
            }}
          />
        </Card>
        <Row className="text-center">
          <section className="d-flex justify-content-end my-3">
            <Link className="btn botonCPA me-3" onClick={handleClickExcel}>
              <FaFileDownload className="iconAntD" /> Descargar Excel
            </Link>
            <Link className="btn botonCPA-PDF me-3" onClick={handleClickPDF}>
              <FaFileDownload className="iconAntD" />
              Descargar PDF
            </Link>
            <BtnVolver route="/" />
          </section>
        </Row>
      </div>
      <Modal centered show={isLoading} onHide={() => setIsLoading(false)}>
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Descargando Archivo...</p>
        </Modal.Body>
      </Modal>
      {/* <SubirPublicidad
        showModal={subirPublicidad}
        closeModal={setSubirPublicidad}
      /> */}
    </Container>
  );
};
export default Extractos;
