import React, { useState } from 'react';
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Modal,
    Spinner,
    Form,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-datetime/css/react-datetime.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import moment from 'moment';
import BtnVolver from '../../../common/BtnVolver';
import MovimientosDeOperaciones from './PDF/MovimientosDeOperaciones';
import {
    AiOutlineUpload,
    AiOutlineDownload,
    AiFillCopy,
    AiOutlineDelete,
    AiOutlineCluster,
    AiFillCloseCircle,
    AiFillCheckCircle,
} from 'react-icons/ai';
import ConsultasAPI from '../../../../shared/helpers/consultasAPI';
import Swal from 'sweetalert2';
import ExcelReader from '../../exportaciones/ExcelReader';
import Parser from '../../../common/Parser';
import Notificaciones from '../../../../shared/helpers/notificacionesToast';

const CargarRendiciones = (props) => {
    // const rolUser = props.rolUsuario;
    const location = useLocation();
    const navigate = useNavigate();
    // const [fecha] = useState(moment().format("DD/MM/YYYY"));
    const [rendicionesArchivo, setRendicionesArchivo] = useState([]);
    const [rendicionesTabla, setRendicionesTabla] = useState(
        location.state ? location.state.rendiciones : []
    );
    const [rendicionesExport, setRendicionesExport] = useState(
        location.state ? location.state.rendicionesExport : []
    );
    const URL_RENDICION_AGENCIA = window.API_ROUTES.RENDICIONAGENCIA;
    const URL_RENDICION = window.API_ROUTES.RENDICION;

    const [isLoading, setIsLoading] = useState(false);
    const [isCharged, setIsCharged] = useState(false);
    const [showXLSX, setShowXLSX] = useState(false);
    const [message, setMessage] = useState('');
    const [fecha, setFecha] = useState({
        fechaMuestra: moment().format('DD/MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const [api_smartoplay, SetApiSmartoplay] = useState(false);

    const columns = [
        {
            header: 'Agencia',
            accessorKey: 'nueva_agencia',
            size: 30,
        },
        {
            header: 'Total Ingreso',
            accessorKey: 'totalIngreso',
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
        {
            header: 'Total Egreso',
            accessorKey: 'totalEgreso',
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
        {
            header: 'Total a Rendir',
            accessorKey: 'totalARendir',
            size: 30,
            // Cell: ({ renderedCellValue, row }) => (
            //   <span>{Parser.currency(renderedCellValue)}</span>
            // ),
        },
    ];

    const handleFileUploaded = (fileData) => {
        SetApiSmartoplay(false);
        setRendicionesArchivo(fileData);
    };

    const obtenerAgencia = (cadena) => {
        const regex = /\d+/; // Expresión regular para encontrar uno o más dígitos
        const numerosEncontrados = cadena.match(regex);
        return numerosEncontrados ? parseFloat(numerosEncontrados[0]) : null;
    };

    const loadRendiciones = (fecha) => {
        // setFecha(value);
        const filters = { fecha: fecha, tipoSucursal: '' };
        ConsultasAPI.ListarObjetos(
            URL_RENDICION_AGENCIA + 'ListaPorFecha/',
            null,
            null,
            filters
        )
            .then((response) => {
                let rendicionesRec = [];
                let rendicionesExp = [];
                response.data.forEach((rendicion) => {
                    rendicionesRec.push({
                        id: rendicion.id,
                        titular:
                            rendicion.titular?.persona_detalle?.apellido +
                            ', ' +
                            rendicion.titular?.persona_detalle?.nombre,
                        telefono: rendicion.agencia.telefono,
                        domicilio: rendicion.domicilio,
                        nueva_agencia: rendicion.agencia.numero_agencia,
                        numeroRendicion: rendicion.numero,
                        fechaRendicion: rendicion.fecha_inicio,
                        ingresoMatutino: Parser.currency(
                            rendicion.ingresoMatutino ?? 0
                        ),
                        egresoMatutino: Parser.currency(
                            rendicion.egresoMatutino ?? 0
                        ),
                        ingresoVespertino: Parser.currency(
                            rendicion.ingresoVespertino ?? 0
                        ),
                        egresoVespertino: Parser.currency(
                            rendicion.egresoVespertino ?? 0
                        ),
                        ingresoSiesta: Parser.currency(
                            rendicion.ingresoSiesta ?? 0
                        ),
                        egresoSiesta: Parser.currency(
                            rendicion.egresoSiesta ?? 0
                        ),
                        ingresoTarde: Parser.currency(
                            rendicion.ingresoTarde ?? 0
                        ),
                        egresoTarde: Parser.currency(
                            rendicion.egresoTarde ?? 0
                        ),
                        ingresoNocturno: Parser.currency(
                            rendicion.ingresoNocturno ?? 0
                        ),
                        egresoNocturno: Parser.currency(
                            rendicion.egresoNocturno ?? 0
                        ),
                        totalIngreso: Parser.currency(
                            rendicion.ingreso_total ?? 0
                        ),
                        totalEgreso: Parser.currency(
                            rendicion.egreso_total ?? 0
                        ),
                        totalARendir: Parser.currency(
                            rendicion.total_a_rendir ?? 0
                        ),
                    });

                    rendicionesExp.push({
                        Agencia: rendicion.agencia.numero_agencia,
                        'Ingreso Matutino': parseInt(
                            rendicion.ingresoMatutino ?? 0
                        ),
                        'Ingreso Vespertino': parseInt(
                            rendicion.ingresoVespertino ?? 0
                        ),
                        'Ingreso Siesta': parseInt(
                            rendicion.ingresoSiesta ?? 0
                        ),
                        'Ingreso Tarde': parseInt(rendicion.ingresoTarde ?? 0),
                        'Ingreso Nocturno': parseInt(
                            rendicion.ingresoNocturno ?? 0
                        ),
                        'Ingreso Total': parseInt(rendicion.ingreso_total ?? 0),
                        'Egreso Matutino': parseInt(
                            rendicion.egresoMatutino ?? 0
                        ),
                        'Egreso Vespertino': parseInt(
                            rendicion.egresoVespertino ?? 0
                        ),
                        'Egreso Siesta': parseInt(rendicion.egresoSiesta ?? 0),
                        'Egreso Tarde': parseInt(rendicion.egresoTarde ?? 0),
                        'Egreso Nocturno': parseInt(
                            rendicion.egresoNocturno ?? 0
                        ),
                        'Egreso Total': parseInt(rendicion.egreso_total ?? 0),
                        'Total a Rendir': parseInt(
                            rendicion.total_a_rendir ?? 0
                        ),
                    });
                });
                setRendicionesExport(rendicionesExp);
                setRendicionesTabla(rendicionesRec);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    const cargarTabla = () => {
        if (rendicionesArchivo) {
            setMessage('Subiendo rendiciones...');
            setIsLoading(true);
            if (rendicionesArchivo.length > 0) {
                let rendiciones = [];
                let nuevasRendiciones = [];
                let rendicionesToExport = [];

                let fechaRendicion = moment(
                    rendicionesArchivo[0]['Fecha Rendición'],
                    'YYYY-MM-DD'
                ).format('YYYY-MM-DD');
                let nueva_agencia = obtenerAgencia(
                    rendicionesArchivo[0]['Punto de Venta']
                );
                let numeroRendicion = rendicionesArchivo[0]['Nº Rendición'];
                let totalIngreso = 0;
                let totalEgreso = 0;
                let totalARendir = 0;

                let ingresoMatutino = 0;
                let ingresoVespertino = 0;
                let ingresoSieta = 0;
                let ingresoTarde = 0;
                let ingresoNocturno = 0;

                let egresoMatutino = 0;
                let egresoVespertino = 0;
                let egresoSiesta = 0;
                let egresoTarde = 0;
                let egresoNocturno = 0;
                let fecha;
                rendicionesArchivo.forEach((rendicion) => {
                    if (
                        rendicion['Tipo Sorteo'] !== undefined &&
                        !rendicion['Punto de Venta']
                            .toLowerCase()
                            .includes('total') &&
                        !rendicion['Tipo Sorteo']
                            .toLowerCase()
                            .includes('total')
                    ) {
                        if (
                            rendicion['$ Egresos'] !== '' ||
                            rendicion['$ Ingresos'] !== ''
                        ) {
                            let numero_rendicion = parseInt(
                                rendicion['Nº Rendición']
                            );
                            let agencia = obtenerAgencia(
                                rendicion['Punto de Venta']
                            );
                            let extracto = parseInt(rendicion['Nº Sorteo']);
                            let ingreso = rendicion['$ Ingresos'];
                            let egreso = rendicion['$ Egresos'];
                            fecha = moment(
                                rendicion['Fecha Rendición'],
                                'YYYY-MM-DD'
                            ).format('YYYY-MM-DD');

                            let fechaSorteo = moment(
                                rendicion['Fecha Sorteo'],
                                'YYYY-MM-DD'
                            ).format('YYYY-MM-DD');
                            nuevasRendiciones.push({
                                agencia,
                                numero_rendicion,
                                extracto,
                                ingreso,
                                egreso,
                                fecha,
                                fechaSorteo,
                                api_smartoplay,
                            });

                            if (
                                obtenerAgencia(rendicion['Punto de Venta']) ===
                                nueva_agencia
                            ) {
                                switch (rendicion['Tipo Sorteo']) {
                                    case 'Matutino':
                                        ingresoMatutino += parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoMatutino += parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso += ingresoMatutino;
                                        totalEgreso += egresoMatutino;
                                        break;
                                    case 'Vespertino':
                                        ingresoVespertino += parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoVespertino += parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso += ingresoVespertino;
                                        totalEgreso += egresoVespertino;
                                        break;
                                    case 'De la Siesta':
                                        ingresoSieta += parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoSiesta += parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso += ingresoSieta;
                                        totalEgreso += egresoSiesta;
                                        break;
                                    case 'De la Tarde':
                                        ingresoTarde += parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoTarde += parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso += ingresoTarde;
                                        totalEgreso += egresoTarde;
                                        break;
                                    case 'Nocturno':
                                        ingresoNocturno += parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoNocturno += parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso += ingresoNocturno;
                                        totalEgreso += egresoNocturno;
                                        break;
                                    default:
                                        break;
                                }
                                totalARendir = totalIngreso - totalEgreso;
                            } else {
                                nueva_agencia = obtenerAgencia(
                                    rendicion['Punto de Venta']
                                );
                                numeroRendicion = parseInt(
                                    rendicion['Nº Rendición']
                                );

                                totalIngreso = 0;
                                totalEgreso = 0;
                                totalARendir = 0;

                                ingresoMatutino = 0;
                                ingresoVespertino = 0;
                                ingresoSieta = 0;
                                ingresoTarde = 0;
                                ingresoNocturno = 0;

                                egresoMatutino = 0;
                                egresoVespertino = 0;
                                egresoSiesta = 0;
                                egresoTarde = 0;
                                egresoNocturno = 0;

                                switch (rendicion['Tipo Sorteo']) {
                                    case 'Matutino':
                                        ingresoMatutino = parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoMatutino = parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso = ingresoMatutino;
                                        totalEgreso = egresoMatutino;
                                        break;
                                    case 'Vespertino':
                                        ingresoVespertino = parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoVespertino = parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso = ingresoVespertino;
                                        totalEgreso = egresoVespertino;
                                        break;
                                    case 'De la Siesta':
                                        ingresoSieta = parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoSiesta = parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso = ingresoSieta;
                                        totalEgreso = egresoSiesta;
                                        break;
                                    case 'De la Tarde':
                                        ingresoTarde = parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoTarde = parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso = ingresoTarde;
                                        totalEgreso = egresoTarde;
                                        break;
                                    case 'Nocturno':
                                        ingresoNocturno = parseFloat(
                                            rendicion['$ Ingresos']
                                        );
                                        egresoNocturno = parseFloat(
                                            rendicion['$ Egresos']
                                        );

                                        totalIngreso = ingresoNocturno;
                                        totalEgreso = egresoNocturno;
                                        break;
                                    default:
                                        break;
                                }
                                totalARendir = totalIngreso - totalEgreso;
                            }
                        }
                    }
                });
                ConsultasAPI.CrearObjeto(
                    URL_RENDICION_AGENCIA,
                    nuevasRendiciones
                )
                    .then((response) => {
                        loadRendiciones(fecha);
                    })
                    .catch((error) => {
                        loadRendiciones(fecha);
                        setIsLoading(false);
                        Swal.fire(
                            'Error',
                            error.response.data.error ??
                                'No se pudieron guardar las rendiciones',
                            'error'
                        );
                    });
            }
        } else {
            Notificaciones.notificacion(
                'Debe cargar datos de Rendiciones para poder procesarlas'
            );
        }
    };

    const handleRemoveFile = () => {
        setIsCharged(false);
        setRendicionesArchivo([]);
        setRendicionesTabla([]);
    };

    const generarRecibos = () => {
        if (rendicionesTabla.length > 0) {
            navigate('/liquidaciones/rendiciones/generar-recibos-rendiciones', {
                state: {
                    rendiciones: rendicionesTabla,
                    upload: true,
                    rendicionesExport: rendicionesExport,
                },
            });
        }
    };

    const connectSmartoPlay = async (event) => {
        event.preventDefault();
        let date = fecha.fechaComparar;
        setMessage('Obteniendo Datos del Servidor');
        setIsLoading(true);
        try {
            const response = await ConsultasAPI.ObtenerObjeto(
                URL_RENDICION + 'rendicionesSmartoplay/',
                date
            );
            setIsLoading(false);
            setShowXLSX(false);
            if (response.data !== 0) {
                SetApiSmartoplay(true);
                const data = response.data;
                data.forEach((json) => {
                    for (let key in json) {
                        if (json[key] === '' || json[key] === null) {
                            json[key] = undefined;
                        }
                    }
                });
                setRendicionesArchivo(data);
                setIsCharged(true);
            } else {
                setIsLoading(false);
                Notificaciones.notificacion(
                    'No existen registros de rendiciones para la fecha seleccionada'
                );
            }
        } catch (error) {
            SetApiSmartoplay(false);
            setIsLoading(false);
            setShowXLSX(true);
            Notificaciones.notificacion(
                'Hubo un error al intentar conectarse al servidor'
            );
        }
    };

    const handleFechaChange = (momentDate) => {
        const fechaMuestra = momentDate.format('DD/MM/YYYY');
        const fechaComparar = momentDate.format('YYYY-MM-DD');
        setFecha({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
    };

    var valid = function (current) {
        const today = moment();
        const isSunday = current.day() === 0;
        return current.isBefore(today) && !isSunday;
    };

    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Cargar Rendiciones</h1>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Form onSubmit={connectSmartoPlay}>
                        <Row>
                            <div className="d-flex justify-content-center mt-3">
                                <h4 className="me-2">
                                    Conectarse al Servidor:
                                </h4>
                            </div>
                        </Row>
                        <Row className="m-2">
                            <Form.Group as={Col} md="3">
                                <Form.Label>Seleccionar Fecha:</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    style={{ width: '100%', height: '32px' }}
                                    dateFormat="DD/MM/YYYY"
                                    inputProps={{
                                        readOnly: true,
                                        placeholder: fecha.fechaMuestra,
                                    }}
                                    value={fecha.fechaMuestra}
                                    closeOnSelect={true}
                                    onChange={handleFechaChange}
                                    isValidDate={valid}
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="8"
                                className="m-3"
                                style={{ with: '100%' }}
                            >
                                {showXLSX ? (
                                    <Button
                                        className="btn botonCPA mb-3"
                                        type="submit"
                                        style={{
                                            backgroundColor: '#EC1B23',
                                            borderColor: '#EC1B23',
                                            width: 'max-content',
                                            float: 'inline-end',
                                            marginTop: '2%',
                                        }}
                                    >
                                        <AiOutlineCluster className="iconAntD" />{' '}
                                        Reintentar conectar al Servidor
                                    </Button>
                                ) : (
                                    <Button
                                        className="btn botonCPA mb-3"
                                        type="submit"
                                        style={{
                                            float: 'inline-end',
                                            marginTop: '2%',
                                        }}
                                    >
                                        <AiOutlineCluster className="iconAntD" />{' '}
                                        Obtener datos del Servidor
                                    </Button>
                                )}
                            </Form.Group>
                        </Row>
                        <Row className="d-flex justify-content-end"></Row>
                    </Form>
                    {showXLSX ? (
                        <>
                            <Row>
                                <div className="d-flex justify-content-center mt-3">
                                    <h4 className="me-2">Subir Archivo:</h4>
                                </div>
                            </Row>
                            <Row>
                                <div className="d-flex justify-content-center mt-1">
                                    <div
                                        className="mb-3"
                                        style={{ width: '63%', height: '80%' }}
                                    >
                                        <ExcelReader
                                            onFileUploaded={handleFileUploaded}
                                            onRemoveFile={handleRemoveFile}
                                            showMessage={
                                                "Esperando el archivo 'Ingresos y Egresos de Rendiciones por Agencia y Sorteo' en formato (XLSX)"
                                            }
                                        />
                                    </div>
                                </div>
                            </Row>
                        </>
                    ) : null}
                    {isCharged ? (
                        <>
                            <Row className="d-flex justify-content-center">
                                Datos de la Rendicion cargados
                                <Button
                                    as={Col}
                                    md="1"
                                    className="btn botonCPA mb-3 justify-content-center"
                                    style={{
                                        background: '#b5b5b5',
                                        color: 'rgb(255, 255, 255)',
                                        border: '2px solid #ffffff',
                                        borderRadius: '5px',
                                        padding: '5px 10px',
                                        textTransform: 'uppercase',
                                        width: 'fit-content',
                                    }}
                                    onClick={() => {
                                        handleRemoveFile();
                                    }}
                                >
                                    <AiOutlineDelete className="iconAntD" />
                                </Button>
                            </Row>
                        </>
                    ) : null}
                    <Row>
                        <div className="d-flex justify-content-center">
                            <Button
                                className="btn botonCPA mb-3"
                                onClick={cargarTabla}
                            >
                                <AiOutlineUpload className="iconAntD" />
                                PROCESAR
                            </Button>
                        </div>
                    </Row>
                </Card>
            </Row>
            <Row className="mt-4">
                <Card>
                    <Row>
                        <div className="my-3">
                            <MaterialReactTable
                                columns={columns}
                                data={rendicionesTabla}
                                initialState={{
                                    columnVisibility: {
                                        id: false,
                                        acciones: false,
                                    },
                                }}
                                enableRowSelection={false}
                                enableColumnOrdering={false}
                                enableGlobalFilter={true}
                                localization={MRT_Localization_ES}
                                enableColumnActions={false}
                            />
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-center">
                                <Button
                                    className="btn botonCPA my-4"
                                    onClick={generarRecibos}
                                >
                                    <AiFillCopy className="iconAntD" />
                                    GENERAR RECIBOS
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-center">
                                {/* <Button
                className="btn botonCPA my-4"
                onClick={}
              > */}
                                <MovimientosDeOperaciones
                                    datos={rendicionesExport}
                                >
                                    <AiOutlineDownload className="iconAntD" />
                                    EXPORTAR MOVIMIENTO
                                </MovimientosDeOperaciones>
                                {/* </Button> */}
                            </div>
                        </Col>
                    </Row>
                </Card>
                <section className="d-flex justify-content-end my-3">
                    <BtnVolver route="/liquidaciones/rendiciones/rendiciones-quiniela" />
                </section>
            </Row>
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>{message}</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default CargarRendiciones;
