import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Card, Form, Container, Button } from "react-bootstrap";
import fondoQuiniela from '../../../img/logo-quiniela-prueba.png';
import fondoCPA from '../../../img/cpalogo.jpeg';
import ConsultasApi from '../../../shared/helpers/consultasAPI'
import moment from 'moment'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BackspaceIcon from '@mui/icons-material/Backspace';
import BtnVolver from "../../common/BtnVolver";

const openNewWindow = () => {
    const newWindow = window.open('', '', 'width=1024,height=768');
    newWindow.document.body.innerHTML = '<div id="modal-content"></div>';
    // newWindow.document.body.style.backgroundImage = `url(${backgroundImage})`;
    newWindow.document.body.style.backgroundRepeat = `no-repeat`;
    newWindow.document.body.style.backgroundSize = `100%`;
    newWindow.document.body.style.backgroundColor = `#f7f7f7`;
    newWindow.document.body.style.display = 'flex';
    newWindow.document.body.style.justifyContent = 'center';
    const styleSheets = Array.from(document.styleSheets);
    styleSheets.forEach((styleSheet) => {
        const cssLink = document.createElement('link');
        cssLink.rel = 'stylesheet';
        cssLink.href = styleSheet.href;
        newWindow.document.head.appendChild(cssLink);
    });
    return newWindow;
};

const CargarResultados = () => {
    const [premios, setPremios] = useState(Array(20).fill(''));
    const modalWindowRef = useRef(null);
    const URL_EXTRACTO = window.API_ROUTES.EXTRACTO;
    const [tipo_sorteo, setTipoSorteo] = useState('')
    const [numero, setNumero] = useState('');

    useEffect(() => {
        obtenerSorteo();
    }, []);

    const obtenerSorteo = async () => {
        const sorteo = await ConsultasApi.ObtenerObjeto(URL_EXTRACTO + 'obtenerSorteo/', 1)
        setNumero(sorteo.data.sorteo + 1)
        setTipoSorteo(sorteo.data.tipo_detalle.tipo)
    }

    const openModal = () => {
        const newWindow = openNewWindow();
        modalWindowRef.current = newWindow;
        renderModalContent(newWindow);
    };

    const limpiarVentana = () => {
        setPremios(Array(20).fill(''));
        obtenerSorteo();
        renderModalContent(modalWindowRef.current);
    };

    const renderModalContent = (newWindow) => {
        const middleIndex = Math.ceil(premios.length / 2); // Encuentra el índice medio

        const firstHalf = premios.slice(0, middleIndex);
        const secondHalf = premios.slice(middleIndex);

        if (newWindow) {
            newWindow.document.getElementById('modal-content').innerHTML = `
                <style>
                .container-todo {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .container-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin: 0px;
                    column-gap: 10%;
                    width: 100%;
                }
                .container-title {
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 15px;
                }
                .container-title h{
                    font-size: 50px;
                    color: black;
                    font-family: Arial, sans-serif;
                }
                .container-prizes {
                    height: 81vh;
                    width: 90%;
                    background-color:#007c84;
                    display:flex;
                    flex-direction: row;
                    border-radius: 8px;
                }

                .col-prize {
                    display:flex;
                    flex-direction: column;
                    flex-grow: 1;
                    justify-content: center;
                    align-items: center;
                    margin: 3%;
                    row-gap: 2%;
                }

                .row-prize {
                    display:flex;
                    width:100%;
                    justify-content: center;
                    align-items: center;
                    gap: 2%;
                    font-size: 5.25vh;
                    margin-bottom: -13px;
                }

                .div-prize {
                    display:flex;
                    width:35%;
                    border-radius: 8px;
                    height: 7.9vh;
                }

                .div-prize h{
                    width:100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: black;
                    font-family: Arial, sans-serif;
                    font-size: 3vh;
                }

                .square {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-grow:1;
                    border-radius: 8px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                }
                .square h{
                    color: black;
                    font-family: Arial, sans-serif;
                    font-weight: bold;
                }
                .imagen {
                    width:15%;
                }
                .imagen-quiniela {
                    width:20%;
                }
                </style>
                <div class="container-todo">
                    <div class="container-header">
                        <img class="imagen-quiniela" src=${fondoQuiniela}></img>
                        <div class="container-title">
                            <h><b>Sorteo ${tipo_sorteo} N° ${numero}</b></h>
                            <h>${moment().format('dddd DD [de] MMMM YYYY, h:mm a').charAt(0).toUpperCase() + moment().format('dddd DD [de] MMMM YYYY').slice(1)}</h>
                        </div>
                        <img class="imagen" src=${fondoCPA}></img>
                    </div>
                    <div class="container-prizes">
                        <div class="col-prize">
                            ${firstHalf.map((valor, index) => `
                                    <div class="row-prize">
                                        <div class="div-prize" style="background-color:${(index%2===0)?'#e6e6e6':'#cccccc'}">
                                            <h class="h-prize">${index + 1}° Premio</h>
                                        </div>
                                        <div class="square" style="background-color:${(index%2===0)?'#e6e6e6':'#cccccc'}">
                                            <h>
                                                ${valor!==''?valor:"&nbsp"}
                                            </h>
                                        </div>
                                    </div>
                                `).join('')}
                        </div>
                        <div class="col-prize">
                            ${secondHalf.map((valor, index) => `
                                    <div class="row-prize">
                                        <div class="div-prize" style="background-color:${(index%2===0)?'#e6e6e6':'#cccccc'}">
                                            <h class="h-prize">${index + 11}° Premio</h>
                                        </div>
                                        <div class="square" style="background-color:${(index%2===0)?'#e6e6e6':'#cccccc'}">
                                            <h>
                                                ${valor!==''?valor:"&nbsp"}
                                            </h>
                                        </div>
                                    </div>
                                `).join('')}
                        </div>
                    </div>
                </div>
            `;
            newWindow.document.getElementById('modal-content').style.width = '100%'
        }
    };

    const handleInputChange = (index, value) => {
        const updatedPremios = [...premios];
        const sanitizedValue = value.slice(0, 4);
        if (/^\d*$/.test(sanitizedValue)) {
            updatedPremios[index] = sanitizedValue;
            setPremios(updatedPremios);
          }
    };

    useEffect(() => {
        if (modalWindowRef.current) {
            // renderModalContent(modalWindowRef.current);
        }
    }, [premios]);

    const handleFormKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex + 1];
            if (nextControl) {
                nextControl.focus();
            }
        }
        if (event.key === 'ArrowLeft') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex - 10];
            if (nextControl) {
                nextControl.focus();
            }
        }
        if (event.key === 'ArrowRight') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex + 10];
            if (nextControl) {
                nextControl.focus();
            }
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex + 1];
            if (nextControl) {
                nextControl.focus();
            }
        }
        if (event.key === 'ArrowUp') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex - 1];
            if (nextControl) {
                nextControl.focus();
            }
        }
    };

    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Resultados</h1>
                </Col>
            </Row>
            <Card>
                <Card.Header>
                    <Card.Title style={{fontSize:'30px'}}>Sorteo {tipo_sorteo.charAt(0) + tipo_sorteo.slice(1).toLowerCase()} - N° {numero}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onKeyDown={handleFormKeyDown} style={{ justifyContent: 'center', display: 'flex' }}>
                        <Row className="m-3" >
                            <Col md="6">
                                {[...Array(10).keys()].map((i) => (
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row',marginBottom:'5%' }} key={i}>
                                        <Form.Label style={{fontSize:'20px',marginRight: i===9 ? '1%':'5%'}}>{i + 1}°</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={premios[i]}
                                            onChange={(event) => handleInputChange(i, event.target.value)}
                                            style={{fontWeight:'bold',fontSize:'20px'}}
                                        />
                                    </Form.Group>
                                ))}
                            </Col>
                            <Col md="6">
                                {[...Array(10).keys()].map((i) => (
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row',marginBottom:'5%' }} key={i + 10}>
                                        <Form.Label style={{fontSize:'20px',marginRight:'5%'}}>{i + 11}°</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={premios[i + 10]}
                                            onChange={(event) => handleInputChange(i + 10, event.target.value)}
                                            style={{fontWeight:'bold',fontSize:'20px'}}
                                        />
                                    </Form.Group>
                                ))}
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <section className="d-flex justify-content-end my-3">
                <Button className="botonCPA m-3" onClick={limpiarVentana}>
                    <BackspaceIcon style={{marginRight:'5px'}}/>
                    Limpiar
                </Button>
                <Button className="botonCPA m-3" onClick={openModal}>
                    <OpenInNewIcon style={{marginRight:'5px'}}/>
                    Abrir Ventana
                </Button>
                <BtnVolver className="botonCPA m-3" style={{margin:'1%',marginTop:'16px',height:'fit-content'}}/>
            </section>
        </Container>
    );
}

export default CargarResultados;
