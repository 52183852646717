import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row, Card, Form, Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
//cambiar from si no funciona sin cambiarlo
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import { useNavigate, Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { IconButton } from '@mui/material';
import { Visibility, AddBox, PictureAsPdf } from '@mui/icons-material';
import { ModalTitular } from '../titulares/ModalTitular';
import Notificaciones from '../../../shared/helpers/notificacionesToast';
import Select from 'react-select';

import { FaEraser } from 'react-icons/fa';
import { ModalEditar } from './ModalEditar';
const FormItem = Form.Item;
// const { Option } = Select;

export function NuevaAgencia(props) {
    const [isLoading, setIsLoading] = useState(props.loading ?? false);
    const [archivoPDF, setArchivoPDF] = useState(null);
    const [archivoColaboradorPDF, setArchivoColaboradorPDF] = useState(null);
    const [titularAgencia, setTitularAgencia] = useState(null);
    const [nombreZona, setNombreZona] = useState('');

    const handleArchivoPDFChange = (event) => {
        const archivo = {
            path: event.target.files[0],
        };
        setArchivoPDF(archivo);
    };
    const handleArchivoColaboradorPDFChange = (event) => {
        const archivo = {
            path: event.target.files[0],
        };
        setArchivoColaboradorPDF(archivo);
    };

    const [fecha, setFecha] = useState({
        fechaMuestra: moment().format('DD/MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const URL_LOCALIDAD = window.API_ROUTES.LOCALIDAD;
    const URL_ZONA = window.API_ROUTES.ZONA;
    const URL_DOMICILIOCOMERCIAL = window.API_ROUTES.DOMICILIOCOMERCIAL;
    const URL_DOMICILIOPARTICULAR = window.API_ROUTES.DOMICILIOPARTICULAR;
    const URL_CONDICIONFISCALTITULAR = window.API_ROUTES.CONDICIONFISCALTITULAR;
    const URL_RESOLUCION = window.API_ROUTES.RESOLUCION;
    const URL_AGENCIA = window.API_ROUTES.AGENCIA;
    const URL_TITULAR = window.API_ROUTES.TITULAR;
    const URL_PERSONA = window.API_ROUTES.PERSONA;
    const URL_TITULARAGENCIA = window.API_ROUTES.TITULARAGENCIA;
    const URL_COLABORADOR = window.API_ROUTES.COLABORADOR;
    const URL_ARCHIVO = window.API_ROUTES.ARCHIVO;
    const URL_ARCHIVORESOLUCION = window.API_ROUTES.ARCHIVORESOLUCION;
    const URL_TIPOSUCURSAL = window.API_ROUTES.TIPOSUCURSAL;

    const [mostrarError, setMostrarError] = useState(false);
    const navigate = useNavigate();
    var currentTime = moment().utcOffset('-03:00').format('hh:mm');
    var currentDate = moment().utcOffset('-03:00').format('YYYY-MM-DD');
    const [nroAgencia, setNroAgencia] = useState('');
    const [nroSubagencia, setNroSubagencia] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [tipo, setTipo] = useState([]);
    const [titular, setTitular] = useState([]);
    const [fechaDesde, setFechaDesde] = useState([]);
    const [fechaResolucion, setFechaResolucion] = useState({
        fechaMuestra: moment().format('DD/MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const [fechaNotificacion, setFechaNotificacion] = useState(
        moment().format('DD/MM/YYYY')
    );
    const [expediente, setExpediente] = useState('');
    const [resolucion, setResolucion] = useState('');
    const [comentario, setComentario] = useState('');
    const [nombreColaborador, setNombreColaborador] = useState('');
    const [apellidoColaborador, setApellidoColaborador] = useState('');
    const [documentoColaborador, setDocumentoColaborador] = useState('');
    const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
    const [tablaLocalidad, setTablaLocalidad] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    const [domicilioComercial, setDomicilioComercial] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [tipoSucursal, setTipoSucursal] = useState([]);
    const [coordenadas, setCoordenadas] = useState('0');
    const [tablaTipoSucursal, setTablaTipoSucursal] = useState([]);
    const [tablaZona, setTablaZona] = useState([]);
    const [zona, setZona] = useState('');
    const [numero, setNumero] = useState();
    const [datosResolucionView, setDatosResolucionView] = useState([]);
    const [archivoView, setArchivoView] = useState([]);
    const [dataResolucion, setDataResolucion] = useState([]);
    const [dataDomicilio, setDataDomicilio] = useState([]);
    const [dataColaboradores, setDataColaboradores] = useState([]);
    const [isLoadingView, setIsLoadingView] = useState([]);
    const [titularView, setTitularView] = useState([]);
    const [tituloModalVerTitular, setTituloModalVerTitular] = useState('');
    const [domicilioView, setDomicilioView] = useState([]);
    const [condicionView, setcondicionView] = useState([]);

    // const [selectedOption, setSelectedOption] = useState("");

    // const [opcionesTitulares, setOpcionesTitulares] = useState([]);

    const [data, setData] = useState([]);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const {
        register,
        formState: { errors },
        reset,
    } = useForm();
    const [fila, setFila] = useState([]);
    const [tituloModalColaborador, setTituloModalColaborador] = useState([]);
    const [idColaborador, setIdColaboradorModal] = useState([]);
    const [nombreColaboradorModal, setNombreColaboradorModal] = useState([]);
    const [apellidoColaboradorModal, setApellidoColaboradorModal] = useState(
        []
    );
    const [documentoColaboradorModal, setDocumentoColaboradorModal] = useState(
        []
    );
    const [añadirTitularModal, setAñadirTitularModal] = useState(false);
    const [titulares, setTitulares] = useState([]);
    const requiredSymbol =
        props.titulo !== 'Ver' ? <b style={{ color: 'red' }}>*</b> : '';

    useEffect(() => {
        cargarTitulares();
        cargarZonas();
        cargarTiposSucursales();
        cargarLocalidades();
    }, []);

    const cambioLocalidad = (zona) => {
        ConsultasAPI.ObtenerObjeto(URL_ZONA, zona).then((response) => {
            setLocalidad(response.data.localidad_detalle.nombre);
        });
    };

    useEffect(() => {
        if (props.show) {
            setIsLoading(true);
            if (props.titulo === 'Ver' || props.titulo === 'Editar') {
                cargarResoluciones();
                cargarDomicilios();
                cargarColaboradores();
                setNroAgencia(props.datosAgencia.numero_agencia);
                setNroSubagencia(props.datosAgencia.numero_subagencia);
                setTelefono(props.datosAgencia.telefono);
                setEmail(props.datosAgencia.email);
                if (props.datosDomicilio) {
                    setDomicilioComercial(props.datosDomicilio.calle);
                    setNumero();
                    setCoordenadas(props.datosDomicilio.coordenadas);
                    setZona(props.datosDomicilio.zona.descripcion);
                    setLocalidad(props.datosDomicilio.localidad_detalle.nombre);
                    setTipoSucursal(
                        props.datosDomicilio.tiposucursal_detalle.nombre
                    );
                }
            }
            if (props.titulo === 'Asignar Ubicacion de') {
                setNroAgencia(props.datosAgencia.numero_agencia);
                setNroSubagencia(props.datosAgencia.numero_subagencia);
                setTelefono(props.datosAgencia.telefono);
                setEmail(props.datosAgencia.email);
                setSelectedOption(selectedOption[0]);
            }
            if (props.titulo === 'Asignar Titular de') {
                setNroAgencia(props.datosAgencia.numero_agencia);
                setNroSubagencia(props.datosAgencia.numero_subagencia);
                setTelefono(props.datosAgencia.telefono);
                setEmail(props.datosAgencia.email);
                setSelectedOption(selectedOption[0]);
                setDomicilioComercial(props.datosDomicilio.calle);
                setNumero();
                setLocalidad(
                    props.datosDomicilio.zona
                        ? props.datosDomicilio.zona.localidad_detalle.nombre
                        : null
                );
                setCoordenadas(props.datosDomicilio.coordenadas);
                setZona(
                    props.datosDomicilio.zona
                        ? props.datosDomicilio.zona.id
                        : null
                );
                setTipoSucursal(
                    props.datosDomicilio.tiposucursal_detalle
                        ? props.datosDomicilio.tiposucursal_detalle.nombre
                        : null
                );
            }
            if (props.titulo === 'Asignar Titular y Ubicacion de') {
                setNroAgencia(props.datosAgencia.numero_agencia);
                setNroSubagencia(props.datosAgencia.numero_subagencia);
                setTelefono(props.datosAgencia.telefono);
                setEmail(props.datosAgencia.email);
            }
            setIsLoading(false);
        }
    }, [props.show]);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const cargarTitulares = async () => {
        var titus = [];
        ConsultasAPI.ListarObjetos(URL_TITULARAGENCIA + 'titularesSinAg/').then(
            (response) => {
                response.data.forEach((titular) => {
                    let obj = {
                        id: titular.id,
                        label:
                            titular.persona_detalle.cuit +
                            '-' +
                            titular.persona_detalle.apellido +
                            ' ' +
                            titular.persona_detalle.nombre,
                    };
                    titus.push(obj);
                });
                setTitulares(titus);
                // setTitular(titus.filter((titular) => titular.id === selectedId));
            }
        );
    };
    const handleNuevoTitular = (selectedId) => {
        cargarTitulares();
    };

    const cargarLocalidades = () => {
        let i = 0;
        ConsultasAPI.ListarObjetos(URL_LOCALIDAD + 'listSinPag/').then(
            (response) => {
                let results = response.data.map((localidad) => ({
                    value: localidad.id,
                    label: localidad.nombre,
                }));
                setTablaLocalidad(results);
            }
        );
    };

    const cargarZonas = () => {
        // ConsultasAPI.ListarObjetos(URL_ZONA).then((response) => {
        //   setTablaZona(response.data.results);
        //   setZona(response.data.results[0].id);
        // });
        ConsultasAPI.ListarObjetos(URL_ZONA + 'listSinPag/').then(
            (response) => {
                setTablaZona(response.data);
                setZona(response.data[0].id);
            }
        );
    };
    const cargarTiposSucursales = () => {
        ConsultasAPI.ListarObjetos(URL_TIPOSUCURSAL).then((response) => {
            setTablaTipoSucursal(response.data.results);
            setTipoSucursal(
                response.data.results.length > 0
                    ? response.data.results[0].id
                    : ''
            );
        });
    };

    const cargarResoluciones = async () => {
        // setDataResolucion([]);
        const response7 = await ConsultasAPI.ObtenerObjeto(
            URL_RESOLUCION + 'listResoluciones/',
            props.datosAgencia.id
        );
        if (response7.status === 200) {
            let resoluciones = response7.data;
            let datosTablaResol = [];
            for (let i = 0; i < resoluciones.length; i++) {
                const archivoRes = await ConsultasAPI.ObtenerObjeto(
                    URL_ARCHIVORESOLUCION + 'busqueda/',
                    resoluciones[i].id
                );
                let datosResolucion = {
                    id: resoluciones[i].id,
                    expediente: resoluciones[i].expediente,
                    resolucion: resoluciones[i].resolucion,
                    fechaResolucion:
                        resoluciones[i].fecha_resolucion.split(' ')[0],
                    fechaNotificacion:
                        resoluciones[i].fecha_notificacion.split(' ')[0],
                    motivo: resoluciones[i].tipo_resolucion,
                    archivo:
                        archivoRes.status === 200
                            ? archivoRes.data.archivo_detalle.path
                            : '-',
                    titular: resoluciones[i].datos_json
                        ? resoluciones[i].datos_json.titular
                            ? resoluciones[i].datos_json.titular.titular_detalle
                                ? resoluciones[i].datos_json.titular
                                      .titular_detalle.persona_detalle.cuit +
                                  '-' +
                                  resoluciones[i].datos_json.titular
                                      .titular_detalle.persona_detalle
                                      .apellido +
                                  ', ' +
                                  resoluciones[i].datos_json.titular
                                      .titular_detalle.persona_detalle.nombre
                                : resoluciones[i].datos_json.titular
                                        .persona_detalle
                                  ? resoluciones[i].datos_json.titular
                                        .persona_detalle.cuit +
                                    '-' +
                                    resoluciones[i].datos_json.titular
                                        .persona_detalle.apellido +
                                    ', ' +
                                    resoluciones[i].datos_json.titular
                                        .persona_detalle.nombre
                                  : resoluciones[i].datos_json.titular
                            : null
                        : null,
                };
                datosTablaResol.push(datosResolucion);
            }
            setDataResolucion(datosTablaResol);
        }
    };

    const cargarColaboradores = async () => {
        ConsultasAPI.ObtenerObjeto(
            URL_COLABORADOR + 'listColaboradores/',
            props.datosAgencia.id
        ).then((response) => {
            let datosColaborador = [];
            if (response.data.length > 0) {
                response.data.forEach((colaborador) => {
                    datosColaborador.push({
                        id: colaborador.id,
                        nombreYapellido:
                            colaborador.persona_detalle.apellido +
                            ', ' +
                            colaborador.persona_detalle.nombre,
                        documento: colaborador.persona_detalle.documento,
                        fechaAlta: colaborador.persona_detalle.fecha_creacion,
                        fechaActualizacion: '-',
                        estado: '-',
                    });
                });
                setDataColaboradores(datosColaborador);
            } else setDataColaboradores([]);
        });
    };

    const cargarDomicilios = async () => {
        const domicilios = await ConsultasAPI.ObtenerObjeto(
            URL_DOMICILIOCOMERCIAL + 'listDomicilios/',
            props.datosAgencia.id
        );
        let datosTablaDom = [];
        const resolucionCambioDomicilio = await ConsultasAPI.ObtenerObjeto(
            URL_RESOLUCION + 'listResolucionesCambio/',
            props.datosAgencia.id
        );
        if (domicilios.data.length > 0)
            domicilios.data.forEach((domicilio) => {
                let datosDomicilio = {
                    id: domicilio.id,
                    comuna: domicilio.localidad_detalle.nombre,
                    coordenadas: domicilio.coordenadas,
                    fechaDesde: domicilio.fecha_creacion,
                    fechaHasta: domicilio.fecha_hasta,
                    archivo: resolucionCambioDomicilio.data
                        ? resolucionCambioDomicilio.data.archivo
                        : '-',
                };
                datosTablaDom.push(datosDomicilio);
            });
        setDataDomicilio(datosTablaDom ?? []);
    };

    // Guarda el archivo en la base de datos, pasa los datos al back
    const editarAgencia = () => {
        Swal.fire({
            title: '¿Estás seguro de editar la Agencia?',
            text: 'Esta acción no se puede revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let agencia;
                if (!nroSubagencia) {
                    //Es decir es agencia, no subAgencia
                    agencia = {
                        numero_agencia:
                            props.datosAgencia.numero_agencia === nroAgencia
                                ? props.datosAgencia.numero_agencia
                                : nroAgencia,
                        numero_subagencia: 0,
                        telefono:
                            props.datosAgencia.telefono === telefono
                                ? props.datosAgencia.telefono
                                : telefono,
                        email:
                            props.datosAgencia.email === email
                                ? props.datosAgencia.email
                                : email,
                        estado: props.datosAgencia.estado,
                    };
                } else {
                    agencia = {
                        numero_agencia:
                            props.datosAgencia.numero_agencia === nroAgencia
                                ? props.datosAgencia.numero_agencia
                                : nroAgencia,
                        numero_subagencia:
                            props.datosAgencia.numero_subagencia ===
                            nroSubagencia
                                ? props.datosAgencia.numero_subagencia
                                : nroSubagencia,
                        telefono:
                            props.datosAgencia.telefono === telefono
                                ? props.datosAgencia.telefono
                                : telefono,
                        email:
                            props.datosAgencia.email === email
                                ? props.datosAgencia.email
                                : email,
                        estado: props.datosAgencia.estado,
                    };
                }
                ConsultasAPI.ModificarObjeto(
                    URL_AGENCIA,
                    props.datosAgencia.id,
                    agencia
                ).then((response) => {
                    if (response.status === 200) {
                        props.recargarTabla();
                        Swal.fire(
                            'Edicion exitosa',
                            'Se edito con Exito la Agencia',
                            'success'
                        );
                    }
                });
            }
        });
    };

    // Función para crear el titular agencia
    const crearTitularAgencia = async (titularAgencia) => {
        try {
            const response = await ConsultasAPI.CrearObjeto(
                URL_TITULARAGENCIA,
                titularAgencia
            );
            console.info('Registro TitularAgencia creado:', response.data);
            const titularAgenciaId = response.data.id; // Suponiendo que la respuesta contiene el ID
            return titularAgenciaId;
        } catch (error) {
            console.error('Error al crear el titular agencia:', error);
            throw error;
        }
    };

    // Función para crear el domicilio comercial
    const crearDomicilioComercial = async (domicilioC) => {
        try {
            const response = await ConsultasAPI.CrearObjeto(
                URL_DOMICILIOCOMERCIAL,
                domicilioC
            );
            console.info('Registro DomicilioComercial creado:', response.data);
            const domicilioCId = response.data.id; // Suponiendo que la respuesta contiene el ID
            return domicilioCId;
        } catch (error) {
            console.error('Error al crear el domicilio comercial:', error);
            throw error;
        }
    };

    const concatCodigo = (a, b) => {
        let codigoAgencia = '';
        if (a < 10) {
            codigoAgencia = '0' + a.toString();
            if (b < 10) {
                codigoAgencia = codigoAgencia + '00' + b.toString();
            } else {
                codigoAgencia = codigoAgencia + '0' + b.toString();
            }
        } else {
            codigoAgencia = a.toString();
            if (b < 10) {
                codigoAgencia = codigoAgencia + '00' + b.toString();
            } else {
                codigoAgencia = codigoAgencia + '0' + b.toString();
            }
        }
        return codigoAgencia;
    };
    const crearAgenciaCompleta = async (data) => {
        let archivoId = null;
        try {
            var dataCrear;
            const nombreCarpeta = await concatCodigo(
                data.agencia.numero_agencia,
                data.agencia.numero_subagencia
            );
            const formData = new FormData();
            formData.append('path', data.archivoPDF.path);
            formData.append(
                'nombre_subcarpeta',
                nombreCarpeta + '/resoluciones'
            ); // Reemplaza "nombre_deseado" con el valor que deseas
            // formData.append("file", data.archivoPDF.file);
            const response1 = await ConsultasAPI.CrearObjeto(
                URL_ARCHIVO,
                formData
            );
            console.info('Registro Archivo creado:', response1.data);
            archivoId = response1.data.id;
            dataCrear = { ...data, archivo: archivoId };
            const response = await ConsultasAPI.CrearObjeto(
                URL_AGENCIA + 'crearAgenciaCompleta/',
                dataCrear
            );

            return { id: archivoId, error: null };
        } catch (error) {
            console.error('Error en el flujo de creación:', error);
            if (archivoId) {
                ConsultasAPI.BorrarObjeto(URL_ARCHIVO, archivoId);
            }
            return { id: 0, error: error };
        }
    };

    const crearArchivo = async (archivo) => {
        try {
            const formData = new FormData();
            formData.append('path', archivo.path);
            formData.append('file', archivo.file); // Assuming 'file' is the field for the actual file content

            const response = await ConsultasAPI.CrearObjeto(
                URL_ARCHIVO,
                formData
            );
            console.info('Registro Archivo creado:', response.data);
            const archivoId = response.data.id; // Suponiendo que la respuesta contiene el ID del archivo creado
            return archivoId;
        } catch (error) {
            console.error('Error al crear el archivo:', error);
            throw error;
        }
    };

    // Función para crear el registro ArchivoResolucion
    const crearArchivoResolucion = async (resolucionId, archivoId) => {
        const archivoResolucionT = {
            resolucion: resolucionId,
            archivo: archivoId,
        };

        try {
            const response = await ConsultasAPI.CrearObjeto(
                URL_ARCHIVORESOLUCION,
                archivoResolucionT
            );
            console.info('Registro ArchivoResolucion creado:', response.data);
        } catch (error) {
            console.error(
                'Error al crear el registro ArchivoResolucion:',
                error
            );
            throw error;
        }
    };

    function formatDateToISO(dateString) {
        // Parse the input date string in DD/MM/YYYY format
        const parts = dateString.split('/');
        const day = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1; // Months are 0-indexed
        const year = parseInt(parts[2]);

        // Create a Date object
        const date = new Date(year, month, day);

        // Format the date in ISO 8601 format
        const formattedDate = date.toISOString();

        return formattedDate;
    }

    const subirAgencia = async () => {
        if (selectedOption && archivoPDF && archivoPDF.path) {
            try {
                const result = await Swal.fire({
                    title: '¿Estás seguro de guardar la Agencia?',
                    text: 'Esta acción no se puede revertir',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#008185',
                    cancelButtonColor: '#EC1B23',
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                });

                if (result.isConfirmed) {
                    let agencia;
                    if (!nroSubagencia) {
                        //Es decir es agencia, no subAgencia
                        agencia = {
                            numero_agencia: nroAgencia,
                            agencia_subagencia: 0,
                            telefono: telefono,
                            email: email,
                        };
                    } else {
                        agencia = {
                            numero_agencia: nroAgencia,
                            numero_subagencia: nroSubagencia,
                            telefono: telefono,
                            email: email,
                        };
                    }

                    // se crea la agencia
                    // const agenciaId = await crearAgencia(agencia);
                    // se crea el titular agencia
                    let titularAgencia = {
                        // agencia: agenciaId,
                        titular: selectedOption.id,
                    };
                    // const titularAgenciaId = await crearTitularAgencia(titularAgencia);
                    // }
                    // se crea el domicilio comercial
                    // const agenciaId = 2724;
                    let domicilio;
                    if (domicilioComercial && tipoSucursal) {
                        domicilio = {
                            calle: domicilioComercial,
                            numero: numero,
                            zona: zona,
                            coordenadas: coordenadas ?? '0',
                            tiposucursal: tipoSucursal,
                            localidad: localidad.value,
                            // agencia: agenciaId,
                        };
                        // const domicilioId = await crearDomicilioComercial(domicilio);
                    }
                    // se crea la resolucion, el archivo y el archivo resolucion
                    let resolucionT;
                    if (resolucion.length !== 0 && archivoPDF) {
                        resolucionT = {
                            resolucion: resolucion,
                            fecha_resolucion: formatDateToISO(
                                fechaResolucion.fechaMuestra
                            ),
                            fecha_notificacion:
                                formatDateToISO(fechaNotificacion),
                            // agencia: agenciaId,
                            tipoderesolucion: 1,
                            expediente: expediente,
                            comentario: comentario,
                            datos_json: {
                                titular: selectedOption.label,
                            },
                        };

                        let data = {
                            agencia,
                            titularAgencia,
                            domicilio,
                            resolucionT,
                            archivoPDF,
                            archivoColaboradorPDF,
                        };
                        // Crear la resolución y obtener su ID
                        // const resolucionId = await crearResolucion(resolucionT);
                        // var resolucionId=27;

                        // Crear el archivo y obtener su ID
                        // const archivoId = await crearArchivo(archivoPDF);

                        // Crear el registro ArchivoResolucion que asocia el archivo con la resolución
                        // const archivoResolucionId = await crearArchivoResolucion(
                        //   resolucionId,
                        //   archivoId
                        // );
                        crearAgenciaCompleta(data)
                            .then((response) => {
                                if (response.id > 0) {
                                    Swal.fire(
                                        'Creación exitosa',
                                        'Se creo con Éxito la Agencia',
                                        'success'
                                    );
                                    console.info(
                                        'Todas las operaciones completadas con éxito.'
                                    );
                                    handleClose();
                                } else {
                                    console.error(
                                        'Error en el flujo de creación:',
                                        response.error
                                    );
                                    Swal.fire(
                                        'Creación fallida',
                                        'No Se creo la Agencia',
                                        'error'
                                    );
                                }
                            })
                            .catch((error) => {
                                console.error(
                                    'Error en el flujo de creación:',
                                    error
                                );
                                Swal.fire(
                                    'Creación fallida',
                                    'No Se creo la Agencia',
                                    'error'
                                );
                            });
                        // Aquí podrías mostrar un mensaje de error al usuario, si lo deseas.
                    } else {
                        Notificaciones.notificacion('Complete los datos');
                    }
                }
            } catch (error) {
                console.error('Error al mostrar el diálogo:', error);
                // Aquí podrías mostrar un mensaje de error al usuario, si lo deseas.
            }
        } else if (selectedOption) {
            Notificaciones.notificacion(
                'Debe subir el archivo de la resolución'
            );
        } else {
            Notificaciones.notificacion('Debe elegir un titular');
        }
    };

    const columns = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            size: 50,
        },
        {
            header: 'Expediente',
            accessorKey: 'expediente',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Resolucion',
            accessorKey: 'resolucion',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha de Resolucion',
            accessorKey: 'fechaResolucion',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha de Notificacion',
            accessorKey: 'fechaNotificacion',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Motivo',
            accessorKey: 'motivo',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Titular',
            accessorKey: 'titular',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Archivo',
            accessorKey: 'archivo',
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    const columnsColaboradores = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            size: 50,
        },
        {
            header: 'Nombre y Apellido',
            accessorKey: 'nombreYapellido',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Documento',
            accessorKey: 'documento',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha de Alta',
            accessorKey: 'fechaAlta',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha de Actualizacion',
            accessorKey: 'fechaActualizacion',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Estado',
            accessorKey: 'estado',
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    const columnsDomicilio = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            size: 50,
        },
        {
            header: 'Comuna',
            accessorKey: 'comuna',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Coordenadas',
            accessorKey: 'coordenadas',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha Desde',
            accessorKey: 'fechaDesde',
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: 'Fecha Hasta',
            accessorKey: 'fechaHasta',
            size: 50,
            enableColumnFilter: false,
        },
        // {
        //   header: "Archivo",
        //   accessorKey: "archivo",
        //   size: 50,
        //   enableColumnFilter: false,
        // },
    ]);

    const handleClose = () => {
        props.recargarTabla();
        setNroAgencia('');
        setNroSubagencia('');
        setTelefono('');
        setEmail('');
        setSelectedOption('');
        setDomicilioComercial('');
        setNumero();
        setLocalidad('');
        setCoordenadas('0');
        setExpediente('');
        setComentario('');
        // setFechaResolucion("");
        setApellidoColaborador('');
        setNombreColaborador('');
        setDocumentoColaborador('');
        setDataDomicilio([]);
        setDataResolucion([]);
        props.onClose();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (props.titulo === 'Nuevo') {
            subirAgencia();
        }
        if (props.titulo === 'Editar') {
            editarAgencia();
        }
        if (props.titulo === 'Asignar Ubicacion de') {
            asignarDomicilio();
        }
        if (props.titulo === 'Asignar Titular de') {
            asignarTitular();
        }
        if (props.titulo === 'Asignar Titular y Ubicacion de') {
            asignarAgencia();
        }
        props.recargarTabla();
        // handleClose();
    };
    const asignarAgencia = () => {
        Swal.fire({
            title: '¿Estás seguro de guardar la Agencia?',
            text: 'Esta acción no se puede revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let agencia;
                if (!selectedOption) {
                    if (!nroSubagencia) {
                        //Es decir es agencia, no subAgencia
                        agencia = {
                            numero_agencia: nroAgencia,
                            agencia_subagencia: 0,
                            telefono: telefono,
                            email: email,
                            estado: false,
                        };
                    } else {
                        agencia = {
                            numero_agencia: nroAgencia,
                            numero_subagencia: nroSubagencia,
                            telefono: telefono,
                            email: email,
                            estado: false,
                        };
                    }
                } else {
                    if (!nroSubagencia) {
                        //Es decir es agencia, no subAgencia
                        agencia = {
                            numero_agencia: nroAgencia,
                            agencia_subagencia: 0,
                            telefono: telefono,
                            email: email,
                            estado: 'Activo',
                        };
                    } else {
                        agencia = {
                            numero_agencia: nroAgencia,
                            numero_subagencia: nroSubagencia,
                            telefono: telefono,
                            email: email,
                            estado: 'Activo',
                        };
                    }
                }
                if (selectedOption) {
                    let titularAgencia = {
                        agencia: props.datosAgencia.id,
                        titular: selectedOption.id,
                    };
                    ConsultasAPI.CrearObjeto(
                        URL_TITULARAGENCIA,
                        titularAgencia
                    );
                }
                if (domicilioComercial) {
                    let domicilio = {
                        calle: domicilioComercial,
                        numero: numero,
                        zona: zona,
                        coordenadas: coordenadas,
                        tiposucursal: tipoSucursal,
                        agencia: props.datosAgencia.id,
                    };
                    ConsultasAPI.CrearObjeto(URL_DOMICILIOCOMERCIAL, domicilio);
                }
                if (resolucion.length !== 0) {
                    let resolucionT;
                    resolucionT = {
                        resolucion: resolucion,
                        agencia: props.datosAgencia.id,
                        tipoderesolucion: 1,
                        expediente: expediente,
                        comentario: comentario,
                        datos_json: {
                            titular: selectedOption.label,
                        },
                    };
                    ConsultasAPI.CrearObjeto(URL_RESOLUCION, resolucionT);
                }
                ConsultasAPI.ModificarObjeto(
                    URL_AGENCIA,
                    props.datosAgencia.id,
                    agencia
                ).then((response) => {
                    if (response.status === 200) {
                        props.recargarTabla();
                        Swal.fire(
                            'Asignacion exitosa',
                            'Se asigno con Exito',
                            'success'
                        );
                    }
                });
            }
        });
    };

    const asignarDomicilio = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let agencia;
                if (!nroSubagencia) {
                    //Es decir es agencia, no subAgencia
                    agencia = {
                        numero_agencia: nroAgencia,
                        numero_subagencia: 0,
                        telefono: telefono,
                        email: email,
                    };
                } else {
                    agencia = {
                        numero_agencia: nroAgencia,
                        numero_subagencia: nroSubagencia,
                        telefono: telefono,
                        email: email,
                    };
                }
                if (domicilioComercial) {
                    let domicilio = {
                        calle: domicilioComercial,
                        numero: numero,
                        zona: zona,
                        localidad: localidad.value,
                        coordenadas: coordenadas,
                        tiposucursal: tipoSucursal,
                        agencia: props.datosAgencia.id,
                    };
                    const dom = crearDomicilioComercial(domicilio);
                }
            }
        });
    };

    const asignarTitular = async () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let agencia;
                if (!nroSubagencia) {
                    //Es decir es agencia, no subAgencia
                    agencia = {
                        numero_agencia: nroAgencia,
                        numero_subagencia: 0,
                        telefono: telefono,
                        email: email,
                        estado: 'Activo',
                    };
                } else {
                    agencia = {
                        numero_agencia: nroAgencia,
                        numero_subagencia: nroSubagencia,
                        telefono: telefono,
                        email: email,
                        estado: 'Activo',
                    };
                }
                if (selectedOption) {
                    let titularAgencia = {
                        agencia: props.datosAgencia.id,
                        titular: selectedOption.id,
                    };
                    crearTitularAgencia(titularAgencia);
                    ConsultasAPI.ModificarObjeto(
                        URL_AGENCIA,
                        props.datosAgencia.id,
                        agencia
                    ).then((response) => {});
                }
            }
        });
    };

    const handleSubmitColaborador = (event) => {
        event.preventDefault();
        if (tituloModalColaborador === 'Agregar Colaborador') {
            agregarColaborador();
        }
    };

    const handleChange = (event) => {
        setOpcionSeleccionada(event.target.value);
    };

    const handleOpenModalEditar = async (row) => {
        if (row) {
            const resolucion = await ConsultasAPI.ObtenerObjeto(
                URL_RESOLUCION,
                row.id
            );
            setArchivoView(row.archivo);
            let dataResolucion = resolucion.data;
            const localidadData = await ConsultasAPI.ObtenerObjeto(
                URL_LOCALIDAD + 'busquedaAgencia/',
                dataResolucion.agencia
            );
            if (localidadData.status === 200) {
                dataResolucion.datos_json.localidad =
                    localidadData.data.localidad_detalle.nombre;
                dataResolucion.datos_json.tiposucursal =
                    localidadData.data.tiposucursal_detalle.nombre;
            }
            //const sucursalData = await ConsultasAPI.ObtenerObjeto(URL_TIPOSUCURSAL,dataResolucion.datos_json.tiposucursal);
            //if(sucursalData.status===200){
            // dataResolucion.datos_json.tiposucursal = sucursalData.data.tiposucursal_detalle.nombre
            //}
            setDatosResolucionView(dataResolucion);
            if (row.motivo === 'CAMBIO DE DOMICILIO') {
                const zona = await ConsultasAPI.ObtenerObjeto(
                    URL_ZONA,
                    resolucion.data.datos_json.zona
                );
                setNombreZona(zona.data.descripcion);
            }
        } else {
            setDatosResolucionView(row);
        }
        setShowModalEditar(true);
    };

    const handleSelectTitular = (selected) => {
        setSelectedOption(selected[0]);
    };

    const [showModalEditar, setShowModalEditar] = useState(false);
    const [tituloModal, setTituloModal] = useState('');

    const handleCloseModalEditar = () => {
        // event.preventDefault();
        cargarResoluciones();
        cargarDomicilios();
        props.recargarTabla();
        setShowModalEditar(false);
    };

    const handleOpenModalColaborador = async (row) => {
        if (row) {
            setIdColaboradorModal(row.id);
            setTituloModalColaborador('Eliminar Colaborador');
            setNombreColaboradorModal(row.nombreYapellido.split(',')[1]);
            setApellidoColaboradorModal(row.nombreYapellido.split(',')[0]);
            setDocumentoColaboradorModal(row.documento);
        } else {
            setTituloModalColaborador('Agregar Colaborador');
            setApellidoColaboradorModal('');
            setNombreColaboradorModal('');
            setDocumentoColaboradorModal('');
            // agregarColaborador();
        }
        setFila(row);
        setShowModalColaborador(true);
    };

    const handleCloseModalColaborador = () => {
        setShowModalColaborador(false);
        setApellidoColaborador('');
        setNombreColaborador('');
        setDocumentoColaborador('');
        cargarColaboradores();
        // props.onClose();
    };
    const [showModal, setShowModal] = useState(false);
    const [showModalColaborador, setShowModalColaborador] = useState(false);

    const agregarColaborador = () => {
        Swal.fire({
            title: '¿Estás seguro de agregar el Colaborador?',
            text: 'Esta acción no se puede revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let persona;
                let idAgencia = props.datosAgencia.id;
                persona = {
                    apellido: apellidoColaboradorModal,
                    nombre: nombreColaboradorModal,
                    cuit: documentoColaboradorModal,
                    documento: documentoColaboradorModal,
                    email: 'colaborador@gmail.com',
                    telefono: '11111111',
                };
                ConsultasAPI.CrearObjeto(URL_PERSONA, persona).then(
                    (response) => {
                        let colaborador;
                        colaborador = {
                            persona: response.data.id,
                            agencia: idAgencia,
                        };
                        ConsultasAPI.CrearObjeto(
                            URL_COLABORADOR,
                            colaborador
                        ).then((response) => {
                            if (response.status === 201) {
                                const idarchivo = crearArchivo(archivoPDF);
                                Swal.fire(
                                    'Exito',
                                    'Se agrego con Exito el colaborador',
                                    'success'
                                );
                                handleCloseModalColaborador();
                            }
                        });
                    }
                );
            }
        });
    };

    const eliminarColaborador = (colaborador) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el Colaborador?',
            text: 'Esta acción no se puede revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                ConsultasAPI.BorrarObjeto(URL_COLABORADOR, colaborador.id)
                    .then((response) => {
                        cargarColaboradores();
                        Swal.fire(
                            'Exito',
                            'Se elimino con Exito el colaborador',
                            'success'
                        );
                        handleCloseModalColaborador();
                    })
                    .catch(() => {
                        Swal.fire(
                            'Error',
                            'Ocurrio un error al eliminar el colaborador',
                            'success'
                        );
                    });
            }
        });
    };

    const handleFormKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter

            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex + 1];
            if (nextControl) {
                nextControl.focus();
            }
        }
    };

    const handleOpenViewModalTitular = async () => {
        setIsLoadingView(true);
        const response = await ConsultasAPI.ObtenerObjeto(
            URL_TITULAR,
            props.datosTitularAgencia.id
        );
        setTitularView(response.data);
        const response2 = await ConsultasAPI.ObtenerObjeto(
            URL_DOMICILIOPARTICULAR + 'busqueda/',
            response.data.id
        );
        setDomicilioView(response2.data);
        const response3 = await ConsultasAPI.ObtenerObjeto(
            URL_CONDICIONFISCALTITULAR + 'busqueda/',
            response.data.id
        );
        setcondicionView(response3.data);
        setTituloModalVerTitular('Ver');
        setIsLoadingView(false);
        setAñadirTitularModal(true);
    };

    return isLoading ? (
        <Modal
            centered
            show={isLoading && props.show}
            onHide={() => setIsLoading(false)}
        >
            <Modal.Body className="text-center">
                <Spinner animation="border" />
                <p>Cargando datos de Agencia...</p>
            </Modal.Body>
        </Modal>
    ) : (
        <Modal show={props.show} size="xl">
            <Modal.Header closeButton onClick={handleClose}>
                <Modal.Title>{props.titulo} Agencia</Modal.Title>
            </Modal.Header>
            <Form
                onSubmit={handleSubmit}
                id="FormOrigen1"
                onKeyDown={handleFormKeyDown}
            >
                <Modal.Body>
                    <Card className="m-3">
                        <Card.Body>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <Form.Label>
                                        Nro Agencia {requiredSymbol}
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Agencia"
                                        onKeyPress={(event) => {
                                            // Solo permitir números
                                            const regex = /[0-9]/;
                                            const key = String.fromCharCode(
                                                event.charCode
                                            );
                                            if (!regex.test(key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        value={nroAgencia}
                                        readOnly={
                                            props.titulo === 'Ver' ||
                                            props.titulo ===
                                                'Asignar Ubicacion de' ||
                                            props.titulo === 'Editar'
                                                ? true
                                                : false
                                        }
                                        onChange={(event) =>
                                            setNroAgencia(event.target.value)
                                        }
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>
                                        Nro SubAgencia {requiredSymbol}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="SubAgencia"
                                        onKeyPress={(event) => {
                                            // Solo permitir números
                                            const regex = /[0-9]/;
                                            const key = String.fromCharCode(
                                                event.charCode
                                            );
                                            if (!regex.test(key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        value={nroSubagencia}
                                        readOnly={
                                            props.titulo === 'Ver' ||
                                            props.titulo ===
                                                'Asignar Ubicacion de'
                                                ? true
                                                : false
                                        }
                                        required
                                        onChange={(event) =>
                                            setNroSubagencia(event.target.value)
                                        }
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>
                                        Tipo {requiredSymbol}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            nroSubagencia === '' ||
                                            nroSubagencia === '0' ||
                                            nroSubagencia === 0
                                                ? 'AGENCIA'
                                                : 'SUBAGENCIA'
                                        }
                                        readOnly={true}
                                        placeholder={
                                            nroSubagencia === '' ||
                                            nroSubagencia === '0' ||
                                            nroSubagencia === 0
                                                ? 'AGENCIA'
                                                : 'SUBAGENCIA'
                                        }
                                        required
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Telefono</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Telefono de la agencia"
                                        value={telefono ? telefono : ''}
                                        readOnly={
                                            props.titulo === 'Ver' ||
                                            props.titulo ===
                                                'Asignar Ubicacion de'
                                                ? true
                                                : false
                                        }
                                        onChange={(event) =>
                                            setTelefono(event.target.value)
                                        }
                                        onKeyPress={(event) => {
                                            const keyCode =
                                                event.keyCode || event.which;
                                            const keyValue =
                                                String.fromCharCode(keyCode);
                                            // Permitir solo números (keyCode 48-57 son los códigos para los números 0 al 9)
                                            if (!/^[0-9]+$/.test(keyValue)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    {nroSubagencia === '' ||
                                    nroSubagencia === '0' ||
                                    nroSubagencia === 0 ? (
                                        <>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Email de la agencia"
                                                value={email}
                                                readOnly={
                                                    props.titulo === 'Ver' ||
                                                    props.titulo ===
                                                        'Asignar Ubicacion de'
                                                        ? true
                                                        : false
                                                }
                                                onChange={(event) => {
                                                    if (
                                                        event.target.value.trim() !==
                                                        ''
                                                    ) {
                                                        setEmail(
                                                            event.target.value
                                                        );
                                                    } else {
                                                        setEmail('');
                                                    }
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>
                                        Titular {requiredSymbol}
                                        {props.titulo === 'Ver' ||
                                        props.titulo === 'Editar' ? (
                                            <IconButton
                                                onClick={() => {
                                                    handleOpenViewModalTitular();
                                                }}
                                            >
                                                <Visibility />
                                            </IconButton>
                                        ) : null}
                                    </Form.Label>
                                    {props.datosTitularAgencia ? (
                                        <>
                                            {props.titulo ===
                                            'Asignar Ubicacion de' ? (
                                                <Form.Control
                                                    required
                                                    type="titular"
                                                    placeholder={
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .apellido +
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .nombre +
                                                        '-' +
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .cuit
                                                    }
                                                    value={
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .apellido +
                                                        ' ' +
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .nombre +
                                                        '-' +
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .cuit
                                                    }
                                                    readOnly={false}
                                                    onChange={
                                                        handleSelectTitular
                                                    }
                                                />
                                            ) : props.titulo === 'Ver' ||
                                              props.titulo === 'Editar' ? (
                                                <Form.Control
                                                    required
                                                    type="titular"
                                                    placeholder={
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .apellido +
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .nombre
                                                    }
                                                    value={
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .apellido +
                                                        ' ' +
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .nombre +
                                                        '-' +
                                                        props
                                                            .datosTitularAgencia
                                                            .persona_detalle
                                                            .cuit
                                                    }
                                                    readOnly={
                                                        props.titulo ===
                                                            'Ver' ||
                                                        props.titulo ===
                                                            'Asignar Ubicacion de' ||
                                                        props.titulo ===
                                                            'Editar'
                                                            ? true
                                                            : false
                                                    }
                                                    style={{
                                                        marginTop: '-16px',
                                                    }}
                                                    onChange={
                                                        handleSelectTitular
                                                    }
                                                />
                                            ) : null}
                                        </>
                                    ) : (
                                        <Typeahead
                                            id="autocomplete"
                                            options={titulares}
                                            labelKey="label"
                                            onChange={handleSelectTitular}
                                            Selected={selectedOption}
                                            placeholder="Escribe aquí para autocompletar"
                                            required
                                        />
                                    )}
                                </Form.Group>
                            </Row>
                            {props.titulo === 'Nuevo' ? (
                                <Form.Group
                                    as={Col}
                                    md="6"
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {/* <Row  > */}
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button
                                            className="botonCPA"
                                            style={{
                                                // height: "100%",
                                                marginTop: 22,
                                                // float: "right",
                                                //   marginLeft: 110,
                                            }}
                                            onClick={() => {
                                                setAñadirTitularModal(true);
                                                setTituloModalVerTitular(
                                                    'Nuevo'
                                                );
                                            }}
                                        >
                                            Añadir Titular
                                        </button>
                                    </div>
                                    {/* </Row> */}
                                </Form.Group>
                            ) : null}
                            {props.titulo === 'Editar' ? (
                                <Row>
                                    <div className="d-flex justify-content-end">
                                        <button
                                            className="btn botonCPA"
                                            style={{ marginTop: 5 }}
                                            type="submit"
                                            form="FormOrigen1"
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </Row>
                            ) : null}
                        </Card.Body>
                    </Card>
                    <Card className="m-3">
                        <Card.Header>
                            <Card.Title>Domicilio Comercial</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="5">
                                    <Form.Label>
                                        Dirección {requiredSymbol}
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Domicilio comercial de la agencia"
                                        value={domicilioComercial}
                                        readOnly={
                                            props.titulo === 'Nuevo' ||
                                            props.titulo ===
                                                'Asignar Ubicacion de'
                                                ? false
                                                : true
                                        }
                                        onChange={(event) => {
                                            if (
                                                event.target.value.trim() !== ''
                                            ) {
                                                setDomicilioComercial(
                                                    event.target.value
                                                );
                                            } else {
                                                setDomicilioComercial('');
                                            }
                                        }}
                                    />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="1">
                  <Form.Label>Numero{requiredSymbol}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nro  "
                    value={numero}
                    readOnly={
                      props.titulo === "Nuevo" ||
                        props.titulo === "Asignar Ubicacion de"
                        ? false
                        : true
                    }
                    onChange={(event) => setNumero(event.target.value)}
                  />
                </Form.Group> */}
                                <Form.Group as={Col} md="3">
                                    <Form.Label>
                                        Zona {requiredSymbol}
                                    </Form.Label>
                                    {props.titulo === 'Nuevo' ||
                                    props.titulo === 'Asignar Ubicacion de' ? (
                                        <Form.Select
                                            name="zona"
                                            value={zona}
                                            onChange={(event) => {
                                                setZona(event.target.value);
                                            }}
                                            readOnly={
                                                props.titulo === 'Ver' ||
                                                props.titulo ===
                                                    'Asignar Titular de'
                                                    ? true
                                                    : false
                                            }
                                            required
                                        >
                                            {tablaZona.map((zona) => (
                                                <option
                                                    key={zona.id}
                                                    value={zona.id}
                                                >
                                                    {zona.descripcion}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    ) : (
                                        <Form.Control
                                            type="text"
                                            placeholder="-"
                                            value={zona}
                                            readOnly={true}
                                            onChange={(event) =>
                                                setComentario(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Coordenadas </Form.Label>
                                    <Form.Control
                                        // required
                                        type="text"
                                        value={coordenadas}
                                        readOnly={
                                            props.titulo === 'Nuevo' ||
                                            props.titulo ===
                                                'Asignar Ubicacion de'
                                                ? false
                                                : true
                                        }
                                        placeholder="Ingrese las coordenadas de su ubicacion"
                                        onChange={(event) => {
                                            if (
                                                event.target.value.trim() !== ''
                                            ) {
                                                setCoordenadas(
                                                    event.target.value
                                                );
                                            } else {
                                                setCoordenadas('');
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="8">
                                    <Form.Label>Municipio/Comuna</Form.Label>
                                    {props.titulo === 'Nuevo' ||
                                    props.titulo === 'Asignar Ubicacion de' ? (
                                        <Select
                                            value={localidad}
                                            onChange={(option) => {
                                                setLocalidad(option);
                                            }}
                                            options={tablaLocalidad}
                                            placeholder="Seleccionar Municipio/Comuna"
                                            required
                                        />
                                    ) : (
                                        <Form.Control
                                            type="text"
                                            placeholder="-"
                                            value={localidad}
                                            readOnly={true}
                                            onChange={(event) =>
                                                setComentario(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>
                                        Sucursal de Rendicion {requiredSymbol}
                                    </Form.Label>
                                    {props.titulo === 'Nuevo' ||
                                    props.titulo === 'Asignar Ubicacion de' ? (
                                        <Form.Select
                                            name="tipoSucursal"
                                            onChange={(event) =>
                                                setTipoSucursal(
                                                    event.target.value
                                                )
                                            }
                                            required
                                            multiple={false}
                                        >
                                            {tablaTipoSucursal.map(
                                                (jurisdiccion) => (
                                                    <option
                                                        key={jurisdiccion.id}
                                                        value={jurisdiccion.id}
                                                    >
                                                        {jurisdiccion.nombre}
                                                    </option>
                                                )
                                            )}
                                        </Form.Select>
                                    ) : (
                                        <Form.Control
                                            type="text"
                                            placeholder="-"
                                            value={tipoSucursal}
                                            readOnly={true}
                                            onChange={(event) =>
                                                setComentario(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    )}
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                    {props.titulo === 'Nuevo' ? (
                        <Card className="m-3">
                            <Card.Header>
                                <Card.Title>
                                    Resolucion: {props.tipoResolucion}
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Expediente {requiredSymbol}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ingrese el expediente"
                                            value={expediente}
                                            readOnly={
                                                props.titulo === 'Nuevo'
                                                    ? false
                                                    : true
                                            }
                                            onChange={(event) => {
                                                if (
                                                    event.target.value.trim() !==
                                                    ''
                                                ) {
                                                    setExpediente(
                                                        event.target.value
                                                    );
                                                } else {
                                                    setExpediente('');
                                                }
                                            }}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Resolucion {requiredSymbol}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ingrese la resolucion"
                                            value={resolucion}
                                            readOnly={
                                                props.titulo === 'Nuevo'
                                                    ? false
                                                    : true
                                            }
                                            onChange={(event) => {
                                                if (
                                                    event.target.value.trim() !==
                                                    ''
                                                ) {
                                                    setResolucion(
                                                        event.target.value
                                                    );
                                                } else {
                                                    setResolucion('');
                                                }
                                            }}
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Comentario</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ingrese un comentario"
                                            value={comentario}
                                            readOnly={
                                                props.titulo === 'Nuevo'
                                                    ? false
                                                    : true
                                            }
                                            onChange={(event) => {
                                                if (
                                                    event.target.value.trim() !==
                                                    ''
                                                ) {
                                                    setComentario(
                                                        event.target.value
                                                    );
                                                } else {
                                                    setComentario('');
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Fecha Resolucion {requiredSymbol}
                                        </Form.Label>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/YYYY"
                                            inputProps={{
                                                placeholder:
                                                    fechaResolucion.fechaMuestra,
                                                readOnly: true,
                                            }}
                                            value={fechaResolucion.fechaMuestra}
                                            required
                                            onChange={(momentDate) => {
                                                const fechaMuestra =
                                                    momentDate.format(
                                                        'DD/MM/YYYY'
                                                    );
                                                const fechaComparar =
                                                    momentDate.format(
                                                        'YYYY-MM-DD'
                                                    );
                                                setFechaResolucion({
                                                    fechaMuestra: fechaMuestra,
                                                    fechaComparar:
                                                        fechaComparar,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>
                                            Fecha de Notificacion{' '}
                                            {requiredSymbol}
                                        </Form.Label>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/YYYY"
                                            inputProps={{
                                                placeholder: fechaNotificacion,
                                                readOnly: true,
                                            }}
                                            value={fechaNotificacion}
                                            required
                                            onChange={(momentDate) => {
                                                setFechaNotificacion(
                                                    momentDate.format(
                                                        'DD/MM/YYYY'
                                                    )
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group controlId="formResolucionPDF">
                                        <Form.Label>
                                            Adjunte el archivo de resolución:
                                        </Form.Label>
                                        <Form.Control
                                            type="file"
                                            accept=".pdf"
                                            size="md"
                                            onChange={handleArchivoPDFChange}
                                        />
                                    </Form.Group>
                                </Row>
                            </Card.Body>
                        </Card>
                    ) : null}

                    {/* <>
            {props.titulo === "Ver" || props.titulo === "Editar" ? (
              <Card className="m-3">
                <Card.Header>
                  <Card.Title>Colaboradores</Card.Title>
                </Card.Header>
                <Form.Group
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    onClick={() => {
                      setTituloModalColaborador("Agregar Colaborador");
                      handleOpenModalColaborador("");
                    }}
                  >
                    <AddBox />
                  </IconButton>
                </Form.Group>

                <Card.Body>
                  <MaterialReactTable
                    className="w-100"
                    columns={columnsColaboradores}
                    data={dataColaboradores}
                    initialState={{
                      columnVisibility: { id: false },
                      showColumnFilters: true,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableSorting={false}
                    displayColumnDefOptions={{
                      "mrt-row-actions": { size: 10 },
                    }} //change width of actions column to 300px
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                      <div>
                        {props.titulo === "Editar" ? (
                          <IconButton
                            onClick={() => {
                              eliminarColaborador(row.original);
                            }}
                          >
                            <FaEraser />
                          </IconButton>
                        ) : null}
                      </div>
                    )}
                    manualPagination
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    localization={MRT_Localization_ES}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                    manualFiltering //turn off client-side filtering
                    onColumnFiltersChange={(value) => {
                      setColumnFilters(value);
                    }} //hoist internal columnFilters state to your state
                    state={{
                      columnFilters,
                      pagination,
                    }}
                  />
                  {/* // <Row className="mb-3">
                    //   <Form.Label as={Col} md="3">
                    //     Posee Colaboradores?
                    //   </Form.Label>
                    //   <Form.Group as={Col} md="1">
                    //     <Form.Check
                    //       type="radio"
                    //       name="opcion"
                    //       id="si"
                    //       value="si"
                    //       label="Si"
                    //       checked={opcionSeleccionada === "si"}
                    //       onChange={handleChange}
                    //     />
                    //   </Form.Group>
                    //   <Form.Group as={Col} md="1">
                    //     <Form.Check
                    //       type="radio"
                    //       name="opcion"
                    //       id="no"
                    //       value="no"
                    //       label="No"
                    //       checked={opcionSeleccionada === "no"}
                    //       onChange={handleChange}
                    //     />
                    //   </Form.Group>
                    // </Row>
                  )}
                  {/* {opcionSeleccionada === "si" && (
                    <>
                      <Row>
                        <Form.Group as={Col} md="4">
                          <Form.Label>Apellido:</Form.Label>
                          <Form.Control
                            type="text"
                            name="apellidoColaborador"
                            value={apellidoColaborador}
                            readOnly={props.titulo === "Ver" ? true : false}
                            placeholder="Ingrese el Apellido del colaborador"
                            onChange={(event) =>
                              setApellidoColaborador(event.target.value)
                            }
                            required
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                          <Form.Label>Nombre:</Form.Label>
                          <Form.Control
                            type="text"
                            name="nombreColaborador"
                            placeholder="Ingrese el nombre del colaborador"
                            value={nombreColaborador}
                            readOnly={props.titulo === "Ver" ? true : false}
                            onChange={(event) =>
                              setNombreColaborador(event.target.value)
                            }
                            required
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                          <Form.Label>Documento:</Form.Label>
                          <Form.Control
                            type="text"
                            name="documento"
                            value={documentoColaborador}
                            readOnly={props.titulo === "Ver" ? true : false}
                            placeholder="Ingrese el documento del colaborador"
                            onChange={(event) =>
                              setDocumentoColaborador(event.target.value)
                            }
                            onKeyPress={(event) => {
                              const keyCode = event.keyCode || event.which;
                              const keyValue = String.fromCharCode(keyCode);

                              // Permitir solo números (keyCode 48-57 son los códigos para los números 0 al 9)
                              if (!/^[0-9]+$/.test(keyValue)) {
                                event.preventDefault();
                              }
                            }}
                            required
                          />
                        </Form.Group>
                      </Row>
                      <Row className="m-3">
                        <Form.Label>
                          Adjunte el archivo de resolución de Colaboradores:
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept=".pdf"
                          size="md"
                          onChange={handleArchivoColaboradorPDFChange}
                        />
                      </Row>
                    </>
                  )} */}
                    {/* </Card.Body> */}
                    {/* </Card> */}
                    {/* ) : null} */}
                    {/* </> */}
                    {props.titulo === 'Editar' || props.titulo === 'Ver' ? (
                        <Card className="m-3">
                            <Card.Header>
                                <Card.Title>Historial Domicilios</Card.Title>
                            </Card.Header>
                            <MaterialReactTable
                                className="w-100"
                                columns={columnsDomicilio}
                                data={dataDomicilio}
                                initialState={{
                                    columnVisibility: { id: false },
                                    showColumnFilters: true,
                                }} //hide firstName column by default
                                editingMode="modal" //default
                                enableRowSelection={false} //enable some features
                                enableColumnOrdering={false}
                                enableHiding={false}
                                enableColumnActions={false}
                                enableSorting={false}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': { size: 10 },
                                }} //change width of actions column to 300px
                                positionActionsColumn="last"
                                manualPagination
                                muiTablePaginationProps={{
                                    rowsPerPageOptions: [10],
                                }}
                                enablePagination={true}
                                localization={MRT_Localization_ES}
                                rowCount={count}
                                onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                                enableGlobalFilter={false} //turn off a feature
                                manualFiltering //turn off client-side filtering
                                onColumnFiltersChange={(value) => {
                                    setColumnFilters(value);
                                }} //hoist internal columnFilters state to your state
                                state={{
                                    columnFilters,
                                    pagination,
                                }}
                            />
                        </Card>
                    ) : null}
                    {props.titulo === 'Editar' || props.titulo === 'Ver' ? (
                        <Card className="m-3">
                            <Card.Header>
                                <Card.Title>Historial Resoluciones</Card.Title>
                            </Card.Header>
                            {props.titulo === 'Ver' ? null : (
                                <Form.Group
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            handleOpenModalEditar('');
                                            setTituloModal('Subir Resolucion');
                                        }}
                                    >
                                        <AddBox />
                                    </IconButton>
                                </Form.Group>
                            )}
                            <MaterialReactTable
                                className="w-100"
                                columns={columns}
                                data={dataResolucion}
                                initialState={{
                                    columnVisibility: {
                                        id: false,
                                        archivo: false,
                                    },
                                    showColumnFilters: true,
                                }} //hide firstName column by default
                                editingMode="modal" //default
                                enableRowSelection={false} //enable some features
                                enableColumnOrdering={false}
                                enableHiding={false}
                                enableColumnActions={false}
                                enableSorting={false}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': { size: 10 },
                                }} //change width of actions column to 300px
                                enableRowActions
                                positionActionsColumn="last"
                                // renderRo
                                renderRowActions={({ row }) => (
                                    <div>
                                        <a
                                            href={row.original.archivo}
                                            target="blank"
                                        >
                                            <IconButton>
                                                <PictureAsPdf />
                                            </IconButton>
                                        </a>
                                        <IconButton
                                            onClick={() => {
                                                handleOpenModalEditar(
                                                    row.original
                                                );
                                                setTituloModal(
                                                    'Ver Resolucion'
                                                );
                                            }}
                                        >
                                            <Visibility />
                                        </IconButton>
                                    </div>
                                )}
                                manualPagination
                                muiTablePaginationProps={{
                                    rowsPerPageOptions: [10],
                                }}
                                enablePagination={true}
                                localization={MRT_Localization_ES}
                                rowCount={count}
                                onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                                enableGlobalFilter={false} //turn off a feature
                                manualFiltering //turn off client-side filtering
                                onColumnFiltersChange={(value) => {
                                    setColumnFilters(value);
                                }} //hoist internal columnFilters state to your state
                                state={{
                                    columnFilters,
                                    pagination,
                                }}
                            />
                        </Card>
                    ) : null}
                </Modal.Body>
                <ModalEditar
                    onClose={handleCloseModalEditar}
                    show={showModalEditar}
                    titulo={tituloModal}
                    datosAgencia={props.datosAgencia}
                    datosResolucion={datosResolucionView}
                    datosArchivo={archivoView}
                    recargarTablaR={cargarResoluciones}
                    recargarTablaD={cargarDomicilios}
                    opcionesTitulares={props.opcionesTitulares}
                    setTitular={setTitularAgencia}
                    datosLocalidad={props.datosLocalidad}
                    datosDomicilio={props.datosDomicilio}
                    nombreZona={nombreZona}
                />
                <Modal.Footer>
                    {props.titulo === 'Nuevo' ? (
                        <Row>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn botonCPA"
                                    // style={{ marginRight: 10}}
                                    type="submit"
                                    form="FormOrigen1"
                                >
                                    Guardar
                                </button>
                            </div>
                        </Row>
                    ) : null}
                    <button className="btn botonCPA m-3" onClick={handleClose}>
                        Volver
                    </button>
                </Modal.Footer>
            </Form>
            <Modal
                show={showModalColaborador}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onClick={handleCloseModalColaborador}>
                    <Modal.Title>{tituloModalColaborador}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitColaborador} id="FormColaborador">
                    <Modal.Body>
                        <Card className="mb-3">
                            <Card.Body>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>
                                            Nombre {requiredSymbol}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="nombreColaborador"
                                            placeholder="Ingrese el nombre del colaborador"
                                            value={nombreColaboradorModal}
                                            onChange={(event) =>
                                                setNombreColaboradorModal(
                                                    event.target.value
                                                )
                                            }
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>
                                            Apellido {requiredSymbol}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="apellidoColaborador"
                                            placeholder="Ingrese el apellido del colaborador"
                                            value={apellidoColaboradorModal}
                                            onChange={(event) =>
                                                setApellidoColaboradorModal(
                                                    event.target.value
                                                )
                                            }
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>
                                            Documento {requiredSymbol}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="documentoColaborador"
                                            placeholder="Ingrese el documento del colaborador"
                                            value={documentoColaboradorModal}
                                            onChange={(event) =>
                                                setDocumentoColaboradorModal(
                                                    event.target.value
                                                )
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group controlId="formResolucionPDF">
                                        <Form.Label>
                                            Adjunte el archivo:
                                        </Form.Label>
                                        <Form.Control
                                            type="file"
                                            accept=".pdf"
                                            size="md"
                                            onChange={handleArchivoPDFChange}
                                        />
                                    </Form.Group>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn botonCPA m-3"
                            onClick={handleCloseModalColaborador}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn botonCPA m-3"
                            type="submit"
                            form="FormColaborador"
                        >
                            Guardar
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalTitular
                onClose={() => {
                    setAñadirTitularModal(false);
                }}
                show={añadirTitularModal}
                titulo={tituloModalVerTitular}
                nuevoTitular={(data) => handleNuevoTitular(data)}
                datosTitular={tituloModal !== 'Nuevo' ? titularView : null}
                datosDomicilio={tituloModal !== 'Nuevo' ? domicilioView : null}
                datosCondicion={tituloModal !== 'Nuevo' ? condicionView : null}
            />
        </Modal>
    );
}
