import { Button } from "react-bootstrap";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    pdf
} from "@react-pdf/renderer";
import logo from "../../../../../img/logo.png";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import AuthenticationHelper from "../../../../../shared/helpers/authenticationHelper.js";
import dayjs from "dayjs";
const styles = StyleSheet.create({
    page: {
        padding: 49,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10,
    },
    logo: {
        width: 80,
        height: 47,
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginLeft: "auto",
    },
    subHeaderTitle: {
        fontSize: 11,
        marginLeft: "auto",
        marginBot: 20,
    },
    title: {
        fontSize: 11,
        fontWeight: "bold",
        textAlign: "left",
    },
    subtitle: {
        fontSize: 16,
        marginBottom: 10,
    },
    table: {
        marginTop: 10,
        marginBot: 50,
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: "row",
        flexWrap: "wrap",
        fontSize: 11,
        marginBot: 10,
    },
    tableColHeader: {
        flex: 1,
        width: "50%",
        borderStyle: "solid",
        backgroundColor: "#bfbfbf",
        borderColor: "#bfbfbf",
        borderBottomWidth: 1,
        borderRightWidth: 1,
        padding: 5,
        fontWeight: "bold",
        fontSize: 12,
    },
    tableCol: {
        flex: 1,
        width: "50%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomWidth: 1,
        padding: 5,
        borderRightWidth: 1, // borde derecho de las columnas
    },
    sectionSeparator: {
        height: 20, // Altura de la separación entre secciones
    },
    separator: {
        borderBottomWidth: 1,
        borderBottomColor: "black",
        marginTop: 5,
        marginBottom: 5,
    },
    separatorDotted: {
        borderBottomWidth: 1,
        borderBottomColor: "black",
        marginTop: 115,
        marginBottom: 20,
        borderStyle: 'dashed',
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    footer: {
        float: "right",
        fontSize: 8,
        textAlign: "center",
        color: "black",
    },
    signatureContainer: {
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
    },
    dottedLine: {
        flex: 1,
        width: '50%',
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'black',
    },
    signatureText: {
        marginLeft: 10,
        fontWeight: 'bold',
        fontSize: 11,
    },
});


const ReciboRendicionesLinea = async (props) => {
    return new Promise((resolve) => {
        const components = props.datos;
        const tables = (dato) => {
            var detalle =
                parseFloat(dato.totalARendir.split(' ')[1].replace('.', '').replace(',', '.')) > 0 ?
                    "Recibo de Cobro N°: " +
                    dato.numeroRendicion +
                    " (Rendicion Liquidada el " +
                    dato.fechaRendicion +
                    ")"
                    :
                    "Orden de Pago N°: " +
                    dato.numeroRendicion +
                    " (Rendicion Liquidada el " +
                    dato.fechaRendicion +
                    ")";
            var importe = dato.totalARendir;
            const component = { detalle: detalle, importe: importe };
            const attributesList = Object.keys(component)
            const uniqueAttributesList = [attributesList];
            return uniqueAttributesList.map((attributes) => {
                const filteredComponents = [component];
                if (filteredComponents.length === 0) {
                    return null;
                }
                const tableHeaders = attributes;
                return (
                    <div key={"recibos"}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                {tableHeaders.map((header, index) => (
                                    <View style={styles.tableColHeader} key={index.toString()}>
                                        <Text>
                                            {header.charAt(0).toUpperCase() +
                                                header.slice(1).replace("_", " ")}
                                        </Text>
                                    </View>
                                ))}
                            </View>

                            {filteredComponents.map((object, index) => (
                                <View key={index.toString()} style={styles.tableRow}>
                                    {tableHeaders.map((column, index) => (
                                        <View style={styles.tableCol} key={index.toString()}>
                                            <Text>{object[column]}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </div>
                );
            });


        };
        const doc = (
            <Document>
                <Page style={styles.page} key={components.nueva_agencia}>
                    <View>
                        <View style={styles.header}>
                            <Image src={logo} style={styles.logo} />
                            <Text style={styles.subHeaderTitle}>
                                Fecha:{dayjs().format("DD/MM/YYYY")} {dayjs().format("HH:mm")}
                                {"\n"}
                                Id Viñeta:{components.id}
                                {"\n"}
                                Usuario: {AuthenticationHelper.getUser()}
                                {"\n"}
                            </Text>
                        </View>
                        <View style={styles.separator} />
                    </View>
                    <View>
                        <Text style={styles.title}>
                            Agencia Oficial N°: {components.nueva_agencia}
                            {"\n"}
                            Titular: {components.titular}
                            {"\n"}
                            Domicilio: {components.domicilio}
                            {"\n"}
                            Telefono: {components.telefono}
                        </Text>
                        {tables(components)}
                    </View>
                    <Text style={styles.subHeaderTitle}>Copia/Agencia</Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={styles.signatureContainer}>
                            <View style={styles.dottedLine} />
                            <Text style={styles.signatureText}>Firma</Text>
                        </View>
                        <View style={styles.signatureContainer}>
                            <View style={styles.dottedLine} />
                            <Text style={styles.signatureText}>Aclaracion</Text>
                        </View>
                    </div>
                    <View style={styles.separatorDotted} />
                    <View style={styles.header}>
                        <Image src={logo} style={styles.logo} />
                        <Text style={styles.subHeaderTitle}>
                            Fecha:{dayjs().format("DD/MM/YYYY")} {dayjs().format("HH:mm")}
                            {"\n"}
                            Id Viñeta:{components.id}
                            {"\n"}
                            Usuario: {AuthenticationHelper.getUser()}
                            {"\n"}
                        </Text>
                    </View>
                    <View style={styles.separator} />
                    <View>
                        <Text style={styles.title}>
                            Agencia Oficial N°: {components.nueva_agencia}
                            {"\n"}
                            Titular: {components.titular}
                            {"\n"}
                            Domicilio: {components.domicilio}
                            {"\n"}
                            Telefono: {components.telefono}
                        </Text>
                        {tables(components)}
                    </View>
                    <Text style={styles.subHeaderTitle}>Original/CPA</Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={styles.signatureContainer}>
                            <View style={styles.dottedLine} />
                            <Text style={styles.signatureText}>Firma</Text>
                        </View>
                        <View style={styles.signatureContainer}>
                            <View style={styles.dottedLine} />
                            <Text style={styles.signatureText}>Aclaracion</Text>
                        </View>
                    </div>
                </Page>

            </Document>
        );
        const pdfBlob = pdf(doc).toBlob();
        resolve(pdfBlob);
    })
};

export default ReciboRendicionesLinea;
