import React, { useMemo, useState } from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import { darken, IconButton } from "@mui/material";
import moment from "moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import Swal from "sweetalert2";
import { ModalRazonBajaDeduccion } from "./ModalRazonBajaDeduccion";
import { FaEdit } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";

const VerDeducciones = (props) => {

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const URL_DEDUCCIONES = window.API_ROUTES.DEDUCCION;
    const [columnFilters, setColumnFilters] = useState([]);

    const [showModalRazonBajaDeduccion, setShowModalRazonBajaDeduccion] =
        useState(false);
    const [showModalModificarImporte, setShowModalModificarImporte] =
        useState(false);

    const [deduccionBajaModal, setdeduccionBajaModal] =
        useState('');


    const [tituloModal, setTituloModal] = useState('');
    const [idDeduccionModificar, setIdDeduccionModificar] = useState('');
    const handleCloseModalRazonBajaDeduccion = () => {
        setShowModalRazonBajaDeduccion(false);
    };

    const handleOpenModalRazonBajaDeduccion = () => {
        setTituloModal('Nuevo');
        setShowModalRazonBajaDeduccion(true);
    };

    const [nuevoImporte, setNuevoImporte] = useState('');

    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50
        },
        {
            header: "estado",
            accessorKey: "estado",
            size: 50
        },
        {
            header: "Concepto",
            accessorKey: "concepto",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Importe",
            accessorKey: "importe",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Nro Cuota Plan de Pago",
            accessorKey: "nro_cuota",
            size: 50,
            enableColumnFilter: false,
        },

    ]);

    const handleCloseModal = (event) => {
        props.close(event);
    };

    const darBaja = async (deduccion) => {
        setdeduccionBajaModal(deduccion);
        setShowModalRazonBajaDeduccion(true);
        return;
    };


    const darAlta = async (deduccion, event) => {
        Swal.fire({
            title: "¿Estás seguro de dar de alta la deduccion?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#008185",
            cancelButtonColor: "#EC1B23",
            confirmButtonText: "Si",
            cancelButtonText: "No",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const deduccionObjeto = await ConsultasAPI.ObtenerObjeto(URL_DEDUCCIONES, deduccion.id);
                    const periodo = moment(deduccionObjeto.data.periodo, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    const objeto = {
                        titular_agencia: deduccionObjeto.data.titular_agencia,
                        tipo_deduccion: deduccionObjeto.data.tipo_deduccion,
                        periodo: periodo,
                        importe: deduccionObjeto.data.importe,
                        orden_pago: deduccionObjeto.data.orden_pago,
                        cantidad_cuotas: deduccionObjeto.data.cantidad_cuotas,
                        nro_cuota: deduccionObjeto.data.nro_cuota,
                        estado: true,
                        motivo: null
                    };
                    const response = await ConsultasAPI.ModificarObjeto(URL_DEDUCCIONES, deduccion.id, objeto);
                    if (response.status === 200) {
                        Swal.fire({
                            title: "Se realizo con exito la operación",
                            icon: "success"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                handleCloseModal(event);
                            }
                        });
                    } else {
                        Swal.fire({
                            title: "Ocurrio un error y No se realizo la operacion",
                            icon: "error"
                        });
                    }
                } catch (error) {
                    console.info(error);
                    Swal.fire({
                        title: "Ocurrio un error y No se realizo la operacion",
                        icon: "error"
                    });
                }
            }
        });
    };
    const modificarDeduccion = async (event) => {
        event.preventDefault()
        Swal.fire({
            title: "¿Estás seguro de dar de alta la deduccion?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#008185",
            cancelButtonColor: "#EC1B23",
            confirmButtonText: "Si",
            cancelButtonText: "No",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const deduccionObjeto = await ConsultasAPI.ObtenerObjeto(URL_DEDUCCIONES, idDeduccionModificar);
                    let periodo = moment(deduccionObjeto.data.periodo, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    let objeto = {
                        titular_agencia: deduccionObjeto.data.titular_agencia,
                        tipo_deduccion: deduccionObjeto.data.tipo_deduccion,
                        periodo: periodo,
                        importe: nuevoImporte,
                        orden_pago: deduccionObjeto.data.orden_pago,
                        cantidad_cuotas: deduccionObjeto.data.cantidad_cuotas,
                        nro_cuota: deduccionObjeto.data.nro_cuota,
                    };
                    const response = await ConsultasAPI.ModificarObjeto(URL_DEDUCCIONES, idDeduccionModificar, objeto);
                    if (response.status === 200) {
                        Swal.fire({
                            title: "Se realizo con exito la operación",
                            icon: "success"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setShowModalModificarImporte(false);
                                setIdDeduccionModificar('');
                                setNuevoImporte('');
                                handleCloseModal(event);
                            }
                        });
                    } else {
                        Swal.fire({
                            title: "Ocurrio un error y No se realizo la operacion",
                            icon: "error"
                        });
                    }
                } catch (error) {
                    console.info(error);
                    Swal.fire({
                        title: "Ocurrio un error y No se realizo la operacion",
                        icon: "error"
                    });
                }
            }
        });
    };

    return (
        <>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>Detalle Deducciones</Modal.Title>
                </Modal.Header>
                {/* <Form onSubmit={} id="formResolucion" ref={}> */}
                <Modal.Body >
                    <Row className="mb-3" >
                        <Form.Group as={Col} md="6">
                            <Form.Label>Agencia: {props.dataDeduccion ? props.dataDeduccion.titular_detalle.agencia_detalle.numero_agencia : ""}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Subagencia: {props.dataDeduccion ? props.dataDeduccion.titular_detalle.agencia_detalle.numero_subagencia : ""}</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6">
                            <Form.Label>Titular: {props.dataDeduccion ? props.dataDeduccion.titular_detalle.titular_detalle.persona_detalle.nombre + " " + props.dataDeduccion.titular_detalle.titular_detalle.persona_detalle.apellido : ""}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>DNI: {props.dataDeduccion ? props.dataDeduccion.titular_detalle.titular_detalle.persona_detalle.documento : ""}</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6">
                            <Form.Label>CUIT: {props.dataDeduccion ? props.dataDeduccion.titular_detalle.titular_detalle.persona_detalle.cuit : ""}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Periodo a liquidar: {props.dataDeduccion ? moment(props.dataDeduccion.periodo, "DD/MM/YYYY").format("MM/YYYY") : ""}</Form.Label>
                        </Form.Group>
                    </Row>
                    <MaterialReactTable
                        className="w-100"
                        columns={columns}
                        data={props.dataDeducciones}
                        muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: "0",
                                border: "1px dashed #e0e0e0",
                            },
                        }}
                        muiTableBodyProps={{
                            sx: (theme) => ({
                                "& tr:nth-of-type(odd)": {
                                    backgroundColor: darken(theme.palette.background.default, 0.1),
                                },
                                fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                            }),
                        }}
                        initialState={{
                            columnVisibility: { id: false, estado: false },
                            showColumnFilters: true,
                        }} //hide firstName column by default
                        editingMode="modal" //default
                        enableRowSelection={false} //enable some features
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableColumnActions={false}
                        enableSorting={false}
                        displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                        enableRowActions
                        positionActionsColumn="last"
                        renderRowActions={({ row }) => (
                            <div className="d-flex">
                                {row.original.pagada ?
                                    null
                                    :
                                    <>
                                        {
                                            row.original.estado ?
                                                <IconButton
                                                    onClick={(event) => {
                                                        darBaja(row.original, event);
                                                        handleOpenModalRazonBajaDeduccion('Nuevo');
                                                    }}
                                                >
                                                    <DoNotDisturbOnIcon />
                                                </IconButton>
                                                :
                                                <IconButton
                                                    onClick={(event) => {
                                                        darAlta(row.original, event);
                                                    }}
                                                >
                                                    <CheckCircleIcon />
                                                </IconButton>
                                        }
                                        {
                                            (row.original.concepto === 'Automatizacion' || row.original.concepto === 'Ticket') && props.dataDeduccion && props.dataDeduccion.periodo.split('/')[2] === '2023' ?
                                                <IconButton
                                                    onClick={() => {
                                                        setShowModalModificarImporte(true);
                                                        setIdDeduccionModificar(row.original.id);
                                                    }}
                                                >
                                                    <AiFillEdit />
                                                </IconButton>
                                                :
                                                null
                                        }
                                    </>
                                }
                            </div>
                        )}
                        manualPagination
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10],
                        }}
                        enablePagination={true}
                        localization={MRT_Localization_ES}
                        //rowCount={count}
                        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                        enableGlobalFilter={false} //turn off a feature
                        manualFiltering //turn off client-side filtering
                        onColumnFiltersChange={(value) => {
                            setColumnFilters(value);
                        }} //hoist internal columnFilters state to your state
                        state={{
                            columnFilters,
                            pagination,
                        }}
                    />
                </Modal.Body>
                {/* </Form> */}
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="botonCPA" onClick={handleCloseModal}>Volver</Button>
                </Modal.Footer>

                <ModalRazonBajaDeduccion
                    onClose={handleCloseModalRazonBajaDeduccion}
                    show={showModalRazonBajaDeduccion}
                    titulo={tituloModal}
                    deduccion={deduccionBajaModal}
                    close={handleCloseModal}
                />

            </Modal>
            <Modal
                show={showModalModificarImporte}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onClick={() => setShowModalModificarImporte(false)}>
                    <Modal.Title>Modificar Importe</Modal.Title>
                </Modal.Header>
                <Form onSubmit={modificarDeduccion}>
                    <Modal.Body >
                        <Row className="mb-3" >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Importe: </Form.Label>
                                <Form.Control
                                    value={nuevoImporte}
                                    onChange={(event) => { setNuevoImporte(event.target.value); }}
                                />
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button className="botonCPA" onClick={handleCloseModal}>Volver</Button>
                        <Button className="botonCPA" type="submit">Guardar</Button>
                    </Modal.Footer>
                </Form>

                <ModalRazonBajaDeduccion
                    onClose={handleCloseModalRazonBajaDeduccion}
                    show={showModalRazonBajaDeduccion}
                    titulo={tituloModal}
                    deduccion={deduccionBajaModal}
                    close={handleCloseModal}
                />

            </Modal>
        </>
    );
};

export default VerDeducciones;
