import React from 'react';

const Footer = () => {
    return (
        <footer className="footer py-3">
            <h3 className="letraCaja">&copy; Desarrollado por CSI - CPA 2024 </h3>
        </footer>
    );
};

export default Footer;
