import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import React, { useState } from "react";
// Rutas
import Footer from "./components/common/Footer";
import Menu from "./components/common/Menu";
import Error from "./components/views/Error";
import Login from "./components/views/login/Login";
import PasswordResetForm from "./components/views/login/PasswordResetForm";
import Newpassword from "./components/views/login/NewPassword";
import CambiarClave from "./components/views/login/CambiarClave";
import Principal from "./components/views/Principal";
import Extractos from "./components/views/extracto/Extractos";
import SubirExtracto from "./components/views/extracto/SubirExtracto";
import Titulares from "./components/views/titulares/Titulares";
import Agencias from "./components/views/agencias/Agencias";
import AsignacionAgencia from "./components/views/agencias/AsignacionAgencia";
import BuscarUsuario from "./components/views/usuarios/Usuarios";
import SorteosExceptuados from "./components/views/extracto/SorteosExceptuados";
// import TipoSorteo from "./components/views/extracto/TipoSorteo";

import MenuLiquidacion from "./components/views/liquidacion/MenuLiquidacion";
import Sorteos from "./components/views/liquidacion/sorteos/Sorteos";
import CargarSorteo from "./components/views/liquidacion/sorteos/CargarSorteo";
import Rendiciones from "./components/views/liquidacion/rendiciones/Rendiciones";
import CargarRendiciones from "./components/views/liquidacion/rendiciones/CargarRendiciones";
import GenerarRecibosRendiciones from "./components/views/liquidacion/rendiciones/GenerarRecibosRendiciones";
// import RegistrarRendiciones from "./components/views/liquidacion/rendiciones/RegistrarRendiciones";
import MovimientosDeOperaciones from "./components/views/liquidacion/rendiciones/PDF/MovimientosDeOperaciones";
import Comisiones from "./components/views/liquidacion/comisiones/Comisiones";
import EnviarComisiones from "./components/views/liquidacion/comisiones/EnviarComisiones";
import Deducciones from "./components/views/deducciones/Deducciones";
import NuevoArchivo from "./components/views/deducciones/NuevoArchivo";
import OrdenesDePago from "./components/views/deducciones/OrdenesDePago";
import AuthHelper from "./shared/helpers/authenticationHelper";
import { ProtectedRoute } from "./components/common/ProtectedRoute";
import icon from "./img/favicon.ico";
import RendicionesTuqui from "./components/views/liquidacion/rendiciones/RendicionesTuqui";
import CargarVentasTuqui from "./components/views/liquidacion/rendiciones/cargarVentasTuqui";
import MenuRendiciones from "./components/views/liquidacion/rendiciones/MenuRendiciones";
import MenuComisiones from "./components/views/liquidacion/comisiones/MenuComisiones";
import ComisionesTuqui from "./components/views/liquidacion/comisiones/ComisionesTuqui";
import SubirDocumentacion from "./components/views/subirDocumentacion/subirDocumentacion";
import Documentacion from "./components/views/deducciones/DocumentacionPage"
import Reportes from "./components/views/reportes/Reportes"
import BuscarExtracto from "./components/views/pantallaResultados/BuscarExtracto";
import SubirPublicidad from "./components/views/pantallaResultados/SubirPublicidad";
import CargarResultados from "./components/views/pantallaResultados/CargarResultados";
import ArchivoDeducciones from "./components/views/deducciones/ArchivoDeducciones";
import SubirExtractoTuqui from "./components/views/extracto/SubirExtractoTuqui";

function App() {
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", icon);
  }, []);
  const [user, setUser] = useState(
    AuthHelper.getUser() ? AuthHelper.getUser() : null
  );
  const [cambioClave, setCambioClave] = useState(
    AuthHelper.getHasChangedPassword()
      ? AuthHelper.getHasChangedPassword()
      : false
  );
  const [rol, setRol] = useState(
    AuthHelper.getRol() ? AuthHelper.getRol() : null
  );

  const updateUserState = (newUser, newCambioClave, newRol) => {
    setUser(newUser);
    setCambioClave(newCambioClave);
    setRol(newRol);
  };

  const handleLogout = () => {
    // Función para cerrar sesión y actualizar el estado del usuario a null
    setUser(null);
  };

  return (
    <BrowserRouter>
      <Menu user={user} cambioClave={cambioClave} onLogout={handleLogout} />
      <Routes>
        <Route path="/pantalla/resultados"
          element={
            <BuscarExtracto />
          }
        />
        {/* Rutas accesibles sin estar logueado */}
        <Route
          path="/login"
          element={
            !user ? <Login onLogin={updateUserState} /> : <Navigate to="/" />
          }
        />
        <Route exact path="/recuperar-clave" element={<PasswordResetForm />} />
        <Route
          exact
          path="/nueva-clave/:uidb64/:token"
          element={<Newpassword />}
        />

        <Route
          element={
            <ProtectedRoute
              isAllowed={!!user}
              redirectTo="/login"
              errorRedirectTo="/login"
            />
          }
        >
          <Route
            element={
              <ProtectedRoute
                isAllowed={!!user && cambioClave}
                redirectTo="/cambiar-clave"
                errorRedirectTo="/cambiar-clave"
              />
            }
          >
            <Route
              exact
              path="/subir-documentacion"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" || rol === "TITULAR" || rol === 'GERENCIA-QUINIELA'
                  }
                  redirectTo="/subir-documentacion"
                  errorRedirectTo="*"
                >
                  <SubirDocumentacion
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/"
              element={
                rol === "SUCURSAL-CONCEPCION" ?
                  <Rendiciones rolUsuario={rol} />
                  :
                  rol === "OPERADOR-EXTRACTO-TUQUI" ?
                    <SubirExtractoTuqui rolUsuario={rol} />
                    :
                    <Principal rolUsuario={rol} />
              }
            />
            {/* Seccion Extratos */}
            <Route
              exact
              path="/extractos"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "GERENCIA-QUINIELA" ||
                    rol === "CONSULTOR"
                  }
                  redirectTo="/extractos"
                  errorRedirectTo="*"
                >
                  <Extractos rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/extractos/subir-extracto"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "OPERADOR-EXTRACTO" ||
                    rol === "ADMINISTRADOR" ||
                    rol === "GERENCIA-QUINIELA" ||
                    rol === 'OPERADOR-EXTRACTO'
                  }
                  redirectTo="/extractos/subir-extracto"
                  errorRedirectTo="*"
                >
                  <SubirExtracto rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/extractos/sorteos-exceptuados"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "OPERADOR-EXTRACTO" ||
                    rol === "ADMINISTRADOR" ||
                    rol === "GERENCIA-QUINIELA"
                  }
                  redirectTo="/extractos/sorteos-exceptuados"
                  errorRedirectTo="*"
                >
                  <SorteosExceptuados rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            {/* Seccion Usuarios */}
            <Route
              exact
              path="/buscar-usuario"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "GERENCIA-QUINIELA" ||
                    rol === "CONSULTOR"
                  }
                  redirectTo="/buscar-usuario"
                  errorRedirectTo="*"
                >
                  <BuscarUsuario rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            {/* Seccion Agencias */}
            <Route
              exact
              path="/agencias"
              element={
                <ProtectedRoute
                  isAllowed={rol === "ADMINISTRADOR" || rol === "GERENCIA-QUINIELA" || rol === "DOCUMENTACION" || rol === "AUDITORIA" || rol === "CONTADURIA"}
                  redirectTo="/agencias"
                  errorRedirectTo="*"
                >
                  <Agencias rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/agencias/asignacion-agencia"
              element={
                <ProtectedRoute
                  isAllowed={rol === "ADMINISTRADOR" || rol === "GERENCIA-QUINIELA" || rol === "DOCUMENTACION"}
                  redirectTo="/agencias/asignacion-agencia"
                  errorRedirectTo="*"
                >
                  <AsignacionAgencia rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            {/* Seccion Titulares */}
            <Route
              exact
              path="/titulares"
              element={
                <ProtectedRoute
                  isAllowed={rol === "ADMINISTRADOR" || rol === "GERENCIA-QUINIELA" || rol === "DOCUMENTACION" || rol === "AUDITORIA" || rol === "CONTADURIA"}
                  redirectTo="/titulares"
                  errorRedirectTo="*"
                >
                  <Titulares rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            {/* Seccion Liquidaciones */}
            <Route
              exact
              path="/liquidaciones"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "DOCUMENTACION" ||
                    rol === "CONTADURIA" ||
                    rol === "GERENCIA-QUINIELA" ||
                    rol === "OPERADOR-EXTRACTO" ||
                    rol === "SUCURSAL-CONCEPCION" ||
                    rol === "AUDITORIA"
                  }
                  redirectTo="/liquidaciones"
                  errorRedirectTo="*"
                >
                  <MenuLiquidacion rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/sorteos"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "CONTADURIA" ||
                    rol === "OPERADOR-EXTRACTO" ||
                    rol === "GERENCIA-QUINIELA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/sorteos"
                  errorRedirectTo="*"
                >
                  <Sorteos rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/sorteos/cargar-sorteo"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "CONTADURIA" ||
                    rol === "OPERADOR-EXTRACTO" ||
                    rol === "GERENCIA-QUINIELA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/sorteos/cargar-sorteo"
                  errorRedirectTo="*"
                >
                  <CargarSorteo rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/rendiciones/rendiciones-quiniela"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "DOCUMENTACION" ||
                    rol === "SUCURSAL-CONCEPCION" ||
                    rol === "GERENCIA-QUINIELA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/rendiciones/rendiciones-quiniela"
                  errorRedirectTo="*"
                >
                  <Rendiciones rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/rendiciones/rendiciones-quiniela/cargar-rendiciones"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "DOCUMENTACION" ||
                    // rol === "SUCURSAL-CONCEPCION" ||
                    rol === "GERENCIA-QUINIELA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/rendiciones/rendiciones-quiniela/cargar-rendiciones"
                  errorRedirectTo="*"
                >
                  <CargarRendiciones rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/rendiciones/generar-recibos-rendiciones"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "DOCUMENTACION" ||
                    rol === "SUCURSAL-CONCEPCION" ||
                    rol === "GERENCIA-QUINIELA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/rendiciones/generar-recibos-rendiciones"
                  errorRedirectTo="*"
                >
                  <GenerarRecibosRendiciones rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            {/* <Route exact path="/liquidaciones/rendiciones/registrar-rendiciones"
                  element={<ProtectedRoute isAllowed={rol === 'ADMINISTRADOR'} redirectTo="/liquidaciones/rendiciones/registrar-rendiciones" errorRedirectTo="*">
                    <RegistrarRendiciones rolUsuario={rol} />
                  </ProtectedRoute>} /> */}
            <Route
              exact
              path="/liquidaciones/rendiciones/movimiento-operaciones"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "DOCUMENTACION" ||
                    rol === "SUCURSAL-CONCEPCION" ||
                    rol === "GERENCIA-QUINIELA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/rendiciones/movimiento-operaciones"
                  errorRedirectTo="*"
                >
                  <MovimientosDeOperaciones rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/comisiones/comisiones-quiniela"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "GERENCIA-QUINIELA" ||
                    rol === "AUDITORIA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/comisiones/comisiones-quiniela"
                  errorRedirectTo="*"
                >
                  <Comisiones rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/comisiones/comisiones-tuqui"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "GERENCIA-QUINIELA" ||
                    rol === "AUDITORIA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/comisiones/comisiones-tuqui"
                  errorRedirectTo="*"
                >
                  <ComisionesTuqui rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/comisiones"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" ||
                    rol === "GERENCIA-QUINIELA" ||
                    rol === "AUDITORIA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/comisiones"
                  errorRedirectTo="*"
                >
                  <MenuComisiones rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/comisiones/comisiones-quiniela/enviar-comisiones"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" || rol === "GERENCIA-QUINIELA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/comisiones/comisiones-quiniela/enviar-comisiones"
                  errorRedirectTo="*"
                >
                  <EnviarComisiones rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/rendiciones/rendiciones-tuqui"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" || rol === "GERENCIA-QUINIELA" ||
                    rol === "DOCUMENTACION" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/rendiciones/rendiciones-tuqui"
                  errorRedirectTo="*"
                >
                  <RendicionesTuqui rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/rendiciones/rendiciones-tuqui/cargar-ventas"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" || rol === "GERENCIA-QUINIELA" || rol === "CONTADURIA"
                  }
                  redirectTo="/liquidaciones/rendiciones/rendiciones-tuqui/cargar-ventas"
                  errorRedirectTo="*"
                >
                  <CargarVentasTuqui rolUsuario={rol} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/liquidaciones/rendiciones"
              element={
                <ProtectedRoute
                  isAllowed={
                    rol === "ADMINISTRADOR" || rol === "GERENCIA-QUINIELA" || rol === "DOCUMENTACION" || rol === "CONTADURIA" || rol === "SUCURSAL-CONCEPCION"
                  }
                  redirectTo="/liquidaciones/rendiciones"
                  errorRedirectTo="*"
                >
                  <MenuRendiciones rolUsuario={rol} />
                </ProtectedRoute>
              }
            />

          </Route>
          <Route exact path="/deducciones"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === "OFICINA-OPERADOR" || rol === "DOCUMENTACION" || rol === "CONTADURIA" || rol === 'GERENCIA-QUINIELA' || rol === "AUDITORIA"}
                redirectTo="/deducciones">
                <Deducciones rolUsuario={rol} />
              </ProtectedRoute>
            } />
          <Route exact path="/deducciones/archivos"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === "OFICINA-OPERADOR" || rol === "DOCUMENTACION" || rol === "CONTADURIA" || rol === 'GERENCIA-QUINIELA' || rol === "AUDITORIA"}
                redirectTo="/deducciones/archivos">
                <ArchivoDeducciones rolUsuario={rol} />
              </ProtectedRoute>
            } />
          <Route exact path="/deducciones/archivos/NuevoArchivo"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === "OFICINA-OPERADOR" || rol === "DOCUMENTACION" || rol === "CONTADURIA" || rol === 'GERENCIA-QUINIELA'}
                redirectTo="/deducciones/archivos/NuevoArchivo">
                <NuevoArchivo rolUsuario={rol} />
              </ProtectedRoute>} />
          <Route exact path="/deducciones/OrdenesDePago"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === "CONTADURIA" || rol === 'GERENCIA-QUINIELA' || rol === "AUDITORIA"}
                redirectTo="/deducciones/OrdenesDePago">
                <OrdenesDePago rolUsuario={rol} />
              </ProtectedRoute>} />
          <Route exact path="/documentacion"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === "OPERADOR-EXTRACTO" || rol === "CONSULTOR" || rol === 'GERENCIA-QUINIELA' || rol === "DOCUMENTACION" || rol === "CONTADURIA" || rol === 'OFICINA-OPERADOR'}
                redirectTo="/documentacion">
                <Documentacion rolUsuario={rol} />
              </ProtectedRoute>} />
          <Route exact path="/reportes"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === "GERENCIA-QUINIELA" || rol === "CONSULTOR"}
                redirectTo="/documentacion">
                <Reportes rolUsuario={rol} />
              </ProtectedRoute>} />
          <Route exact path="/extractos/publicidad"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === 'RELACIONES' || rol === 'GERENCIA-QUINIELA'}
                redirectTo="/extractos/publicidad">
                <SubirPublicidad rolUsuario={rol} />
              </ProtectedRoute>} />
          <Route exact path="/extractos/resultados"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === 'OPERADOR-EXTRACTO' || rol === 'GERENCIA-QUINIELA'}
                redirectTo="/extractos/resultados">
                <CargarResultados />
              </ProtectedRoute>} />
          <Route exact path="/extractos/extracto-tuqui"
            element={
              <ProtectedRoute
                isAllowed={rol === 'ADMINISTRADOR' || rol === 'OPERADOR-EXTRACTO-TUQUI' || rol === 'GERENCIA-QUINIELA'}
                redirectTo="/extractos/extracto-tuqui"
                errorRedirectTo="*"
              >
                <SubirExtractoTuqui rolUsuario={rol} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cambiar-clave/"
            element={<CambiarClave onLogout={handleLogout} />}
          />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
