import React, { useMemo, useState, useEffect } from "react";
import BtnVolver from "../../common/BtnVolver";
import Datetime from 'react-datetime';
import { Modal, Form, Container, Col, Row, Card, Spinner, Button } from "react-bootstrap";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import { darken } from "@mui/material";
import moment from "moment";
import { FaTrash, FaPlus } from "react-icons/fa";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import Parser from "../../common/Parser";

const CargarDeduccion = (props) => {
    const URL_TIPODEDUCCION = window.API_ROUTES.TIPODEDUCCION;
    const URL_DEDUCCION = window.API_ROUTES.DEDUCCION
    const URL_TITULARAGENCIA = window.API_ROUTES.TITULARAGENCIA
    const URL_JUGADASMES = window.API_ROUTES.JUGADASMES;
    const URL_COMISION = window.API_ROUTES.COMISION;

    const [data, setData] = useState([]);
    const [count] = useState("");
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50
        },
        {
            header: "Nombre",
            accessorKey: "nombre",
            size: 50
        },
        {
            header: "Apellido",
            accessorKey: "apellido",
            size: 50
        },
        {
            header: "DNI",
            accessorKey: "documento",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Agencia",
            accessorKey: "agencia",
            size: 50
        },
        {
            header: "Subagencia",
            accessorKey: "subagencia",
            size: 50
        },
        {
            header: "Concepto",
            accessorKey: "concepto",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Monto",
            accessorKey: "monto",
            size: 50,
            enableColumnFilter: false,
        },
    ]);
    const [numero, setNumero] = useState("");
    const [nombreTitular, setNombreTitular] = useState("");
    const [dataTitular, setDataTitular] = useState("");
    const [monto, setMonto] = useState("");
    const [tablaConcepto, setTablaConcepto] = useState([]);
    const [concepto, setConcepto] = useState({ descripcion: '' });
    const [cantidadCuotas, setCantidadCuotas] = useState("");
    const [cuota, setCuota] = useState("");
    const [periodo, setPeriodo] = useState({
        fechaMuestra: moment().format("MM/YYYY"),
        fechaComparar: moment().format("YYYY-MM"),
    });

    useEffect(() => {
        cargarTablaConcepto();
    }, []);


    const cargarTablaConcepto = async () => {
        const conceptos = await ConsultasAPI.ListarObjetos(URL_TIPODEDUCCION);
        let results = conceptos.data;
        results = results.filter(concepto => concepto.descripcion !== "Automatizacion" && concepto.descripcion !== "Ticket")
        setTablaConcepto(results)
    };

    const handleTablaConceptoChange = (concepto) => {
        setConcepto(tablaConcepto.filter((x) => x.id === parseInt(concepto))[0]);
    };

    const handleCloseNuevaDeduccion = (event) => {
        props.close(event)
        props.recargar()
        setNumero("");
        setNombreTitular("");
        setDataTitular([]);
        setMonto("");
        setConcepto({ id: "", descripcion: "" });
        setCantidadCuotas("");
        setCuota("");
        setData([]);
    };

    const agregarDeduccion = async (event) => {
        event.preventDefault();
        const Deduccion = await crearDeduccion();
        setMonto("");
        setConcepto({ id: "", descripcion: "" });

    };

    const handleSubmitDeduccion = async (event) => {
        event.preventDefault();
        subirDeducciones(event);
    };

    const descargarTxt = (vector) => {
        // Convertir el array en una cadena de texto, separando los elementos por un salto de línea
        const contenido = vector.join('\n');

        // Crear un Blob con el contenido
        const blob = new Blob([contenido], { type: 'text/plain' });

        // Crear una URL para el Blob
        const url = URL.createObjectURL(blob);

        // Crear un enlace y simular un clic para descargar el archivo
        const link = document.createElement('a');
        link.href = url;
        link.download = 'deducciones.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Liberar la URL del Blob
        URL.revokeObjectURL(url);
    };
    const subirDeducciones = async (event) => {
        Swal.fire({
            title: "¿Estás seguro de crear las Deducciones?",
            text: "Esta acción no se puede deshacer",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#008185",
            cancelButtonColor: "#EC1B23",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const dataDeduccion = [];
                    data.forEach(deduccion => {
                        dataDeduccion.push(deduccion.deduccion);
                    });
                    const subir = await ConsultasAPI.CrearObjeto(URL_DEDUCCION + 'crearDeducciones/', dataDeduccion)
                    if (subir.status === 201) {
                        if (subir.data.length > 1) {
                            Swal.fire(
                                {
                                    title: "Subida exitosa",
                                    text: "Se subieron algunas de las Deducciones",
                                    icon: "info",
                                    confirmButtonColor: "#008185",
                                    footer: 'Se descargara un archivo con las deducciones que no se subieron'
                                }).then(() => {
                                    descargarTxt(subir.data);
                                })
                        } else {
                            Swal.fire(
                                {
                                    title: "Subida exitosa",
                                    text: "Se subieron con Exito las Deducciones",
                                    icon: "success",
                                    confirmButtonColor: "#008185"
                                }
                            );
                        }
                        handleCloseNuevaDeduccion(event);
                    } else {
                        handleCloseNuevaDeduccion(event);
                        Swal.fire(
                            {
                                title: "Subida Erronea",
                                text: "No se pudieron subir las deducciones",
                                icon: "error",
                                confirmButtonColor: "#EC1B23"
                            }
                        );
                    };
                } catch (error) {
                    console.error(error);
                    Swal.fire(
                        {
                            title: "Subida Erronea",
                            text: "No se pudieron subir las deducciones",
                            icon: "error",
                            confirmButtonColor: "#EC1B23"
                        }
                    );
                }
            };
        });
    };

    const borrarFilaTabla = (row) => {
        const dat = [...data];
        setData(dat.filter(objeto => objeto.id !== row.id))
    };

    const crearDeduccion = async () => {
        var deduccion;
        var datitos = [...data]
        let importe = monto;
        if (concepto.descripcion === "Multas" || concepto.descripcion === "Producto de mantenimiento de equipos") {
            deduccion = {
                titular_agencia: dataTitular.id,
                tipo_deduccion: concepto.id,
                periodo: moment(periodo.fechaComparar + '-01', 'YYYY-MM').format('YYYY-MM-DD'),
                importe: importe.replace(/\./g, '').replace(',', '.'),
                cantidad_cuotas: cantidadCuotas,
                nro_cuota: cuota,
            };
        } else if (concepto.descripcion === "Embargos Porcentuales") {
            var comisionTuquito = 0;
            var comisionQuinielita = 0;
            let paramComisionesTuqui = dataTitular.agencia + ':' + moment(periodo.fechaComparar + '-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
            const comisonesTuquiDatos = await ConsultasAPI.ObtenerObjeto(URL_COMISION + 'obtenerComisionAgencia/', paramComisionesTuqui);
            if (comisonesTuquiDatos.status === 200 && comisonesTuquiDatos.data.length > 0) {
                comisionTuquito = comisonesTuquiDatos.data[0].comision_bruta
            }
            const datos = await ConsultasAPI.ObtenerObjeto(URL_JUGADASMES + 'obtenerJugadaTitular/', dataTitular.id + ',' + moment(periodo.fechaComparar + '-01', 'YYYY-MM').format('MM/YYYY'));
            if (datos.status === 200 && comisonesTuquiDatos.data.length > 0) {
                comisionQuinielita = datos.data[0].comisionBruta
            }
            importe = ((comisionQuinielita + comisionTuquito) * (monto / 100));
            deduccion = {
                titular_agencia: dataTitular.id,
                tipo_deduccion: concepto.id,
                periodo: moment(periodo.fechaComparar + '-01', 'YYYY-MM').format('YYYY-MM-DD'),
                importe: importe,
            };
            importe = importe.toLocaleString('es-ES')
        } else {
            deduccion = {
                titular_agencia: dataTitular.id,
                tipo_deduccion: concepto.id,
                periodo: moment(periodo.fechaComparar + '-01', 'YYYY-MM').format('YYYY-MM-DD'),
                importe: importe.replace('.', '').replace('.', '').replace(',', '.'),
            };
        };
        var dataTabla = {
            id: datitos.length + 1,
            nombre: dataTitular.titular_detalle.persona_detalle.nombre,
            apellido: dataTitular.titular_detalle.persona_detalle.apellido,
            documento: dataTitular.titular_detalle.persona_detalle.documento,
            agencia: dataTitular.agencia_detalle.numero_agencia,
            subagencia: dataTitular.agencia_detalle.numero_agencia,
            concepto: concepto.descripcion,
            monto: Parser.currency(importe.replace('.', '').replace('.', '').replace(',', '.')),
            deduccion: deduccion,
        };
        datitos.push(dataTabla);
        setData(datitos);

        return deduccion;
    };


    const obtenerTitular = async (numero) => {
        if (numero.toString().length >= 7) {
            const titular = await ConsultasAPI.ObtenerObjeto(URL_TITULARAGENCIA + 'busquedaPorDocumento/', numero)
            if (titular.status === 200) {
                setNombreTitular(titular.data.titular_detalle.persona_detalle.apellido + ", " + titular.data.titular_detalle.persona_detalle.nombre)
                setDataTitular(titular.data);

                // Busqueda de la ultima deduccion por Multa
                const multa = await ConsultasAPI.ObtenerObjeto(URL_DEDUCCION + 'obtenerDeduccionMultaPorTitular/', titular.data.id);
                if (multa.status !== 204) {
                    if (multa.data.nro_cuota < multa.data.cantidad_cuotas) {
                        setCantidadCuotas(multa.data.cantidad_cuotas);
                        setCuota(multa.data.nro_cuota + 1);
                    } else {
                        if (multa.data.nro_cuota === multa.data.cantidad_cuotas) {
                            setCantidadCuotas("");
                            setCuota("");
                        };
                    };
                };
            } else {
                setNombreTitular("No se encontro coincidencia")
                setCantidadCuotas("");
                setCuota("");
            }
        } else {
            if (numero.toString().length === 0) {
                setNombreTitular("")
            } else {
                setNombreTitular("Escribiendo...")
            }
        };
    };
    function format(input) {
        var num = input.replace(/\./g, '');
        // if (!isNaN(num)) {
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
        num = num.split('').reverse().join('').replace(/^[\.]/, '');
        input = num;
        setMonto(input)
        // }
    }
    return (
        <>
            <Modal show={props.show}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton
                    onClick={handleCloseNuevaDeduccion}
                >
                    <Modal.Title>Nueva Deduccion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={agregarDeduccion}>
                        <Row
                            className="mb-3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2%",
                            }}
                        >
                            <Form.Group as={Col} md="3">
                                <Form.Label>Numero Documento</Form.Label>
                                <Form.Control
                                    value={numero}
                                    required
                                    onChange={(event) => {
                                        obtenerTitular(event.target.value)
                                        setNumero(event.target.value)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Titular</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    value={nombreTitular}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Periodo de Deduccion</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat="MM/YYYY"
                                    inputProps={{
                                        required: true,
                                        placeholder: periodo.fechaMuestra,
                                        readOnly: true,
                                    }}
                                    value={periodo.fechaMuestra}
                                    closeOnSelect={true}
                                    onChange={(momentDate) => {
                                        const fechaMuestra = momentDate.format("MM/YYYY");
                                        const fechaComparar = momentDate.format("YYYY-MM");
                                        setPeriodo({
                                            fechaMuestra: fechaMuestra,
                                            fechaComparar: fechaComparar,
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2%",
                            }}
                        >
                            <Form.Group as={Col} md="3">
                                <Form.Label>Concepto</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={concepto.id}
                                    onChange={(event) => {
                                        handleTablaConceptoChange(event.target.value);
                                    }}
                                >
                                    <option value="" hidden>Elegir Concepto</option>
                                    {
                                        tablaConcepto.length > 0 ?
                                            tablaConcepto.map((concepto) => (
                                                <option key={concepto.id} value={concepto.id}>
                                                    {concepto.descripcion}
                                                </option>
                                            ))
                                            : null
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                {concepto.descripcion === "Embargos Porcentuales" ?
                                    <>
                                        <Form.Label>Porcentaje</Form.Label>
                                        <Form.Control
                                            value={monto}
                                            required
                                            onKeyPress={(event) => {
                                                // Solo permitir números
                                                const regex = /[0-9]/;
                                                const key = String.fromCharCode(event.charCode);
                                                if (!regex.test(key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (event.target.value === '' || (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100)) {
                                                    setMonto(event.target.value);
                                                }
                                            }}
                                        />
                                    </>
                                    :
                                    <>
                                        <Form.Label>Monto</Form.Label>
                                        <Form.Control
                                            value={monto}
                                            required
                                            onChange={(event) => {
                                                format(event.target.value)
                                            }}
                                        />
                                    </>
                                }
                            </Form.Group>
                            {
                                concepto.descripcion === "Multas" || concepto.descripcion === "Producto de mantenimiento de equipos" ?
                                    <>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Cantidad de Cuotas</Form.Label>
                                            <Form.Control
                                                type="number"
                                                required
                                                value={cantidadCuotas}
                                                onChange={(event) => {
                                                    setCantidadCuotas(event.target.value)
                                                }} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Cuotas</Form.Label>
                                            <Form.Control
                                                required
                                                value={cuota}
                                                onChange={(event) => {
                                                    setCuota(event.target.value)
                                                }} />
                                        </Form.Group>
                                    </>
                                    :
                                    null

                            }
                        </Row>
                        <Row
                            className="mb-3"
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                marginRight: "10%",
                            }}
                        >
                            <Button
                                type="submit"
                                className="botonCPA p-2 d-flex justify-content-center"
                                style={{ width: "fit-content", float: "right" }}
                            >
                                <FaPlus /> Agregar
                            </Button>
                        </Row>
                    </Form>
                    <MaterialReactTable
                        className="w-100"
                        columns={columns}
                        data={data}
                        muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: "0",
                                border: "1px dashed #e0e0e0",
                            },
                        }}
                        muiTableBodyProps={{
                            sx: (theme) => ({
                                "& tr:nth-of-type(odd)": {
                                    backgroundColor: darken(theme.palette.background.default, 0.1),
                                },
                                fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                            }),
                        }}
                        initialState={{
                            columnVisibility: { id: false },
                            showColumnFilters: true,
                        }} //hide firstName column by default
                        editingMode="modal" //default
                        enableRowSelection={false} //enable some features
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableColumnActions={false}
                        enableSorting={false}
                        displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                        enableRowActions
                        positionActionsColumn="last"
                        renderRowActions={({ row }) => (
                            <div className="d-flex">
                                <IconButton
                                    onClick={() => {
                                        borrarFilaTabla(row.original);
                                    }}
                                >
                                    <FaTrash />
                                </IconButton>
                            </div>
                        )}
                        manualPagination
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10],
                        }}
                        enablePagination={true}
                        localization={MRT_Localization_ES}
                        rowCount={count}
                        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                        enableGlobalFilter={false} //turn off a feature
                        manualFiltering //turn off client-side filtering
                        onColumnFiltersChange={(value) => {
                            setColumnFilters(value);
                        }} //hoist internal columnFilters state to your state
                        state={{
                            columnFilters,
                            pagination,
                        }}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="botonCPA" onClick={handleSubmitDeduccion}>Confirmar</Button>
                    <Button className="botonCPA" onClick={handleCloseNuevaDeduccion} style={{ background: "#EC1B23", border: "2px solid #EC1B23" }}>Cancelar</Button>
                </Modal.Footer>

            </Modal >
        </>
    )
};
export default CargarDeduccion;
