
import {
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Link,
    Document,
} from "@react-pdf/renderer";
import ConsultasAPI from "../../../shared/helpers/consultasAPI.jsx";
import { useEffect, useState } from "react";
import { Container, Card, Row, Col, Button, Form } from "react-bootstrap";
import logo from "../../../img/logo.png"
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { PDFDownloadLink } from '@react-pdf/renderer';
import Parser from "../../common/Parser.jsx"
import Converter from "../../common/Converter.jsx"
import moment from "moment"
const styles = StyleSheet.create({
    page: {
        padding: 49,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10,
    },
    logo: {
        width: 80,
        height: 47,
    },
    headerTitle: {
        fontSize: 12,
        fontWeight: "bold",
        marginLeft: "auto",
        borderBottomColor: "black",
        borderBottomWidth: 1,
    },
    subHeaderTitle: {
        fontSize: 10,
        marginLeft: "auto",
        marginBot: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
    },
    subtitle: {
        fontSize: 12,
        marginBottom: 10,
        fontWeight: "bold",
        textAlign: "left",
        borderBottomColor: "black",
        borderBottomWidth: 1,
    },
    text: {
        fontSize: 12,
        marginBottom: 12,
    },
    table: {
        marginTop: 10,
        marginBot: 50,
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: "row",
        flexWrap: "wrap",
        fontSize: 11,
        marginBot: 10,
    },
    tableColHeader: {
        flex: 1,
        width: "50%",
        borderStyle: "solid",
        backgroundColor: "#bfbfbf",
        borderColor: "#bfbfbf",
        borderBottomWidth: 1,
        borderRightWidth: 1,
        padding: 5,
        fontWeight: "bold",
        fontSize: 12,
    },
    tableCol: {
        flex: 1,
        width: "50%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomWidth: 1,
        padding: 5,
        borderRightWidth: 1, // borde derecho de las columnas
    },
    sectionSeparator: {
        height: 20, // Altura de la separación entre secciones
    },
    separator: {
        marginTop: 20,
        marginBottom: 20,
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    footer: {
        float: "right",
        fontSize: 8,
        textAlign: "center",
        color: "black",
    },
});

const GenerarCertificados = (props) => {
    const ordenPago_id = props.ordenPagoId;
    const URL_RETENCION = window.API_ROUTES.RETENCION;
    const URL_DOMICILIOCOMERCIAL = window.API_ROUTES.DOMICILIOCOMERCIAL;
    const URL_ARCHIVODOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;
    const [dataRetenciones, setDataRetenciones] = useState([]);
    const [domicilio, setDomicilio] = useState([]);
    const [factura, setFactura] = useState([]);
    useEffect(() => {
        if (props.ordenPagoId) {
            obtenerRetenciones();
        }
    }, [props.ordenPagoId])
    const obtenerRetenciones = async () => {
        try {
            const retencion = await ConsultasAPI.ObtenerObjeto(URL_RETENCION + 'busquedaRetencionOP/', ordenPago_id);
            setDataRetenciones(retencion.data)
            const domicilio = await ConsultasAPI.ObtenerObjeto(URL_DOMICILIOCOMERCIAL + 'busqueda/', retencion.data.orden_detalle.titular_detalle.agencia)
            setDomicilio(domicilio.data)
            var data = retencion.data.orden_detalle.titular_detalle.agencia + ':' + moment(retencion.data.orden_detalle.periodo_comision, "DD/MM/YYYY").format("YYYY-MM-DD")
            const factura = await ConsultasAPI.ObtenerObjeto(URL_ARCHIVODOCUMENTACION + 'obtenerFacturaAgencia/', data)
            setFactura(factura.data)
        } catch (error) {
            console.error(error)
        };
    };

    const inicio = () => {
        return (
            <div key={"inicio"}>
                {dataRetenciones.length !== 0 && domicilio.length !== 0
                    ?
                    <>
                        <View >
                            {/* Datos del Agente de Recaudacion */}
                            <View>
                                <Text style={styles.subtitle}>
                                    A. - Datos del Agente de Recaudación
                                </Text>
                                <Text style={styles.text}>
                                    Apellido y Nombre o Denominación:  CAJA POPULAR DE AHORROS DE TUCUMAN
                                </Text>
                                <Text style={styles.text}>
                                    Cuit N°:  30-51799955-1
                                </Text>
                                <Text style={styles.text}>
                                    N° Insc. Ing Brutos:  30-51799955-1
                                </Text>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={styles.text}>
                                        Domicilio:
                                    </Text>

                                    <Text style={{ ...styles.text, marginLeft: "20" }}>
                                        Calle:
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "10" }}>
                                        San Martin 469
                                    </Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ ...styles.text }}>
                                        Localidad:
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "10" }}>
                                        San Miguel de Tucuman
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "90" }}>
                                        Provincia:
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "10" }}>
                                        Tucuman
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "100" }}>
                                        C.P.:
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "10" }}>
                                        4000
                                    </Text>
                                </View>
                            </View>
                            {/* Datos del sujeto Recaudado */}

                            <View>
                                <Text style={styles.subtitle}>
                                    B. - Datos del sujeto Recaudado
                                </Text>
                                <Text style={styles.text}>
                                    Apellido y Nombre o Denominación:  {dataRetenciones.orden_detalle.titular_detalle.titular_detalle.persona_detalle.apellido}, {dataRetenciones.orden_detalle.titular_detalle.titular_detalle.persona_detalle.nombre}
                                </Text>
                                <Text style={styles.text}>
                                    Cuit N°:  {dataRetenciones.orden_detalle.titular_detalle.titular_detalle.persona_detalle.cuit}
                                </Text>
                                <Text style={styles.text}>
                                    N° Insc. Ing Brutos:  {dataRetenciones.orden_detalle.titular_detalle.titular_detalle.persona_detalle.cuit}
                                </Text>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={styles.text}>
                                        Domicilio:
                                    </Text>

                                    <Text style={{ ...styles.text, marginLeft: "20" }}>
                                        Calle:
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "10" }}>
                                        {domicilio.calle}
                                    </Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ ...styles.text }}>
                                        Localidad:
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "15" }}>
                                        {domicilio.localidad_detalle.nombre}
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "90" }}>
                                        Provincia:
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "12" }}>
                                        Tucuman
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "100" }}>
                                        C.P.:
                                    </Text>
                                    <Text style={{ ...styles.text, marginLeft: "10" }}>
                                        -
                                    </Text>
                                </View>
                            </View>

                        </View>
                    </>
                    :
                    null}
            </div>
        );
    }
    const init = inicio();
    const retencionIngresosBrutos = () => {
        return (

            <div key={"ingresosBrutos"}>
                {dataRetenciones.length !== 0 && domicilio.length !== 0 && (dataRetenciones.retencion_iibb_quiniela !== 0 || dataRetenciones.retencion_iibb_tuqui !== 0)
                    ?
                    <PDFDownloadLink
                        className="PDF-Download"
                        document={
                            <Document>
                                <Page style={styles.page} >
                                    {/* Encabezado */}
                                    <View>
                                        <View style={styles.header}>
                                            <Image src={logo} style={styles.logo} />
                                            <Text style={styles.headerTitle}>
                                                Constancia de Recaudación Ingresos Brutos Tucumán
                                            </Text>
                                            <Text style={styles.subHeaderTitle}>
                                                N°: {dataRetenciones.id}
                                                {"\n"}
                                                Fecha: {moment(dataRetenciones.fecha_creacion, "DD/MM/YYYY hh:ss:m").format("DD/MM/YY")}
                                            </Text>
                                        </View>
                                        <View style={styles.separator} />

                                    </View>
                                    {init}
                                    {/* Detalle de la Recaudacion */}

                                    <View>
                                        <Text style={styles.subtitle}>
                                            C. - Detalle de la Recaudación
                                        </Text>
                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            <div>
                                                <Text style={styles.text}>
                                                    Base Quiniela:

                                                </Text>
                                                <Text style={styles.text}>

                                                    Alicuota de Retención:

                                                </Text>
                                                <Text style={styles.text}>

                                                    Retención Practicada:
                                                </Text>
                                            </div>

                                            <div>
                                                <Text style={{ ...styles.text, marginLeft: "20" }}>
                                                    {Parser.currency(100 * dataRetenciones.retencion_iibb_quiniela / 6.50)}
                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto", borderBottomColor: "black", borderBottomWidth: 1, }}>
                                                    6,50%
                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    {Parser.currency(dataRetenciones.retencion_iibb_quiniela)}
                                                </Text>
                                            </div>
                                            <div>
                                                <Text style={{ ...styles.text, marginLeft: "100" }}>
                                                    Base Tuqui 10:

                                                </Text>
                                            </div>
                                            <div>
                                                <Text style={{ ...styles.text, marginLeft: "40" }}>
                                                    {Parser.currency(100 * dataRetenciones.retencion_iibb_tuqui / 6.50)}
                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto", borderBottomColor: "black", borderBottomWidth: 1, }}>
                                                    6,50%
                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    {Parser.currency(dataRetenciones.retencion_iibb_tuqui)}
                                                </Text>
                                            </div>
                                        </View>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Text style={styles.text}>
                                                Total de Retenciones:
                                            </Text>
                                            <Text style={{
                                                ...styles.text, marginLeft: "35", fontWeight: "bold",
                                            }}>
                                                {Parser.currency(dataRetenciones.retencion_iibb_quiniela + dataRetenciones.retencion_iibb_tuqui)}
                                            </Text>
                                        </div>
                                        <View>
                                            <Text style={{
                                                ...styles.text, fontWeight: "bold",
                                            }}>
                                                Son Pesos: {"  "}
                                                {(Converter.convert(parseInt(dataRetenciones.retencion_iibb_quiniela + dataRetenciones.retencion_iibb_tuqui))).toUpperCase()}
                                                {" "}
                                                {
                                                    parseFloat((dataRetenciones.retencion_iibb_quiniela + dataRetenciones.retencion_iibb_tuqui)).toFixed(2).split('.')[1]
                                                }/100
                                            </Text>
                                        </View>
                                        <View>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Firma del Agente de Recaudación:
                                            </Text>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Aclaración:
                                            </Text>
                                        </View>
                                    </View>
                                </Page>
                            </Document>
                        }
                        fileName={"ConstanciadeRecaudaciónIngresosBrutos.pdf"}
                        style={{ display: 'flex', width: 'fit-content' }}

                    >
                        {({ loading }) => (
                            <Button onClick={props.onExport} className="btn botonCPA" style={{ width: 'fit-content', justifyContent: 'center' }}>
                                <BsFillFileEarmarkPdfFill className="mb-1" />
                                <strong>{loading ? "Generando PDF..." : "Descargar"}</strong>
                            </Button>
                        )}
                    </PDFDownloadLink>
                    :
                    null}
            </div>
        );
    }
    const ingresosBrutos = retencionIngresosBrutos()


    const tributoMunicipal = () => {
        return (

            <div key={"tributoMunicipal"}>
                {dataRetenciones.length !== 0 && domicilio.length !== 0 && dataRetenciones.retencion_municipal !== 0
                    ?
                    <PDFDownloadLink
                        className="PDF-Download"
                        document={
                            <Document>
                                <Page style={styles.page} >
                                    {/* Encabezado */}
                                    <View >
                                        <View style={styles.header}>
                                            <Image src={logo} style={styles.logo} />
                                            <View style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', justifyContent: 'center' }}>
                                                <Text style={styles.headerTitle}>
                                                    Constancia de Retención Tributo Municipal
                                                </Text>
                                                {/* <Text style={{ ...styles.headerTitle, marginRight: '15' }}>
                                                    Municipalidad de S.M de TUCUMAN
                                                </Text> */}
                                            </View>
                                            <Text style={styles.subHeaderTitle}>
                                                N°: {dataRetenciones.id}
                                                {"\n"}
                                                Fecha: {moment(dataRetenciones.fecha_creacion, "DD/MM/YYYY hh:ss:m").format("DD/MM/YY")}
                                            </Text>
                                        </View>
                                        <View style={styles.separator} />

                                    </View>
                                    {/* Datos del Agente de Recaudacion */}
                                    {init}
                                    {/* Detalle de la Recaudacion */}
                                    <View>
                                        <Text style={styles.subtitle}>
                                            C. - Detalle de la Retención
                                        </Text>
                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            <div>
                                                <Text style={styles.text}>
                                                    Base para cálculo:
                                                </Text>
                                                <Text style={styles.text}>
                                                    Alicuota de Retención:
                                                </Text>
                                                <Text style={styles.text}>
                                                    Retención Practicada:
                                                </Text>

                                            </div>

                                            <div>
                                                <Text style={{ ...styles.text, marginLeft: "20" }}>
                                                    {Parser.currency((100 * dataRetenciones.retencion_iibb_quiniela / 6.50) + (100 * dataRetenciones.retencion_iibb_tuqui / 6.50))}
                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto", borderBottomColor: "black", borderBottomWidth: 1, }}>
                                                    {parseFloat(
                                                        ((dataRetenciones.retencion_municipal * 100) /
                                                            (((dataRetenciones.retencion_iibb_quiniela * 100) / 6.50) +
                                                                ((dataRetenciones.retencion_iibb_tuqui * 100) / 6.50))).toFixed(1)
                                                    )}%
                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    {Parser.currency(dataRetenciones.retencion_municipal)}
                                                </Text>
                                            </div>
                                        </View>
                                        <View>
                                            <Text style={{ ...styles.text }}>
                                                Son Pesos:{"     "}
                                                {
                                                    (Converter.convert(parseInt(dataRetenciones.retencion_municipal))).toUpperCase()
                                                }
                                                {" "}
                                                {
                                                    parseFloat(dataRetenciones.retencion_municipal).toFixed(2).split('.')[1]
                                                }/100
                                            </Text>
                                        </View>
                                        <View>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Firma del Agente de Recaudación:
                                            </Text>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Aclaración:
                                            </Text>
                                        </View>
                                    </View>
                                </Page>
                            </Document>
                        }
                        fileName={"ConstanciadeRetencionTributoMunicipal.pdf"}
                        style={{ display: 'flex', width: 'fit-content' }}

                    >
                        {({ loading }) => (
                            <Button onClick={props.onExport} className="btn botonCPA" style={{ width: 'fit-content', justifyContent: 'center', width: 'fit-content' }}>
                                <BsFillFileEarmarkPdfFill className="mb-1" />
                                <strong>{loading ? "Generando PDF..." : "Descargar"}</strong>
                            </Button>
                        )}
                    </PDFDownloadLink>
                    :
                    null}
            </div>
        );

    }
    const tributo = tributoMunicipal()


    const ganancias = () => {
        return (
            <div key={"ganancias"}>
                {dataRetenciones.length !== 0 && domicilio.length !== 0 && factura.length !== 0 && dataRetenciones.retencion_ganancias !== 0
                    ?
                    <PDFDownloadLink
                        className="PDF-Download"
                        document={
                            <Document>
                                <Page style={styles.page} >
                                    {/* Encabezado */}
                                    <View >
                                        <View style={styles.header}>
                                            <Image src={logo} style={styles.logo} />
                                            <View style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', justifyContent: 'center' }}>
                                                <Text style={styles.headerTitle}>
                                                    Constancia de Retencion AFIP
                                                </Text>
                                            </View>
                                            <Text style={styles.subHeaderTitle}>
                                                N°: {dataRetenciones.id}
                                                {"\n"}
                                                Fecha: {moment(dataRetenciones.fecha_creacion, "DD/MM/YYYY hh:ss:m").format("DD/MM/YYYY")}
                                            </Text>
                                        </View>
                                        <View style={styles.separator} />

                                    </View>
                                    {/* Datos del Agente de Recaudacion */}
                                    {init}
                                    {/* Detalle de la Recaudacion */}
                                    <View>
                                        <Text style={styles.subtitle}>
                                            C. - Detalle de la Retención Practicada
                                        </Text>
                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            <div>
                                                <Text style={styles.text}>
                                                    Impuesto:
                                                </Text>
                                                <Text style={styles.text}>
                                                    Comprobante que Origina la Retencion:
                                                </Text>
                                                <Text style={styles.text}>
                                                    Monto del Comprobante que origina la Retencion:
                                                </Text>
                                                <Text style={styles.text}>
                                                    Monto de la Retencion:
                                                </Text>

                                            </div>

                                            <div>
                                                <Text style={{ ...styles.text, marginLeft: "20" }}>
                                                    Impto. A las Ganancias
                                                </Text>

                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    01-FACTURA N°  {factura.datos_json.nroFactura}
                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    {Parser.currency((100 * dataRetenciones.retencion_iibb_quiniela / 6.50) + (100 * dataRetenciones.retencion_iibb_tuqui / 6.50))}

                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    {Parser.currency(dataRetenciones.retencion_ganancias)}

                                                </Text>

                                            </div>
                                        </View>
                                        <View>
                                            <Text style={styles.text}>
                                                Imposibilidad de la Retencion:  NO
                                            </Text>
                                        </View>
                                        <View>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Firma del Agente de Recaudación:
                                            </Text>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Aclaración:
                                            </Text>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Cargo:
                                            </Text>
                                        </View>
                                    </View>
                                </Page>
                            </Document>
                        }
                        fileName={"ConstanciadeRetencionAFIP.pdf"}
                        style={{ display: 'flex', width: 'fit-content' }}

                    >
                        {({ loading }) => (
                            <Button onClick={props.onExport} className="btn botonCPA" style={{ width: 'fit-content', justifyContent: 'center' }}>
                                <BsFillFileEarmarkPdfFill className="mb-1" />
                                <strong>{loading ? "Generando PDF..." : "Descargar"}</strong>
                            </Button>
                        )}
                    </PDFDownloadLink>
                    :
                    null}
            </div>
        );


    }
    const gcias = ganancias()
    const impuestoIva = () => {
        return (
            <div key={"iva"}>
                {dataRetenciones.length !== 0 && domicilio.length !== 0 && factura.length !== 0 && dataRetenciones.retencion_iva !== 0
                    ?
                    <PDFDownloadLink
                        className="PDF-Download"
                        document={
                            <Document>
                                <Page style={styles.page} >
                                    {/* Encabezado */}
                                    <View >
                                        <View style={styles.header}>
                                            <Image src={logo} style={styles.logo} />
                                            <View style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', justifyContent: 'center' }}>
                                                <Text style={styles.headerTitle}>
                                                    Constancia de Retencion AFIP
                                                </Text>
                                            </View>
                                            <Text style={styles.subHeaderTitle}>
                                                N°: {dataRetenciones.id}
                                                {"\n"}
                                                Fecha: {moment(dataRetenciones.fecha_creacion, "DD/MM/YYYY hh:ss:m").format("DD/MM/YYYY")}
                                            </Text>
                                        </View>
                                        <View style={styles.separator} />

                                    </View>
                                    {/* Datos del Agente de Recaudacion */}
                                    {init}
                                    {/* Detalle de la Recaudacion */}
                                    <View>
                                        <Text style={styles.subtitle}>
                                            C. - Detalle de la Retención Practicada
                                        </Text>
                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            <div>
                                                <Text style={styles.text}>
                                                    Impuesto:
                                                </Text>
                                                <Text style={styles.text}>
                                                    Comprobante que Origina la Retencion:
                                                </Text>
                                                <Text style={styles.text}>
                                                    Monto del Comprobante que origina la Retencion:
                                                </Text>
                                                <Text style={styles.text}>
                                                    Monto de la Retencion:
                                                </Text>

                                            </div>

                                            <div>
                                                <Text style={{ ...styles.text, marginLeft: "20" }}>
                                                    Impuesto al Valor Agregado
                                                </Text>

                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    01-FACTURA N°  {factura.datos_json.nroFactura}
                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    {Parser.currency((100 * dataRetenciones.retencion_iibb_quiniela / 6.50) + (100 * dataRetenciones.retencion_iibb_tuqui / 6.50))}

                                                </Text>
                                                <Text style={{ ...styles.text, marginLeft: "auto" }}>
                                                    {Parser.currency(dataRetenciones.retencion_iva)}
                                                </Text>

                                            </div>
                                        </View>
                                        <View>
                                            <Text style={styles.text}>
                                                Imposibilidad de la Retencion:  NO
                                            </Text>
                                        </View>
                                        <View>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Firma del Agente de Recaudación:
                                            </Text>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Aclaración:
                                            </Text>
                                            <Text style={{ ...styles.text, marginTop: "20" }}>
                                                Cargo:
                                            </Text>
                                        </View>
                                    </View>
                                </Page>
                            </Document>
                        }
                        fileName={"ConstanciadeRetencionIva.pdf"}
                        style={{ display: 'flex', width: 'fit-content' }}
                    >
                        {({  loading }) => (
                            <Button onClick={props.onExport} className="btn botonCPA" style={{ width: 'fit-content', justifyContent: 'center' }}>
                                <BsFillFileEarmarkPdfFill className="mb-1" />
                                <strong>{loading ? "Generando PDF..." : "Descargar"}</strong>
                            </Button>
                        )}
                    </PDFDownloadLink>
                    :
                    null}
            </div>
        );


    }
    const iva = impuestoIva()


    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
            {
                dataRetenciones.retencion_iibb_quiniela !== 0 || dataRetenciones.retencion_iibb_tuqui !== 0 ?
                    <div className="justify-content-between" style={{ display: 'flex', borderBottom: 'inset', paddingBottom: "15px", paddingTop: "10px", flexDirection: 'row' }}>
                        <Card.Text style={{ fontSize: "0.75rem", fontWeight: 'bold', width: 'fit-content' }}>Constancia de Recaudación Ingresos Brutos Tucumán</Card.Text>
                        {ingresosBrutos}
                    </div>
                    :
                    null
            }
            {
                dataRetenciones.retencion_municipal !== 0 ?
                    <div className="justify-content-between" style={{ display: 'flex', borderBottom: 'inset', paddingBottom: "15px", paddingTop: "10px", flexDirection: 'row' }}>
                        <Card.Text style={{ fontSize: "0.75rem", fontWeight: 'bold' }}>Constancia de Retención Tributo Municipal</Card.Text>
                        {tributo}
                    </div>
                    :
                    null
            }
            {
                dataRetenciones.retencion_ganancias !== 0 ?
                    <div className="justify-content-between" style={{ display: 'flex', borderBottom: 'inset', paddingBottom: "15px", paddingTop: "10px", flexDirection: 'row' }}>
                        <Card.Text style={{ fontSize: "0.75rem", fontWeight: 'bold' }}>Constancia de Retención AFIP</Card.Text>
                        {gcias}
                    </div>
                    :
                    null
            }
            {
                dataRetenciones.retencion_iva !== 0 ?
                    <div className="justify-content-between" style={{ display: 'flex', borderBottom: 'inset', paddingBottom: "15px", paddingTop: "10px", flexDirection: 'row' }}>
                        <Card.Text style={{ fontSize: "0.75rem", fontWeight: 'bold' }}>Constancia de Retención IVA</Card.Text>
                        {iva}
                    </div>
                    :
                    null
            }
        </div>
    )
}


export default GenerarCertificados
