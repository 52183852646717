import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Row, Card, Button, Spinner, Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Select } from "antd";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { BsEnvelopePlusFill } from "react-icons/bs";
import { PDFDownloadLink, PDFViewer, Document, BlobProvider } from "@react-pdf/renderer";
import EmailComisiones from "./PDF/EmailComisiones";
import CondicionesPago from "./PDF/CondicionesPago";
import EmailComisionesHTML from "./PDF/CondicionesPagoHtml";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import dayjs from "dayjs";
import BtnVolver from "../../../common/BtnVolver";
import moment from "moment";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import Swal from "sweetalert2";
import ReactDOMServer from 'react-dom/server';
import { FaLeaf } from "react-icons/fa";

const { Option } = Select;

const EnviarComisiones = () => {
  // const rolUser = props.rolUsuario;
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSendedMail, setIsSendedMail] = useState(false);
  const [dataMail, setDataMail] = useState(null);
  const [mailAgencia, setMailAgencia] = useState("");
  var valid = function (current) {
    const today = moment();
    const isSunday = current.day() === 0;
    return current.isBefore(today) && !isSunday;
  };
  const [periodo, setPeriodo] = useState();
  const [agencias, setAgencias] = useState([]);
  const [agencia, setAgencia] = useState();
  const [NumeroAgencia, setNumeroAgencia] = useState("");
  const [loading, setLoading] = useState(false);
  const [comisionesTabla, setComisionesTabla] = useState([]);
  const [mailsAgencias, setmailsAgencias] = useState([]);
  const URL_JUGADASMES = window.API_ROUTES.JUGADASMES;
  const URL_AGENCIAS = window.API_ROUTES.AGENCIAS;
  const URL_BASE = window.API_ROUTES.BASE;
  const URL_COMISION = window.API_ROUTES.COMISION;
  const URL_REGISTROMAIL = window.API_ROUTES.REGISTROMAIL;
  const [htmlString, sethtmlString] = useState([]);
  const DivRef = useRef(null);
  useEffect(() => {
    ConsultasAPI.ListarObjetos(URL_AGENCIAS + "ListaFiltrar/").then(
      (response) => {
        setAgencias(
          response.data.filter(
            (agencia) => parseInt(agencia.numero_agencia) !== 0
          )
        );
      }
    );
  }, []);

  useEffect(() => {
    loadComisiones();
  }, [agencia, periodo]);


  useEffect(() => {
    sethtmlString(ReactDOMServer.renderToStaticMarkup(
      <EmailComisionesHTML datos={periodo} />)
    );
  }, [agencia, periodo]);

  const loadComisiones = async () => {
    if (agencia && periodo) {
      setLoading(true);
      const filters = {
        agencia: agencia,
        periodo: dayjs(periodo).format("YYYY-MM-DD HH:mm:ssZ"),
      };
      var mails = [];
      ConsultasAPI.ListarObjetos(
        URL_JUGADASMES + "noPag/",
        null,
        null,
        filters
      ).then(async (response) => {
        var datos = response.data;
        const mail = await ConsultasAPI.ObtenerObjeto(URL_AGENCIAS + 'busqueda/', agencia)
        const promesas =
          // setComisionesTabla(
          //El filter me saca las jugadasmes sin responsables
          datos
            .filter((dato) => dato.titularAgencia.titular)
            .map((dato) => {
              return ConsultasAPI.ObtenerObjeto(URL_COMISION
                + 'obtenerComisionTitular/', dato.titularAgencia.id + ':' + dayjs(periodo).format("YYYY-MM-DD")
              ).then((responseTuqui) => {
                var tuqui;
                if (responseTuqui.data.length === 0) {
                  tuqui = 0;
                } else {
                  tuqui = responseTuqui.data[0];
                }
                mails.push({
                  mail: dato.titularAgencia.titular_detalle.persona_detalle.email,
                  agencia: 'Ag: ' + dato.titularAgencia.agencia_detalle.numero_agencia + ' - Sub: ' + dato.titularAgencia.agencia_detalle.numero_subagencia,
                })
                if (dato.titularAgencia.agencia_detalle.numero_subagencia === 0) {
                  setMailAgencia(dato.titularAgencia.agencia_detalle.email);
                } else {
                  setMailAgencia(mail.data.email);
                }
                setNumeroAgencia('Ag: ' + dato.titularAgencia.agencia_detalle.numero_agencia + ' - Sub: ' + dato.titularAgencia.agencia_detalle.numero_subagencia)
                return {
                  AGENCIA: 'Ag: ' + dato.titularAgencia.agencia_detalle.numero_agencia + ' - Sub: ' + dato.titularAgencia.agencia_detalle.numero_subagencia,
                  // SUB: dato.titularAgencia.agencia_detalle.numero_subagencia,
                  Titular:
                    dato.titularAgencia.titular_detalle.persona_detalle.apellido + ', ' +
                    dato.titularAgencia.titular_detalle.persona_detalle.nombre,
                  CUIT: dato.titularAgencia.titular_detalle.persona_detalle.cuit.toString(),
                  Venta: dato.montoVendido,
                  "Venta Oficial+Sub":
                    dato.titularAgencia.agencia_detalle.numero_subagencia === 0
                      ? dato.jugadaMesTotal.montoVendido
                      : 0,
                  "Comision 12%": dato.comisionSubagencia,
                  "Comision 4%":
                    dato.titularAgencia.agencia_detalle.numero_subagencia === 0
                      ? dato.comisionAgencia
                      : 0,
                  "Subtotal Quiniela":
                    dato.titularAgencia.agencia_detalle.numero_subagencia === 0
                      ? dato.jugadaMesTotal.comisionBruta
                      : dato.comisionBruta,
                  "Comision 12%  Tuqui": tuqui === 0 ? tuqui : tuqui.comision_venta,
                  "Comision 4%  Tuqui": tuqui === 0 ? tuqui : tuqui.comision_venta_total,
                  "Subtotal Tuqui": tuqui === 0 ? tuqui : tuqui.comision_bruta,
                  "Comision Bruta Total":
                    dato.titularAgencia.agencia_detalle.numero_agencia === 0
                      ? dato.jugadaMesTotal.comisionBruta + tuqui.comision_bruta
                      : dato.comisionBruta + (tuqui === 0 ? tuqui : tuqui.comision_bruta),
                };
              })
            })
        // );
        Promise.all(promesas)
          .then(async (resultados) => {
            let filtersMail = {
              agencia: agencia,
              periodo: periodo.format('YYYY-MM-DD'),
              concepto: "Envio Comisiones",
            }
            const registroMail = await ConsultasAPI.ListarObjetos(URL_REGISTROMAIL, null, null, filtersMail);
            if (registroMail.status === 200 && registroMail.data.length > 0) {
              setDataMail(registroMail.data[0]);
              setIsSendedMail(true);
            }
            else {
              setDataMail(null);
              setIsSendedMail(false);
            }
            setComisionesTabla(resultados);
            setLoading(false);
            setmailsAgencias(mails)
          })
          .catch((error) => {
            console.error("Error al procesar las promesas:", error);
            setLoading(false);
          });
      })
      // });
    }
  };


  const enviarComisiones = async (pdfBlob) => {
    // if (pdfBlob) {
    console.info(pdfBlob)
    setIsLoading(true);
    if (mailsAgencias.length > 0) {
      const promises = mailsAgencias.map(async (mail) => {
        const blob = new Blob([htmlString], { type: 'text/html' });
        const formData = new FormData();
        formData.append('file', blob, mail.mail);
        const numero_ag = mail.agencia.split(' - ')[0].split('').pop()
        const numero_sub = mail.agencia.split(' - ')[1].split(' ')[1]
        formData.append('agencia', mail.agencia);
        formData.append('periodo', moment(periodo, "MM/YYYY").format("MM/YYYY"));
        const response = await ConsultasAPI.CrearObjeto(URL_BASE + 'enviar_mail/', formData)
        if (response.status === 200) {

        } else {
          setIsLoading(false);
          Swal.fire({
            title: "No se pudo realizar el envio del mail",
            icon: "error"
          });
        }
        Promise.all(promises).then(async () => {
          let data = {
            agencia: numero_ag,
            subagencia: numero_sub,
            mail: mailAgencia,
            concepto: "Envio Comisiones",
            periodo: periodo.format('YYYY-MM-DD')
          }
          const mailRegistrado = await ConsultasAPI.CrearObjeto(URL_REGISTROMAIL, data);
          if (mailRegistrado.status === 201) {
            setDataMail(mailRegistrado.data);
            setIsSendedMail(true);
          } else {
            setDataMail(null);
            setIsSendedMail(false);
          }
          setIsLoading(false);
          Swal.fire({
            title: "Se envio con exito el mail",
            icon: "success"
          });
        })
      });
    } else {
      setIsLoading(false);
      Swal.fire({
        title: "El titular de la Agencia no posee mail",
        icon: "error"
      });
    }
  };

  const cargarMails = async () => {
    if (periodo) {
      const filters = {
        agencia: 'TODAS',
        periodo: dayjs(periodo).format("YYYY-MM-DD HH:mm:ssZ"),
      };
      var mails = [];
      setIsLoading(true)
      ConsultasAPI.ListarObjetos(
        URL_JUGADASMES + "noPag/",
        null,
        null,
        filters
      ).then(async (response) => {
        var datos = response.data;
        const promesas =
          datos
            .filter((dato) => dato.titularAgencia.titular)
            .map(async (dato) => {
              var mail = {
                mail: dato.titularAgencia.titular_detalle.persona_detalle.email,
                agencia: 'Ag: ' + dato.titularAgencia.agencia_detalle.numero_agencia + ' - Sub: ' + dato.titularAgencia.agencia_detalle.numero_subagencia,
                numero_agencia: dato.titularAgencia.agencia_detalle.numero_agencia,
                numero_subagencia: dato.titularAgencia.agencia_detalle.numero_subagencia,
              }
              const Envio = await enviarComisionesTodas(mail, ReactDOMServer.renderToStaticMarkup(
                <EmailComisionesHTML datos={periodo} />))
            })
        Promise.all(promesas)
          .then(async () => {
            setmailsAgencias(mails)
            setIsLoading(false)
          })
          .catch((error) => {
            console.error("Error al procesar las promesas:", error);
            setIsLoading(false)
            setLoading(false);
          });
      })
    }
  };

  const enviarComisionesTodas = async (mail, htmlString) => {
    if (mail) {
      const blob = new Blob([htmlString], { type: 'text/html' });
      const formData = new FormData();
      formData.append('file', blob, mail.mail);
      formData.append('agencia', mail.agencia);
      formData.append('periodo', moment(periodo, "MM/YYYY").format("MM/YYYY"));
      const response = await ConsultasAPI.CrearObjeto(URL_BASE + 'enviar_mail/', formData)
      if (response.status === 200) {
        const data = {
          agencia: mail.numero_agencia,
          subagencia: mail.numero_subagencia,
          mail: mail.mail,
          concepto: "Envio Comisiones",
          periodo: periodo.format('YYYY-MM-DD')
        }
        const mailRegistrado = await ConsultasAPI.CrearObjeto(URL_REGISTROMAIL, data);
        if (mailRegistrado.status === 201) {
          setDataMail(mailRegistrado.data);
          setIsSendedMail(true);
        } else {
          setDataMail(null);
          setIsSendedMail(false);
        };

      } else {
        setIsLoading(false);
        Swal.fire({
          title: "No se pudo realizar el envio del mail",
          icon: "error"
        });
      }
    };
  };

  return (
    <Container className="mainSection">
      <Row>
        <Col>
          <h1 className="py-4 fw-bold">Comisiones</h1>
        </Col>
      </Row>
      <Row>
        <Card>
          <Row>
            <div className="d-flex justify-content-center m-3">
              <h4 className="me-2">Periodo de Comisión:</h4>
              <div style={{ width: "13%", height: "32px" }}>
                <Datetime
                  isValidDate={valid}
                  timeFormat={false}
                  dateFormat="MM/YYYY"
                  inputProps={{
                    readOnly: true,
                    placeholder: periodo,
                  }}
                  value={periodo}
                  onChange={setPeriodo}
                />
              </div>
              <h4 className="me-2 ms-5">Agencia:</h4>
              <Select
                style={{ width: "13%", height: "37px" }}
                placeholder={agencias.length > 0 ? agencias[0].numero : "-"}
                value={agencia}
                onSelect={setAgencia}
                disabled={agencias < 1}
              >
                {agencias
                  .filter((agencia) => agencia.numero_subagencia === 0)
                  .map((agencia) => (
                    <Option key={agencia.id} value={agencia.numero_agencia}>
                      {agencia.numero}
                    </Option>
                  ))}
              </Select>
            </div>
            {periodo ?
              <Row style={{ display: 'flex', justifyContent: 'end' }}>
                <Button className="btn botonCPA my-3" onClick={cargarMails} style={{ width: 'fit-content', float: 'right' }}>Enviar Todas</Button>
              </Row> : null}
          </Row>
          {agencia && periodo && comisionesTabla.length === 0 ?
            <Row>
              <div className="d-flex justify-content-center m-3">
                <h4 className="me-2">
                  No se encontraron comisiones en este Periodo y/o Agencia
                </h4>
              </div>
            </Row> : (periodo || agencia) && comisionesTabla.length === 0 && !loading ? <Row>
              <div className="d-flex justify-content-center m-3">
                <h4 className="me-2">
                  Elija Periodo y Agencia
                </h4>
              </div>
            </Row> : null}
        </Card>
      </Row>
      {comisionesTabla.length > 0 ? (
        <Row style={{ height: 1200 }} className="my-4">
          {/* <EmailComisionesHTML datos={periodo}/> */}
          <PDFViewer>
            <Document>
              <EmailComisiones datos={comisionesTabla ? comisionesTabla : []} />
              <CondicionesPago datos={periodo} />
            </Document>
          </PDFViewer>
        </Row>
      ) : null}
      <Row>
        <section className="d-flex justify-content-end my-3">
          <Col className="d-flex justify-content-end my-3">
            <BtnVolver  />
          </Col>
          {comisionesTabla.length > 0 ? (
            <>
              <Col className="d-flex justify-content-center ">
                <PDFDownloadLink
                  className="PDF-Download"
                  document={
                    <Document orientation="landscape">
                      <EmailComisiones
                        datos={comisionesTabla ? comisionesTabla : []}
                      />
                      <CondicionesPago datos={periodo} />
                    </Document>
                  }
                  fileName={"Comisiones.pdf"}

                >
                  {({ loading }) => (
                    <>
                      <Button className="btn botonCPA my-3 ">
                        <BsFillFileEarmarkPdfFill className="mb-1" />
                        <strong>
                          {loading ? "Generando PDF..." : "Exportar a PDF"}
                        </strong>
                      </Button>

                    </>
                  )}
                </PDFDownloadLink>
              </Col>
              <Col style={{ width: "100%" }} >
                <BlobProvider document={
                  <Document>
                    <EmailComisiones
                      datos={comisionesTabla ? comisionesTabla : []}
                    />
                    <CondicionesPago datos={periodo} />
                  </Document>
                }
                  fileName={"Comisiones.pdf"}>
                  {({ blob,  loading }) => {
                    // Do whatever you need with blob here
                    return (
                      <Button className="btn botonCPA my-3 " onClick={() => enviarComisiones(blob)}>
                        <BsEnvelopePlusFill className="mb-1" />
                        <strong>
                          {loading ? "Generando PDF..." : (isSendedMail ? "Reenviar Mail" : "Enviar Mail")}
                        </strong>
                      </Button>
                    )
                  }}
                </BlobProvider>
              </Col>
            </>
          ) : null}
        </section>
        {dataMail ?
          <Row style={{ justifyContent: 'end', display: 'flex' }}>
            <Col md='3'>
              <button className="botonCPA" style={{ backgroundColor: 'white', color: 'red', borderColor: 'white' }}>Ya se envio mail el dia {dataMail.fecha_envio}</button>
            </Col>
          </Row>
          :
          null
        }
      </Row>
      <Modal centered show={isLoading} onHide={() => setIsLoading(false)}>
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Enviando Mail</p>
        </Modal.Body>
      </Modal>
      <Modal centered show={loading}>
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Cargando...</p>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default EnviarComisiones;
