import React from "react";
import OpcionInicio from "../OpcionInicio";
import { Container, Card, Row, Col } from "react-bootstrap";
import BtnVolver from "../../common/BtnVolver";

const MenuLiquidacion = (props) => {
  const rolUser = props.rolUsuario;
  return (
    <>
      <Container className="mainSection" style={{marginTop:'60px'}}>
        <Row className="auto mt-5" >
          {rolUser === "ADMINISTRADOR" ||
            rolUser === "CONTADURIA" ||
            rolUser === "GERENCIA-QUINIELA" ||
            rolUser === "OPERADOR-EXTRACTO" ? (
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <OpcionInicio
                imagen={require("../../../img/MenuPrincipal/MenuPrincipal2/sorteo.png")}
                texto={"Sorteos"}
                ruta={"sorteos"}
              />
            </Col>
          ) : null}
          {rolUser === "ADMINISTRADOR" ||
            rolUser === "DOCUMENTACION" ||
            rolUser === "SUCURSAL-CONCEPCION" ||
            rolUser === "GERENCIA-QUINIELA" || rolUser === "CONTADURIA" ? (
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <OpcionInicio
                imagen={require("../../../img/MenuPrincipal/MenuPrincipal2/rendiciones.png")}
                texto={"Rendiciones"}
                ruta={"rendiciones"}
              />
            </Col>
          ) : null}
          {rolUser === "ADMINISTRADOR" ||
            rolUser === "GERENCIA-QUINIELA" ||
            rolUser === "AUDITORIA" || rolUser === "CONTADURIA" ? (
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <OpcionInicio
                imagen={require("../../../img/MenuPrincipal/MenuPrincipal2/comisiones.png")}
                texto={"Comisiones"}
                ruta={"comisiones"}
              />
            </Col>
          ) : null}
        </Row>
        <BtnVolver route = '/' style={{ float: "right", marginTop: 120 }} />
      </Container>
    </>
  );
};

export default MenuLiquidacion;
