import React, { useState, useEffect, useMemo, useRef } from 'react';
import BtnVolver from '../../common/BtnVolver';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { Table, Select, Form } from 'antd';
import moment from 'moment';
import ExcelReader from '../exportaciones/ExcelReader.jsx';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Swal from 'sweetalert2';
import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import AuthenticationHelper from '../../../shared/helpers/authenticationHelper';
import Notificaciones from '../../../shared/helpers/notificacionesToast';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import MaterialReactTable from 'material-react-table';
import { darken } from '@mui/material';
import { FaEraser, FaPlus, FaFileDownload } from 'react-icons/fa';

const FormItem = Form.Item;

const SorteosExceptuados = (props) => {
    const rolUser = props.rolUsuario;
    const URL_TIPOSORTEO = window.API_ROUTES.TIPOSORTEO;
    const URL_SORTEOEXCEPTUADO = window.API_ROUTES.SORTEOEXCEPTUADO;

    const [tipo, setTipo] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [fecha, setFecha] = useState({
        fechaMuestra: moment().format('DD/MM/YYYY'),
        fechaComparar: moment().format('YYYY-MM-DD'),
    });
    const [fechaExceptuada, setFechaExceptuada] = useState({
        fechaMuestra: null,
        fechaComparar: null,
    });
    var currentTime = moment().utcOffset('-03:00').format('HH:mm');
    var currentDate = moment().utcOffset('-03:00').format('YYYY-MM-DD');
    const [pendiente, setPendiente] = useState(true);

    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [data, setData] = useState([]);

    //Carga los tipos de sorteo en la lista desplegable dependiendo de la hora final del sorteo y la actual, y tambien del estado de los sorteo
    const cargarTipo = () => {
        setTipos([]);
        if (
            AuthenticationHelper.getRol() &&
            (rolUser === 'ADMINISTRADOR' || rolUser === 'GERENCIA-QUINIELA')
        ) {
            ConsultasAPI.ListarObjetos(URL_TIPOSORTEO).then((response) => {
                const objetosOrdenados = ordenarEnRango(
                    response.data.results.filter(
                        (tipo) => tipo.estado === true
                    ),
                    currentTime,
                    '23:59'
                );
                setTipos(objetosOrdenados);
            });
        } else {
            ConsultasAPI.ListarObjetos(URL_TIPOSORTEO).then((response) => {
                const objetosOrdenados = ordenarEnRango(
                    response.data.results.filter(
                        (tipo) => tipo.estado === true
                    ),
                    currentTime,
                    '23:59'
                );
                setTipos(objetosOrdenados);
            });
        }
    };

    const cargarSorteosExceptuados = () => {
        ConsultasAPI.ListarObjetos(
            URL_SORTEOEXCEPTUADO,
            pagination.pageIndex,
            pagination.pageSize,
            columnFilters,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            fechaExceptuada.fechaComparar
        ).then((response) => {
            let datosTabla = [];
            setCount(response.data.count);
            response.data.results.forEach((sorteoExceptuado) => {
                let sorteo = {
                    email: sorteoExceptuado.usuario,
                    fecha_exceptuada: sorteoExceptuado.fecha_exceptuada,
                    tipo: sorteoExceptuado.tipo_detalle.tipo,
                };
                datosTabla.push(sorteo);
            });
            setData(datosTabla);
        });
    };

    const ordenarEnRango = (t, horaMin, horaMax) => {
        const objetosOrdenados = t
            .filter((tipo) => tipo.estado === true)
            .sort((a, b) => {
                const horaA = moment(a.hora_sorteo, 'hh:mm:ss').format('HH:mm');
                const horaB = moment(b.hora_sorteo, 'hh:mm:ss').format('HH:mm');
                // Verificar si las horas están dentro del rango
                const horaDentroDelRango = (hora) =>
                    hora >= horaMin && hora <= horaMax;

                // Si las dos horas están dentro del rango o fuera del rango, no se realiza ningún cambio
                if (horaDentroDelRango(horaA) === horaDentroDelRango(horaB)) {
                    if (horaA < horaB) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                // Si a está dentro del rango pero b está fuera, se coloca a antes en la ordenación
                if (horaDentroDelRango(horaA)) {
                    return -1;
                }
                // Si b está dentro del rango pero a está fuera, se coloca b antes en la ordenación
                return 1;
            });
        return objetosOrdenados;
    };

    useEffect(() => {
        cargarSorteosExceptuados();
    }, [pagination, columnFilters, fechaExceptuada]);

    useEffect(() => {
        cargarTipo();
    }, []);

    const handleFechaChange = (momentDate) => {
        const fechaMuestra = momentDate.format('DD/MM/YYYY');
        const fechaComparar = momentDate.format('YYYY-MM-DD');
        setFecha({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
        cargarTipo();
    };

    //Maneja el cambio de la variable tipos
    const hadleTipoChange = (tipo) => {
        setTipo(tipos.filter((x) => x.id === tipo)[0]);
    };

    //Carga el extracto seleccionado en la tabla, trae los datos desde el archivo .csv al front

    //Guarda el archivo en la base de datos, pasa los datos al back
    const subirSorteoExceptuado = () => {
        Swal.fire({
            title: '¿Estás seguro de crear el Sorteo Exceptuado?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                const sorteoExceptuado = {
                    fecha_exceptuada: fecha.fechaComparar,
                    tipo: tipo.id,
                };
                ConsultasAPI.CrearObjeto(URL_SORTEOEXCEPTUADO, sorteoExceptuado)
                    .then((response) => {
                        if (response.status === 201) {
                            cargarSorteosExceptuados();
                            Swal.fire(
                                'Subida exitosa',
                                'El sorteo se creo con exito',
                                'success'
                            );
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 500) {
                            Swal.fire(
                                'Sorteo Exceptuado Repetido',
                                'Ya existe el sorteo exceptuado que desea crear',
                                'error'
                            );
                        } else {
                            Swal.fire(
                                'Error en Subida',
                                'No se pudo crear el sorteo exceptuado',
                                'error'
                            );
                        }
                    });
            } else {
            }
        });
    };

    const columns = useMemo(() => [
        {
            header: 'Usuario',
            accessorKey: 'email',
            size: 140,
        },
        {
            header: 'Fecha Exceptuada',
            accessorKey: 'fecha_exceptuada',
            enableColumnFilter: false,
            size: 50,
        },
        {
            header: 'Tipo Sorteo',
            accessorKey: 'tipo',
            size: 140,
        },
    ]);

    const datetimeRefExceptuada = useRef(null);
    const handleFechaExceptuadaChange = (momentDate) => {
        const fechaMuestra = momentDate.format('DD/MM/YYYY');
        const fechaComparar = momentDate.format('YYYY-MM-DD');
        setFechaExceptuada({
            fechaMuestra: fechaMuestra,
            fechaComparar: fechaComparar,
        });
    };

    const handleLimpiarFecha = (momentDate) => {
        setFechaExceptuada({ fechaMuestra: null, fechaComparar: null });
        datetimeRefExceptuada.current.setState({ inputValue: '' });
    };
    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Sorteo Exceptuado</h1>
                </Col>
            </Row>
            <Card>
                <Card.Header>
                    <Card.Title>Subir Sorteo Exceptuado</Card.Title>
                </Card.Header>
                <Form>
                    <Col>
                        <Row>
                            <div className="d-flex justify-content-center m-3">
                                <h4 className="me-2">Fecha:</h4>
                                <FormItem
                                    name="Fecha"
                                    style={{ width: '23%', height: '32px' }}
                                >
                                    {AuthenticationHelper.getRol() &&
                                    (AuthenticationHelper.getRol() ===
                                        'ADMINISTRADOR' ||
                                        AuthenticationHelper.getRol() ===
                                            'GERENCIA-QUINIELA') ? (
                                        <Datetime
                                            timeFormat={false}
                                            style={{
                                                width: '100%',
                                                height: '32px',
                                            }}
                                            dateFormat="DD/MM/YYYY"
                                            inputProps={{
                                                readOnly: true,
                                                placeholder: fecha.fechaMuestra,
                                            }}
                                            value={fecha.fechaMuestra}
                                            onChange={handleFechaChange}
                                        />
                                    ) : (
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/YYYY"
                                            renderInput={(
                                                props,
                                                openCalendar,
                                                closeCalendar
                                            ) => (
                                                <input
                                                    {...props}
                                                    readOnly
                                                    placeholder={
                                                        fecha.fechaMuestra
                                                    }
                                                    onFocus={openCalendar}
                                                    onBlur={closeCalendar}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            )}
                                            open={false}
                                            value={fecha.fechaMuestra}
                                            onChange={handleFechaChange}
                                        />
                                    )}
                                </FormItem>
                                <h4 className="me-2 ms-5">Tipo:</h4>
                                <FormItem
                                    name="Tipo"
                                    style={{ width: '23%', height: '37px' }}
                                >
                                    <Select
                                        style={{
                                            width: '100%',
                                            height: '37px',
                                        }}
                                        placeholder={
                                            'Sorteo ' +
                                            (tipos.length > 0
                                                ? tipos[0].tipo
                                                : '-')
                                        }
                                        value={tipo}
                                        onSelect={hadleTipoChange}
                                    >
                                        {tipos.map((tipo) => (
                                            <Select.Option
                                                key={tipo.id}
                                                value={tipo.id}
                                            >
                                                Sorteo {tipo.tipo}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </FormItem>
                                <FormItem
                                    name="Tipo"
                                    style={{ width: '23%', height: '37px' }}
                                >
                                    <button
                                        className="btn botonCPA ms-4"
                                        variant="contained"
                                        onClick={subirSorteoExceptuado}
                                    >
                                        <FaPlus className="iconAntD" /> Subir
                                        Sorteo Exceptuado
                                    </button>
                                </FormItem>
                            </div>
                        </Row>
                    </Col>
                </Form>
            </Card>
            <Card style={{ marginTop: '1%' }}>
                <Card.Header>
                    <Card.Title>Sorteos Exceptuados</Card.Title>
                </Card.Header>
                <div className="d-flex justify-content-center m-3">
                    <h4 className="me-2">Fecha Exceptuada:</h4>
                    <FormItem
                        name="Fecha"
                        style={{ width: '23%', height: '32px' }}
                    >
                        <Datetime
                            timeFormat={false}
                            style={{ width: '100%', height: '32px' }}
                            dateFormat="DD/MM/YYYY"
                            inputProps={{
                                readOnly: true,
                                placeholder: 'Elegir fecha',
                            }}
                            ref={datetimeRefExceptuada}
                            value={null}
                            onChange={handleFechaExceptuadaChange}
                        />
                    </FormItem>
                    <FormItem
                        name="Tipo"
                        style={{ width: '23%', height: '37px' }}
                    >
                        <button
                            className="btn botonCPA ms-4"
                            variant="contained"
                            onClick={handleLimpiarFecha}
                        >
                            <FaEraser className="iconAntD" />
                            Limpiar Filtro
                        </button>
                    </FormItem>
                </div>

                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={data}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: '0',
                            border: '1px dashed #e0e0e0',
                        },
                    }}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                    theme.palette.background.default,
                                    0.1
                                ),
                            },
                            fontFamily: 'Roboto, sans-serif', // Configuración de la tipografía para las filas pares
                        }),
                    }}
                    initialState={{
                        columnVisibility: { id: false },
                        showColumnFilters: true,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    // enableEditing
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableHiding={false}
                    enableSorting={true}
                    manualPagination
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    localization={MRT_Localization_ES}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                    manualFiltering //turn off client-side filtering
                    onColumnFiltersChange={(value) => {
                        setColumnFilters(value);
                    }} //hoist internal columnFilters state to your state
                    manualSorting
                    onSortingChange={setSorting}
                    state={{
                        columnFilters,
                        pagination,
                        sorting,
                    }}
                />
            </Card>
            <section className="d-flex justify-content-end my-3">
                <BtnVolver route="/extractos" />
            </section>
        </Container>
    );
};
export default SorteosExceptuados;
