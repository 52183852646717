import React, { useState, useEffect, useMemo } from "react";
import { Container, Col, Row, InputGroup, Card, Form, Spinner, Modal, Button } from "react-bootstrap";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import { darken } from "@mui/material";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import moment from "moment";
import * as XLSX from "xlsx";
import { FaFileDownload } from "react-icons/fa";

const ModalResumen = (props) => {
    const URL_REGISTROMAIL = window.API_ROUTES.REGISTROMAIL
    const [columnFilters, setColumnFilters] = useState([]);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [count, setCount] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 10,
        },
        {
            header: "N° Agencia",
            accessorKey: "numero_agencia",
            size: 10,
        },
        {
            header: "Titular",
            accessorKey: "titular",
            size: 100,
        },
        {
            header: "Cuit",
            accessorKey: "cuit",
            size: 50,
        },
        {
            header: "Fecha Envio Comision Bruta",
            accessorKey: "fecha_envio",
            size: 50,
        },
        {
            header: "Periodo Comision",
            accessorKey: "periodo",
            size: 50,
        },
        {
            header: "Estado de Comision",
            accessorKey: "estado_comision",
            size: 50,
        },
        {
            header: "Fecha de Generación",
            accessorKey: "fecha_generacion",
            size: 50,
        },
        {
            header: "Fecha de Pago",
            accessorKey: "fecha_pago",
            size: 50,
        },
    ]);

    useEffect(() => {
        if (props.fechaDesde && props.fechaHasta && props.show) {
            getData()
        }
    }, [props.show, pagination])

    const getData = async () => {
        setIsLoading(true);
        let filter = {
            concepto: 'Envio Comisiones',
            fechas: props.fechaDesde + '-' + props.fechaHasta,
            agencias: props.agenciaDesde.label + '-' + props.agenciaHasta.label,
        }
        const response = await ConsultasAPI.ListarObjetos(URL_REGISTROMAIL + 'listReport/', pagination.pageIndex, pagination.pageSize, filter, true);
        let vector_data = []
        if (response.status === 200) {

            response.data.results.forEach(registro => {
                let data = {
                    id: registro.id,
                    numero_agencia: registro.agencia_detalle.numero_agencia.toString().padStart(2, '0') + registro.agencia_detalle.numero_subagencia.toString().padStart(3, '0'),
                    titular: registro.titular.persona_detalle.apellido + ' ' + registro.titular.persona_detalle.nombre,
                    cuit: registro.titular.persona_detalle.cuit,
                    fecha_envio: registro.fecha_envio,
                    periodo: moment(registro.periodo, 'DD/MM/YYYY').format('MMMM YYYY'),
                    estado_comision: registro.orden_pago ? registro.orden_pago.estado_comision : '-',
                    fecha_generacion: registro.orden_pago && (registro.orden_pago.estado_comision === 'Generada' || registro.orden_pago.estado_comision === 'Pagada') ? registro.orden_pago.fecha_facturacion : '-',
                    fecha_pago: registro.orden_pago && registro.orden_pago.estado_comision === 'Pagada' ? registro.orden_pago.fecha_modificacion : '-',
                }
                vector_data.push(data);
            });
            setData(vector_data.sort((a, b) => a.numero_agencia !== b.numero_agencia ? a.numero_agencia - b.numero_agencia : a.numero_subagencia - b.numero_subagencia));
            setCount(response.data.count);
        }
        setIsLoading(false);
    }

    const exportToExcel = async () => {
        setIsLoading(true);
        let filter = {
            concepto: 'Envio Comisiones',
            fechas: props.fechaDesde + '-' + props.fechaHasta,
            agencias: props.agenciaDesde.label + '-' + props.agenciaHasta.label,
        }
        const response = await ConsultasAPI.ListarObjetos(URL_REGISTROMAIL + 'listReport/', pagination.pageIndex, pagination.pageSize, filter, false);
        let vector_data = []
        if (response.status === 200) {
            response.data.results.forEach(registro => {
                let data = {
                    numero_agencia: registro.agencia_detalle.numero_agencia.toString().padStart(2, '0') + registro.agencia_detalle.numero_subagencia.toString().padStart(3, '0'),
                    titular: registro.titular.persona_detalle.apellido + ' ' + registro.titular.persona_detalle.nombre,
                    cuit: registro.titular.persona_detalle.cuit,
                    fecha_envio: registro.fecha_envio,
                    periodo: moment(registro.periodo, 'DD/MM/YYYY').format('MMMM YYYY'),
                    estado_comision: registro.orden_pago ? registro.orden_pago.estado_comision : '-',
                    fecha_generacion: registro.orden_pago && (registro.orden_pago.estado_comision === 'Generada' || registro.orden_pago.estado_comision === 'Pagada') ? registro.orden_pago.fecha_facturacion : '-',
                    fecha_pago: registro.orden_pago && registro.orden_pago.estado_comision === 'Pagada' ? registro.orden_pago.fecha_modificacion : '-',
                }
                vector_data.push(data);
            });
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(vector_data.sort((a, b) => a.numero_agencia !== b.numero_agencia ? a.numero_agencia - b.numero_agencia : a.numero_subagencia - b.numero_subagencia).flat());
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(
                workbook,
                "Reporte envio comisiones.xlsx"
            );
        }
        setIsLoading(false);
    }

    const handleCloseModal = () => {
        setData([]);
        setCount(0);
        props.close()
    };


    return (
        <Container>
            <Modal show={props.show} size="xl" >
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>Reporte por Agencia Resumen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.fechaDesde && props.fechaHasta  ?
                        <></>
                        :
                        <h4 className="me-2" style={{ color: 'red' }}>Deben seleccionar los periodos para obtener los registros</h4>
                    }
                    <MaterialReactTable
                        className="w-100"
                        columns={columns}
                        data={data}
                        muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: "0",
                                border: "1px dashed #e0e0e0",
                            },
                        }}
                        muiTableBodyProps={{
                            sx: (theme) => ({
                                "& tr:nth-of-type(odd)": {
                                    backgroundColor: darken(theme.palette.background.default, 0.1),
                                },
                                fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                            }),
                        }}
                        initialState={{
                            columnVisibility: { id: false },
                            showColumnFilters: true,
                        }} //hide firstName column by default
                        editingMode="modal" //default
                        enableRowSelection={false} //enable some features
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableColumnActions={false}
                        enableSorting={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        manualPagination
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10],
                        }}
                        enablePagination={true}
                        localization={MRT_Localization_ES}
                        rowCount={count}
                        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                        enableGlobalFilter={false} //turn off a feature
                        manualFiltering //turn off client-side filtering
                        onColumnFiltersChange={(value) => {
                            setColumnFilters(value);
                        }} //hoist internal columnFilters state to your state
                        state={{
                            columnFilters,
                            pagination,
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn botonCPA me-3" onClick={exportToExcel}>
                        <FaFileDownload className="iconAntD" />
                        Descargar Excel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={isLoading}
            >
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando Datos...</p>
                </Modal.Body>
            </Modal>
        </Container>
    )
};
export default ModalResumen;
