import React, { useMemo, useState, useEffect } from "react";
import BtnVolver from "../../common/BtnVolver";
import Datetime from 'react-datetime';
import { Modal, Form, Container, Col, Row, Card, Spinner, Button } from "react-bootstrap";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import { Link } from "react-router-dom";
import { FaEraser, FaFileDownload, FaPlusCircle, FaList } from "react-icons/fa";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { darken } from "@mui/material";
import { IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerDeducciones from "./VerDeducciones";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import Swal from 'sweetalert2';

const ArchivoDeducciones = () => {

    const URL_ARCHIVODEDUCCION = window.API_ROUTES.ARCHIVODEDUCCION;

    const [data, setData] = useState([]);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [paginationDeducciones, setPaginationDeducciones] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFiltersDeducciones, setColumnFiltersDeducciones] = useState([]);
    const [showModalVerDeducciones, setShowModalVerDeducciones] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [dataDeducciones, setDataDeducciones] = useState([])
    const [tipoDeduccion, setTipoDeduccion] = useState([])
    const [archivo_id, setArchivoId] = useState([])
    const [motivo, setMotivo] = useState([]);
    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50
        },
        {
            header: "Deduccion",
            accessorKey: "deduccion",
            size: 50
        },
        {
            header: "Fecha de Creacion",
            accessorKey: "fecha_creacion",
            size: 50
        },
        {
            header: "Archivo",
            accessorKey: "archivo",
            size: 50
        },
    ]);

    const columnsDeducciones = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50
        },
        {
            header: "Apellido",
            accessorKey: "titular_detalle.titular_detalle.persona_detalle.apellido",
            size: 50
        },
        {
            header: "Nombre",
            accessorKey: "titular_detalle.titular_detalle.persona_detalle.nombre",
            size: 50
        },
        {
            header: "Cuit",
            accessorKey: "titular_detalle.titular_detalle.persona_detalle.cuit",
            size: 50
        },
        {
            header: "N° Agencia",
            accessorKey: "titular_detalle.agencia_detalle.numero_agencia",
            size: 50
        },
        {
            header: "N° Subagencia",
            accessorKey: "titular_detalle.agencia_detalle.numero_subagencia",
            size: 50
        },
        {
            header: "Importe",
            accessorKey: "importe",
            size: 50
        },
        {
            header: "Periodo",
            accessorKey: "periodo",
            size: 50
        },
    ]);

    useEffect(() => {
        cargarArchivosDeducciones();
    }, [pagination, columnFilters]);

    const cargarArchivosDeducciones = async () => {
        setIsLoading(true)
        const deducciones = await ConsultasAPI.ListarObjetos(URL_ARCHIVODEDUCCION,
            pagination.pageIndex,
            pagination.pageSize,
            columnFilters,
        );
        const data = []
        deducciones.data.results.forEach(archivo => {
            const nombre_archivo = archivo.archivo_detalle.path.split("/")[archivo.archivo_detalle.path.split("/").length - 1]
            const d = {
                id: archivo.id,
                archivo: ((<a
                    href={archivo.archivo_detalle.path}
                    target="blank"
                >
                    <IconButton size={"small"}>
                        {nombre_archivo}
                    </IconButton>
                </a>)),
                deduccion: archivo.deduccion_detalle.tipo_deduccion_detalle.descripcion,
                fecha_creacion: archivo.fecha_creacion,
            }
            data.push(d)
        });
        setCount(deducciones.data.count)
        setData(data)
        setIsLoading(false)
    }

    const verDeduccionesArchivo = async (archivoId) => {
        setIsLoading(true)
        const deducciones = await ConsultasAPI.ObtenerObjeto(URL_ARCHIVODEDUCCION + 'obtenerDeducciones/', archivoId);
        setArchivoId(archivoId)
        if (deducciones.status === 200) {
            setDataDeducciones(deducciones.data)
            setTipoDeduccion(deducciones.data[0].tipo_deduccion_detalle.descripcion)
            handleOpenModal()
            setIsLoading(false)
        } else {
            setIsLoading(false)
        };
    };

    const handleOpenModal = () => {
        setShowModalVerDeducciones(true)
    }
    const handleCloseModal = () => {
        setMotivo("");
        setShowModalVerDeducciones(false)
    }
    const borrarArchivo = async (event) => {
        event.preventDefault()
        try {
            const deducciones = await ConsultasAPI.ModificarObjeto(URL_ARCHIVODEDUCCION + 'borrarArchivoDeducciones/', archivo_id, { 'motivo': motivo });
            if (deducciones.status === 204) {
                Swal.fire(
                    'Error',
                    'No se puede borrar las deducciones de este archivo porque las OP ya se encuentran Generadas y/o Pagadas',
                    'error'
                );
                handleCloseModal()
            } else if (deducciones.status === 200) {
                Swal.fire(
                    'Eliminacion Correcta',
                    'Se dieron de baja exitosamente las deducciones de este archivo',
                    'success'
                );
                handleCloseModal()
            } else {
                Swal.fire(
                    'Error',
                    'No se pudo borrar el archivo',
                    'error'
                );
                handleCloseModal()
            }
        } catch (error) {
            console.error(error);
            Swal.fire(
                'Error',
                'No se pudo borrar el archivo',
                'error'
            );
            handleCloseModal()
        }

    }
    return (
        <Container fluid className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Archivos de Deducciones</h1>
                </Col>
                <Col className="d-flex justify-content-end m-3">
                    <Link
                        className="btn botonCPA me-3"
                        to={window.location + '/NuevoArchivo'}
                        style={{ alignContent: 'center', margin: 15, float: 'right' }}
                    >
                        <FaPlusCircle className="iconAntD" />
                        Nuevo Archivo
                    </Link>
                </Col>
            </Row>
            <Card className="m-3">
                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={data}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: "0",
                            border: "1px dashed #e0e0e0",
                        },
                    }}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            "& tr:nth-of-type(odd)": {
                                backgroundColor: darken(theme.palette.background.default, 0.1),
                            },
                            fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                        }),
                    }}
                    initialState={{
                        columnVisibility: { id: false },
                        showColumnFilters: true,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableSorting={false}
                    displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                        <div className="d-flex">
                            <IconButton onClick={() => { verDeduccionesArchivo(row.original.id) }}>
                                <VisibilityIcon />
                            </IconButton>
                        </div>
                    )}
                    manualPagination
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    localization={MRT_Localization_ES}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                    manualFiltering //turn off client-side filtering
                    onColumnFiltersChange={(value) => {
                        setColumnFilters(value);
                    }} //hoist internal columnFilters state to your state
                    state={{
                        columnFilters,
                        pagination,
                    }}
                />
            </Card>
            <Row className="text-center">
                <section className="d-flex justify-content-end my-3">
                    <BtnVolver />
                </section>
            </Row>
            <Modal
                show={showModalVerDeducciones}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>Deducciones del Archivo - {tipoDeduccion}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        AuthenticationHelper.getRol() === "ADMINISTRADOR" || AuthenticationHelper.getRol() === "GERENCIA-QUINIELA" || AuthenticationHelper.getRol() === "CONTADURIA" ?
                            <Form style={{ display: 'flex' }} onSubmit={borrarArchivo}>
                                <Form.Group as={Col} md="10">
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={motivo}
                                        placeholder="Motivo por el cual quiere borrar el archivo"
                                        required
                                        onChange={(event) => { setMotivo(event.target.value) }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2" style={{ alignContent: 'center' }}>
                                    <button
                                        className="btn botonCPA me-3"
                                        style={{ alignContent: 'center', marginLeft: '7%' }}
                                        type="submit"
                                    >
                                        Borrar Archivo
                                    </button>
                                </Form.Group>
                            </Form>
                            :
                            null
                    }
                    <MaterialReactTable
                        className="w-100"
                        columns={columnsDeducciones}
                        data={dataDeducciones}
                        muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: "0",
                                border: "1px dashed #e0e0e0",
                            },
                        }}
                        muiTableBodyProps={{
                            sx: (theme) => ({
                                "& tr:nth-of-type(odd)": {
                                    backgroundColor: darken(theme.palette.background.default, 0.1),
                                },
                                fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                            }),
                        }}
                        initialState={{
                            columnVisibility: { id: false },
                            showColumnFilters: true,
                        }} //hide firstName column by default
                        editingMode="modal" //default
                        enableRowSelection={false} //enable some features
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableColumnActions={false}
                        enableSorting={false}
                        displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10],
                        }}
                        enablePagination={true}
                        localization={MRT_Localization_ES}
                        // rowCount={count}
                        onPaginationChange={setPaginationDeducciones}
                        enableGlobalFilter={false}
                        onColumnFiltersChange={(value) => {
                            setColumnFiltersDeducciones(value);
                        }}
                        state={{
                            columnFilters: columnFiltersDeducciones,
                            pagination: paginationDeducciones,
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando Archivo...</p>
                </Modal.Body>
            </Modal>
        </Container >
    );
};

export default ArchivoDeducciones;
