/* eslint-disable */
import React, { useCallback, useMemo, useState, useEffect } from "react";
import BtnVolver from "../../common/BtnVolver";
import { Modal, Form, Button } from "react-bootstrap";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import getNombreEstado from "../../../shared/helpers/estados";
import Notificaciones from "../../../shared/helpers/notificacionesToast";

export function ModalUsuario(props) {
  const URL_USUARIOS = window.API_ROUTES.USUARIOS;
  const URL_ROLES = window.API_ROUTES.ROLES;
  const [roles, setRoles] = useState([]);
  const [apellido, setApellido] = useState("");
  const [nombre, setNombre] = useState("");
  const [legajo, setLegajo] = useState(null);
  const [documento, setDocumento] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [confirmar_password, setConfirmarPassword] = useState("");
  const [isConfirmarPasswordVisible, setIsConfirmarPasswordVisible] =
    useState(false);
  const [rol, setRol] = useState();
  const [estado, setEstado] = useState("");
  const [message, setMessage] = useState("");
  const {
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    setApellido(props.usuario ? props.usuario.apellido : "");
    setNombre(props.usuario ? props.usuario.nombre : "");
    setLegajo(props.usuario ? props.usuario.legajo : "");
    setEmail(props.usuario ? props.usuario.email : "");
    setDocumento(props.usuario ? props.usuario.documento : "");
    setRol(props.usuario?.rol ? props.usuario.rol : "");
    setEstado(props.usuario?.estado ? props.usuario.estado : "Activo");
  }, [props.show]);

  useEffect(() => {
    try {
      ConsultasAPI.ListarObjetos(URL_ROLES, 0).then((respuesta) => {
        if (respuesta.status === 200) {
          let roles = respuesta.data
          roles = roles.filter(rol => rol.descripcion !== "ADMINISTRADOR")
          setRoles(roles);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible);
  };
  const toggleConfirmarPasswordVisibility = () => {
    setIsConfirmarPasswordVisible(
      (prevIsPasswordVisible) => !prevIsPasswordVisible
    );
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!props.usuario) {
      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*[\W_\x7B-\xFF]).{8,}$/;
      const letrasRegex = /^[A-Za-záéíóúÁÉÍÓÚñÑ\s]+$/; // Expresión regular para letras y espacios

      if (!letrasRegex.test(nombre) || !letrasRegex.test(apellido)) {
        setMessage({
          text: "El nombre y apellido deben contener solo letras.",
          variant: "danger",
        });
        setTimeout(() => {
          setMessage(null);
        }, 7000);
        return;
      }
    }

    const form = event.target;
    const formData = new FormData(form);
    const newUsuario = {};
    formData.forEach((value, key) => {
      if (key !== "confirmar_password") {
        if (key !== "is_active") {
          newUsuario[key] = value; // al modificar un usuario, no se puede activarlo o desactivarlo (boton solo para tal funcion)
        }
      }
    });
    if(newUsuario['legajo'] === ""){
      newUsuario['legajo']=null;
    }
    try {
      if (props.usuario) {
        const response = await ConsultasAPI.ModificarObjeto(
          URL_USUARIOS,
          props.usuario.id,
          newUsuario
        );
        if (response.status === 200) {
          Swal.fire({
            title: "Usuario modificado",
            text: "El usuario fue modificado con éxito",
            icon: "success",
            onOpen: () => {
              // Establecer el enfoque en la ventana Swal
              Swal.setFocus();
            },
          });
        }
      } else {
        newUsuario["password"] = newUsuario.documento;
        const response = await ConsultasAPI.CrearObjeto(
          URL_USUARIOS,
          newUsuario
        ).catch((error) => {
          if (error.response.data.documento) {
            Notificaciones.notificacion(
              "Ya existe un usuario con este documento"
            );
          }
          if (error.response.data.email) {
            Notificaciones.notificacion(
              "Ya existe un usuario con este email"
            );
          }
          if (error.response.data.legajo) {
            Notificaciones.notificacion("Ya existe un usuario con este legajo");
          };
        });
        if (response.status === 201) {
          Swal.fire({
            title: "Usuario creado",
            text: "El usuario fue creado con éxito",
            icon: "success",
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                Swal.clickConfirm();
              }
            },
          });
        }
      }
      // obtener todos los oficinas actuales y actualizar el state oficinas
      ConsultasAPI.ListarObjetos(URL_USUARIOS, 0)
        .then((response) => {
          let usuarios = [];
          response.data.results.map((usuario) => {
            usuario.is_active = getNombreEstado(usuario.is_active);
            usuarios.push(usuario);
          });
          props.setTableData(usuarios);
        })
        .catch((err) => console.error(err));

      // cerrar el modal de creación
      props.onClose();

      // resetear el formulario
      setEmail("");
      setPassword("");
      setConfirmarPassword("");
    } catch (error) {
      console.error(error);
      if (error.response.data.error) {
        Swal.fire("Ocurrio un error", error.response.data.error.split('DETAIL:')[1].split(').')[0]+').', "error");
      } else {
        if (
          error.response.data &&
          error.response.data.email[0] ===
          "Ya existe un/a Usuario con este/a Dirección de email."
        ) {
          Swal.fire(
            "Ocurrio un error",
            "Ya existe un Usuario con esta dirección de email.",
            "error"
          );
        }
      }
    }
  };

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter

      // Encuentra el índice del campo actual dentro del formulario
      const formControls = event.target.form.elements;
      const currentIndex = Array.from(formControls).indexOf(event.target);

      // Encuentra el siguiente campo y enfócalo
      const nextControl = formControls[currentIndex + 1];
      if (nextControl) {
        nextControl.focus();
      }
    }
  };

  return (
    <Modal show={props.show}>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title>{props.nombre}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
        <Modal.Body>
          <Form.Group controlId="apellido" className="mb-3">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              type="text"
              name="apellido"
              autoComplete="off"
              value={apellido}
              onChange={(event) => {
                if(event.target.value.trim() !== ''){
                  setApellido(event.target.value);
                }else{
                  setApellido('');
                }
              }}
              required
            />
          </Form.Group>
          <Form.Group controlId="nombre" className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="nombre"
              autoComplete="off"
              value={nombre}
              onChange={(event) => {
                if(event.target.value.trim() !== ''){
                  setNombre(event.target.value);
                }else{
                  setNombre('');
                }
              }}
              required
            />
          </Form.Group>
          <Form.Group controlId="legajo" className="mb-3">
            <Form.Label>Legajo (si es empleado CPA) </Form.Label>
            <Form.Control
              type="text"
              name="legajo"
              autoComplete="off"
              value={legajo}
              onChange={(event) => setLegajo(event.target.value)}
              onKeyPress={(event) => {
                // Solo permitir números
                const regex = /[0-9]/;
                const key = String.fromCharCode(event.charCode);
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Group>
          <Form.Group controlId="documento" className="mb-3">
            <Form.Label>Documento</Form.Label>
            <Form.Control
              type="text"
              name="documento"
              autoComplete="off"
              value={documento}
              onChange={(event) => setDocumento(event.target.value)}
              onKeyPress={(event) => {
                // Solo permitir números
                const regex = /[0-9]/;
                const key = String.fromCharCode(event.charCode);
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              }}
              required
            />
          </Form.Group>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              autoComplete="off"
              value={email}
              onChange={(event) => {
                if(event.target.value.trim() !== ''){
                  setEmail(event.target.value);
                }else{
                  setEmail('');
                }
              }}
              required
            />
          </Form.Group>
          <Form.Group controlId="rol" className="mb-3">
            <Form.Label>Rol</Form.Label>
            <Form.Control
              name="rol"
              as="select"
              value={rol}
              onChange={(event) => setRol(event.target.value)}
            >
              {roles.map((rol) => (
                <option key={rol.id} value={rol.id}>
                  {rol.descripcion}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {message && (
            <div className={`alert alert-${message.variant} my-4`} role="alert">
              {message.text}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="botonCPA" onClick={handleClose}>
            Cancelar
          </Button>
          <Button className="botonCPA" type="submit">
            Aceptar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
