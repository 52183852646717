import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, Button, Form } from "react-bootstrap";
// import { Select } from "antd";
import Datetime from "react-datetime";
import { FaPlus } from "react-icons/fa";
import * as XLSX from "xlsx";
import "react-datetime/css/react-datetime.css";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import BtnVolver from "../../../common/BtnVolver";
import Parser from "../../../common/Parser";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import { AiOutlineUpload } from "react-icons/ai";
import { FaEraser } from "react-icons/fa";
// import Notificaciones from "../../../../shared/helpers/notificacionesToast";
import moment from "moment";

// const { Option } = Select;

const Sorteos = (props) => {
  const rolUser = props.rolUsuario;

  const URL_TIPOSORTEO = window.API_ROUTES.TIPOSORTEO;
  const URL_JUGADAS = window.API_ROUTES.JUGADAS;

  const URL_AGENCIAS = window.API_ROUTES.AGENCIAS;

  const [tipos, setTipos] = useState([]);

  const [fechaSorteo, setFechaSorteo] =
    useState(/*moment().format("DD/MM/YYYY")*/);
  const [tipo, setTipo] = useState("");
  const [agencias, setAgencias] = useState([]);
  const [agenciaSelected, setAgenciaSelected] = useState("");
  const [subagenciaSelected, setSubagenciaSelected] = useState("");
  const [datosTabla, setDatosTabla] = useState([]);
  const [count, setCount] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [key, setKey] = useState(0);

  const columns = [
    {
      header: "Fecha Sorteo",
      accessorKey: "extracto.fecha_sorteo",
      size: 50,
    },
    {
      header: "Tipo Sorteo",
      accessorKey: "extracto.tipo_detalle.tipo",
      size: 70,
    },
    // {
    //   header: "Hora de Jugada",
    //   accessorKey: "ticket.hora_jugada",
    //   size: 50,
    // },
    {
      header: "Subido por",
      accessorKey: "creado_por.email",
      size: 20,
    },
    {
      header: "Hora de Registro",
      accessorKey: "fecha_creacion",
      size: 50,
    },
    {
      header: "Agencia de Venta",
      accessorKey: "agencia.numero_agencia",
      size: 30,
    },
    {
      header: "Subagencia de Venta",
      accessorKey: "agencia.numero_subagencia",
      size: 30,
    },
    {
      header: "Monto",
      accessorKey: "monto",
      size: 30,
      Cell: ({ renderedCellValue, row }) => (
        <span>{Parser.currency(renderedCellValue)}</span>
      ),
    },
  ];

  const handleExportExcel = () => {
    const filters = {
      fechaSorteo: fechaSorteo,
    };
    var exportData = [];

    ConsultasAPI.ListarObjetos(
      URL_JUGADAS + "exportControl/",
      pagination.pageIndex,
      pagination.pageSize,
      filters
    )
      .then((response) => {
        var total = 0;
        var ticketsA = 0;
        var ticketsE = 0;
        var siesta = 0;
        var tarde = 0;
        var matutino = 0;
        var nocturno = 0;
        var vespertino = 0;
        var diaViejo = response.data[0].fecha_sorteo;
        response.data.forEach((jugada) => {
          if (diaViejo !== jugada.fecha_sorteo) {
            exportData.push({
              diaSorteo: diaViejo,
              "De la Siesta": Parser.currency(siesta),
              "De la Tarde": Parser.currency(tarde),
              Matutino: Parser.currency(matutino),
              Nocturno: Parser.currency(nocturno),
              Vespertino: Parser.currency(vespertino),
              "Total Sorteo": Parser.currency(
                siesta + tarde + matutino + nocturno + vespertino
              ),
              ticketsAnulados: ticketsA,
              ticketsEmitidos: ticketsE,
            });
            siesta = 0;
            tarde = 0;
            matutino = 0;
            nocturno = 0;
            vespertino = 0;
            ticketsA = 0;
            ticketsE = 0;
            diaViejo = jugada.fecha_sorteo;
          }
          total = total + jugada.montoVendido;
          ticketsA = ticketsA + jugada.ticketsAnulados;
          ticketsE = ticketsE + jugada.ticketsEmitidos;
          if (jugada.tipo.toLowerCase().includes("siesta")) {
            siesta = jugada.montoVendido;
            // sum = sum + 1;
          } else if (jugada.tipo.toLowerCase().includes("tarde")) {
            tarde = jugada.montoVendido;
            // sum = sum + 1;
          } else if (jugada.tipo.toLowerCase().includes("matutin")) {
            matutino = jugada.montoVendido;
            // sum = sum + 1;
          } else if (jugada.tipo.toLowerCase().includes("nocturn")) {
            nocturno = jugada.montoVendido;
            // sum = sum + 1;
          } else if (jugada.tipo.toLowerCase().includes("vespertin")) {
            vespertino = jugada.montoVendido;
            // sum = sum + 1;
          }
        });
        exportData.push({
          diaSorteo: diaViejo,
          "De la Siesta": Parser.currency(siesta),
          "De la Tarde": Parser.currency(tarde),
          Matutino: Parser.currency(matutino),
          Nocturno: Parser.currency(nocturno),
          Vespertino: Parser.currency(vespertino),
          "Total Sorteo": Parser.currency(
            siesta + tarde + matutino + nocturno + vespertino
          ),
          ticketsAnulados: ticketsA,
          ticketsEmitidos: ticketsE,
        });

        var exportFooter = {
          totalPagado: Parser.currency(total),
        };
        exportData.push({
          diaSorteo: "",
          "De la Siesta": "",
          "De la Tarde": "",
          Matutino: "",
          Nocturno: "",
          Vespertino: "",
          ticketsAnulados: "",
          ticketsEmitidos: "",
          ...exportFooter,
        });

        const worksheet = XLSX.utils.json_to_sheet(exportData.flat());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(
          workbook,
          "sumaJugadasPorSorteo" + filters.fechaSorteo + ".xlsx"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickClear = () => {
    setFechaSorteo();
    setKey(key + 1);
    setTipo("-");
    setAgenciaSelected("-");
    setSubagenciaSelected("-");
  };

  useEffect(() => {
    loadSorteos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, agenciaSelected, subagenciaSelected, tipo, fechaSorteo]);

  var valid = function (current) {
    const today = moment();
    const isSunday = current.day() === 0;
    return current.isBefore(today) && !isSunday;
  };
  const loadSorteos = () => {
    const filters = {
      agencia:
        agenciaSelected && agenciaSelected !== "-" ? agenciaSelected : "",
      subagencia:
        subagenciaSelected && subagenciaSelected !== "-"
          ? subagenciaSelected
          : "",
      tipo: tipo && tipo !== "-" ? tipo : "",
      fechaSorteo,
    };
    //Para rellenar la tabla con los sorteos
    ConsultasAPI.ListarObjetos(
      URL_JUGADAS,
      pagination.pageIndex,
      pagination.pageSize,
      filters
    ).then((response) => {
      setCount(response.data.count);
      setDatosTabla(response.data.results);
    });
    //Trae las agencias para el filtro
    ConsultasAPI.ListarObjetos(URL_AGENCIAS + "ListaFiltrar/").then(
      (response) => {
        setAgencias(response.data);
        // console.log(response.data)
        // console.log(response.data
        //   .filter(
        //     (agencia) =>
        //       agencia.numero_agencia === agenciaSelected
        //   )
        //   // .map((agencia) => (
        //   //   <option key={agencia.id} value={agencia.numero_subagencia}>
        //   //     {agencia.numero_subagencia}{console.log(agencia)}
        //   //   </option>
        //   // ))
        //   )
      }
    );
    //Trae los tipos de sorteo, tambien para el filtro
    ConsultasAPI.ListarObjetos(URL_TIPOSORTEO).then((response) => {
      setTipos(response.data.results);
    });
  };

  const cargarSorteo = () => {
    window.location.href += "/cargar-sorteo";
  };

  return (
    <Container className="mainSection">
      <Row>
        <Col>
          <h1 className="py-4 fw-bold">Sorteos</h1>
        </Col>
        {rolUser === "ADMINISTRADOR" ||
        rolUser === "DOCUMENTACION" ||
        rolUser === "CONTADURIA" ||
        rolUser === "OPERADOR-EXTRACTO" ||
        rolUser === "GERENCIA-QUINIELA" ? (
          <Col className="d-flex justify-content-end my-3 ">
            <Button className="btn botonCPA m-3" onClick={() => cargarSorteo()}>
              <FaPlus className="iconAntD" /> NUEVO
            </Button>
          </Col>
        ) : null}
      </Row>
      <Card>
        <Card.Body>
          <Row>
            <Form.Group as={Col} md="2">
              <Form.Label>Fecha</Form.Label>
              <Datetime
                isValidDate={valid}
                key={key}
                timeFormat={false}
                dateFormat="DD/MM/YYYY"
                inputProps={{
                  readOnly: true,
                  placeholder: "N/A",
                }}
                value={fechaSorteo}
                onChange={setFechaSorteo}
                closeOnSelect
              />
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label>Tipo</Form.Label>
              <Form.Select
                // style={{ width: "20%", height: "37px" }}
                value={tipo}
                onChange={(event) => setTipo(event.target.value)}
                disabled={tipos.length < 1}
              >
                <option hidden>-</option>
                {tipos.map((tipo) => (
                  <option key={tipo.id} value={tipo.id}>
                    Sorteo {tipo.tipo}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label>Agencia</Form.Label>
              <Form.Select
                // style={{ width: "13%", height: "32px" }}
                value={agenciaSelected}
                onChange={(event) => {
                  setAgenciaSelected(event.target.value);
                  setSubagenciaSelected("-");
                }}
                disabled={agencias.length < 1}
              >
                <option hidden>-</option>
                {agencias
                  .filter((agencia) => agencia.numero_subagencia === 0)
                  .map((agencia) => (
                    <option key={agencia.id} value={agencia.numero_agencia}>
                      {agencia.numero_agencia}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="2">
              <Form.Label>Subagencia</Form.Label>
              <Form.Select
                // style={{ width: "13%", height: "32px" }}
                value={subagenciaSelected}
                onChange={(event) => {
                  setSubagenciaSelected(event.target.value);
                }}
                disabled={
                  !agenciaSelected ||
                  agenciaSelected === "-" ||
                  agencias.length < 1
                }
              >
                <option hidden>-</option>
                {agencias
                  .filter(
                    (agencia) =>
                      parseInt(agencia.numero_agencia) ===
                      parseInt(agenciaSelected)
                  )
                  .map((agencia) => (
                    <option key={agencia.id} value={agencia.numero_subagencia}>
                      {agencia.numero_subagencia}
                      {console.log(agencia)}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Button
                className="btn botonCPA me-3"
                onClick={handleClickClear}
                style={{ alignContent: "center", margin: 15, float: "right" }}
              >
                <FaEraser className="iconAntD" />
                Limpiar Filtro
              </Button>
            </Form.Group>
          </Row>
        </Card.Body>
        <MaterialReactTable
          className="w-100"
          columns={columns}
          data={datosTabla}
          initialState={{ columnVisibility: { id: false } }} //hide firstName column by default
          // editingMode="modal" //default
          // enableEditing
          // onEditingRowSave={handleSaveRowEdits}
          // onEditingRowCancel={handleCancelRowEdits}
          enableRowSelection={false} //enable some features
          enableColumnOrdering={false}
          enableGlobalFilter={false} //turn off a feature
          enableFilters={false}
          localization={MRT_Localization_ES}
          // enableRowActions
          // positionActionsColumn="last"
          manualPagination
          muiTablePaginationProps={{
            rowsPerPageOptions: [10],
          }}
          enablePagination={true}
          // onPaginationChange={value => console.log(value)}
          rowCount={count}
          onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
          // state={{ pagination }} //pass the pagination state to the table
          state={{
            pagination,
          }}
          // renderRowActions={({ row, table }) => (
          //     <Box className="d-flex">
          //         <Button
          //             className="me-3"
          //             title="Editar"
          //             variant="outline-info"
          //             onClick={() => handleOpenEditModal(row.getValue("id"))}
          //         >
          //             <BsFillPencilFill />
          //         </Button>
          //         <Button
          //             title="Eliminar"
          //             variant="outline-danger"
          //             onClick={() => borrarDepartamento(row.getValue("id"))}
          //         >
          //             <BsFillTrashFill />
          //         </Button>
          //     </Box>
          // )}
        />
        <section className="d-flex justify-content-end my-3">
          <BtnVolver route="/liquidaciones" />
          <Button
            className="justify-content-center btn botonCPA me-3"
            variant="outline-info"
            disabled={!fechaSorteo}
            onClick={() => handleExportExcel()}
          >
            <AiOutlineUpload className="iconAntD" />
            Excel - Suma por Mes
          </Button>
        </section>
      </Card>
    </Container>
  );
};

export default Sorteos;
