import React, { useState } from "react";
import BtnVolver from "../../common/BtnVolver";
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import Request from "../../../shared/helpers/request";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const PasswordResetForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setValue } = useForm();

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    Request.post("login/password/reset/", { email })
      .then(() => {
        setIsLoading(false);
        setValue("email", " ");
        setMessage({
          text: "Te hemos enviado un correo electrónico con instrucciones para resetear tu contraseña.",
          variant: "success",
        });
        setTimeout(() => {
          navigate("/");
        }, 5000); //
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        setMessage({
          text: `Ha ocurrido un error: ${error.response.data.email}`,
          variant: "danger",
        });
        setTimeout(() => {
          setMessage(null);
        }, 7000);
      });
  };

  return (
    <Container fluid className="mainSection my-md-5 p-3">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Header as="h5">¿Olvidaste tu contraseña?</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="my-4 mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    autoComplete="off" // Deshabilitar autocompletado para este campo
                    value={email}
                    placeholder="Ingrese su correo electrónico"
                    onChange={handleEmailChange}
                    required
                  />
                </Form.Group>
                <article className="my-4 mb-3 d-flex justify-content-end">
                  <BtnVolver />
                  <Button className="botonCPA" type="submit">
                    Resetear contraseña
                  </Button>
                </article>
              </Form>
              {message && (
                <div
                  className={`alert alert-${message.variant} my-4`}
                  role="alert"
                >
                  {message.text}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal centered show={isLoading} onHide={() => setIsLoading(false)}>
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Enviando correo electrónico...</p>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default PasswordResetForm;
