import MaterialReactTable from 'material-react-table';
import { useState, useMemo, useEffect, useRef } from 'react';
import { Container, Modal, Row, Form, Col, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFileDownload } from 'react-icons/fa';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import ConsultasAPI from '../../../shared/helpers/consultasAPI';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import * as XLSX from 'xlsx';
import Notificaciones from '../../../shared/helpers/notificacionesToast';

const Estadistica = (props) => {
    const [count, setCount] = useState(0);
    const [dataResoluciones, setDataResoluciones] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [fechaHasta, setFechaHasta] = useState(props.fecha);
    const [fechaDesde, setFechaDesde] = useState(props.fecha);
    const [AllowButton, setAllowButton] = useState(false);
    const datetimeRefDesde = useRef(null);
    const datetimeRefHasta = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEstadistica, setIsLoadingEstadistica] = useState(false);
    const URL_ARCHIVODOCUMENTACION = window.API_ROUTES.ARCHIVODOCUMENTACION;

    const columns = useMemo(() => [
        {
            header: 'Usuario',
            accessorKey: 'usuario',
            size: 50,
            enableColumnFilter: true,
        },
        {
            header: 'Cantidad de Legajos Revisados',
            accessorKey: 'cantidad',
            size: 50,
            enableColumnFilter: false,
        },
    ]);

    useEffect(() => {
        if (props.show) {
            cargarResoluciones(fechaDesde, fechaHasta);
        }
    }, [fechaDesde, fechaHasta, props.show, pagination, columnFilters]);

    const cargarResoluciones = async (fechaDesde, fechaHasta) => {
        let filters = {
            fechaHasta: fechaHasta,
            fechaDesde: fechaDesde,
            pagination: 'true',
            usuario: columnFilters[0] ? columnFilters[0].value : '',
        };
        setIsLoadingEstadistica(true);
        try {

            const response = await ConsultasAPI.ListarObjetos(
                URL_ARCHIVODOCUMENTACION + 'listEstadistica/',
                pagination.pageIndex,
                pagination.pageSize,
                filters
            );
            let total = 0;
            if (response.status === 200) {
                let archivos = response.data;
                let dataTable = [];
                const promises = Object.keys(archivos).map(async (usuario) => {
                    let datosArchivo = {
                        usuario: usuario,
                        cantidad: archivos[usuario],
                    };
                    total = total + archivos[usuario];
                    dataTable.push(datosArchivo);
                });
                await Promise.all(promises).then(() => {
                    setCount(total);
                    let totalTabla = {
                        usuario: 'Total',
                        cantidad: total,
                    };
                    dataTable.push(totalTabla);
                    setDataResoluciones(
                        dataTable.sort((a, b) => {
                            // Si el elemento de la tabla es Total lo muestra al final de la tabla
                            if (a.usuario === 'Total') return 1;
                            if (b.usuario === 'Total') return -1;
                            return b.cantidad - a.cantidad;
                        })
                    );
                    setIsLoadingEstadistica(false);
                    setAllowButton(true);
                });
            } else {
                setIsLoadingEstadistica(false);
                setDataResoluciones([]);
                setAllowButton(false);
            }
        } catch (error) {
            console.info(error)
            setIsLoadingEstadistica(false);
            setDataResoluciones([]);
            setAllowButton(false);
        }
    };

    const exportToExcel = async () => {
        setIsLoading(true);
        let filters = {
            fechaHasta: fechaHasta,
            fechaDesde: fechaDesde,
            pagination: 'true',
            usuario: columnFilters[0] ? columnFilters[0].value : '',
        };
        const response = await ConsultasAPI.ListarObjetos(
            URL_ARCHIVODOCUMENTACION + 'listEstadistica/',
            pagination.pageIndex,
            pagination.pageSize,
            filters
        );
        if (response.status === 200) {
            let archivos = response.data;
            let dataTable = [];
            const promises = Object.keys(archivos).map(async (usuario) => {
                let datosArchivo = {
                    Usuario: usuario,
                    'Cantidad de Legajos Revisados': archivos[usuario],
                };
                dataTable.push(datosArchivo);
            });
            await Promise.all(promises);
            const worksheet = XLSX.utils.json_to_sheet(
                dataTable
                    .sort((a, b) => {
                        return b.cantidad - a.cantidad;
                    })
                    .filter((dato) => {
                        return dato['Usuario'].includes(filters.usuario);
                    })
                    .flat()
            );
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, 'Estadisticas.xlsx');
            setIsLoading(false);
        } else {
            setIsLoading(false);
            Notificaciones.notificacion('Ocurrio un error');
        }
    };

    var valid = function (current) {
        const today = moment();
        return current.isBefore(today);
    };

    const handleCloseModal = () => {
        setFechaDesde(props.fecha);
        setFechaHasta(props.fecha);
        setDataResoluciones([]);
        props.close();
    };

    return (
        <>
            <Modal
                show={props.show}
                size="xl"
                centered
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>Estadistica</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Fecha Desde:</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat="DD/MM/YYYY"
                                    value={fechaDesde}
                                    ref={datetimeRefDesde}
                                    onChange={(momentDate) => {
                                        setFechaDesde(momentDate);
                                    }}
                                    isValidDate={valid}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Fecha Hasta:</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat="DD/MM/YYYY"
                                    value={fechaHasta}
                                    ref={datetimeRefHasta}
                                    onChange={(momentDate) => {
                                        setFechaHasta(momentDate);
                                    }}
                                    isValidDate={valid}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <MaterialReactTable
                                className="w-100"
                                columns={columns}
                                data={dataResoluciones}
                                initialState={{
                                    columnVisibility: {
                                        id: false,
                                    },
                                    showColumnFilters: true,
                                    density: 'compact',
                                }} //hide firstName column by default
                                editingMode="modal" //default
                                enableRowSelection={false} //enable some features
                                enableColumnOrdering={false}
                                enableHiding={false}
                                enableColumnActions={false}
                                enableSorting={false}
                                enableRowVirtualization
                                displayColumnDefOptions={{
                                    'mrt-row-actions': { size: 10 },
                                }} //change width of actions column to 300px
                                manualPagination
                                muiTablePaginationProps={{
                                    rowsPerPageOptions: [10],
                                }}
                                enablePagination={false}
                                localization={MRT_Localization_ES}
                                rowCount={count}
                                onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                                enableGlobalFilter={true} //turn off a feature
                                onColumnFiltersChange={(value) => {
                                    setColumnFilters(value);
                                }} //hoist internal columnFilters state to your state
                                state={{
                                    columnFilters,
                                    pagination,
                                }}
                            />
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {AllowButton ? (
                        <Link
                            className="btn botonCPA-Excel me-3"
                            onClick={exportToExcel}
                        >
                            <FaFileDownload className="iconAntD" />
                            Descargar Excel
                        </Link>
                    ) : null}
                </Modal.Footer>
            </Modal>
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Descargando Archivo...</p>
                </Modal.Body>
            </Modal>
            <Modal centered show={isLoadingEstadistica}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando Informacion...</p>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default Estadistica;
