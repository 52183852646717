import Request from "./request";

export default class ConsultasAPI{
    static ListarObjetos(url,page, pageSize,columnFilters,fechaDesde, fechaHasta, estado,tipo,zona,localidad,sorting,fechaExceptuada,estado2,estadoDocumentacion,offset2,limit){
        return Request
            .get(url
                +'?offset='+page*10
                + '&fechaDesde='+(fechaDesde ? fechaDesde : null)
                + '&fechaHasta='+(fechaHasta ? fechaHasta : null)
                + '&estado='+estado
                + '&tipo='+tipo
                + '&zona='+zona
                + '&localidad='+localidad
                + '&filters='+JSON.stringify(columnFilters ?? [])
                + '&sorting='+JSON.stringify(sorting ?? [])
                + '&fechaExceptuada='+(fechaExceptuada ? fechaExceptuada : null)
                + '&estado2='+estado2
                + '&estadoDocumentacion='+estadoDocumentacion
                + '&offset2='+offset2
                + '&limit='+limit
                //  ,{params:{page:page, filters:JSON.stringify(columnFilters ?? [])}}
                )
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
    }



    static ListarObjetos2(url,page,pageSize,columnFilters,fechaDesde,fechaHasta,search='',provincia='',tipoCargoPublico=''){
        return Request
            .get(url
                +'?offset='+page*10
                + '&filters='+JSON.stringify(columnFilters ?? [])
                + '&fechaDesde='+(fechaDesde ? fechaDesde : null)
                + '&fechaHasta='+(fechaHasta ? fechaHasta : null)
                + '&search=' + encodeURIComponent(search)
                + '&provincia='+provincia
                + '&tipoCargoPublico='+tipoCargoPublico
                )
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
        }








    static ListarTodos(url){
        return Request
            .get(url,)
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
    }
    static BuscarObjetos(url,page, columnFilters){
        return Request
            .post(url
                 , {page:page, columnFilters:columnFilters}
                )
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
    }


    static  ObtenerObjeto(url, id){
        return Request
            .get(url+id+'/')
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
    }

    static BorrarObjeto(url, id){
        return Request
            .delete(url+id+'/')
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
    }

    static CrearObjeto(url, objeto){
        return Request
            .post(url,objeto)
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
        }

    static CrearObjetoArchivo(url, objeto){
        return Request
            .postMultipart(url,objeto)
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
        }

    static ModificarObjeto(url, id, objeto){
        return Request
            .put(url+id+'/', objeto)
            .then(response => {  return response })
            .catch(error => {
                throw error;
            });
    }


}
