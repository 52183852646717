import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Modal, Container, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
//import { useForm } from "react-hook-form";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import CardHeader from "react-bootstrap/esm/CardHeader";
import Select from 'react-select'
import Notificaciones from '../../../../shared/helpers/notificacionesToast';
import moment from "moment";


const FormItem = Form.Item;
const { Option } = Select;

export function ModalNuevoSorteo(props) {

  const URL_RANGO = window.API_ROUTES.TUQUI_RANGO;
  const URL_COLOR = window.API_ROUTES.TUQUI_COLOR;
  const URL_CREAR = window.API_ROUTES.SORTEO;

  const [fechaSorteo, setFechaSorteo] = useState({
    fechaMuestra: '',
    fechaComparar: '',
  });
  const [fechaCaducidad, setFechaCaducidad] = useState({
    fechaMuestra: '',
    fechaComparar: '',
  });

  const [sorteo, setSorteo] = useState("");
  const [rango, setRango] = useState("");
  const [precio, setPrecio] = useState("");
  const [color, setColor] = useState("");
  const [vectorRangos, setVectorRangos] = useState([]);
  const [vectorColores, setVectorColores] = useState([]);

  // const {
  //   //register,
  //   formState: { errors },
  //   //reset,
  // } = useForm();

  const requiredSymbol = props.titulo !== "Ver" ? <b style={{ color: "red" }}>*</b> : "";

  useEffect(() => {
   cargarRangos();
    cargarColores();
  }, []);

  const cargarRangos = async () => {
    await ConsultasAPI.CrearObjeto(URL_RANGO+'dame_rangos/',{}).then((response) => {
      setVectorRangos(response.data);
});
  };

  const cargarColores = async () => {
    await ConsultasAPI.CrearObjeto(URL_COLOR+'dame_colores/',{}).then((response) => {
      setVectorColores(response.data);
});
  };

  const CrearSorteo = async (event) => {
    event.preventDefault()
    if(moment(fechaSorteo.fechaMuestra,"DD/MM/YYYY").isAfter(moment(fechaCaducidad.fechaMuestra,"DD/MM/YYYY"))){
      Notificaciones.notificacion("Controlar las fechas");
      return
    }

    if(precio<=0){
      Notificaciones.notificacion("El precio es inválido");
      return
    }
    if(sorteo<=0){
      Notificaciones.notificacion("El numero de sorteo es inválido");
      return
    }

    Swal.fire({
      title: "Estás seguro?",
      text: "Esta accion creará un nuevo sorteo",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#008185",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancelar",
    }).then(async(result) => {

      if (result.isConfirmed) {
    var datos={
      Sorteo:sorteo,
        FechaSorteo:fechaSorteo.fechaComparar,
       FechaCaducidad:fechaCaducidad.fechaComparar,
        Color:color,
       Rango:rango,
        Precio:precio
    }
  await ConsultasAPI.CrearObjeto(URL_CREAR+'crear_sorteo/', datos).then((response) => {
      if(response.status===200){
          Swal.fire(
            "Sorteo creado",
            "Se creó el sorteo con éxito",
            "success"
          );
            handleClose(event);
                             }
      if(response.status===202){
          Notificaciones.notificacion(response.data.Mensaje);
                                  }
           }).catch((error) => {
          console.info(error)
          Swal.fire({
            title: "Error",
            text: "Error del servidor",
            icon: "error"

          })
                         });
                        }
                        });
 }

 const handleClose = (event) => {
    event.preventDefault();
    setFechaSorteo({
      fechaMuestra: '',
      fechaComparar: '',
    });
  setFechaCaducidad({
  fechaMuestra: '',
      fechaComparar: '',
    });
    setSorteo("");
    setRango("");
    setPrecio("");
    setColor("");
   props.onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.titulo === "Nuevo") {
     CrearSorteo(event);
    }
      };


  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter

      // Encuentra el índice del campo actual dentro del formulario
      const formControls = event.target.form.elements;
      const currentIndex = Array.from(formControls).indexOf(event.target);

      // Encuentra el siguiente campo y enfócalo
      const nextControl = formControls[currentIndex + 1];
      if (nextControl) {
        nextControl.focus();
      }
    }
  };


  return (
    <Container>
      <Modal show={props.show} size="lg" centered>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>{props.titulo} Sorteo</Modal.Title>
        </Modal.Header>
        <Form onKeyDown={handleFormKeyDown} style={{ width: "100%" }}>
          <Modal.Body style={{ width: "100%" }}>
            <Card className="m-3">
              <CardHeader>
                <Card.Title>Datos del Sorteo</Card.Title>
              </CardHeader>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>
                      Número de sorteo {requiredSymbol}
                    </Form.Label>
                    <Form.Control
                      readOnly={props.titulo === "Ver" ? true : false}
                      type="text"
                      inputMode="numeric"
                      placeholder={"Ingrese el número"}
                      value={sorteo}
                      onChange={(event) => {
                        if(event.target.value.trim() !== ''){
                          setSorteo(event.target.value);
                        }else{
                          setSorteo('');
                        }
                      }}
                      onKeyPress={(event) => {
                        // Solo permitir números
                        const regex = /[0-9]/;
                        const key = String.fromCharCode(event.charCode);
                        if (!regex.test(key)) {
                          event.preventDefault();
                        }
                      }}
                      required

                    />
                  </Form.Group>
                   <Form.Group as={Col} md="6">
                      <Form.Label>Color {requiredSymbol}</Form.Label>
                      <Form.Select
                        readOnly={props.titulo === "Ver" ? true : false}
                        value={color}
                        onChange={(event) =>
                          setColor(event.target.value)
                        }
                        required
                      >
                        <option value="" disabled hidden>
                          Seleccionar Color
                        </option>

                        {vectorColores.map((condicion) => (
                          <option key={condicion.id} value={condicion.id}>
                            {condicion.nombre}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                <Form.Group as={Col} md="6">
                      <Form.Label>Fecha de sorteo {requiredSymbol}</Form.Label>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          readOnly: true,
                          placeholder: fechaSorteo.fechaMuestra?fechaSorteo.fechaMuestra:'Seleccione una fecha',
                        }}
                        required
                        value={fechaSorteo.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format("DD/MM/YYYY");
                          const fechaComparar = momentDate.format("YYYY-MM-DD");
                          setFechaSorteo({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });

                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Fecha de caducidad {requiredSymbol}</Form.Label>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          readOnly: true,
                          placeholder: fechaCaducidad.fechaMuestra?fechaCaducidad.fechaMuestra:'Seleccione una fecha',
                        }}
                        required
                        value={fechaCaducidad.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format("DD/MM/YYYY");
                          const fechaComparar = momentDate.format("YYYY-MM-DD");
                          setFechaCaducidad({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    </Form.Group>
                     </Row>
                <Row className="mb-3">
                 <Form.Group as={Col} md="6">
                      <Form.Label>Rango {requiredSymbol}</Form.Label>
                      <Form.Select
                        readOnly={props.titulo === "Ver" ? true : false}
                        value={rango}
                        onChange={(event) =>
                          setRango(event.target.value)
                        }
                        required
                      >
                        <option value="" disabled hidden>
                          Seleccionar Rango
                        </option>

                        {vectorRangos.map((valor) =>
                         (
                          <option key={valor.id} value={valor.id}>
                            {valor.desde} - {valor.hasta}
                          </option>
                        )
                        )}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                    <Form.Label>Precio {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      readOnly={props.titulo === "Ver" ? true : false}
                      placeholder={"Ingrese el precio"}
                      value={precio}
                      onChange={(event) => setPrecio(event.target.value)}
                      onKeyPress={(event) => {
                        // Solo permitir números
                        const regex = /[0-9]/;
                        const key = String.fromCharCode(event.charCode);
                        if (!regex.test(key)) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn botonCPA m-3" onClick={handleClose}>
              {'Cancelar'}
            </button>
            {<button className="btn botonCPA m-3" onClick={handleSubmit}>
                Guardar
              </button>}
          </Modal.Footer>
        </Form>
        </Modal>
       </Container>
  );
}
