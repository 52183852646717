const EmailComisionesHTML = (props) => {
    function formatDate(date) {
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            timeZone: "UTC", // Adjust this to the desired time zone
        };
        return date.toLocaleDateString("es-AR", options);
    }
    function formatMonth(date) {
        const options = { month: "long", timeZone: "UTC" };
        const newDate = new Date(date);
        return newDate.toLocaleDateString("es-AR", options).toUpperCase();
    }
    function formatYear(date) {
        const options = { year: "numeric", timeZone: "UTC" };
        const newDate = new Date(date);
        return newDate.toLocaleDateString("es-AR", options).toUpperCase();
    }
    const mes = formatMonth(props.datos);
    const ano = formatYear(props.datos);

    return (
        <div>
            <p>
                San Miguel de Tucumán, {formatDate(new Date())}
                <br />
                Dpto. de Quinielas
            </p>
            <p>
                Sr.\a agente de quinielas, se encuentra habilitada la presentación de documentación para el
                cobro de comisiones del mes de {mes} {ano} y la visualizacion de las comisiones de dicho periodo, la misma será a través del
                siguiente Enlace:{" "}
                <a href="https://agenciasdequiniela.cajapopular.gov.ar/login">
                    https://agenciasdequiniela.cajapopular.gov.ar/login
                </a>
            </p>
            <p>
                La modalidad que se implementara será la siguiente:
            </p>
            <ol>
                <li>
                    1) El Concesionario Oficial deberá remitir a cada sub agente esta
                    dirección web, para que cada integrante de la red de Agentes y Sub
                    agentes de Quiniela suba o cuelgue la documentación correspondiente
                    para el cobro de su comisión en la página.
                </li>
                <li>
                    La documentación que se requerirá para subir a la página,
                    en formato PDF de manera excluyente, será:
                </li>
                <li>
                    a) Factura electrónica correspondiente al mes por comisionar.
                </li>
                <li>
                    b) Certificado de Cumplimiento Fiscal VIGENTE.
                </li>
                <li>
                    c) Constancia de Inscripción en AFIP ACTUALIZADO.
                </li>
                <li>
                    d) Constancia de Inscripción en Rentas de la Provincia ACTUALIZADO.
                </li>
                <li>
                    e) Constancia del Tributo Económico Municipal ACTUALIZADA.
                </li>
                <li>
                    f) Certificación de NO estar registrado como Deudor Alimenticio.
                </li>
                <li>
                    g) Declaración Jurada de liquidación mensual (solo Agencieros)
                </li>
                <li >
                    CABE ACLARAR QUE DE NO SUBIR ALGUNOS DE LOS REQUISITOS EXIGIDOS,
                    DARÁ COMO CONSECUENCIA EL NO ENVIO DE LA DOCUMENTACION POR PARTE DEL SISTEMA.
                </li>
                <li>
                    2) En el detalle de su facturación deberán discriminar “COMISION {mes}{" "}
                    {ano} VENTA JUEGO QUINIELA” y “COMISION {mes} {ano} VENTA JUEGO EL
                    TUQUI 10”.-
                </li>
            </ol>
            <p>Atte.-</p>
        </div>
    );
};

export default EmailComisionesHTML;
